import { registerDecorator } from 'class-validator';

import { Address } from '../entities/address.entity';

export function IsFullAddress() {
  return function (object, propertyName: string) {
    registerDecorator({
      name: 'isFullAddress',
      target: object.constructor,
      propertyName: propertyName,
      validator: {
        validate(address: Address) {
          let isValid = true;

          if (address) {
            const hasAtLeastOneValue = Object.values(address).some((prop) => prop?.length > 0);

            if (hasAtLeastOneValue) {
              isValid = Object.entries(address).every(([key, value]) => {
                return key === 'suiteNumber' || value?.length > 0;
              });
              //isValid = Object.values(addressRequiredFieldsOnly).every(prop => prop.length > 0);
            }
          } else {
            isValid = false;
          }

          return isValid;
        },
      },
    });
  };
}
