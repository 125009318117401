import { HttpClient, HttpContext, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfigService } from '../config/config.service';
import { IGNORE_ERROR_INTERCEPTOR_TOKEN } from '../../interceptors/error.interceptor';
import { Observable, lastValueFrom } from 'rxjs';
import { DocumentList } from '@common/entities';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private FILES_ENDPOINT = this.configService.getConfig().FILES_ENDPOINT;
  private FILES_TARGET = this.configService.getConfig().FILES_TARGET ?? 'file-upload';

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private toasterService: ToastrService
  ) {}

  upload(parentId: string, file: File, ignoreErrorInterceptor = false) {
    const formData = new FormData();
    formData.append('image', file);
    return this.http
      .post(`${this.FILES_ENDPOINT}/${this.FILES_TARGET}/${parentId}`, formData, {
        reportProgress: true,
        observe: 'events',
        context: new HttpContext().set(IGNORE_ERROR_INTERCEPTOR_TOKEN, ignoreErrorInterceptor),
      })
      .pipe<{ type: HttpEventType; loaded?: number; total?: number; key: string }>(
        map((result) => ({
          ...result,
          key: String(result['body']?.filename).substring(String(result['body']?.filename).indexOf('/') + 1),
          azureUrl: String(result['body']?.url),
        }))
      );
  }

  getDocument(key: string, parentId: string) {
    return this.http.get(`${this.FILES_ENDPOINT}/${this.FILES_TARGET}/${parentId}/${key}`);
  }

  isFileExists(key: string, parentId: string) {
    return this.http.get(`${this.FILES_ENDPOINT}/${this.FILES_TARGET}/exists/${parentId}/${key}`);
  }

  listFiles(parentId: string) {
    return this.http.get(`${this.FILES_ENDPOINT}/${this.FILES_TARGET}/list/list-files/${parentId}`);
  }

  deleteFile(key: string, parentId: string) {
    return this.http
      .delete(`${this.FILES_ENDPOINT}/${this.FILES_TARGET}/${parentId}/${key}`, {
        reportProgress: true,
        observe: 'events',
      })
      .toPromise();
  }

  formatBytes(fileSize: number, decimals = 2) {
    if (fileSize === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(fileSize) / Math.log(k));

    return parseFloat((fileSize / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }

  async validateAzureFiles(documentList: DocumentList, applicationId: string) {
    let azureFiles: string[] = [];

    if (documentList.documents) {
      const validDocuments = [];

      for (const document of documentList.documents) {
        if (document.isAdded) {
          // Only query Azure if a document is added and files array is empty
          if (!azureFiles.length) {
            azureFiles = await lastValueFrom(this.listFiles(applicationId) as Observable<string[]>);
          }

          const documentExists = azureFiles.includes(`${applicationId}/${document.key}`);
          if (documentExists) {
            validDocuments.push(document);
          } else {
            this.toasterService.error(`Error uploading document, please re-upload: ${document.name}`, 'Upload Error');
          }
          delete document.isAdded;
        } else {
          validDocuments.push(document);
        }
      }

      documentList.documents = validDocuments;
    }
  }
}
