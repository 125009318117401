import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService, IGNORE_ERROR_INTERCEPTOR_TOKEN } from '@common/ui/shared-components';
import { Apollo, gql } from 'apollo-angular';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HealthCheckService {
  constructor(private http: HttpClient, private apollo: Apollo, private configService: AppConfigService) {}

  isServicesAlive(): Observable<boolean> {
    return combineLatest([this.getFileServerHealth(), this.getFiServiceHealth()]).pipe(
      map((res) => {
        return res.every((val) => val === true);
      })
    );
  }

  getFileServerHealth(): Observable<boolean> {
    const FILES_ENDPOINT = this.configService.getConfig().FILES_ENDPOINT;
    return this.http.get<unknown>(`${FILES_ENDPOINT}` + '/health/live').pipe(
      catchError(() => {
        return of(false);
      }),
      map((res) => {
        return Object.values(res)[0]['status'] === 'up';
      })
    );
  }

  getFiServiceHealth(): Observable<boolean> {
    const FI_SERVICE_ENDPOINT = this.configService.getConfig().FSCD_INTAKE_SERVICE_URL;
    const index = FI_SERVICE_ENDPOINT.lastIndexOf('/');
    const serviceUrl = FI_SERVICE_ENDPOINT.substring(0, index);
    return this.http.get<unknown>(`${serviceUrl}` + '/api/health/live').pipe(
      catchError(() => {
        return of(false);
      }),
      map((res) => {
        return res['fscdi-api'].status === 'up';
      })
    );
  }

  queryGetUIVersion(): Observable<string> {
    //The github.sha will be missing on local dev environments, so if 404 then we will return null which will be handled by the version header
    return this.http
      .get('/github.sha', {
        responseType: 'text',
        context: new HttpContext().set(IGNORE_ERROR_INTERCEPTOR_TOKEN, true),
      })
      .pipe(catchError(() => of(null)));
  }

  queryGetFileVersion(): Observable<string> {
    const FILES_ENDPOINT = this.configService.getConfig().FILES_ENDPOINT;
    return this.http
      .get(`${FILES_ENDPOINT}/admin/version`, {
        responseType: 'text',
        context: new HttpContext().set(IGNORE_ERROR_INTERCEPTOR_TOKEN, true),
      })
      .pipe(
        catchError((_) => {
          return of(null);
        })
      );
  }

  queryGetAPIVersion(): Observable<string> {
    return this.apollo
      .query<{ getVersion: string }>({
        query: gql`
          query getVersion {
            getVersion
          }
        `,
      })
      .pipe(
        filter(({ data, loading }) => data && !loading),
        map(({ data }) => data?.getVersion)
      );
  }
}
