import { registerDecorator } from 'class-validator';
import { mod10Validation } from './luhn';

export function SinFormat() {
  return function (object, propertyName: string) {
    registerDecorator({
      name: 'SinFormat',
      target: object.constructor,
      propertyName: propertyName,
      validator: {
        validate(sin: string) {
          const mod10 = mod10Validation(sin, 8);
          return mod10 === null; //{ mod10Validation: true } result means failure!
        },
        defaultMessage(): string {
          return 'SIN must be a valid SIN';
        },
      },
    });
  };
}
