/* eslint-disable @typescript-eslint/ban-types */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CanadaPostAddress } from '@common/entities';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'common-address-lookup',
  templateUrl: './address-lookup.component.html',
  styleUrls: ['./address-lookup.component.scss'],
})
export class AddressLookupComponent implements OnInit, OnDestroy {
  CharactersStartToLookup = 5;
  addressLookupControl = this.formBuilder.control('');

  destroy$ = new Subject<void>();

  @Input() hideLookupResult: boolean;
  @Output() hideLookupResultChange = new EventEmitter<boolean>();
  @Input() dataCyPrefix = '';
  @Input() hideOptionalLabel = false;
  isLookingup = false;
  private _suggestedAddresses: CanadaPostAddress[];
  @Input() set suggestedAddresses(value: CanadaPostAddress[]) {
    this._suggestedAddresses = value;
    this.isLookingup = false;
  }
  get suggestedAddresses() {
    return this._suggestedAddresses;
  }

  @Output() lookupAddress = new EventEmitter<string>();
  @Output() selectAddress = new EventEmitter<CanadaPostAddress>();
  constructor(private formBuilder: UntypedFormBuilder) {}
  ngOnInit(): void {
    this.addressLookupControl.valueChanges.pipe(debounceTime(700), takeUntil(this.destroy$)).subscribe((value) => {
      if (value?.length >= this.CharactersStartToLookup) this.lookupAddress.emit(value);
      else this.suggestedAddresses = [];
    });

    this.addressLookupControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (this.hideLookupResult) {
        this.hideLookupResult = false;
        this.hideLookupResultChange.emit(false);
      }
      this.isLookingup = !!value;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSelectAddress(canadaPostAddress: CanadaPostAddress) {
    this.addressLookupControl.setValue(null);
    if (canadaPostAddress) this.selectAddress.emit(canadaPostAddress);
  }
  onLookupClick(event) {
    event.stopPropagation();
  }
}
