<div class="date">
  <input     
  type="text"
  [min]="minDate" 
  [max]="maxDate" 
  [matDatepicker]="date"
    placeholder="MM/YYYY"
    [formControl]="formControl"
  />

  <mat-datepicker-toggle [disableRipple]="true" matSuffix [for]="date" class="icon">
    <span class="icon" matDatepickerToggleIcon>
      <span class="expander-icon"></span>
    </span>                  
  </mat-datepicker-toggle>

  <mat-datepicker 
    #date                                  
    startView="multi-year"
    (monthSelected)="monthChanged($event, date)"
  >
  </mat-datepicker>
</div>