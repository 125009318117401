import { Component, OnInit } from '@angular/core';
import { FscdIntakeApplication } from '@fscd-intake/entities';
import { FiParentInfoLabels, FiChildInfoLabels, FiServicesLabels } from '@common/labels';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FiGraphqlService } from '../../services/fi-graphql.service';
import { FileService } from '@common/ui/shared-components';

@Component({
  selector: 'fi-view-application-page',
  templateUrl: './view-application-page.component.html',
  styleUrls: ['./view-application-page.component.scss'],
})
export class ViewApplicationPageComponent implements OnInit {
  application: FscdIntakeApplication;
  private destroy$ = new Subject<void>();
  parentLabels = FiParentInfoLabels;
  childLabels = FiChildInfoLabels;
  servicesLabels = FiServicesLabels;

  constructor(private router: Router, private graphqlService: FiGraphqlService, private fileService: FileService) {}

  async ngOnInit() {
    const { selectedApplicationId } = await this.graphqlService.getSelectedApplication();
    if (selectedApplicationId) {
      this.graphqlService
        .getFullApplication(selectedApplicationId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((appInfo) => {
          this.application = appInfo;
        });
    }
  }

  onClickDownloadPdf() {
    this.fileService.getDocument(this.application.outputPdfFileName, this.application.id).subscribe((result) => {
      window.open(result['URL']);
    });
  }

  onBackToDashboard() {
    this.router.navigateByUrl('dash?tab=submitted');
  }
}
