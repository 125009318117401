import { ValidationType } from '../enums/validation-type.enum';
import { classToPlain, plainToClass } from 'class-transformer';
import { validateSync, ValidationError } from 'class-validator';
import { ValidationIssue } from '../entities/validation-issue.entity';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ClassConstructor<T> = { new (...args: unknown[]): T };

export class ValidationUtils {
  private static hasRequiredConstraint(validationError: ValidationError) {
    const result =
      validationError.constraints['isNotEmpty'] ||
      validationError.constraints['isDefined'] ||
      validationError.constraints['arrayNotEmpty'];
    return result;
  }

  static getNestedValidationError(validationError: ValidationError, fieldName: string) {
    let result;
    if (validationError.property === fieldName) {
      result = validationError;
    } else if (validationError.children.length > 0) {
      result = ValidationUtils.getNestedValidationError(validationError.children[0], fieldName);
    }

    return result;
  }

  static getRequiredConstraint(validationError: ValidationError, fieldName: string) {
    const targettedError = ValidationUtils.getNestedValidationError(validationError, fieldName);
    const result = ValidationUtils.hasRequiredConstraint(targettedError);

    return result;
  }

  private static flattenErrors(errors: ValidationError[]) {
    let allErrors = [];

    errors.forEach((error: ValidationError) => {
      if (error?.children?.length) {
        error.children.forEach((childError) => {
          const childErrors = ValidationUtils.flattenErrors([childError]);
          allErrors = [...allErrors, ...childErrors];
        });
      } else {
        // Separate document constraints from field constraints so we push an issue of each type if both are present.
        const docConstraints = Object.entries(error.constraints)
          .filter(([k, v]) => k.startsWith('RequiredDocument'))
          .map(([k, v]) => v);
        const fieldConstraints = Object.entries(error.constraints)
          .filter(([k, v]) => !k.startsWith('RequiredDocument'))
          .map(([k, v]) => v);

        if (docConstraints.length > 0) {
          allErrors.push({
            target: error.target.constructor.name,
            property: error.property,
            constraints: docConstraints,
            type: ValidationType.Document,
          } as ValidationIssue);
        }

        if (fieldConstraints.length > 0) {
          allErrors.push({
            target: error.target.constructor.name,
            property: error.property,
            constraints: fieldConstraints,
            type: ValidationType.Other,
          } as ValidationIssue);
        }
      }
    });

    return allErrors;
  }

  static collectValidationErrors(entity): ValidationError[] {
    let validationErrors = [];

    if (Array.isArray(entity)) {
      entity.forEach(async (e) => {
        const errors = validateSync(e);
        const flattenedErrors = ValidationUtils.flattenErrors(errors);
        validationErrors = [...validationErrors, ...flattenedErrors];
      });
    } else {
      const errors = validateSync(entity);
      validationErrors = ValidationUtils.flattenErrors(errors);
    }
    return validationErrors;
  }

  static validateEntity<T>(cls: ClassConstructor<T>, entity, isUndefinedValid = false): ValidationIssue[] {
    let validationErrors = [];
    if (isUndefinedValid && !entity) {
      const err = {
        target: cls.name,
        property: cls.name,
        constraints: [`${cls.name} should be defined`],
        type: ValidationType.Other,
      } as ValidationIssue;
      validationErrors.push(err);
    }

    //first we need to ensure Client is proper client instance and not just a typed POJO
    const plainEntity = classToPlain(entity);
    const classedEntity = plainToClass(cls, plainEntity, { excludeExtraneousValues: true });

    validationErrors = ValidationUtils.collectValidationErrors(classedEntity);

    return validationErrors;
  }
}
