<div
  class="indian-registration-numbers"
  [formGroup]="formGroup"
  [ngClass]="{
    error:
      formGroup?.errors?.allFilled &&
      (formGroup?.controls.band.touched || formGroup?.controls.family.touched || formGroup?.controls.position.touched)
  }"
>
  <div class="registration-number control-margin">
    <common-form-control helperMessage="3 digits" controlName="band" label="Band #">
      <input type="text" inputmode="tel" maxlength="3" formControlName="band" data-cy="band" iesNumberOnly />
    </common-form-control>
  </div>
  <div class="registration-number control-margin">
    <common-form-control helperMessage="5 digits max" controlName="family" label="Family">
      <input type="text" inputmode="tel" maxlength="5" formControlName="family" data-cy="family" iesNumberOnly />
    </common-form-control>
  </div>
  <div class="registration-number">
    <common-form-control helperMessage="2 digits" controlName="position" label="Position">
      <input type="text" inputmode="tel" maxlength="2" formControlName="position" data-cy="position" iesNumberOnly />
    </common-form-control>
  </div>
</div>
<div
  class="error-text"
  *ngIf="
    formGroup?.errors?.allFilled &&
    (formGroup?.controls.band.touched || formGroup?.controls.family.touched || formGroup?.controls.position.touched)
  "
>
  All values must be entered.
</div>
