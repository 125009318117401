import { FinancialsLabels } from './financials-labels.interface';

export const FbParentsFinancialsLabels: FinancialsLabels = {
  hasNoIncome: {
    label: 'This parent/guardian does not have any income.',
    replacementLabel: '<<replacementLabel>> does not have any income.',
  },
  incomeNotProvided: {
    label: `The parent/guardian's income was not provided.`,
    replacementLabel: `<<replacementLabel>>'s income was not provided.`,
  },
  hasNoAssets: {
    label: 'This parent/guardian does not have any assets.',
    replacementLabel: '<<replacementLabel>> does not have any assets.',
  },
  assetsNotProvided: {
    label: `The parent/guardian's assets were not provided.`,
    replacementLabel: `<<replacementLabel>>'s assets were not provided.`,
  },
  // hasIncomeAssets: {
  //   label:
  //     'You have not shared any information for this parent. Does this parent have any of the above sources of income and assets?',
  //   replacementLabel:
  //     'You have not shared any information for <<replacementLabel>>. Does <<replacementLabel>> have any of the above sources of income and assets?',
  // },
  // canProvideBankStatement: {
  //   label: 'Are you able to provide bank accounts/statements?',
  //   replacementLabel: "Are you able to provide <<replacementLabel>>'s bank accounts/statements?",
  // },
  uploadBankStatementLabel: {
    label: "Upload this parent/guardian's financial documents",
    replacementLabel: "Upload <<replacementLabel>>'s financial documents",
  },
  knowBankLocation: {
    label: 'Can you provide this banking information?',
  },
  noIncomeAssets: {
    label: 'No information regarding the income or assets of the parent/guardian has been shared.',
    replacementLabel: 'No information regarding the income or assets of the <<replacementLabel>> has been shared.',
  },
};
