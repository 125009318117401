import { Lookup } from '../lookup.interface';
export const DenialReasons: { [name: string]: Lookup } = {
  assets: { displayValue: 'Assets', code: 'assets' },
  noSubstantiation: { displayValue: 'Failure to provide substantiation', code: 'noSubstantiation' },
  student: { displayValue: 'Full Time Student ', code: 'student' },
  immigration: { displayValue: 'Immigration Status', code: 'immigration' },
  income: { displayValue: 'Income', code: 'income' },
  justice: { displayValue: 'Justice/House Arrest', code: 'justice' },
  notLookingForWork: { displayValue: 'Not looking for work', code: 'notLookingForWork' },
  quitEmployment: { displayValue: 'Quit employment', code: 'quitEmployment' },
  programmingRefusal: {
    displayValue: 'Refusal to attend training/rehabilitative programming',
    code: 'programmingRefusal',
  },
  residence: { displayValue: 'Residence', code: 'residence' },
  spouseOnAish: { displayValue: 'Spouse on AISH', code: 'spouseOnAish' },
  other: { displayValue: 'Other', code: 'other' },
};
