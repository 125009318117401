import { Directive, Renderer2, ElementRef, OnInit, OnDestroy, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[iesGoaBanner]',
})
export class GoaBannerDirective implements OnInit, OnDestroy {
  @Input() largeBannerClass = 'goa-banner-lg';

  private routerSubscription: Subscription;

  constructor(private renderer: Renderer2, private el: ElementRef, private router: Router) {}

  ngOnInit(): void {
    if (this.router?.events) {
      this.routerSubscription = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url === '/login') {
            this.renderer.addClass(this.el.nativeElement, this.largeBannerClass);
          } else {
            this.renderer.removeClass(this.el.nativeElement, this.largeBannerClass);
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }
}
