import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FiChild } from '@fscd-intake/entities';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'fi-start-app-modal',
  templateUrl: './start-app-modal.component.html',
  styleUrls: ['./start-app-modal.component.scss'],
})
export class StartAppModalComponent implements OnInit {
  @Input() showModal: EventEmitter<boolean>;
  @Output() startNewApp = new EventEmitter<FiChild>();

  @ViewChild('startAppModal') startAppModalRef;

  formGroup = this.fb.group({
    firstName: ['', Validators.required],
    middleName: [''],
    lastName: ['', Validators.required],
  });

  constructor(private startAppModal: MatDialog, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.startAppModal.afterAllClosed.subscribe(() => {
      this.formGroup.reset();
    });

    this.showModal.subscribe((shouldShow) => {
      if (shouldShow) this.startAppModal.open(this.startAppModalRef);
      else this.startAppModal.closeAll();
    });
  }

  onAddChildContinue() {
    const newChild = new FiChild();
    newChild.firstName = this.formGroup.controls.firstName.value?.trim() ?? '';
    newChild.middleName = this.formGroup.controls.middleName.value?.trim() ?? '';
    newChild.lastName = this.formGroup.controls.lastName.value?.trim() ?? '';
    if (!newChild.firstName || !newChild.lastName) {
      this.formGroup.controls.firstName.markAsTouched();
      this.formGroup.controls.lastName.markAsTouched();
      return;
    }

    this.startAppModal.closeAll();
    this.startNewApp.emit(newChild);
  }

  onAddChildCancel() {
    this.startAppModal.closeAll();
  }
}
