<div class="form">
  <h2 data-cy="page-title">Is FSCD right for you and this child?</h2>
  <goa-callout type="information">
    Please provide us with some personal information to help determine
    <a href="https://www.alberta.ca/fscd-eligibility.aspx" target="_blank"> eligibility for FSCD</a>
  </goa-callout>

  <div [formGroup]="form">
    <div class="form-control question">
      <common-form-control [label]="fiLabels.birthdate | labelText" [control]="form.controls.birthdate">
        <common-date-picker
          formControlName="birthdate"
          data-cy="birthdate"
          [iesMaxDate]="_birthDateMax"
          [iesMinDate]="_birthDateMin"
          iesMinDateErrorMessage="This child must be under the age of 18 to apply for FSCD."
          [showErrorMessages]="true"
          required
        ></common-date-picker>
      </common-form-control>
    </div>
    <div class="info-callout" *ngIf="form?.controls?.birthdate?.value !== null && isBetween16and18()">
      <goa-callout type="important">
        This child is approaching the age of 18. To ensure a seamless transition to adult services, please
        <a href="https://www.alberta.ca/pdd-eligibility" target="_blank"> review this child’s eligibility for PDD</a>
        (Persons with Developmental Disabilities) in addition to FSCD.
      </goa-callout>
    </div>

    <div class="question">
      <common-fieldset-control [label]="fiLabels.isChildCitizenOrPermanentResident | labelText">
        <div
          [class.error]="
            form.controls.isChildCitizenOrPermanentResident.errors &&
            form.controls.isChildCitizenOrPermanentResident.touched
          "
        >
          <mat-radio-group color="primary" [formControl]="form.controls.isChildCitizenOrPermanentResident">
            <mat-radio-button data-cy="isChildCitizenOrPermanentResident-yes" [value]="true">Yes</mat-radio-button>
            <mat-radio-button data-cy="isChildCitizenOrPermanentResident-no" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="error-text"
          *ngIf="
            form?.controls?.isChildCitizenOrPermanentResident?.errors &&
            form?.controls?.isChildCitizenOrPermanentResident?.touched
          "
        >
          This field is required.
        </div>
      </common-fieldset-control>
    </div>

    <div class="question">
      <common-fieldset-control [label]="fiLabels.isResidentOfAlberta | labelText">
        <div [class.error]="form.controls.isResidentOfAlberta.errors && form.controls.isResidentOfAlberta.touched">
          <mat-radio-group color="primary" [formControl]="form.controls.isResidentOfAlberta">
            <mat-radio-button data-cy="isResidentOfAlberta-yes" [value]="true">Yes</mat-radio-button>
            <mat-radio-button data-cy="isResidentOfAlberta-no" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="error-text"
          *ngIf="form?.controls?.isResidentOfAlberta?.errors && form?.controls?.isResidentOfAlberta?.touched"
        >
          This field is required.
        </div>
      </common-fieldset-control>
    </div>

    <div class="question">
      <common-fieldset-control [label]="fiLabels.isParentOrGuardian | labelText">
        <div [class.error]="form.controls.isParentOrGuardian.errors && form.controls.isParentOrGuardian.touched">
          <mat-radio-group color="primary" [formControl]="form.controls.isParentOrGuardian">
            <mat-radio-button data-cy="isParentOrGuardian-yes" [value]="true">Yes</mat-radio-button>
            <mat-radio-button data-cy="isParentOrGuardian-no" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="error-text"
          *ngIf="form?.controls?.isParentOrGuardian?.errors && form?.controls?.isParentOrGuardian?.touched"
        >
          This field is required.
        </div>
        <goa-details
          [heading]="isScreenSmall() ? 'Click here for more info' : 'Click here to check parent/guardian information'"
        >
          <p>{{ fiLabels.isParentOrGuardian.helperText }}</p>
        </goa-details>
      </common-fieldset-control>
    </div>

    <div class="question">
      <common-fieldset-control [label]="fiLabels.hasConfirmedDiagnosis | labelText">
        <div [class.error]="form.controls.hasConfirmedDiagnosis.errors && form.controls.hasConfirmedDiagnosis.touched">
          <mat-radio-group color="primary" [formControl]="form.controls.hasConfirmedDiagnosis">
            <mat-radio-button data-cy="hasConfirmedDiagnosis-yes" [value]="true">Yes</mat-radio-button>
            <mat-radio-button data-cy="hasConfirmedDiagnosis-no" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="error-text"
          *ngIf="form?.controls?.hasConfirmedDiagnosis?.errors && form?.controls?.hasConfirmedDiagnosis?.touched"
        >
          This field is required.
        </div>
        <goa-details
          [heading]="
            isScreenSmall() ? 'Click here for more info' : 'Click here to verify the criteria for a confirmed diagnosis'
          "
        >
          <p>{{ fiLabels.hasConfirmedDiagnosis.helperText }}</p>
        </goa-details>
      </common-fieldset-control>
    </div>

    <div class="question">
      <common-fieldset-control [label]="fiLabels.isAwaitingDiagnosis | labelText">
        <div [class.error]="form.controls.isAwaitingDiagnosis.errors && form.controls.isAwaitingDiagnosis.touched">
          <mat-radio-group color="primary" [formControl]="form.controls.isAwaitingDiagnosis">
            <mat-radio-button data-cy="isAwaitingDiagnosis-yes" [value]="true">Yes</mat-radio-button>
            <mat-radio-button data-cy="isAwaitingDiagnosis-no" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="error-text"
          *ngIf="form?.controls?.isAwaitingDiagnosis?.errors && form?.controls?.isAwaitingDiagnosis?.touched"
        >
          This field is required.
        </div>
        <goa-details
          [heading]="
            isScreenSmall()
              ? 'Click here for more info'
              : 'Click here to review the meaning of waiting to receive a diagnosis'
          "
        >
          <p>{{ fiLabels.isAwaitingDiagnosis.helperText }}</p>
        </goa-details>
      </common-fieldset-control>
    </div>
    <div class="question apply-button">
      <goa-button data-cy="next" (_click)="evaluate()" [disabled]="!form.valid"> Save and continue</goa-button>
    </div>
  </div>
</div>
