<div class="main-container">
  <div class="btn-group" *ngIf="!user">
    <goa-button class="sign-in" (_click)="login()" data-cy="sign-in" type="primary">Sign in</goa-button>
    <div class="btn-description"><p>To continue or view your FSCD application.</p></div>
  </div>
  <div class="btn-group">
    <goa-button class="create-app" (_click)="navigateToPage(preScreenPage)" data-cy="create-application" type="primary">
      Create application</goa-button
    >
    <div class="btn-description">
      <p>To create an FSCD application, or to find out if FSCD is the right program for you.</p>
    </div>
  </div>
</div>
