import { registerDecorator, ValidationArguments } from 'class-validator';
import { Document } from '../entities/document.entity';

export function RequiredDocumentsIn(property: string) {
  return function (object, propertyName: string) {
    registerDecorator({
      name: `RequiredDocument - ${property}`,
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      validator: {
        validate(documents, args: ValidationArguments) {
          const [requiredDocCategory] = args.constraints;
          const docs = documents as Document[];
          if (docs?.find((d) => d.documentCategory === requiredDocCategory)) {
            return true;
          }
          return false;
        },
        defaultMessage(): string {
          return `Required document ${property} missing`;
        },
      },
    });
  };
}
