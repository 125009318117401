import { Lookup } from '../lookup.interface';

export enum ServiceCompleteOutcomeCodes {
  complete = 'complete',
  incomplete = 'incomplete',
  cancelled = 'cancelled',
}

export const ServiceCompleteOutcomes: { [name: string]: Lookup } = {
  complete: {
    code: ServiceCompleteOutcomeCodes.complete,
    displayValue: 'Complete',
  },
  incomplete: {
    code: ServiceCompleteOutcomeCodes.incomplete,
    displayValue: 'Incomplete',
  },
  cancelled: {
    code: ServiceCompleteOutcomeCodes.cancelled,
    displayValue: 'Cancelled',
  },
};
