import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { Directive } from '@angular/core';
function luhn(formattedNumber: string) {
  return (
    [...formattedNumber.replace(/\D/g, '')]
      .reverse()
      .map((e) => parseInt(e, 10))
      .map((e, i) => (i % 2 ? e * 2 - (e > 4 ? 9 : 0) : e))
      .reduce((ac, e) => ac + e) %
      10 ===
    0
  );
}
export function luhnValidator(): ValidatorFn {
  return (control: AbstractControl) =>
    !control.value || luhn(control.value) ? null : { invalidChecksum: control.value };
}

/**
 * @deprecated Use iesSinValidator or iesAhnValidator instead.
 */
@Directive({
  selector: '[iesLuhnValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: LuhnValidatorDirective,
      multi: true,
    },
  ],
})
export class LuhnValidatorDirective implements Validator {
  validate(control: AbstractControl) {
    return luhnValidator()(control);
  }
}
