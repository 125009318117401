import { Directive, ElementRef, Input } from '@angular/core';

/**
 * Adds a `data-cy` attribute to an element matching its `formControlName` attribute value.
 */
@Directive({
  selector: '[cyFormControlName]',
})
export class CyFormControlNameDirective {
  @Input() prefix = '';

  constructor(private el: ElementRef) {
    const element = this.el.nativeElement as HTMLElement;
    if (!element) {
      throw new Error('Failed to get element reference');
    }

    const ctrlName = element.attributes.getNamedItem('formControlName');
    if (!ctrlName) {
      throw new Error("Failed to get 'formControlName' attribute.");
    }

    element.setAttribute('data-cy', `${this.prefix}${ctrlName.value}`);
  }
}
