export const ServicesNeeded = [
  'Career Advising',
  'Computer Skills',
  'Employability Skills/Life Skills',
  'Employment Workshops',
  'Exposure Courses',
  'Interview Skills',
  'Job Search',
  'Resume/Cover Letters',
];
