import { Component, Input, TemplateRef } from '@angular/core';
import { CalendarEvent } from 'angular-calendar/modules/calendar.module';

@Component({
  selector: 'common-worker-calendar-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
})
export class WorkerCalendarListView {
  @Input() allOverdueEvents: CalendarEvent[];
  @Input() allUpcomingEvents: CalendarEvent[];
  @Input() overdueEvents0to7: CalendarEvent[];
  @Input() overdueEvents7to14: CalendarEvent[];
  @Input() overdueEvents14to30: CalendarEvent[];
  @Input() overdueEventsOther: CalendarEvent[];
  @Input() upcomingEventsToday: CalendarEvent[];
  @Input() upcomingEventsOther: CalendarEvent[];

  @Input() eventTemplate: TemplateRef<any>;
  @Input() isLoading = false;
  @Input() hasMore = false;

  @Input() overdueCount;
  @Input() upcomingCount;

  /** Optional template to show the group title on the open day */
  @Input() groupingTemplate?: TemplateRef<void>;
}
