import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CalendarEvent, CalendarNativeDateFormatter, DateFormatterParams } from 'angular-calendar';
import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  CalendarView,
  EventSchemeLookup,
  WorkerCalendarEvent,
  WorkerCalendarEventGrouping,
} from './worker-calendar.interface';
import { Pipe, PipeTransform } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Pipe({
  name: 'workerCalendarDayEvents',
  pure: true,
})
export class WorkerCalendarDayEventsPipe implements PipeTransform {
  transform(allEvents: CalendarEvent[], date: string, mapperFunction): CalendarEvent[] {
    //this pipe takes all events a date string and a mapper function and returns the events for a given day...
    //it would be better to use the calendar day.events... but that doesn't work with change detection and firest the pipe too much
    const events = allEvents?.filter((e) => {
      if (e.start && e.end)
        return dayjs(date).isSameOrAfter(e.start, 'day') && dayjs(date).isSameOrBefore(e.end, 'day');
      else if (e.start) return dayjs(date).isSame(e.start, 'day');
      else if (e.end) return dayjs(date).isSame(e.end, 'day');
    });

    if (!events || events.length === 0) return [];
    if (!mapperFunction) return events;

    return (
      mapperFunction(events.map((e) => e.meta))?.map((e) => {
        return {
          title: e.title,
          start: e.startDate,
          end: e.endDate,
          meta: e,
        };
      }) ?? []
    );
  }
}

@Pipe({
  name: 'workerCalendarDayGroupedEvents',
  pure: true,
})
export class WorkerCalendarDayGroupedEventsPipe implements PipeTransform {
  transform(allEvents: CalendarEvent[], date: Date, grouperFunction): WorkerCalendarEventGrouping<unknown>[] {
    //this pipe takes all events a date string and a mapper function and returns the events for a given day...
    //it would be better to use the calendar openTempalte events... but that doesn't work with change detection and firest the pipe too much
    const events = allEvents.filter((e) => {
      if (e.start && e.end) {
        return dayjs(date).isSameOrAfter(e.start, 'day') && dayjs(date).isSameOrBefore(e.end, 'day');
      } else if (e.start) {
        return dayjs(date).isSame(e.start, 'day');
      } else if (e.end) {
        return dayjs(date).isSame(e.end, 'day');
      }
    });

    if (!events || events.length === 0) return [];
    if (!grouperFunction) events.map((e) => ({ title: e.title, events: [e.meta] }));

    const grouped = grouperFunction(events.map((e) => e.meta)) ?? [];
    return grouped;
  }
}

@Injectable({ providedIn: 'root' })
export class ShortDayNameFormatter extends CalendarNativeDateFormatter {
  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date);
  }
}

const EXPANSION_PANEL_ANIMATION_TIMING = '225ms cubic-bezier(0.4,0.0,0.2,1)';

@Component({
  selector: 'common-worker-calendar',
  templateUrl: './worker-calendar.component.html',
  styleUrls: ['./worker-calendar.component.scss'],
  animations: [
    trigger('showHide', [
      state('show', style({ height: '*', visibility: 'visible', paddingTop: '*', paddingBottom: '*' })),
      state('hide', style({ height: '0', visibility: 'hidden', paddingTop: '0', paddingBottom: '0' })),
      transition('show => hide', [animate(EXPANSION_PANEL_ANIMATION_TIMING)]),
      transition('hide => show', [animate(EXPANSION_PANEL_ANIMATION_TIMING)]),
    ]),
    trigger('rotateExpander', [
      state('expanded', style({ transform: 'rotate(0)' })),
      state('collapsed', style({ transform: 'rotate(180deg)' })),
      transition('expanded => collapsed', [animate(EXPANSION_PANEL_ANIMATION_TIMING)]),
      transition('collapsed => expanded', [animate(EXPANSION_PANEL_ANIMATION_TIMING)]),
    ]),
  ],
})
export class WorkerCalendarComponent implements OnInit, OnDestroy, AfterViewInit {
  _activeDay: dayjs.Dayjs;
  private _destroy$ = new Subject<void>();
  _groupCollapseExpandState: { [key: string]: boolean } = {};

  //how many events can fit in a cell
  EVENTS_PER_DAY = 3;
  CalendarView = CalendarView;

  _currentMonthLabel;
  _previousMonthLabel;
  _nextMonthLabel;

  _showFilters = false;

  @Input() eventCategories: string[];
  @Input() eventSchemes: { [name: string]: EventSchemeLookup };

  _formGroup = this.formBuilder.group({ activeDay: [] });

  @Input() allOverdueEvents: CalendarEvent[];
  @Input() allUpcomingEvents: CalendarEvent[];
  @Input() overdueEvents0to7: CalendarEvent[];
  @Input() overdueEvents7to14: CalendarEvent[];
  @Input() overdueEvents14to30: CalendarEvent[];
  @Input() overdueEventsOther: CalendarEvent[];
  @Input() upcomingEventsToday: CalendarEvent[];
  @Input() upcomingEventsOther: CalendarEvent[];
  @Input() stickyHeader = true;

  /** Function called with a days events to allow consumers to change what is presented */
  @Input() daySummaryMapper: (eventsForDay: WorkerCalendarEvent<unknown>[]) => WorkerCalendarEvent<unknown>[];

  /**
   * The events to show
   */
  @Input() events: CalendarEvent[];

  /**
   * Day selected.  Also determines what month is showing
   */
  @Input() set activeDay(value: Date) {
    this._activeDay = dayjs(value);
    this._formGroup.get('activeDay').setValue(value, { emitEvent: false });
    this._currentMonthLabel = this._activeDay.format('MMMM');
    this._previousMonthLabel = this._activeDay.add(-1, 'month').format('MMMM');
    this._nextMonthLabel = this._activeDay.add(1, 'month').format('MMMM');
  }
  get activeDay(): Date {
    return this._activeDay.toDate();
  }

  /**
   * Display loading indicator
   */
  @Input() isLoading = false;

  /**
   * Was the limit of events reached (paging)
   */
  @Input() hasMore = false;

  /**
   * Allow new events in the past
   */
  @Input() allowEventsInPast = false;

  /**
   * Hide the legend
   */
  @Input() hideLegend = true;

  /**
   * Hide the filters
   */
  @Input() hideFilters = true;

  /**
   * Show month/week/or day view
   */
  @Input() calendarView: CalendarView = CalendarView.Month;

  /** Optional function to group events on the open day */
  @Input() groupingFunction?: (events: WorkerCalendarEvent<unknown>[]) => WorkerCalendarEventGrouping<unknown>[];
  /** Optional template to show the group title on the open day */
  @Input() groupTitleTemplate?: TemplateRef<WorkerCalendarEventGrouping<unknown>>;

  /** Server accurate counts for list view */
  @Input() overdueCount;
  @Input() upcomingCount;
  @Input() set showFilters(value: boolean) {
    this._showFilters = value;
  }

  @Output() eventEdited = new EventEmitter<CalendarEvent>();
  @Output() eventAdded = new EventEmitter<Date>();
  @Output() eventDeleted = new EventEmitter<CalendarEvent>();
  @Output() activeDayChanged = new EventEmitter<Date>();
  @Output() calendarViewChanged = new EventEmitter<CalendarView>();

  constructor(private formBuilder: UntypedFormBuilder, private cdr: ChangeDetectorRef) {
    this.activeDay = new Date();
  }

  ngOnInit() {
    this._formGroup
      .get('activeDay')
      .valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.updateActiveDayAndEmit(value);
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngAfterViewInit() {
    const days = document.getElementsByClassName('day');
    const EVENT_HEIGHT = 22; //from SCSS.. can we do dynamic?  We don't have data loaded yet to query
    if (days && days.length > 0) {
      this.EVENTS_PER_DAY = Math.floor(days[0].clientHeight / EVENT_HEIGHT) - 1;
      this.cdr.detectChanges();
    }
  }

  setCalendarView(view: CalendarView) {
    this.calendarView = view;
    this.calendarViewChanged.emit(view);
  }

  goToToday() {
    this.updateActiveDayAndEmit(new Date());
  }

  isInPast(date: Date) {
    return dayjs(date).isBefore(new Date(), 'day');
  }

  onDayClicked(day: Date) {
    this.updateActiveDayAndEmit(day);
  }

  isDayActive(day) {
    return this._activeDay.isSame(day.date, 'day');
  }

  previousMonthClick() {
    switch (this.calendarView) {
      case CalendarView.Week:
        this.updateActiveDayAndEmit(this._activeDay.add(-1, 'week').toDate());
        break;
      case CalendarView.Month:
        this.updateActiveDayAndEmit(this._activeDay.add(-1, 'month').toDate());
        break;
      case CalendarView.Day:
        this.updateActiveDayAndEmit(this._activeDay.add(-1, 'day').toDate());
        break;
    }
  }

  nextMonthClick() {
    switch (this.calendarView) {
      case CalendarView.Week:
        this.updateActiveDayAndEmit(this._activeDay.add(1, 'week').toDate());
        break;
      case CalendarView.Month:
        this.updateActiveDayAndEmit(this._activeDay.add(1, 'month').toDate());
        break;
      case CalendarView.Day:
        this.updateActiveDayAndEmit(this._activeDay.add(1, 'day').toDate());
        break;
    }
  }

  addEventClick() {
    this.eventAdded.emit(this._activeDay.toDate());
  }

  eventClick(event) {
    this.eventEdited.emit(event.meta);
  }

  groupExpandCollapseClick(group, date) {
    if (this._groupCollapseExpandState[group._id + date] ?? group.isExpanded) {
      this._groupCollapseExpandState[group._id + date] = false;
      group.isExpanded = false;
    } else {
      this._groupCollapseExpandState[group._id + date] = true;
      group.isExpanded = true;
    }
  }

  private updateActiveDayAndEmit(activeDay: Date) {
    this.activeDay = activeDay;
    this.activeDayChanged.emit(this.activeDay);
  }
}
