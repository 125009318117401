<div *ngIf="application?.isSubmitted">
  <fi-submitted [application]="application"></fi-submitted>
</div>

<div class="stepperContainer"></div>
<div class="stepper-section-top"></div>
<div
  [ngClass]="{
    sticky: makeSticky,
    'stepper-section': true
  }"
>
  <div class="stepperContainer" *ngIf="!application?.isSubmitted">
    <div>
      <mat-horizontal-stepper
        #horizontalStepper
        labelPosition="bottom"
        [selectedIndex]="selectedStepIndex"
        [disableRipple]="true"
        data-cy="stepper"
        aria-busy="true"
        (selectionChange)="onStepperIndexChange($event)"
      >
        <mat-step
          *ngFor="let step of activeSteps"
          data-cy="`step-${step.name}`"
          [label]="step.name"
          [hasError]="step.hasError"
          errorMessage="Attention required"
          [state]="step.state"
        >
        </mat-step>
        <ng-template matStepperIcon="notVisited" let-index="index">
          {{ index + 1 }}
        </ng-template>
        <ng-template matStepperIcon="error" let-index="index">
          <mat-icon style="width: 40px; height: 40px" svgIcon="stepper-error"></mat-icon>
        </ng-template>
        <ng-template matStepperIcon="edit" let-index="index"><mat-icon>create</mat-icon></ng-template>
        <ng-template matStepperIcon="done" let-index="index"
          ><mat-icon style="width: 24px; height: 24px" svgIcon="stepper-complete"></mat-icon
        ></ng-template>
      </mat-horizontal-stepper>
    </div>
    <a (click)="toggleStepperExpanded()" class="expansion-button" *ngIf="makeSticky"
      ><mat-icon>unfold_more</mat-icon></a
    >
    <div class="stepper-expansion-panel">
      <mat-vertical-stepper
        #verticalStepper
        [ngClass]="{ 'show-stepper': orientation === 'vertical' }"
        labelPosition="bottom"
        [selectedIndex]="selectedStepIndex"
        [disableRipple]="true"
        (selectionChange)="onStepperIndexChange($event)"
      >
        <mat-step
          *ngFor="let step of activeSteps"
          data-cy="`step-${step.code}`"
          [label]="step.name"
          [hasError]="step.hasError"
          errorMessage="Attention required"
          [state]="step.state"
        >
        </mat-step>
        <ng-template matStepperIcon="notVisited" let-index="index">
          {{ index + 1 }}
        </ng-template>
        <ng-template matStepperIcon="error" let-index="index">
          <mat-icon style="width: 40px; height: 40px" svgIcon="stepper-error"></mat-icon>
        </ng-template>
        <ng-template matStepperIcon="edit" let-index="index"><mat-icon>create</mat-icon></ng-template>
        <ng-template matStepperIcon="done" let-index="index"
          ><mat-icon style="width: 24px; height: 24px" svgIcon="stepper-complete"></mat-icon
        ></ng-template>
      </mat-vertical-stepper>
    </div>
  </div>
</div>

<div *ngIf="!application?.isSubmitted" class="form-section">
  <router-outlet></router-outlet>
  <div
    *ngIf="application && !application?.isSubmitted"
    class="button-footer"
    [ngClass]="isSingleButtonVisible ? 'single-button' : 'remove-single-button'"
  >
    <goa-button
      class="apply-button"
      data-cy="back"
      (_click)="back()"
      *ngIf="selectedStepIndex > 0"
      [attr.disabled]="isSubmitting"
      type="secondary"
    >
      Back
    </goa-button>
    <goa-button
      class="apply-button"
      data-cy="next"
      (_click)="next()"
      *ngIf="selectedStepIndex < activeSteps.length - 1"
      type="primary"
    >
      Save and continue
    </goa-button>
    <goa-button
      class="apply-button"
      data-cy="next"
      (_click)="submit()"
      *ngIf="selectedStepIndex === activeSteps.length - 1"
      [attr.disabled]="disableSubmit || isSubmitting"
      type="primary"
    >
      Submit
    </goa-button>
  </div>
</div>

<fi-developer-tools *ngIf="showDeveloperTools"></fi-developer-tools>
