/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input } from '@angular/core';
import { FbApplication } from '@fb-intake/entities';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'fi-export-application-data',
  templateUrl: './export-application-data.component.html',
  styleUrls: ['./export-application-data.component.scss'],
})
export class ExportApplicationDataComponent {
  @Input()
  private application$: BehaviorSubject<FbApplication>;

  constructor(private authService: AuthenticationService) {}

  async exportApplicationData() {
    this.application$
      .subscribe((data) => {
        const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
        const fileUrl = URL.createObjectURL(blob);
        window.open(fileUrl, '_blank');
      })
      .unsubscribe();
  }
}
