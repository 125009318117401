import { Lookup } from './lookup.interface';
export interface PhoneNumberTypeLookup extends Lookup {
  edbId: string;
}
export const PhoneNumberTypes: { [name: string]: PhoneNumberTypeLookup } = {
  Mobile: { displayValue: 'Mobile', edbId: '4' },
  Home: { displayValue: 'Home', edbId: '3' },
  Work: { displayValue: 'Work', edbId: '3' },
  Other: { displayValue: 'Other', edbId: '15' },
};
