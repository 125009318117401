<div
  *ngFor="let file of files; last as isLast; let i = index"
  class="file-container"
  [ngClass]="{ 'not-last-row': !isLast }"
>
  <div class="metadata-container" [class]="file.errors ? 'file-error' : ''">
    <div class="document-type">{{ file.documentType || categoryDisplay }}</div>
    <div class="file-name" [attr.data-cy]="file.name + '-' + i.toString()">{{ file.name }}</div>
    <div
      class="preview-button material-icons"
      [class]="file.errors ? 'error' : ''"
      (click)="onView(file.key)"
      iesIconButton
      [matTooltip]="tooltipMessage"
      [matTooltipPosition]="tooltipPosition"
      data-cy="preview"
      [attr.aria-label]="ariaLabelForPreviewIcon"
      *ngIf="!file.errors && file.completed"
    >
      visibility
    </div>
    <div class="preview-button material-icons hidden" *ngIf="!(!file.errors && file.completed)">visibility</div>
    <div class="spacer"></div>
    <div (click)="onRemoveFile(file)" class="remove-button" data-cy="remove" aria-label="Remove File" iesIconButton>
      <span class="remove-icon material-icons" aria-hidden="true"> highlight_off </span>
      <span class="remove-text">Remove</span>
    </div>
    <!-- <div class="remove-button hidden" *ngIf="!(!file.errors && file.completed)">
      <span class="remove-icon material-icons" aria-hidden="true"> highlight_off </span>
      <span class="remove-text">Remove</span>
    </div> -->
  </div>

  <!-- <div *ngIf="showProgressBar && !file.errors && file.progress" class="progress-container">
    <div class="progress" [style.width]="file.progress + '%'"></div>
  </div> -->

  <div class="error-text" *ngIf="file?.errors?.upload">
    <ng-container>Document Upload Failed</ng-container>
  </div>
</div>
