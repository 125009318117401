import { Lookup } from './lookup.interface';

export interface ImmigrationTypesLookup extends Lookup {
  typeSponsored?: boolean;
  descriptionRequired?: boolean;
}

export const ImmigrationTypes: {
  [name: string]: ImmigrationTypesLookup;
} = {
  canadian: { code: 'canadian', displayValue: 'Canadian citizen', typeSponsored: false, descriptionRequired: false },
  immigrant: { code: 'immigrant', displayValue: 'Immigrant sponsored', typeSponsored: true, descriptionRequired: false  },
  permenantResident: { code: 'permenantResident', displayValue: 'Permanent resident of Canada', typeSponsored: false, descriptionRequired: false  },
  other: { code: 'other', displayValue: 'Other', typeSponsored: false, descriptionRequired: true  },
};
