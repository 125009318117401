import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  Validators,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SELECT_ONE_TEXT } from '@common/constants';
@Component({
  selector: 'common-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DatePickerComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DatePickerComponent,
    },
  ],
})
export class DatePickerComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  @Input() showErrorMessages = true;
  @Input() hideDay = false;
  @Input() dataCyPrefix = '';
  @Input() allowDefaultSelection = false;
  @Input() defaultSelectionLabel = SELECT_ONE_TEXT;
  @ViewChild('yearControl') yearInput: ElementRef;

  _months = [];
  formGroup = this.fb.group({
    day: this.fb.control(null, { updateOn: 'blur', validators: [Validators.min(1), Validators.max(31)] }),
    month: this.fb.control(''),
    year: this.fb.control(null, { updateOn: 'blur' }),
  });
  private touched;
  private changed;
  private destroy$ = new Subject<void>();
  constructor(private fb: UntypedFormBuilder) {
    // if (this.ngControl) this.ngControl.valueAccessor = this;
    for (let i = 0; i < 12; i++) this._months.push(this.createMonth(i));
  }
  ngOnInit(): void {
    this.formGroup.controls.month.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      // this is only for case management employment page. when select Current Employer, need to clear
      if (this.defaultSelectionLabel === 'Current Employer' && !value) this.formGroup.controls.year.setValue(null);
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.setFormValidators(value);

      if (this.formGroup.valid) {
        // this.dateFormControl.setErrors({ datePicker: true });
        // this.changed();
        if (value.year && value.month && value.day)
          this.changeValue(new Date(Date.UTC(value.year, value.month - 1, value.day, 11)));
        else this.changeValue(null);
      } else this.changeValue(null);
      this.markAsTouched();
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(obj: Date | string | null): void {
    let formValue;
    if (obj) {
      const value = (typeof obj === 'string' && new Date(obj)) || (obj as Date);
      formValue = {
        day: this.hideDay ? 1 : value.getUTCDate(),
        month: value.getUTCMonth() + 1,
        year: value.getUTCFullYear(),
      };
    } else formValue = { day: this.hideDay ? '1' : null, month: '', year: null };

    this.formGroup.setValue(formValue, { emitEvent: false });
    this.setFormValidators(formValue);
    this.formGroup.markAsUntouched();
    this.isTouched = false;
  }

  registerOnChange(fn): void {
    this.changed = fn;
  }
  registerOnTouched(fn): void {
    this.touched = fn;
  }
  onBlur() {
    this.markAsTouched();
  }

  private isTouched = false;
  private markAsTouched() {
    if (!this.isTouched) {
      this.touched && this.touched();
      this.isTouched = true;
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable({ emitEvent: false }) : this.formGroup.enable({ emitEvent: false });
  }
  //control: AbstractControl
  validate(): ValidationErrors | null {
    return this.formGroup.valid ? null : { datePicker: true };
  }

  private getMaxDays() {
    //grab the year from the actual control as the formgroup value won't be updated until the field is blurred and is then unreliable here.
    let year = Number.parseInt(this.yearInput?.nativeElement?.value);
    //if year is NaN use 2012 (leap year)
    //if year is not 4 digits use 2012 (leap year)
    if (Number.isNaN(year) || year < 1000) year = 2012;
    return new Date(year, this.formGroup.value.month || 1, 0).getDate();
  }

  private setFormValidators(dateValue) {
    const maxDays = this.getMaxDays();

    if (dateValue?.year || dateValue?.month || (!this.hideDay && dateValue?.day)) {
      this.formGroup.controls.year.setValidators([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]);
      this.monthControl.setValidators([Validators.required]);
      this.dayControl.setValidators([Validators.required, Validators.min(1), Validators.max(maxDays)]);
    } else {
      this.formGroup.controls.year.setValidators([Validators.minLength(4), Validators.maxLength(4)]);
      this.monthControl.setValidators([]);
      this.dayControl.setValidators([Validators.min(1), Validators.max(maxDays)]);
    }
    this.dayControl.updateValueAndValidity({ emitEvent: false });
    this.monthControl.updateValueAndValidity({ emitEvent: false });
    this.formGroup.controls.year.updateValueAndValidity({ emitEvent: false });
  }

  private changeValue(newValue: Date | null) {
    this.changed && this.changed(newValue);
  }

  private createMonth(month: number) {
    return { label: new Date(2020, month, 1).toLocaleString('en-CA', { month: 'long' }), value: month + 1 };
  }

  get monthControl() {
    return this.formGroup.controls.month;
  }
  get dayControl() {
    return this.formGroup.controls.day;
  }
}
