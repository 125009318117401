import { Lookup } from '../lookup.interface';
export const FileRestrictionReasons: { [name: string]: Lookup } = {
  goaMember: {
    displayValue: 'Individual or their family member works for GOA',
    code: 'goaMember',
  },
  workingForProvider: {
    displayValue: 'Individual or their family member works for a Provider',
    code: 'workingForProvider',
  },
  individualRequest: {
    displayValue: 'Individual request',
    code: 'individualRequest',
  },
  workerRequest: {
    displayValue: 'Worker request',
    code: 'workerRequest',
  },
  escapingViolence: {
    displayValue: 'Individual escaping abuse/family violence',
    code: 'escapingViolence',
  },
};
