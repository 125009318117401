<!-- <div class="my-account-wrapper" *ngIf="(profile$ | async) as profile"> -->
<div class="my-account-wrapper" *ngIf="profile">
  <div class="message intakeApplicationSaveMessage" [class]="!showMessage ? 'hidden' : ''">
    <div class="checkbox-wrapper my-account-item">
      <span class="material-icons-outlined"> check_circle </span>
    </div>
    <div class="my-account-item text">All Changes Saved</div>
  </div>

  <div class="profile-wrapper" *ngIf="profile.name">
    <div class="my-account-item" [class]="profile.primaryRole ? 'display-role' : ''">
      <div
        class="my-account-item button-wrapper"
        *ngIf="isApplicationPage && !isApplicationSubmitted"
        (click)="$event.stopPropagation()"
      >
        <goa-button
          (_click)="saveCurrentApplication()"
          id="intakeApplicationSaveButton"
          [attr.class]="isExpanded ? 'menu-expanded' : 'menu-collapsed'"
          type="secondary"
          data-cy="my-account-saveButton"
          tabindex="0"
        >
          Save
        </goa-button>
      </div>

      <mat-accordion class="profile-accordion">
        <mat-expansion-panel #panel (opened)="expand(true)" (closed)="expand(false)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="material-icons-outlined account-circle">account_circle</div>
              <div class="display-name" data-cy="display-name">
                {{ profile.name }}
                <span *ngIf="profile.primaryRole">
                  <br />
                  <span class="my-role">{{ profile.primaryRole }}</span>
                </span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="menuContainer" [class]="isExpanded ? 'expanded' : 'collapsed'">
            <p class="menuItem" (click)="navigateToPage('/dash')">Homepage</p>
            <p
              class="menuItem"
              (click)="navigateToPage('/admin')"
              *ngIf="profile.roles.includes(CssRoles.CSSAdmin.code)"
            >
              Admin
            </p>
            <p class="menuItem" (click)="changePassword()">Change Password</p>
            <p class="menuItem last" (click)="logout()">Sign Out</p>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>

<div class="my-account-wrapper" *ngIf="!profile">
  <div class="my-account-item button-wrapper sign-in">
    <goa-button (_click)="login('/dash')" id="applicationHeaderLogin" type="primary">Sign in</goa-button>
  </div>
</div>
