import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[iesBlockCopyCutPaste]',
})
export class BlockCopyCutPasteDirective {
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    //console.log('paste');
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    //console.log('copy');
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    //console.log('cut');
    e.preventDefault();
  }
}
