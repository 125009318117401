<ng-template #startAppModal>
  <div [formGroup]="formGroup" class="start-app-modal-component">
    <h1>Child Information: Please provide the name of a child you have not yet created an application for</h1>
    <p>Please provide your child's full name as it appears in medical documents and other government issued ID.</p>

    <div class="form-field">
      <common-form-control label="Child’s First Name" [control]="formGroup.controls.firstName">
        <input type="text" data-cy="firstName" autocomplete="off" formControlName="firstName" />
      </common-form-control>
    </div>

    <div class="form-field">
      <common-form-control label="Child’s Middle Name" [control]="formGroup.controls.middleName">
        <input type="text" data-cy="middleName" autocomplete="off" formControlName="middleName" />
      </common-form-control>
    </div>

    <div class="form-field">
      <common-form-control label="Child’s Last Name" [control]="formGroup.controls.lastName">
        <input type="text" data-cy="lastName" autocomplete="off" formControlName="lastName" />
      </common-form-control>
    </div>

    <div class="button-footer">
      <span class="button"><goa-button (_click)="onAddChildContinue()" data-cy="continue">Continue</goa-button></span>
      <span class="button"
        ><goa-button type="secondary" (_click)="onAddChildCancel()" data-cy="cancel">Cancel</goa-button></span
      >
    </div>
  </div>
</ng-template>
