<div *ngIf="groupedDocuments; else noDocuments">
  <div class="row">
    <div class="col-4">
      <h3><ng-content></ng-content></h3>
    </div>
  </div>
  <div>
    <div *ngFor="let group of groupedDocuments">
      <div class="row" *ngIf="showInGroups">
        <div class="col-4 bold" [innerHTML]="getDoclistLabel(group, categoryLabel)"></div>
      </div>
      <div class="row" *ngIf="group.optOutLabel">* {{ group.optOutLabel }}</div>
      <div class="row" *ngIf="showColumnHeadings">
        <div class="header bold">Document Type</div>
        <div class="header bold">Title</div>
      </div>
      <div class="row documentContainer" *ngFor="let document of group.documents; let i = index">
        <div class="document-type">
          {{ document.documentType || formatDocumentCategory(document.documentCategory) }}
        </div>
        <div class="file-name no-overflow">{{ document.name }}</div>
        <div class="col-1">
          <button
            matTooltip="Display file in new window"
            matTooltipPosition="right"
            class="preview material-icons"
            (click)="onView(document.key)"
            [attr.data-cy]="'preview_' + i"
            aria-label="“Display document"
          >
            <span aria-hidden="true" class="material-icons">visibility</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noDocuments>
  <div class="row">
    <div class="col-4">
      <h3>Documents</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-4">No documents available.</div>
  </div>
</ng-template>
