import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'fi-knockout-page',
  templateUrl: './knockout-page.component.html',
  styleUrls: ['./knockout-page.component.scss'],
})
export class KnockoutPageComponent {
  contactEmail = 'css.ascc@gov.ab.ca';
  contactPhone = '1-877-644-9992';

  constructor(private router: Router) {}

  returnToPrescreenPage() {
    this.router.navigateByUrl('prescreen');
  }

  navToFscdHome() {
    window.location.href = 'https://www.alberta.ca/fscd-how-to-apply.aspx';
  }
}
