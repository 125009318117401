<div class="app">
  <div class="app-left">
    <div class="app-field" data-cy="firstName">
      <h2>{{ labels.firstName | labelText }}</h2>
      <p>{{ application?.parent?.firstName || '-' }}</p>
    </div>
    <div class="app-field" data-cy="middleName">
      <h2>{{ labels.middleName | labelText }}</h2>
      <p>{{ application?.parent?.middleName || '-' }}</p>
    </div>
    <div class="app-field" data-cy="lastName">
      <h2>{{ labels.lastName | labelText }}</h2>
      <p>{{ application?.parent?.lastName || '-' }}</p>
    </div>
    <div class="app-field" data-cy="preferredName">
      <h2>{{ labels.preferredName | labelText }}</h2>
      <p>{{ application?.parent?.preferredName || '-' }}</p>
    </div>
    <div class="app-field" data-cy="birthdate">
      <h2>{{ labels.birthdate | labelText }}</h2>
      <p>{{ application?.parent?.birthdate | date: 'longDate' }}</p>
    </div>
    <div class="app-field" data-cy="gender">
      <h2>{{ labels.gender | labelText }}</h2>
      <p>{{ genderList[application?.parent?.gender]?.displayValue || '-' }}</p>
    </div>
    <div class="app-field" data-cy="isEnglishFirstLanguage">
      <h2>{{ labels.isEnglishFirstLanguage | labelText }}</h2>
      <p>{{ utility.displayBoolean(application?.parent?.isEnglishFirstLanguage) || '-' }}</p>
    </div>
    <div *ngIf="!application?.parent?.isEnglishFirstLanguage">
      <div class="app-field left-indent" data-cy="primaryLanguage">
        <h2>{{ labels.primaryLanguage | labelText }}</h2>
        <p>{{ application?.parent?.primaryLanguage || '-' }}</p>
      </div>
      <div class="app-field left-indent" data-cy="requiresInterpreter">
        <h2>{{ labels.requiresInterpreter | labelText }}</h2>
        <p>{{ utility.displayBoolean(application?.parent?.requiresInterpreter) || '-' }}</p>
      </div>
    </div>
    <div class="app-field" data-cy="haveOtherCommunicationNeed">
      <h2>{{ labels.haveOtherCommunicationNeed | labelText }}</h2>
      <p>{{ utility.displayBoolean(application?.parent?.haveOtherCommunicationNeed) || '-' }}</p>
    </div>
    <div
      class="app-field left-indent"
      data-cy="otherCommunicationNeed"
      *ngIf="application?.parent?.haveOtherCommunicationNeed"
      data-cy="otherCommunicationNeed"
    >
      <h2>{{ labels.otherCommunicationNeed | labelText }}</h2>
      <p>{{ application?.parent?.otherCommunicationNeed || '-' }}</p>
    </div>
  </div>
  <!-- END class="app-left" -->

  <div class="app-right">
    <!-- phone number(s) -->
    <common-displayfield-control class="app-field" [label]="labels.phoneNumber | labelText">
      <span *ngFor="let ph of application?.parent?.phoneNumbers || []; let i = index">
        <div [attr.data-cy]="'phoneNumber-' + i" *ngIf="ph.phoneNumber?.length > 0">
          {{ (ph.phoneNumber | phoneNumber) + ' - ' + ph.type || '-' }}
        </div>
        <!--By default there is 1 ph number control in ph numbers array-->
        <div *ngIf="applicant?.phoneNumbers?.length === 1 && ph.phoneNumber.length === 0">{{ '-' }}</div>
      </span>
    </common-displayfield-control>
    <div class="app-field" data-cy="email">
      <h2>{{ labels.email | labelText }}</h2>
      <p>{{ application?.parent?.email || '-' }}</p>
    </div>
    <div class="app-field" data-cy="hasSameAddressAsChild">
      <h2>{{ labels.hasSameAddressAsChild | labelText }}</h2>
      <p>{{ utility.displayBoolean(application?.parent?.hasSameAddressAsChild) || '-' }}</p>
    </div>
    <div class="ml-60" *ngIf="!application?.parent?.hasSameAddressAsChild">
      <div class="app-field" data-cy="streetAddress">
        <h2>{{ labels.street | labelText }}</h2>
        <p>{{ application?.parent?.residentialAddress?.streetAddress || '-' }}</p>
      </div>
      <div class="app-field" data-cy="suiteNumber">
        <h2>{{ labels.suite | labelText }}</h2>
        <p>{{ application?.parent?.residentialAddress?.suiteNumber || '-' }}</p>
      </div>
      <div class="app-field" data-cy="city">
        <h2>{{ labels.city | labelText }}</h2>
        <p>{{ application?.parent?.residentialAddress?.city || '-' }}</p>
      </div>
      <div class="app-field-container-horizontal">
        <div class="app-field" data-cy="province">
          <h2>{{ labels.province | labelText }}</h2>
          <p>{{ application?.parent?.residentialAddress?.province || '-' }}</p>
        </div>
        <div class="app-field" data-cy="postalCode">
          <h2>{{ labels.postalCode | labelText }}</h2>
          <p>{{ (application?.parent?.residentialAddress?.postalCode | postalCode) || '-' }}</p>
        </div>
      </div>
    </div>
    <div class="app-field" data-cy="relationToChild">
      <h2>{{ labels.relationToChild | labelText }}</h2>
      <p>{{ relationList[application?.parent?.relationToChild]?.displayValue || '-' }}</p>
    </div>
    <div class="app-field" data-cy="learnAboutFscd">
      <h2>{{ labels.learnAboutFscd | labelText }}</h2>
      <p>{{ getLearnAboutFscdValues(application?.parent) || '-' }}</p>
      <ng-template [ngIf]="application?.parent?.learnAboutFscdOther">
        <p>{{ getLearnAboutFscdOtherValue(application?.parent) }}</p>
      </ng-template>
    </div>
  </div>
  <!-- END class="app-right" -->
</div>
<!-- END class="app" -->

<div class="app-docs mt-30" *ngIf="docArr.length > 0">
  <h1>{{ application?.parent?.firstName || 'parent' }}'s documents</h1>
  <p class="mt-24 mb-15"><strong>Confirmation of guardian-child relationship</strong></p>
  <div class="identity-table">
    <div *ngFor="let doc of docArr" class="row-container">
      <p *ngIf="!doc.isView">{{ doc.str }}</p>
      <a *ngIf="doc.isView" class="row-view" (click)="onClickRowView(doc.doc)">
        <fi-eye-icon height="22"></fi-eye-icon>
        <p>View</p>
      </a>
    </div>
  </div>
</div>
<!-- END class="app-docs" -->

<!-- START second parent-->
<div *ngIf="application?.parent?.hasSecondParent" class="second-parent">
  <h1>{{ labels.secondParentheader | labelText }}</h1>
  <div class="app">
    <div class="app-left">
      <div class="app-field" data-cy="firstName">
        <h2>{{ labels.firstName | labelText }}</h2>
        <p>{{ secondParent?.firstName || '-' }}</p>
      </div>
      <div class="app-field" data-cy="middleName">
        <h2>{{ labels.middleName | labelText }}</h2>
        <p>{{ secondParent?.middleName || '-' }}</p>
      </div>
      <div class="app-field" data-cy="lastName">
        <h2>{{ labels.lastName | labelText }}</h2>
        <p>{{ secondParent?.lastName || '-' }}</p>
      </div>
      <div class="app-field" data-cy="preferredName">
        <h2>{{ labels.preferredName | labelText }}</h2>
        <p>{{ secondParent?.preferredName || '-' }}</p>
      </div>
      <div class="app-field" data-cy="birthdate">
        <h2>{{ labels.birthdate | labelText }}</h2>
        <p>{{ secondParent?.birthdate | date: 'longDate' }}</p>
      </div>
      <div class="app-field" data-cy="gender">
        <h2>{{ labels.gender | labelText }}</h2>
        <p>{{ genderList[secondParent?.gender]?.displayValue || '-' }}</p>
      </div>

      <div class="app-field" data-cy="isEnglishFirstLanguage">
        <h2>{{ labels.isEnglishFirstLanguage | labelText }}</h2>
        <p>{{ utility.displayBoolean(secondParent?.isEnglishFirstLanguage) || '-' }}</p>
      </div>
      <div *ngIf="!secondParent?.isEnglishFirstLanguage">
        <div class="app-field left-indent" data-cy="primaryLanguage">
          <h2>{{ labels.primaryLanguage | labelText }}</h2>
          <p>{{ secondParent?.primaryLanguage || '-' }}</p>
        </div>
        <div class="app-field left-indent" data-cy="requiresInterpreter">
          <h2>{{ labels.requiresInterpreter | labelText }}</h2>
          <p>{{ utility.displayBoolean(secondParent?.requiresInterpreter) || '-' }}</p>
        </div>
      </div>
      <div class="app-field" data-cy="haveOtherCommunicationNeed">
        <h2>{{ labels.haveOtherCommunicationNeed | labelText }}</h2>
        <p>{{ utility.displayBoolean(secondParent?.haveOtherCommunicationNeed) || '-' }}</p>
      </div>
      <div
        class="app-field left-indent"
        data-cy="otherCommunicationNeed"
        *ngIf="secondParent?.haveOtherCommunicationNeed"
        data-cy="otherCommunicationNeed"
      >
        <h2>{{ labels.otherCommunicationNeed | labelText }}</h2>
        <p>{{ secondParent?.otherCommunicationNeed || '-' }}</p>
      </div>
    </div>
    <!-- END class="app-left" -->

    <div class="app-right">
      <!-- phone number(s) -->
      <common-displayfield-control class="app-field" [label]="labels.phoneNumber | labelText">
        <span *ngFor="let ph of secondParent?.phoneNumbers || []; let i = index">
          <div [attr.data-cy]="'phoneNumber-' + i" *ngIf="ph.phoneNumber?.length > 0">
            {{ (ph.phoneNumber | phoneNumber) + ' - ' + ph.type || '-' }}
          </div>
        </span>
      </common-displayfield-control>
      <div class="app-field" data-cy="email">
        <h2>{{ labels.email | labelText }}</h2>
        <p>{{ secondParent?.email || '-' }}</p>
      </div>
      <div class="app-field" data-cy="hasSameAddressAsChild">
        <h2>{{ labels.hasSameAddressAsChild | labelText }}</h2>
        <p>{{ utility.displayBoolean(secondParent?.hasSameAddressAsChild) || '-' }}</p>
      </div>
      <div class="ml-60" *ngIf="!application?.parent?.hasSameAddressAsChild">
        <div class="app-field" data-cy="streetAddress">
          <h2>{{ labels.street | labelText }}</h2>
          <p>{{ secondParent?.residentialAddress?.streetAddress || '-' }}</p>
        </div>
        <div class="app-field" data-cy="suiteNumber">
          <h2>{{ labels.suite | labelText }}</h2>
          <p>{{ secondParent?.residentialAddress?.suiteNumber || '-' }}</p>
        </div>
        <div class="app-field" data-cy="city">
          <h2>{{ labels.city | labelText }}</h2>
          <p>{{ secondParent?.residentialAddress?.city || '-' }}</p>
        </div>
        <div class="app-field-container-horizontal">
          <div class="app-field" data-cy="province">
            <h2>{{ labels.province | labelText }}</h2>
            <p>{{ secondParent?.residentialAddress?.province || '-' }}</p>
          </div>
          <div class="app-field" data-cy="postalCode">
            <h2>{{ labels.postalCode | labelText }}</h2>
            <p>{{ (secondParent?.residentialAddress?.postalCode | postalCode) || '-' }}</p>
          </div>
        </div>
      </div>
      <div class="app-field" data-cy="relationToChild">
        <h2>{{ labels.relationToChild | labelText }}</h2>
        <p>{{ relationList[secondParent?.relationToChild]?.displayValue || '-' }}</p>
      </div>
    </div>
    <!-- END class="app-right" -->
  </div>
</div>
<!-- END class="app" -->
