import { Lookup } from './lookup.interface';

export class ContactOutcomes {
  public static readonly SuccessfulContact: Lookup = { displayValue: 'Successful', code: 'SuccessfulContact' };
  public static readonly WaitingForInfo: Lookup = { displayValue: 'Waiting for information', code: 'WaitingForInfo' };
  public static readonly MessageLeft: Lookup = { displayValue: 'Message left', code: 'MessageLeft' };
  public static readonly EmailSent: Lookup = { displayValue: 'Email sent', code: 'EmailSent' };
  public static readonly UnableToLeaveMessage: Lookup = { displayValue: 'Unable to leave message', code: 'UnableToLeaveMessage' };

  public static readonly Values: { [name: string]: Lookup } = {
    SuccessfulContact: ContactOutcomes.SuccessfulContact,
    WaitingForInfo: ContactOutcomes.WaitingForInfo,
    MessageLeft: ContactOutcomes.MessageLeft,
    EmailSent: ContactOutcomes.EmailSent,
    UnableToLeaveMessage: ContactOutcomes.UnableToLeaveMessage,
  };
}
