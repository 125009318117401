<div [formGroup]="formGroup">
  <section class="review-consent">
    <h2>Consent and Declaration</h2>
    <h3>Privacy Notice</h3>
    <p>
      The personal information you provide is being collected to determine your eligibility for social-based supports
      and benefits offered by the Government of Alberta under Alberta Supports. If you choose to apply, the personal
      information you provide will then be used and disclosed in the application process, for ongoing eligibility
      verification, and for delivery of those programs, benefits or services offered by the Government of Alberta
      through Alberta Supports, if your eligibility is confirmed.
    </p>
    <p>
      The personal information provided to Alberta Supports is collected, used, and disclosed under the authority of
      sections 33 to 40 of the Freedom of Information and Protection of Privacy Act and various statutes establishing
      the programs included in Alberta Supports. To see the list of the programs, including the legislation authorizing
      each program, please visit
      <a
        href="https://open.alberta.ca/publications/authorizing-legislation-for-alberta-supports-programs-and-services"
        target="_blank"
        >Authorizing Legislation</a
      >
      &nbsp;.
    </p>
    <h3>Questions</h3>
    <p>
      If you have questions about the collection or use of your personal information, please contact the Alberta
      Supports Contact Centre at 1-877-644-9992 (toll free) or 780-644-9992 (Edmonton).
    </p>
    <div class="consent-checkbox">
      <mat-checkbox
        aria-label="consent"
        data-cy="consent"
        formControlName="isConsentChecked"
        (change)="onConsentChange($event.checked)"
      >
      </mat-checkbox>
      <span class="consent-checkbox-star">*</span>&nbsp;I acknowledge that I have read the above information and
      understand how my information will be used.
    </div>
  </section>
</div>
