import * as CryptoJS from 'crypto-js';

export class EncryptionUtils {
  constructor(private secretKey: string) {
    //for testing only
    if (secretKey) {
      this.secretKey = secretKey;
    } else {
      throw new Error('Must provide secret');
    }
  }
  encrypt(text: string) {
    if (!text) {
      return null;
    }
    const encryptedText = CryptoJS.AES.encrypt(text, this.secretKey).toString();
    return encryptedText;
  }

  decrypt(text: string) {
    if (!text) {
      return null;
    }
    //const decryptedText = CryptoJS.AES.decrypt(text, this.secretKey);
    // Decrypt
    const bytes = CryptoJS.AES.decrypt(text, this.secretKey);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  }
}
