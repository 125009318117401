import { Lookup } from './lookup.interface';
import { MINISTRY_NAME_LONG, MINISTRY_NAME_SHORT } from './ministry-name';
export interface MinistryLookup extends Lookup {
  sector?: string;
  orgShortName?: string;
  orgFullName?: string;
}

export const MinistryList: { [name: string]: MinistryLookup } = {
  scss: {
    code: MINISTRY_NAME_SHORT,
    displayValue: MINISTRY_NAME_LONG,
    orgShortName: 'GOA',
    orgFullName: 'Government of Alberta',
  },
};
