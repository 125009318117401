import { AngularComponentsModule } from '@abgov/angular-components';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';
import { AutosizeModule } from 'ngx-autosize';
import { FiGraphqlService } from '../../services/fi-graphql.service';
import { PdfGenerationService } from '../../services/pdf-generation.service';
import { ApplicatonViewerComponent } from './applicaton-viewer/applicaton-viewer.component';
import { CompileDocumentsComponent } from './compile-documents/compile-documents.component';
import { DeveloperToolsComponent } from './developer-tools.component';
import { ExportApplicationDataComponent } from './export-application-data/export-application-data.component';
import { GeneratePdfButtonComponent } from './generate-pdf-button/generate-pdf-button.component';
import { PreviewDimgRequestComponent } from './preview-dimg-request/preview-dimg-request.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, AngularComponentsModule, AutosizeModule, MatExpansionModule, MatButtonModule],
  declarations: [
    DeveloperToolsComponent,
    GeneratePdfButtonComponent,
    ExportApplicationDataComponent,
    CompileDocumentsComponent,
    PreviewDimgRequestComponent,
    ApplicatonViewerComponent,
  ],
  providers: [FiGraphqlService, AuthenticationService, PdfGenerationService],
  exports: [DeveloperToolsComponent],
})
export class DeveloperToolsModule {}
