import { HttpHandler, HttpErrorResponse, HttpInterceptor, HttpResponse, HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
// import { Logger } from '@nestjs/common';
import { v4 as uuidv4 } from 'uuid';
import { AppConfigService } from '../services/config/config.service';
import { GoogleAnalyticsService } from '../services/google-analytics/google-analytics.service';

export const IGNORE_ERROR_INTERCEPTOR_TOKEN = new HttpContextToken(() => false);

@Injectable()
export class ErrorIntercept implements HttpInterceptor {
  constructor(
    private toasterService: ToastrService,
    private config: AppConfigService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  intercept(request, next: HttpHandler) {
    if (request.context.get(IGNORE_ERROR_INTERCEPTOR_TOKEN)) return next.handle(request);

    const requestErrorMessage = request.body?.variables?.errorMessage;
    const requestErrorTitle = request.body?.variables?.errorTitle;
    const errorId = uuidv4(); //create an error id so we can cross reference the error if there is a screenshot

    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          //graphql returns 200 for partial responses (like unauthenticated), so handle it here
          if (event.body?.errors?.length > 0) {
            let isUnAuthorized = event.body.errors[0].message === 'Unauthorized';
            let errorMessage = `Error: ${event.body.errors[0].message}`;
            console.error(event.body.errors[0].extensions?.exception?.stacktrace);

            if (event.body.errors.length > 1) {
              event.body.errors.array.forEach((error) => {
                isUnAuthorized = isUnAuthorized || error.message === 'Unauthorized';
                errorMessage += `\n${error.message}`;
                console.error(error.extensions?.exception?.stacktrace);
              });
            }

            if (!isUnAuthorized) {
              //give requestErrorMessage/Title precedence if provided
              const toasterMessage = requestErrorMessage || errorMessage;
              const toasterTitle = requestErrorTitle || '';
              this.displayErrorMessage(toasterMessage, toasterTitle, errorId);
              return throwError(event.body.errors);
            }
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        // const requestErrorMessage2 = request.body?.variables?.errorMessage;

        let errorMessage = '';
        let errorTitle = '';
        if (error?.error?.message && error?.error?.title) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
          errorTitle = error.error?.title;
        } else if (error.status && error.message) {
          // server-side error
          errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
        } else if (error.message) {
          errorMessage = `An error has occurred: ${error.message}`;
        } else {
          errorMessage = `An error has occurred.`;
        }

        const serverErrorRegex = new RegExp('Http failure response for https?://.+/file-upload/.+: 0 Unknown Error');
        if (serverErrorRegex.test(error?.message)) {
          errorMessage = 'File server is down. Please try uploading your documents again in a few minutes';
        }

        //give requestErrorMessage/Title precedence if provided
        const toasterMessage = requestErrorMessage || errorMessage;
        const toasterTitle = requestErrorTitle || errorTitle;
        this.displayErrorMessage(toasterMessage, toasterTitle, errorId);
        return throwError(error);
      })
    );
  }

  displayErrorMessage(errorMessage, errorTitle, errorId) {
    const toasterMessage = `${errorMessage}. ErrorID: ${errorId}`;
    const logErrorMessage = `ErrorID: ${errorId}. ${errorMessage}`;
    const logErrorMessageShort = logErrorMessage.substring(0, 8000);
    try {
      if (errorTitle !== '') {
        this.toasterService.error(toasterMessage, errorTitle);
      } else {
        this.toasterService.error(toasterMessage);
      }
      //TODO: Need to find a way to log this error message client side.
      //For now let's log to google and truncate the payload size. Max payload size 8192 bytes
      // Logger.log(errorMessage);
      console.log(logErrorMessage);
      if (this.config.getConfig().GOOGLE_MEASUREMENT_ID) {
        this.googleAnalyticsService.trackErrorMessage(logErrorMessageShort);
      }
    } catch (e) {
      // console.log('An error occurred', '');
      console.log(logErrorMessage);
    }
  }
}
