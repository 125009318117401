<goa-callout type="information" [heading]="title" *ngIf="!messageDialog">
  <div mat-dialog-content [innerHTML]="description"></div>
  <div mat-dialog-actions>
    <div></div>
    <button goa-button buttonSize="small" mat-dialog-close class="goa-button compact">Okay</button>
  </div>
</goa-callout>
<ng-container *ngIf="messageDialog">
  <h3 mat-dialog-title>{{ title }}</h3>
  <div mat-dialog-content [innerHTML]="description"></div>

  <div mat-dialog-actions>
    <div></div>
    <button goa-button mat-dialog-close class="goa-button">Okay</button>
  </div>
</ng-container>
