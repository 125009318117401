import { Lookup } from './lookup.interface';

export const RegionsList: { [name: string]: Lookup } = {
  Edmonton: {
    code: 'Edmonton',
    displayValue: 'Edmonton',
  },
  Calgary: {
    code: 'Calgary',
    displayValue: 'Calgary',
  },
  Central: {
    code: 'Central',
    displayValue: 'Central',
  },
  South: {
    code: 'South',
    displayValue: 'South',
  },
  Northeast: {
    code: 'Northeast',
    displayValue: 'Northeast',
  },
  Northwest: {
    code: 'Northwest',
    displayValue: 'Northwest',
  },
};
