import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FscdIntakeSessionStorageKeys } from '@common/constants';
import { AppConfigService, ContextProvider } from '@common/ui/shared-components';
import { Utilities } from '@common/utils';
import { FiChild, FscdIntakeApplication } from '@fscd-intake/entities';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';
import { Apollo } from 'apollo-angular';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { FiGraphqlService } from '../../services/fi-graphql.service';
import {
  existingApplicationDraft,
  existingApplicationRemoved,
  existingApplicationSubmitted,
} from '../../shared/components/dashboard-modals/dashboard-page.modals';
import { DashboardListApplication } from '../../shared/interfaces/dashboard-list-application';
import { FiUtils } from '../../shared/utils/fi-utils';

@Component({
  selector: 'fi-proceedwithapplication-page',
  templateUrl: './proceedwithapplication-page.component.html',
  styleUrls: ['./proceedwithapplication-page.component.scss'],
})
export class ProceedWithApplicationPageComponent implements OnInit {
  isAuthenticated = false;
  redirectUrl = encodeURI('/proceed');
  encryptionKey = null;
  user = null;

  showStartAppModal = new EventEmitter<boolean>();
  rawApplications: FscdIntakeApplication[] = [];
  applicationsDraft: DashboardListApplication[] = [];
  applicationsSubmitted: DashboardListApplication[] = [];
  applicationsRemoved: DashboardListApplication[] = [];

  tooltipMessage =
    'According to the FSCD ACT, a disability is a "chronic developmental, physical, sensory, mental or neurological condition or impairment that does not include a condition for which the primary need is for medical care or health services to treat or manage the condition, unless it is a chronic condition that significantly limits a child\'s ability to function in normal daily living".';

  constructor(
    apollo: Apollo,
    private appExistsModal: MatDialog,
    private readonly contextProvider: ContextProvider,
    private configService: AppConfigService,
    private router: Router,
    private graphqlService: FiGraphqlService,
    private authenticationService: AuthenticationService,
    private utils: FiUtils
  ) {}

  async ngOnInit() {
    this.user = await firstValueFrom(this.contextProvider.getCurrentUser());
    if (this.configService) {
      this.encryptionKey = this.configService.getConfig().ENCRYPTION_KEY;
    }
    if (this.user) {
      await this.loadApplications();
    }
  }

  async loadApplications() {
    this.rawApplications = await lastValueFrom(this.graphqlService.getAllApplications());
    const applicationsAll = this.rawApplications.map(this.utils.createDashboardListApplication);
    this.applicationsDraft = applicationsAll.filter((listApp) => listApp.isDraft);
    this.applicationsSubmitted = applicationsAll.filter((listApp) => listApp.isSubmitted);
    this.applicationsRemoved = applicationsAll.filter((listApp) => listApp.isRemoved);
  }

  createAccount() {
    this.authenticationService.createAccount(this.redirectUrl);
  }

  login() {
    this.authenticationService.signIn(this.redirectUrl);
  }

  async navToApplication(childArg: FiChild) {
    const prescreenData = Utilities.getSessionStorageValue(
      FscdIntakeSessionStorageKeys.IntakeApplicationPrescreenData.key,
      this.encryptionKey
    );
    Utilities.removeSessionStorageValue(FscdIntakeSessionStorageKeys.IntakeApplicationPrescreenData.key);

    const child = {
      firstName: childArg?.firstName || '',
      middleName: childArg?.middleName || '',
      lastName: childArg?.lastName || '',
      birthdate: prescreenData?.birthdate || '',
      hasConfirmedDiagnosis: prescreenData?.hasConfirmedDiagnosis || false,
      isAwaitingDiagnosis: prescreenData?.isAwaitingDiagnosis || false,
    } as FiChild;

    try {
      const application = await lastValueFrom(
        this.graphqlService.updateApplication({
          isSubmitted: false,
          child: child,
        } as FscdIntakeApplication)
      );
      this.router.navigate(['application', { applicationId: application.id }]);
    } catch (error) {
      console.error(error);
      this.router.navigate(['not-found']);
    }
  }

  onStartApplication() {
    const storageChildInfo = Utilities.getSessionStorageValue(
      FscdIntakeSessionStorageKeys.ChildName.key,
      this.encryptionKey
    ) as FiChild;
    Utilities.removeSessionStorageValue(FscdIntakeSessionStorageKeys.ChildName.key);

    if (storageChildInfo) {
      this.navToApplication(storageChildInfo);
      return;
    }

    this.showStartAppModal.emit(true);
  }

  async onStartAppModalContinue(modalChildInfo: FiChild) {
    const hasNameMatch = this.utils.hasNameMatch(modalChildInfo);
    const draftApp = this.applicationsDraft.find(hasNameMatch);
    const isInSubmitted = !!this.applicationsSubmitted.find(hasNameMatch);
    const isInRemoved = !!this.applicationsRemoved.find(hasNameMatch);

    let dashboardPageUrlParam = null;
    if (draftApp) {
      const navToDraft = await existingApplicationDraft(this.appExistsModal, {
        confirmLabel: 'Continue',
        cancelLabel: 'Cancel',
      });

      if (navToDraft) this.onContinueApplication(draftApp);
      return;
    }

    if (isInSubmitted) {
      const showTabSubmitted = await existingApplicationSubmitted(this.appExistsModal, {
        confirmLabel: 'View',
        cancelLabel: 'Cancel',
      });

      if (!showTabSubmitted) return;
      dashboardPageUrlParam = 'submitted';
    }

    if (isInRemoved) {
      const showTabRemoved = await existingApplicationRemoved(this.appExistsModal, {
        confirmLabel: 'Restore',
        cancelLabel: 'Cancel',
      });

      if (!showTabRemoved) return;
      dashboardPageUrlParam = 'removed';
    }

    if (dashboardPageUrlParam) {
      this.router.navigateByUrl('dash?tab=' + dashboardPageUrlParam);
      return;
    }

    this.navToApplication(modalChildInfo);
  }

  async onContinueApplication(application: DashboardListApplication) {
    this.router.navigate(['application', { applicationId: application.id }]);
  }
}
