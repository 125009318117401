import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'common-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.scss'],
})
export class NotificationBannerComponent implements OnInit {
  @Input() bannerMessage = '';
  @Input() bannerType = 'info'; // important, emergency, info
  @Input() startDate?: Date;
  @Input() endDate?: Date;
  bannerVisible = true;
  constructor() {}

  ngOnInit(): void {}

  handleDismiss() {
    this.bannerVisible = false;
  }

  get formattedBannerMessage(): string {
    return `${this.bannerMessage}`;
  }

  get inDateRange() {
    let inRange = true; //by default
    const currentDate = new Date();
    if (this.startDate && this.startDate > currentDate) {
      inRange = false;
    } else if (this.endDate && this.endDate < currentDate) {
      inRange = false;
    }
    return inRange;
  }
}
