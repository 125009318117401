export interface EducationLevelLookup {
  code?: string;
  displayValue: string;
  certificateDegreeDiplomalabel?: string;
  isCertificateDegreeDiplomaRequired?: boolean;
}

export const EducationLevels: { [name: string]: EducationLevelLookup } = {
  elementary: { code: 'elementary', displayValue: 'Grade 1 - 6' },
  juniorHigh: { code: 'juniorHigh', displayValue: 'Grade 7 - 9' },
  highSchool: { code: 'highSchool', displayValue: 'Grade 10 - 12' },
  ged: { code: 'ged', displayValue: 'GED' },
  highSchoolCertificate: {
    code: 'highSchoolCertificate',
    displayValue: 'High school certification of achievement',
  },
  highSchoolDiploma: { code: 'highSchoolDiploma', displayValue: 'High school diploma' },
  collegeUniversity: {
    code: 'collegeUniversity',
    displayValue: 'Some college/university',
    certificateDegreeDiplomalabel: 'Degree/diploma obtained',
  },
  tradesTechnical: {
    code: 'tradesTechnical',
    displayValue: 'Some trades/technical',
    certificateDegreeDiplomalabel: 'Certificate obtained',
  },
  certificate: {
    code: 'certificate',
    displayValue: 'Certificate (includes journeyman and trades)',
    certificateDegreeDiplomalabel: 'Certificate obtained',
    isCertificateDegreeDiplomaRequired: true,
  },
  degree: {
    code: 'degree',
    displayValue: 'Degree (includes all levels of degrees)',
    certificateDegreeDiplomalabel: 'Degree/diploma obtained',
    isCertificateDegreeDiplomaRequired: true,
  },
  diplomaObtained: {
    code: 'diplomaObtained',
    displayValue: 'Diploma',
    certificateDegreeDiplomalabel: 'Diploma obtained',
    isCertificateDegreeDiplomaRequired: true,
  },
  nonCredential: {
    code: 'nonCredential',
    displayValue: 'Non credential (includes college preparatory and English as a second language)',
  },
};
