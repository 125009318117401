import { Document } from './document.entity';
import { Expose } from "class-transformer";

export class DocumentList {
  @Expose()
  documents?: Document[];
  @Expose()
  optOut?: string[];
  @Expose()
  activated?: boolean;
}
