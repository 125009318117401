import { Lookup } from './lookup.interface';

export const ClientOutcomes: { [name: string]: Lookup } = {
  employedRelated: { displayValue: 'Employed - related field', code: 'employedRelated' },
  employedUnrelated: { displayValue: 'Employed - unrelated field', code: 'employedUnrelated' },
  selfEmployed: { displayValue: 'Self-Employed', code: 'selfEmployed' },
  moreTraining: { displayValue: 'In further training', code: 'moreTraining' },
  attendAnotherProgram: { displayValue: 'Attending another program/service', code: 'attendAnotherProgram' },
  unemployedLooking: { displayValue: 'Unemployed - looking for work', code: 'unemployedLooking' },
  unemployedNotLooking: { displayValue: 'Unemployed - not in the labour force', code: 'unemployedNotLooking' },
};
