import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FiReview } from '@fscd-intake/entities';
import { BaseStepComponent } from '../base-step/base-step.component';

@Component({
  selector: 'fi-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsentComponent extends BaseStepComponent implements OnInit, OnDestroy {
  _review: FiReview;
  @Input() set review(value: FiReview) {
    this._review = value;
    if (value) {
      this.setFormValue();
      this.cdr.detectChanges();
    }
  }

  @Input() applicationId: string;
  @Input() set hasErrors(value: boolean) {
    if (value) {
      this.cdr.detectChanges();
      this.formGroup.markAllAsTouched();
    }
  }
  @Output() formError = new EventEmitter();
  @Output() formUpdated = new EventEmitter();
  @Output() saveReview = new EventEmitter();
  @Input() noPreviousErrors;

  private _formChangesSubscription;

  formGroup = this.fb.group({
    isConsentChecked: this.fb.control<boolean>(null, [Validators.required]),
  });

  constructor(fb: FormBuilder, private cdr: ChangeDetectorRef, public route: ActivatedRoute) {
    super(route, fb);
  }

  ngOnInit(): void {
    this._formChangesSubscription = this.formGroup.valueChanges.subscribe((val) => {
      this.formUpdated.emit(val);
    });
  }

  ngOnDestroy(): void {
    if (this._formChangesSubscription) this._formChangesSubscription.unsubscribe();
  }

  onConsentChange(value) {
    this.formGroup.get('isConsentChecked').setValue(value);
    this.saveReview.emit();
  }

  setFormValue() {
    if (this.formGroup) {
      const _isConsentChecked = this._review?.isConsentChecked ?? false;
      this.formGroup.patchValue({
        isConsentChecked: _isConsentChecked,
      });
      this.cdr.detectChanges();
    }
  }
}
