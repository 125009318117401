<div [formGroup]="formGroup" class="client-intake-citizenship">
  <div class="item">
    <common-form-control controlName="type" label="Citizenship">
      <mat-select
        formControlName="type"
        data-cy="citizenship-dropdown"
        placeholder="{{ selectOneText }}"
        (selectionChange)="onCitizenshipTypeSelect($event)"
      >
        <mat-option disabled value="">{{ selectOneText }}</mat-option>
        <mat-option
          *ngFor="
            let option of (shouldShowLandedImmigrant ? citizenshipTypes : citizenshipTypeWithoutLanded)
              | keyvalue: unsort
          "
          [value]="option.key"
        >
          {{ option.value.displayValue }}
        </mat-option>
      </mat-select>
      <div
        class="error-text"
        *ngIf="formGroup?.controls?.type?.touched && formGroup?.controls?.type?.errors?.deprecated"
      >
        {{ formGroup?.controls?.type?.errors?.deprecated }}
      </div>
    </common-form-control>
  </div>
  <div class="item">
    <common-form-control controlName="yearOfLanding" label="Year of landing">
      <input
        type="text"
        formControlName="yearOfLanding"
        data-cy="yearOfLanding"
        minlength="4"
        maxlength="4"
        iesNumberOnly
      />
    </common-form-control>
  </div>
  <div class="item" *ngIf="formGroup.controls.countryOfOrigin">
    <common-form-control controlName="countryOfOrigin" label="Country of origin">
      <input type="text" formControlName="countryOfOrigin" data-cy="countryOfOrigin" />
    </common-form-control>
  </div>
  <div class="item">
    <common-form-control controlName="isSponsored" [shouldUseLabel]="false" label="Is a sponsored immigrant?">
      <div>
        <mat-button-toggle-group
          formControlName="isSponsored"
          data-cy="is-sponsered"
          (change)="onIsSponsoredChange($event)"
        >
          <mat-button-toggle [value]="false"> No </mat-button-toggle>
          <mat-button-toggle [value]="true"> Yes </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </common-form-control>
  </div>
  <div class="item" *ngIf="formGroup.controls.sponsorshipExpirationDate">
    <common-form-control controlName="sponsorshipExpirationDate" label="Date of expiration">
      <common-calendar formControlName="sponsorshipExpirationDate" [minDate]="TODAY"></common-calendar>
    </common-form-control>
  </div>
</div>
