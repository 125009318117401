import { Lookup } from '@common/constants';

export interface ModifiedYesNoLookup extends Lookup {
  evaluatesToTrue?: boolean;
}

export interface ModifiedYesNoOptionList {
  [name: string]: ModifiedYesNoLookup;
}

//unsure evaluates to false
export const ModifiedYesNoOptionsUnsureNo: ModifiedYesNoOptionList = {
  yes: {
    displayValue: 'Yes',
    code: 'yes',
    evaluatesToTrue: true,
  },
  no: {
    displayValue: 'No',
    code: 'no',
    evaluatesToTrue: false,
  },
  unsure: {
    code: 'unsure',
    displayValue: 'Unsure',
    evaluatesToTrue: false,
  },
};

//same list but unsure evaluates to true
export const ModifiedYesNoOptionsUnsureYes: ModifiedYesNoOptionList = {
  ...ModifiedYesNoOptionsUnsureNo,
  unsure: { ...ModifiedYesNoOptionsUnsureNo.unsure, evaluatesToTrue: true },
};
