import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const IesAdditionalAssetLabels: IntakeLabelCollection = {
  hasGiveAway: {
    label: 'Did you sell or give away any assets in the past month?',
    spousePartnerLabel: 'Did you or your <<spouseLabel>> sell or give away any assets in the past month?',
  },
  hasGiveAwayAlt: {
    label: 'I sold or gave away assets in the past month?',
    spousePartnerLabel: 'I or my <<spouseLabel>> sold or gave away assets in the past month?',
  },
  hasVehicle: {
    label: 'Do you own a vehicle?',
    spousePartnerLabel: 'Do you or your <<spouseLabel>> own a vehicle?',
  },
  hasVehicleAlt: {
    label: 'I own a vehicle?',
    spousePartnerLabel: 'I or my <<spouseLabel>> own a vehicle?',
  },
  hasRecreationalVehicle: {
    label: 'Do you own a recreational vehicle?',
    spousePartnerLabel: 'Do you or your <<spouseLabel>> own a recreational vehicle?',
  },
  hasRecreationalVehicleAlt: {
    label: 'I own a recreational vehicle?',
    spousePartnerLabel: 'I or my <<spouseLabel>> own a recreational vehicle?',
  },
  hasProperty: {
    label: 'Do you own property or land - (other than the home you live in)?',
    spousePartnerLabel: 'Do you or your <<spouseLabel>> own property or land - (other than the home you live in)?',
  },
  hasPropertyAlt: {
    label: 'I own a property?',
    spousePartnerLabel: 'I or my <<spouseLabel>> own a property?',
  },
  hasFarm: {
    label: 'Do you own a farm?',
    spousePartnerLabel: 'Do you or your <<spouseLabel>> own a farm?',
  },
  hasFarmAlt: {
    label: 'I own a farm?',
    spousePartnerLabel: 'I or my <<spouseLabel>> own a farm?',
  },
  hasHomeQuarter: {
    label: 'Do you live on a home quarter section?',
    spousePartnerLabel: 'Do you or your <<spouseLabel>> live on a home quarter section?',
  },
  hasHomeQuarterAlt: {
    label: 'I live on a home quarter section?',
    spousePartnerLabel: 'I or my <<spouseLabel>> live on a home quarter section?',
  },
  hasBusiness: {
    label: 'Do you own a business?',
    spousePartnerLabel: 'Do you or your <<spouseLabel>> own a business?',
  },
  hasBusinessAlt: {
    label: 'I own a business?',
    spousePartnerLabel: 'I or my <<spouseLabel>> own a business?',
  },
  hasOther: {
    label: 'Do you have any other assets?',
    spousePartnerLabel: 'Do you or your <<spouseLabel>> have any other assets?',
  },
  hasOtherAlt: {
    label: 'I have any other assets?',
    spousePartnerLabel: 'I or my <<spouseLabel>> have any other assets?',
  },
};
