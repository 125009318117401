import { TemplateRef } from '@angular/core';
export interface EventSchemeLookup {
  cssBackgroundColor: string;
  cssEventColor: string;
  cssFontColor: string;
}
export interface WorkerCalendarEvent<T> {
  title: string;
  description?: string;
  eventTemplate?: TemplateRef<WorkerCalendarEvent<T>>;
  category: string;
  startDate?: Date;
  endDate?: Date;
  isComplete?: boolean;
  isImportant?: boolean;

  eventData: T;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actions?: { name: string; callback: (event: WorkerCalendarEvent<T>) => void }[];
}

export interface WorkerCalendarEventGrouping<T> {
  _id: string;
  title: string;
  events: WorkerCalendarEvent<T>[];
  isExpanded: boolean;
}

export enum CalendarView {
  Month = 'month',
  Week = 'week',
  Day = 'day',
  List = 'list',
}
