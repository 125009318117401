import { IntakeLabelCollection } from '../../entities/intake-label.interface';
import { MinistryList } from '../../constants/ministry-list';

export const AishConsentLabels: IntakeLabelCollection = {
  agreePrivacy: {
    label: 'I have read and agree to the Privacy Notice.',
  },
  agreePrivacyContent: {
    label: `The personal information you provide is being collected to determine your eligibility for social-based supports and benefits offered by the Government of Alberta under Alberta Supports. If you choose to apply, the personal information you provide will then be used and disclosed in the application process, ongoing eligibility verification, and for delivery of those programs, benefits or services offered by the Government of Alberta through Alberta Supports.<br /><br />
    The personal information provided to Alberta Supports is collected, used and disclosed under the authority of sections 33 to 40 of the Freedom of Information and Protection of Privacy Act and various statutes establishing the programs included in Alberta Supports. To see the list of the programs, including the legislation authorizing each program, you can click <a href="https://open.alberta.ca/publications/authorizing-legislation-for-alberta-supports-programs-and-services" target="_blank">Authorizing Legislation</a>.<br /><br />
    If you have questions about the collection or use of your personal information, please call the Alberta Supports Contact Centre at <a href="tel:780-644-9992">780-644-9992</a> in Edmonton or toll free at <a href="tel:1-877-644-9992">1-877-644-9992</a>.`,
  },
  agreeAishConsent: {
    label: 'I have read and agree to the AISH consent above.',
  },
  agreeAishConsentContent: {
    label:
      'I give my permission to any person, agency, organization, institution or other source to give the AISH program and/or AISH contracted services any information about my household situation, education and training, employment, and finances AISH asks for to decide if I am eligible for AISH. I understand I may withdraw my consent, in writing, at any time.',
  },
  agreeCraConsent: {
    label: 'I have read and agree to the Canada Revenue Agency consent above.',
  },
  agreeCraConsentContent: {
    label: `I authorize Canada Revenue Agency to release information required from my tax file to the Alberta Ministry of ${MinistryList['scss']?.displayValue}. The information will be relevant to and used solely for the purpose of determining and verifying my eligibility, or the eligibility of my co-habiting partner, for benefits under the Assured Income for the Severely Handicapped Act (c. A-45.1, 2006), and the general administration and enforcement of the benefit programs. This authorization is valid for the taxation year prior to the year of signature of this consent, the current taxation year, and each subsequent consecutive taxation year for which assistance is requested. I understand that if I wish to withdraw this consent, I may do so in writing to the Alberta Ministry of ${MinistryList['scss']?.displayValue}.`,
  },
  agreeCppConsent: {
    label: 'I have read and agree to the Canada Pension Plan - Disability (CPP-D) above.',
  },
  agreeCppConsentContent: {
    label: `I understand the AISH program 1. requires applicants to use all income, and that CPP-D is a benefit I may be entitled to:
    <ol>
        <li>If I am eligible for AISH benefits, I agree to have a CPP-D representative decide if I am eligible for CPP-D benefits. If the CPP-D representative decides I am not eligible for CPP-D based on my earnings and contributions, they will share that information with AISH and I will not need to apply for CPP-D.</li>
        <li>To decide my eligibility for CPP-D, I give my permission to AISH to share the following information with CPP-D: AISH Medical Report (Part B, Application Form) filled out by my doctor, and any other reports or documents that will help the programs decide my medical eligibility; and my completed AISH Application form.</li>
        <li>To decide my eligibility for, and the amount of my AISH benefits, I give my permission to CPP-D to share the following information with AISH: CPP-D will tell AISH whether or not I need to apply for CPP-D; and CPP-D's decision about my CPP-D benefit and the amount of the benefit I will receive.</li>
        <li>I understand I may withdraw my consent, in writing, at any time, and that this consent in place for three years from the date ( Month DD, YYYY), I ( or my Guardian/Co-decision maker/Agent/ trustee/Attorney) Sign it.</li>
    </ol>`,
  },
  agreeDeclaration: {
    label: 'I have read and agree to the Declaration above.',
  },
  agreeDeclarationContent: {
    label: `<ol>
        <li>I declare that the information I am giving about me, is true and complete and I understand that hiding information or giving false or incomplete information on purpose is an offence that could result in criminal charges.</li>
        <li>If I am Guardian, Co-decision maker, Agent, Trustee or Attorney (under a Power of Attorney), I Understand what this declaration means as it applies to the applicant.</li>
    </ol>`,
  },
};
