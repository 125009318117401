<div class="date-picker-container" [formGroup]="formGroup">
  <input
    maxlength="8"
    class="day-input control-margin"
    formControlName="day"
    [attr.data-cy]="dataCyPrefix + 'day'"
    aria-label="Day"
    type="text"
    (blur)="onBlur()"
    placeholder="Day"
    iesNumberOnly
    [wholeNumbersOnly]="true"
    *ngIf="!hideDay"
    inputmode="numeric"
  />
  <mat-select
    class="month-dropdown control-margin"
    [attr.data-cy]="dataCyPrefix + 'month'"
    aria-label="Month"
    (blur)="onBlur()"
    formControlName="month"
    placeholder="{{ defaultSelectionLabel }}"
  >
    <mat-option [disabled]="!allowDefaultSelection" value="">{{ defaultSelectionLabel }}</mat-option>
    <mat-option *ngFor="let month of _months" [value]="month.value">
      {{ month.label }}
    </mat-option>
  </mat-select>
  <input
    #yearControl
    minlength="4"
    maxlength="4"
    class="year-input"
    formControlName="year"
    [attr.data-cy]="dataCyPrefix + 'year'"
    aria-label="Year"
    type="text"
    (blur)="onBlur()"
    placeholder="Year"
    iesNumberOnly
    [wholeNumbersOnly]="true"
    inputmode="numeric"
  />
</div>
<ng-container *ngIf="showErrorMessages">
  <ng-container *ngIf="dayControl.touched && dayControl.invalid">
    <div class="error-text" *ngIf="dayControl.errors.required">Day is required.</div>
    <div class="error-text" *ngIf="dayControl.errors?.minlength">
      <!-- -->Day is invalid. Required minimum length: {{ dayControl.errors.minlength.requiredLength }}, actual:
      {{ dayControl.errors.minlength.actualLength }}.
    </div>
    <div class="error-text" *ngIf="dayControl.errors?.min">
      <!-- -->Day is invalid. minimum value: {{ dayControl.errors.min.min }}, actual:
      {{ dayControl.errors.min.actual }}.
    </div>
    <div class="error-text" *ngIf="dayControl.errors?.max">
      <!-- -->Day is invalid. max value: {{ dayControl.errors.max.max }}, actual: {{ dayControl.errors.max.actual }}.
    </div>
  </ng-container>
  <ng-container *ngIf="monthControl.touched && monthControl.invalid">
    <div class="error-text" *ngIf="monthControl.errors.required">Month is required.</div>
  </ng-container>

  <ng-container *ngIf="formGroup.controls.year.touched && formGroup.controls.year.invalid">
    <div class="error-text" *ngIf="formGroup.controls.year.errors.required">Year is required.</div>
    <div class="error-text" *ngIf="formGroup.controls.year.errors.minlength">
      <!-- -->Year is invalid. minimum length: {{ formGroup.controls.year.errors.minlength.requiredLength }}.
    </div>
  </ng-container>
</ng-container>
