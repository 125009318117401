interface SessionStorageKey {
  key: string;
  description: string;
}

export const SessionStorageKeys: { [name: string]: SessionStorageKey } = {
  IntakeApplicationSessionId: {
    key: 'intakeAppSessionId',
    description: 'AISH Application Session ID issued to non authenticated users',
  },
  IntakeApplicationPrescreenData: {
    key: 'intakeAppPrescreenData',
    description: 'AISH Prescreen data to store data to be used in the intake application',
  },
  AuthenticatedApplicationSessionId: {
    key: 'authAppSessionId',
    description: 'AISH Application Session ID issued to authenticated users',
  },
};

export const IesSessionStorageKeys: { [name: string]: SessionStorageKey } = {
  IntakeApplicationSessionId: {
    key: 'iesIntakeAppSessionId',
    description: 'IES Application Session ID issued to non authenticated users',
  },
  IntakeApplicationPrescreenData: {
    key: 'iesIntakeAppPrescreenData',
    description: 'IES Prescreen data to store data to be used in the intake application',
  },
  AuthenticatedApplicationSessionId: {
    key: 'iesAuthAppSessionId',
    description: 'IES Application Session ID issued to authenticated users',
  },
};

export const FbSessionStorageKeys: { [name: string]: SessionStorageKey } = {
  IntakeApplicationSessionId: {
    key: 'fbIntakeAppSessionId',
    description: 'FB Application Session ID issued to non authenticated users',
  },
  IntakeApplicationPrescreenData: {
    key: 'fbIntakeAppPrescreenData',
    description: 'FB Prescreen data to store data to be used in the intake application',
  },
  AuthenticatedApplicationSessionId: {
    key: 'fbAuthAppSessionId',
    description: 'FB Application Session ID issued to authenticated users',
  },
};

export const FscdIntakeSessionStorageKeys: { [name: string]: SessionStorageKey } = {
  ChildName: {
    key: 'fscdIntakeNewChildNameObj',
    description: 'FSCD Intake new child name object',
  },
  IntakeApplicationSessionId: {
    key: 'fscdIntakeAppSessionId',
    description: 'FSCD Application Session ID issued to non authenticated users',
  },
  IntakeApplicationPrescreenData: {
    key: 'fscdIntakeAppPrescreenData',
    description: 'FSCD Prescreen data to store data to be used in the intake application',
  },
  AuthenticatedApplicationSessionId: {
    key: 'fscdAuthAppSessionId',
    description: 'FSCD Application Session ID issued to authenticated users',
  },
};
