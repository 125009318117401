import { registerDecorator } from 'class-validator';

export function HaveValidNumbers() {
  return function (object, propertyName: string) {
    registerDecorator({
      name: 'HaveValidNumbers',
      target: object.constructor,
      propertyName: propertyName,
      validator: {
        validate(phoneNumbers: { phoneNumber: string; type: string }[]) {
          let isValid = true;
          if (phoneNumbers) {
            phoneNumbers.forEach((ph) => {
              if (ph.phoneNumber.length > 0 && ph.phoneNumber.length < 10) {
                isValid = false;
              }
            });
          }
          return isValid;
        },
        defaultMessage(): string {
          return 'At least one number is less than 10 digits';
        },
      },
    });
  };
}
