import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject, switchMap } from 'rxjs';

export type NavLink = {
  title: string;
  url: string;
  current: boolean;
  dataCy?: string;
};

@UntilDestroy()
@Component({
  selector: 'common-css-navbar',
  templateUrl: './css-navbar.component.html',
  styleUrls: ['./css-navbar.component.scss'],
})
export class CssNavbarComponent implements OnInit {
  @Input() navlinks: NavLink[];

  _isBelowBreakpoint = false;
  private _menuBreakpoint$: ReplaySubject<number> = new ReplaySubject(1);
  @Input() set menuBreakpoint(value) {
    if (value) this._menuBreakpoint$.next(value);
  }

  constructor(private breakPoint: BreakpointObserver) {}

  ngOnInit() {
    this._menuBreakpoint$
      .pipe(
        untilDestroyed(this),
        switchMap((breakPointPx) => this.breakPoint.observe([`(max-width: ${breakPointPx}px)`]))
      )
      .subscribe(({ matches }) => (this._isBelowBreakpoint = matches));
  }

  getCurrentLink() {
    this.navlinks?.find((l) => l.current);
  }
}
