import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DashboardListType } from '../../shared/enums/dashboard-list-types';
import { DashboardListApplication } from '../../shared/interfaces/dashboard-list-application';
import { BaseStepComponent } from '../base-step/base-step.component';

// TODO create 3 components for the three list types

@Component({
  selector: 'fi-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.scss'],
})
export class ApplicationListComponent extends BaseStepComponent {
  @Input() listType: DashboardListType;
  @Input() actionButtonLeftText: string;
  @Input() actionButtonRightText: string;
  @Input() actionButtonLeftStylePrimary: boolean;
  @Input() actionButtonRightStylePrimary: boolean;

  // TODO these should be defined in a shared place
  @Input() displayedColumns: string[];

  @Output() continueApplication = new EventEmitter<DashboardListApplication>();
  @Output() viewApplication = new EventEmitter<DashboardListApplication>();

  _dataSource: DashboardListApplication[];
  @Input() set dataSource(value) {
    if (value) this._dataSource = value;
  }
  get dataSource() {
    return this._dataSource;
  }

  constructor(route: ActivatedRoute, fb: FormBuilder, matDialog: MatDialog) {
    super(route, fb, matDialog);
  }

  hasSecondButton() {
    return this.listType !== DashboardListType.Submitted;
  }

  onActionButtonLeftClick(application) {
    if (this.listType === DashboardListType.Submitted && application.isSubmitted) {
      return this.viewApplication.emit(application);
    }

    if (this.listType === DashboardListType.Draft && application.isDraft) {
      return this.continueApplication.emit(application);
    }

    if (this.listType === DashboardListType.Removed && application.isRemoved) {
      return this.recoverApplication.emit(application);
    }
  }

  onActionButtonRightClick(application) {
    if (this.listType === DashboardListType.Draft && application.isDraft) {
      this.removeAppDialog(application);
    }
    if (this.listType === DashboardListType.Removed && application.isRemoved) {
      this.deleteAppDialog(application);
    }
  }

  changeSortOrder($event) {
    const columnName = $event.active;
    const sortDirection = $event.direction;

    this.dataSource = this.dataSource
      .map((key) => key)
      .sort((a, b) => {
        if (columnName === 'childName') {
          if (sortDirection === 'asc') return a[columnName]?.toLowerCase().localeCompare(b[columnName]?.toLowerCase());
          else return b[columnName]?.toLowerCase().localeCompare(a[columnName]?.toLowerCase());
        }
        if (
          columnName === 'dateStarted' ||
          columnName === 'dateLastSaved' ||
          columnName === 'dateSubmitted' ||
          columnName === 'dateRemoved'
        ) {
          if (sortDirection === 'asc') return a[columnName]?.getTime() - b[columnName]?.getTime();
          else return b[columnName]?.getTime() - a[columnName]?.getTime();
        }
        return 0;
      });
  }

  dataCy(columnName: string) {
    return `application-list-${columnName}`;
  }
}
