import { FieldPolicy, Reference } from '@apollo/client/core';
type KeyArgs = FieldPolicy<unknown>["keyArgs"];

export function offsetLimitPagination<T = Reference>(
  keyArgs: KeyArgs = false,
): FieldPolicy<T[]> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      const { startIndex = 0 } = args;

      if (startIndex === 0) return incoming;
      
      const merged = existing ? existing.slice(0) : [];
      
      for (let i = 0; i < incoming.length; ++i) {
        merged[startIndex + i] = incoming[i];
      }
      return merged;
    },
  };
}