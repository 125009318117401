import { Component, OnInit, Input, HostBinding } from '@angular/core';

/**
 * A Government of Alberta styled button.
 * selector: button[goa-button], input[type="button"][goa-button], input[type="submit"][goa-button]
 */
@Component({
  selector: 'button[goa-button], input[type="button"][goa-button], input[type="submit"][goa-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class GoAButtonComponent implements OnInit {
  /**
   * Adds class goa-button to the host.
   * @ignore
   */
  @HostBinding('class.goa-button') get buttonBinding() {
    return true;
  }

  /**
   * Adds class destructive to the host if destructive = true.
   * @ignore
   */
  @HostBinding('class.destructive') get destructiveBinding() {
    return this.destructive;
  }

  /**
   * Adds class goa--primary to the host if the buttonStyle = primary.
   * @ignore
   */
  @HostBinding('class.primary') get primaryBinding() {
    return this.buttonType === 'primary';
  }

  /**
   * Adds class goa--submit to the host if the buttonStyle = submit.
   * @ignore
   */
  @HostBinding('class.submit') get submitBinding() {
    return this.buttonType === 'submit';
  }

  /**
   * Adds class goa--start to the host if the buttonStyle = start.
   * @ignore
   */
  @HostBinding('class.start') get startBinding() {
    return this.buttonType === 'start';
  }

  /**
   * Adds class goa--secondary to the host if the buttonStyle = secondary.
   * @ignore
   */
  @HostBinding('class.secondary') get secondaryBinding() {
    return this.buttonType === 'secondary';
  }

  /**
   * Adds class goa--tertiary to the host if the buttonStyle = tertiary.
   * @ignore
   */
  @HostBinding('class.tertiary') get tertiaryBinding() {
    return this.buttonType === 'tertiary';
  }

  /**
   * Adds class btn-small to the host if the buttonSize = small.
   * @ignore
   */
  @HostBinding('class.compact') get smallBinding() {
    return this.buttonSize === 'small';
  }

  /**
   * Is the button destructive
   */
  @Input() destructive = false;

  /**
   * The appearance style of the button.
   */
  @Input() buttonType: 'primary' | 'secondary' | 'tertiary' | 'submit' | 'start' = 'primary';

  /**
   * The size of the button, controls font size and padding.
   */
  @Input() buttonSize: 'small' | 'normal' = 'normal';

  constructor() {}

  /**
   * @ignore
   */
  ngOnInit() {}
}
