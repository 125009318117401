import { IncomeLookup } from './case-management/case-income-types';

export const IesIncomeTypes: { [name: string]: IncomeLookup } = {
  employmentIncome: {
    order: 1,
    displayValue: 'Employment income',
    tips: 'Money you receive from: salary, wages, bonuses',
    documentTypes: [
      'Bank statement',
      'Pay stubs',
      'Written document from employer (must include name of employer, name of employee, pay period, date of pay, gross income/deductions)',
    ],
    helpText: 'Bank statements include last 60 days',
    needSupportDocument: false,
  },
  selfEmploymentIncome: {
    order: 2,
    displayValue: 'Self-employment',
    tips: `Earned from a business you operate as a sole proprietor or with someone else as a partner
    including earnings from:
    <ul>
      <li>a business</li>
      <li>sales commission</li>
      <li>farming</li>
      <li>fishing activities</li>
    </ul>`,
    documentTypes: [],
    needSupportDocument: false,
  },
  employmentInsurance: {
    order: 3,
    displayValue: 'Employment insurance (EI)',
    tips: `Federal government benefits for: 
    <ul>
      <li>loss of a job through no fault of their own – (for example, due to work shortage or seasonal work)</li> 
      <li>people able and available to work, but can’t find a job</li>
      <li>parents on maternity or paternity leave</li>
    </ul>`,
    documentTypes: [],
    needSupportDocument: false,
  },
  bandPayments: {
    order: 4,
    displayValue: 'Band Payments',
    tips: 'An amount paid to you from your Band',
    documentTypes: [],
    needSupportDocument: false,
  },
  childSupport: {
    order: 5,
    displayValue: 'Child support',
    tips: 'Child support is an ongoing payment made to a parent for the financial benefit of a child following the end of a relationship.',
    documentTypes: [],
    needSupportDocument: false,
  },
  spousalSupport: {
    order: 6,
    displayValue: 'Spousal Support/Alimony',
    tips: 'Money that one spouse/partner may have to pay the other spouse/partner due to a separation or divorce',
    documentTypes: ['Decree Nisi', 'Separation agreement', 'Court order', 'Confirmation from Child Support Service'],
    needSupportDocument: false,
  },
  canadaPensionPlan: {
    order: 7,
    displayValue: 'Canada Pension Plan (CPP)',
    tips: `Federal government benefits including
    <ul>
      <li>retirement pension (CPP)</li>
      <li>disability benefit (CPP-D)</li>
      <li>survivor’s pension</li>
    </ul>`,
    documentTypes: [
      'Statement from source',
      'Cheque stub',
      'Latest income tax assessment',
      'Direct deposit statement',
      'Bank statement',
    ],
    needSupportDocument: false,
  },
  otherPensionIncome: {
    order: 8,
    displayValue: 'Other Pension Income',
    documentTypes: [],
    needSupportDocument: false,
  },
  trainingAllowanceStudentFunding: {
    order: 9,
    displayValue: 'Training allowance/student funding',
    tips: 'Training or student funding can include provincial or federal student loans, funding for training from your band, or any other type of loan/funding for educational purposes',
    documentTypes: [],
    needSupportDocument: false,
  },
  canadaChildBenefit: {
    order: 10,
    displayValue: 'Canada child benefit',
    tips: 'The Canada child benefit is a tax-free monthly payment made to eligible families to help with the cost of raising children under 18 years of age.',
    documentTypes: [],
    needSupportDocument: false,
  },
  honorariumTipsCommissions: {
    order: 11,
    displayValue: 'Honorarium, tips, commissions',
    tips: 'An honorarium or tip is a voluntary payment that is given to a person for services for which fees are not legally or traditionally required. Commissions are amounts of money paid to an employee based on their performance.',
    documentTypes: [],
    needSupportDocument: false,
  },
  rentalIncome: {
    order: 12,
    displayValue: 'Rental income',
    tips: 'Rental Income: Income from a property or room you rent to someone else',
    documentTypes: [
      'Income tax return with notice of assessment (most recent)',
      'Rent book/receipts',
      'Lease or rental agreement',
      'Written statement/declaration from boarder',
    ],
    needSupportDocument: false,
  },
  wcbIncome: {
    order: 13,
    displayValue: "Workers' Compensation Benefits",
    tips: 'Payment for workplace injuries or illnesses',
    documentTypes: [],
    needSupportDocument: false,
  },
  dependentIncome: {
    order: 14,
    displayValue: 'Dependent income (include if not in school)',
    documentTypes: [],
    needSupportDocument: false,
  },
  lifeInsurance: {
    order: 15,
    displayValue: 'Life insurance',
    tips: 'Income paid to you as a beneficiary of a life insurance policy',
    documentTypes: [],
    needSupportDocument: false,
  },
  disabilityInsurance: {
    order: 16,
    displayValue: 'Disability insurance',
    tips: 'Disability insurance includes short-term or long-term disability insurance provided by employers through a third-party insurance company.  If you are unable to work for medical reasons, you may be eligible for these benefits.',
    documentTypes: [],
    needSupportDocument: false,
  },
  settlementIncome: {
    order: 17,
    displayValue: 'Settlement income',
    tips: 'Settlement income is provided to Government assisted refugees on a monthly basis, usually for a term of one year after arriving in Canada',
    documentTypes: [],
    needSupportDocument: false,
  },
  giftsWinningsInheritance: {
    order: 18,
    displayValue: 'Gifts, winnings, inheritance',
    tips: 'A financial gift from family or friends. Winnings are usually from a lottery, or other gaming activities. An inheritance is money, property or assets that you would receive from a person who has passed away.',
    documentTypes: [],
    needSupportDocument: false,
  },
  otherIncome: {
    order: 19,
    displayValue: 'Other Income',
    tips: 'Any other type of income, or funds received',
    documentTypes: [],
    needSupportDocument: false,
  },
};
