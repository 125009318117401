import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'common-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss'],
})
export class CopyComponent {
  swapIcon = false;
  @ViewChild('tooltip') tooltip: MatTooltip;

  @Input() copyText: string;
  @Input() extendSpace = false;
  constructor(private cdr: ChangeDetectorRef) {}

  onClick() {
    navigator.clipboard.writeText(this.copyText);
    this.swapIcon = true;
    this.tooltip.show();

    setTimeout(() => {
      this.swapIcon = false;
      this.tooltip.hide();
      this.cdr.detectChanges();
    }, 2000);
  }
}
