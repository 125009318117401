<div [formGroup]="formGroup">
  <div class="common-phone-container">
    <common-form-control class="phone-number control-margin" controlName="phoneNumber" [label]="hideLabel ? '' : label">
      <input
        type="tel"
        [attr.data-cy]="dataCy + '-number'"
        formControlName="phoneNumber"
        placeholder="000-000-0000"
        [validation]="true"
        [attr.aria-label]="label"
        mask="000-000-0000"
        autocomplete="tel"
      />
    </common-form-control>
    <common-form-control class="phone-type" controlName="type" [label]="hideLabel ? '' : 'Type'">
      <mat-select aria-label="Type" formControlName="type" [attr.data-cy]="dataCy + '-type'">
        <mat-option [value]="'Mobile'">Mobile</mat-option>
        <mat-option [value]="'Home'">Home</mat-option>
        <mat-option [value]="'Work'">Work</mat-option>
        <mat-option [value]="'Other'">Other</mat-option>
      </mat-select>
    </common-form-control>
  </div>
</div>
