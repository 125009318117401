<div class="wrapper">
  <div class="header">
    <div class="title">Developer Tools</div>
    <div>Application ID: {{ applicationId }}</div>
  </div>
  <div class="container">
    <fi-generate-pdf-button [application$]="application$"></fi-generate-pdf-button>
    <fi-export-application-data [application$]="application$"></fi-export-application-data>
    <fi-compile-documents [application$]="application$"></fi-compile-documents>
    <fi-preview-dimg-request [application$]="application$"></fi-preview-dimg-request>
    <fi-applicaton-viewer class="width-100" [application$]="application$"></fi-applicaton-viewer>
  </div>
</div>
