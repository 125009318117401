import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FbDependentLabels: IntakeLabelCollection = {
  firstName: {
    label: "Child's first name",
  },
  middleName: {
    label: "Child's middle name",
  },
  lastName: {
    label: "Child's last name",
  },
  firstNameAlt: {
    label: 'First name',
  },
  middleNameAlt: {
    label: 'Middle name',
  },
  lastNameAlt: {
    label: 'Last name',
  },
  birthdate: {
    label: 'Birthdate',
  },
  attendingHighSchool: {
    label: 'Is the dependent child still in high school?',
  },
  relationship: {
    label:
      'What is the relationship of this dependent child to the deceased, or to the spouse/partner of the deceased?',
    helperText: 'For example: Biological child, adopted child, foster child etc.',
  },
  compositeErrorMessage: {
    label: `The dependent older than 18 and not attending high school is not considered as dependent for the eligibility of
    the Funeral Benefits.`,
  },
  olderThan20ErrorMessage: {
    label: `The dependent older than 20 is not considered as dependent for the eligibility of the Funeral Benefits.`,
  },
};
