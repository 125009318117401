import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const IesEmploymentLabels: IntakeLabelCollection = {
  currentlyWorking: {
    label: 'Are you currently working?',
    spousePartnerLabel: 'Is your <<spouseLabel>> currently working?',
  },
  typeOfWork: {
    label: 'What type of work do you do at this job?',
    spousePartnerLabel: 'What type of work does your <<spouseLabel>> do at this job?',
  },
  avgHours: {
    label: 'On average how many hours do you work per week?',
    spousePartnerLabel: 'On average how many hours does your <<spouseLabel>> work per week?',
  },
  reasonNotWorking: {
    label: 'What is the reason that you are not working?',
    spousePartnerLabel: 'What is the reason that your <<spouseLabel>> is not working?',
  },
  otherReasonNotWorking: {
    label: 'Explain not being able to work for other reason',
    spousePartnerLabel: 'Explain the other reason why your <<spouseLabel>> cannot work',
  },
  receivingEi: {
    label: 'Are you receiving or have you applied for Employment Insurance (EI)?',
    spousePartnerLabel: 'Is your <<spouseLabel>> receiving or have they applied for Employment Insurance (EI)?',
  },
};
