import { UploadLookup } from '../upload-lookup.interface';
export interface IncomeLookup extends UploadLookup {
  code?: string;
  isSpecial?: boolean;
  tips?: string;
}
export const CaseIncomeTypes: { [name: string]: IncomeLookup } = {
  employmentIncome: {
    order: 1,
    displayValue: 'Employment income',
    tips: 'Money you receive from: salary, wages, bonuses',
    documentTypes: [
      'Bank statement',
      'Pay stubs',
      'Written document from employer (must include name of employer, name of employee, pay period, date of pay, gross income/deductions)',
    ],
    helpText: 'Bank statements include last 60 days',
    needSupportDocument: true,
  },
  selfEmploymentIncome: {
    order: 2,
    displayValue: 'Self-employment income',
    tips: `May be earned from a business you operate yourself as a sole proprietor or with someone else as a partner
    including earnings from:
    <ul>
      <li>a business</li>
      <li>a profession commission sales</li>
      <li>farming</li>
      <li>fishing activities</li>
    </ul>`,
    documentTypes: [
      'Statement of net self-employment income',
      'Audited financial statement',
    ],
    needSupportDocument: true,
  },
  canadaChildTaxBenefit: {
    order: 3,
    displayValue: 'Canada Child Tax Benefit',
    tips:
      'A tax-free monthly payment made to eligible families to help with the cost of raising children under 18 years of age.',
    documentTypes: ['Canada Child Benefit Notice of Determination or Canada Child Benefit Verification'],
    needSupportDocument: true,
  },
  supportIncome: {
    order: 4,
    displayValue: 'Income from child support/adult support',
    documentTypes: [],
    needSupportDocument: false,
  },
  bandCapitaSettlement: {
    order: 5,
    displayValue: 'Band payments/per capita payments income',
    documentTypes: ['Direct deposit statement', 'EI payment stubs', 'Statement from EI'],
    needSupportDocument: true,
  },
  settlementIncome: {
    order: 6,
    displayValue: 'Settlement income',
    documentTypes: [],
    needSupportDocument: false,
  },
  employmentInsurance: {
    order: 7,
    displayValue: 'Employment Insurance (EI)',
    tips: `Federal government benefits for: 
    <ul>
      <li>those who lose their jobs through no fault of their own – (for example, due to
          work shortage, seasonal work or mass lay-offs)</li> 
      <li>those who are able and available to work, but can’t find a job</li>
      <li>parents on maternity or paternity leave</li>
    </ul>`,
    documentTypes: ['EI payment stubs', 'Statement from EI'],
    needSupportDocument: true,
  },
  canadaPensionPlan: {
    order: 8,
    displayValue: 'Canada Pension Plan (CPP)',
    tips: `Federal government benefits including
    <ul>
      <li>retirement pension (CPP)</li>
      <li>disability benefit (CPP-D)</li>
      <li>survivor’s pension</li>
    </ul>`,
    documentTypes: [
      'Statement from source',
      'Cheque stub',
      'Latest income tax assessment',
      'Direct deposit statement',
      'Bank statement',
    ],
    needSupportDocument: true,
  },
  oldAgeSecurity: {
    order: 9,
    displayValue: 'Old Age Security (OAS)',
    tips: `Federal government benefits for seniors aged 65 and older and/or their spouse or partner, aged 60 to 64:
    <ul>
      <li>Old Age Security pension (OAS)</li>
      <li>Allowance for the survivor</li>
      <li>Guaranteed Income Supplement (GIS)</li>
      <li>Allowance for a GIS recipient’s spouse or partner</li>
    </ul>`,
    documentTypes: ['Direct deposit statement', 'Cheque stub', 'Written statement from WCB'],
    needSupportDocument: true,
  },

  pensionIncome: {
    order: 10,
    displayValue: 'Pension income from previous employment',
    tips: `Income provided by a pension program like:
    <ul>
      <li>military pension</li>
      <li>provincial pension plans</li>
      <li>retirement compensation arrangements</li>
    </ul>`,
    documentTypes: [],
    needSupportDocument: false,
  },

  incomeTaxRefund: {
    order: 11,
    displayValue: 'Income tax refund',
    tips:
      'An income tax refund is a payment a taxpayer receives from the federal government when they have paid more tax than they were required to',
    documentTypes: [],
    needSupportDocument: true,
  },
  disabilityInsurance: {
    order: 12,
    displayValue: 'Disability wage loss insurance income',
    tips: 'Payments from an insurance company when an employee is no longer able to earn a living',
    documentTypes: [],
    needSupportDocument: true,
  },
  trustAccountIncome: {
    order: 13,
    displayValue: 'Income for trust account(s)',
    tips: 'Interest payments and capital payments',
    documentTypes: [],
    needSupportDocument: true,
  },

  investmentIncome: {
    order: 14,
    displayValue: 'Income from investments',
    tips: `<ul>
    <li>Interest payments</li>
    <li>Dividends</li>
    <li>Capital gains from selling a security or other assets</li>
    <li>Any other profit from any investment</li>
  </ul>`,
    documentTypes: [
      'Statement from payer, broker, or bank',
      'Cheque stubs',
      'Direct deposit/bank statement',
      'Latest income tax assessment',
    ],
    needSupportDocument: true,
  },
  rentalIncome: {
    order: 15,
    displayValue: 'Income from a rental property',
    tips: 'Rental Income: income from a property or room that you rent to someone else',
    documentTypes: [
      'Income tax return with notice of assessment (most recent)',
      'Rent book/receipts',
      'Lease or rental agreement',
      'Written statement/declaration from boarder',
    ],
    needSupportDocument: true,
  },

  studentFunding: {
    order: 16,
    displayValue: 'Training allowance/student funding received',
    documentTypes: ['Direct deposit statement', 'Cheque stub', 'Written statement from source'],
    needSupportDocument: true,
  },
  lifeInsuranceIncome: {
    order: 17,
    displayValue: 'Life insurance income',
    tips: 'Income paid to you as a beneficiary of a life insurance policy',
    documentTypes: [],
    needSupportDocument: true,
  },

  guaranteedSupplement: {
    order: 18,
    displayValue: 'Guaranteed Income Supplement (GIS)',
    tips: '',
    documentTypes: [],
    needSupportDocument: false,
  },
  spousalSupport: {
    order: 19,
    displayValue: 'Spouse support',
    tips: 'Alimony or maintenance money from a former spouse or partner due to a separation or divorce agreement',
    documentTypes: ['Decree Nisi', 'Separation agreement', 'Court order', 'Confirmation from Child Support Service'],
    needSupportDocument: true,
  },
  childSupport: {
    order: 20,
    displayValue: 'Child support',
    tips: '',
    documentTypes: [],
    needSupportDocument: false,
  },
  dependentIncome: {
    order: 21,
    displayValue: 'Dependent income',
    tips: '',
    documentTypes: [],
    needSupportDocument: false,
  },
  tipsCommissions: {
    order: 22,
    displayValue: 'Tips, commissions, or honorariums',
    tips: 'A sum of money given to you from a client or customer for a service you have performed',
    documentTypes: [],
    needSupportDocument: false,
  },
  gifts: {
    order: 23,
    displayValue: 'Gifts',
    tips: '',
    documentTypes: [],
    needSupportDocument: false,
  },
  wcbIncome: {
    order: 24,
    displayValue: "Workers' compensation board income",
    tips: 'Payment for workplace injuries or illnesses',
    documentTypes: [
      'Income tax return with notice of Assessment (most recent)',
      'WCB payment stub',
      'Written statement from WCB',
    ],
    needSupportDocument: true,
  },
  otherGovernmentIncome: {
    order: 25,
    displayValue: 'Other income from government',
    tips: 'Examples include: Money received for fostering a child, post adoption benefits',
    documentTypes: [],
    needSupportDocument: false,
  },
  assetSale: {
    order: 26,
    tips: 'Money you have received from selling an asset (i.e. a car, a boat etc.)',
    displayValue: 'Income from the sale of an asset',
    documentTypes: [],
    needSupportDocument: false,
  },
  otherSourcesOfIncome: {
    code: 'otherSourcesOfIncome',
    order: 27,
    displayValue: 'Other sources of income',
    documentTypes: ['Other sources of income'],
    needSupportDocument: true,
    helpText: 'e.g. Money in trust for children, gifts',
    isSpecial: true,
  },
  specialPayment: {
    code: 'specialPayment',
    order: 28,
    displayValue: 'Special payment',
    documentTypes: ['Special payment'],
    needSupportDocument: true,
    isSpecial: true,
  },
};
