<div class="undo-toaster">
  <div
    *ngIf="message"
    role="alert"
    aria-live="polite"
    class="custom-message"
    [class]="options.messageClass"
    [attr.aria-label]="message"
  >
    {{ message }}
  </div>

  <div class="undo-link">
    <a data-cy="undo-toaster-button" (click)="undo()"> UNDO </a>
  </div>
</div>
