import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FscdIntakeSessionStorageKeys } from '@common/constants';
import { FiPrescreenLabels } from '@common/labels';
import { AppConfigService } from '@common/ui/shared-components';
import { Utilities } from '@common/utils';
import { MAX_WIDTH_FOR_MOBILE } from '@fscd-intake/entities';

@Component({
  selector: 'fi-pre-screen-page',
  templateUrl: './pre-screen-page.component.html',
  styleUrls: ['./pre-screen-page.component.scss'],
})
export class PreScreenPageComponent implements OnInit {
  _birthDateMax = new Date();
  _birthDateMin = new Date(
    this._birthDateMax.getFullYear() - 18,
    this._birthDateMax.getMonth(),
    this._birthDateMax.getDate()
  );
  encryptionKey = null;
  fiLabels = FiPrescreenLabels;

  form: UntypedFormGroup = this.formBuilder.group({
    birthdate: this.formBuilder.control(undefined, [Validators.required]),
    isChildCitizenOrPermanentResident: this.formBuilder.control(undefined, [Validators.required]),
    isResidentOfAlberta: this.formBuilder.control(undefined, [Validators.required]),
    isParentOrGuardian: this.formBuilder.control(undefined, [Validators.required]),
    hasConfirmedDiagnosis: this.formBuilder.control(undefined, [Validators.required]),
    isAwaitingDiagnosis: this.formBuilder.control(undefined, [Validators.required]),
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private configService: AppConfigService
  ) {}

  async ngOnInit() {
    if (this.configService) {
      this.encryptionKey = this.configService.getConfig().ENCRYPTION_KEY;
    }
  }

  async evaluate() {
    if (this.form.valid) {
      Utilities.setSessionStorageValue(
        FscdIntakeSessionStorageKeys.IntakeApplicationPrescreenData.key,
        this.form.value,
        this.encryptionKey
      );
      if (this.isEligible()) {
        this.router.navigateByUrl('proceed');
      } else {
        this.router.navigateByUrl('knockout');
      }
    } else {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
    }
  }

  isEligible(): boolean {
    let isEligible = true;

    if (Utilities.is18Older(this.form.get('birthdate').value)) isEligible = false;
    if (this.form.get('isChildCitizenOrPermanentResident').value === false) isEligible = false;
    if (this.form.get('isResidentOfAlberta').value === false) isEligible = false;
    if (this.form.get('isParentOrGuardian').value === false) isEligible = false;
    if (this.form.get('hasConfirmedDiagnosis').value === false && this.form.get('isAwaitingDiagnosis').value === false)
      isEligible = false;

    return isEligible;
  }

  isBetween16and18(): boolean {
    let isBetween16and18 = false;
    if (Utilities.isBetween16And18(this.form.get('birthdate').value)) {
      isBetween16and18 = true;
    }
    return isBetween16and18;
  }

  @HostListener('window:resize', [])
  isScreenSmall(): boolean {
    return window.innerWidth <= MAX_WIDTH_FOR_MOBILE;
  }
}
