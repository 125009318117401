import { FinancialsLabels } from './financials-labels.interface';

export const FbSpouseFinancialLabels: FinancialsLabels = {
  hasNoIncome: {
    label: 'The spouse/partner does not have any income.',
    replacementLabel: 'The <<replacementLabel>> does not have any income.',
  },
  incomeNotProvided: {
    label: `The spouse/partner income was not provided.`,
    replacementLabel: `The <<replacementLabel>>'s income was not provided.`,
  },
  hasNoAssets: {
    label: 'The spouse/partner does not have any assets.',
    replacementLabel: 'The <<replacementLabel>> does not have any assets.',
  },
  assetsNotProvided: {
    label: `The spouse/partner assets were not provided.`,
    replacementLabel: `The <<replacementLabel>>'s assets were not provided.`,
  },
  // hasIncomeAssets: {
  //   label:
  //     'You have not shared any financial information for the spouse/partner. Does the spouse/partner have any of the above source of income or assets?',
  //   replacementLabel:
  //     'You have not shared any financial information for the <<replacementLabel>>. Does the <<replacementLabel>> have any of the above sources of income or assets?',
  // },
  // canProvideBankStatement: {
  //   label: 'Are you able to provide the spouse/partner bank accounts/statements?',
  //   replacementLabel: "Are you able to provide the <<replacementLabel>>'s bank accounts/statements?",
  // },
  knowBankLocation: {
    label: 'Can you provide this banking information?',
  },
  noIncomeAssets: {
    label: 'No information regarding the income or assets of the spouse/partner has been shared.',
    replacementLabel: 'No information regarding the income or assets of the <<replacementLabel>> has been shared.',
  },
};
