import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { DateValidator } from '../validators/date.validator';

@Directive({
  selector: '[iesMinDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: MinDateValidator,
      multi: true,
    },
  ],
})
export class MinDateValidator implements Validator {
  @Input('iesMinDate') minDateValue: Date;
  @Input('iesMinDateErrorMessage') message = null;

  //constructor(@prop('minDate') private minDateValue: Date) {}
  validate(control: AbstractControl) {
    return DateValidator.minDate(this.minDateValue, '', this.message)(control);
  }
}
