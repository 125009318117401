import { Directive, ElementRef, HostListener } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[matTooltip][iesEllipsisTooltip]'
})
export class EllipsisTooltipDirective {
  
  constructor(
    private matTooltip: MatTooltip,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  @HostListener('mouseenter', ['$event'])
  setTooltipState() {
      const element = this.elementRef.nativeElement;
      //console.log(`element.scrollWidth: ${element.scrollWidth} , element.clientWidth: ${element.clientWidth}`);
      this.matTooltip.disabled = element.scrollWidth <= element.clientWidth;
  }
}