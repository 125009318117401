import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'array',
})
export class ArrayPipe implements PipeTransform {
  transform(array: Array<string>, seperator = ', ', displayProperty = null): string {
    if (displayProperty) return array?.map((i) => i[displayProperty]).join(seperator) ?? '';
    return array?.join(seperator) ?? '';
  }
}
