<div class="file-upload-container" [formGroup]="formGroup" *ngIf="hasCategory">
  <div class="upload-row" *ngIf="!hideHeaderLabel">
    <span class="label">{{ label }}</span>
    <span
      class="chip"
      *ngIf="showUploadRemaining && uploadsRemaining"
      [innerHtml]="remainingUploadsIndicator(uploadsRemaining)"
    ></span>
  </div>
  <div
    class="upload-row"
    role="region"
    [attr.aria-label]="categoryLabel + ' ' + documentCategory?.value['displayValue']"
    *ngFor="let documentCategory of documentCategories | keyvalue: sort"
  >
    <div *ngIf="!enableDragDrop">
      <common-document-upload-worker
        [documentCategory]="documentCategory"
        [maxFileSize]="maxFileSize"
        [accept]="accept"
        [parentId]="parentId"
        [deleteMessage]="deleteMessage"
        [documentsUploaded]="getDocumentsBy(documentCategory.key)"
        [multipleDocument]="multipleDocument"
        [categoryLabel]="categoryLabel"
        [dataCy]="dataCy"
        [componentInitialized]="_componentInitialized"
        [alwaysShowOptOut]="alwaysShowOptOut"
        (started)="onUploadStarted($event)"
        (uploadInProgress)="onUploadInProgress($event)"
        (fileUploaded)="onFileUploaded($event)"
        (optOutChanged)="onOptOutChanged($event)"
        (errorOccurred)="onErrorOccurred($event)"
        (completed)="onCompleted()"
      >
        <common-document-list
          [files]="getAllDocumentsBy(documentCategory.key)"
          [parentId]="parentId"
          [categoryDisplay]="documentCategory.value['displayValue']"
          (removeFile)="onRemoveFile($event)"
          [tooltipMessage]="tooltipMessage"
          [tooltipPosition]="tooltipPosition"
          [ariaLabelForPreviewIcon]="ariaLabelForPreviewIcon"
        >
        </common-document-list>
      </common-document-upload-worker>
    </div>

    <div *ngIf="enableDragDrop">
      <d3-drag-drop-worker
        [documentCategory]="documentCategory"
        [maxFileSize]="maxFileSize"
        [accept]="accept"
        [parentId]="parentId"
        [deleteMessage]="deleteMessage"
        [documentsUploaded]="getDocumentsBy(documentCategory.key)"
        [multipleDocument]="multipleDocument"
        [categoryLabel]="categoryLabel"
        [dataCy]="dataCy"
        [componentInitialized]="componentInitialized"
        [alwaysShowOptOut]="alwaysShowOptOut"
        [markAsTouched]="markAsTouched"
        [uploadedDocuments]="getAllDocumentsBy(documentCategory.key)"
        (started)="onUploadStarted($event)"
        (uploadInProgress)="onUploadInProgress($event)"
        (fileUploaded)="onFileUploaded($event)"
        (optOutChanged)="onOptOutChanged($event)"
        (errorOccurred)="onErrorOccurred($event)"
        (completed)="onCompleted()"
        [initialCanvasState]="getCanvasState(documentCategory.key)"
        (saveCanvasState)="saveCanvasState($event)"
        [skipUploadMore]="true"
      >
        <common-document-list
          [files]="getAllDocumentsBy(documentCategory.key)"
          [parentId]="parentId"
          [categoryDisplay]="documentCategory.value['displayValue']"
          (removeFile)="onRemoveFile($event)"
          [tooltipMessage]="tooltipMessage"
          [tooltipPosition]="tooltipPosition"
          [ariaLabelForPreviewIcon]="ariaLabelForPreviewIcon"
        >
        </common-document-list>
      </d3-drag-drop-worker>
    </div>
  </div>
</div>
