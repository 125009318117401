import { FiChildInfoLabels } from '@common/labels';
import { Utilities } from '@common/utils';
import { Component, Input, OnChanges } from '@angular/core';
import { FscdIntakeApplication, ModifiedYesNoOptionsUnsureYes } from '@fscd-intake/entities';
import { Document } from '@common/entities';
import { FileService } from '@common/ui/shared-components';
import { FiLegalStatus, Genders } from '@common/constants';

@Component({
  selector: 'fi-view-application-child',
  templateUrl: './view-application-child.component.html',
  styleUrls: ['../view-application.component.scss', '../view-application-child-parent.component.scss'],
})
export class ViewApplicationChildComponent implements OnChanges {
  @Input() application: FscdIntakeApplication;

  identityDocArr = [];
  medicalDocArr = [];
  additionalDocArr = [];
  genderList = Genders;
  legalStatusList = FiLegalStatus;
  utility = Utilities;
  labels = FiChildInfoLabels;
  customRadioOptions = ModifiedYesNoOptionsUnsureYes;

  constructor(private fileService: FileService) {}

  ngOnChanges(): void {
    const identityDocuments = this.application?.child?.identityDocuments || [];
    const medicalDocuments = this.application?.child?.medicalDocuments || [];
    const additionalDocuments = this.application?.child?.additionalDocuments || [];

    identityDocuments.forEach((doc) => {
      this.identityDocArr.push({ str: doc.documentType || '-' });
      this.identityDocArr.push({ str: doc.name || '-' });
      this.identityDocArr.push({ isView: true, doc });
    });

    medicalDocuments.forEach((doc) => {
      this.medicalDocArr.push({ str: doc.documentType || '-' });
      this.medicalDocArr.push({ str: doc.name || '-' });
      this.medicalDocArr.push({ isView: true, doc });
    });

    additionalDocuments.forEach((doc) => {
      this.additionalDocArr.push({ str: doc.documentType || '-' });
      this.additionalDocArr.push({ str: doc.name || '-' });
      this.additionalDocArr.push({ isView: true, doc });
    });
  }

  onClickRowView(document: Document) {
    if (!document.key || !this.application.id) return;

    this.fileService.getDocument(document.key, this.application.id).subscribe((result) => {
      window.open(result['URL']);
    });
  }
}
