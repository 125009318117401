import { DateAdapter } from '@angular/material/core';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as localeData from 'dayjs/plugin/localeData';

export class DayJsDateAdapter extends DateAdapter<dayjs.Dayjs> {
  constructor(matDateLocale: string) {
    super();

    dayjs.locale(matDateLocale);
    dayjs.extend(customParseFormat);
    dayjs.extend(localizedFormat);
    dayjs.extend(localeData);
  }

  getYear(date: dayjs.Dayjs): number {
    return date.year();
  }
  getMonth(date: dayjs.Dayjs): number {
    return date.month();
  }
  getDate(date: dayjs.Dayjs): number {
    return date.date();
  }
  getDayOfWeek(date: dayjs.Dayjs): number {
    return date.day();
  }
  getMonthNames(): string[] {
    return dayjs().localeData().months();
  }
  getDateNames(): string[] {
    const dates = [];
    for (let i = 1; i <= 31; i++) {
      dates.push(i.toString());
    }

    return dates;
  }
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style === 'long') return dayjs.localeData().weekdays();
    if (style === 'short') return dayjs.localeData().weekdaysShort();
    if (style === 'narrow') return dayjs.localeData().weekdaysMin();
  }
  getYearName(date: dayjs.Dayjs): string {
    return date.year().toString();
  }
  getFirstDayOfWeek(): number {
    return dayjs().localeData().firstDayOfWeek();
  }
  getNumDaysInMonth(date: dayjs.Dayjs): number {
    return date.daysInMonth();
  }
  clone(date: dayjs.Dayjs): dayjs.Dayjs {
    return date.clone();
  }
  createDate(year: number, month: number, date: number): dayjs.Dayjs {
    return dayjs(new Date(year, month, date));
  }
  today(): dayjs.Dayjs {
    return dayjs(new Date());
  }
  addCalendarYears(date: dayjs.Dayjs, years: number): dayjs.Dayjs {
    return date.clone().add(years, 'year');
  }
  addCalendarMonths(date: dayjs.Dayjs, months: number): dayjs.Dayjs {
    return date.clone().add(months, 'month');
  }
  addCalendarDays(date: dayjs.Dayjs, days: number): dayjs.Dayjs {
    return date.clone().add(days, 'day');
  }
  toIso8601(date: dayjs.Dayjs): string {
    return date.toISOString();
  }
  isDateInstance(obj): boolean {
    return dayjs.isDayjs(obj);
  }
  isValid(date: dayjs.Dayjs): boolean {
    return !date || (this.isDateInstance(date) && date.isValid());
  }
  invalid(): dayjs.Dayjs {
    return dayjs(null);
  }
  parse(value) {
    if (!value) {
      return null;
    }

    return dayjs(value);
  }
  format(date: dayjs.Dayjs, displayFormat): string {
    return date.format(displayFormat);
  }
  /**
   * Returns the given value if given a valid DayJs or null. Deserializes valid ISO 8601 strings
   * (https://www.ietf.org/rfc/rfc3339.txt) and valid Date objects into valid DayJs and empty
   * string into null. Returns an invalid date for all other values.
   */
  deserialize(value): dayjs.Dayjs | null {
    if (value instanceof Date) {
      dayjs(value);
    } else if (this.isDateInstance(value)) {
      return this.clone(value);
    }
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      return this.parse(value);
    }

    return super.deserialize(value);
  }
}
