import { Injectable } from '@angular/core';
import { AppConfigService } from '@common/ui/shared-components';
import * as dayjs from 'dayjs';

const CONFIG_CACHE_LIFETIME_MS = 10000;

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  // Keep this flag in root scope so dismissal persists for life of app component.
  private _bannerDismissed = false;

  constructor(private configService: AppConfigService) {}

  public async scheduledMaintenance(): Promise<ScheduledMaintenance> {
    const config = await this.configService.cachedGetConfig(CONFIG_CACHE_LIFETIME_MS);
    const startDate = config.MAINTENANCE_START_DATE ? dayjs(config.MAINTENANCE_START_DATE) : undefined;
    const endDate = config.MAINTENANCE_END_DATE ? dayjs(config.MAINTENANCE_END_DATE) : undefined;

    const valid = (startDate && startDate.isValid() && endDate && endDate.isValid()) == true;
    const now = dayjs();
    const active = (valid && !now.isBefore(startDate) && !now.isAfter(endDate)) == true;

    return {
      start: valid ? startDate.toDate() : undefined,
      end: valid ? endDate.toDate() : undefined,
      active: active,
      endsAfterNow: valid && endDate.isAfter(now),
    };
  }

  public dismissBanner() {
    this._bannerDismissed = true;
  }

  public get bannerDismissed() {
    return this._bannerDismissed;
  }
}

export interface ScheduledMaintenance {
  start?: Date;
  end?: Date;
  active: boolean;
  endsAfterNow: boolean;
}
