import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { mod10Validation } from '@common/validation';

export const LegalNamePattern = "^[ ]*[a-zA-Z]+[a-zA-Z|.|'|\\-| ]*$";

export class PersonalIdentifierValidator {
  /**
   * Validator for legal name in Alberta
   * https://www.alberta.ca/restrictions-respecting-personal-names.aspx
   */
  // It is better to allow invalid names than to disallow valid, so this regex just checks basic allowed character rules
  static legalNameValidator: ValidatorFn = (control: AbstractControl) => {
    const validationReturn = Validators.pattern(LegalNamePattern)(control);
    if (validationReturn) return { legalName: true };
  };

  static sinValidator: ValidatorFn = (control: AbstractControl) => {
    return mod10Validation(control.value, 8);
  };

  static ahnValidator: ValidatorFn = (control: AbstractControl) => {
    return mod10Validation(control.value, 5);
  };
}
