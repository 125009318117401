import { Lookup } from './lookup.interface';

export class NeedsIdentificationStatuses {
  static readonly NiCompleted: Lookup = { displayValue: 'Completed', code: 'NiCompleted' };
  static readonly NiNotApplicable: Lookup = { displayValue: 'Not applicable', code: 'NiNotApplicable' };
  static readonly NiNotCompleted: Lookup = { displayValue: 'Not completed', code: 'NiNotCompleted' };

  static readonly KeyLookup = {
    NiCompleted: NeedsIdentificationStatuses.NiCompleted,
    NiNotApplicable: NeedsIdentificationStatuses.NiNotApplicable,
    NiNotCompleted: NeedsIdentificationStatuses.NiNotCompleted,
  };

  static readonly ALL = Object.values(NeedsIdentificationStatuses.KeyLookup) as Lookup[];

  static readonly CanProceed = [NeedsIdentificationStatuses.NiCompleted, NeedsIdentificationStatuses.NiNotApplicable];
}
