import { Component, Input } from '@angular/core';

@Component({
  selector: 'common-matrix-form-label',
  templateUrl: './matrix-form-label.component.html',
  styleUrls: ['./matrix-form-label.component.scss'],
})
export class MatrixFormLabelComponent {
  @Input() label: string;
  /**
   * Whether this form is optional
   */
  @Input() isOptional = false;
  /**
   * Help text for the form
   */
  @Input() helperMessage: string;
}
