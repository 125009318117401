import { registerDecorator } from 'class-validator';

export function validatePostalCode(postalCode: string, validateProvince?: string) {
  //Note closely the rules:
  // chars cannot be D, F, I, O, Q, U
  // additionally, 1st char cannot be W, Z
  const defaultPostalCodeRegExp = /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][\s-]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
  const albertaPostalCodeRegExp = /^[T]\d[ABCEGHJ-NPRSTV-Z][\s-]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

  if (validateProvince && validateProvince.trim().length > 0 && validateProvince === 'AB') {
    return albertaPostalCodeRegExp.test(postalCode);
  }

  return defaultPostalCodeRegExp.test(postalCode);
}

export function PostalCodeFormat(validateProvince?: string) {
  return function (object, propertyName: string) {
    registerDecorator({
      name: 'PostalCodeFormat',
      target: object.constructor,
      propertyName: propertyName,
      validator: {
        validate(postalCode: string) {
          return validatePostalCode(postalCode, validateProvince);
        },
        defaultMessage(): string {
          return 'Postal Code must be a valid Postal Code';
        },
      },
    });
  };
}
