import { registerDecorator } from 'class-validator';

export function IsConsentValid() {
  return function (object, propertyName: string) {
    registerDecorator({
      name: 'IsConsentValid',
      target: object.constructor,
      propertyName: propertyName,
      validator: {
        validate(value: boolean) {
          let isValid = false;
          if (value) {
            isValid = true;
          }
          return isValid;
        },
        defaultMessage(): string {
          return 'Applicant consent is missing.';
        },
      },
    });
  };
}
