import { Component, Input } from '@angular/core';

@Component({
  selector: 'common-displayfield-control',
  templateUrl: './displayfield-control.component.html',
  styleUrls: ['./displayfield-control.component.scss'],
})
export class DisplayfieldControlComponent {
  @Input() label;
}
