<h3 mat-dialog-title>{{ data?.title }}</h3>
<div class="content" mat-dialog-content>
  <p *ngIf="data?.message" [innerHTML]="data?.message"></p>
  <ng-container *ngIf="data.messages">
    <p *ngFor="let message of data.messages" [innerHTML]="message"></p>
  </ng-container>
</div>

<div mat-dialog-actions [ngClass]="data.reverseButtons ? 'button-footer-reverse' : 'button-footer'">
  <button
    [ngClass]="{ warning: data?.destructiveConfirm }"
    autofocus
    goa-button
    [mat-dialog-close]="true"
    [buttonSize]="data.buttonSize ? data.buttonSize : 'normal'"
    data-cy="confirm-yes"
    class="goa-button"
  >
    {{ data?.confirmLabel || 'Yes' }}
  </button>
  <button
    goa-button
    buttonType="secondary"
    [buttonSize]="data.buttonSize ? data.buttonSize : 'normal'"
    data-cy="confirm-no"
    [mat-dialog-close]="false"
  >
    {{ data?.cancelLabel || 'No ' }}
  </button>
</div>
