export abstract class ClipboardTransformerBase {
  abstract transform(pastedDocument: Document);

  filterNode(node: HTMLElement, match: (el: HTMLElement) => boolean): HTMLElement[] {
    let matches = [] as HTMLElement[];

    if (match(node)) {
      matches = [node];
    }

    for (const i in node.childNodes) {
      const child = node.childNodes[i];
      if (child instanceof HTMLElement) {
        matches = [...matches, ...this.filterNode(child, match)];
      }
    }

    return matches;
  }
}
