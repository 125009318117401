import { Lookup } from '../lookup.interface';
export interface ReferralStatusLookup extends Lookup {
  caseDisplayValue: string;
}
export const ReferralStatuses: { [name: string]: ReferralStatusLookup } = {
  sent: { code: 'sent', displayValue: 'Sent', caseDisplayValue: 'Referral sent' },
  accepted: { code: 'accepted', displayValue: 'Accepted', caseDisplayValue: 'Referral accepted' },
  rejected: { code: 'rejected', displayValue: 'Rejected', caseDisplayValue: 'Referral rejected' },
  assigned: { code: 'assigned', displayValue: 'Assigned', caseDisplayValue: 'Assigned to Service Provider' },
  completed: { code: 'completed', displayValue: 'Completed', caseDisplayValue: 'Completed CEIS service' },
};
