import { Component, Input } from '@angular/core';
/**
 * Component which wraps a fieldsetith optional, helper message, and validation functionality.
 */
@Component({
  selector: 'common-fieldset-control',
  templateUrl: './fieldset-control.component.html',
  styleUrls: ['./fieldset-control.component.scss'],
})
export class FieldsetControlComponent {
  /**
   * The label text.  Note this won't apply to any input's in the content.  Use aria-label for individual inputs.
   */
  @Input() label: string;
  /**
   * The label text.  Note this won't apply to any input's in the content.  Use aria-label for individual inputs.
   */
  @Input() showLabel = true;
  /**
   * Whether this form is optional
   */
  @Input() isOptional = false;
  /**
   * Help message for the form
   */
  @Input() helperMessage: string;
  /**
   * Help text for the form
   */
  @Input() helperText: string;

  @Input() container;
  @Input() info;

  @Input() infoTooltip: string;
}
