import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { validatePostalCode } from '@common/validation';
@Directive({
  selector: '[iesPostalCodeValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: PostalCodeValidateDirective,
      multi: true,
    },
  ],
})
export class PostalCodeValidateDirective implements Validator {
  @Input() postalCodeFormat: string;
  validate(control: AbstractControl) {
    if (!control.value) return null;
    const isValid = validatePostalCode(control.value, this.postalCodeFormat);
    return isValid ? null : { invalidPostalCode: control.value };
  }
}
