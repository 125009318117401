import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FscdIntakeApplication } from '@fscd-intake/entities';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FiGraphqlService } from '../../services/fi-graphql.service';

@Component({
  selector: 'fi-developer-tools',
  templateUrl: './developer-tools.component.html',
  styleUrls: ['./developer-tools.component.scss'],
})
export class DeveloperToolsComponent implements OnInit, OnDestroy {
  applicationId: string;
  application$: BehaviorSubject<FscdIntakeApplication> = new BehaviorSubject<FscdIntakeApplication>(null);

  private subscription: Subscription;
  constructor(
    private route: ActivatedRoute,
    private dataService: FiGraphqlService,
    private authService: AuthenticationService
  ) {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.applicationId = this.route.snapshot.paramMap.get('applicationId');
    this.subscription = this.dataService.getFullApplication(this.applicationId).subscribe((data) => {
      this.application$.next(data);
    });
  }
}
