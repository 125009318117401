import { AlbertaAddress, BasePerson, PhoneNumber } from '@common/entities';
import { Expose, Type } from 'class-transformer';
import { IsDefined, IsEmail, IsNotEmpty, ValidateIf, ValidateNested } from 'class-validator';
import { IsFullAddress } from '@common/validation';
import { HaveValidNumbers } from '../custom-validation-functions/have-valid-number';

export class FiSecondParent extends BasePerson {
  @Expose()
  preferredName?: string;

  @Expose()
  @IsDefined()
  isEnglishFirstLanguage?: boolean;

  @Expose()
  @ValidateIf((o) => o.isEnglishFirstLanguage == false)
  @IsNotEmpty()
  primaryLanguage?: string;

  @Expose()
  @ValidateIf((o) => o.isEnglishFirstLanguage == false)
  @IsDefined()
  requiresInterpreter?: boolean;

  @Expose()
  @IsDefined()
  haveOtherCommunicationNeed?: boolean;

  @Expose()
  @ValidateIf((o) => o.haveOtherCommunicationNeed)
  @IsNotEmpty()
  otherCommunicationNeed?: string;

  @Expose()
  @Type(() => PhoneNumber)
  @ValidateNested()
  @HaveValidNumbers()
  @IsNotEmpty()
  phoneNumbers?: Array<PhoneNumber>;

  @Expose()
  @IsDefined()
  @IsEmail()
  email?: string;

  @Expose()
  @IsDefined()
  hasSameAddressAsChild: boolean;

  @Expose()
  @Type(() => AlbertaAddress)
  @ValidateIf((o) => o.hasSameAddressAsChild == false)
  @ValidateNested()
  @IsFullAddress()
  residentialAddress?: AlbertaAddress;

  @Expose()
  @IsDefined()
  relationToChild?: string;
}
