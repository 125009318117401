import { Component, ElementRef, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'common-info-icon-tooltip',
  templateUrl: './info-icon-tooltip.component.html',
  styleUrls: ['./info-icon-tooltip.component.scss'],
})
export class InfoIconTooltipComponent {
  @Input() message: string;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}
}
