import { Lookup } from './lookup.interface';


const parentGuardianCare = 'Parent/Guardian care';
const extendedFamilyCare = 'Extended family (kinship) care';
const interventionServicesCare = 'In the care of child intervention services';
const outOfHomeLiving = 'Out-of-home living arrangements';


export const FiLivingArrangements: { [name: string]: Lookup } = {
  parentGuardianCare: { displayValue: parentGuardianCare, code: parentGuardianCare },
  extendedFamilyCare: { displayValue: extendedFamilyCare, code: extendedFamilyCare },
  interventionServicesCare: { displayValue: interventionServicesCare, code: interventionServicesCare },
  outOfHomeLiving: { displayValue: outOfHomeLiving, code: outOfHomeLiving },


};

