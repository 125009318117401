import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@common/entities';

/**
 * Pipe for address.  Accepts a lineNumber arg.
 * line1 = street address
 * line2 = suite
 * line3 = city, province, postalcode
 * no line = suite-street, city, province, postalcode
 */
@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
  transform(address: Address, lineNumber?: 'line1' | 'line2', allowIncomplete = false): string {
    if (!address) return '';

    const theAddress = { ...address };
    if (!theAddress.streetAddress || !theAddress.city || !theAddress.postalCode || theAddress.postalCode.length !== 6) {
      if (!allowIncomplete) return '';

      if (!theAddress.city?.trim()) theAddress.city = '<City not set>';
      if (!theAddress.streetAddress?.trim()) theAddress.streetAddress = '-';
    }

    if (!lineNumber && theAddress.suiteNumber) {
      return `${theAddress.suiteNumber}-${theAddress.streetAddress}, ${theAddress.city}, ${
        theAddress.province || 'Alberta'
      }, ${this.getPostalCode(theAddress.postalCode)}`;
    }

    if (!lineNumber) {
      return `${theAddress.streetAddress}, ${theAddress.city}, ${
        theAddress.province || 'Alberta'
      }, ${this.getPostalCode(theAddress.postalCode)}`;
    }

    if (lineNumber === 'line1') {
      return `${theAddress.streetAddress} ${theAddress.suiteNumber ? '#' + theAddress.suiteNumber : ''}`;
    }

    if (lineNumber === 'line2') {
      return `${theAddress.city}, ${theAddress.province || 'Alberta'}, ${this.getPostalCode(theAddress.postalCode)}`;
    }
  }

  private getPostalCode(postalCode: string): string {
    if (postalCode && postalCode.length === 6) {
      return `${postalCode.slice(0, 3)} ${postalCode.slice(3)}`;
    } else return '<Postal code not set>';
  }
}
