import { Component, Input } from '@angular/core';
import { FiChild, FiParent, FiServices } from '@fscd-intake/entities';

@Component({
  selector: 'fi-view-application-tabs-mobile-view',
  templateUrl: './view-application-tabs-mobile-view.component.html',
  styleUrls: ['./view-application-tabs-mobile-view.component.scss'],
})
export class ViewApplicationTabsMobileViewComponent {
  @Input() child: FiChild;
  @Input() parent: FiParent;
  @Input() services: FiServices;

  constructor() {}
}
