<div class="primary-number-control">
  <common-fieldset-control label="Primary phone number" [showLabel]="true">
    <div class="phone-number-row" *ngFor="let phoneNumberGroup of formArray.controls; let $index = index">
      <div class="phone-number-container">
        <mat-radio-button
          *ngIf="formArray.controls.length >= 2"
          [aria-label]="'Make number ' + phoneNumberGroup.phoneNumber + ' primary'"
          [value]="$index"
          [name]="_uniqueGroupId"
          [checked]="phoneNumberGroup.value.isPrimary"
          (change)="isPrimaryChanged(phoneNumberGroup)"
          class="phone-number-primary"
        ></mat-radio-button>
        <span class="phone-number-input">
          <common-phone-number
            [dataCy]="'phoneNumber' + $index.toString()"
            [formControl]="phoneNumberGroup"
            [isTouched]="phoneNumberGroup.touched"
            [hideLabel]="true"
          ></common-phone-number>
        </span>
        <span
          class="phone-number-delete"
          *ngIf="formArray.length > 1 || allowNoNumbers"
          iesIconButton
          aria-label="Remove phone number"
          (click)="deleteNumber($index)"
        >
          <span class="material-icons" [attr.data-cy]="'phoneNumber' + $index.toString() + '-removeButton'">
            highlight_off
          </span>
        </span>
      </div>
    </div>
    <button
      goa-button
      buttonType="tertiary"
      class="phone-number-add"
      [disabled]="hasInvalidOrEmptyPhoneNumber()"
      (click)="addNumber()"
      data-cy="phoneNumber-addButton"
    >
      + Add another number
    </button>
  </common-fieldset-control>
</div>
