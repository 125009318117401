import { UploadLookup } from './upload-lookup.interface';
export const AssetTypes: { [name: string]: UploadLookup } = {
  bankAccounts: {
    displayValue: 'Bank accounts',
    documentTypes: ['Statement from bank account'],
    needSupportDocument: true,
    order: 1,
    helpText: 'Provide bank statements for the last 60 days.',
  },
  insurance: { displayValue: 'Life insurance', documentTypes: [], needSupportDocument: true, order: 2 },
  termDeposits: {
    displayValue: 'Term deposits',
    documentTypes: ['Bank statement'],
    needSupportDocument: true,
    order: 3,
  },
  tfsa: {
    displayValue: 'Tax Free Savings Account (TFSA)',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 4,
  },
  rrsp: {
    displayValue: 'Registered Retirement Savings Plan (RRSP)',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 5,
  },
  gic: {
    displayValue: 'Guaranteed Investment Certificate (GIC)',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 6,
  },
  rrif: {
    displayValue: 'Registered Retirement Income Fund (RRIF)',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 7,
  },
  annuities: {
    displayValue: 'Annuities',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 8,
  },
  lira: {
    displayValue: 'Locked-In Retirement Account (LIRA)',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 9,
  },
  rdsp: {
    displayValue: 'Registered Disability Savings Plan (RDSP)',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 10,
  },
  resp: {
    displayValue: 'Registered Education Savings Plan (RESP)',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 11,
  },
  stocksAndBonds: {
    displayValue: 'Stocks and/or bonds',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 12,
  },
  trustFunds: {
    displayValue: 'Trust funds',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 13,
  },
  funeral: {
    displayValue: 'Pre-paid funeral',
    documentTypes: ['Copy of receipt'],
    needSupportDocument: true,
    order: 14,
  },
  other: { displayValue: 'Other investment(s)', documentTypes: [], needSupportDocument: true, order: 14 },
  uncashedChequesOrCashOnHand: {
    displayValue: 'Uncashed cheques or cash on hand',
    documentTypes: [],
    needSupportDocument: false,
    order: 15,
  },
};
