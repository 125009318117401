import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FscdIntakeSessionStorageKeys } from '@common/constants';
import { AppConfigService, FileService } from '@common/ui/shared-components';
import { Utilities } from '@common/utils';
import { FiChild, FscdIntakeApplication } from '@fscd-intake/entities';
import { Apollo } from 'apollo-angular';
import { lastValueFrom } from 'rxjs';
import { FiGraphqlService } from '../../services/fi-graphql.service';
import {
  existingApplicationDraft,
  existingApplicationRemoved,
  existingApplicationSubmitted,
} from '../../shared/components/dashboard-modals/dashboard-page.modals';
import { DashboardListApplication } from '../../shared/interfaces/dashboard-list-application';
import { FiUtils } from '../../shared/utils/fi-utils';

@Component({
  selector: 'fi-submitted',
  templateUrl: './submitted.component.html',
  styleUrls: ['./submitted.component.scss'],
})
export class SubmittedComponent implements OnInit {
  @Input() application: FscdIntakeApplication;

  showStartAppModal = new EventEmitter<boolean>();
  encryptionKey = null;

  rawApplications: FscdIntakeApplication[] = [];
  applicationsDraft: DashboardListApplication[] = [];
  applicationsSubmitted: DashboardListApplication[] = [];
  applicationsRemoved: DashboardListApplication[] = [];

  constructor(
    apollo: Apollo,
    private configService: AppConfigService,
    private router: Router,
    private appExistsModal: MatDialog,
    private graphqlService: FiGraphqlService,
    private utils: FiUtils,
    private fileService: FileService
  ) {}

  async ngOnInit() {
    if (this.configService) {
      this.encryptionKey = this.configService.getConfig().ENCRYPTION_KEY;
    }
    await this.loadApplications();
  }

  async loadApplications() {
    this.rawApplications = await lastValueFrom(this.graphqlService.getAllApplications());
    const applicationsAll = this.rawApplications.map(this.utils.createDashboardListApplication);
    this.applicationsDraft = applicationsAll.filter((listApp) => listApp.isDraft);
    this.applicationsSubmitted = applicationsAll.filter((listApp) => listApp.isSubmitted);
    this.applicationsRemoved = applicationsAll.filter((listApp) => listApp.isRemoved);
  }

  async onStartAppModalContinue(newChild: FiChild) {
    const hasNameMatch = this.utils.hasNameMatch(newChild);
    const draftApp = this.applicationsDraft.find(hasNameMatch);
    const isInSubmitted = !!this.applicationsSubmitted.find(hasNameMatch);
    const isInRemoved = !!this.applicationsRemoved.find(hasNameMatch);

    let dashboardPageUrlParam = null;
    if (draftApp) {
      const navToDraft = await existingApplicationDraft(this.appExistsModal, {
        confirmLabel: 'Continue',
        cancelLabel: 'Cancel',
      });

      if (navToDraft) this.onContinueApplication(draftApp);
      return;
    }

    if (isInSubmitted) {
      const showTabSubmitted = await existingApplicationSubmitted(this.appExistsModal, {
        confirmLabel: 'View',
        cancelLabel: 'Cancel',
      });

      if (!showTabSubmitted) return;
      dashboardPageUrlParam = 'submitted';
    }

    if (isInRemoved) {
      const showTabRemoved = await existingApplicationRemoved(this.appExistsModal, {
        confirmLabel: 'Restore',
        cancelLabel: 'Cancel',
      });

      if (!showTabRemoved) return;
      dashboardPageUrlParam = 'removed';
    }

    if (dashboardPageUrlParam) {
      this.router.navigateByUrl('dash?tab=' + dashboardPageUrlParam);
    } else {
      Utilities.setSessionStorageValue(FscdIntakeSessionStorageKeys.ChildName.key, newChild, this.encryptionKey);
      this.router.navigateByUrl('/prescreen');
    }
  }

  async onContinueApplication(application: DashboardListApplication) {
    window.open('application;applicationId=' + application.id, '_self');
  }

  onAddNewApplication() {
    this.showStartAppModal.emit(true);
  }

  onClickDownloadPdf() {
    this.fileService.getDocument(this.application.outputPdfFileName, this.application.id).subscribe((result) => {
      window.open(result['URL']);
    });
  }

  onViewMyApplications() {
    this.router.navigateByUrl('dash?tab=submitted');
  }
}
