import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponentCanDeactivateDirective } from '../../../ui-shared-components.directives';
import { MessageDialogComponent } from '../../../ui-shared-components.components';

@Injectable()
export class CannotLeaveGuard {
  constructor(private _dialog: MatDialog) {}

  async canDeactivate(component: AbstractComponentCanDeactivateDirective) {
    const { canDeactivate, title, message } = component.canDeactivate();

    if (canDeactivate) return true;
    if (this._dialog.openDialogs.length > 0) return await lastValueFrom(this._dialog.openDialogs[0].afterClosed());

    const dialogRef = this._dialog.open(MessageDialogComponent, {
      autoFocus: false,
      data: { title, description: message, messageDialog: true },
    });

    await lastValueFrom(dialogRef.afterClosed());
    return false;
  }
}
