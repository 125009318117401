import { Lookup } from './lookup.interface';

export const Disabilities: { [name: string]: Lookup } = {
  cognitive: { displayValue: 'Cognitive' },
  environmental: { displayValue: 'Environmental' },
  hearing: { displayValue: 'Hearing' },
  learning: { displayValue: 'Learning' },
  mentalHealth: { displayValue: 'Mental health' },
  mobility: { displayValue: 'Mobility' },
  psychology: { displayValue: 'Psychology' },
  sensory: { displayValue: 'Sensory' },
  speech: { displayValue: 'Speech' },
  vision: { displayValue: 'Vision' },
};
