<div
  class="common-matrix-form-control"
  [ngClass]="{ 'has-error': hasError }"
  [attr.aria-invalid]="hasError ? 'true' : 'false'"
  [attr.aria-label]="label"
>
  <label class="col-label label">
    <span>{{ colLabel }}</span>
    <span class="optional-col-label optional-label" *ngIf="isOptional">(Optional)</span>
  </label>
  <ng-content></ng-content>
  <ng-container *ngIf="hasError">
    <div class="error-text" *ngIf="control.errors?.required">This field is required.</div>
    <div class="error-text" *ngIf="control.errors.mask || control.errors?.mod10Validation">
      {{ label }} has invalid value.
    </div>
  </ng-container>
</div>
