export const APPLICATION_ERROR_FRAGMENT = `
  target
  property
  constraints
  type
`;

export const APPLICATION_ERRORS_FRAGMENT = `
  parentGuardianErrors {
    ${APPLICATION_ERROR_FRAGMENT}
  }
  childErrors {
    ${APPLICATION_ERROR_FRAGMENT}
  }
  servicesErrors {
    ${APPLICATION_ERROR_FRAGMENT}
  }
  reviewErrors {
   ${APPLICATION_ERROR_FRAGMENT}
  }
`;

export const DOCUMENT_FRAGMENT = `
  documentCategory
  documentType
  key
  name
  size
  completed
`;

export const ADDRESS_FRAGMENT = `
  streetAddress
  suiteNumber
  city
  province
  postalCode
`;

export const TOMB_STONE_FRAGMENT = `
  firstName
  middleName
  lastName
  preferredName
  birthdate
  gender
`;

export const SERVICES_FRAGMENT = `
  homeRespite
  medicalAppointment
  counselling
  clothingFootwear
  agreementDuration  
`;

export const FSCD_APPLICATION_LIST = `
  id
  applicationNumber
  authorizedUser
  createdAt
  updatedAt
  isSubmitted
  submittedOn
  isRemoved
  removedOn
  isUploaded
  documentCount
  outputPdfFileName
  outputPdfUrl
  child { 
    firstName
    lastName
   }
  parent { 
    firstName
    lastName 
    phoneNumbers { 
      phoneNumber
      type 
    } 
  }
`;

export const LANGUAGE_FRAGMENT = `
  isEnglishFirstLanguage
  primaryLanguage
  requiresInterpreter
  haveOtherCommunicationNeed
  otherCommunicationNeed
`;

export const FSCD_APPLICATION_DETAIL = `
  id
  applicationNumber
  authorizedUser
  createdAt
  updatedAt
  isSubmitted
  submittedOn
  isRemoved
  removedOn
  isUploaded
  documentCount
  outputPdfFileName
  outputPdfUrl
  child {
    _id
    ${TOMB_STONE_FRAGMENT}
    hasPrevApplication
    hasPrevClosedWithin90
    citizenImmigrationStatus
    identityDocuments {
      ${DOCUMENT_FRAGMENT}
    }
    residentialAddress {
      ${ADDRESS_FRAGMENT}
    }
    hasSameMailingAddress
    mailingAddress {
      ${ADDRESS_FRAGMENT}
    }
    livingArrangements
    hasConfirmedDiagnosis
    isAwaitingDiagnosis
    medicalDocuments {
      ${DOCUMENT_FRAGMENT}
    }
    additionalDocuments {
      documentCategory
      documentType
      key
      name
      size
      completed
    }
    confirmedDiagnosis
    waitingDiagnosis
  }
  parent {
    _id
    ${TOMB_STONE_FRAGMENT}
    ${LANGUAGE_FRAGMENT}
    phoneNumbers {
      phoneNumber
      type
    }
    email
    hasSameAddressAsChild
    residentialAddress {
      ${ADDRESS_FRAGMENT}
    }
    relationToChild
    documents {
      ${DOCUMENT_FRAGMENT}
    }
    learnAboutFscdPhysician
    learnAboutFscdHospital
    learnAboutFscdAgency
    learnAboutFscdEarlyInterventionProgram
    learnAboutFscdChildrensLink
    learnAboutFscdHealthUnit
    learnAboutFscdSchool
    learnAboutFscdChildrensServices
    learnAboutFscdOther
    learnAboutFscdOtherDetail
    hasSecondParent
    secondParent {
      ${TOMB_STONE_FRAGMENT}
      ${LANGUAGE_FRAGMENT}
      phoneNumbers {
        phoneNumber
        type
      }
      email
      hasSameAddressAsChild
      residentialAddress {
        ${ADDRESS_FRAGMENT}
      }
      relationToChild
    }
  }
  services {
    ${SERVICES_FRAGMENT}
  }
   review {
    isConsentChecked
  }
  applicationErrors {
    ${APPLICATION_ERRORS_FRAGMENT}
  }
`;
