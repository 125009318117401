import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { from, InMemoryCache } from '@apollo/client/core';
import { AppConfigService, UnauthenticatedErrorLink } from '@common/ui/shared-components';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { KeycloakService } from 'keycloak-angular';

export function createApollo(
  httpLink: HttpLink,
  configService: AppConfigService,
  apolloLink: UnauthenticatedErrorLink
) {
  const config = configService.getConfig();
  return {
    link: from([apolloLink.errorLink, httpLink.create({ uri: config.FSCD_INTAKE_SERVICE_URL })]),
    cache: new InMemoryCache({
      addTypename: true,
      typePolicies: {
        UserDto: {
          keyFields: ['_id', 'email'],
        },
      },
    }),
  };
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [ApolloModule],
  providers: [
    UnauthenticatedErrorLink,
    KeycloakService,
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AppConfigService, UnauthenticatedErrorLink],
    },
  ],
})
export class GraphQLModule {}
