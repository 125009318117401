import { OneToFiveScaleWithNA, YesNoScale } from './assessment-scales';
import { SkillsLookup } from './skills-lookup.interface';

export const SocialFactors: { [name: string]: SkillsLookup } = {
  housingSupports: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Housing supports',
    helperText:
      'Housing supports refer to supports and services such as rental and rent supplement programs usually administered by community organizations and municipalities. It can include subsidized rental units or rental subsidies. ',
    sampleQuestions: [
      { question: 'Tell me about your living situation.' },
      { question: 'What kinds of support might you need to ensure you have safe and stable housing?' },
    ],
  },
  addictionsSupports: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Addictions supports',
    helperText:
      'Treatment services and supports can help people experiencing harms from substance use or addiction. Services can include early identification and intervention, harm reduction approaches, and relapse prevention. There is no one-size-fits-all treatment option, each person’s experience is unique.',
    sampleQuestions: [
      {
        question: 'What kinds of addiction supports have you accessed in the past?',
        followupQuestions: [{ question: 'What was the outcome?' }],
      },
      {
        question: 'What steps have you taken to deal with your addictions? What more can you do?',
      },
    ],
  },
  familySupports: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Family supports',
    helperText:
      'Family Support is important when individuals are taking the major step of returning to school, looking for employment, starting a new job, or moving between employment opportunities. Relocation is often a major strain on family relationships.',
    sampleQuestions: [
      { question: 'Tell me about the support that you have in your life right now.' },

      { question: 'How might your family or friends help you in your job search?' },

      {
        question: 'What sort of support are you good at providing to important people in your life?',
        followupQuestions: [{ question: 'How do you receive that kind of support from them?' }],
      },
    ],
  },
  communitySupports: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Community supports',
    helperText:
      'Community supports systems may be organized through extended family members, neighbours, religious organizations, community programs, cultural, and ethnic organizations, or other support groups or organizations.',
    sampleQuestions: [
      { question: 'Tell me about supports you have in your community.' },
      { question: 'What steps could you take now to start to widen your circle of support?' },
    ],
  },
  homelessness: {
    scale: YesNoScale,
    displayValue: 'Homelessness',
    helperText:
      'Homelessness is the situation of an individual, family, or community without stable, safe, permanent, appropriate housing, or the immediate prospect, means and ability to acquiring it. Not everyone experiences homelessness the same, every situation is unique.',
    sampleQuestions: [
      { question: 'Tell me about your living situation.' },
      { question: 'Where are you living right now?' },
      { question: 'Do you have, or are you on a lease?' },
    ],
  },
};
