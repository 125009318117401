<div class="common-matrix-form-label">
  <label class="label">
    <span>{{ label }}</span>
    <span class="optional-label" *ngIf="isOptional">(Optional)</span>
  </label>
  <ng-content></ng-content>
  <div *ngIf="helperMessage" class="helper-text">
    {{ helperMessage }}
  </div>
</div>
