import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enabledDisabled',
})
export class EnabledDisabledPipe implements PipeTransform {
  transform(value: string): string {
    return value ? 'Enabled' : 'Disabled';
  }
}
