import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BaseSaveComponent } from '../base-save/base-save.component';
import { PageNavigation } from '../page-navigation';
import { Apollo } from 'apollo-angular';
import { ContextProvider, FileService } from '@common/ui/shared-components';
import { FiGraphqlService } from '../../services/fi-graphql.service';
import { Observable, Subject, lastValueFrom, map, takeUntil } from 'rxjs';
import { FiServices, FscdIntakeApplication, FscdIntakeApplicationErrors } from '@fscd-intake/entities';
import { ValidationUtils } from '@common/utils';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';

@Component({
  selector: 'fi-services-page',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesPageComponent extends BaseSaveComponent implements OnInit, OnDestroy, PageNavigation {
  private destroy$ = new Subject<void>();
  application: FscdIntakeApplication;
  isSubmitted: boolean;
  loading = true;
  services$: Observable<FiServices>;
  hasErrors$: Observable<boolean>;
  hasConfirmedDiagnosis$: Observable<boolean>;
  servicesFormValue;

  constructor(
    apollo: Apollo,
    fileService: FileService,
    authenticationService: AuthenticationService,
    contextProvider: ContextProvider,
    private graphqlService: FiGraphqlService,
    private cdr: ChangeDetectorRef
  ) {
    super(apollo, fileService, authenticationService, contextProvider, graphqlService);
  }
  next(): boolean {
    return true;
  }

  async ngOnInit() {
    await super.ngOnInit();
    await this.loadServices();
  }

  async loadServices() {
    const { selectedApplicationId, isSubmitted } = await this.graphqlService.getSelectedApplication();
    this.isSubmitted = isSubmitted;
    this.applicationId = selectedApplicationId;
    if (this.applicationId) {
      const results$ = this.graphqlService.getFullApplication(this.applicationId);
      this.services$ = results$.pipe(takeUntil(this.destroy$)).pipe(
        map((application) => {
          this.application = application;
          return application.services;
        })
      );

      this.hasConfirmedDiagnosis$ = results$.pipe(
        map((application) => {
          return application?.child?.hasConfirmedDiagnosis || false;
        })
      );

      this.hasErrors$ = results$.pipe(
        map((application) => {
          return application?.applicationErrors?.servicesErrors?.length > 0 || false;
        })
      );
    }
    this.cdr.detectChanges();
  }

  async onFormError() {
    this.displayStaticToastError('One or more fields are not filled in correctly.');
  }

  async onFormUpdated(formValue) {
    this.servicesFormValue = formValue;
  }

  async save() {
    super.save();
    const updatedFormValue = this.servicesFormValue;
    const services = {
      ...updatedFormValue,
    } as FiServices;

    const applicationErrors = this.application?.applicationErrors
      ? { ...this.application.applicationErrors }
      : new FscdIntakeApplicationErrors();
    applicationErrors.servicesErrors = ValidationUtils.validateEntity(FiServices, services);

    const applicationToSave = {
      id: this.applicationId,
      services: services,
      applicationErrors: applicationErrors,
    } as FscdIntakeApplication;
    return lastValueFrom(this.graphqlService.updateApplication(applicationToSave));
  }

  async ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
