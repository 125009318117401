<div *ngIf="!isSubmitted">
  <fi-parent-info
    [applicationId]="applicationId"
    [profile]="profile$ | async"
    [parentInfo]="parentInfo$ | async"
    [hasErrors]="hasErrors$ | async"
    (formError)="onFormError()"
    (formUpdated)="onFormUpdated($event)"
    (blockSaveMessage)="setBlockSaveMessage($event)"
  >
  </fi-parent-info>
</div>
