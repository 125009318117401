import { Lookup } from './lookup.interface';

export interface IesImmigrationTypesLookup extends Lookup {
  typeSponsored?: boolean;
  descriptionRequired?: boolean;
}

export const IesImmigrationTypes: { [name: string]: IesImmigrationTypesLookup } = {
  canadian: {
    code: 'canadian',
    displayValue: 'Canadian citizen',
    typeSponsored: false,
    descriptionRequired: false,
  },

  permenantResident: {
    code: 'permenantResident',
    displayValue: 'Permanent resident of Canada',
    typeSponsored: true,
    descriptionRequired: false,
  },

  protectedPerson: {
    code: 'protectedPerson',
    displayValue: 'Protected person',
    typeSponsored: false,
    descriptionRequired: false,
  },

  temporaryResident: {
    code: 'temporaryResident',
    displayValue: 'Temporary resident',
    typeSponsored: true,
    descriptionRequired: false,
  },

  refugeeClaimant: {
    code: 'refugeeClaimant',
    displayValue: 'Refugee claimant',
    typeSponsored: false,
    descriptionRequired: false,
  },

  noStatusInCanada: {
    code: 'noStatusInCanada',
    displayValue: 'No status in Canada',
    typeSponsored: false,
    descriptionRequired: false,
  },
};
