import { LocationList } from './lookup.interface';

export const ProvinceList: LocationList = {
  Alberta: {
    key: 'Alberta',
    displayName: 'Alberta',
    code: 'AB',
    default: true,
    isAlberta: true,
  },
  BritishColumbia: {
    displayName: 'British Columbia',
    code: 'BC',
    default: false,
  },
  Manitoba: {
    displayName: 'Manitoba',
    code: 'MB',
    default: false,
  },
  NewBrunswick: {
    displayName: 'New Brunswick',
    code: 'NB',
    default: false,
  },
  Newfoundland: {
    displayName: 'Newfoundland',
    code: 'NL',
    default: false,
  },
  NorthwestTerritories: {
    displayName: 'Northwest Territories',
    code: 'NT',
    default: false,
  },
  NovaScotia: {
    displayName: 'Nova Scotia',
    code: 'NS',
    default: false,
  },
  Nunavut: {
    displayName: 'Nunavut',
    code: 'NU',
    default: false,
  },
  Ontario: {
    displayName: 'Ontario',
    code: 'ON',
    default: false,
  },
  PrinceEdwardIsland: {
    displayName: 'Prince Edward Island',
    code: 'PE',
    default: false,
  },
  Quebec: {
    displayName: 'Quebec',
    code: 'QC',
    default: false,
  },
  Saskatchewan: {
    displayName: 'Saskatchewan',
    code: 'SK',
    default: false,
  },
  Yukon: {
    displayName: 'Yukon',
    code: 'YT',
    default: false,
  },
};

export function DefaultProvince() {
  const filterProvince = Object.keys(ProvinceList).filter((i) => ProvinceList[i].default);
  const defaultProvince = filterProvince[0];
  return defaultProvince;
}
