import { Component, Input } from '@angular/core';
import { IndianRegistration } from '@common/entities';

@Component({
  selector: 'common-indian-status-view',
  templateUrl: './indian-status-view.component.html',
  styleUrls: ['./indian-status-view.component.scss'],
})
export class IndianStatusViewComponent {
  @Input() indianRegistration: IndianRegistration;
}
