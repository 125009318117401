import { Lookup } from '../lookup.interface';
export const PreInterventionEmploymentDetailsTypes: { [name: string]: Lookup } = {
  employed: { displayValue: 'Employed' },
  unemployed: { displayValue: 'Unemployed' },
  medicalLeave: { displayValue: 'Medical Leave' },
  maternityParentalLeave: { displayValue: 'Maternity/Parental Leave' },
  onCompassionateLeave: { displayValue: 'On Compassionate Leave' },
  selfEmployed: { displayValue: 'Self-Employed' },
  strikeOrLockout: { displayValue: 'Strike or Lockout' },
  unknown: { displayValue: 'Unknown' },
};
