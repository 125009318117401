import { Component, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { FormControlComponent } from '../form-control/form-control.component';

@Component({
  selector: 'common-matrix-form-control',
  templateUrl: './matrix-form-control.component.html',
  styleUrls: ['./matrix-form-control.component.scss'],
})
export class MatrixFormControlComponent extends FormControlComponent {
  /**
   * The label text for the column.
   */
  @Input() colLabel: string;

  constructor(controlContainer: ControlContainer) {
    super(controlContainer);
  }
}
