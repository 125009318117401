<div class="date">
  <mat-date-range-input [rangePicker]="picker" [min]="_minDateDayJs" [max]="_maxDateDayJs">
    <input
      #startInput
      matStartDate
      data-cy="calendar-range-start-input"
      placeholder="MMM D YYYY"
      (keydown)="onKeyDown(dateRangeGroup.controls.fromDate, $event)"
      (blur)="onBlur()"
      [formControl]="dateRangeGroup.controls.fromDate"
    />
    <input
      #endInput
      matEndDate
      data-cy="calendar-range-end-input"
      placeholder="MMM D YYYY"
      (keydown)="onKeyDown(dateRangeGroup.controls.toDate, $event)"
      (blur)="onBlur()"
      [formControl]="dateRangeGroup.controls.toDate"
    />
  </mat-date-range-input>
  <mat-datepicker-toggle
    data-cy="calendar-open-button"
    [disableRipple]="true"
    class="icon from-icon"
    matSuffix
    [for]="picker"
  >
    <span class="from-icon icon" matDatepickerToggleIcon>
      <span class="expander-icon"></span>
    </span>
  </mat-datepicker-toggle>
  <mat-datepicker-toggle
    data-cy="calendar-open-button"
    [disableRipple]="true"
    class="icon to-icon"
    matSuffix
    [for]="picker"
  >
    <span class="to-icon icon" matDatepickerToggleIcon>
      <span class="expander-icon"></span>
    </span>
  </mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</div>
