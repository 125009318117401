<div class="width-900">
  <div class="mt-24">
    <goa-callout type="success">You're in the right place, you can continue with your FSCD application. </goa-callout>
  </div>
  <div class="mv-48">
    <goa-button
      *ngIf="user"
      (_click)="onStartApplication()"
      type="primary"
      class="create-app"
      data-cy="start-new-application"
    >
      + Start a new application
      <mat-icon role="img" aria-hidden="true" class="mat-icon">open_in_new</mat-icon></goa-button
    >
  </div>
  <div *ngIf="!user" class="mv-48">
    <ng-content *ngTemplateOutlet="applyRegister; context: { showHeader: true, showDescription: true }"></ng-content>
  </div>

  <h2 data-cy="page-title">What you need to know before starting your application</h2>

  <div class="section">You will need to include some of the following documents with your application:</div>
  <div>
    <div class="section">
      <div class="section-header">Proof of your child’s citizenship/immigration status</div>
      <span>To be eligible for FSCD, your child must be either a Canadian citizen or permanent resident. </span>
      <p>You would need to upload one of the following documents as proof of their citizenship/immigration status:</p>
      <ul class="goa-unordered-list">
        <li><span>Birth certificate</span></li>
        <li><span>Record of live birth</span></li>
        <li><span>Canadian Passport</span></li>
        <li><span>Canadian Citizenship or Permanent Resident Card</span></li>
        <li><span>IMM5292 Confirmation of Permanent Residency</span></li>
      </ul>
    </div>

    <div class="section">
      <div class="section-header">Your child’s medical documentation/reports</div>
      <div>
        To be eligible for FSCD, your child must have a disability that has been diagnosed by a medical professional or
        be in the process of getting a diagnosis.
      </div>
      <div class="callout">
        <goa-details heading="Click here to check FSCD ACT Definition: Child disability criteria">{{
          tooltipMessage
        }}</goa-details>
      </div>
      <p>The medical documentation must include the following:</p>
      <ul class="goa-unordered-list">
        <li><span>Child’s name and date of birth</span></li>
        <li>
          <span>Child’s diagnosis</span>
          <ul class="goa-unordered-list-circle">
            <li>Optional: information on the impact of the diagnosis on daily functioning</li>
          </ul>
        </li>
        <li><span>Date when the child was diagnosed</span></li>
        <li><span>Name of the medical professional who diagnosed the child</span></li>
        <!-- prettier-ignore -->
        <li><span>If the child is in the process of being diagnosed, information about provisional diagnosis or the condition or impairment that indicates the child may have a disability</span>
        </li>
      </ul>
      <p>
        The medical report or letter should be written by the relevant health professional who is able to make the
        diagnosis or probable diagnosis within the scope of their practice, such as:
      </p>
      <ul class="goa-unordered-list">
        <li><span>Physician or psychiatrist</span></li>
        <li><span>Physical or occupational therapist, speech and language pathologist or audiologist</span></li>
        <li><span>Clinical social worker or psychologist</span></li>
      </ul>
      <p>Medical documentation should be as current as possible.</p>
    </div>

    <div class="section">
      <div class="section-header">Proof of guardianship if you are not the biological parent of the child</div>
      If applicable, you may be asked to provide documents such as a guardianship order, birth certificate, or adoption
      papers.
      <p>
        Please note that an FSCD application is considered complete only after the Application and all supporting
        documents (proof of child’s citizenship/immigration status, medical documentation, and proof of guardianship if
        applicable) are received.
      </p>
    </div>
  </div>

  <ng-content *ngTemplateOutlet="applyRegister; context: { showHeader: false, showDescription: false }"></ng-content>
</div>

<ng-template #applyRegister let-showHeader="showHeader" let-showDescription="showDescription">
  <div *ngIf="!user">
    <h2 *ngIf="showHeader">Sign in / Create an account</h2>
    <p *ngIf="showDescription">
      You will need to create an account in order to start your FSCD application. If you already have an account, please
      sign in.
    </p>

    <div [class.section]="showHeader" [class.section-header]="!showHeader">
      <goa-button data-cy="sign-in" (_click)="login()">Sign in <mat-icon>open_in_new</mat-icon></goa-button>
      &nbsp;
      <goa-button data-cy="create-account" (_click)="createAccount()">
        Create account <mat-icon>open_in_new</mat-icon></goa-button
      >
    </div>
  </div>

  <div *ngIf="user" class="mt-48">
    <goa-button
      *ngIf="user"
      (_click)="onStartApplication()"
      type="primary"
      class="create-app"
      data-cy="create-application"
    >
      + Start a new application
      <mat-icon role="img" aria-hidden="true" class="mat-icon">open_in_new</mat-icon></goa-button
    >
  </div>
</ng-template>

<!-- Start a new application -->
<fi-start-app-modal
  [showModal]="showStartAppModal"
  (startNewApp)="onStartAppModalContinue($event)"
></fi-start-app-modal>
