import { gql } from '@apollo/client/core';

export const FIND_ADDRESS_QUERY = gql`
  query findAddress($searchTerm: String!, $enableProvince: Boolean) {
    findAddress(searchTerm: $searchTerm, enableProvince: $enableProvince) {
      Id
      Text
      Description
    }
  }
`;

export const FIND_ADDRESS_DETAILS_QUERY = gql`
  query findAddressDetails($addressId: String!) {
    findAddressDetails(addressId: $addressId) {
      Id
      SubBuilding
      BuildingNumber
      Street
      City
      ProvinceCode
      ProvinceName
      PostalCode
    }
  }
`;
