import { LocationList } from './lookup.interface';
import { ProvinceList } from './location-provincelist';
import { StateList } from './location-statelist';

export const CountryList: LocationList = {
  Afghanistan: { displayName: 'Afghanistan', code: 'AF' },
  AlandIslands: { displayName: 'Åland Islands', code: 'AX' },
  Albania: { displayName: 'Albania', code: 'AL' },
  Algeria: { displayName: 'Algeria', code: 'DZ' },
  AmericanSamoa: { displayName: 'American Samoa', code: 'AS' },
  AndorrA: { displayName: 'AndorrA', code: 'AD' },
  Angola: { displayName: 'Angola', code: 'AO' },
  Anguilla: { displayName: 'Anguilla', code: 'AI' },
  Antarctica: { displayName: 'Antarctica', code: 'AQ' },
  AntiguaAndBarbuda: { displayName: 'Antigua and Barbuda', code: 'AG' },
  Argentina: { displayName: 'Argentina', code: 'AR' },
  Armenia: { displayName: 'Armenia', code: 'AM' },
  Aruba: { displayName: 'Aruba', code: 'AW' },
  Australia: { displayName: 'Australia', code: 'AU' },
  Austria: { displayName: 'Austria', code: 'AT' },
  Azerbaijan: { displayName: 'Azerbaijan', code: 'AZ' },
  Bahamas: { displayName: 'Bahamas', code: 'BS' },
  Bahrain: { displayName: 'Bahrain', code: 'BH' },
  Bangladesh: { displayName: 'Bangladesh', code: 'BD' },
  Barbados: { displayName: 'Barbados', code: 'BB' },
  Belarus: { displayName: 'Belarus', code: 'BY' },
  Belgium: { displayName: 'Belgium', code: 'BE' },
  Belize: { displayName: 'Belize', code: 'BZ' },
  Benin: { displayName: 'Benin', code: 'BJ' },
  Bermuda: { displayName: 'Bermuda', code: 'BM' },
  Bhutan: { displayName: 'Bhutan', code: 'BT' },
  Bolivia: { displayName: 'Bolivia', code: 'BO' },
  BosniaAndHerzegovina: { displayName: 'Bosnia and Herzegovina', code: 'BA' },
  Botswana: { displayName: 'Botswana', code: 'BW' },
  BouvetIsland: { displayName: 'Bouvet Island', code: 'BV' },
  Brazil: { displayName: 'Brazil', code: 'BR' },
  BritishIndianOceanTerritory: { displayName: 'British Indian Ocean Territory', code: 'IO' },
  BruneiDarussalam: { displayName: 'Brunei Darussalam', code: 'BN' },
  Bulgaria: { displayName: 'Bulgaria', code: 'BG' },
  BurkinaFaso: { displayName: 'Burkina Faso', code: 'BF' },
  Burundi: { displayName: 'Burundi', code: 'BI' },
  Cambodia: { displayName: 'Cambodia', code: 'KH' },
  Cameroon: { displayName: 'Cameroon', code: 'CM' },
  Canada: {
    key: 'Canada',
    displayName: 'Canada',
    code: 'CA',
    default: true,
    subLocation: ProvinceList,
    isCanada: true,
  },
  CapeVerde: { displayName: 'Cape Verde', code: 'CV' },
  CaymanIslands: { displayName: 'Cayman Islands', code: 'KY' },
  CentralAfricanRepublic: { displayName: 'Central African Republic', code: 'CF' },
  Chad: { displayName: 'Chad', code: 'TD' },
  Chile: { displayName: 'Chile', code: 'CL' },
  China: { displayName: 'China', code: 'CN' },
  ChristmasIsland: { displayName: 'Christmas Island', code: 'CX' },
  CocosIslands: { displayName: 'Cocos (Keeling) Islands', code: 'CC' },
  Colombia: { displayName: 'Colombia', code: 'CO' },
  Comoros: { displayName: 'Comoros', code: 'KM' },
  Congo: { displayName: 'Congo', code: 'CG' },
  CongoDemocraticRepublic: { displayName: 'Congo, The Democratic Republic of the', code: 'CD' },
  CookIslands: { displayName: 'Cook Islands', code: 'CK' },
  CostaRica: { displayName: 'Costa Rica', code: 'CR' },
  TEST: { displayName: "Côte d'Ivoire", code: 'CI' },
  Croatia: { displayName: 'Croatia', code: 'HR' },
  Cuba: { displayName: 'Cuba', code: 'CU' },
  Cyprus: { displayName: 'Cyprus', code: 'CY' },
  CzechRepublic: { displayName: 'Czech Republic', code: 'CZ' },
  Denmark: { displayName: 'Denmark', code: 'DK' },
  Djibouti: { displayName: 'Djibouti', code: 'DJ' },
  Dominica: { displayName: 'Dominica', code: 'DM' },
  DominicanRepublic: { displayName: 'Dominican Republic', code: 'DO' },
  Ecuador: { displayName: 'Ecuador', code: 'EC' },
  Egypt: { displayName: 'Egypt', code: 'EG' },
  ElSalvador: { displayName: 'El Salvador', code: 'SV' },
  EquatorialGuinea: { displayName: 'Equatorial Guinea', code: 'GQ' },
  Eritrea: { displayName: 'Eritrea', code: 'ER' },
  Estonia: { displayName: 'Estonia', code: 'EE' },
  Ethiopia: { displayName: 'Ethiopia', code: 'ET' },
  FalklandIslands: { displayName: 'Falkland Islands (Malvinas)', code: 'FK' },
  FaroeIslands: { displayName: 'Faroe Islands', code: 'FO' },
  Fiji: { displayName: 'Fiji', code: 'FJ' },
  Finland: { displayName: 'Finland', code: 'FI' },
  France: { displayName: 'France', code: 'FR' },
  FrenchGuiana: { displayName: 'French Guiana', code: 'GF' },
  FrenchPolynesia: { displayName: 'French Polynesia', code: 'PF' },
  FrenchSouthernTerritories: { displayName: 'French Southern Territories', code: 'TF' },
  Gabon: { displayName: 'Gabon', code: 'GA' },
  Gambia: { displayName: 'Gambia', code: 'GM' },
  Georgia: { displayName: 'Georgia', code: 'GE' },
  Germany: { displayName: 'Germany', code: 'DE' },
  Ghana: { displayName: 'Ghana', code: 'GH' },
  Gibraltar: { displayName: 'Gibraltar', code: 'GI' },
  Greece: { displayName: 'Greece', code: 'GR' },
  Greenland: { displayName: 'Greenland', code: 'GL' },
  Grenada: { displayName: 'Grenada', code: 'GD' },
  Guadeloupe: { displayName: 'Guadeloupe', code: 'GP' },
  Guam: { displayName: 'Guam', code: 'GU' },
  Guatemala: { displayName: 'Guatemala', code: 'GT' },
  Guernsey: { displayName: 'Guernsey', code: 'GG' },
  Guinea: { displayName: 'Guinea', code: 'GN' },
  GuineaBissau: { displayName: 'Guinea-Bissau', code: 'GW' },
  Guyana: { displayName: 'Guyana', code: 'GY' },
  Haiti: { displayName: 'Haiti', code: 'HT' },
  HeardMcdonaldIslands: { displayName: 'Heard Island and Mcdonald Islands', code: 'HM' },
  HolySee: { displayName: 'Holy See (Vatican City State)', code: 'VA' },
  Honduras: { displayName: 'Honduras', code: 'HN' },
  HongKong: { displayName: 'Hong Kong', code: 'HK' },
  Hungary: { displayName: 'Hungary', code: 'HU' },
  Iceland: { displayName: 'Iceland', code: 'IS' },
  India: { displayName: 'India', code: 'IN' },
  Indonesia: { displayName: 'Indonesia', code: 'ID' },
  Iran: { displayName: 'Iran, Islamic Republic Of', code: 'IR' },
  Iraq: { displayName: 'Iraq', code: 'IQ' },
  Ireland: { displayName: 'Ireland', code: 'IE' },
  IsleOfMan: { displayName: 'Isle of Man', code: 'IM' },
  Israel: { displayName: 'Israel', code: 'IL' },
  Italy: { displayName: 'Italy', code: 'IT' },
  Jamaica: { displayName: 'Jamaica', code: 'JM' },
  Japan: { displayName: 'Japan', code: 'JP' },
  Jersey: { displayName: 'Jersey', code: 'JE' },
  Jordan: { displayName: 'Jordan', code: 'JO' },
  Kazakhstan: { displayName: 'Kazakhstan', code: 'KZ' },
  Kenya: { displayName: 'Kenya', code: 'KE' },
  Kiribati: { displayName: 'Kiribati', code: 'KI' },
  KoreaDemocratic: { displayName: "Korea, Democratic People's Republic of", code: 'KP' },
  KoreaRepublic: { displayName: 'Korea, Republic of', code: 'KR' },
  Kuwait: { displayName: 'Kuwait', code: 'KW' },
  Kyrgyzstan: { displayName: 'Kyrgyzstan', code: 'KG' },
  Lao: { displayName: "Lao People's Democratic Republic", code: 'LA' },
  Latvia: { displayName: 'Latvia', code: 'LV' },
  Lebanon: { displayName: 'Lebanon', code: 'LB' },
  Lesotho: { displayName: 'Lesotho', code: 'LS' },
  Liberia: { displayName: 'Liberia', code: 'LR' },
  LibyanArabJamahiriya: { displayName: 'Libyan Arab Jamahiriya', code: 'LY' },
  Liechtenstein: { displayName: 'Liechtenstein', code: 'LI' },
  Lithuania: { displayName: 'Lithuania', code: 'LT' },
  Luxembourg: { displayName: 'Luxembourg', code: 'LU' },
  Macao: { displayName: 'Macao', code: 'MO' },
  Macedonia: { displayName: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  Madagascar: { displayName: 'Madagascar', code: 'MG' },
  Malawi: { displayName: 'Malawi', code: 'MW' },
  Malaysia: { displayName: 'Malaysia', code: 'MY' },
  Maldives: { displayName: 'Maldives', code: 'MV' },
  Mali: { displayName: 'Mali', code: 'ML' },
  Malta: { displayName: 'Malta', code: 'MT' },
  MarshallIslands: { displayName: 'Marshall Islands', code: 'MH' },
  Martinique: { displayName: 'Martinique', code: 'MQ' },
  Mauritania: { displayName: 'Mauritania', code: 'MR' },
  Mauritius: { displayName: 'Mauritius', code: 'MU' },
  Mayotte: { displayName: 'Mayotte', code: 'YT' },
  Mexico: { displayName: 'Mexico', code: 'MX' },
  Micronesia: { displayName: 'Micronesia, Federated States of', code: 'FM' },
  Moldova: { displayName: 'Moldova, Republic of', code: 'MD' },
  Monaco: { displayName: 'Monaco', code: 'MC' },
  Mongolia: { displayName: 'Mongolia', code: 'MN' },
  Montserrat: { displayName: 'Montserrat', code: 'MS' },
  Morocco: { displayName: 'Morocco', code: 'MA' },
  Mozambique: { displayName: 'Mozambique', code: 'MZ' },
  Myanmar: { displayName: 'Myanmar', code: 'MM' },
  Namibia: { displayName: 'Namibia', code: 'NA' },
  Nauru: { displayName: 'Nauru', code: 'NR' },
  Nepal: { displayName: 'Nepal', code: 'NP' },
  Netherlands: { displayName: 'Netherlands', code: 'NL' },
  NetherlandsAntilles: { displayName: 'Netherlands Antilles', code: 'AN' },
  NewCaledonia: { displayName: 'New Caledonia', code: 'NC' },
  NewZealand: { displayName: 'New Zealand', code: 'NZ' },
  Nicaragua: { displayName: 'Nicaragua', code: 'NI' },
  Niger: { displayName: 'Niger', code: 'NE' },
  Nigeria: { displayName: 'Nigeria', code: 'NG' },
  Niue: { displayName: 'Niue', code: 'NU' },
  NorfolkIsland: { displayName: 'Norfolk Island', code: 'NF' },
  NorthernMarianaIslands: { displayName: 'Northern Mariana Islands', code: 'MP' },
  Norway: { displayName: 'Norway', code: 'NO' },
  Oman: { displayName: 'Oman', code: 'OM' },
  Pakistan: { displayName: 'Pakistan', code: 'PK' },
  Palau: { displayName: 'Palau', code: 'PW' },
  PalestinianTerritory: { displayName: 'Palestinian Territory, Occupied', code: 'PS' },
  Panama: { displayName: 'Panama', code: 'PA' },
  PapuaNewGuinea: { displayName: 'Papua New Guinea', code: 'PG' },
  Paraguay: { displayName: 'Paraguay', code: 'PY' },
  Peru: { displayName: 'Peru', code: 'PE' },
  Philippines: { displayName: 'Philippines', code: 'PH' },
  Pitcairn: { displayName: 'Pitcairn', code: 'PN' },
  Poland: { displayName: 'Poland', code: 'PL' },
  Portugal: { displayName: 'Portugal', code: 'PT' },
  PuertoRico: { displayName: 'Puerto Rico', code: 'PR' },
  Qatar: { displayName: 'Qatar', code: 'QA' },
  Reunion: { displayName: 'Reunion', code: 'RE' },
  Romania: { displayName: 'Romania', code: 'RO' },
  RussianFederation: { displayName: 'Russian Federation', code: 'RU' },
  RWANDA: { displayName: 'RWANDA', code: 'RW' },
  SaintHelena: { displayName: 'Saint Helena', code: 'SH' },
  SaintKittsNevis: { displayName: 'Saint Kitts and Nevis', code: 'KN' },
  SaintLucia: { displayName: 'Saint Lucia', code: 'LC' },
  SaintPierreMiquelon: { displayName: 'Saint Pierre and Miquelon', code: 'PM' },
  SaintVincentGrenadines: { displayName: 'Saint Vincent and the Grenadines', code: 'VC' },
  Samoa: { displayName: 'Samoa', code: 'WS' },
  SanMarino: { displayName: 'San Marino', code: 'SM' },
  SaoTomePrincipe: { displayName: 'Sao Tome and Principe', code: 'ST' },
  SaudiArabia: { displayName: 'Saudi Arabia', code: 'SA' },
  Senegal: { displayName: 'Senegal', code: 'SN' },
  SerbiaMontenegro: { displayName: 'Serbia and Montenegro', code: 'CS' },
  Seychelles: { displayName: 'Seychelles', code: 'SC' },
  SierraLeone: { displayName: 'Sierra Leone', code: 'SL' },
  Singapore: { displayName: 'Singapore', code: 'SG' },
  Slovakia: { displayName: 'Slovakia', code: 'SK' },
  Slovenia: { displayName: 'Slovenia', code: 'SI' },
  SolomonIslands: { displayName: 'Solomon Islands', code: 'SB' },
  Somalia: { displayName: 'Somalia', code: 'SO' },
  SouthAfrica: { displayName: 'South Africa', code: 'ZA' },
  SouthGeorgiaSouthSandwichIslands: { displayName: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  Spain: { displayName: 'Spain', code: 'ES' },
  SriLanka: { displayName: 'Sri Lanka', code: 'LK' },
  Sudan: { displayName: 'Sudan', code: 'SD' },
  SuridisplayName: { displayName: 'Suriname', code: 'SR' },
  SvalbardJanMayen: { displayName: 'Svalbard and Jan Mayen', code: 'SJ' },
  Swaziland: { displayName: 'Swaziland', code: 'SZ' },
  Sweden: { displayName: 'Sweden', code: 'SE' },
  Switzerland: { displayName: 'Switzerland', code: 'CH' },
  SyrianArabRepublic: { displayName: 'Syrian Arab Republic', code: 'SY' },
  Taiwan: { displayName: 'Taiwan, Province of China', code: 'TW' },
  Tajikistan: { displayName: 'Tajikistan', code: 'TJ' },
  Tanzania: { displayName: 'Tanzania, United Republic of', code: 'TZ' },
  Thailand: { displayName: 'Thailand', code: 'TH' },
  TimorLeste: { displayName: 'Timor-Leste', code: 'TL' },
  Togo: { displayName: 'Togo', code: 'TG' },
  Tokelau: { displayName: 'Tokelau', code: 'TK' },
  Tonga: { displayName: 'Tonga', code: 'TO' },
  TrinidadTobago: { displayName: 'Trinidad and Tobago', code: 'TT' },
  Tunisia: { displayName: 'Tunisia', code: 'TN' },
  Turkey: { displayName: 'Turkey', code: 'TR' },
  Turkmenistan: { displayName: 'Turkmenistan', code: 'TM' },
  TurksCaicosIslands: { displayName: 'Turks and Caicos Islands', code: 'TC' },
  Tuvalu: { displayName: 'Tuvalu', code: 'TV' },
  Uganda: { displayName: 'Uganda', code: 'UG' },
  Ukraine: { displayName: 'Ukraine', code: 'UA' },
  UnitedArabEmirates: { displayName: 'United Arab Emirates', code: 'AE' },
  UnitedKingdom: { displayName: 'United Kingdom', code: 'GB' },
  UnitedStates: { key: 'UnitedStates', displayName: 'United States', code: 'US', subLocation: StateList },
  UnitedStatesMinorOutlyingIslands: { displayName: 'United States Minor Outlying Islands', code: 'UM' },
  Uruguay: { displayName: 'Uruguay', code: 'UY' },
  Uzbekistan: { displayName: 'Uzbekistan', code: 'UZ' },
  Vanuatu: { displayName: 'Vanuatu', code: 'VU' },
  Venezuela: { displayName: 'Venezuela', code: 'VE' },
  VietNam: { displayName: 'Vietnam', code: 'VN' },
  VirginIslandsBritish: { displayName: 'Virgin Islands, British', code: 'VG' },
  VirginIslandsUS: { displayName: 'Virgin Islands, U.S.', code: 'VI' },
  WallisAndFutuna: { displayName: 'Wallis and Futuna', code: 'WF' },
  WesternSahara: { displayName: 'Western Sahara', code: 'EH' },
  Yemen: { displayName: 'Yemen', code: 'YE' },
  Zambia: { displayName: 'Zambia', code: 'ZM' },
  Zimbabwe: { displayName: 'Zimbabwe', code: 'ZW' },
};

export function DefaultCountry() {
  const filterCountry = Object.keys(CountryList).filter((i) => CountryList[i].default);
  const defaultCountry = filterCountry[0];
  return defaultCountry;
}
