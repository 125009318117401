import { IncomeLookup } from './case-management/case-income-types';

export interface FbAssetsLookup extends IncomeLookup {
  requiresDescription?: boolean;
}

export const FbAssetTypes: { [name: string]: FbAssetsLookup } = {
  bankAccounts: {
    displayValue: 'Bank accounts/cash on hand/cheques',
    documentTypes: ['Statement from bank account'],
    tips: 'Include the total amount of money the individual has in all of their bank accounts and any cash or cheques.',
    order: 1,
    isOptional: true,
    suppressOptionalLabel: true,
    needSupportDocument: true,
  },
  stocksAndBonds: {
    displayValue: 'Stocks/bonds/mutual funds',
    documentTypes: [],
    order: 2,
    needSupportDocument: true,
  },
  rrsp: {
    displayValue: 'Registered Retirement Savings Plan (RRSP)',
    documentTypes: [],
    order: 3,
    needSupportDocument: true,
  },
  vehicles: {
    displayValue: 'Vehicle(s)',
    documentTypes: [],
    order: 4,
    needSupportDocument: true,
    helpText: 'Current market value, minus any amount owed',
  },
  propertyLand: {
    displayValue: 'Property or land',
    documentTypes: [],
    tips: 'Include the value of property or land other than the home lived in by the individual',
    order: 5,
    needSupportDocument: true,
    helpText: 'Other than the home lived in by the deceased individual',
  },
  otherAssets: {
    displayValue: 'Other assets',
    documentTypes: [],
    order: 6,
    needSupportDocument: true,
    requiresDescription: true,
  },
};
