import { registerDecorator, ValidationArguments } from 'class-validator';

export function RequiredSpecificDocument(property: string) {
  return function (object, propertyName: string) {
    registerDecorator({
      name: 'RequiredDocument',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      validator: {
        validate(value, args: ValidationArguments) {
          let isValid = false;
          const [requiredDocCategory] = args.constraints;
          const docs = args.object['documents'];

          if (docs?.find((d) => d.documentCategory === requiredDocCategory)) {
            isValid = true;
          }

          return isValid;
        },
        defaultMessage(): string {
          return `Required document ${property} missing`;
        },
      },
    });
  };
}
