import { IsEmail, IsNotEmpty } from 'class-validator';
export class EmailRequest {
  @IsNotEmpty()
  @IsEmail()
  email_address: string;

  @IsNotEmpty()
  template_id: string;

  /* personalisation of type {key:value}   */
  personalisation?: { [key: string]: string };
}
