import { HttpHandler, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class UnauthenticatedInterceptor implements HttpInterceptor {
  constructor(private keycloakService: KeycloakService) {}

  intercept(request, next: HttpHandler) {
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          if (
            event.body?.errors?.find((e) => {
              return e.extensions?.code === 'UNAUTHENTICATED';
            })
          ) {
            this.keycloakService.login();
          }
        }
      })
    );
  }
}
