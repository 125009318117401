import { GoogleAnalyticsService } from '@common/ui/shared-components';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuidv4 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}
  handleError(error) {
    const googleAnalyticsService = this.injector.get<GoogleAnalyticsService>(GoogleAnalyticsService);
    const errorId = uuidv4(); //create an error id so we can cross reference the error if there is a screenshot
    const toasterService = this.injector.get<ToastrService>(ToastrService);

    //TODO: Had to comment out logging to analytics. Sometimes we're getting a 413 (Payload too large) error probably due to sending the error stack information being too large
    //For now just going to console.log the error so we can at least see it
    const logErrorMessage = `ErrorID: ${errorId}. ${error}. `;
    const toastErrorMessage = `An unexpected error has occurred. Please check logs for details. ErrorID: ${errorId}.`;
    const logErrorMessageShort = logErrorMessage.substring(0, 8000);
    //toast error message because sometimes the console.log of this error isn't seen unless we are monitoring the console
    //toast a message so that it is obvious there is an error
    toasterService.error(toastErrorMessage);
    console.log(error);

    //TODO: Need to find a way to log this error message client side.
    //For now let's log to google and truncate the payload size. Max payload size 8192 bytes
    googleAnalyticsService.trackErrorMessage(logErrorMessageShort);
  }
}
