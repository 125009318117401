import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FbPaymentDirectionLabels: IntakeLabelCollection = {
  paymentConsent: { label: 'Who should we pay?' },
  fspNotOnList: { label: 'The funeral service provider is not on this list' },
  funeralServiceProvider: { label: 'Select the funeral service provider you are working with' },
  fspLocated: { label: 'Where is the service provider located?' },
  fspName: { label: 'Name of service provider' },
  fspPhoneNumbers: { label: 'Phone number' },
  fspFaxNumber: { label: 'Fax number' },
  fspEmail: { label: 'Email' },

  workingWithCemetery: {
    label: 'Are you also working with a cemetery?',
  },
  cemeteryNotOnList: { label: 'The cemetery I want to work with is not on this list' },
  cemetery: { label: 'Select the cemetery you are working with' },
  cemeteryLocated: { label: 'Where is the cemetery located?' },
  cemeteryName: { label: 'Name of cemetery' },
  cemeteryPhoneNumbers: { label: 'Phone number' },
  cemeteryFaxNumber: { label: 'Fax number' },
  cemeteryEmail: { label: 'Email' },
  streetAddress: { label: 'Street address' },
  suiteNumber: { label: 'Suite or unit #' },
  city: { label: 'City/town' },
  province: { label: 'Province/territory' },
  postalCode: { label: 'Postal code' },
  hasExceptionalAmounts: {
    label: 'Do you have exceptional amounts not covered by the benefits that you would like to be paid for?',
  },
  exceptionalAmountReasons: {
    label: 'What was the reason for going over the maximum amount?',
  },
  exceptionalAmountExplain: {
    label:
      'Please explain why additional funds are necessary and essential above the maximum benefits amount, include how you have considered least cost alternatives for the whole funeral package.',
  },
};
