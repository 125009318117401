import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { DateValidator } from '../validators/date.validator';

@Directive({
  selector: '[iesMaxDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: MaxDateValidator,
      multi: true,
    },
  ],
})
export class MaxDateValidator implements Validator {
  @Input('iesMaxDate') maxDateValue: Date;
  // constructor(@prop('maxDate') private maxDate: Date) {}
  validate(control: AbstractControl) {
    return DateValidator.maxDate(this.maxDateValue)(control);
  }
}
