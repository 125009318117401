<div [ngClass]="{ sticky: stickyHeader }" class="header">
  <div class="flex-row middle top-controls">
    <div class="today-button flex-row middle" *ngIf="calendarView !== CalendarView.List">
      <button goa-button buttonType="tertiary" buttonSize="small" (click)="goToToday()">Today</button>
    </div>
    <div class="current-month" [formGroup]="_formGroup" *ngIf="calendarView !== CalendarView.List">
      <div class="date-picker" *ngIf="calendarView !== CalendarView.List">
        <common-calendar formControlName="activeDay" data-cy="calendar-active-date"></common-calendar>
      </div>
    </div>
    <div class="month-navigator flex-row middle" *ngIf="calendarView !== CalendarView.List">
      <div class="month-change-icon" iesIconButton (click)="previousMonthClick()">
        <span class="material-icons"> chevron_left </span>
      </div>
      <div class="month-change-icon" iesIconButton (click)="nextMonthClick()">
        <span class="material-icons"> chevron_right </span>
      </div>
    </div>
    <div *ngIf="!hideLegend" class="legend">
      <div class="item" *ngFor="let category of eventCategories">
        <span
          class="color-indicator"
          [ngStyle]="{ backgroundColor: eventSchemes[category].cssEventColor, color: category.cssFontColor }"
        ></span>
        <span>{{ category }}</span>
      </div>
    </div>
    <div class="flex-grow"></div>
    <div class="loading-indicator">
      <mat-progress-spinner mode="indeterminate" diameter="25" *ngIf="isLoading"> </mat-progress-spinner>
    </div>
    <div class="day-week-month">
      <mat-button-toggle-group>
        <mat-button-toggle
          [checked]="calendarView === CalendarView.Month"
          (click)="setCalendarView(CalendarView.Month)"
        >
          Month
        </mat-button-toggle>
        <mat-button-toggle [checked]="calendarView === CalendarView.Week" (click)="setCalendarView(CalendarView.Week)">
          Week
        </mat-button-toggle>
        <mat-button-toggle [checked]="calendarView === CalendarView.Day" (click)="setCalendarView(CalendarView.Day)">
          Day
        </mat-button-toggle>
        <mat-button-toggle [checked]="calendarView === CalendarView.List" (click)="setCalendarView(CalendarView.List)">
          List
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="filters-button" *ngIf="!hideFilters">
      <button
        goa-button
        buttonType="secondary"
        buttonSize="small"
        [attr.aria-label]="_showFilters ? 'Hide filters' : 'Show filters'"
        (click)="_showFilters = !_showFilters"
        data-cy="calendar-toggle-filters-button"
      >
        <span *ngIf="!_showFilters" class="material-icons-outlined filter-icon"> filter_list </span>
        <span *ngIf="_showFilters" class="material-icons-outlined filter-icon"> filter_list_off </span>
        <span class="filter-label">{{ _showFilters ? 'Hide filters' : 'Filters' }}</span>
      </button>
    </div>
    <div class="add-button">
      <button
        [disabled]="!allowEventsInPast && isInPast(activeDay)"
        goa-button
        buttonType="primary"
        buttonSize="small"
        (click)="addEventClick()"
        data-cy="add-reminder-button"
      >
        + Add
      </button>
    </div>
  </div>
  <div class="flex-row controls" *ngIf="_showFilters && !hideFilters">
    <div class="filters flex-grow">
      <ng-content select="[filters]"></ng-content>
    </div>
  </div>

  <div class="filter-chips" [ngClass]="{ controls: !_showFilters }" *ngIf="!hideFilters">
    <ng-content select="[filter-chips]"></ng-content>
  </div>
</div>

<div [ngSwitch]="calendarView" class="calendar">
  <div class="month-view" *ngSwitchCase="CalendarView.Month">
    <mwl-calendar-month-view
      [viewDate]="_activeDay"
      [events]="events"
      [activeDayIsOpen]="true"
      [cellTemplate]="customCellTemplate"
      [openDayEventsTemplate]="openDayTemplate"
      (dayClicked)="onDayClicked($event.day.date)"
    ></mwl-calendar-month-view>
  </div>
  <div class="week-view" *ngSwitchCase="CalendarView.Week">
    <common-worker-calendar-week-view
      [events]="events"
      [isLoading]="isLoading"
      [viewDate]="_activeDay"
      [eventTemplate]="eventTemplate"
      [groupingTemplate]="groupingFunction ? dayGroupedTemplate : null"
      (dayClicked)="onDayClicked($event)"
      [hasMore]="hasMore"
    >
    </common-worker-calendar-week-view>
  </div>
  <div class="day-view" *ngSwitchCase="CalendarView.Day">
    <common-worker-calendar-day-view
      [isLoading]="isLoading"
      [events]="events"
      [viewDate]="_activeDay"
      [eventTemplate]="eventTemplate"
      [groupingTemplate]="groupingFunction ? dayGroupedTemplate : null"
    >
    </common-worker-calendar-day-view>
  </div>
  <div class="list-view" *ngSwitchCase="CalendarView.List">
    <common-worker-calendar-list-view
      [isLoading]="isLoading"
      [overdueCount]="overdueCount"
      [upcomingCount]="upcomingCount"
      [allOverdueEvents]="allOverdueEvents"
      [allUpcomingEvents]="allUpcomingEvents"
      [overdueEvents0to7]="overdueEvents0to7"
      [overdueEvents7to14]="overdueEvents7to14"
      [overdueEvents14to30]="overdueEvents14to30"
      [overdueEventsOther]="overdueEventsOther"
      [upcomingEventsToday]="upcomingEventsToday"
      [upcomingEventsOther]="upcomingEventsOther"
      [eventTemplate]="eventTemplate"
      [groupingTemplate]="groupingFunction ? dayGroupedTemplate : null"
      [hasMore]="hasMore"
    >
    </common-worker-calendar-list-view>
  </div>
</div>
<!----
  Template for the cell in the month calendar
-->
<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="day" [ngClass]="{ active: isDayActive(day), past: day.isPast }">
    <!-- The day reference changes, so to avoid the pipe running too much we are using the date string literal which can be change detected -->
    <ng-container *ngIf="events | workerCalendarDayEvents: day.date.toString():daySummaryMapper as dayEvents">
      <ng-container *ngFor="let event of dayEvents; let index = index">
        <div
          [matTooltip]="event.title"
          matTooltipShowDelay="1000"
          class="event-container"
          [ngClass]="{ completed: event.meta.isComplete, important: event.meta.isImportant }"
          *ngIf="index < EVENTS_PER_DAY"
        >
          <div
            class="event"
            [ngClass]="{ completed: event.meta.isComplete }"
            [ngStyle]="{
              backgroundColor: eventSchemes[event.meta.category].cssBackgroundColor,
              borderColor: eventSchemes[event.meta.category].cssEventColor,
              color: eventSchemes[event.meta.category].cssFontColor
            }"
          >
            <span>{{ event.title }}</span>
          </div>
          <span
            class="status-icon"
            *ngIf="event.meta.isComplete"
            [ngStyle]="{
              color: eventSchemes[event.meta.category].cssEventColor
            }"
            ><span class="material-icons filled"> check_circle </span>
          </span>
          <span
            [ngStyle]="{
              color: eventSchemes[event.meta.category].cssEventColor
            }"
            class="status-icon"
            *ngIf="event.meta.isImportant && !event.meta.isComplete"
            ><span class="material-icons filled"> flag </span></span
          >
        </div>
      </ng-container>
      <div class="day-bottom" [ngClass]="{ faded: !day.inMonth, today: day.isToday }">
        <div class="more-events" *ngIf="dayEvents.length <= EVENTS_PER_DAY"></div>
        <div class="more-events event-container" *ngIf="dayEvents.length > EVENTS_PER_DAY">
          <div class="event">+ {{ dayEvents.length - EVENTS_PER_DAY }} more</div>
        </div>
        <span class="number"
          ><span>{{ day.date | calendarDate: 'monthViewDayNumber':locale }}</span></span
        >
      </div>
    </ng-container>
  </div>
</ng-template>
<!----
  Template for the open day in the month calendar
-->
<ng-template #openDayTemplate let-events="events" let-isOpen="isOpen">
  <div class="open-day" *ngIf="isOpen && events.length > 0">
    <ng-container *ngIf="!groupingFunction; else groupedOpendDay">
      <div class="events-list border">
        <ng-container *ngFor="let event of events">
          <ng-container *ngTemplateOutlet="eventMetaTemplate; context: { event: event.meta }"></ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #groupedOpendDay>
      <ng-container
        *ngTemplateOutlet="dayGroupedTemplate; context: { events: events, date: _formGroup.value.activeDay }"
      ></ng-container>
    </ng-template>
  </div>
</ng-template>
<!----
  Template for events on a given day grouped by grouping function.  Used by open day as well as week/day views
-->
<ng-template #dayGroupedTemplate let-events="events" let-date="date">
  <ng-container *ngIf="events | workerCalendarDayGroupedEvents: date:groupingFunction as groupedEvents">
    <ng-container *ngFor="let group of groupedEvents">
      <ng-container *ngIf="group.events.length > 1">
        <div class="group-list">
          <div class="group-header" iesIconButton (click)="groupExpandCollapseClick(group, date)">
            <span
              [attr.aria-controls]="group._id"
              [attr.aria-expanded]="_groupCollapseExpandState[group._id + date] ?? group.isExpanded ? 'true' : 'false'"
            >
              <span *ngIf="!groupTitleTemplate">
                {{ group.title }} <span class="count-pill">{{ group.events.length }}</span>
              </span>
              <ng-container *ngIf="groupTitleTemplate">
                <ng-container
                  [ngTemplateOutlet]="groupTitleTemplate"
                  [ngTemplateOutletContext]="{ $implicit: group }"
                ></ng-container>
              </ng-container>
            </span>
            <span
              [@rotateExpander]="
                _groupCollapseExpandState[group._id + date] ?? group.isExpanded ? 'expanded' : 'collapsed'
              "
              class="expander material-icons material-icons-outlined"
              >expand_less</span
            >
          </div>
          <div
            class="events-list expandable"
            [attr.id]="group._id"
            role="region"
            [attr.aria-expanded]="_groupCollapseExpandState[group._id + date] ?? group.isExpanded ? 'true' : 'false'"
            [@showHide]="_groupCollapseExpandState[group._id + date] ?? group.isExpanded ? 'show' : 'hide'"
          >
            <ng-container *ngFor="let event of group.events">
              <ng-container *ngTemplateOutlet="eventMetaTemplate; context: { event: event }"></ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="group.events.length === 1">
        <div class="events-list border">
          <ng-container *ngTemplateOutlet="eventMetaTemplate; context: { event: group.events[0] }"></ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
<!----
  Templates for a single event.  Used by open day and week/day/list views
-->
<ng-template #eventTemplate let-event="event" let-index="index">
  <ng-container *ngTemplateOutlet="eventMetaTemplate; context: { event: event.meta, index }"></ng-container>
</ng-template>
<ng-template #eventMetaTemplate let-event="event" let-index="index">
  <div class="event-container" [ngClass]="{ completed: event.isComplete, important: event.isImportant }">
    <span
      class="status-icon"
      *ngIf="event.isComplete"
      [ngStyle]="{
        color: eventSchemes[event.category].cssEventColor
      }"
      ><span class="material-icons filled"> check_circle </span>
    </span>
    <span
      [ngStyle]="{
        color: eventSchemes[event.category].cssEventColor
      }"
      class="status-icon"
      *ngIf="event.isImportant && !event.isComplete"
      ><span class="material-icons filled"> flag </span></span
    >
    <div
      class="event"
      data-cy="calendar-event"
      [ngStyle]="{
        backgroundColor: eventSchemes[event.category].cssBackgroundColor,
        borderColor: eventSchemes[event.category].cssEventColor,
        color: eventSchemes[event.category].cssFontColor
      }"
    >
      <ng-container *ngIf="!event.eventTemplate">
        <div class="default">
          <div class="title">
            {{ event.title }}
          </div>
          <div class="actions">
            <button
              goa-button
              buttonType="tertiary"
              buttonSize="small"
              *ngFor="let action of event.actions"
              (click)="$event.stopPropagation(); action.callback(event)"
            >
              {{ action.name }}
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="event.eventTemplate">
        <ng-container
          [ngTemplateOutlet]="event.eventTemplate"
          [ngTemplateOutletContext]="{ event,index }"
        ></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
