import { Directive } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { mod10Validation } from '@common/validation';

@Directive({
  selector: '[iesAhnValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: AhnValidateDirective,
      multi: true,
    },
  ],
})
export class AhnValidateDirective implements Validator {
  validate(control: AbstractControl) {
    return mod10Validation(control.value, 5);
  }
}
