import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const IesPersonalInformationLabels: IntakeLabelCollection = {
  firstName: {
    label: 'First name',
  },
  middleName: {
    label: 'Middle name',
  },
  lastName: {
    label: 'Last name',
  },
  sin: {
    label: 'Social Insurance Number',
  },
  gender: {
    label: 'Gender',
  },
  email: {
    label: 'Email address',
  },
  phoneNumber: {
    label: 'Phone number',
  },
  birthdate: {
    label: 'Birthdate',
  },
  immigrationStatus: {
    label: 'Citizenship / Immigration status',
  },
  immigrationStatusSponsored: {
    label: 'Are you sponsored?',
  },
  immigrationStatusOther: {
    label: 'Please explain your immigration status',
  },
  hasIndianRegistration: {
    label: 'Do you have an Indian Registration Number?',
    spousePartnerLabel: 'Does your <<spouseLabel>> have an Indian Registration Number?',
  },
  indianRegistrationNumber: {
    label: 'Indian Registration Number',
  },
  livesOnReserve: {
    label: 'Are you living on the reserve?',
    spousePartnerLabel: 'Is your <<spouseLabel>> living on a reserve?',
  },
  receivesFundsFromBand: {
    label: 'Do you receive funds from your Band?',
    spousePartnerLabel: 'Does your <<spouseLabel>> receive funds from their Band?',
  },
  livingSituation: {
    label: 'Living situation',
  },
  monthlyRentAndUtilities: {
    label: 'Monthly rent including utilities',
  },
  monthlyMortgageAndUtilities: {
    label: 'Monthly mortgage including utilities',
  },
  monthlyRoomAndBoardAndUtilities: {
    label: 'Monthly room and board including utilities',
  },
  paysForPowerElectricity: {
    label: 'Do you pay your own power/electricity?',
  },
  powerElectricityCosts: {
    label: 'Power/electricity costs',
  },
  livingWithRelatives: {
    label: 'Indicate the relationship to the adult relative',
  },
  livingSituationsOther: {
    label: 'Please describe your living situation',
  },
  livesWithGrandparent: {
    label: 'Grandparent',
  },
  livesWithParentStepParent: {
    label: 'Parent/Step parent',
  },
  livesWithAdultChildOrAdultGrandchild: {
    label: 'Adult child/Adult grandchild',
  },
  mailingAddressDifferent: {
    label: 'Is your mailing address different than your home address?',
  },
  hasMailingAddress: {
    label: 'Do you have a mailing address where you can be contacted?',
  },
  streetAddress: { label: 'Street address' },
  suiteNumber: { label: 'Suite or unit #' },
  city: { label: 'City/town' },
  province: { label: 'Province/territory' },
  postalCode: { label: 'Postal code' },
};
