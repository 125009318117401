import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'common-help-icon-tooltip',
  templateUrl: './help-icon-tooltip.component.html',
  styleUrls: ['./help-icon-tooltip.component.scss'],
})
export class HelpIconTooltipComponent {
  @Input() helpTitle: string;
  @Input() helpTooltip: TemplateRef<any>;
  isOpen = false;
}
