import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FbDashboardLabels: IntakeLabelCollection = {
  submittedAppsNote: {
    label:
      '<b>Note:</b> In order to safeguard your privacy and the security of your information, your submitted application(s) will be deleted 60 days after the submitted date.',
  },
  draftAppsNote: {
    label: '<b>Note:</b> Your draft application(s) will be deleted after 60 days of inactivity.',
  },
  removedAppsNote: {
    label: '<b>Note:</b> Application(s) in this tab will be automatically deleted 60 days after date removed.',
  },
  removeApplicationModalHeader: {
    label: 'Remove application?',
  },
  removeApplicationModalContent: {
    label:
      'This application will be moved to the <b>Recently removed application(s)</b> tab. Applications are automatically deleted from <b>Recently removed</b> tab after 60 days of inactivity.',
  },
  deleteApplicationModalHeader: {
    label: 'Permanently delete application?',
  },
  deleteApplicationModalContent: {
    label: ``,
    replacementLabel: `This action will delete the application for <<replacementLabel>>.\nAre you sure you want to delete this application?`,
  },
};
