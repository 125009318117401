import { Lookup } from '../lookup.interface';
export const ReferralRejectReasons: Lookup[] = [
  {
    displayValue: 'Client not interested in participating',
    code: 'ClientNotInterested',
  },
  {
    displayValue: 'Client ineligible for the program',
    code: 'ClientIneligible',
  },
  {
    displayValue: 'No seats available at the present time',
    code: 'NoSeatsAvailable',
  },
  {
    displayValue: 'Inadequate language levels',
    code: 'InadequateLanguageLevels',
  },
  {
    displayValue: 'Unable to contact client',
    code: 'UnableToContactClient',
  },
  {
    displayValue: 'Barriers preventing participation',
    code: 'BarrierPreventingParticipation',
  },
  {
    displayValue: 'Client demonstrated rude or offensive behaviours',
    code: 'ClientRudeOrOffensive',
  },
  {
    displayValue: 'Client did not attend',
    code: 'ClientDidNotAttend',
  },
];
