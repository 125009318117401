<div class="date-picker-container" [formGroup]="formGroup">
  <mat-select
    class="month-dropdown"
    data-cy="month"
    aria-label="Month"
    (selectionChange)="onYearMonthChanged()"
    formControlName="month"
    role="combobox"
    placeholder="{{ selectOneText }}"
  >
    <mat-option disabled value="">{{selectOneText}}</mat-option>
    <mat-option *ngFor="let month of _months" [value]="month.value">
      {{ month.label }}
    </mat-option>
  </mat-select>
  <input
    minlength="4"
    maxlength="4"
    class="year-input"
    formControlName="year"
    data-cy="year"
    aria-label="Year"
    type="text"
    placeholder="Year"
    (input)="onYearMonthChanged()"
    (blur)="onYearBlur()"
    iesNumberOnly
    [wholeNumbersOnly]="true"
  />
</div>
<ng-container *ngIf="monthControl.touched && monthControl.invalid">
  <div
    class="error-text"
    *ngIf="(yearMonthFormControl.untouched || yearMonthFormControl.errors?.yearMonth) && monthControl.errors.required"
  >
    Month is required.
  </div>
</ng-container>
<ng-container *ngIf="yearControl.touched && yearControl.invalid">
  <div
    class="error-text"
    *ngIf="(yearMonthFormControl.untouched || yearMonthFormControl.errors?.yearMonth) && yearControl.errors.required"
  >
    Year is required.
  </div>
  <div class="error-text" *ngIf="yearControl.errors.minlength">
    Year is invalid. minimum length:
    {{ yearControl.errors.minlength.requiredLength }}, actual:
    {{ yearControl.errors.minlength.actualLength }}
  </div>
</ng-container>
