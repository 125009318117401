//IMPORTANT: These methods currently exist in authentication-service.ts
//But authentication-service.ts will be moved to an NPM package so repositories outside of this one can access those methods.
//The methods in context-service.ts are ones de-coupled from authentication-service.ts that are not common and apply only to context profile.

import { Injectable } from '@angular/core';
import { getPrimaryRole } from '../../../shared/helpers/utils';
import { Office, User } from '@common/entities';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  id;
  constructor(private authService: AuthenticationService) {}

  async getContextUserProfile() {
    const profile = await this.authService.getUserProfileWithRoles();
    const user = {
      _id: profile.userid,
      name: `${profile.firstName} ${profile.lastName}`,
      roles: profile.roles,
      primaryRole: getPrimaryRole(profile.roles)?.displayValue ?? null,
      title: profile.title,
      location: profile.location ?? 'Alberta',
      email: profile.email,
      office: this.getOfficeInfo(profile._rawProfile),
      phone: profile._rawProfile?.phone ?? null,
      agency: profile._rawProfile?.agency ?? null,
      district: profile._rawProfile?.district ?? null,
    } as User;
    return user;
  }

  private getOfficeInfo(data): Office {
    if (!data?.address && !data?.officeAddress) return null;
    return {
      streetAddress: data.address?.street_address ?? data.officeAddress ?? null,
      city: data.address?.locality ?? data.officeCity ?? null,
      province: 'Alberta',
      postalCode: data.address?.postal_code ?? data.officePostalCode ?? null,
      phone: data.phone ?? data.officePhone ?? null,
      fax: data.fax ?? data.officeFax ?? null,
    };
  }
}
