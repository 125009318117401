import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { from, Observable, ReplaySubject, Subject, timer } from 'rxjs';
import { delay, delayWhen, map, switchMap, takeUntil } from 'rxjs/operators';
import { SaveStatus, SaveStatusProvider } from './save-indicator.provider';
import * as dayjs from 'dayjs';
import * as isToday from 'dayjs/plugin/isToday';
import * as duration from 'dayjs/plugin/duration';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { getNatualLanguageDateDiff } from '../shared/helpers/utils';

dayjs.extend(isToday);
dayjs.extend(duration);
dayjs.extend(relativeTime);

@Component({
  selector: 'common-save-indicator',
  templateUrl: './save-indicator.component.html',
  styleUrls: ['./save-indicator.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0ms', style({ opacity: 0.5 }))]),
      transition(':leave', [style({ opacity: 0.5 }), animate('300ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class SaveIndicatorComponent implements OnInit, OnDestroy {
  saveStatus: SaveStatus;
  SaveStatuses = SaveStatus;
  humanReadableDate$: Observable<string>;
  private saveStatus$: Observable<SaveStatus>;
  private dateUpdater$ = new ReplaySubject<string>();
  private _destroy$ = new Subject<void>();

  /**
   * Time to fade.  0 = stay on forever
   */
  @Input() fadeTimerMilliseconds = 0;

  /**
   * Min time a message will stay on screen for
   */
  @Input() minTimeMilliseconds = 1000;

  /**
   * The date of the last save time
   */
  _dateSaved;
  @Input() set dateSaved(value) {
    this._dateSaved = value;
    this.dateUpdater$.next('');
  }

  constructor(private saveStatusProvider: SaveStatusProvider) {}

  ngOnInit() {
    const status$ = this.saveStatusProvider.saveStatus$.pipe(takeUntil(this._destroy$));

    this.humanReadableDate$ = this.dateUpdater$.pipe(
      takeUntil(this._destroy$),
      switchMap(() => timer(0, 60 * 1000).pipe(map(() => this.humanReadableDuration())))
    );

    this.saveStatus$ = status$.pipe(
      switchMap((status) => {
        if (this.fadeTimerMilliseconds > 0 && status === SaveStatus.Success)
          return from([SaveStatus.Success, SaveStatus.Inactive]).pipe(
            delayWhen((s) =>
              timer((s === SaveStatus.Success && this.minTimeMilliseconds) || this.fadeTimerMilliseconds)
            )
          );
        else if (status === SaveStatus.InProgress || status === SaveStatus.Inactive) return status$.pipe();
        else return status$.pipe(delay(this.minTimeMilliseconds));
      })
    );

    this.saveStatus$.subscribe((val) => (this.saveStatus = val));
  }

  private humanReadableDuration() {
    if (!this._dateSaved) return null;
    // If our clock is ahead of server time, assume this was saved recently
    if (this._dateSaved > new Date()) {
      this._dateSaved = new Date();
      this._dateSaved.setSeconds(-1);
    }
    return getNatualLanguageDateDiff(this._dateSaved, new Date()) + ' ago';
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
