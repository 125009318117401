import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const AishEmploymentLabels: IntakeLabelCollection = {
  name: {
    label: 'Employer name',
  },
  employmentType: {
    label: 'Employment type',
  },
  employmentRole: {
    label: 'What was your job/role when you worked with this employer?',
  },
  employmentTypeOther: {
    label: 'Other Employment Type',
  },
  startDate: {
    label: 'Start date',
  },
  endDate: {
    label: 'End date',
  },
  isEmployedByThisEmployer: {
    label: 'Do you currently work for this employer?',
  },
  reasonForLeaving: {
    label: 'Reason for leaving',
  },
  reasonForLeavingOther: {
    label: 'Other reason',
  },
};
