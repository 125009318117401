import { Lookup } from '../lookup.interface';
export interface ClientTypesLookup extends Lookup {
  order: number;
  categoryDesignation?: string;
}
export const ClientTypes: { [name: string]: ClientTypesLookup } = {
  '11': { categoryDesignation: 'ETW', displayValue: 'Self-employed', order: 0 },
  '12': { categoryDesignation: 'ETW', displayValue: 'Employed full-time', order: 1 },
  '13': { categoryDesignation: 'ETW', displayValue: 'Employed part-time', order: 2 },
  '14': { categoryDesignation: 'ETW', displayValue: 'Available for work/training', order: 3 },
  '15': { categoryDesignation: 'ETW', displayValue: 'Attending a program', order: 4 },
  '17': {
    categoryDesignation: 'ETW',
    displayValue: 'Temporarily unable to work/train – health problems',
    order: 5,
  },
  '18': { categoryDesignation: 'ETW', displayValue: 'Temporarily unable to work/train – family care', order: 6 },
  '42': { categoryDesignation: 'BFE', displayValue: 'Medical or multiple barriers', order: 7 },
  '43': { categoryDesignation: 'BFE', displayValue: 'Severe handicap', order: 8 },
  '44': { categoryDesignation: 'BFE', displayValue: 'Self-employed', order: 9 },
  '45': { categoryDesignation: 'BFE', displayValue: 'Employed full-time', order: 10 },
  '46': { categoryDesignation: 'BFE', displayValue: 'Employed part-time', order: 11 },
  '47': { categoryDesignation: 'BFE', displayValue: 'Attending a program', order: 12 },
  '72': { categoryDesignation: 'AAHB', displayValue: 'AISH leaver due to excess employment income', order: 13 },
  '73': { categoryDesignation: 'AAHB', displayValue: 'AISH leaver due to excess CPP-D income', order: 14 },
  '74': { categoryDesignation: 'AAHB', displayValue: 'ETW leaver due to excess employment income', order: 15 },
  '75': {
    categoryDesignation: 'AAHB',
    displayValue: 'BFE leaver due to excess employment income (except HT 43)',
    order: 16,
  },
  '77': {
    categoryDesignation: 'AAHB',
    displayValue: 'AAHB Severe Handicap leaver due to excess employment or CPP-D income (HT 43 only)',
    order: 17,
  },
  '78': { categoryDesignation: 'AAHB', displayValue: 'Health benefits card only (pregnancy)', order: 18 },
  '79': { categoryDesignation: 'AAHB', displayValue: 'Health benefits card only (high drug needs)', order: 19 },
  '81': { displayValue: 'Transient', order: 20 },
  '82': { displayValue: 'Resident', order: 21 },
};

export const OneTimeApprovalClientTypes = { '82': ClientTypes['82'], '81': ClientTypes['81'] };
