import { IntakeLabelCollection } from '../../entities/intake-label.interface';
export const AishSpouseLabels: IntakeLabelCollection = {
  firstName: {
    label: 'First name',
    replacementLabel: "<<replacementLabel>>'s first name",
  },
  middleName: {
    label: 'Middle name',
    replacementLabel: "<<replacementLabel>>'s middle name",
  },
  lastName: {
    label: 'Last name',
    replacementLabel: "<<replacementLabel>>'s last name",
  },
  birthdate: {
    label: 'Birthdate',
    replacementLabel: "<<replacementLabel>>'s birthdate",
  },
  preferredName: {
    label: 'Other preferred first name',
    replacementLabel: "<<replacementLabel>>'s other preferred first name",
  },
  birthCertificateLastName: {
    label: 'Last name on birth certificate',
    replacementLabel: "<<replacementLabel>>'s last name on birth certificate",
  },
  gender: {
    label: 'Gender',
    replacementLabel: "<<replacementLabel>>'s gender",
  },
  socialInsuranceNumber: {
    label: 'Social Insurance Number',
    replacementLabel: "<<replacementLabel>>'s Social Insurance Number",
  },
  isReceivingAISH: {
    label: 'Is your spouse currently receiving AISH?',
    replacementLabel: 'Is your <<replacementLabel>> currently receiving AISH?',
  },
};
