<div class="file-uploading-v1" *ngIf="useLegacy && documentsInProgress?.length > 0">
  <div *ngFor="let documentInProgress of documentsInProgress; let i = index">
    <div class="progressRow" *ngIf="!documentInProgress.errors && documentInProgress?.progress">
      <div class="progressName">
        {{ documentInProgress.name }}
      </div>
      <div class="progressPercentage">
        {{ documentInProgress.progress + '%' }}
      </div>
      <div class="progress-bar">
        <div class="progress" [style.width]="documentInProgress.progress + '%'"></div>
      </div>
    </div>
  </div>
</div>

<div class="file-uploading-v2" *ngIf="!useLegacy && documentsInProgress?.length > 0">
  <div class="files-list">
    <div *ngFor="let file of documentsInProgress; let i = index">
      <div class="single-file" *ngIf="!file.errors && file?.progress">
        <div class="file-icon">
          <mat-icon svgIcon="common-file-icon-pdf" *ngIf="getFileType(file?.name) === 'pdf'"></mat-icon>
          <mat-icon svgIcon="common-file-icon-img" *ngIf="getFileType(file?.name) === 'image'"></mat-icon>
        </div>
        <div class="info">
          <div class="name">{{ file?.name }}</div>
          <div class="size">
            {{ formatBytes(file?.size) }}
          </div>
          <div class="progress-cont">
            <div class="progress" [style.width]="getProgressPercentage(file.progress)"></div>
          </div>
        </div>

        <div class="progressPercentage">
          {{ getProgressPercentage(file.progress, 99) }}
        </div>
        <!-- <div class="delete" (click)="cancelFile(i)">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
        <path
          fill="#B1B1B1"
          fill-rule="nonzero"
          d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z"
        />
      </svg>
    </div> -->
      </div>
    </div>
  </div>
</div>
