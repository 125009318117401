import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'common-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss'],
})
export class SingleSelectComponent implements AfterViewInit {
  @Input() options: { value: unknown; displayValue: string; disabled?: boolean };
  @Input() control: AbstractControl;
  @Input() allowDeselection = true;
  @Input() disabled: boolean;
  @Output() change: EventEmitter<string> = new EventEmitter();
  @Output() deselect = new EventEmitter(); //pass with allowDeselection

  @ViewChild('buttonGroup') buttonToggleGroup: ElementRef;
  selectedValue;
  isFocused = false;

  constructor(private focusMonitor: FocusMonitor) {}

  ngAfterViewInit() {
    this.focusMonitor.monitor(this.buttonToggleGroup.nativeElement, true).subscribe((event) => {
      //not the best place for initial value
      //however by the time onChanged happened, control value is already set
      if (this.selectedValue === undefined) this.selectedValue = this.control.value;
      if (event === null) {
        //blurring, mark as touched
        this.control.markAsTouched();
        this.isFocused = false;
      } else {
        this.isFocused = true;
      }
    });
  }

  onChanged(event) {
    if (this.selectedValue === event.value) {
      this.deselect.emit({ isDeSelectionAllowed: this.allowDeselection });

      this.selectedValue = this.allowDeselection ? null : event.value;
    } else {
      this.selectedValue = event.value;
    }

    // if (this.allowDeselection && this.selectedValue === event.value) {
    //   this.selectedValue = undefined;
    //   this.deselect.emit();
    // } else {
    //   this.selectedValue = event.value;
    // }

    this.control.patchValue(this.selectedValue);
    this.change.emit(this.selectedValue);
  }

  onFocus() {
    this.control.markAsTouched();
  }
}
