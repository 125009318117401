import { Component, Input, OnDestroy, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
import { CalendarEvent } from 'angular-calendar/modules/calendar.module';

@Component({
  selector: 'common-worker-calendar-day-view',
  templateUrl: './day-view.component.html',
  styleUrls: ['./day-view.component.scss'],
})
export class WorkerCalendarDayView implements OnDestroy {
  _destroy$ = new Subject<void>();
  _filteredEvents: CalendarEvent[];
  _eventsSwitch$ = new Subject<void>();
  _isToday = false;

  private _events: CalendarEvent[];
  @Input() set events(value) {
    this._events = value;
    this._eventsSwitch$.next();
  }
  get events() {
    return this._events;
  }
  _viewDate = new Date();
  @Input() set viewDate(value) {
    const dateChanged = !dayjs(value).isSame(this._viewDate, 'day');
    if (!dateChanged) return; //do not do anything if the date hasn't changed

    this._viewDate = value;
    if (value) {
      this._isToday = dayjs(value).isSame(new Date(), 'day');
    }
    this._eventsSwitch$.next();
  }
  get viewDate() {
    return this._viewDate;
  }
  @Input() eventTemplate: TemplateRef<any>;
  @Input() showFullDate = true;
  @Input() isLoading = false;
  @Input() hasMore = false;

  /** Optional template to show the group title on the open day */
  @Input() groupingTemplate?: TemplateRef<void>;

  constructor() {
    this._eventsSwitch$
      .pipe(
        takeUntil(this._destroy$),
        map(() => {
          if (this.events && this.viewDate) {
            return this.events.filter((e) => {
              if (e.start && e.end)
                return (
                  dayjs(e.start).isSameOrBefore(this.viewDate, 'day') &&
                  dayjs(e.end).isSameOrAfter(this.viewDate, 'day')
                );
              if (e.start) return dayjs(e.start).isSame(this.viewDate, 'day');
              if (e.end) return dayjs(e.end).isSame(this.viewDate, 'day');
            });
          } else {
            return [];
          }
        })
      )
      .subscribe((events) => {
        this._filteredEvents = events;
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
