import { Component, Input } from '@angular/core';

@Component({
  selector: 'common-document-progress',
  templateUrl: './document-progress.component.html',
  styleUrls: ['./document-progress.component.scss'],
})
export class DocumentProgressComponent {
  constructor() {}

  @Input() documentsInProgress: Document[];
  @Input() useLegacy = true;

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 0) {
    if (bytes === 0 || bytes < 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getFileType(fileName: string) {
    let fileType = 'image';
    if (fileName.toLowerCase().endsWith('.pdf')) {
      fileType = 'pdf';
    }
    return fileType;
  }

  getProgressPercentage(progress: number, cap = 100) {
    //should never show 100% because these get removed from here once it's complete.
    //there may be a rounding issue rounding up to 100 or file server may be returning 100% before sending us completion status.
    return (progress === 100 ? cap : progress) + '%';
  }

  /**
   * Cancel file from files list
   * Use this if we need to cancel upload on progress bar.
   * @param index (File index)
   */
  // cancelFile(index: number) {
  //   this.documentsInProgress.splice(index, 1);
  // }
}
