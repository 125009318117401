import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'common-management-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent {
  title: string;
  description: string;
  messageDialog: boolean;

  constructor(private dialogRef: MatDialogRef<MessageDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.title = data.title;
    this.description = data.description;
    this.messageDialog = data.messageDialog;
  }

  close() {
    this.dialogRef.close();
  }
}
