import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'yesNoDash' })
export class YesNoDashPipe implements PipeTransform {
  transform(value: string): string {
    if (value === null || value === undefined) return '---';

    return value ? 'Yes' : 'No';
  }
}
