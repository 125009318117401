import { IncomeLookup } from './case-management/case-income-types';

export const AishIncomeTypes: { [name: string]: IncomeLookup } = {
  employmentIncome: {
    order: 1,
    displayValue: 'Employment income',
    tips: 'Money you receive from: salary, wages, bonuses',
    documentTypes: [
      'Pay stubs',
      'Written document from employer (must include name of employer, name of employee, pay period, date of pay, gross income/deductions)',
    ],
    needSupportDocument: true,
  },
  selfEmploymentIncome: {
    order: 2,
    displayValue: 'Self-employment',
    tips: `Earned from a business you operate as a sole proprietor or with someone else as a partner
    including earnings from:
    <ul>
      <li>a business</li>
      <li>sales commission</li>
      <li>farming</li>
      <li>fishing activities</li>
    </ul>`,
    documentTypes: [
      'Most recent income tax return with notice of assessment',
      'Statement of net self-employment income',
      'Audited financial statement',
    ],
    needSupportDocument: true,
  },
  employmentInsurance: {
    order: 3,
    displayValue: 'Employment insurance (EI)',
    tips: `Federal government benefits for: 
    <ul>
      <li>people who have lost a job through no fault of their own – (for example, due to work shortage or seasonal work)</li> 
      <li>people able and available to work, but can’t find a job</li>
      <li>parents on maternity or paternity leave</li>
    </ul>`,
    documentTypes: ['EI payment stubs', 'Statement from EI'],
    needSupportDocument: true,
  },
  canadaPensionPlan: {
    order: 4,
    displayValue: 'Canada Pension Plan (CPP)',
    tips: `Federal government benefits including
    <ul>
      <li>retirement pension (CPP)</li>
      <li>disability benefit (CPP-D)</li>
      <li>survivor’s pension</li>
    </ul>`,
    documentTypes: ['Statement from source', 'Cheque stub', 'Latest income tax assessment', 'Direct deposit statement'],
    needSupportDocument: true,
  },
  oldAgeSecurity: {
    order: 5,
    displayValue: 'Old Age Security (OAS)',
    tips: `Federal government benefits for seniors aged 65 and older and/or their spouses/partners, aged 60 to 64:
    <ul>
      <li>Old Age Security pension (OAS)</li>
      <li>Allowance for the survivor</li>
      <li>Guaranteed Income Supplement (GIS)</li>
      <li>Allowance for a GIS recipient’s spouse/partner</li>
    </ul>`,
    documentTypes: ['Direct deposit statement', 'Cheque stub', 'Written statement from WCB'],
    needSupportDocument: true,
  },
  pensionIncome: {
    order: 6,
    displayValue: 'Pension income from previous employment',
    tips: `Income provided by a pension program like:
    <ul>
      <li>military pension</li>
      <li>provincial pension plans</li>
      <li>retirement compensation arrangements</li>
    </ul>`,
    documentTypes: [],
    needSupportDocument: true,
  },
  disabilityInsurance: {
    order: 7,
    displayValue: 'Disability/Wage Loss Insurance',
    tips: 'Payments from an insurance company when an employee is no longer able to earn a living',
    documentTypes: [],
    needSupportDocument: true,
  },
  trustAccountIncome: {
    order: 8,
    displayValue: 'Income from trust accounts',
    tips: 'Include principal and interest payments',
    documentTypes: [],
    needSupportDocument: true,
  },
  investmentIncome: {
    order: 9,
    displayValue: 'Income from investments',
    tips: `<ul>
    <li>Interest payments</li>
    <li>Dividends</li>
    <li>Capital gains from selling a security or other assets</li>
    <li>Any other profit from any investment</li>
  </ul>`,
    documentTypes: [
      'Statement from payer, broker, or bank',
      'Cheque stubs',
      'Direct deposit/bank statement',
      'Latest income tax assessment',
    ],
    needSupportDocument: true,
  },
  rentalIncome: {
    order: 10,
    displayValue: 'Income from a rental property',
    tips: 'Rental Income: Income from a property or room you rent to someone else',
    documentTypes: [
      'Income tax return with notice of assessment (most recent)',
      'Rent book/receipts',
      'Lease or rental agreement',
      'Written statement/declaration from boarder',
    ],
    needSupportDocument: true,
  },
  lifeInsuranceIncome: {
    order: 11,
    displayValue: 'Life insurance income',
    tips: 'Income paid to you as a beneficiary of a life insurance policy',
    documentTypes: [],
    needSupportDocument: true,
  },
  guaranteedSupplement: {
    order: 12,
    displayValue: 'Guaranteed Income Supplement',
    tips: '',
    documentTypes: [],
    needSupportDocument: true,
  },
  spousalSupport: {
    order: 13,
    displayValue: 'Spousal Support/Alimony',
    tips: 'Money that one spouse/partner may have to pay the other spouse/partner due to a separation or divorce',
    documentTypes: ['Decree Nisi', 'Separation agreement', 'Court order', 'Confirmation from Child Support Service'],
    needSupportDocument: true,
  },
  wcbIncome: {
    order: 14,
    displayValue: "Workers' Compensation Benefits",
    tips: 'Payment for workplace injuries or illnesses',
    documentTypes: [
      'Income tax return with notice of Assessment (most recent)',
      'WCB payment stub',
      'Written statement from WCB',
    ],
    needSupportDocument: true,
  },

  otherSourcesOfIncome: {
    code: 'otherSourcesOfIncome',
    order: 15,
    displayValue: 'Other sources of income',
    documentTypes: ['Other sources of income'],
    needSupportDocument: true,
    helpText: 'e.g. Money in trust for children, gifts',
    isSpecial: true,
  },
  specialPayment: {
    code: 'specialPayment',
    order: 16,
    displayValue: 'Special payment',
    documentTypes: ['Special payment'],
    needSupportDocument: true,
    isSpecial: true,
  },
};
