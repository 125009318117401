import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FbBenefitLabels: IntakeLabelCollection = {
  benefitType: {
    label: 'Which benefit will you be requesting?',
  },
  seekingReimbursement: {
    label: 'Have you paid any amount toward the funeral?',
    helperText: 'Please note that you are not required to pay for services before submitting this application.',
  },
  paidInFull: {
    label: 'Have you finished paying for all the services?',
  },
  passAwayLast6Months: {
    label: 'Were these expenses paid in the last 6 months?',
  },
  purchasedBefore: {
    label: 'Was there an emergency reason why the service had to be paid before submitting this application?',
    helperText: 'For example: health, safety or religious.',
  },
  emergencyReason: {
    label: 'Please explain the reason',
  },
};
