import { ContactOutcomes } from './contact-outcomes';
import { EligibilityDecisions } from './eligibility-decisions';
import { NeedsIdentificationStatuses } from './ni-statuses';

export const CaseStubStatuses = {};

for (const [key, value] of Object.entries(NeedsIdentificationStatuses.KeyLookup)) {
  CaseStubStatuses[key] = { ...value };
  //prefix NI status displayValues with "NI - "
  CaseStubStatuses[key].displayValue = `NI - ${CaseStubStatuses[key].displayValue}`;
}

for (const [key, value] of Object.entries(ContactOutcomes.Values)) {
  CaseStubStatuses[key] = value;
}

for (const [key, value] of Object.entries(EligibilityDecisions)) {
  CaseStubStatuses[key] = value;
}
