import { Lookup } from './lookup.interface';
export const EmploymentTypes: {
  [name: string]: Lookup;
} = {
  fullTime: { code: 'fullTime', displayValue: 'Full-time' },
  partTime: { code: 'partTime', displayValue: 'Part-time' },
  selfEmployed: { code: 'selfEmployed', displayValue: 'Self employed' },
  seasonal: { code: 'seasonal', displayValue: 'Seasonal/occasional' },
  volunteer: { code: 'volunteer', displayValue: 'Volunteer (unpaid)' },
  other: { code: 'other', displayValue: 'Other' },
};
