import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { DateValidator } from '../validators/date.validator';

/*
 This is a clone MinDateValidator with input value change and revalidation support
*/
@Directive({
  selector: '[iesResponsiveMinDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: ResponsiveMinDateValidator,
      multi: true,
    },
  ],
})
export class ResponsiveMinDateValidator implements Validator {
  private _onChange?: () => void;
  private _minTime;

  @Input('iesResponsiveMinDate')
  get minDateValue() {
    return this._minTime;
  }

  set minDateValue(value) {
    this._minTime = value;
    if (this._onChange) {
      this._onChange();
    }
  }

  @Input('iesMinDateErrorMessage') message = null;

  validate(control: AbstractControl) {
    return DateValidator.minDate(this.minDateValue, '', this.message)(control);
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }
}
