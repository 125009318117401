<div class="date">
  <input
    #dateInput
    type="text"
    data-cy="calendar-text-input"
    [placeholder]="placeholderText"
    [matDatepicker]="date"
    [formControl]="_date"
    [matDatepickerFilter]="dateFilter"
    disableRipple
    (keydown)="onKeyDown($event)"
    (blur)="onBlur()"
    [max]="_maxDateDayJs"
    [min]="_minDateDayJs"
  />
  <mat-datepicker-toggle
    data-cy="calendar-open-button"
    [disableRipple]="true"
    class="icon toggle-highlight"
    matSuffix
    [for]="date"
  >
    <span class="icon" matDatepickerToggleIcon>
      <span class="expander-icon"></span>
    </span>
  </mat-datepicker-toggle>
  <mat-datepicker #date data-cy="calendar" disableRipple></mat-datepicker>
</div>
