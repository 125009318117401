import { ClipboardTransformerBase } from './clipboard-transformer.base';

const fontWeightPattern = /\bfont-weight: (\w*)/;
const fontStylePattern = /\bfont-style: (\w*)/;
const textDecorationPattern = /\btext-decoration: ([\w\s]+)/;

/**
 * MSOWebTransformer
 * Detect and convert pastes from MS Word's browser based editor.
 * Captures style elements before they are scrubbed down-stream.
 * Scrubs some elements that result in whitespace.
 */
export class MSWordWebTransformer extends ClipboardTransformerBase {
  transform(document: Document) {
    const clipboardRoot = document.querySelector('#WACViewPanel_ClipboardElement');
    if (!clipboardRoot) {
      return;
    }

    console.debug('Detected MSWord web paste...');
    document.body.innerHTML = clipboardRoot.innerHTML;

    this.removeCCPPropsElements(document.body);

    this.replaceStyleInTextRuns(document.body);
  }

  private removeCCPPropsElements(body: HTMLElement) {
    const ccpProps = body.querySelectorAll('[data-ccp-props]');

    ccpProps.forEach((el) => el.remove());
  }

  private replaceStyleInTextRuns(body: HTMLElement) {
    const textRuns = body.querySelectorAll('.TextRun');

    for (const c in textRuns) {
      let el = textRuns[c] as HTMLElement;
      if (!(el instanceof HTMLElement)) {
        continue;
      }

      const modifiers = this.getTextModifiers(el);
      if (!modifiers) {
        continue;
      }

      if (modifiers.bold) {
        el = this.wrapElementInTag(el, 'b');
      }

      if (modifiers.italic) {
        el = this.wrapElementInTag(el, 'i');
      }

      if (modifiers.strikethrough) {
        el = this.wrapElementInTag(el, 's');
      }

      if (modifiers.underlined) {
        el = this.wrapElementInTag(el, 'u');
      }
    }
  }

  private wrapElementInTag(el: HTMLElement, tagName: string): HTMLElement {
    const newEl = el.ownerDocument.createElement(tagName);
    newEl.innerHTML = el.outerHTML;
    el.replaceWith(newEl);

    return newEl;
  }

  private getTextModifiers(el: HTMLElement): TextStyles | null {
    const style = el.getAttribute('style');
    if (!style) {
      return null;
    }

    const fontWeightMatch = style.match(fontWeightPattern);
    const fontStyleMatch = style.match(fontStylePattern);
    const decorationMatch = style.match(textDecorationPattern);

    let hasUnderline = false;
    let hasStrikethrough = false;

    if (decorationMatch?.length === 2) {
      hasUnderline = decorationMatch[1].includes('underline');
      if (hasUnderline) {
        decorationMatch[1] = decorationMatch[1].replace('underline', '');
      }

      hasStrikethrough = decorationMatch[1].includes('line');
    }

    return {
      bold: fontWeightMatch?.length === 2 && fontWeightMatch[1] === 'bold',
      italic: fontStyleMatch?.length === 2 && fontStyleMatch[1] === 'italic',
      strikethrough: hasStrikethrough,
      underlined: hasUnderline,
    };
  }

  private hasCCPPropsAttribute(node: HTMLElement): boolean {
    return node instanceof HTMLElement && node.getAttribute('data-ccp-props') != null;
  }
}

interface TextStyles {
  bold: boolean;
  italic: boolean;
  underlined: boolean;
  strikethrough: boolean;
}
