<div [formGroup]="formGroup">
  <section aria-labelledby="services-section-label" class="sub-form">
    <h2 id="services-section-label" data-cy="page-title">{{ fiServicesLabels.header | labelText }}</h2>
    <div>{{ fiServicesLabels.description | labelText }}</div>
    <br />

    <goa-callout type="information">
      {{ fiServicesLabels.moreInfoText | labelText }}
      <a href="https://www.alberta.ca/fscd-supports-and-services.aspx" target="_blank"> FSCD - Supports and services</a>
      .
    </goa-callout>
    <div>Please select which of the below services you believe your family could benefit from:</div>
    <br />
    <div class="form-control question">
      <common-fieldset-control
        [label]="fiServicesLabels.homeRespite | labelText"
        [helperText]="fiServicesLabels.homeRespite.helperText"
      >
        <div [class.error]="formGroup.controls?.homeRespite?.errors && formGroup.controls?.homeRespite?.touched">
          <mat-radio-group color="primary" [formControl]="formGroup.controls.homeRespite">
            <mat-radio-button
              *ngFor="let item of customRadioOptions | keyvalue: unsort"
              [value]="item.key"
              [attr.data-cy]="'homeRespite-' + item.key"
            >
              {{ item.value['displayValue'] }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="error-text"
          *ngIf="formGroup?.controls?.homeRespite?.errors && formGroup?.controls?.homeRespite?.touched"
        >
          This field is required.
        </div>
      </common-fieldset-control>
    </div>
    <div class="form-control question">
      <common-fieldset-control
        [label]="fiServicesLabels.medicalAppointment | labelText"
        [helperText]="fiServicesLabels.medicalAppointment.helperText"
      >
        <div
          [class.error]="
            formGroup.controls?.medicalAppointment?.errors && formGroup.controls?.medicalAppointment?.touched
          "
        >
          <mat-radio-group color="primary" [formControl]="formGroup.controls.medicalAppointment">
            <mat-radio-button
              *ngFor="let item of customRadioOptions | keyvalue: unsort"
              [value]="item.key"
              [attr.data-cy]="'medicalAppointment-' + item.key"
            >
              {{ item.value['displayValue'] }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="error-text"
          *ngIf="formGroup?.controls?.medicalAppointment?.errors && formGroup?.controls?.medicalAppointment?.touched"
        >
          This field is required.
        </div>
      </common-fieldset-control>
    </div>
    <div class="form-control question">
      <common-fieldset-control
        [label]="fiServicesLabels.counselling | labelText"
        [helperText]="fiServicesLabels.counselling.helperText"
      >
        <div class="helper-text">
          <div class="helper-list">
            {{ fiServicesLabels.counsellingNote.label }}
          </div>
        </div>
        <div [class.error]="formGroup.controls?.counselling?.errors && formGroup.controls?.counselling?.touched">
          <mat-radio-group color="primary" [formControl]="formGroup.controls.counselling">
            <mat-radio-button
              *ngFor="let item of customRadioOptions | keyvalue: unsort"
              [value]="item.key"
              [attr.data-cy]="'counselling-' + item.key"
            >
              {{ item.value['displayValue'] }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="error-text"
          *ngIf="formGroup?.controls?.counselling?.errors && formGroup?.controls?.counselling?.touched"
        >
          This field is required.
        </div>
      </common-fieldset-control>
    </div>
    <div class="form-control question">
      <common-fieldset-control
        [label]="fiServicesLabels.clothingFootwear | labelText"
        [helperText]="fiServicesLabels.clothingFootwear.helperText"
      >
        <div
          [class.error]="formGroup.controls?.clothingFootwear?.errors && formGroup.controls?.clothingFootwear?.touched"
        >
          <mat-radio-group color="primary" [formControl]="formGroup.controls.clothingFootwear">
            <mat-radio-button
              *ngFor="let item of customRadioOptions | keyvalue: unsort"
              [value]="item.key"
              [attr.data-cy]="'clothingFootwear-' + item.key"
            >
              {{ item.value['displayValue'] }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="error-text"
          *ngIf="formGroup?.controls?.clothingFootwear?.errors && formGroup?.controls?.clothingFootwear?.touched"
        >
          This field is required.
        </div>
      </common-fieldset-control>
    </div>
    <div *ngIf="hasOptedForAnyService()" data-cy="fscd-agreement">
      <h2>{{ fiServicesLabels.agreementHeader | labelText }}</h2>
      <div class="helper-text">
        <p class="margin-bt">
          {{
            hasConfirmedDiagnosis
              ? (fiServicesLabels.agreementText | labelText: '3')
              : (fiServicesLabels.agreementText | labelText: '2')
          }}
        </p>
        <div class="margin-bt">
          {{ fiServicesLabels.agreementBenefits | labelText }}
          <ul class="helper-list">
            <li>{{ fiServicesLabels.agreementBenefitsList | labelText }}</li>
            <li>{{ fiServicesLabels.agreementBenefitsList2 | labelText }}</li>
            <li>{{ fiServicesLabels.agreementBenefitsList3 | labelText }}</li>
          </ul>
        </div>
      </div>
      <div class="form-control question">
        <common-fieldset-control [label]="fiServicesLabels.agreementDuration | labelText" data-cy="agreementDuration">
          <div
            [class.error]="
              formGroup.controls?.agreementDuration?.errors && formGroup.controls?.agreementDuration?.touched
            "
          >
            <mat-radio-group color="primary" [formControl]="formGroup.controls.agreementDuration">
              <mat-radio-button
                *ngFor="
                  let item of (hasConfirmedDiagnosis ? serviceDurationOptions : modifiedServiceDurationOptions)
                    | keyvalue: unsort
                "
                [value]="item.key"
                [attr.data-cy]="'agreementDuration-' + item.key"
              >
                {{ item.value['displayValue'] }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div
            class="error-text"
            *ngIf="formGroup?.controls?.agreementDuration?.errors && formGroup?.controls?.agreementDuration?.touched"
          >
            This field is required.
          </div>
        </common-fieldset-control>
      </div>
    </div>
  </section>
</div>
