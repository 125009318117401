import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { CssRoles } from '@common/constants';
import { User } from '@common/entities';
import { AppConfigService, ContextProvider } from '@common/ui/shared-components';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';
import { FiGraphqlService } from '../../../services/fi-graphql.service';
import { PageNavigation } from '../../page-navigation';

@Component({
  selector: 'fi-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit, OnDestroy {
  constructor(
    private authenticationService: AuthenticationService,
    private contextProvider: ContextProvider,
    private router: Router,
    private fiGraphqlService: FiGraphqlService,
    private route: ActivatedRoute,
    public configService: AppConfigService
  ) {}

  showMessage = false;
  isApplicationPage = false;
  isExpanded = false;
  isApplicationSubmitted = false;
  clickedInsideComponent = false;
  profile$;
  profile: User = null;
  primaryRole;
  //profile: User = null;
  _destroy$ = new Subject<void>();
  // serviceUrl = null;

  CssRoles = CssRoles;
  @ViewChild('panel') panel;
  validateStep = false;

  @Input() applicationComponent;

  async ngOnInit(): Promise<void> {
    this.isApplicationPage = this.isInApplication(this.route.snapshot?.url[0]?.path);

    this.profile$ = this.contextProvider.getCurrentUser();

    this.profile$.pipe(takeUntil(this._destroy$)).subscribe((data) => {
      if (!this.profile) {
        this.profile = data;
      }
    });

    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(async (nav: NavigationEnd) => {
      const navUrl = nav.url;
      this.isApplicationPage = this.isInApplication(navUrl);
      if (this.panel) {
        this.panel.close();
      }
    });

    this.fiGraphqlService
      .getSelectedApplicationSub()
      .pipe(
        filter(({ selectedApplicationId }) => !!selectedApplicationId),
        switchMap(({ selectedApplicationId }) => this.fiGraphqlService.getFullApplication(selectedApplicationId))
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe((appInfo) => {
        if (appInfo?.id) {
          this.isApplicationSubmitted = appInfo.isSubmitted;
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  navigateToPage(url: string): void {
    if (this.router) {
      if (this.isExpanded) {
        this.panel.close();
      }
      this.router.navigateByUrl(url);
    }
  }

  isDisabled(route: string): boolean {
    if (this.router.url.endsWith(route)) {
      return true;
    }
    return false;
  }

  async saveCurrentApplication() {
    this.showMessage = false; //reset show message default to false
    let valid = true;

    const component = this.applicationComponent?.routerOutlet.component as PageNavigation;
    if (component?.next) {
      valid = component.next();
    }
    if (!this.applicationComponent?.doSave || !valid) {
      return;
    }
    this.validateStep = false;
    await this.applicationComponent.doSave(null);
    this.showMessage = true; //if it saves without issues show message
    setTimeout(() => {
      this.showMessage = false; //hide after 5 seconds
    }, 5000);
  }

  expand(expanded: boolean) {
    if (expanded === null) {
      this.isExpanded = !this.isExpanded;
    } else {
      this.isExpanded = expanded;
    }
  }

  logout(url?: string) {
    const returnUrl = url ? url : window.location.origin;
    this.contextProvider.setCurrentUser(null);
    this.authenticationService.signOut(returnUrl);
  }

  login(url?: string) {
    const returnUrl = url ? url : window.location.origin;
    this.contextProvider.setCurrentUser(null);
    this.authenticationService.signIn(returnUrl);
  }

  changePassword() {
    this.authenticationService.changePassword();
  }

  isInApplication(url: string) {
    return url ? url.includes('application') : false;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.clickedInsideComponent) {
      if (this.isExpanded) {
        this.panel.close();
      }
    }
    this.clickedInsideComponent = false;
  }

  @HostListener('click')
  clickInside() {
    this.clickedInsideComponent = true;
  }
}
