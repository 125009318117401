import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, ControlContainer } from '@angular/forms';
import { LivingSituation } from '@common/entities';
import { LivingSituations } from '@common/constants';
import { SELECT_ONE_TEXT } from '@common/constants';
import { StringValidator } from '../ui-shared-components.validators';

@Component({
  selector: 'common-living-situation',
  templateUrl: './living-situation.component.html',
  styleUrls: ['./living-situation.component.scss'],
})
export class LivingSituationComponent implements OnInit {
  selectOneText = SELECT_ONE_TEXT;
  firstTime = true;
  @Input() label: string;
  private _livingSituation: LivingSituation;
  @Input() set livingSituation(value: LivingSituation) {
    this._livingSituation = value;
    this.setFormData();
  }
  @Output() selectionValueChange = new EventEmitter<string>();

  livingSituations = LivingSituations;
  formGroup;
  constructor(private formBuilder: UntypedFormBuilder, private controlContainer: ControlContainer) {}
  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as UntypedFormGroup;
    const isDisabled = this.formGroup.disabled;
    this.formGroup.addControl('type', this.formBuilder.control('', Validators.required), { emitEvent: false });
    this.setFormData();
    if (isDisabled) this.formGroup.disable({ emitEvent: false });
  }

  onLivingSituationSelect() {
    //if (this.formGroup.controls.other) this.formGroup.removeControl('other');
    if (this.formGroup.controls.institutionName) this.formGroup.removeControl('institutionName');
    if (this.formGroup.controls.longTermCareFacilityName) this.formGroup.removeControl('longTermCareFacilityName');

    this.addFormControl(this.formGroup.controls.type.value);
    this.selectionValueChange.emit(this.formGroup.controls.type.value);
  }
  private setFormData() {
    if (this.formGroup && this._livingSituation && this.firstTime) {
      this.firstTime = false;
      this.addFormControl(this._livingSituation.type, false);
      this.formGroup.patchValue(this._livingSituation, { emitEvent: false });
    }
  }
  private addFormControl(type: string, emitEvent = true) {
    if (LivingSituations[type]?.controlName)
      this.formGroup.addControl(
        LivingSituations[type].controlName,
        this.formBuilder.control('', { validators: [StringValidator.notEmpty], updateOn: 'blur' }),
        {
          emitEvent: emitEvent,
        }
      );
  }
  unsort() {
    return 0;
  }
}
