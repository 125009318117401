import { Platform } from '@angular/cdk/platform';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MonthpickerDateAdapter } from './month-picker-date-adapter';

/**
 * Component for date entry using a calendar.  This will supply only the month and year
 */
@Component({
  selector: 'common-calendar-month-year',
  templateUrl: './calendar-month-year.component.html',
  styleUrls: ['./calendar-month-year.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: CalendarMonthYearComponent,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MonthpickerDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
  ],
})
export class CalendarMonthYearComponent implements ControlValueAccessor {
  @Output()
  public monthAndYearChange = new EventEmitter<Date | null>();
  minDate = new Date(new Date().getFullYear() - 100, 0, 1); // 100 years in the past
  maxDate = new Date(new Date().getFullYear() + 100, 0, 1); // 100 years in the future

  @Input() formControl: UntypedFormControl;

  writeValue(obj: any): void {
    // Do Nothing
  }

  change;
  registerOnChange(fn) {
    this.change = fn;
  }
  touch;
  registerOnTouched(fn) {
    this.touch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Do Nothing
  }

  public monthChanged(value: any, widget: any): void {
    this.monthAndYearChange.emit(value);
    widget.close();
  }
}
