import { AppComponent } from './app.component';

export const ROUTES = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('@fscd-intake/ui/application').then((module) => module.FscdIntakeModule),
      },
    ],
  },
];
