import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@common/entities';
import { ContextProvider } from '@common/ui/shared-components';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';

@Component({
  selector: 'fi-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit {
  user: User = null;
  redirectUrl = encodeURI('/dash');
  preScreenPage = '/prescreen';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private contextProvider: ContextProvider
  ) {}

  ngOnInit() {
    this.contextProvider.getCurrentUser().subscribe((user) => {
      this.user = user;
    });
  }

  navigateToPage(url: string): void {
    this.router.navigateByUrl(url);
  }

  login() {
    this.authenticationService.signIn(this.redirectUrl);
  }
}
