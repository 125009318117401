import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CssRoles, FscdIntakeSessionStorageKeys } from '@common/constants';
import { ContextProvider, ContextService } from '@common/ui/shared-components';
import { Utilities } from '@common/utils';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';
import { Apollo, gql } from 'apollo-angular';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { FSCD_APPLICATION_DETAIL } from '../pages/gql-fragments';
import { SELECTED_APPLICATION_QUERY } from '../pages/page-gql';

const GQL_SECURE_APPLICATION = gql`
query application($id: String!) {
  application(id: $id) {
    ${FSCD_APPLICATION_DETAIL}
  }
}`;

@Injectable({
  providedIn: 'root',
})
export class IntakeApplicationAuthGuard extends KeycloakAuthGuard {
  utils = Utilities;
  defaultNotAuthorized = '/forbidden';
  defaultInvalid = '/invalid';
  defaultTimeout = '/timeout';

  constructor(
    protected readonly router: Router,
    protected readonly keycloakService: KeycloakService,
    protected readonly authenticationService: AuthenticationService,
    private apollo: Apollo,
    private contextProvider: ContextProvider,
    private contextService: ContextService
  ) {
    super(router, keycloakService);
  }

  //This is still under construction.
  public async isAccessAllowed(route: ActivatedRouteSnapshot): Promise<boolean> {
    // Force the user to log in if currently unauthenticated.

    // if (!this.authenticated) {
    //   this.router.navigate(['/notauthorized']);
    // }

    const applicationId = route.params.applicationId ?? (route.paramMap ? route.paramMap['applicationId'] : null);
    if (!applicationId) {
      return true;
    }
    this.apollo.client.writeQuery({
      query: SELECTED_APPLICATION_QUERY,
      data: {
        selectedApplicationId: applicationId,
        isSubmitted: false, //TODO: implement localstorage for not parameterized client graph fields
      },
    });

    let granted = false;

    //get application email

    // make sure the application is cached
    const applicationUserId = applicationId ? await this.getApplicationAuthorizedUserId(applicationId) : null; //authorized keycloak id for requesting application

    //get current logged in user keycloak id
    let userid = null;
    let isAdmin = false;
    if (this.authenticated) {
      const user = await this.contextService.getContextUserProfile();
      this.contextProvider.setCurrentUser(user);
      userid = user._id;
      isAdmin = user.roles?.some((role) => role == CssRoles.CSSAdmin.code);
    } else {
      this.contextProvider.setCurrentUser(null);
    }

    if (isAdmin) return (granted = true);

    if (applicationUserId !== -1 && applicationUserId !== -2) {
      if (userid !== null && userid === applicationUserId) {
        //If current user is also the authorizedUser.
        granted = true;
      } else if (applicationUserId === null) {
        //If the application has no authorizedUser then:

        //Option 1: Grant access to all applications that are anonymously created
        //granted = true;

        //Option 2: Check the session storage to see if the user's application session is valid, and only grant them access to that application for that browser session.
        //const sessionApplicationIds = this.getUserSessionApplicationIds();
        const sessionApplicationIds = this.utils.getSessionStorageArray<string>(
          FscdIntakeSessionStorageKeys.IntakeApplicationSessionId.key
        );
        if (sessionApplicationIds.indexOf(applicationId) >= 0) {
          granted = true;
        }
      }
    } else if (applicationUserId === -1) {
      //invalid application ID request
      const invalidPage = route?.data?.invalidPage ?? this.defaultInvalid;
      const timeoutPage = route?.data?.timeoutPage ?? this.defaultTimeout;
      if (this.authenticated) {
        this.router.navigate([invalidPage]);
      } else {
        //not authenticated, user is timed out
        this.router.navigate([timeoutPage]);
      }

      return false;
    }

    if (granted === false) {
      const notAuthorizedPage = route?.data?.notAuthorizedPage ?? this.defaultNotAuthorized;
      this.router.navigate([notAuthorizedPage]);
      return false;
    }

    //resolve(granted);
    return true;
  }

  //returns -1 for invalid applications
  //returns -2 for valid application but not authorized
  private async getApplicationAuthorizedUserId(applicationId: string) {
    const applicationQuery = await this.apollo.query({
      query: GQL_SECURE_APPLICATION,
      variables: {
        id: applicationId,
      },
    });

    if (!applicationQuery) {
      return -1;
    }

    let validApplication = false;
    const authorizedUserId = applicationQuery
      .toPromise()
      .then((result) => {
        //this will get keycloak id that's authorized to access this application. valid application if it gets in here.

        //the application property will be null application/anonymousApplication object will be null if authorizedUser does not match current user.
        validApplication = true;

        let application = null;
        if (!this.authenticated) {
          application = result.data['anonymousApplication'];
        } else {
          application = result.data['application'];
        }
        const applicationUserId = application.authorizedUser;
        return applicationUserId;
      })
      .catch((ex) => {
        if (validApplication || ex?.message === 'Forbidden') {
          return -2;
        }
        return -1; //using -1 to indicate an error due to application does not exist with this application id
      });
    return authorizedUserId;
  }
}
