import { IntakeLabelCollection } from '../../entities/intake-label.interface';
export const AishAdditionalAssetsLabels: IntakeLabelCollection = {
  hasVehicle: {
    label: 'Do you own a vehicle?',
    replacementLabel: 'Do you or your <<replacementLabel>> own a vehicle?',
  },
  vehicleInformation: {
    label: 'Vehicle information (approximate value)',
    alternateLabel: 'Vehicle information',
  },
  hasRecreationalVehicle: {
    label: 'Do you own a recreational vehicle?',
    replacementLabel: 'Do you or your <<replacementLabel>> own a recreational vehicle?',
  },
  recreationalVehicleInformation: {
    label: 'Recreational vehicle information (approximate value)',
    alternateLabel: 'Recreational vehicle information',
  },
  hasProperty: {
    label: 'Do you own property?',
    replacementLabel: 'Do you or your <<replacementLabel>> own property?',
  },
  propertyInformation: {
    label: 'Property information (approximate value)',
    alternateLabel: 'Property Information',
  },
  hasFarm: {
    label: 'Do you own a farm?',
    replacementLabel: 'Do you or your <<replacementLabel>> own a farm?',
  },
  hasHomeQuarter: {
    label: 'Do you live on a home quarter section?',
    replacementLabel: 'Do you or your <<replacementLabel>> live on a home quarter section?',
  },
  farmInformation: {
    label: 'Farm information (approximate value)',
    alternateLabel: 'Farm',
  },
  hasBusiness: {
    label: 'Do you own a business?',
    replacementLabel: 'Do you or your <<replacementLabel>> own a business?',
  },
  businessInformation: {
    label: 'Business information (approximate value)',
    alternateLabel: 'Business',
  },
};
