<div class="session-expired">
  <h2>Session timeout</h2>
  <div>
    <p>
      Your session has timed out.
      <br />
      <!-- Click <a href="/profile">here</a> to go back to profile. -->
      Click <a class="link-button" (click)="login('/dash')">here</a> to log back in.
    </p>
  </div>
</div>
