import { Expose } from 'class-transformer';
import { IsNotEmpty, IsOptional } from 'class-validator';
import { LegalNameFormat } from '../custom-validation-functions/legal-name';

export class BasePerson {
  @Expose()
  @IsNotEmpty()
  @LegalNameFormat()
  firstName?: string;

  @Expose()
  @LegalNameFormat()
  @IsOptional()
  middleName?: string;

  @Expose()
  @LegalNameFormat()
  @IsOptional()
  preferredName?: string;

  @Expose()
  @IsNotEmpty()
  @LegalNameFormat()
  lastName?: string;

  @Expose()
  @IsNotEmpty()
  birthdate?: Date;

  @Expose()
  gender?: string;
}
