import { DocumentsUpload } from './documents-upload.interface';

//displayValue: is the title on the document uploader (ex: Copy of 'medical letter').
//  -Also shows in the documentType column in the PDF if no doc types selected (only 1 doc type).
//displayCategory: is the category column value in the PDF (only used in PDF). Friendly text to the documentCategory alias (this is needed for optOut)
//  -If this isn't specified, it will use the property alias transformed (notWorkingReasonPersonal) using startCase(camelCase('notWorkingReasonPersonal').
//documentTypes: is the list of document types that can be selected for this category.

export const FiDocumentCategories: { [name: string]: DocumentsUpload } = {
  child: {
    citizen: {
      displayValue: 'Identification',
      documentTypes: [
        'Canadian birth certificate',
        'Canadian record of live birth',
        'Canadian passport',
        'Canadian citizenship card',
      ],
      order: 1,
    },
    permanentResident: {
      displayValue: 'Identification',
      documentTypes: ['Canadian permanent residency card', 'IMM5292 confirmation of permanent residency'],
      order: 2,
    },
    medical: {
      displayValue: 'Medical document',
      documentTypes: [
        'Audiologist documentation',
        'Multi-disciplinary report',
        'Occupational therapist documentation',
        'Physician or specialist documentation',
        'Psychoeducational or IQ assessment',
        'Psychiatrist, psychologist or clinical social worker documentation',
        'Physical therapist documentation',
        'Speech and language pathologist documentation',
      ],
      order: 3,
    },
    additional: {
      displayValue: 'Additional document', //Upload child’s additional document(s)
      categoryTitleOverride:
        'Please upload any other child documentation to be considered as part of this application. <span class="document-optional">(Optional)</span>',
      documentTypes: [
        'Individualized Program Plan (IPP): Educational support plan for Program Unit Funding (PUF) to Grade 12',
        'School report card',
        'Behavioural support plan',
        'Childcare/daycare report',
        'Support letter from a community support/agency',
        'Additional information from the guardian',
        'Other',
      ],
      isOptional: true,
      suppressOptionalLabel: true,
      hideDocumentTypeLabel: true,
      order: 4,
    },
  },
  parent: {
    identification: {
      displayValue: 'Identification',
      documentTypes: ['Guardianship order', 'Adoption order'],
      categoryTitleOverride: 'Proof of guardianship',
      requiredErrorOverride: 'Proof of relationship to the child is required',
    },
  },
};
