import { DocumentDetails } from './document-details.interface';
import { FbAppData } from './fb-app-data.interface';

export class DIMGJob {
  applicationId: string;
  applicationType: string;
  submittedDate: Date;
  collection: DocumentDetails[];
  target: string;
  highPriority?: boolean;
  shortId?: string;
  region?: string;
  pid?: string;
  birthdate?: Date;
  jobId?: string;
  jobQueueName?: string;
  fbAppData?: FbAppData;
  clientFullName?: string;
}
