import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, lastValueFrom, Subscription } from 'rxjs';

import { AppConfigService } from '../config/config.service';
import { DIMGJob, DocumentDetails } from '@common/entities';
import { Apollo } from 'apollo-angular';
import { gql } from '@apollo/client/core';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';

//needs to be text/plain so not to trigger an OPTIONS call
// const headers = new HttpHeaders({
//   'Content-Type': 'text/plain',
// });
// interface Response {
//   key?: string;
//   uploadURL: string;
// }

export const SERVICE_PROVIDER_QUERY = gql`
  query getProviderList {
    providers @client {
      id
      css_vendor_program
      css_vendor_partnerNumber
      css_vendor_type
      css_vendor_name
      css_vendor_visible
      css_vendor_address
      css_vendor_city
      css_vendor_prov
      css_vendor_postalcode
      css_vendor_phone
      css_vendor_email
      css_vendor_fax
      edit
      delete
      include
      print
      create
      read
      barcode
      brokerID
      css_vendor_id
      css_vendor_comment
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DimgService {
  readonly FILES_ENDPOINT = this.configService.getConfig().FILES_ENDPOINT;
  private ALBERTA_WORKS_URL =
    this.configService.getConfig().DIMG_URL_ALBERTA_WORKS ?? 'http://train-dimg/p/alberta-works/';

  constructor(private http: HttpClient, private configService: AppConfigService, public apollo: Apollo) {}

  //collect no longer exists and has been refactored
  //OLD method can be found here: apps\file-server\src\old-file-server-code\dimg\dimg.controller.ts
  collect(applicationId: string, sortedCollectionOfFiles: DocumentDetails[], target?: string): Observable<string[]> {
    const payLoad = {
      applicationId: applicationId,
      collection: sortedCollectionOfFiles,
      target: target,
    };
    const options = { reportProgress: true, headers: new HttpHeaders({ timeout: `${10 * 60 * 1000}` }) };
    return this.http.post<string[]>(`${this.FILES_ENDPOINT}/collect`, payLoad, options);
  }

  send(collection: string[], applicationId: string) {
    const payLoad = {
      applicationId: applicationId,
      collection: collection,
    };
    return this.http.post(`${this.FILES_ENDPOINT}/send`, payLoad);
  }

  getAlbertaWorksPidSearchUrl(pid: string) {
    const pidSearchPath = 'pid-search/?pid=';
    let dimgUrl: string = this.ALBERTA_WORKS_URL;
    if (dimgUrl.endsWith('/')) {
      dimgUrl = dimgUrl.slice(0, dimgUrl.length - 1);
    }

    return `${dimgUrl}/${pidSearchPath}${pid}`;
  }

  // old param based function... moved to dto based function below
  // TODO: remove after working
  // addJobToQueue(applicationId, documentDetails, target: string, applicationType: 'IES' | 'AISH', submittedDate: Date) {
  //   const dimgData = {
  //     applicationId: applicationId,
  //     applicationType: applicationType,
  //     target: target,
  //     collection: documentDetails,
  //     submittedDate: submittedDate,
  //   } as DIMGJobDto;

  //   return this.http.post(`${this.FILES_ENDPOINT}/dimg/process`, dimgData);

  //   // for using with local file server
  //   //return this.http.post(`http://localhost:3333/dimg/process`, dimgData);
  // }

  getFbProvidersList(): Observable<[{ [key: string]: string }]> {
    // return this.http.get<[{ [key: string]: string }]>(`${this.FILES_ENDPOINT}/dimg/fb-providers`);
    const providerList = this.getProviderList();
    return providerList;
  }

  async initializeProviderList() {
    const providerListObservable = this.http.get<[{ [key: string]: string }]>(
      `${this.FILES_ENDPOINT}/dimg/fb-providers`
    );
    const providerList = await lastValueFrom(providerListObservable)
      .then((providerList) => {
        const providerCount = providerList?.length ?? 0;
        console.log(`Initialized provider list from DIMG: ${providerCount} total.`);
        return providerList;
      })
      .catch(() => {
        console.log('Cannot pull DIMG list. Using static list');
        return null;
      });
    this.setProviderList(providerList);
  }

  /**
   * Returns the provider list
   */
  getProviderList() {
    return this.apollo
      .watchQuery<{ providers: [{ [key: string]: string }] }>({
        query: SERVICE_PROVIDER_QUERY,
        returnPartialData: true,
      })
      .valueChanges.pipe(map((result) => result.data?.providers))
      .pipe(shareReplay(1));
  }

  /**
   * Sets the provider list
   */
  setProviderList(providers?: [{ [key: string]: string }]) {
    this.apollo.client.writeQuery({
      query: SERVICE_PROVIDER_QUERY,
      data: { providers: providers },
    });
  }
}
