import { ConfirmDialogComponent } from '@common/ui/shared-components';
import { lastValueFrom } from 'rxjs';

export interface DialogLabels {
  confirmLabel: string;
  cancelLabel: string;
}

export async function existingApplicationDraft(matDialog, labels: DialogLabels) {
  const dialogRef = matDialog.open(ConfirmDialogComponent, {
    autoFocus: false,
    data: {
      title: 'Application already exists',
      message: `Application for this child already exists in your <b>Draft application(s)</b> tab. Would you like to continue the application?`,
      confirmLabel: labels.confirmLabel,
      cancelLabel: labels.cancelLabel,
    },
    panelClass: 'modal-container',
  });

  const isDialogConfirmed = await lastValueFrom(dialogRef.afterClosed());
  if (!isDialogConfirmed) {
    return false;
  }

  return true;
}

export async function existingApplicationSubmitted(matDialog, labels: DialogLabels) {
  const dialogRef = matDialog.open(ConfirmDialogComponent, {
    autoFocus: false,
    data: {
      title: 'Application already exists',
      message: `Application for this child already exists in your <b>Submitted application(s)</b> tab. Would you like to view the application?`,
      confirmLabel: labels.confirmLabel,
      cancelLabel: labels.cancelLabel,
    },
    panelClass: 'modal-container',
  });

  const isDialogConfirmed = await lastValueFrom(dialogRef.afterClosed());
  if (!isDialogConfirmed) {
    return false;
  }

  return true;
}

export async function existingApplicationRemoved(matDialog, labels: DialogLabels) {
  const dialogRef = matDialog.open(ConfirmDialogComponent, {
    autoFocus: false,
    data: {
      title: 'Application already exists',
      message: `Application for this child already exists in your <b>Recently removed application(s)</b> tab. Would you like to restore it?`,
      confirmLabel: labels.confirmLabel,
      cancelLabel: labels.cancelLabel,
    },
    panelClass: 'modal-container',
  });

  const isDialogConfirmed = await lastValueFrom(dialogRef.afterClosed());
  if (!isDialogConfirmed) {
    return false;
  }

  return true;
}
