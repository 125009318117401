import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[iesTrimPaste]',
})
export class TrimPasteDirective {
  constructor(private ngControl: NgControl) {}

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text/plain').trim();
    this.ngControl.control.setValue(pastedText);
    event.preventDefault();
  }
}
