import { IntakeLabelCollection } from '../../entities/intake-label.interface';
export const AishEducationLabels: IntakeLabelCollection = {
  educationLevel: {
    label: 'What is the highest level of education you have completed?',
  },
  collegeUniversityName: {
    label: 'College/university name',
  },
  programOfStudy: {
    label: 'Program of study',
  },
  lastYearAttended: {
    label: 'Last year attended',
  },
  degreeDiplomaObtained: {
    label: 'Degree/diploma obtained',
  },
  schoolProvider: {
    label: 'School/provider',
  },
  dateStarted: {
    label: 'Date started',
  },
  expectedCompletionDate: {
    label: 'Expected completion date',
  },
  goals: {
    label: 'What are your goals upon completing training?',
  },
  stepsToFindWork: {
    label: 'What steps have you taken to find work or training suitable for your medical condition(s)?',
  },
  currentlyAttendingEducationOrTraining: {
    label: 'Are you currently attending an education or training program?',
  },
  planAttendingEducationOrTraining: {
    label: 'Do you have plans to take further training or upgrading in the near future?',
  },
  trainingGoals: {
    label: 'What are your goals upon completing training?',
  },
};
