import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FbSponsorLabels: IntakeLabelCollection = {
  sponsorType: {
    label: "Who was the deceased's sponsor?",
  },
  firstName: {
    label: 'First name',
  },
  middleName: {
    label: 'Middle name',
  },
  lastName: {
    label: 'Last name',
  },
  groupName: {
    label: `Group's name`,
  },
  birthdate: {
    label: 'Birthdate',
  },
  phoneNumber: {
    label: 'Phone number',
  },
  email: {
    label: 'Email address',
  },
  mailingAddress: {
    label: 'Mailing address',
  },
  streetAddress: { label: 'Street address' },
  suiteNumber: { label: 'Suite or unit #' },
  city: { label: 'City/town' },
  province: { label: 'Province/territory' },
  postalCode: { label: 'Postal code' },
  whyApplying: {
    label:
      'You have indicated that there is an active sponsorship. Please explain why are you applying for these benefits while there is an active sponsorship?',
  },
};
