import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FbDeceasedLabels: IntakeLabelCollection = {
  firstName: {
    label: 'First name',
  },
  middleName: {
    label: 'Middle name',
  },
  lastName: {
    label: 'Last name',
  },
  sin: {
    label: 'Social Insurance Number',
  },
  birthdate: {
    label: 'Birthdate',
  },
  deathdate: {
    label: 'Date of death',
  },
  addressSameAsApplicant: {
    label: 'Was the residential address of the deceased the same as the applicant?',
  },
  placeOfDeathLocation: {
    label: 'Where did the deceased pass away?',
  },
  streetAddress: { label: 'Street address' },
  suiteNumber: { label: 'Suite or unit #' },
  city: { label: 'City/town' },
  province: { label: 'Province/territory' },
  postalCode: { label: 'Postal code' },
  placeOfDeath: { label: 'Place of death' },
  deathCountry: {
    label: 'Country',
  },
  deathProvince: {
    label: 'Province/territory',
  },
  deathState: {
    label: 'State',
  },
  deathCity: {
    label: 'City/town',
  },
  pid: {
    label: 'Personal Identification Number (PID)',
  },
  fileNumber: {
    label: 'File number',
  },
  hasSponsor: {
    label: 'Was the deceased a sponsored immigrant?',
    helperText:
      'Sponsored immigrants are people who are granted permanent resident status through their relationship with their sponsor: their spouse, partner, parent, grand-parent, child, or other relative.',
  },
  hasActiveSponsor: {
    label: 'Was the sponsorship still active at the time of death?',
  },
  minorOver18: {
    label: 'Invalid date of birth. You indicated on the Key questions page that the deceased was a minor',
  },
  notMinorUnder18: {
    label: 'Invalid date of birth. You indicated on the Key questions page that the deceased was not a minor',
  },
};
