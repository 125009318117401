import { HttpHandler, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
// import { Logger } from '@nestjs/common';
import { v4 as uuidv4 } from 'uuid';
import { AppConfigService } from '../services/config/config.service';
import { GoogleAnalyticsService } from '../services/google-analytics/google-analytics.service';

@Injectable()
export class ResponseErrorMessageIntercept implements HttpInterceptor {
  constructor(
    private toasterService: ToastrService,
    private config: AppConfigService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  intercept(request, next: HttpHandler) {
    const requestErrorMessage = request.body?.variables?.errorMessage;
    const requestErrorTitle = request.body?.variables?.errorTitle;
    const errorId = uuidv4(); //create an error id so we can cross reference the error if there is a screenshot

    return next.handle(request).pipe(
      switchMap((event) => {
        if (event instanceof HttpResponse) {
          //graphql returns 200 for partial responses (like unauthenticated), so handle it here
          if (event.body?.errors?.length > 0) {
            let isUnAuthorized = event.body.errors[0].message === 'Unauthorized';
            let errorMessage = `Error: ${event.body.errors[0].message}`;
            console.error(event.body.errors[0].extensions?.exception?.stacktrace);

            if (event.body.errors.length > 1) {
              event.body.errors.array.forEach((error) => {
                isUnAuthorized = isUnAuthorized || error.message === 'Unauthorized';
                errorMessage += `\n${error.message}`;
                console.error(error.extensions?.exception?.stacktrace);
              });
            }

            if (!isUnAuthorized) {
              const toasterMessage = errorMessage || requestErrorMessage;
              const toasterTitle = requestErrorTitle || '';
              this.displayErrorMessage(toasterMessage, toasterTitle, errorId);
              return EMPTY;
            }
          }
        }

        //default case - pass the response back through unmodified
        return of(event);
      })
    );
  }

  displayErrorMessage(errorMessage, errorTitle, errorId) {
    const toasterMessage = `${errorMessage}. ErrorID: ${errorId}`;
    const logErrorMessage = `ErrorID: ${errorId}. ${errorMessage}`;
    const logErrorMessageShort = logErrorMessage.substring(0, 8000);
    try {
      if (errorTitle !== '') {
        this.toasterService.error(toasterMessage, errorTitle);
      } else {
        this.toasterService.error(toasterMessage);
      }
      //TODO: Need to find a way to log this error message client side.
      //For now let's log to google and truncate the payload size. Max payload size 8192 bytes
      // Logger.log(errorMessage);
      console.log(logErrorMessage);
      if (this.config.getConfig().GOOGLE_MEASUREMENT_ID) {
        this.googleAnalyticsService.trackErrorMessage(logErrorMessageShort);
      }
    } catch (e) {
      // console.log('An error occurred', '');
      console.log(logErrorMessage);
    }
  }
}
