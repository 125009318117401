import { IntakeLabelCollection } from '../../entities/intake-label.interface';
export const AishPersonalInfoLabels: IntakeLabelCollection = {
  firstName: {
    label: 'First name',
  },
  preferredName: {
    label: 'Preferred name, if different',
  },
  middleName: {
    label: 'Middle name',
  },
  lastName: {
    label: 'Last name',
  },
  birthCertificateLastName: {
    label: 'Last name on birth certificate if different',
  },
  birthdate: {
    label: 'Birthdate',
  },
  gender: {
    label: 'Gender',
  },
  email: {
    label: 'Email address',
  },
  confirmEmail: {
    label: 'Confirm email address',
  },
  socialInsuranceNumber: {
    label: 'Social Insurance Number',
  },
  albertaHealthNumber: {
    label: 'Alberta Health Number',
  },
  immigrationStatus: {
    label: 'Citizenship / Immigration status',
  },
  sponsorshipStartDate: {
    label: 'Sponsorship Start Date',
  },
  sponsorshipEndDate: {
    label: 'Sponsorship End Date',
  },
  immigrationStatusOther: {
    label: 'Explain your immigration status',
  },
  livingSituation: {
    label: 'Living situation',
  },
  hasDifferentMailingAddress: {
    label: 'Is your mailing address different than your home address?',
    alternateLabel: 'Do you have a mailing address?',
  },
};
