import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FiDashboardLabels: IntakeLabelCollection = {
  submittedAppsNote: {
    label:
      'Note: In order to safeguard your privacy and the security of your information, all data from submitted applications will be deleted after a period of 4 years after submitted date.',
  },
  draftAppsNote: {
    label:
      'Note: Your draft applications will be moved to the Recently removed application(s) tab after 12 months of inactivity.',
  },
  removedAppsNote: {
    label: 'Note: Applications in this tab will be permanently deleted 40 days after they have been removed.',
  },
};
