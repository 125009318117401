import { Component, Input } from '@angular/core';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';
import { FscdIntakeApplication } from '@fscd-intake/entities';
import { Observable } from 'rxjs';

@Component({
  selector: 'fi-compile-documents',
  templateUrl: './compile-documents.component.html',
  styleUrls: ['./compile-documents.component.scss'],
})
export class CompileDocumentsComponent {
  @Input()
  private application$: Observable<FscdIntakeApplication>;

  constructor(private authService: AuthenticationService) {}
  async consoleLogDocuments() {
    this.application$
      .subscribe((app) => {
        console.log(app);
      })
      .unsubscribe();
  }
}
