import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FbIncomeLabels: IntakeLabelCollection = {
  //common
  bankAddress: { label: 'Bank address' },
  institutionName: { label: 'Institution name' },
  address: { label: 'Address' },
  city: { label: 'City/town' },
  province: { label: 'Province/territory' },
  postalCode: { label: 'Postal code' },
  faxNumber: { label: 'Fax number' },

  // incomeAmount: {
  //   label: 'Net income per month',
  // },
  // incomePaymentDate: {
  //   label: 'Date of final payment',
  // },
  incomeAmount: {
    label: 'Amount',
  },
  incomePaymentDate: {
    label: 'Payment Date',
  },
  assetAmount: {
    label: 'Amount',
  },
};
