import { Lookup } from '../lookup.interface';

export interface ContactReasonLookup extends Lookup {
  tip: string;
  tipDetail: string;
}
export const ContactReasons: { [name: string]: ContactReasonLookup } = {
  careerServices: {
    displayValue: 'Career Services',
    code: 'careerServices',
    tip: 'Client needs help to make career decisions.',
    tipDetail:
      'Assist an individual to clarify occupational and career goals and make career decisions through services such as career advising, career counseling, and career planning workshops',
  },
  skillDevelopment: {
    displayValue: 'Skill Development',
    code: 'skillDevelopment',
    tip: 'Client need services to access training. Includes specialized assessments or DRES for education.',
    tipDetail:
      'Support an individual in full or part-time training opportunities that enable them to find work and substantially improve their employment situation or allow them to adapt to changing labour conditions and skill requirements.',
  },
  employmentServices: {
    displayValue: 'Employment Services',
    code: 'employmentServices',
    tip: 'Client needs services to find and maintain employment. Includes Exposure Courses and specialized assessments or DRES for employment.',
    tipDetail:
      'Assist an individual to prepare for, find, and maintain employment. Services include work search workshops, job placement, and exposure courses',
  },
  informationServices: {
    displayValue: 'Information Services',
    code: 'informationServices',
    tip: 'Referral of client to access information independently. i.e. ALIS',
    tipDetail:
      'May include Labour Market Information (LMI), occupational, training and educational information, and resources available in the community. Information Services may be provided as part of a needs identification and assessment process, through Alberta Supports Centres, ALIS, or delivery events',
  },
  supportFinancial: {
    displayValue: 'Support - Financial',
    code: 'supportFinancial',
    tip: 'Client needs help to meet basic needs (i.e. IS or AISH). Does not include exposure courses or specialized assessments.',
    tipDetail:
      'Services related to accessing financial resources and increasing financial independence May include Ministry financial assistance/benefits as well as other re-sources such as AISH, WCB, CPP, etc.',
  },
  supportNonFinancial: {
    displayValue: 'Support - Non-Financial',
    code: 'supportNonFinancial',
    tip: 'Client needs help related to personal, medical and/or family needs.',
    tipDetail:
      'Referral services related to personal and/or family support in order to enable individuals to improve their circumstances and achieve their goals. (Examples include: mental health services, parenting supports, housing assistance, addictions support)',
  },
};
