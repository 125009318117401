import { Injectable } from '@angular/core';
import { onError } from '@apollo/client/link/error';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class UnauthenticatedErrorLink {
  constructor(private keycloak: KeycloakService) {}

  errorLink = onError(({graphQLErrors}) => {
    if (graphQLErrors?.find((e) => e?.extensions?.code === 'UNAUTHENTICATED')) {
      this.keycloak.login();
    }
  });
}
