import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { ContextProvider } from '../../services/context-provider/context.provider';
import { ContextService } from '../../../ui-shared-components.services';

@Injectable({
  providedIn: 'root',
})

//All this does is load the contextProfile so that we can still show logged in user information on public pages
//my-account menu component shows up on all pages so we need contextProfile
//cypress tests are also pull user information in the my-account bar for public pages too
export class AnonymousGuard extends KeycloakAuthGuard {
  constructor(
    protected router: Router,
    protected readonly contextService: ContextService,
    private contextProvider: ContextProvider,
    protected readonly keycloakService: KeycloakService
  ) {
    super(router, keycloakService);
  }

  public async isAccessAllowed(): Promise<boolean> {
    if (this.authenticated) {
      const user = await this.contextService.getContextUserProfile();
      this.contextProvider.setCurrentUser(user);
    }

    return true;
  }
}
