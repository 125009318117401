import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tittleCase',
})
export class TittleCasePipe implements PipeTransform {
  transform(value: string): unknown {
    if (!value) {
      return null;
    }

    const words = value.split(' ');
    for (let i = 0; i < words.length; i++) {
      words[i] = this.toTitleCase(words[i]);
    }
    return words.join(' ');
  }

  private toTitleCase(word: string): string {
    return word.substr(0, 1).toUpperCase() + word.substring(1).toLowerCase();
  }
}
