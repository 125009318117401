import { IntakeLabelCollection } from '../../entities/intake-label.interface';
export const FiParentInfoLabels: IntakeLabelCollection = {
  header: { label: 'Parent/Guardian information' },
  firstName: { label: 'Parent/Guardian First name' },
  middleName: { label: 'Parent/Guardian Middle name' },
  lastName: { label: 'Parent/Guardian Last name' },
  preferredName: { label: 'Preferred name, if different' },
  birthdate: { label: 'Date of birth' },
  gender: { label: 'Gender' },
  isEnglishFirstLanguage: { label: 'Is English your first language?' },
  primaryLanguage: { label: 'What is your primary language?' },
  requiresInterpreter: { label: 'Do you require an interpreter?' },
  haveOtherCommunicationNeed: {
    label: 'Do you have any other communication needs?',
    helperText:
      'Communication needs could include hearing impairments, visual impairments or literacy issues for the parent/guardian.',
  },
  otherCommunicationNeed: { label: 'Please specify any communication needs you have' },
  phoneNumber: { label: 'Phone number' },
  email: { label: 'Email address' },
  hasSameAddressAsChild: { label: 'Is your residential address the same as the child?' },
  street: { label: 'Street address' },
  suite: { label: 'Suite or unit #' },
  city: { label: 'City/town' },
  province: { label: 'Province/territory' },
  postalCode: { label: 'Postal code' },
  relationToChild: { label: 'Relation to child' },
  learnAboutFscd: { label: 'How did you learn about the FSCD program?' },

  secondParentheader: { label: 'Second Parent/Guardian information' },
  primaryContactInfoText: {
    label:
      'Begin by entering details for the primary contact parent/guardian, who will serve as the main point of contact for the applicant. You can add information for the second parent/guardian later on this page.',
  },
  autoFillingInfoText: {
    label:
      'The Parent/Guardian information entered here will be saved for the use of auto-filling additional applications within this account.',
  },
};
