import { Component, Input } from '@angular/core';
import type { DIMGJob } from '@common/entities';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';
import { FbApplication } from '@fb-intake/entities';
import { Observable } from 'rxjs';
// import { DocumentIterator } from '../../../pages/review-page/document-iterator';

@Component({
  selector: 'fi-preview-dimg-request',
  templateUrl: './preview-dimg-request.component.html',
  styleUrls: ['./preview-dimg-request.component.scss'],
})
export class PreviewDimgRequestComponent {
  @Input()
  private application$: Observable<FbApplication>;

  constructor(private authService: AuthenticationService) {}
  async logDimgRequest() {
    this.application$
      .subscribe((app) => {
        // console.info(
        //   'This will never include the actual application document, just the files uploaded by the end user.'
        // );

        // simulate adding applicatoin sumamry
        app.outputPdfUrl = 'http://someurl.com/somedoc.pdf';
        app.outputPdfFileName = 'simulated-application-doc-which-will-never-be-browsable.pdf';

        // const documentDetails = new DocumentIterator(app).iterateNames();
        // const documentCount = documentDetails.length;
        // app.documentCount = documentCount;

        // const isHighPriority = app.applicant?.myStory?.hasUrgentFinancialNeed;
        const isHighPriority = false;

        const jobData = {
          applicationId: app.id,
          applicationType: 'FSCD',
          submittedDate: new Date(),
          target: 'fscd-imaging/fscd-intake',
          // collection: documentDetails,
          highPriority: isHighPriority,
        } as DIMGJob;

        console.log(jobData);
      })
      .unsubscribe();
  }
}
