import { Expose } from 'class-transformer';
import { IsDefined, IsNotEmpty, ValidateIf } from 'class-validator';
import { ModifiedYesNoOptionsUnsureYes } from '../constants/modified-yes-no';

function isServicesRequired(services: FiServices): boolean {
  return (
    ModifiedYesNoOptionsUnsureYes[services.homeRespite]?.evaluatesToTrue === true ||
    ModifiedYesNoOptionsUnsureYes[services.medicalAppointment]?.evaluatesToTrue === true ||
    ModifiedYesNoOptionsUnsureYes[services.counselling]?.evaluatesToTrue === true ||
    ModifiedYesNoOptionsUnsureYes[services.clothingFootwear]?.evaluatesToTrue === true
  );
}

export class FiServices {
  @Expose()
  id?: string;

  @Expose()
  @IsDefined()
  @IsNotEmpty()
  homeRespite: string;

  @Expose()
  @IsDefined()
  @IsNotEmpty()
  medicalAppointment: string;

  @Expose()
  @IsDefined()
  @IsNotEmpty()
  counselling: string;

  @Expose()
  @IsDefined()
  @IsNotEmpty()
  clothingFootwear: string;

  @Expose()
  @ValidateIf((o) => isServicesRequired(o))
  @IsDefined()
  @IsNotEmpty()
  agreementDuration: string;

  
}
