import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[iesAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  @Input() iesAutofocus = true;

  constructor(private element: ElementRef<HTMLInputElement>) {}

  ngAfterViewInit(): void {
    if (this.iesAutofocus) {
      this.element.nativeElement.focus();
    }
  }
}
