import { Component, Input } from '@angular/core';
import { FscdIntakeApplication } from '@fscd-intake/entities';
import { Observable } from 'rxjs';

@Component({
  selector: 'fi-applicaton-viewer',
  templateUrl: './applicaton-viewer.component.html',
  styleUrls: ['./applicaton-viewer.component.scss'],
})
export class ApplicatonViewerComponent {
  @Input()
  application$: Observable<FscdIntakeApplication>;
}
