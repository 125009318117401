export function addNewItemToCache(
  cache,
  cacheName: string,
  query,
  variables,
  newItem,
  propertyName: string = null,
  parentPropertyName: string = null,
  parentPropertyId: string = null
) {
  if (newItem?._id === 'fake') return;
  const queryData = variables ? cache.readQuery({ query, variables }) : cache.readQuery({ query });
  if (!queryData) return;
  let cacheValue;
  if (propertyName) {
    cacheValue = { ...queryData[cacheName] };
    if (parentPropertyName) {
      if (parentPropertyId) {
        cacheValue[parentPropertyName] = [...cacheValue[parentPropertyName]];
        const index = cacheValue[parentPropertyName].findIndex((x) => x._id === parentPropertyId);
        if (index < 0) return;
        const value = { ...cacheValue[parentPropertyName][index] };
        if (value[propertyName]?.some((item) => item._id === newItem._id)) return;
        value[propertyName] = (value[propertyName] && [...value[propertyName]]) || [];
        value[propertyName].push(newItem);
        cacheValue[parentPropertyName].splice(index, 1, value);
      } else {
        if (cacheValue[parentPropertyName][propertyName]?.some((item) => item._id === newItem._id)) return;
        cacheValue[parentPropertyName] = { ...cacheValue[parentPropertyName] };
        cacheValue[parentPropertyName][propertyName] = cacheValue[parentPropertyName][propertyName]
          ? [...cacheValue[parentPropertyName][propertyName], newItem]
          : [newItem];
      }
    } else {
      if (cacheValue[propertyName]?.some((item) => item._id === newItem._id)) return;
      cacheValue[propertyName] = cacheValue[propertyName] ? [...cacheValue[propertyName], newItem] : [newItem];
    }
  } else {
    cacheValue = queryData[cacheName];
    if (cacheValue?.some((item) => item._id === newItem._id)) return;
    cacheValue = cacheValue ? [...cacheValue, newItem] : [newItem];
  }
  const data = {};
  data[cacheName] = cacheValue;
  variables ? cache.writeQuery({ query, variables, data }) : cache.writeQuery({ query, data });
}

export function removeItemFromCache(
  cache,
  cacheName: string,
  query,
  variables,
  id: string,
  propertyName: string = null,
  parentPropertyName: string = null,
  parentPropertyId: string = null
) {
  const queryData = (variables && cache.readQuery({ query, variables })) || cache.readQuery({ query });
  if (!queryData) return;
  let cacheValue;
  if (propertyName) {
    cacheValue = { ...queryData[cacheName], updatedAt: new Date() };
    if (parentPropertyName) {
      if (cacheValue[parentPropertyName])
        if (parentPropertyId) {
          cacheValue[parentPropertyName] = [...cacheValue[parentPropertyName]];
          const index = cacheValue[parentPropertyName].findIndex((x) => x._id === parentPropertyId);
          if (index < 0) return;
          const value = { ...cacheValue[parentPropertyName][index], updatedAt: new Date() };
          value[propertyName] = (value[propertyName] && [...value[propertyName]]) || [];
          value[propertyName] = value[propertyName].filter((item) => item._id !== id);
          cacheValue[parentPropertyName].splice(index, 1, value);
        } else {
          cacheValue[parentPropertyName] = { ...cacheValue[parentPropertyName], updatedAt: new Date() };
          cacheValue[parentPropertyName][propertyName] = cacheValue[parentPropertyName][propertyName].filter(
            (item) => item._id !== id
          );
        }
    } else cacheValue[propertyName] = cacheValue[propertyName].filter((item) => item._id !== id);
  } else cacheValue = queryData[cacheName].filter((item) => item._id !== id);

  const data = {};
  data[cacheName] = cacheValue;
  (variables && cache.writeQuery({ query, variables, data })) || cache.writeQuery({ query, data });
}
