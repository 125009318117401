import { Expose } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';
import { PostalCodeFormat } from '../custom-validation-functions/postal-code-format';
import { Address } from './address.entity';

export class AlbertaAddress extends Address {
  @Expose()
  @IsNotEmpty()
  @PostalCodeFormat('AB')
  postalCode: string;
}
