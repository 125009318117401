// import { each } from 'lodash';
/* eslint @typescript-eslint/naming-convention: "off" */
// import { AppConfigService } from '@common/ui/shared-components';
import { AppConfigService } from '../config/config.service';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// import { Utilities } from '@common/utils';

declare const gtag;
// declare const ga: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private router: Router, private configService: AppConfigService) {}

  public event(eventName: string, params) {
    if (gtag) gtag('event', eventName, params);
  }

  trackException(error: Error) {
    gtag('event', 'exception', {
      description: `Fatal - ${error.stack}`,
      fatal: false,
    });
  }

  trackErrorMessage(errorMessage: string) {
    gtag('event', 'exception', {
      description: `${errorMessage}`,
      fatal: false,
    });
  }

  public async init() {
    const measurementId = this.configService.getConfig().GOOGLE_MEASUREMENT_ID;
    if (measurementId) {
      this.listenForRouteChanges();
      try {
        const script1 = document.createElement('script');
        script1.async = true;
        script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + measurementId;
        document.head.appendChild(script1);
        const script2 = document.createElement('script');
        script2.innerHTML = this.getAnalyticsCode(measurementId);
        document.head.appendChild(script2);
      } catch (ex) {
        console.error('Error appending google analytics ' + ex);
      }
    }
  }

  private listenForRouteChanges() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const pageName = event.urlAfterRedirects.split('/').pop();
        gtag('config', this.configService.getConfig().GOOGLE_MEASUREMENT_ID, {
          page_path: event.urlAfterRedirects,
          page_name: pageName,
        });

        // Feature detects Navigation Timing API support.
        if (window.performance) {
          // Gets the number of milliseconds since page load
          // (and rounds the result since the value must be an integer).
          const timeSincePageLoad = Math.round(performance.now());
          // Sends the timing event to Google Analytics.
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).gtag('event', 'timing_complete', {
            name: 'load',
            value: timeSincePageLoad,
            event_category: pageName,
          });
        }
      }
    });
  }

  private getAnalyticsCode(measurementId: string) {
    //LOCAL: 'G-7HPSZDZXXX' //DEV: 'G-7HPSZDZXNP' //TEST: 'G-56SWTSXJ6N' //PROD: 'G-ZSFEBRJLHP'
    //Important: do not change this code, it's been white listed in our nginx.config file for dev, test, and prod keys
    //use this tool to hash (sha-256 base 64): https://report-uri.com/home/hash | https://zinoui.com/tools/csp-hash
    //If changes need to be made to this inline script block, you will need to update the
    //sha256 hash in apps\aish-intake\aish-ui\src\nginx.conf in order to whitelist this inline script in the Content-Security-Policy
    const code = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js',new Date());
    gtag('config','${measurementId}',{'send_page_view':false});`;
    return code;
  }
}
