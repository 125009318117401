export class EmergencyReason {
  static readonly provincialEmergency = { displayValue: 'Provincial emergency', code: 'provincialEmergency' };
  static readonly eviction = { displayValue: 'Eviction', code: 'eviction' };
  static readonly fleeingAbuse = { displayValue: 'Fleeing abuse', code: 'fleeingAbuse' };
  static readonly utilityDisconnection = { displayValue: 'Utility disconnection', code: 'utilityDisconnection' };
  static readonly medical = { displayValue: 'Medical', code: 'medical' };
  static readonly dental = { displayValue: 'Dental', code: 'dental' };
  static readonly other = { displayValue: 'Other', code: 'other' };
}

export const EmergencyReasons = {};
for (const [key, value] of Object.entries(EmergencyReason)) {
  EmergencyReasons[key] = value;
}
