import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'nullNot0',
})
export class NullNot0Pipe implements PipeTransform {
  constructor(private numberPipe: DecimalPipe) {}

  transform(n: number | null, digitsInfo?: string, locale?: string): string {
    return n === null ? '' : this.numberPipe.transform(n, digitsInfo, locale);
  }
}
