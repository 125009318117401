import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const IesMySituationLabels: IntakeLabelCollection = {
  isCompletedOnBehalf: {
    label: 'Is someone helping you complete the application?',
  },
  movedToAlberta: {
    label: 'Did you move to Alberta within the last year?',
  },
  onBehalfName: {
    label: 'Name/organization',
  },
  onBehalfPhoneNumber: {
    label: 'Phone number',
  },
  onBehalfEmail: {
    label: 'Email',
  },
  isMovedToAlberta: {
    label: 'Did you move to Alberta within the last year?',
  },
  movedFromLocation: {
    label: 'Where did you move from?',
  },
  movedToAlbertaDate: {
    label: 'Arrival Date In Alberta',
  },
  municipality: {
    label: 'What is the name of the city/town where you currently live?',
  },
  hasFinancialAdmin: {
    label: 'Do you have a financial administrator?',
  },
  isEvictionDue: {
    label: 'Do you have an eviction notice that requires you to move out within the next 5 days?',
  },
  isUtilityCutOffDue: {
    label: 'Are your utilities being cut-off within the next 5 days? e.g. power, gas, water.',
  },
  hasMedicalNeed: {
    label: 'Do you have an immediate medical need that requires financial support?',
  },
  hasDentalNeed: {
    label: 'Do you require financial support for an immediate dental problem?',
  },
  leavingAbusiveSituation: {
    label: 'Are you leaving an abusive situation?',
  },
  maritalStatus: {
    label: 'What is your marital status?',
  },
  hasDependentChild: {
    label: 'Do you have dependent child(ren)?',
  },
};
