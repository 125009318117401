<div class="list-view">
  <div class="overdue event-bucket" data-cy="overdue-count">
    <h3>
      Overdue
      <span *ngIf="allOverdueEvents?.length" class="pill">{{ overdueCount ?? allOverdueEvents.length }}</span>
    </h3>

    <ng-container *ngIf="allOverdueEvents?.length; else noOverdue">
      <ng-container *ngIf="overdueEventsOther && overdueEventsOther.length > 0">
        <h4>Over 30 days overdue</h4>
        <ng-container *ngFor="let event of overdueEventsOther; let index = index">
          <div class="event-container">
            <ng-container *ngTemplateOutlet="eventTemplate; context: { event, index }"> </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="overdueEvents14to30?.length">
        <h4>15 to 30 days overdue</h4>
        <ng-container *ngFor="let event of overdueEvents14to30; let index = index">
          <div class="event-container">
            <ng-container *ngTemplateOutlet="eventTemplate; context: { event, index }"> </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="overdueEvents7to14?.length">
        <h4>8 to 14 days overdue</h4>
        <ng-container *ngFor="let event of overdueEvents7to14; let index = index">
          <div class="event-container">
            <ng-container *ngTemplateOutlet="eventTemplate; context: { event, index }"> </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="overdueEvents0to7?.length">
        <h4>0 to 7 days overdue</h4>
        <ng-container *ngFor="let event of overdueEvents0to7; let index = index">
          <div class="event-container">
            <ng-container *ngTemplateOutlet="eventTemplate; context: { event, index }"> </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #noOverdue>
      <ng-container *ngIf="!isLoading">
        <div class="event-container">{{ hasMore ? 'No events yet loaded.' : 'No events overdue.' }}</div>
      </ng-container>
      <mat-progress-spinner
        *ngIf="isLoading && !allOverdueEvents?.length"
        class="spinner"
        [mode]="'indeterminate'"
        [diameter]="50"
      >
      </mat-progress-spinner>
    </ng-template>
  </div>
  <div class="upcoming event-bucket">
    <h3>
      Upcoming
      <span *ngIf="allUpcomingEvents?.length" class="pill">{{ upcomingCount ?? allUpcomingEvents.length }}</span>
    </h3>
    <ng-container *ngIf="allUpcomingEvents?.length; else noUpcoming">
      <ng-container *ngIf="upcomingEventsToday?.length">
        <h4>Due today</h4>
        <ng-container *ngFor="let event of upcomingEventsToday; let index = index">
          <div class="event-container">
            <ng-container *ngTemplateOutlet="eventTemplate; context: { event, index }"> </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="upcomingEventsOther?.length">
        <h4>Due after today</h4>
        <ng-container *ngFor="let event of upcomingEventsOther; let index = index">
          <div class="event-container">
            <ng-container *ngTemplateOutlet="eventTemplate; context: { event, index }"> </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #noUpcoming>
      <ng-container *ngIf="!isLoading">
        <div class="event-container">{{ hasMore ? 'No events yet loaded.' : 'No events upcoming.' }}</div>
      </ng-container>
      <mat-progress-spinner
        *ngIf="isLoading && !allUpcomingEvents?.length"
        class="spinner"
        [mode]="'indeterminate'"
        [diameter]="50"
      >
      </mat-progress-spinner>
    </ng-template>
  </div>
</div>
