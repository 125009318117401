import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { filter, map } from 'rxjs/operators';
import { CanadaPostAddress, CanadaPostAddressDetails } from '@common/entities';
import { firstValueFrom } from 'rxjs';
import { FIND_ADDRESS_DETAILS_QUERY, FIND_ADDRESS_QUERY } from './address.gql';

@Injectable({
  providedIn: 'root',
})
export class AddressValidationService {
  constructor(public apollo: Apollo) {}

  findAddress(searchTerm: string, enableProvince = false) {
    return firstValueFrom(
      this.apollo
        .query<{ findAddress: CanadaPostAddress[] }>({
          query: FIND_ADDRESS_QUERY,
          variables: { searchTerm, enableProvince },
          fetchPolicy: 'no-cache',
        })
        .pipe(
          filter(({ data, loading }) => data && !loading),
          map(({ data }) => data.findAddress)
        )
    );
  }

  findAddressDetails(addressId: string) {
    return firstValueFrom(
      this.apollo
        .query<{ findAddressDetails: CanadaPostAddressDetails[] }>({
          query: FIND_ADDRESS_DETAILS_QUERY,
          variables: { addressId },
          fetchPolicy: 'no-cache',
        })
        .pipe(
          filter(({ data, loading }) => data && !loading),
          map(({ data }) => data.findAddressDetails)
        )
    );
  }
}
