import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class QuillValidator {
  static required(message = null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      return !value || !value?.plainTextContent || value.plainTextContent.trim() === ''
        ? { error: message ?? 'Required' }
        : null;
    };
  }
}
