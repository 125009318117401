<div class="single-select-container">
  <mat-button-toggle-group #buttonGroup [formControl]="control" [ngClass]="{ focused: isFocused }">
    <mat-button-toggle
      *ngFor="let option of options"
      [value]="option.value"
      (change)="onChanged($event)"
      class="button-toggle"
      [disabled]="disabled || option.disabled"
      [ngClass]="'single-select-option-' + option.value"
    >
      {{ option.displayValue }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
