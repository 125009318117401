import { Lookup } from './lookup.interface';

const online = { displayValue: 'Online', code: 'Online' };
const phone = { displayValue: 'Phone', code: 'Phone' };
const inperson = { displayValue: 'In Person', code: 'In Person' };

export const ApplicationModes: { [name: string]: Lookup } = {
  Online: online,
  Phone: phone,
  InPerson: inperson,
};
