import * as Quill from 'quill';
const QuillType: any = Quill;
const Delta = QuillType.import('delta');

class WebHeaderMatcher {
  static match(node: HTMLElement, delta: any) {
    const paraStyle = WebHeaderMatcher.getElementParaStyle(node);
    const attributes = WebHeaderMatcher.getAttributesForParaStyle(paraStyle);

    if (paraStyle) {
      return delta.compose(new Delta().retain(delta.length()).insert('\n', attributes));
    }

    return delta;
  }

  private static getElementParaStyle(node: HTMLElement): string | null {
    if (node instanceof HTMLElement) {
      return node.getAttribute('data-ccp-parastyle');
    }

    return null;
  }

  private static getAttributesForParaStyle(paraStyle: string | null): any {
    switch (paraStyle) {
      case 'heading 1':
        return { header: 1 };
      case 'heading 2':
        return { header: 2 };
      case 'heading 3':
        return { header: 3 };
      case 'heading 4':
        return { header: 4 };
      default:
        return undefined;
    }
  }
}

/** Convert paste from MS Word web edit to capture headers. */
export const MSWordWebHeaderMatcher = (node, delta) =>
  WebHeaderMatcher.match(node, delta) as Quill.ClipboardMatcherCallback;
