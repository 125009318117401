import { Lookup } from './lookup.interface';

export interface LivingSituationLookup extends Lookup {
  hasHomeAddress?: boolean;
  controlName?: string;
  displayOrder?: number;
}

export const LivingSituations: { [name: string]: LivingSituationLookup } = {
  rent: { displayValue: 'Rent', code: 'rent', hasHomeAddress: true, displayOrder: 0 },
  own: { displayValue: 'Own', code: 'own', hasHomeAddress: true, displayOrder: 1 },
  shelter: { displayValue: 'Shelter', code: 'shelter', hasHomeAddress: true, displayOrder: 6 },
  longTermCare: {
    displayValue: 'Long-term care facility',
    code: 'longTermCare',
    hasHomeAddress: true,
    controlName: 'longTermCareFacilityName',
    displayOrder: 7,
  },
  institution: {
    displayValue: 'Institution',
    code: 'institution',
    hasHomeAddress: true,
    controlName: 'institutionName',
    displayOrder: 8,
  },
  groupHome: { displayValue: 'Group home', code: 'groupHome', hasHomeAddress: true, displayOrder: 5 },
  livingWithRelatives: {
    displayValue: 'Living with relatives',
    code: 'livingWithRelatives',
    hasHomeAddress: true,
    displayOrder: 4,
  },
  noFixedAddress: { displayValue: 'No fixed address', code: 'noFixedAddress', displayOrder: 9 },
  publicSocialHousing: {
    displayValue: 'Public/social housing',
    code: 'publicSocialHousing',
    hasHomeAddress: true,
    displayOrder: 3,
  },
  other: { displayValue: 'Other', code: 'other', displayOrder: 10 },
  roomAndBoard: { displayValue: 'Room and Board', code: 'roomAndBoard', hasHomeAddress: true, displayOrder: 2 },
};
