import { Component, Input } from '@angular/core';
import { PdfGenerationService } from '../../../services/pdf-generation.service';
import * as pdfMake from 'pdfmake/build/pdfmake.min';
import { lastValueFrom, map, Observable } from 'rxjs';
import { FscdIntakeApplication } from '@fscd-intake/entities';
import { DocumentIterator } from '../../../pages/review-page/document-iterator';
import { Apollo } from 'apollo-angular';
import { ASSIGN_APPLICATION_NUMBER_MUTATION, SAVE_APPLICATION_MUTATION } from '../../../pages/page-gql';

@Component({
  selector: 'fi-generate-pdf-button',
  templateUrl: './generate-pdf-button.component.html',
  styleUrls: ['./generate-pdf-button.component.scss'],
})
export class GeneratePdfButtonComponent {
  @Input()
  private application$: Observable<FscdIntakeApplication>;

  constructor(private pdfService: PdfGenerationService, public apollo: Apollo) {}

  async generatePDF() {
    this.application$
      .subscribe((data) => {
        const pdf = this.pdfService.generatePdf(data);
        pdfMake.createPdf(pdf).open({}, window.open('', '_blank'));
      })
      .unsubscribe();
  }

  async submitApplication() {
    this.application$
      .subscribe(async (data) => {
        const applicationWithAppNumber = await lastValueFrom(this.assignApplicationNumber(data));
        data.applicationNumber = applicationWithAppNumber.applicationNumber;
        const uploadedPdf = await this.pdfService.uploadPdf(data);
        data.outputPdfUrl = uploadedPdf.azureUrl;
        data.outputPdfFileName = uploadedPdf.key;

        const documentDetails = new DocumentIterator(data).iterateNames();
        const documentCount = documentDetails.length;
        data.documentCount = documentCount;
        data.isSubmitted = true;
        data.applicationErrors = null;

        const submitResponse = await lastValueFrom(this.submit(data));
        if (submitResponse.isSubmitted) {
          console.log(`Application is submitted successfully`);
          return true;
        }
      })
      .unsubscribe();
  }

  private submit(application: FscdIntakeApplication) {
    return this.apollo
      .mutate({
        mutation: SAVE_APPLICATION_MUTATION,
        variables: {
          applicationInput: application,
        },
      })
      .pipe<FscdIntakeApplication>(
        map(({ data }) => {
          return data;
        })
      );
  }

  private assignApplicationNumber(application: FscdIntakeApplication) {
    return this.apollo
      .mutate({
        mutation: ASSIGN_APPLICATION_NUMBER_MUTATION,
        variables: {
          id: application.id,
        },
      })
      .pipe<FscdIntakeApplication>(
        map(({ data }) => {
          return data;
        })
      );
  }
}
