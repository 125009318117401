import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe for ahn.  Returns XXXXX-XXXX
 */
@Pipe({ name: 'phoneNumber' })
export class PhoneNumberPipe implements PipeTransform {
  transform(number: string): string {
    if (!number || number.length !== 10) {
      return number;
    }

    return `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6, 10)}`;
  }
}
