import { AbstractControl, ValidatorFn } from '@angular/forms';

export function ValidSelectOption(validValues?: string[]): ValidatorFn {
  return (control: AbstractControl) => {
    if (control.value && !validValues.some((o) => o === control.value)) {
      return { invalidSelectOption: true };
    }

    return null;
  };
}
