import 'reflect-metadata';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { GraphQLModule } from './graphql/graphql.module';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { FscdIntakeModule } from '@fscd-intake/ui/application';
import { MaterialModule } from '@common/ui/material';
import { AppConfigService, DateInterceptor, LogInterceptor } from '@common/ui/shared-components';
import { ToastNoAnimation, ToastrModule } from 'ngx-toastr';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { KeycloakInitializer } from '@common/utils';
import { GlobalErrorHandler } from './global-error.handler';
import '@abgov/web-components';

export function initConfig(keycloakService: KeycloakService, appConfig: AppConfigService, ngZone: NgZone) {
  return async () => {
    await appConfig.loadConfig();
    //checkLoginIframe will use keycloak adapter to pull bearer token from iframe.
    //If you set this to false it will store the token into local storage
    const keycloakIframeDisabled = appConfig.getConfig().KEYCLOAK_DISABLE_IFRAME ?? false;
    const options = { onLoad: 'check-sso', checkLoginIframe: !keycloakIframeDisabled, pkceMethod: 'S256' };

    const keycloakHelper = new KeycloakInitializer(
      keycloakService,
      appConfig.getConfig().KEYCLOAK_URL,
      appConfig.getConfig().KEYCLOAK_REALM,
      appConfig.getConfig().KEYCLOAK_CLIENTID,
      null,
      options
    );
    return ngZone.runOutsideAngular(() => {
      return keycloakHelper.initialize();
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    GraphQLModule,
    RouterModule.forRoot(ROUTES, {
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
    ToastrModule.forRoot({
      toastComponent: ToastNoAnimation,
      disableTimeOut: false,
      timeOut: 8000,
      maxOpened: 1,
      closeButton: true,
      positionClass: 'toast-bottom-left',
    }),
    KeycloakAngularModule,
    FscdIntakeModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [KeycloakService, AppConfigService, NgZone],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
