import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FbApplicantInformationLabels: IntakeLabelCollection = {
  highestPriority: {
    label: 'Are you the person with the highest priority who is making these arrangements?',
  },
  highestPriorityExplain: {
    label: 'Please explain why you are applying, instead of the person with higher priority?',
  },
  firstName: {
    label: 'First name',
  },
  middleName: {
    label: 'Middle name',
  },
  lastName: {
    label: 'Last name',
  },
  phoneNumber: {
    label: 'Phone number',
  },
  birthdate: {
    label: 'Birthdate',
  },
  email: {
    label: 'Email address',
    helperText: 'This email address must belong to you (the applicant) for us to send you important information.',
  },
  mailingAddressDifferent: {
    label: 'My mailing address and my place of residence are different',
  },
  homeAddress: {
    label: 'Home address',
  },
  streetAddress: { label: 'Street address' },
  suiteNumber: { label: 'Suite or unit #' },
  city: { label: 'City/town' },
  province: { label: 'Province/territory' },
  postalCode: { label: 'Postal code' },
};
