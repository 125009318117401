import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const AishIncomeLabels: IntakeLabelCollection = {
  hasOtherSourcesIncome: {
    replacementLabel: 'Do you or your <<replacementLabel>> have other sources of income?',
    label: 'Do you have other sources of income?',
  },
  otherSourcesType: {
    label: 'Description of income type',
  },
  otherSourcesFrequency: {
    label: 'How frequently do you receive this income type?',
  },
  otherSourcesAmount: {
    label: 'How much?',
  },
  receivedSpecialPayment: {
    label: 'Have you received a special payment in the past 12 months?',
    replacementLabel: 'Have you or your <<replacementLabel>> received a special payment in the past 12 months?',
  },
  specialPaymentType: {
    label: 'Description of special payment',
  },
  specialPaymentFrequency: {
    label: 'How frequently do you receive this special payment?',
  },
  specialPaymentAmount: {
    label: 'How much?',
  },
};
