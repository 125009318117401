import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

/**
 * SignedInRedirectGuard
 * Redirect signed in users to dashboard.
 */
@Injectable({
  providedIn: 'root',
})
export class SignedInRedirectGuard extends KeycloakAuthGuard {
  constructor(protected readonly router: Router, protected readonly keycloakService: KeycloakService) {
    super(router, keycloakService);
  }

  public async isAccessAllowed() {
    if (this.authenticated) {
      this.router.navigateByUrl('/dash');
      return false;
    }
    return true;
  }
}
