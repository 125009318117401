import { Lookup } from './lookup.interface';

export class CaseloadWorkflowStatuses {
  static readonly NINotCompleted: Lookup = { displayValue: 'NI - Not completed', code: '10' };
  static readonly NICompletedNA: Lookup = { displayValue: 'NI - Completed/NA', code: '30' };
  static readonly EDContacted: Lookup = { displayValue: 'ED - Contacted', code: '50' };
  static readonly EDFinalOutcome: Lookup = { displayValue: 'ED - Final Outcome', code: '99' };

  static readonly KeyLookup = {
    NINotCompleted: CaseloadWorkflowStatuses.NINotCompleted,
    NICompletedNA: CaseloadWorkflowStatuses.NICompletedNA,
    EDContacted: CaseloadWorkflowStatuses.EDContacted,
    EDFinalOutcome: CaseloadWorkflowStatuses.EDFinalOutcome,
  };

  static readonly ALL = Object.values(CaseloadWorkflowStatuses.KeyLookup) as Lookup[];
}
