<div [formGroup]="formGroup" class="calendar-range-select-group">
  <mat-select formControlName="type" data-cy="range-type-dropdown" placeholder="{{ selectOneText }}">
    <mat-option disabled value="">{{ selectOneText }}</mat-option>
    <ng-container *ngFor="let option of calendarRangeTypes | keyvalue: unsort; let last = last">
      <mat-option [value]="option.value.code">
        {{ option.value.displayValue }}
      </mat-option>
      <mat-divider *ngIf="!last"></mat-divider>
    </ng-container>
    <mat-option value="customDate">Custom date</mat-option>
    <mat-option value="customRange">Custom range</mat-option>
  </mat-select>
  <common-calendar data-cy="date" formControlName="date" *ngIf="showDate()"></common-calendar>
  <common-calendar-range
    data-cy="dateRange"
    formControlName="dateRange"
    *ngIf="showDateRange()"
  ></common-calendar-range>
</div>
