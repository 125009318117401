import { Utilities } from '@common/utils';
import { map, share } from 'rxjs/operators';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  SELECTED_APPLICATION_QUERY,
  APPLICATION_BY_ID_QUERY,
  SAVE_APPLICATION_MUTATION,
  ASSIGN_APPLICATION_NUMBER_MUTATION,
  MY_APPLICATIONS_QUERY,
  DELETE_APPLICATION_MUTATION,
  RECOVER_APPLICATION_MUTATION,
  REMOVE_APPLICATION_MUTATION,
} from '../pages/page-gql';
import { FscdIntakeApplication } from '@fscd-intake/entities';
import { lastValueFrom } from 'rxjs';

const limitApiResults = 100;

@Injectable()
export class FiGraphqlService {
  isAuthenticated = false;
  constructor(private apollo: Apollo, authenticationService: AuthenticationService) {
    if (authenticationService) this.isAuthenticated = authenticationService.isAuthenticated();
  }

  getAllApplications() {
    return this.apollo
      .query<{
        myApplications: FscdIntakeApplication[];
      }>({
        query: MY_APPLICATIONS_QUERY,
        variables: { limit: limitApiResults },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((result) => {
          return result?.data?.myApplications ?? [];
        })
      );
  }

  getSelectedApplication() {
    return this.apollo
      .query<{
        selectedApplicationId: string;
        isSubmitted?: boolean;
        submittedOn?: Date;
      }>({
        query: SELECTED_APPLICATION_QUERY,
      })
      .pipe(map(({ data }) => data))
      .toPromise();
  }

  getSelectedApplicationSub() {
    return this.apollo
      .watchQuery<{
        selectedApplicationId: string;
        isSubmitted?: boolean;
        submittedOn?: Date;
      }>({
        query: SELECTED_APPLICATION_QUERY,
      })
      .valueChanges.pipe(map(({ data }) => data))
      .pipe(share());
  }

  updateSelectedApplication(id) {
    this.apollo.client.writeQuery({
      query: SELECTED_APPLICATION_QUERY,
      data: {
        selectedApplicationId: id,
        isSubmitted: true,
      },
    });
  }

  getFullApplication(id) {
    return this.apollo
      .watchQuery<{ application: FscdIntakeApplication }>({
        query: APPLICATION_BY_ID_QUERY,
        variables: {
          id: id,
        },
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const applicationData = data['application'];
          return Utilities.removeTypeNameFromObject(applicationData) as FscdIntakeApplication;
        })
      );
  }

  updateApplication(application: FscdIntakeApplication) {
    return this.apollo
      .mutate<{ saveApplication: FscdIntakeApplication }>({
        mutation: SAVE_APPLICATION_MUTATION,
        variables: {
          applicationInput: application,
        },
      })
      .pipe<FscdIntakeApplication>(
        map(({ data }) => {
          const savedApplication = this.isAuthenticated ? data['saveApplication'] : data['saveAnonymousApplication'];
          return Utilities.removeTypeNameFromObject(savedApplication) as FscdIntakeApplication;
        })
      );
  }

  assignApplicationNumber(application: FscdIntakeApplication) {
    return this.apollo
      .mutate<{ assignApplicationNumber: FscdIntakeApplication }>({
        mutation: ASSIGN_APPLICATION_NUMBER_MUTATION,
        variables: { id: application.id },
      })
      .pipe<FscdIntakeApplication>(
        map(({ data }) => {
          return data.assignApplicationNumber;
        })
      );
  }

  removeApplication(id: string) {
    return lastValueFrom(
      this.apollo.mutate({
        mutation: REMOVE_APPLICATION_MUTATION,
        variables: { id: id },
      })
    );
  }

  recoverApplication(id: string) {
    return lastValueFrom(
      this.apollo.mutate({
        mutation: RECOVER_APPLICATION_MUTATION,
        variables: { id: id },
      })
    );
  }

  deleteApplication(id: string) {
    return lastValueFrom(
      this.apollo.mutate({
        mutation: DELETE_APPLICATION_MUTATION,
        variables: { id: id },
      })
    );
  }
}
