import { min } from 'lodash';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UploadLookup, DocumentsUpload } from '@common/constants';
import { Document, DocumentList, OptoutParam } from '@common/entities';
import { CanvasState } from './canvas-state';

@Component({
  selector: 'common-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
})
export class DocumentUploadComponent implements OnInit, OnDestroy {
  @Input() parentId: string;
  @Input() groupName = 'uploadControl';
  @Input() label: string;
  @Input() showUploadRemaining = true;
  @Input() documentCategories: DocumentsUpload;
  @Input() maxFileSize = 15 * 1024 * 1024;
  @Input() accept = '.PDF, .JPEG, .JPG, .PNG, .TIFF';
  @Input() deleteMessage = 'We will not be saving these documents, but you are free to remove them.';
  // @Input() documentsUploaded: Document[];
  @Input() multipleDocument = true;
  @Input() tooltipMessage: string;
  @Input() tooltipPosition: string;
  @Input() ariaLabelForPreviewIcon = 'Preview';
  @Input() categoryLabel = 'Proof of';
  @Input() dataCy: string;
  @Input() componentInitialized: boolean;
  @Input() alwaysShowOptOut = false;
  @Input() enableDragDrop = false;
  @Input() markAsTouched: boolean = null;
  @Input() hideHeaderLabel = false;
  @Output() uploadStarted = new EventEmitter<Document[]>();
  @Output() uploadInProgress = new EventEmitter<Document>();
  @Output() fileUploaded = new EventEmitter<Document>();
  @Output() errorOccurred = new EventEmitter<Document>();
  @Output() uploadCompleted = new EventEmitter<void>();
  @Output() removeFile = new EventEmitter<Document>();
  @Output() initUploader = new EventEmitter<void>();
  @Output() optOutChanged = new EventEmitter<OptoutParam>();

  canvasStates: CanvasState[] = [];

  //IMPORTANT - Either pass in documentListUploaded (new way) or documentsUploaded (legacy way). NOT BOTH!
  @Input() documentListUploaded: DocumentList = null;
  private _documentsUploaded: Document[];
  @Input() set documentsUploaded(value: Document[]) {
    if (value) {
      this._documentsUploaded = value;
      //this is to maintain backwards compatibility for AISH where it's not using DocumentList object
      if (!this.documentListUploaded && this._documentsUploaded.length > 0) {
        this.documentListUploaded = { documents: value, optOut: [], activated: false };
      }
    }
  }

  // get documentsUploaded(): Document[] {
  //   return this._documentsUploaded;
  // }

  // _files = new BehaviorSubject<Document[]>([]);
  // files$ = this._files.asObservable();
  formGroup = this.fb.group({});
  private errorDocuments: Document[] = [];
  private containner: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder, private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.containner = this.controlContainer.control as UntypedFormGroup;
    this.containner.addControl(this.groupName, this.formGroup, { emitEvent: false });
    this.initUploader.emit();
  }

  ngOnDestroy(): void {
    if (this.containner?.controls[this.groupName]) this.containner.removeControl(this.groupName, { emitEvent: false });
  }
  onUploadStarted(files: Document[]) {
    //this._files.next([...((this._files.value && this._files.value) || []), ...files]);
    this.uploadStarted.emit(files);
  }

  saveCanvasState(canvasState: CanvasState) {
    const item = this.canvasStates.find((i) => i.key === canvasState.key);
    const index = this.canvasStates.findIndex((i) => i.key === canvasState.key);

    if (!item) {
      this.canvasStates.push(canvasState);
    } else {
      // item = canvasState;
      this.canvasStates[index] = canvasState;
    }
  }
  getCanvasState(key: string) {
    const item = this.canvasStates.find((i) => i.key === key) as CanvasState;
    return item?.state;
  }

  onUploadInProgress(document: Document) {
    // const index = this.errorDocuments.findIndex((d) => d.key === document.key);
    // if (index >= 0) this.errorDocuments[index] = { ...document };
    // else this.errorDocuments.push({ ...document });
    this.uploadInProgress.emit(document);
  }

  onFileUploaded(document: Document) {
    delete document.file;
    delete document.progress;
    this.fileUploaded.emit(document);
  }
  onRemoveFile(document: Document) {
    if (this.documentCategories[document.documentCategory]?.isRemoving) {
      if (
        !this.documentListUploaded?.documents.some(
          (d) => d.documentCategory === document.documentCategory && d.key !== document.key
        )
      )
        delete this.documentCategories[document.documentCategory];
    }
    this.removeFile.emit(document);
  }

  onErrorOccurred(document: Document) {
    //there is an error with this line. if it fails, document will not have a key value
    //working only for single file upload cause this never runs more than once at a time. fails for multi upload
    //const index = this.errorDocuments.findIndex((d) => d.key === document.key);

    const index = this.errorDocuments.findIndex((d) => d.name === document.name); //search doc by name as they cannot upload 2 files of the same name
    if (index >= 0) {
      this.errorDocuments[index] = document;
    } else {
      this.errorDocuments.push(document);
      this.errorOccurred.emit(document);
    }
  }

  onCompleted() {
    this.uploadCompleted.emit();
  }

  onOptOutChanged(optOutParam: OptoutParam) {
    if (optOutParam.optOut) {
      //add category

      if (!this.hasOptedOut(optOutParam.category)) {
        if (!this.documentListUploaded.optOut) {
          this.documentListUploaded.optOut = [];
        }
        this.documentListUploaded.optOut.push(optOutParam.category);
      }
    } else {
      //remove category
      if (this.hasOptedOut(optOutParam.category)) {
        const removeIndex = this.documentListUploaded.optOut.indexOf(optOutParam.category);
        this.documentListUploaded.optOut.splice(removeIndex, 1);
      }
    }
    // this.documentListUploaded.optOut = optOut;
    this.optOutChanged.emit(optOutParam);
  }

  getDocumentsBy(documentCategory: string) {
    // const docsUploaded = this.documentListUploaded?.documents ?? [];
    let docsUploaded = this._documentsUploaded;
    if (!docsUploaded) {
      docsUploaded = this.documentListUploaded?.documents ?? [];
    }
    const documents = docsUploaded.filter(
      (document) => document.documentCategory === documentCategory && !document.isDeleted
    );
    // const documentList = { documents: documents, optOut: this.documentListUploaded?.optOut } as DocumentList;
    const documentList = { ...this.documentListUploaded, documents: documents } as DocumentList;
    return documentList;
  }

  getAllDocumentsBy(documentCategory: string) {
    let docsUploaded = this._documentsUploaded;
    if (!docsUploaded) {
      docsUploaded = this.documentListUploaded?.documents ?? [];
    }
    // const docsUploaded = this.documentListUploaded?.documents ?? [];
    // return [...docsUploaded, ...this.errorDocuments].filter(
    //   (document) => document.documentCategory === documentCategory && !document.isDeleted
    // );

    const allDocs = [...docsUploaded, ...this.errorDocuments]
      .filter((document) => document.documentCategory === documentCategory && !document.isDeleted)
      .sort(function (a, b) {
        const dateA = a?.dateUploaded?.getTime() ?? 0;
        const dateB = b?.dateUploaded?.getTime() ?? 0;
        return dateA - dateB;
      });

    return allDocs;
  }

  get uploadsRemaining() {
    return Object.keys(this.documentCategories).reduce(
      (prev, category) =>
        prev +
        (this.documentCategories[category]?.isRemoving ||
        this.getDocumentsBy(category)?.documents?.length ||
        this.hasOptedOut(category) ||
        this.documentCategories[category]?.isOptional
          ? 0
          : 1),
      0
    );
  }

  hasOptedOut(category: string) {
    let optedOut = false;
    if (this.documentListUploaded?.optOut?.includes(category)) {
      optedOut = true;
    }
    return optedOut;
  }

  sort(a: { value: UploadLookup }, b: { value: UploadLookup }) {
    return a.value.order - b.value.order;
  }

  get hasCategory() {
    return Object.keys(this.documentCategories).length > 0;
  }

  remainingUploadsIndicator(uploadsRemaining: number) {
    //{{ uploadsRemaining }} uploads remaining
    let remainingText = 'uploads remaining';
    if (uploadsRemaining === 1) {
      remainingText = 'upload remaining';
    }
    const indicatorText = `${uploadsRemaining} ${remainingText}`;
    return indicatorText;
  }
}
