import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FscdIntakeSessionStorageKeys } from '@common/constants';
import { FiDashboardLabels } from '@common/labels';
import { AppConfigService, ContextProvider, FileService } from '@common/ui/shared-components';
import { Utilities } from '@common/utils';
import { FiChild, FscdIntakeApplication } from '@fscd-intake/entities';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { Subject, firstValueFrom, lastValueFrom, takeUntil } from 'rxjs';
import { FiGraphqlService } from '../../services/fi-graphql.service';
import {
  existingApplicationDraft,
  existingApplicationRemoved,
  existingApplicationSubmitted,
} from '../../shared/components/dashboard-modals/dashboard-page.modals';
import { DashboardListType } from '../../shared/enums/dashboard-list-types';
import { DashboardListApplication } from '../../shared/interfaces/dashboard-list-application';
import { FiUtils } from '../../shared/utils/fi-utils';
import { BaseSaveComponent } from '../base-save/base-save.component';
import { DocumentIterator } from '../review-page/document-iterator';

@Component({
  selector: 'fi-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent extends BaseSaveComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  encryptionKey = null;
  submittedAppsCalloutText: string = FiDashboardLabels.submittedAppsNote.label;
  draftAppsCalloutText: string = FiDashboardLabels.draftAppsNote.label;
  removedAppsCalloutText: string = FiDashboardLabels.removedAppsNote.label;

  constructor(
    apollo: Apollo,
    fileService: FileService,
    authenticationService: AuthenticationService,
    contextProvider: ContextProvider,
    toasterService: ToastrService,
    fiGraphqlService: FiGraphqlService,
    private configService: AppConfigService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private addChildModal: MatDialog,
    private utils: FiUtils,
    private cdr: ChangeDetectorRef
  ) {
    super(apollo, fileService, authenticationService, contextProvider, fiGraphqlService, toasterService);
  }

  showStartAppModal = new EventEmitter<boolean>();
  isAccordionExpanded = false;
  tabIndex = 1;
  tabIndexMobile = null;
  loading = true;
  empty = false;
  profileFirstName = '';
  user = null;

  rawApplications: FscdIntakeApplication[] = [];
  applicationsDraft: DashboardListApplication[];
  applicationsSubmitted: DashboardListApplication[];
  applicationsRemoved: DashboardListApplication[];

  displayColumnsDraft = ['childName', 'dateStarted', 'dateLastSaved', 'action'];
  displayedColumnsSubmitted = ['childName', 'applicationId', 'dateStarted', 'dateSubmitted', 'status', 'action'];
  displayedColumnsRemoved = ['childName', 'dateStarted', 'dateLastSaved', 'dateRemoved', 'action'];

  async ngOnInit() {
    this.user = await firstValueFrom(this.contextProvider.getCurrentUser());
    const name = this.user?.name ?? '';
    this.profileFirstName = name.split(' ')[0].trim();

    if (this.configService) {
      this.encryptionKey = this.configService.getConfig().ENCRYPTION_KEY;
    }

    await this.loadApplications();
    this.loading = false;

    if (this.activatedRoute?.queryParams) {
      this.activatedRoute.queryParams.subscribe((params) => {
        // TODO page reload not opening accordion
        if (params.tab === 'submitted') this.showTab(0);
        if (params.tab === 'draft') this.showTab(1);
        if (params.tab === 'removed') this.showTab(2);
      });
    }
  }

  showTab(index: number) {
    this.tabIndex = index;
    this.tabIndexMobile = index;
  }

  getListTypeDraft() {
    return DashboardListType.Draft;
  }

  getListTypeRemoved() {
    return DashboardListType.Removed;
  }

  getListTypeSubmitted() {
    return DashboardListType.Submitted;
  }

  async loadApplications() {
    this.rawApplications = await lastValueFrom(this.fiGraphqlService.getAllApplications());
    const applicationsAll = this.rawApplications.map(this.utils.createDashboardListApplication);
    this.applicationsDraft = applicationsAll.filter((listApp) => listApp.isDraft);
    this.applicationsSubmitted = applicationsAll.filter((listApp) => listApp.isSubmitted);
    this.applicationsRemoved = applicationsAll.filter((listApp) => listApp.isRemoved);

    if (!this.applicationsDraft.length && !this.applicationsSubmitted.length && !this.applicationsRemoved.length) {
      this.empty = true;
    }
  }

  async onStartAppModalContinue(newChild: FiChild) {
    const hasNameMatch = this.utils.hasNameMatch(newChild);
    const draftApp = this.applicationsDraft.find(hasNameMatch);
    const isInSubmitted = !!this.applicationsSubmitted.find(hasNameMatch);
    const isInRemoved = !!this.applicationsRemoved.find(hasNameMatch);

    if (draftApp) {
      const navToDraft = await existingApplicationDraft(this.addChildModal, {
        confirmLabel: 'Continue',
        cancelLabel: 'Cancel',
      });

      if (navToDraft) this.onContinueApplication(draftApp);
      return;
    }
    if (isInSubmitted) {
      const showTabSubmitted = await existingApplicationSubmitted(this.addChildModal, {
        confirmLabel: 'View',
        cancelLabel: 'Cancel',
      });

      if (showTabSubmitted) this.showTab(0);
      return;
    }
    if (isInRemoved) {
      const showTabRemoved = await existingApplicationRemoved(this.addChildModal, {
        confirmLabel: 'Restore',
        cancelLabel: 'Cancel',
      });

      if (showTabRemoved) this.showTab(2);
      return;
    }

    Utilities.setSessionStorageValue(FscdIntakeSessionStorageKeys.ChildName.key, newChild, this.encryptionKey);
    this.router.navigateByUrl('/prescreen');
  }

  onAddNewApplication() {
    if (this.empty) {
      this.router.navigateByUrl('/prescreen');
      return;
    }
    this.showStartAppModal.emit(true);
  }

  async onViewApplication(application: DashboardListApplication) {
    this.router.navigate(['view-application', { applicationId: application.id }]);
  }

  async onContinueApplication(application: DashboardListApplication) {
    this.router.navigate(['application', { applicationId: application.id }]);
  }

  async onRecoverApplication(application: DashboardListApplication) {
    try {
      await this.fiGraphqlService.recoverApplication(application.id);
      this.toasterService.success('Application has been restored');
      await this.loadApplications();
    } catch (e) {
      this.toasterService.error('Application could not be restored');
    }
  }

  async onRemoveApplication(application: DashboardListApplication) {
    try {
      await this.fiGraphqlService.removeApplication(application.id);
      this.toasterService.success('Application has been removed');
      await this.loadApplications();
      return true;
    } catch (e) {
      this.toasterService.error('Application could not be removed');
      return false;
    }
  }

  async onDeleteApplication(application: DashboardListApplication) {
    try {
      await this.deleteAllDocuments(application.id);
      await this.fiGraphqlService.deleteApplication(application.id);
      this.toasterService.success('Application permanently deleted');
      await this.loadApplications();

      return true;
    } catch (e) {
      this.toasterService.error('Application could not be deleted');
      return false;
    }
  }

  async onExpansion(isExpanded: boolean) {
    this.isAccordionExpanded = isExpanded;
    this.cdr.detectChanges();
  }

  private async deleteAllDocuments(applicationID: string) {
    try {
      const fullApplication$ = this.fiGraphqlService.getFullApplication(applicationID);
      const application: FscdIntakeApplication = await new Promise((resolve, reject) => {
        fullApplication$.pipe(takeUntil(this.destroy$)).subscribe({
          next: (app: FscdIntakeApplication) => {
            resolve(app);
          },
          error: (error) => {
            reject(error);
          },
        });
      });

      const docIterator = new DocumentIterator(application);
      const documents = docIterator.iterateNames();
      const deletePromises = documents.map((doc) => {
        return this.fileService.deleteFile(doc.name, applicationID);
      });
      await Promise.all(deletePromises);
    } catch (e) {
      //don't throw error, we don't want to stop app delete from UI, even if we can't delete all documents
      return;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
