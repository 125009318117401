import { AngularComponentsModule } from '@abgov/angular-components';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MaterialModule } from '@common/ui/material';
import { GoogleAnalyticsService, UiSharedComponentsModule } from '@common/ui/shared-components';
import { ApolloModule } from 'apollo-angular';

import { FscdIntakeRoutingModule } from './fscd-intake-routing.module';
import { SharedModule } from './shared/shared.module';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { ApplicationListComponent } from './components/application-list/application-list.component';
import { PreScreenPageComponent } from './pages/pre-screen-page/pre-screen-page.component';
import { KnockoutPageComponent } from './pages/knockout-page/knockout-page.component';
import { ProceedWithApplicationPageComponent } from './pages/proceedwithapplication-page/proceedwithapplication-page.component';
import { InformationBoxComponent } from './components/information-box/information-box.component';
import { ApplicationComponent } from './pages/application.component';
import { ChildInfoPageComponent } from './pages/child-info-page/child-info-page.component';
import { ParentInfoPageComponent } from './pages/parent-info-page/parent-info-page.component';
import { ParentInfoComponent } from './components/parent-info/parent-info.component';
import { FiGraphqlService } from './services/fi-graphql.service';
import { PdfGenerationService } from './services/pdf-generation.service';
import { ChildInfoComponent } from './components/child-info/child-info.component';
import { DeveloperToolsModule } from './modules/developer-tools/developer-tools.module';
import { ReviewComponent } from './components/review/review.component';
import { ReviewPageComponent } from './pages/review-page/review-page.component';
import { SubmittedComponent } from './components/submitted/submitted.component';
import { EyeIconComponent } from './shared/components/eye-icon/eye-icon.component';
import { SessionTimeoutPageComponent } from './shared/pages/session-timeout-page/session-timeout-page.component';
import { ConsentComponent } from './components/consent/consent.component';
import { ApplicationListMobileComponent } from './components/application-list-mobile/application-list-mobile.component';
import { ServiceErrorPageComponent } from './shared/pages/service-error-page/service-error-page.component';
import { CssRoles } from '@common/constants';
import { MaintenanceBypassRoles, MaintenanceRedirectRoute } from '@common/ui/maintenance-mode';
import { ServicesPageComponent } from './pages/services-page/services-page.component';
import { ServicesComponent } from './components/services/services.component';
import { HealthCheckService } from './services/health-check.service';
import { StartAppModalComponent } from './components/start-app-modal/start-app-modal.component';
import { ViewApplicationTabsMobileViewComponent } from './components/view-application-tabs-mobile-view/view-application-tabs-mobile-view.component';
import { ViewApplicationPageComponent } from './pages/view-application-page/view-application-page.component';
import { ViewApplicationServicesComponent } from './components/application-view/view-application-services/view-application-services.component';
import { ViewApplicationChildComponent } from './components/application-view/view-application-child/view-application-child.component';
import { ViewApplicationParentComponent } from './components/application-view/view-application-parent/view-application-parent.component';
import { AutosizeModule } from 'ngx-autosize';
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    MainPageComponent,
    DashboardPageComponent,
    PreScreenPageComponent,
    ApplicationListComponent,
    KnockoutPageComponent,
    ProceedWithApplicationPageComponent,
    InformationBoxComponent,
    ApplicationComponent,
    ChildInfoPageComponent,
    ParentInfoPageComponent,
    ParentInfoComponent,
    ChildInfoComponent,
    ReviewPageComponent,
    ReviewComponent,
    SubmittedComponent,
    EyeIconComponent,
    SessionTimeoutPageComponent,
    ConsentComponent,
    ApplicationListMobileComponent,
    ServiceErrorPageComponent,
    ServicesPageComponent,
    ServicesComponent,
    StartAppModalComponent,
    ViewApplicationTabsMobileViewComponent,
    ViewApplicationPageComponent,
    ViewApplicationChildComponent,
    ViewApplicationParentComponent,
    ViewApplicationServicesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ApolloModule,
    UiSharedComponentsModule,
    AngularComponentsModule,
    FscdIntakeRoutingModule,
    MaterialModule,
    InfiniteScrollModule,
    DeveloperToolsModule,
    AutosizeModule,
  ],
  providers: [
    FiGraphqlService,
    PdfGenerationService,
    DatePipe,
    GoogleAnalyticsService,
    HealthCheckService,
    { provide: MaintenanceBypassRoles, useValue: [CssRoles.CSSAdmin] },
    { provide: MaintenanceRedirectRoute, useValue: '/serviceError' },
  ],
  exports: [ViewApplicationTabsMobileViewComponent],
})
export class FscdIntakeModule {}
