import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'ageInYearsAndMonths',
})
export class AgeInYearsAndMonthsPipe implements PipeTransform {
  transform(dob: Date, dateOfDeath?: Date): unknown {
    const endDate = dayjs(dateOfDeath ?? new Date());
    const years = endDate.diff(dob, 'years') < 0 ? 0 : endDate.diff(dob, 'years');
    const months = endDate.subtract(years, 'years').diff(dob, 'months');
    let yearString: string = years > 0 ? years + ' year' : '';
    yearString += years > 1 ? 's' : '';
    let monthString: string = months > 0 ? months + ' month' : '';
    monthString += months > 1 ? 's' : '';
    const ageDisplay = yearString + ' ' + monthString;

    return ageDisplay.trim();
  }
}
