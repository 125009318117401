import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { validatePostalCode } from '@common/validation';
export class StringValidator {
  static max(value: string, label = ''): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.invalid || !control.value) return null;
      return (control.value > value && { max: { max: value, actual: control.value, label: label } }) || null;
    };
  }
  static min(value: string, label = ''): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.invalid || !control.value) return null;
      return (control.value < value && { min: { min: value, actual: control.value, label: label } }) || null;
    };
  }

  static removeFile(control: AbstractControl): ValidationErrors | null {
    return control.invalid || !control.value || control.value.length === 0 ? null : { removeFile: true };
  }

  static isValidPostalCode(): ValidatorFn {
    return function validate(control: AbstractControl) {
      const isValid = validatePostalCode(control.value);
      return isValid ? null : { invalidPostalCode: control.value };
    };
  }

  static leadingSpace(label = 'Field value'): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value?.startsWith(' ')) {
        return {
          leadingSpace: { value: `${label} has leading whitespace` },
        };
      }
      return null;
    };
  }

  static trailingSpace(label = 'Field value'): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value?.endsWith(' ')) {
        return {
          trailingSpace: { value: `${label} has trailing whitespace` },
        };
      }
      return null;
    };
  }

  static notEmpty(control: AbstractControl): ValidationErrors | null {
    if (!control.value || (typeof control.value === 'string' && control.value.trim() === '')) {
      return {
        required: true,
      };
    }

    return null;
  }
}
