import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FbSpousePartnerLabels: IntakeLabelCollection = {
  firstName: {
    label: 'First name',
  },
  middleName: {
    label: 'Middle name',
  },
  lastName: {
    label: 'Last name',
  },
  email: {
    label: 'Email address',
  },
  phoneNumber: {
    label: 'Phone number',
  },
  birthdate: {
    label: 'Birthdate',
  },
  spouseAddress: {
    label: 'Address of spouse/partner',
    replacementLabel: 'Address of <<replacementLabel>>',
  },
  streetAddress: { label: 'Street address' },
  suiteNumber: { label: 'Suite or unit #' },
  city: { label: 'City/town' },
  province: { label: 'Province/territory' },
  postalCode: { label: 'Postal code' },

  //TODO: cleanup above labels
  // whyApplying: {
  //   label:
  //     'You have indicated that there is an active sponsorship. Please explain why are you applying for these benefits while there is an active sponsorship?',
  // },
  executorIsSpouse: {
    label: `Are you the spouse/partner of the deceased?`,
  },
};
