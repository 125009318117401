import * as Quill from 'quill';
const QuillType: any = Quill;
const Delta = QuillType.import('delta');

class WebListMatcher {
  static match(node: Node, delta: any) {
    const listLevel = WebListMatcher.getListLevel(node);
    if (!listLevel) {
      return delta;
    }

    return delta.compose(new Delta().retain(delta.length() - 1).retain(1, { indent: listLevel - 1 }));
  }

  private static getListLevel(node: Node): number | null {
    if (!(node instanceof HTMLElement)) {
      return null;
    }

    const levelStr = (node as HTMLElement).getAttribute('data-aria-level');
    const levelNum = Number(levelStr);

    return isNaN(levelNum) ? null : levelNum;
  }
}

/** Convert paste from MS Word web editor to capture list indentation. */
export const MSWordWebListMatcher = (node, delta) =>
  WebListMatcher.match(node, delta) as Quill.ClipboardMatcherCallback;
