import { OneToFiveScaleWithNA } from './assessment-scales';
import { SkillsLookup } from './skills-lookup.interface';

export const OccupationalSkills: { [name: string]: SkillsLookup } = {
  studySkills: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Study skills',
    helperText:
      'Effective study skills include the ability to plan, set priorities, organize, and complete both assigned and supplementary schoolwork. Time management skills contribute to effective study skills. In addition, knowing how to spend time effectively while studying is an important skill.',
    sampleQuestions: [
      {
        question: 'Tell me about the last time you were in school or training and how it went for you.',
        followupQuestions: [
          { question: 'What did you do outside of class time related to studying, homework, etc?' },
          { question: 'What did you do to prepare for your tests/exams?' },
        ],
      },

      {
        question: 'Give me your five best study skill tips.',
        followupQuestions: [
          { question: 'What do you think you need to focus on or do to be an effective student/learner?' },
        ],
      },
    ],
  },
  financialCapacity: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Financial capacity',
    helperText: 'Financial capacity involves the financial resources that are available to the individual.',
    sampleQuestions: [
      { question: 'What is the field of work that you are choosing to enter?' },
      {
        question:
          'Do you anticipate having to complete some education, training, or certification before you enter the field?',
        followupQuestions: [
          { question: 'Describe this training, where you can get it, how long it will take, and so on.' },
          {
            question: 'What have you been able to find out about the cost of the training?',
            followupQuestions: [
              { question: 'List all the costs that might come up for you related to completing the training.' },
              { question: 'Do you have enough funds to cover these costs?' },
              { question: 'What is the shortfall that you face to cover these costs?' },
            ],
          },
        ],
      },

      {
        question:
          'If entering directly into employment: What costs do you anticipate facing related to obtaining the work you choose as well as costs of employment once you have this work?',
        followupQuestions: [
          { question: 'Do you have enough funds to cover these costs?' },
          { question: 'What is the shortfall that you face to cover these costs?' },
        ],
      },

      {
        question:
          'Tell me about the financial resources that you have in your life right now that could support you while you follow through on your plan of action.',
      },
    ],
  },
  prerequisitesForTraining: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Pre-requisites required for training',
    helperText:
      'Pre-requisites required for training are related to one’s current educational level. Students who do no have the required educational level to enter training must complete the specified pre-requisites to qualify for entry.',
    sampleQuestions: [
      {
        question: 'What is the field of work that you choose to enter?',
        followupQuestions: [
          { question: 'What is it about this work that is appealing?' },
          { question: 'How does getting involved in this type of work relate to your vision?' },
        ],
      },

      {
        question: 'Describe the training required to enter this type of work.',
        followupQuestions: [
          {
            question:
              'What background in terms of education and experience do  you need to qualify for entry into this training?',
          },
          { question: 'How does your background relate to those requirements?' },
          {
            question: 'What pre-requisites must you complete to enter the training?',
            followupQuestions: [{ question: 'Do you have these pre-requisites?' }],
          },
        ],
      },
      {
        question: 'What have you found out about how you might go about completing these pre-requisites?',
        followupQuestions: [
          {
            question: 'Including:',
            followupQuestions: [{ question: 'Availability and location' }, { question: 'Cost and duration' }],
          },
        ],
      },
      { question: 'What action will you have to take to successfully complete these pre-requisites?' },
    ],
  },
  workExperience: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Work experience including volunteer',
    helperText:
      'Work experience includes self-employment, full-time, part-time, casual and summer employment, as well as volunteer activities. Volunteer work involves exchanging one’s labour not for money but for other reasons and purposes.',
    sampleQuestions: [
      {
        question: 'Tell me about the work that you have done in the past.',
        followupQuestions: [
          {
            question:
              'How might the skills that you developed in your past work relate to the work you are seeking now?',
          },
        ],
      },

      {
        question:
          'Tell me about any volunteer work that you have done, responsibilities you have for your family, community work.',
        followupQuestions: [
          {
            question:
              'How might the skills you have built through volunteer, family, and/or community work relate to the work you are seeking now?',
          },
        ],
      },

      {
        question:
          'It seems that you have changed jobs fairly frequently (or) have had some gaps in your work periods. Tell me more about that.',
      },

      { question: 'Who might you ask for a reference regarding your skills?' },

      { question: 'What do you think your references might say?' },
    ],
  },
  relevantTraining: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Training/certification for employment goals',
    helperText:
      'Relevant occupational training and/or certification is related to an individual’s employment goals and related background. Many occupations require specific levels of training and/or certification in order to gain employment.',
    sampleQuestions: [
      {
        question: 'What is the field of work that you choose to enter?',
        followupQuestions: [
          { question: 'What is it about this work that is appealing?' },
          { question: 'How does getting involved in this type of work relate to your vision?' },
        ],
      },
      {
        question: 'Describe the training/certification that you must complete to enter this field of employment.',
        followupQuestions: [
          { question: 'How does your background relate to those requirements?' },
          {
            question:
              'What have you learned about the availability of the training/certification required including location, cost, and duration?',
          },
        ],
      },

      {
        question: 'Do you have any concerns about completing any required training/certification?',
        followupQuestions: [
          { question: 'Tell me about them.' },
          { question: 'How might you deal with these concerns?' },
        ],
      },
      {
        question: 'Where might you find the support that you need to help you complete this training/certification?',
      },
    ],
  },
};
