import { IsDate, IsDefined, ValidateNested } from 'class-validator';
import { Expose, Type } from 'class-transformer';
import { FiChild } from './child.entity';
import { FiParent } from './parent.entity';
import { FscdIntakeApplicationErrors } from './application-errors.entity';
import { FiReview } from './review.entity';
import { FiServices } from './services.entity';

export class FscdIntakeApplication {
  @Expose()
  id?: string;

  @Expose()
  applicationNumber?: string;

  @Expose()
  status?: string;

  @Expose()
  @IsDefined()
  @IsDate()
  createdAt?: Date;

  @Expose()
  @IsDefined()
  @IsDate()
  updatedAt?: Date;

  @Expose()
  isSubmitted?: boolean;

  @Expose()
  submittedOn?: Date;

  @Expose()
  submittedBy?: string;

  @Expose()
  authorizedUser?: string;

  @Expose()
  @Type(() => FiChild)
  @IsDefined()
  @ValidateNested()
  child?: FiChild;

  @Expose()
  @Type(() => FiParent)
  @IsDefined()
  @ValidateNested()
  parent?: FiParent;

  @Expose()
  outputPdfUrl?: string;

  @Expose()
  outputPdfFileName?: string;

  @Expose()
  isRemoved?: boolean = false;

  @Expose()
  removedOn?: Date;

  @Expose()
  isUploaded?: boolean;

  @Expose()
  uploadedOn?: Date;

  @Expose()
  documentCount?: number;

  @Expose()
  @Type(() => FscdIntakeApplicationErrors)
  applicationErrors?: FscdIntakeApplicationErrors;

  @Expose()
  @Type(() => FiReview)
  @IsDefined()
  @ValidateNested()
  review?: FiReview;

  @Expose()
  @Type(() => FiServices)
  @IsDefined()
  @ValidateNested()
  services?: FiServices;
}
