import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import {
  Validators,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ControlValueAccessor,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { Citizenship } from '@common/entities';
import { CitizenshipTypes } from '@common/constants';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StringValidator } from '../shared/validators/string.validator';
import { SELECT_ONE_TEXT } from '@common/constants';
import { DateValidator } from '../shared/validators/date.validator';

const { landedImmigrant, ...CitizenshipTypeWithoutLanded } = CitizenshipTypes;

@Component({
  selector: 'common-citizenship',
  templateUrl: './citizenship.component.html',
  styleUrls: ['./citizenship.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: CitizenshipComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: CitizenshipComponent,
      multi: true,
    },
  ],
})
export class CitizenshipComponent implements ControlValueAccessor, OnDestroy {
  private destroy$ = new Subject<void>();
  selectOneText = SELECT_ONE_TEXT;
  shouldShowLandedImmigrant = false;
  TODAY = new Date();
  citizenshipTypes = CitizenshipTypes;
  citizenshipTypeWithoutLanded = CitizenshipTypeWithoutLanded;
  _maxYear = new Date().getFullYear();
  _minYear = new Date(this._maxYear - 100, 1, 1).getFullYear();

  sponsorshipExpirationDateControl = this.formBuilder.control(null, [Validators.required]);
  formGroup = this.formBuilder.group({
    type: this.formBuilder.control('', [Validators.required, this.landedImmigrantDeprecatedValidator]),
    isSponsored: this.formBuilder.control(false, [Validators.required]),
    yearOfLanding: this.formBuilder.control('', {
      validators: [
        Validators.minLength(4),
        DateValidator.maxYearValidator(this._maxYear, this._maxYear.toString()),
        DateValidator.minYearValidator(this._minYear, this._minYear.toString()),
      ],
      updateOn: 'blur',
    }),
  });
  @Input() label: string;

  @Input() set maxYear(value: Date) {
    if (value?.getFullYear()) {
      this._maxYear = value.getFullYear();
      this.updateYearOfLandingValidators(this.formGroup.value?.type);
    }
  }
  @Input() set minYear(value: Date) {
    if (value?.getFullYear()) {
      this._minYear = value.getFullYear();
      this.updateYearOfLandingValidators(this.formGroup.value?.type);
    }
  }

  /**
   * Is touched.  This is a bit of a hack, because CVA doesn't propogate markAllAsTouched
   */
  @Input() set isTouched(value) {
    if (this.formGroup) {
      if (value) this.formGroup.markAllAsTouched();
      else this.formGroup.markAsUntouched();
    }
  }
  private _canEditIsASponsoredImmigrant: boolean;
  @Input() set canEditIsASponsoredImmigrant(value) {
    this._canEditIsASponsoredImmigrant = value;
    if (value) {
      this.formGroup.controls.isSponsored.enable({ emitEvent: false });
      this.sponsorshipExpirationDateControl.enable({ emitEvent: false });
    } else {
      this.formGroup.controls.isSponsored.disable({ emitEvent: false });
      this.sponsorshipExpirationDateControl.disable({ emitEvent: false });
    }
  }
  get canEditIsASponsoredImmigrant() {
    return this._canEditIsASponsoredImmigrant;
  }

  @Output() selectionValueChange = new EventEmitter<string>();

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCitizenshipTypeSelect(value) {
    const type = value.value;
    if (this.formGroup.controls.countryOfOrigin) this.formGroup.removeControl('countryOfOrigin', { emitEvent: false });

    this.addTypeSpecificFormControl(type, false);
    this.updateYearOfLandingValidators(type);
  }

  onIsSponsoredChange(value) {
    this.addRemoveSponsorshipControl(value.value, true);
  }

  private landedImmigrantDeprecatedValidator(formControl: AbstractControl): ValidationErrors | null {
    const value = formControl.value;
    if (value === CitizenshipTypes.landedImmigrant.code)
      return { deprecated: 'Landed immigrant option is discontinued and must be changed.' };
    return null;
  }

  private addRemoveSponsorshipControl(isSponsored: boolean, emitEvent = true) {
    if (isSponsored && !this.formGroup.get('sponsorshipExpirationDate'))
      this.formGroup.addControl('sponsorshipExpirationDate', this.sponsorshipExpirationDateControl, {
        emitEvent,
      });

    if (!isSponsored && this.formGroup.get('sponsorshipExpirationDate'))
      this.formGroup.removeControl('sponsorshipExpirationDate', { emitEvent });

    if (this.touched) this.formGroup.markAllAsTouched();
  }

  private addTypeSpecificFormControl(type: string, emitEvent = true) {
    if (type === CitizenshipTypes.refugee.code)
      if (!this.formGroup.get(CitizenshipTypes[type].additionalInformation))
        this.formGroup.addControl(
          CitizenshipTypes[type].additionalInformation,
          this.formBuilder.control('', { validators: [StringValidator.notEmpty], updateOn: 'blur' }),
          { emitEvent: emitEvent }
        );

    if (this.touched) this.formGroup.markAllAsTouched();
  }

  private updateYearOfLandingValidators(type) {
    this.formGroup.controls.yearOfLanding.clearValidators();
    if (type !== 'canadianCitizen')
      this.formGroup.controls.yearOfLanding.setValidators([
        Validators.minLength(4),
        DateValidator.maxYearValidator(this._maxYear, this._maxYear.toString()),
        DateValidator.minYearValidator(this._minYear, this._minYear.toString()),
        StringValidator.notEmpty,
      ]);
    else
      this.formGroup.controls.yearOfLanding.setValidators([
        DateValidator.maxYearValidator(this._maxYear, this._maxYear.toString()),
        DateValidator.minYearValidator(this._minYear, this._minYear.toString()),
      ]);

    this.formGroup.controls.yearOfLanding.updateValueAndValidity();
  }

  validate() {
    return this.formGroup.valid ? null : { invalid: true };
  }

  writeValue(value: Citizenship): void {
    if (value) {
      this.shouldShowLandedImmigrant = value.type === CitizenshipTypes.landedImmigrant.code;
      this.addTypeSpecificFormControl(value.type, false);
      this.addRemoveSponsorshipControl(value.isSponsored, false);
      const needsYearOfLandingUpdate = this.formGroup?.value?.type !== value.type;

      this.formGroup.patchValue({ ...value, isSponsored: value.isSponsored === true }, { emitEvent: false });
      if (needsYearOfLandingUpdate) this.updateYearOfLandingValidators(value.type);
    }
  }

  registerOnChange(fn) {
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((formValue) => {
      formValue.yearOfLanding = formValue.yearOfLanding ? Number(formValue.yearOfLanding) : null;
      if (formValue.type !== CitizenshipTypes.refugee.code) formValue.countryOfOrigin = null;
      if (!formValue.isSponsored) formValue.sponsorshipExpirationDate = null;
      fn(formValue);
    });
  }

  touched;
  registerOnTouched(fn) {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) this.formGroup.disable({ emitEvent: false });
    else {
      this.formGroup.enable({ emitEvent: false });
      if (!this.canEditIsASponsoredImmigrant) {
        this.formGroup.controls.isSponsored.disable({ emitEvent: false });
        this.sponsorshipExpirationDateControl.disable({ emitEvent: false });
      }
    }
  }

  unsort() {
    return 0;
  }
}
