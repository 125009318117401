import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';
import { KeycloakTokenParsed } from 'keycloak-js';
import { interval, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const SECONDS_TO_MILLISECONDS = 1000;

@Injectable({
  providedIn: 'root',
})
export class SessionMonitor implements OnDestroy {
  private stopTimer$ = new Subject<void>();
  public sessionTimeRemainingSeconds$: Observable<number> = new Subject<number>();

  constructor(private authenticationService: AuthenticationService) {}

  ngOnDestroy(): void {
    this.stopTimer$.next();
    this.stopTimer$.complete();
  }

  monitorSession(intervalSeconds: number) {
    const keycloakInstance = this.authenticationService.getKeycloakInstance();
    const intervalMs = intervalSeconds * SECONDS_TO_MILLISECONDS;

    //check the token and display notification if session is about to expire
    interval(intervalMs)
      .pipe(takeUntil(this.stopTimer$))
      .subscribe(() => {
        const token = keycloakInstance?.refreshTokenParsed; //get updated refresh token
        this.checkToken(token);
      });
  }

  stopMonitor() {
    this.stopTimer$.next();
  }

  private calculateTimeRemainingSeconds() {
    const keycloakInstance = this.authenticationService.getKeycloakInstance();
    const token = keycloakInstance?.refreshTokenParsed;
    const expTimeS = token?.exp;
    const nowTimeS = new Date().getTime() / SECONDS_TO_MILLISECONDS;
    return Math.round(expTimeS - nowTimeS); //round to nearest second
  }

  private checkToken(token: KeycloakTokenParsed) {
    if (!token?.exp) {
      (this.sessionTimeRemainingSeconds$ as Subject<number>).next(0);
    }

    // //************ */ this is just to display information - will be removed after testing out *************
    // const expTimeMs = token?.exp * SECONDS_TO_MILLISECONDS;
    // const expDateTime = new Date(expTimeMs);
    // const nowDateTime = new Date();
    // // const timeRemainingDisplay = this.calculateTimeRemainingSeconds() + ' seconds';
    // const timeRemainingSeconds = this.calculateTimeRemainingSeconds();
    // let timeRemainingDisplay = timeRemainingSeconds + ' seconds';
    // if (timeRemainingSeconds > 120) {
    //   timeRemainingDisplay = (timeRemainingSeconds / 60).toFixed(2) + ' minutes'; //show minutes for more than 2 minutes (easier to read)
    // }
    // console.log('***** Session Info *****');
    // console.log('Current Time: ' + nowDateTime);
    // console.log('Token Expiry: ' + expDateTime);
    // console.log('Time Remaining: ' + timeRemainingDisplay);

    (this.sessionTimeRemainingSeconds$ as Subject<number>).next(this.calculateTimeRemainingSeconds());
  }
}
