<div class="application-list-component">
  <table
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8 application-list-table"
    matSort
    matSortDisableClear
    (matSortChange)="changeSortOrder($event)"
  >
    <ng-container matColumnDef="childName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name of the child</th>
      <td mat-cell *matCellDef="let element">{{ element.childName || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="applicationId">
      <th mat-header-cell *matHeaderCellDef>Application ID</th>
      <td mat-cell *matCellDef="let element">{{ element.applicationId || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="dateStarted">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date started</th>
      <td mat-cell *matCellDef="let element">{{ (element.dateStarted | date: 'longDate') || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="dateLastSaved">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date last saved</th>
      <td mat-cell *matCellDef="let element">{{ (element.dateLastSaved | date: 'longDate') || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="dateSubmitted">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date submitted</th>
      <td mat-cell *matCellDef="let element">{{ (element.dateSubmitted | date: 'longDate') || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="dateRemoved">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date removed</th>
      <td mat-cell *matCellDef="let element">{{ (element.dateRemoved | date: 'longDate') || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element">
        <div class="action-cell">
          <span class="action-button">
            <goa-button
              [attr.data-cy]="dataCy(actionButtonLeftText.toLowerCase() + '-' + element.childName)"
              (_click)="onActionButtonLeftClick(element)"
              [type]="actionButtonLeftStylePrimary ? 'primary' : 'secondary'"
            >
              {{ actionButtonLeftText }}</goa-button
            >
          </span>
          <span class="action-button">
            <goa-button
              [attr.data-cy]="dataCy(actionButtonRightText.toLowerCase() + '-' + element.childName)"
              *ngIf="hasSecondButton()"
              (_click)="onActionButtonRightClick(element)"
              class="action-button"
              [type]="actionButtonRightStylePrimary ? 'primary' : 'secondary'"
            >
              {{ actionButtonRightText }}</goa-button
            >
          </span>
        </div>
      </td>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9999">No Applications Found</td>
    </tr>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
