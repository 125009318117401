import { FinancialsLabels } from './financials-labels.interface';

export const FbSponsorFinancialLabels: FinancialsLabels = {
  hasNoIncome: { label: 'The sponsor does not have any income.' },
  incomeNotProvided: {
    label: `The sponsor's income was not provided.`,
  },
  hasNoAssets: {
    label: 'The sponsor does not have any assets.',
  },
  assetsNotProvided: {
    label: `The sponsor's assets were not provided.`,
  },
  // hasIncomeAssets: {
  //   label:
  //     'You have not shared any financial information for the sponsor. Does the sponsor have any of the above source of income or assets?',
  // },
  // canProvideBankStatement: {
  //   label: 'Are you able to provide the sponsor bank accounts/statements?',
  // },
  knowBankLocation: {
    label: 'Can you provide this banking information?',
  },
  noIncomeAssets: {
    label: 'No information regarding the income or assets of the sponsor has been shared.',
  },
};
