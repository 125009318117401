import { Component } from '@angular/core';

import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  selector: 'common-undo-toaster-component',
  styleUrls: ['./undo-toaster.component.scss'],
  templateUrl: `./undo-toaster.component.html`,
  preserveWhitespaces: false,
})
export class UndoToasterComponent extends Toast {
  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  undo() {
    this.toastPackage.triggerAction('undo');
    return true;
  }
}
