import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FiServicesLabels } from '@common/labels';
import { FiServices, ModifiedYesNoOptionsUnsureYes, ServiceDurationOptions } from '@fscd-intake/entities';
import { BaseStepComponent } from '../base-step/base-step.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fi-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent extends BaseStepComponent implements OnInit, OnDestroy {
  formChangeSubscription;
  fiServicesLabels = FiServicesLabels;
  customRadioOptions = ModifiedYesNoOptionsUnsureYes;
  hasOptedForService = false;
  serviceDurationOptions = ServiceDurationOptions;
  characterCountAdditionalServicesDetail = 0;
  

  /* child with NO confirmed diagnosis can only avail services upto 2 years */
  modifiedServiceDurationOptions = {
    ...Object.fromEntries(Object.entries(ServiceDurationOptions).slice(0, 2)),
  };

  @Input() applicationId: string;

  _services: FiServices;
  @Input() set services(value: FiServices) {
    if (value) {
      this._services = value;
      this.setFormValue();
    }
  }

  @Input() hasConfirmedDiagnosis: boolean;

  @Input() set hasErrors(value: boolean) {
    if (value) {
      this.formGroup.markAllAsTouched();
      this.cdr.detectChanges();
    }
  }

  @Output() formError = new EventEmitter();
  @Output() formUpdated = new EventEmitter();

  formGroup = this.fb.group({
    homeRespite: ['', Validators.required],
    medicalAppointment: ['', Validators.required],
    counselling: ['', Validators.required],
    clothingFootwear: ['', Validators.required],
    agreementDuration: ['', Validators.required],
   
  });

  constructor(fb: FormBuilder, private cdr: ChangeDetectorRef, public route: ActivatedRoute) {
    super(route, fb);
  }

  ngOnInit(): void {
    this.formChangeSubscription = this.formGroup.valueChanges.subscribe((val) => {
      this.formUpdated.emit(val);
    });
    
  }

  setFormValue() {
    if (this.formGroup && this._services) {
      this.formGroup.patchValue({
        homeRespite: this._services.homeRespite,
        medicalAppointment: this._services.medicalAppointment,
        counselling: this._services.counselling,
        clothingFootwear: this._services.clothingFootwear,
        agreementDuration: this._services.agreementDuration,
      });
      this.hasOptedForAnyService();
    }
  }

  hasOptedForAnyService() {
    const serviceValues = Object.values({ ...this.formGroup.value, additionalServices: '' });
    const hasOptedForService = serviceValues.some(
      (val) => ModifiedYesNoOptionsUnsureYes[val]?.evaluatesToTrue === true
    );
    this.hasOptedForService = hasOptedForService;
    const controlExists = this.formGroup.get('agreementDuration');
    if (hasOptedForService) {
      if (!controlExists) {
        this.addControlToForm(this.formGroup, 'agreementDuration', true);
        this.formGroup.get('agreementDuration').setValidators([Validators.required]);
        if (this._services?.agreementDuration) {
          this.formGroup.patchValue({
            agreementDuration: this._services.agreementDuration,
          });
        }
      }
    } else {
      if (controlExists) this.removeControlFromForm(this.formGroup, 'agreementDuration');
    }
    this.formGroup.updateValueAndValidity();

    return hasOptedForService;
  }

  ngOnDestroy(): void {
    this.formChangeSubscription.unsubscribe();
   
  }

  unsort() {
    return 0;
  }

 

 
}
