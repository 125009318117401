import { NoYesScaleWithNa, OneToFiveScaleWithNA } from './assessment-scales';
import { SkillsLookup } from './skills-lookup.interface';
export const WorkSearchSkills: { [name: string]: SkillsLookup } = {
  hasAResume: {
    displayValue: 'Has a resume',
    scale: NoYesScaleWithNa,
    helperText:
      'Resumes should be visually appealing <i>and</i> accurately convey one’s skills, strengths, and accomplishments to convince an employer to grant the writer an interview.',
    sampleQuestions: [
      {
        question: 'Do you presently have a resume?',
        followupQuestions: [{ question: 'When did you last update it?' }, { question: 'When did you last use it?' }],
      },
      {
        question: 'What are the key skills, background, and experience required in the type of work you are seeking?',
        followupQuestions: [
          { question: 'How do your skills, background, and experience relate?' },
          { question: 'How are these key areas represented in your resume?' },
        ],
      },
      {
        question:
          'Tell me about some of your accomplishments in your previous work. (Identify skills and knowledge demonstrated in the accomplishments and give this information back to the individual)',
        followupQuestions: [{ question: 'How might you include these skills in your resume?' }],
      },
    ],
  },
  interview: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Interview',
    helperText:
      'The interview skills factor involves having an understanding of current interviewing practices and the ability to prepare effectively for an interview.',
    sampleQuestions: [
      {
        question: 'Tell me about some of the work that you have done previously, both paid and volunteer.',
        followupQuestions: [
          { question: 'What were the three most important skills that you needed in each of those positions?' },
        ],
      },

      {
        question: 'What are the top five skills that you bring to an employer?',
      },

      {
        question: 'What positions have you recently applied for?',
        followupQuestions: [
          { question: 'What appeals to you about the work involved in each?' },
          { question: 'What skills do you anticipate are needed for each position?' },
          { question: 'Explain how your skills relate to those needed in these positions.' },
        ],
      },

      {
        question:
          'Tell me about a time that you felt good about something that you accomplished at work, either paid or volunteer. (Identify skills and knowledge demonstrated in the accomplishments and give this information back to the individual).',
        followupQuestions: [
          {
            question: 'How might you use these skills and knowledge areas in the positions that you are applying for?',
          },
        ],
      },

      {
        question: 'Are there any areas you anticipate might be problematic in an interview?',
        followupQuestions: [{ question: 'How might you prepare to handle those most effectively?' }],
      },

      {
        question: 'How do you usually prepare for interviews?',
        followupQuestions: [
          { question: 'What has worked well for you in that approach?' },
          { question: 'What, if anything, might you want to change to enhance your interview preparation?' },
        ],
      },
    ],
  },
  personalPresentation: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Personal presentation',
    helperText:
      'Personal presentation skills involve the ability to present one’s self appropriately for training, interview, and employment situations.',
    sampleQuestions: [
      {
        question: 'Tell me about the type of work you are hoping to gain.',
        followupQuestions: [
          { question: 'Describe someone who might be successful in such work, including their skills and strengths.' },
          { question: 'How do you and your strengths and skills relate to your description?' },
        ],
      },
      {
        question: 'If you asked three different friends or colleagues to describe you, what might they say about you?',
      },
      {
        question: 'What is your strongest point in terms of your personal presentation?',
      },
    ],
  },
  networking: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Networking',
    helperText:
      'Networking skills provide an organized way to make links from the people an individual knows to the people they know, resulting in a “net” of personal contacts who can provide support and information.',
    sampleQuestions: [
      {
        question: 'Tell me what the term networking means to you.',
      },

      {
        question: 'Rate your networking skills on a scale of 1-5.',
        followupQuestions: [{ question: 'How might you move your skills up one notch?' }],
      },

      {
        question: 'How might the skills of networking fit into your career planning research?',
        followupQuestions: [{ question: 'You work search?' }],
      },
      {
        question:
          'List ten people that you can think of that you could talk to about the type of work you are looking for.',
        followupQuestions: [{ question: 'What might you say to them about you and your qualifications?' }],
      },
    ],
  },
  workReferences: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Work references',
    helperText:
      'Work references are former and/or current employers who would be willing and able to speak to an individual’s experience, skills, accomplishments, and work ethic.',
    sampleQuestions: [
      {
        question: 'List three work references that you can provide to a potential employer.',
        followupQuestions: [
          { question: 'What might each of these references say about you and your skills and strengths?' },
        ],
      },
      {
        question:
          'List five of the most important skills and/or strengths that will be required for the type of work you are targeting.',
        followupQuestions: [
          { question: 'What jobs have you held that have involved these skills and strengths?' },
          { question: 'Which of your work references will be best suited to recommend your abilities in these areas?' },
        ],
      },
      {
        question: 'Do you have any concerns about providing work references and what they might say?',
        followupQuestions: [
          { question: 'What are your concerns?' },
          { question: 'How might you address these concerns?' },
        ],
      },
    ],
  },
  jobSearchAndFinding: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Job seeking and finding techniques',
    helperText:
      'Job seeking and finding techniques involve the understanding of and ability to use various work search methods.',
    sampleQuestions: [
      {
        question: 'What have you done so far in your work search?',
      },
      {
        question: 'Describe the type of work you are looking for.',
        followupQuestions: [
          { question: 'What has led you to focus on that particular area of work?' },
          { question: 'What is it that appeals to you in this type of work?' },
        ],
      },
      {
        question: 'How are you feeling about your work search?',
        followupQuestions: [
          {
            question:
              'What kinds of things might you do to feel better, if you get discouraged or down with your job search?',
          },
        ],
      },
      {
        question: 'What has worked for you before to find a job?',
      },
    ],
  },
  labourMarketKnowledge: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Labour market knowledge',
    helperText:
      'Labour market knowledge involves knowing and understanding information about the world of work, including information about different types of work and learning opportunities. It also includes being aware of labour market trends and forecasts about future employment growth.',
    sampleQuestions: [
      {
        question: 'What do you understand by the term, labour market information?',
      },
      { question: 'What are some of your best ways to pick up labour market information?' },
      {
        question:
          'Tell me about a time that you used labour market information in a way that was helpful to you in making a career decision.',
      },
      { question: 'How might you use labour market information to access the hidden job market?' },
      {
        question:
          'What labour market information are you aware of that might impact the type of work you are considering?',
        followupQuestions: [
          {
            question:
              'Given your understanding of the labour market at this point, what information seems to support the viability of the type of work you are targeting?',
          },
          {
            question:
              'Are there any downsides to this type of work that you can predict, given your understanding of the labour market, at this point?',
          },
        ],
      },
    ],
  },
};
