import { HttpHandler, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utilities } from '@common/utils';
import { tap } from 'rxjs/operators';
@Injectable()
export class DateInterceptor implements HttpInterceptor {
  intercept(req, next: HttpHandler) {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          const body = event.body;
          Utilities.deserializeToDates(body);
        }
      })
    );
  }
}
