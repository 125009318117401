import { AssetLookup } from './asset-lookup.interface.';

export const AdditionalAssetTypes: { [name: string]: AssetLookup } = {
  vehicle: {
    // code: 'vehicle',
    order: 1,
    displayValue: 'Vehicles',
    tips:
      'While it is optional, you can choose to include supporting documents to prove the value of your vehicle(s) as it may be requested by a worker later in the application process',
    documentTypes: ['Vehicle registration', 'Bill of sale'],
    subTypes: {
      vehicle: {
        displayValue: 'Vehicle',
      },
      vehicleDisability: {
        displayValue: 'Vehicle adapted for a disability',
      },
    },
    isOptional: true,
  },
  recreationalVehicle: {
    // code: 'recreationalVehicle',
    order: 2,
    tips:
      'While it is optional, you can choose to include supporting documents to prove the value of recreational vehicles as it may be requested by a worker later in the application process',
    displayValue: 'Recreational vehicles',
    documentTypes: ['Vehicle registration', 'Bill of sale'],
    subTypes: {
      recreationalVehicle: {
        displayValue: 'Recreational vehicle',
      },
    },
    isOptional: true,
  },
  farm: {
    // code: 'farm',
    order: 4,
    displayValue: 'Farms',
    documentTypes: ['Most recent property tax assessment', 'Mortgage documents'],
    subTypes: {
      farmValue: {
        displayValue: 'Current value of farm assets',
      },
      landValue: {
        displayValue: 'Current value of land other than the home quarter section',
      },
    },
    isOptional: true,
  },
  business: {
    // code: 'business',
    displayValue: 'Businesses',
    order: 5,
    tips: 'Business assets or property, such as vehicles and equipment.',
    documentTypes: [
      'Most recent property tax assessment',
      'Mortgage documents',
      'Tax notice of assessment',
      'Business income tax return accounting statement/balance sheet',
      'Business asset insurance',
    ],
    subTypes: {
      businessValue: {
        displayValue: 'Current value of business',
      },
    },
    isOptional: false,
  },
  property: {
    // code: 'property',
    order: 3,
    tips:
      'While it is optional, you can choose to include supporting documents to prove the value of your property as it may be requested by a worker later in the application process',
    displayValue: 'Properties',
    documentTypes: ['Most recent property tax assessment', 'Mortgage documents'],
    subTypes: {
      homePrincipleResidence: {
        displayValue: 'Home/principle residence',
      },
      recreationProperty: {
        displayValue: 'Recreation property',
      },
      rentalProperty: {
        displayValue: 'Rental property',
      },
    },
    isOptional: true,
  },
};
