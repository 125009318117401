<div>
  <mat-accordion>
    <mat-expansion-panel [expanded]="tabIndex === 0" (opened)="onOpen(0)" (closed)="onClose(0)">
      <mat-expansion-panel-header #submittedTab>
        <mat-panel-title>Submitted application(s)</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="no-app" *ngIf="submittedList?.length === 0">
        <p>No Applications Found</p>
      </div>

      <div class="mt-24" *ngIf="submittedList?.length > 0">
        <goa-callout type="important"> {{ submittedAppsCalloutText }} </goa-callout>
      </div>

      <div
        *ngFor="let app of submittedList; let $index = index"
        class="card"
        [ngClass]="{ last: isLast(submittedList, $index) }"
      >
        <h1>{{ app?.childName || '-' }}</h1>
        <h2>Application ID - {{ app?.applicationId }}</h2>
        <div class="font-color">
          Status <b>{{ app?.status || '-' }}</b>
        </div>
        <div class="mb-24 font-color">
          Submitted <b>{{ app?.dateSubmitted | date: 'longDate' }}</b>
        </div>
        <div class="mb-24">
          <goa-button
            type="primary"
            (_click)="onClickSubmittedView(app)"
            [attr.data-cy]="dataCy('view-' + app?.applicationId)"
            >View</goa-button
          >
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="tabIndex === 1" (opened)="onOpen(1)" (closed)="onClose(1)">
      <mat-expansion-panel-header #draftTab>
        <mat-panel-title>Draft application(s)</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="no-app" *ngIf="draftList?.length === 0">
        <p>No Applications Found</p>
      </div>
      <div class="mt-24" *ngIf="draftList?.length > 0">
        <goa-callout type="important"> {{ draftAppsCalloutText }} </goa-callout>
      </div>

      <div
        *ngFor="let app of draftList; let $index = index"
        class="card"
        [ngClass]="{ last: isLast(draftList, $index) }"
      >
        <h1>{{ app?.childName || '-' }}</h1>
        <div class="font-color">
          Date started <b>{{ app?.dateStarted | date: 'longDate' }}</b>
        </div>
        <div class="mb-24 font-color">
          Date last saved <b>{{ app?.dateLastSaved | date: 'longDate' }}</b>
        </div>
        <div class="mb-24">
          <goa-button
            type="primary"
            (_click)="onClickDraftContinue(app)"
            [attr.data-cy]="dataCy('continue-' + app?.applicationId)"
          >
            Continue
          </goa-button>
        </div>
        <div class="mb-24">
          <goa-button
            type="secondary"
            (_click)="onClickDraftRemove(app)"
            [attr.data-cy]="dataCy('remove-' + app?.applicationId)"
          >
            Remove
          </goa-button>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="tabIndex === 2" (opened)="onOpen(2)" (closed)="onClose(2)">
      <mat-expansion-panel-header #removedTab>
        <mat-panel-title>Recently removed application(s)</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="no-app" *ngIf="removedList?.length === 0">
        <p>No Applications Found</p>
      </div>
      <div class="mt-24" *ngIf="removedList?.length > 0">
        <goa-callout type="important"> {{ removedAppsCalloutText }} </goa-callout>
      </div>

      <div
        *ngFor="let app of removedList; let $index = index"
        class="card"
        [ngClass]="{ last: isLast(removedList, $index) }"
      >
        <h1>{{ app?.childName || '-' }}</h1>
        <h2 class="mb-12">Application ID - {{ app?.applicationId }}</h2>
        <div class="font-color">
          Date started <b>{{ app?.dateStarted | date: 'longDate' }}</b>
        </div>
        <div class="font-color">
          Date last saved <b>{{ app?.dateLastSaved | date: 'longDate' }}</b>
        </div>
        <div class="mb-24 font-color">
          Date removed <b>{{ app?.dateRemoved | date: 'longDate' }}</b>
        </div>
        <div class="mb-24">
          <goa-button
            type="primary"
            (_click)="onClickRecover(app)"
            [attr.data-cy]="dataCy('restore-' + app?.applicationId)"
            >Restore</goa-button
          >
        </div>
        <div class="mt-24">
          <goa-button
            type="secondary"
            (_click)="onClickDelete(app)"
            [attr.data-cy]="dataCy('delete-' + app?.applicationId)"
            >Delete</goa-button
          >
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
