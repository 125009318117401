import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UPLOAD_IN_PROGRESS } from '@common/constants';
import { ConfirmDialogComponent } from '@common/ui/shared-components';
import { lastValueFrom } from 'rxjs';
import { DashboardListApplication } from '../../shared/interfaces/dashboard-list-application';
interface ComponentStorage {
  key: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

@Component({
  selector: 'fi-base-step',
  template: '<div>Dont need a template!</div>',
})
export class BaseStepComponent {
  storeComponentData = Array<ComponentStorage>();
  @Output() blockSaveMessage = new EventEmitter<string>();
  @Output() removeApplication = new EventEmitter<DashboardListApplication>();
  @Output() recoverApplication = new EventEmitter<DashboardListApplication>();
  @Output() deleteApplication = new EventEmitter<DashboardListApplication>();

  constructor(public route: ActivatedRoute, public fb: FormBuilder, private matDialog?: MatDialog) {}

  //Used to pull component data from components that were removed from the parent FormGroup
  //retrieved by field name.
  private getPersistedData(uniqueId: string) {
    const componentItem = this.storeComponentData.find((i) => i.key === uniqueId);
    let existingdata = null;
    if (componentItem && componentItem !== null) {
      existingdata = componentItem.data;
    }
    return existingdata;
  }

  //removes control and also stores it into the ComponentStorage collection to be accessed if we add the control back in
  //use 'controlName' as the name of the control you are removing
  removeControlFromForm(formGroup: UntypedFormGroup, controlName: string, useNullValue = false) {
    let componentItem = this.storeComponentData.find((i) => i.key === controlName);
    if (!componentItem) {
      this.storeComponentData.push({ key: controlName, data: null });
      componentItem = this.storeComponentData.find((i) => i.key === controlName);
    }
    if (formGroup.controls[controlName]) {
      componentItem.data = formGroup.controls[controlName].value;
      if (useNullValue === true) {
        formGroup.controls[controlName].setValue(null);
      } else {
        formGroup.removeControl(controlName);
      }
    }
  }

  //adds control into the form.
  //use 'controlName' as the name of the control you are adding
  //use 'persistData' and set to true if you want to re-populate the control value with what was stored in the ComponentStorage collection before it was removed
  //use 'value' (optional) parameter to set the value of the control manually
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addControlToForm(formGroup: UntypedFormGroup, controlName: string, persistData?: boolean, value?: any) {
    let data = null;
    if (persistData === true) {
      data = this.getPersistedData(controlName);
    } else if (value) {
      data = value;
    }
    if (!formGroup.controls[controlName]) {
      formGroup.addControl(controlName, this.fb.control(data));
    } else if (data) {
      //if control exists, just set value
      formGroup.controls[controlName].setValue(data);
    }
  }

  uploadStarted() {
    this.blockSaveMessage.emit(UPLOAD_IN_PROGRESS);
  }

  uploadCompleted() {
    this.blockSaveMessage.emit(null);
  }

  async removeAppDialog(application: DashboardListApplication) {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      autoFocus: false,
      data: {
        title: 'Remove application?',
        message: `This application will be moved to the <b>'Recently removed' tab</b>. Applications are permanently deleted after 40 days.`,
        confirmLabel: 'Remove',
        cancelLabel: 'Cancel',
      },
      panelClass: 'modal-container',
    });

    const isDialogConfirmed = await lastValueFrom(dialogRef.afterClosed());
    if (isDialogConfirmed) {
      this.removeApplication.emit(application);
    } else {
      return false;
    }
  }

  async deleteAppDialog(application: DashboardListApplication) {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      autoFocus: false,
      data: {
        title: 'Permanently delete application?',
        message: `This action will permanently delete the application for ${application.childName}.\nAre you sure you want to delete this application?`,
        confirmLabel: 'Yes, delete it',
        cancelLabel: 'Cancel',
        destructiveConfirm: true,
      },
      panelClass: 'modal-container',
    });

    const isDialogConfirmed = await lastValueFrom(dialogRef.afterClosed());
    if (isDialogConfirmed) {
      this.deleteApplication.emit(application);
    } else {
      return false;
    }
  }
}
