<div (click)="onLookupClick($event)">
  <common-form-control [control]="addressLookupControl" label="Address lookup">
    <input
      type="text"
      [formControl]="addressLookupControl"
      [attr.data-cy]="dataCyPrefix + '-address-lookup'"
      autocomplete="address-lookup"
      placeholder="Search by street address or city/town"
    />

    <div
      *ngIf="!hideLookupResult && addressLookupControl.value?.length >= CharactersStartToLookup"
      class="address-looup-list-container"
    >
      <mat-selection-list
        (selectionChange)="onSelectAddress($event.options[0].value)"
        class="address-looup-list"
        [multiple]="false"
        [attr.data-cy]="dataCyPrefix + '-lookup-list'"
      >
        <ng-container *ngIf="suggestedAddresses?.length">
          <mat-list-option *ngFor="let address of suggestedAddresses" [value]="address">
            {{ address.Text }} {{ address.Description }}
          </mat-list-option>
        </ng-container>
        <ng-container *ngIf="!isLookingup && !suggestedAddresses?.length">
          <mat-list-option>No matching address found. </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </div>
  </common-form-control>
</div>
