import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const IesDependentLabels: IntakeLabelCollection = {
  firstName: {
    label: "Child's first name",
  },
  middleName: {
    label: "Child's middle name",
  },
  lastName: {
    label: "Child's last name",
  },
  firstNameAlt: {
    label: 'First name',
  },
  middleNameAlt: {
    label: 'Middle name',
  },
  lastNameAlt: {
    label: 'Last name',
  },
  birthdate: {
    label: 'Birthdate',
  },
  gender: {
    label: 'Gender',
  },
  immigrationStatus: {
    label: 'Citizenship / Immigration status',
  },
  immigrationStatusSponsored: {
    label: 'Is your child sponsored?',
  },
  livesWithParent: {
    label: 'Does the child live with you?',
  },
  attendingHighSchool: {
    label: 'Is the child attending Grades K-12?',
  },
  hasIndianRegistration: {
    label: 'Does the child have an Indian Registration Number?',
  },
  biologicalChild: {
    spousePartnerLabel:
      'Are you and your <<spouseLabel>> the biological/adoptive parents of all of the child(ren) listed above?',
    label: '',
  },
};
