import { Lookup } from './lookup.interface';

export interface FbImmigrationTypesLookup extends Lookup {
  description?: string;
  isCanadian?: boolean;
  isUnknown?: boolean;
  isNotListed?: boolean;
  enablesSponsor?: boolean;
}

export const FbImmigrationTypes: { [name: string]: FbImmigrationTypesLookup } = {
  canadian: {
    code: 'canadian',
    displayValue: 'Canadian citizen',
    description:
      'A person born in Canada, or those who obtained Canadian citizenship through the immigration process, or have Canadian citizenship under Canadian law',
    isCanadian: true,
    enablesSponsor: true,
  },
  permanentResident: {
    code: 'permanentResident',
    displayValue: 'Permanent resident',
    description:
      'A person who has legally immigrated to Canada, and meets residency requirements, but is not a Canadian citizen',
    enablesSponsor: true,
  },
  temporaryResident: {
    code: 'temporaryResident',
    displayValue: 'Temporary resident',
    description: 'A person who is a foreign national granted the right to stay in Canada for a certain length of time',
    enablesSponsor: true,
  },
  refugeeClaimant: {
    code: 'refugeeClaimant',
    displayValue: 'Refugee or refugee claimant',
    description:
      'A person who has applied for refugee protection status while in Canada and is waiting for a decision on his/her claim from the Immigration and Refugee Board of Canada; who is not receiving financial assistance from the Government of Canada',
    enablesSponsor: true,
  },
  victimHumanTrafficking: {
    code: 'immigrant',
    displayValue: 'Victim of human trafficking',
    description:
      'Human trafficking involves recruiting, moving, or holding victims to exploit them for profit, usually for sexual reasons or forced labour',
    enablesSponsor: true,
  },
  notListed: {
    code: 'notListed',
    displayValue: 'No status in Canada',
    description:
      "A person who is known as 'undocumented' or 'non-status' does not have a legal status of immigration in Canada on a temporary or permanent basis. This can happen when a person stays in Canada even after a visa or study or work permit expires, or when a refugee claim has been denied",
    isNotListed: true,
  },
  unknown: {
    code: 'unknown',
    displayValue: 'Unknown',
    isUnknown: true,
  },
};
