<div class="app">
  <div class="app-left">
    <div class="app-field" data-cy="firstName">
      <h2>{{ labels.firstName | labelText }}</h2>
      <p>{{ application?.child?.firstName || '-' }}</p>
    </div>
    <div class="app-field" data-cy="middleName">
      <h2>{{ labels.middleName | labelText }}</h2>
      <p>{{ application?.child?.middleName || '-' }}</p>
    </div>
    <div class="app-field" data-cy="lastName">
      <h2>{{ labels.lastName | labelText }}</h2>
      <p>{{ application?.child?.lastName || '-' }}</p>
    </div>
    <div class="app-field" data-cy="preferredName">
      <h2>{{ labels.preferredName | labelText }}</h2>
      <p>{{ application?.child?.preferredName || '-' }}</p>
    </div>
    <div class="app-field" data-cy="birthdate">
      <h2>{{ labels.birthdate | labelText }}</h2>
      <p>{{ application?.child?.birthdate | date: 'longDate' }}</p>
    </div>
    <div class="app-field" data-cy="gender">
      <h2>{{ labels.gender | labelText }}</h2>
      <p>{{ genderList[application?.child?.gender]?.displayValue || '-' }}</p>
    </div>
    <div class="app-field" data-cy="hasPrevApplication">
      <h2>{{ labels.hasPrevApplication | labelText }}</h2>
      <p>{{ utility.displayBoolean(application?.child?.hasPrevApplication) || '-' }}</p>
    </div>
    <div *ngIf="application?.child?.hasPrevApplication" class="app-field" data-cy="hasPrevClosedWithin90">
      <h2>{{ labels.hasPrevClosedWithin90 | labelText }}</h2>
      <p>{{ customRadioOptions[application?.child?.hasPrevClosedWithin90]?.displayValue || '-' }}</p>
    </div>
    <div class="app-field" data-cy="citizenImmigrationStatus">
      <h2>{{ labels.isChildCitizenOrPermanentResident | labelText }}</h2>
      <p>{{ legalStatusList[application?.child?.citizenImmigrationStatus]?.displayValue || '-' }}</p>
    </div>
    <div class="app-field" data-cy="streetAddress">
      <h2>{{ labels.street | labelText }}</h2>
      <p>{{ application?.child?.residentialAddress?.streetAddress || '-' }}</p>
    </div>
    <div class="app-field" data-cy="suiteNumber">
      <h2>{{ labels.suite | labelText }}</h2>
      <p>{{ application?.child?.residentialAddress?.suiteNumber || '-' }}</p>
    </div>
    <div class="app-field" data-cy="city">
      <h2>{{ labels.city | labelText }}</h2>
      <p>{{ application?.child?.residentialAddress?.city || '-' }}</p>
    </div>
    <div class="app-field-container-horizontal">
      <div class="app-field" data-cy="province">
        <h2>{{ labels.province | labelText }}</h2>
        <p>{{ application?.child?.residentialAddress?.province || '-' }}</p>
      </div>
      <div class="app-field" data-cy="postalCode">
        <h2>{{ labels.postalCode | labelText }}</h2>
        <p>{{ (application?.child?.residentialAddress?.postalCode | postalCode) || '-' }}</p>
      </div>
    </div>
  </div>
  <!-- END class="app-left" -->

  <div class="app-right">
    <div class="app-field" data-cy="hasSameMailingAddress">
      <h2>{{ labels.hasSameMailingAddress | labelText }}</h2>
      <p>{{ utility.displayBoolean(application?.child?.hasSameMailingAddress) || '-' }}</p>
    </div>
    <div *ngIf="!application?.child?.hasSameMailingAddress" class="ml-60">
      <div class="app-field" data-cy="mailing-streetAddress">
        <h2>{{ labels.street | labelText }}</h2>
        <p>{{ application?.child?.mailingAddress?.streetAddress || '-' }}</p>
      </div>
      <div class="app-field" data-cy="mailing-suiteNumber">
        <h2>{{ labels.suite | labelText }}</h2>
        <p>{{ application?.child?.mailingAddress?.suiteNumber || '-' }}</p>
      </div>
      <div class="app-field" data-cy="mailing-city">
        <h2>{{ labels.city | labelText }}</h2>
        <p>{{ application?.child?.mailingAddress?.city || '-' }}</p>
      </div>
      <div class="app-field-container-horizontal">
        <div class="app-field" data-cy="mailing-province">
          <h2>{{ labels.province | labelText }}</h2>
          <p>{{ application?.child?.mailingAddress?.province || '-' }}</p>
        </div>
        <div class="app-field" data-cy="mailing-postalCode">
          <h2>{{ labels.postalCode | labelText }}</h2>
          <p>{{ (application?.child?.mailingAddress?.postalCode | postalCode) || '-' }}</p>
        </div>
      </div>
    </div>
    <div class="app-field" data-cy="livingArrangements">
      <h2>{{ labels.livingArrangements | labelText }}</h2>
      <p>{{ application?.child?.livingArrangements || '-' }}</p>
    </div>
    <div class="app-field" data-cy="hasConfirmedDiagnosis">
      <h2>{{ labels.hasConfirmedDiagnosis | labelText }}</h2>
      <p>{{ utility.displayBoolean(application?.child?.hasConfirmedDiagnosis) || '-' }}</p>
      <p *ngIf="application?.child?.hasConfirmedDiagnosis">
        {{ application?.child?.confirmedDiagnosis || '-' }}
      </p>
    </div>
    <div class="app-field" data-cy="isAwaitingDiagnosis">
      <h2>{{ labels.isAwaitingDiagnosis | labelText }}</h2>
      <p>{{ utility.displayBoolean(application?.child?.isAwaitingDiagnosis) || '-' }}</p>
      <p *ngIf="application?.child?.isAwaitingDiagnosis">
        {{ application?.child?.waitingDiagnosis || '-' }}
      </p>
    </div>
  </div>
  <!-- END class="app-right" -->
</div>
<!-- END class="app" -->

<div class="app-docs mt-30">
  <h1>{{ application?.child?.firstName || 'child' }}'s documents</h1>
  <p class="mt-24 mb-15"><strong>Citizenship/immigration documents</strong></p>
  <p *ngIf="!identityDocArr.length || identityDocArr.length === 0">None</p>

  <div *ngIf="identityDocArr.length > 0" class="identity-table">
    <div *ngFor="let identityDoc of identityDocArr" class="row-container">
      <p *ngIf="!identityDoc.isView">{{ identityDoc.str }}</p>
      <a *ngIf="identityDoc.isView" class="row-view" (click)="onClickRowView(identityDoc.doc)">
        <fi-eye-icon height="22"></fi-eye-icon>
        <p>View</p>
      </a>
    </div>
  </div>

  <p class="mt-15 mb-15"><strong>Medical documents</strong></p>
  <p *ngIf="!medicalDocArr.length || medicalDocArr.length === 0">None</p>
  <div *ngIf="medicalDocArr.length > 0" class="identity-table">
    <div *ngFor="let medicalDoc of medicalDocArr" class="row-container">
      <p *ngIf="!medicalDoc.isView">{{ medicalDoc.str }}</p>
      <a *ngIf="medicalDoc.isView" class="row-view" (click)="onClickRowView(medicalDoc.doc)">
        <fi-eye-icon height="22"></fi-eye-icon>
        <p>View</p>
      </a>
    </div>
  </div>

  <p class="mt-15 mb-15"><strong>Additional documents</strong></p>
  <p *ngIf="!additionalDocArr.length">None</p>
  <div *ngIf="additionalDocArr.length" class="identity-table">
    <div *ngFor="let additionalDoc of additionalDocArr" class="row-container">
      <p *ngIf="!additionalDoc.isView">{{ additionalDoc.str }}</p>
      <a *ngIf="additionalDoc.isView" class="row-view" (click)="onClickRowView(additionalDoc.doc)">
        <fi-eye-icon height="22"></fi-eye-icon>
        <p>View</p>
      </a>
    </div>
  </div>
</div>
<!-- END class="app-docs" -->
