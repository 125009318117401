import * as Quill from 'quill';
const QuillType: any = Quill;
import { QuillMSOClipboard } from './quill-mso-clipboard/quill-mso-clipboard';

/**
 * Override the Quill clipboard module with the MSO Clipboard.
 * This offers improved handling of pastes from MS Word App and Web Editor.
 */
export const registerQuillMSOClipboard = () => {
  QuillType.register('modules/clipboard', QuillMSOClipboard, true);
};
