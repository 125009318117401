import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'password-generator',
  templateUrl: './password-generator.component.html',
  styleUrls: ['./password-generator.component.scss'],
})
export class PasswordGeneratorComponent {
  @ViewChild('tooltip') tooltip: MatTooltip;

  @Input() length = 8;
  @Input() alphabet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()_-+=.?';
  //let's remove some not-so-common characters from generation, makes it easier for communicating the password '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ~`!@#$%^&*()_-+={[}]|\\:;"\'<,>.?/';
  @Input() validationPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ ~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/])[A-Za-z\d ~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]{8,}$/;

  @Output() passwordGenerated = new EventEmitter<string>();

  onClick() {
    let pwd = '';
    while (!this.validationPattern.test(pwd)) {
      const arr = new Array(this.length).fill(0);
      const rnd = new Uint32Array(this.length);
      crypto.getRandomValues(rnd);
      pwd = arr.map((v, i) => this.alphabet[rnd[i] % this.alphabet.length]).join('');
    }

    this.passwordGenerated.emit(pwd);
  }
}
