import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
export class CheckBoxValidator {
  static maxChecked(max: number): ValidatorFn {
    return function (formGroup: UntypedFormGroup) {
      let checkedCount = 0;
      Object.keys(formGroup.controls).forEach((key) => {
        const checkBox = formGroup.controls[key];
        if (checkBox.value) {
          checkedCount++;
        }
      });
      return checkedCount <= max ? null : { allowedMax: max };
    };
  }

  static atLeastOneChecked(
    minRequired = 1
  ): ValidatorFn {
    return function validate(formGroup: UntypedFormGroup) {
      let checked = 0
  
      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key]
  
        if (control.value) {
          checked++
        }
      })
  
      if (checked < minRequired) {
        return {
          requireCheckboxToBeChecked: true,
        }
      }
  
      return null
    }
  }
}
