import { OneToFiveScaleWithNA } from './assessment-scales';
import { SkillsLookup } from './skills-lookup.interface';

export const EssentialSkills: { [name: string]: SkillsLookup } = {
  readingText: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Reading text',
    helperText:
      'Reading text involves the ability to read, comprehend, and use written materials including graphs, charts, and displays. Functional literacy is the ability to use written or numerical information in daily activities such as reading and understanding the correct dosage on a prescription, completing an application form, or finding a specific listing in the telephone directory.',
    sampleQuestions: [
      { question: 'Tell me about your educational background.' },
      { question: 'What types of reading appeal to you?' },
      { question: 'Tell me about the reading and writing that you were required to do in your previous work.' },
      { question: 'How comfortable is it for you to answer these questions in English?' },
    ],
  },
  numeracy: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Numeracy',
    helperText:
      'Numeracy involves the ability to demonstrate skills in working with numbers and mathematics. Skill may be reflected in the ability to use numbers to perform calculations and estimations and to complete tasks such as handling cash, budgeting, measuring, and analyzing.',
    sampleQuestions: [
      { question: 'How would you rate yourself in terms of your ability to work with numbers?' },
      { question: 'What experience have you had where you worked with numbers?' },
      {
        question:
          'How do you like working with numbers and math - statistics and measurements and other areas that require those skills?',
      },
    ],
  },
  oralCommunication: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Oral communication',
    helperText:
      'Oral communication skills involve the use of speech to give and exchange thoughts and information. They include the ability to listen for understanding and to speak the language in which business is conducted.',
    sampleQuestions: [
      {
        question:
          'On a scale of 1-5, how would you rate your ability to communication with co-workers? Supervisors? Customers?',
        followupQuestions: [{ question: 'What might you do to move that rating up a notch?' }],
      },
      { question: 'Tell me about a time that you used your oral communication skills successfully.' },
      {
        question:
          'What feedback have you received regarding your communication skills, either on the job, while volunteering, or in your everyday life?',
      },
      {
        question:
          'Describe the way you might be required to use your oral communication skills in the work that you are seeking.',
        followupQuestions: [{ question: 'How do you think your skills relate to those required?' }],
      },
    ],
  },
  thinkingSkills: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Thinking skills',
    helperText:
      'Thinking skills include knowing how to problem solve, make decisions, plan and organize tasks, find information, and make effective use of memory.',
    sampleQuestions: [
      { question: 'Tell me about a time that you had to solve a problem and how you went about solving it.' },
      { question: 'Give me an example of a decision that you have made and tell me how you made it.' },
      {
        question: 'Tell me about your school experience.',
        followupQuestions: [
          { question: 'What are some of the things that you do well when it comes to learning and studying?' },
          { question: 'What do you find challenging when learning new things?' },
        ],
      },
    ],
  },
  continuousLearning: {
    scale: OneToFiveScaleWithNA,
    displayValue: ' Continuous learning',
    helperText:
      'Continuous learning involves continuing to acquire and build knowledge and skills on an ongoing and lifelong basis.',
    sampleQuestions: [
      {
        question: 'Tell me about something that you have recently learned.',
        followupQuestions: [{ question: 'How did you learn it?' }],
      },
      {
        question: 'What have you done recently that is new or different for you?',
        followupQuestions: [{ question: 'What new learning was involved in this experience?' }],
      },
      { question: 'Tell me about some goals you have for the next 1-2 years.' },
      { question: 'What might you need to learn to accomplish your goals?' },
      { question: 'How might you go about learning these new skills or getting this knowledge?' },
      {
        question: 'What kinds of support have been helpful to you in learning new things before?',
        followupQuestions: [{ question: 'In what ways did this support help you learn?' }],
      },
    ],
  },
  documentUse: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Document use',
    helperText:
      'Document use skills are used in tasks that involve a variety of information displays in which words, numbers, icons, and other visual characteristics, for example: line, colour, shape, are given meaning by their spatial arrangement. For example, graphs, lists, tables, blueprints, schematics, drawings, signs, and labels are documents used in the world of work.',
    sampleQuestions: [
      { question: 'Tell me how you have used documents in your work and in your everyday life.' },
      {
        question: 'Have you used tables, charts, schedules in your work or everyday life?',
        followupQuestions: [{ question: 'In what ways have you used them?' }],
      },
      {
        question: 'Have you been involved in creating any types of documents?',
        followupQuestions: [{ question: ' Describe the types of documents and your role in creating them.' }],
      },
      {
        question: 'Describe the way you might be required to use documents in the work that you are seeking.',
        followupQuestions: [{ question: 'How do you think your skills relate to those required?' }],
      },
    ],
  },
  writing: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Writing',
    helperText:
      'Writing involves the ability to write effectively in the language in which business is conducted. It includes writing texts and writing in documents for example: completing forms as well as non-paper-based writing like typing on a computer.',
    sampleQuestions: [
      { question: 'Tell me about your educational background.' },
      { question: 'Tell me about the writing that you were required to do in your previous work.' },
      {
        question:
          'On a scale of 1-5, how would you rate your ability to write notes, messages, and documentation in order to pass on important information to others?',
      },
      { question: 'What feedback have you received on your writing skills?' },
    ],
  },
  workingWithOthers: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Working with others',
    helperText:
      'Working with others, whether with partners or as a member of a team is common in today’s workplace. Interpersonal skills such as active listening, accepting, non-judgmental attitude and assertiveness lay an important base for effective communication with team members. Working with others may also include working with customers and providing service, where the skills identified previously are also important.',
    sampleQuestions: [
      {
        question: 'Tell me about a time that you have worked with a partner or on a team.',
        followupQuestions: [
          {
            question:
              'What skills did you need to relate effectively to your co-workers to get the job done? How did that experience go for you?',
          },
        ],
      },
      { question: 'What is your greatest strength in building relationships with others?' },
      {
        question: 'Describe a time that an issue arose with a co-worker.',
        followupQuestions: [{ question: 'How did you deal with and resolve the issue?' }],
      },
      { question: 'What interpersonal skills do you think might be required in the work that you are thinking of?' },
      { question: 'How do you see your skills compared to those that are required?' },
    ],
  },
  digitalSkills: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Digital skills',
    helperText:
      'Digital skills includes skills required to effectively manage basic technology such as voice messaging, automated banking systems and computers encountered in everyday life and work. It also includes using the internet for electronic communication as well as a basic research tool and use of simple software to open, read, and create documents.',
    sampleQuestions: [
      {
        question:
          'In what ways have you used technology in your previous work, volunteer experiences, and/or your everyday life?',
      },
      {
        question: 'Are you familiar with using a computer, tablet, smart phone?',
        followupQuestions: [
          { question: 'How have you used a computer, tablet, smart phone?' },
          { question: 'What tasks can you perform using this technology?' },
        ],
      },
      {
        question:
          'What technology or digital skills might you require related to your employment and/or training goals?',
      },
      {
        question:
          'How does your present level of skills using technology compare to the level you think you will need?',
      },
    ],
  },
};
