import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FbParentLabels: IntakeLabelCollection = {
  firstName: {
    label: 'First name',
  },
  middleName: {
    label: 'Middle name',
  },
  lastName: {
    label: 'Last name',
  },
  phoneNumber: {
    label: 'Phone number',
  },
  birthdate: {
    label: 'Birthdate',
  },
  email: {
    label: 'Email address',
  },
  parentAddress: {
    label: 'Address of parent/guardian',
  },
  streetAddress: {
    label: 'Street address',
  },
  suiteNumber: {
    label: 'Suite or unit #',
  },
  city: {
    label: 'City/town',
  },
  province: {
    label: 'Province/territory',
  },
  postalCode: {
    label: 'Postal code',
  },
  deceasedMinorHasParents: {
    label: 'Does the deceased have parents/guardians?',
    alternateLabel: 'Does the deceased have other parents/guardians? ',
  },
  applicantIsParent: {
    label: 'Are you a parent/guardian of the deceased?',
  },
  deceasedMinorApplicantOnlyParent: {
    label: 'Are you the only parent/guardian?',
  },
};
