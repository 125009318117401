import { Expose } from 'class-transformer';
import { IsNotEmpty, ValidateIf } from 'class-validator';
import { PostalCodeFormat } from '../custom-validation-functions/postal-code-format';

export class InternationalAddress {
  @Expose()
  @IsNotEmpty()
  streetAddress: string;

  @Expose()
  suiteNumber?: string;

  @Expose()
  @IsNotEmpty()
  city: string;

  @Expose()
  @IsNotEmpty()
  provState: string;

  @Expose()
  @IsNotEmpty()
  country: string;

  @Expose()
  @IsNotEmpty()
  //@IsPostalCode('any')
  @ValidateIf((o) => o.country === 'Canada')
  @PostalCodeFormat()
  postalZip: string;
}
