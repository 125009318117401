import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FiServicesLabels: IntakeLabelCollection = {
  header: { label: 'Services' },
  description: {
    label: `If determined eligible for the FSCD program, some services may be available to your family right away. Other services will require a caseworker to conduct an assessment before receiving them.`,
  },
  homeRespite: {
    label: 'In or out of home respite services',
    helperText:
      'Provides you with a caretaker for your child with a disability to temporarily relieve you from caring for your child (i.e. to run errands, to do housework, or for self care).',
  },
  medicalAppointment: {
    label: 'Medical appointment supports',
    helperText:
      'Support to help with the costs of your child’s disability-related medical appointments or hospitalization, such as mileage, parking and/or public transportation.',
  },
  counselling: {
    label: 'Family and/or individual counselling',
    helperText: `Counselling can support your family’s well-being and ability to care for and face the challenges of your child’s disability.`,
  },
  counsellingNote: {
    label: `Before accessing funding for counselling through FSCD, you need to use existing benefit plans available to you, for example through your employment benefit plan.`,
  },
  clothingFootwear: {
    label: 'Clothing and footwear',
    helperText: `Intended to help with some of the costs of extraordinary clothing and footwear needs that are directly related to your child's disability.`,
  },
 
  moreInfoHeader: { label: 'For more information' },
  moreInfoText: {
    label: `For more information on other FSCD services (e.g. specialized services, behavior developmental support, sibling care, work-related childcare, etc.) please visit `,
  },
  agreementHeader: { label: 'FSCD Agreement' },
  agreementText: {
    label: `If you are eligible for FSCD, your agreement will include some family support services but you will be connected with a case worker to discuss your overall child’s needs and your family situation. The FSCD agreement outlines the services the FSCD program will provide to help you meet your goals. It is a legal document that can last up to 3 years.`,
    replacementLabel: `If you are eligible for FSCD, your agreement will include some family support services but you will be connected with a case worker to discuss your overall child’s needs and your family situation. The FSCD agreement outlines the services the FSCD program will provide to help you meet your goals. It is a legal document that can last up to <<replacementLabel>> years.`,
  },
  agreementBenefits: { label: `The benefits of multi-year agreements includes:` },
  agreementBenefitsList: { label: `Fewer contract renewals and less paperwork requirements` },
  agreementBenefitsList2: { label: `Greater stability and consistency in your supports and services` },
  agreementBenefitsList3: {
    label: `The agreement can be updated at anytime to reflect the changing needs of your family`,
  },
  agreementDuration: { label: `How long would you want your agreement to be?` },
};
