import { Lookup } from '../lookup.interface';

export const FollowupAttemptStatuses: { [name: string]: Lookup } = {
  successful: {
    code: 'successful',
    displayValue: 'Successful',
  },
  unableToContact: {
    code: 'unableToContact',
    displayValue: 'Unable to contact',
  },
};
