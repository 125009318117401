import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { IndianRegistration } from '@common/entities';

@Component({
  selector: 'common-indian-registration-numbers',
  templateUrl: './indian-registration-numbers.component.html',
  styleUrls: ['./indian-registration-numbers.component.scss'],
})
export class IndianRegistrationNumbersComponent implements OnInit {
  private firstLoad = true;
  formGroup: UntypedFormGroup;
  @Input() isRequired = true;
  private _indianRegistration: IndianRegistration;
  @Input() set indianRegistration(value: IndianRegistration) {
    if (value && this.firstLoad) {
      this._indianRegistration = value;
      this.setFormData();
      this.firstLoad = false;
    }
  }

  constructor(private fb: UntypedFormBuilder, private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as UntypedFormGroup;
    const isDisabled = this.formGroup.disabled;
    this.formGroup.addControl(
      'band',
      this.fb.control(undefined, {
        validators: this.isRequired
          ? [Validators.required, Validators.pattern('[1-9][0-9]{2}')]
          : [Validators.pattern('[1-9][0-9]{2}')],
        updateOn: 'blur',
      }),
      { emitEvent: false }
    );

    this.formGroup.addControl(
      'family',
      this.fb.control(undefined, {
        validators: this.isRequired
          ? [Validators.required, Validators.pattern('^(([1-9][0-9]{0,4})|(\\d{5}))$')]
          : [Validators.pattern('^(([1-9][0-9]{0,4})|(\\d{5}))$')],
        updateOn: 'blur',
      }),
      { emitEvent: false }
    );
    this.formGroup.addControl(
      'position',
      this.fb.control(undefined, {
        validators: this.isRequired
          ? [Validators.required, Validators.pattern('^([0-9][0-9])$')]
          : [Validators.pattern('^([0-9][0-9])$')],
        updateOn: 'blur',
      }),
      { emitEvent: false }
    );
    if (isDisabled) this.formGroup.disable({ emitEvent: false });
    this.formGroup.setValidators(
      this.formGroup.validator
        ? [this.formGroup.validator, this.indianRegistrationValidator]
        : [this.indianRegistrationValidator]
    );
    this.setFormData();
  }
  private setFormData() {
    if (this.formGroup && this._indianRegistration) {
      this.formGroup.patchValue(this._indianRegistration, { emitEvent: false });
    }
  }

  private indianRegistrationValidator(control: AbstractControl): ValidationErrors | null {
    const indianRegistration: IndianRegistration = control.value;
    if (indianRegistration) {
      if (indianRegistration.band || indianRegistration.family || indianRegistration.position) {
        if (!indianRegistration.band || !indianRegistration.family || !indianRegistration.position) {
          return {
            allFilled: 'All fields must be entered.',
          };
        }
      }
    }

    return null;
  }
}
