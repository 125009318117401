import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import * as WebFont from 'webfontloader';

export function registerIcons(matIconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
  return () => {
    return new Promise((resolve) => {
      WebFont.load({
        google: {
          families: [
            'Material+Icons',
            'Material+Icons+Outlined',
            'Material+Icons+Two+Tone',
            'Material+Icons+Round',
            'Material+Icons+Sharp',
          ],
        },
        active: function () {
          resolve(true);
        },
        inactive: function () {
          resolve(true);
        },
      });
      matIconRegistry.addSvgIcon('common-x', sanitizer.bypassSecurityTrustResourceUrl('/assets/x.svg'));
      matIconRegistry.addSvgIcon('common-trash', sanitizer.bypassSecurityTrustResourceUrl('/assets/trash.svg'));
      matIconRegistry.addSvgIcon('common-user', sanitizer.bypassSecurityTrustResourceUrl('/assets/user.svg'));
      matIconRegistry.addSvgIcon('common-navpoint', sanitizer.bypassSecurityTrustResourceUrl('/assets/navpoint.svg'));
      matIconRegistry.addSvgIcon('common-phone', sanitizer.bypassSecurityTrustResourceUrl('/assets/phone.svg'));
      matIconRegistry.addSvgIcon(
        'stepper-error',
        sanitizer.bypassSecurityTrustResourceUrl('/assets/stepper-error.svg')
      );
      matIconRegistry.addSvgIcon(
        'stepper-complete',
        sanitizer.bypassSecurityTrustResourceUrl('/assets/stepper-complete.svg')
      );
      matIconRegistry.addSvgIcon('stepper-edit', sanitizer.bypassSecurityTrustResourceUrl('/assets/stepper-edit.svg'));
      matIconRegistry.addSvgIcon('incomplete', sanitizer.bypassSecurityTrustResourceUrl('/assets/incomplete.svg'));
      matIconRegistry.addSvgIcon(
        'outgoing-link',
        sanitizer.bypassSecurityTrustResourceUrl('/assets/outgoing-link.svg')
      );
      matIconRegistry.addSvgIcon(
        'common-cloud-upload',
        sanitizer.bypassSecurityTrustResourceUrl('/assets/cloud-upload.svg')
      );
      matIconRegistry.addSvgIcon(
        'common-file-icon-pdf',
        sanitizer.bypassSecurityTrustResourceUrl('/assets/icon-pdf.svg')
      );
      matIconRegistry.addSvgIcon(
        'common-file-icon-img',
        sanitizer.bypassSecurityTrustResourceUrl('/assets/icon-img.svg')
      );
      matIconRegistry.addSvgIcon(
        'common-calendar-icon',
        sanitizer.bypassSecurityTrustResourceUrl('/assets/calendar-icon.svg')
      );
      matIconRegistry.addSvgIcon(
        'stepper-partial',
        sanitizer.bypassSecurityTrustResourceUrl('/assets/stepper-partial.svg')
      );
    });
  };
}
