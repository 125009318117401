<div
  [ngClass]="[
    icon === 'info' ? 'infoicon-section' : '',
    icon === 'error' ? 'erroricon-section' : '',
    icon === 'check' ? 'informationbox-section' : ''
  ]"
>
  <div
    [ngClass]="[
      icon === 'info' ? 'goa-callout goa--infoicon' : '',
      icon === 'error' ? 'goa-callout goa--erroricon' : '',
      icon === 'check' ? 'goa-callout goa--informationbox' : ''
    ]"
    data-cy="informationbox-component"
  >
    <ng-container *ngIf="title.length > 0">
      <h3>
        <span data-cy="title">{{ title }}</span>
      </h3>
      <br />
    </ng-container>

    <div data-cy="html1" *ngIf="html1" [innerHTML]="html1"></div>
    <div>
      <span data-cy="description">{{ description }}</span>
    </div>
    <div data-cy="html2" *ngIf="html2" [innerHTML]="html2"></div>
    <div>
      <span data-cy="description1">{{ description1 }}</span>
    </div>
    <div data-cy="html3" *ngIf="html3" [innerHTML]="html3"></div>
    <div>
      <span data-cy="description2">{{ description2 }}</span>
    </div>
  </div>
</div>
