<div class="container">
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Application JSON Data</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <textarea autosize [maxRows]="10" readonly>{{ this.application$ | async | json }}</textarea>
  </mat-expansion-panel>
</div>
