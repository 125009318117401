<div class="app">
  <div class="app-left">
    <common-displayfield-control class="app-field" data-cy="homeRespite" [label]="labels.homeRespite | labelText">
      {{ customRadioOptions[services?.homeRespite]?.displayValue || '-' }}
    </common-displayfield-control>

    <common-displayfield-control
      class="app-field"
      data-cy="medicalAppointment"
      [label]="labels.medicalAppointment | labelText"
    >
      {{ customRadioOptions[services?.medicalAppointment]?.displayValue || '-' }}
    </common-displayfield-control>

    <common-displayfield-control class="app-field" data-cy="counselling" [label]="labels.counselling | labelText">
      {{ customRadioOptions[services?.counselling]?.displayValue || '-' }}
    </common-displayfield-control>
  </div>

  <div class="app-right">
    <common-displayfield-control
      class="app-field"
      data-cy="clothingFootwear"
      [label]="labels.clothingFootwear | labelText"
    >
      {{ customRadioOptions[services?.clothingFootwear]?.displayValue || '-' }}
    </common-displayfield-control>

    <common-displayfield-control
      *ngIf="services.agreementDuration"
      class="app-field"
      data-cy="agreementDuration"
      [label]="labels.agreementDuration | labelText"
    >
      {{ serviceDurationOptions[services?.agreementDuration]?.displayValue || '-' }}
    </common-displayfield-control>
  </div>
</div>
