<div>
  <section class="callout-section">
    <goa-callout type="success">
      <h1 data-cy="page-title">
        Application successfully submitted: <strong>{{ application.submittedOn | date: 'longDate' }}</strong>
      </h1>
      <h1>
        <common-copy [copyText]="application.applicationNumber">
          <strong>Application number is {{ application.applicationNumber || '-' }}</strong>
        </common-copy>
      </h1>
      <div class="mt-15" data-cy="successMessage">
        <p>
          Your application has been successfully sent to FSCD. We will contact you if further information is needed.
        </p>
      </div>
      <div class="mt-30">
        <a
          tabindex="0"
          role="link"
          (click)="onClickDownloadPdf()"
          (keydown.space)="onClickDownloadPdf()"
          (keydown.enter)="onClickDownloadPdf()"
          >Download PDF application</a
        >
        <p>You can view, download, and print from here.</p>
      </div>
    </goa-callout>
  </section>

  <section class="callout-section mt-15">
    <goa-callout type="information" heading="Please check your email for a confirmation message."> </goa-callout>
  </section>

  <section class="button-section">
    <button
      (click)="onAddNewApplication()"
      class="action-button"
      data-cy="start-application-button"
      goa-button
      buttonType="secondary"
    >
      <span>+ Start a new application</span>
    </button>
    &nbsp;
    <button
      (click)="onViewMyApplications()"
      class="action-button"
      data-cy="view-my-application-button"
      goa-button
      buttonType="primary"
    >
      <span>View my applications</span>
    </button>
  </section>

  <!-- mobile buttons -->
  <button
    (click)="onAddNewApplication()"
    class="action-button show-mobile"
    data-cy="start-application-button-mobile"
    goa-button
    buttonType="secondary"
  >
    <span>+ Start a new application</span>
  </button>
  <button
    (click)="onViewMyApplications()"
    class="action-button show-mobile"
    data-cy="view-my-application-button-mobile"
    goa-button
    buttonType="primary"
  >
    <span>View my applications</span>
  </button>

  <!-- Start a new application -->
  <fi-start-app-modal
    [showModal]="showStartAppModal"
    (startNewApp)="onStartAppModalContinue($event)"
  ></fi-start-app-modal>
</div>
