import { Expose } from 'class-transformer';

export class Document {
  @Expose()
  documentCategory?: string;

  @Expose()
  documentType?: string;

  @Expose()
  key: string;

  @Expose()
  name: string;

  @Expose()
  size: number;

  @Expose()
  completed?: boolean;

  @Expose()
  isAdded?: boolean;

  @Expose()
  isDeleted?: boolean;

  @Expose()
  file?: File;

  @Expose()
  progress?: number;

  @Expose()
  dateUploaded?: Date;

  @Expose()
  errors?: { [name: string]: string };
}
