import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[type="tel"][iesMaxNumber]',
})
export class MaxNumberDirective {
  @Input() iesMaxNumber: number = 1000000;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event.target'])
  onInputChange(input: HTMLInputElement) {
    const value = parseFloat(input.value);
    if (value > this.iesMaxNumber) {
      input.setCustomValidity(`Value must not exceed ${this.iesMaxNumber}`);
      input.reportValidity();
      input.value = input.value.slice(0, -1);
      // console.log(input.value);
    } else {
      input.setCustomValidity('');
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const selectionStart = input.selectionStart;
    const selectionEnd = input.selectionEnd;
    let currentValue = parseFloat(input.value + event.key);
    if (selectionStart !== selectionEnd) {
      //need to splice the current value with
      const slice1 = input.value.slice(0, selectionStart);
      const slice2 = input.value.slice(selectionEnd, input.value.length);
      const newValue = parseFloat(slice1 + event.key + slice2);
      if (!isNaN(newValue)) {
        currentValue = newValue;
      }
    }

    if (currentValue > this.iesMaxNumber) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const input = event.target as HTMLInputElement;
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text/plain').trim();
    const pastedValue = parseFloat(pastedText);
    let currentValue = parseFloat(input.value);

    if (!isNaN(pastedValue)) {
      const selectionStart = input.selectionStart;
      const selectionEnd = input.selectionEnd;
      const slice1 = input.value.slice(0, selectionStart);
      const slice2 = input.value.slice(selectionEnd, input.value.length);
      const newValue = parseFloat(slice1 + pastedValue + slice2);
      if (!isNaN(newValue)) {
        currentValue = newValue;
      }
    }
    if (currentValue > this.iesMaxNumber) {
      event.preventDefault();
    }
  }
}
