import { Pipe, PipeTransform } from '@angular/core';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

@Pipe({
  name: 'quillToHtml',
})
export class QuillToHtmlPipe implements PipeTransform {
  transform(quillOps: string): string {
    if (quillOps) {
      try {
        const obj = JSON.parse(quillOps);
        const html = new QuillDeltaToHtmlConverter(obj.ops).convert();
        return `<div class='quill-output'>${html}</div>`;
      } catch (e) {
        return '-';
      }
    }
    return '-';
  }
}
