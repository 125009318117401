import { AngularComponentsModule } from '@abgov/angular-components';
import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@common/ui/material';
import {
  CalendarCommonModule,
  CalendarDateFormatter,
  CalendarMomentDateFormatter,
  CalendarMonthModule,
  DateAdapter,
  MOMENT,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as dayjs from 'dayjs';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { AddressComponent } from './address/address.component';
import { AddressPipe } from './address/address.pipe';
import { CalendarRangeSelectComponent } from './calendar-range-select/calendar-range-select.component';
import { CalendarRangeComponent } from './calendar-range/calendar-range.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarMonthYearComponent } from './calendar-month-year/calendar-month-year.component';
import { CitizenshipComponent } from './citizenship/citizenship.component';
import { CopyComponent } from './copy/copy.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { EllipsisTooltipDirective } from './directives/ellipsis-tooltip.directive';
import { MatTabsHeaderLessDirective } from './directives/mat-tabs-header-less.directive';
import { NoSpacesDirective } from './directives/no-spaces.directive';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { DisplayfieldControlComponent } from './displayfield-control/displayfield-control.component';
import { DocumentListPreviewComponent } from './document-list-preview/document-list-preview.component';
import { DocumentListComponent } from './document-upload/document-list.component';
import { DocumentUploadWorkerComponent } from './document-upload/document-upload-worker.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { EmailAddressComponent } from './email-address/email-address.component';
import { EnvironmentHeaderComponent } from './environment-header/environment-header.component';
import { FieldsetControlComponent } from './fieldset-control/fieldset-control.component';
import { FormControlComponent } from './form-control/form-control.component';
import { IndianRegistrationNumbersComponent } from './indian-registration-numbers/indian-registration-numbers.component';
import { IndianStatusViewComponent } from './indian-status-view/indian-status-view.component';
import { IndianStatusComponent } from './indian-status/indian-status.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { InfoIconComponentComponent } from './info-icon-component/info-icon-component.component';
import { InfoIconTooltipComponent } from './info-icon-tooltip/info-icon-tooltip.component';
import { HelpIconTooltipComponent } from './help-icon-tooltip/help-icon-tooltip.component';
import { LivingSituationComponent } from './living-situation/living-situation.component';
import { MatrixFormControlComponent } from './matrix-form-control/matrix-form-control.component';
import { MatrixFormLabelComponent } from './matrix-form-label/matrix-form-label.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { PhoneNumberMultipleComponent } from './phone-number-multiple/phone-number-multiple.component';
import { PhoneNumberPrimaryComponent } from './phone-number-primary/phone-number-primary.component';
import { PhoneNumberComponent } from './phone-number/phone-number.component';
import { PhoneNumberPipe } from './phone-number/phone-number.pipe';
import { ConfirmDialogComponent } from './popups/confirm-dialog/confirm-dialog.component';
import { MessageDialogComponent } from './popups/message-dialog/message-dialog.component';
import { TooltipDialogComponent } from './popups/tooltip-dialog/tooltip-dialog.component';
import { RemoveButtonComponent } from './remove-button/remove-button.component';
import { SaveIndicatorComponent } from './save-indicator/save-indicator.component';
import { SearchableDropdownComponent } from './searchable-dropdown/searchable-dropdown.component';
import { AhnValidateDirective } from './shared/directives/ahn-validator.directive';
import { BlockCopyCutPasteDirective } from './shared/directives/blockcopycutpaste.directive';
import { TrimPasteDirective } from './shared/directives/trimpaste.directive';
import { MaxNumberDirective } from './shared/directives/max-number.directive';
import { DisableChildrenDirective } from './shared/directives/disable-children.directive';
import { GoaBannerDirective } from './shared/directives/goa-banner.directive';
import { IconButton } from './shared/directives/icon-button.directive';
import { LuhnValidatorDirective } from './shared/directives/luhn-validator.directive';
import { MatInputCommifiedDirective } from './shared/directives/mat-input-commified.directive';
import { MatInputIntegerDirective } from './shared/directives/mat-input-integer.directive';
import { MaxDateValidator } from './shared/directives/max-date-validator.directive';
import { MinDateValidator } from './shared/directives/min-date-validator.directive';
import { PostalCodeValidateDirective } from './shared/directives/postal-code-validator.directive';
import { SinValidateDirective } from './shared/directives/sin-validator.directive';
import { AgePipe } from './shared/pipes/age.pipe';
import { AgeInYearsAndMonthsPipe } from './shared/pipes/age-in-years-and-months.pipe';
import { AlbertaHealthNumberPipe } from './shared/pipes/alberta-health-number.pipe';
import { ApostrapheNamePipe } from './shared/pipes/apostraphe-name.pipe';
import { DateMediumNoSecondsPipe } from './shared/pipes/date-medium-no-seconds.pipe';
import { DateMediumNoTimePipe } from './shared/pipes/date-medium-no-time.pipe';
import { HighlightSearchPipe } from './shared/pipes/highlight-search.pipe';
import { NiStatusPipe } from './shared/pipes/ni-status.pipe';
import { PostalCodePipe } from './shared/pipes/postal-code.pipe';
import { QuillToHtmlPipe } from './shared/pipes/quill-to-html.pipe';
import { SafePipe } from './shared/pipes/safe.pipe';
import { SocialInsuranceNumberPipe } from './shared/pipes/social-insurance-number.pipe';
import { TittleCasePipe } from './shared/pipes/tittle-case.pipe';
import { YesNoPipe } from './shared/pipes/yes-no.pipe';
import { EnabledDisabledPipe } from './shared/pipes/enabled-disabled.pipe';
import { OrdinalPipe } from './shared/pipes/ordinal.pipe';
import { NullNot0Pipe } from './shared/pipes/null-not-0.pipe';
import { DateOrTodayPipe } from '././shared/pipes/date-or-today.pipe';
import { RemoveFileExtensionPipe } from './ui-shared-components.pipes';
import { SingleSelectComponent } from './single-select/single-select.component';
import { TabGroupButtonsComponent } from './tab-group-with-buttons/tabgroup-buttons.component';
import { UndoToasterComponent } from './undo-toaster/undo-toaster.component';
import { WorkerCalendarDayView } from './worker-calendar/day-view/day-view.component';
import { WorkerCalendarWeekView } from './worker-calendar/week-view/week-view.component';
import {
  ShortDayNameFormatter,
  WorkerCalendarComponent,
  WorkerCalendarDayEventsPipe,
  WorkerCalendarDayGroupedEventsPipe,
} from './worker-calendar/worker-calendar.component';
import { YearMonthComponent } from './year-month/year-month.component';
import { WorkerCalendarListView } from './worker-calendar/list-view/list-view.component';
import { GoANumberInputComponent } from './abgov-experimental/number-input/number-input.component';
import { ArrayPipe } from './shared/pipes/array.pipe';
import { LabelTextPipe } from './shared/pipes/label-text.pipe';
import { CssNavbarComponent } from './css-navbar/css-navbar.component';
import { YesNoDashPipe } from './shared/pipes/yes-no-dash.pipe';
import { AbCityAutocompleteComponent } from './ab-city-autocomplete/ab-city-autocomplete.component';
import { CyFormControlNameDirective } from './directives/cy-form-control-name.directive';
import { DragDropDirective } from './shared/directives/drag-drop.directive';
import { D3DragDropWorkerComponent } from './document-upload/drag-drop-worker/drag-drop-worker.component';
import { DocumentProgressComponent } from './document-upload/document-progress/document-progress.component';

import { PasswordGeneratorComponent } from './password-generator/password-generator.component';
import { NotificationBannerComponent } from './notification-banner/notification-banner.component';
import { GoAButtonComponent } from './abgov-experimental/button/button.component';
import { ResponsiveMaxDateValidator } from './shared/directives/max-date-responsive-validator.directive';
import { ResponsiveMinDateValidator } from './shared/directives/min-date-responsive-validator.directive';
import { AutofocusDirective } from './shared/directives/autofocus.directive';
import { AddressLookupComponent } from './address-lookup/address-lookup.component';
import { YearMonthPipe } from './ui-shared-components.pipes';

export function momentAdapterFactory() {
  return adapterFactory(dayjs);
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    NgxMaskDirective,
    NgxMaskPipe,
    CommonModule,
    AngularComponentsModule,
    CalendarCommonModule.forRoot(
      {
        provide: DateAdapter,
        useFactory: momentAdapterFactory,
      },
      {
        dateFormatter: {
          provide: CalendarDateFormatter,
          useClass: CalendarMomentDateFormatter,
        },
      }
    ),
    CalendarMonthModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  declarations: [
    GoAButtonComponent,
    PhoneNumberComponent,
    PhoneNumberMultipleComponent,
    PhoneNumberPrimaryComponent,
    CalendarComponent,
    CalendarMonthYearComponent,
    CalendarRangeComponent,
    CalendarRangeSelectComponent,
    EmailAddressComponent,
    FormControlComponent,
    LivingSituationComponent,
    LuhnValidatorDirective,
    DatePickerComponent,
    FieldsetControlComponent,
    BlockCopyCutPasteDirective,
    TrimPasteDirective,
    MaxNumberDirective,
    AddressComponent,
    AddressLookupComponent,
    SocialInsuranceNumberPipe,
    AddressPipe,
    PhoneNumberPipe,
    AlbertaHealthNumberPipe,
    SafePipe,
    MatrixFormControlComponent,
    MatrixFormLabelComponent,
    EnvironmentHeaderComponent,
    YearMonthComponent,
    MessageDialogComponent,
    TooltipDialogComponent,
    NumberOnlyDirective,
    NoSpacesDirective,
    EllipsisTooltipDirective,
    MatTabsHeaderLessDirective,
    MatInputCommifiedDirective,
    MatInputIntegerDirective,
    InfoBoxComponent,
    UndoToasterComponent,
    ConfirmDialogComponent,
    MaxDateValidator,
    MinDateValidator,
    InfoIconComponentComponent,
    InfoIconTooltipComponent,
    HelpIconTooltipComponent,
    AhnValidateDirective,
    SinValidateDirective,
    PostalCodeValidateDirective,
    TabGroupButtonsComponent,
    DocumentUploadComponent,
    DocumentUploadWorkerComponent,
    DocumentListComponent,
    DocumentListPreviewComponent,
    DisplayfieldControlComponent,
    IndianStatusComponent,
    IndianRegistrationNumbersComponent,
    IndianStatusViewComponent,
    GoaBannerDirective,
    TittleCasePipe,
    PostalCodePipe,
    ApostrapheNamePipe,
    QuillToHtmlPipe,
    IconButton,
    RemoveButtonComponent,
    CitizenshipComponent,
    CopyComponent,
    SaveIndicatorComponent,
    SearchableDropdownComponent,
    SingleSelectComponent,
    MultiSelectComponent,
    YesNoPipe,
    YesNoDashPipe,
    YearMonthPipe,
    EnabledDisabledPipe,
    OrdinalPipe,
    NiStatusPipe,
    HighlightSearchPipe,
    SafePipe,
    DisableChildrenDirective,
    DateMediumNoTimePipe,
    DateMediumNoSecondsPipe,
    WorkerCalendarComponent,
    WorkerCalendarDayView,
    WorkerCalendarWeekView,
    WorkerCalendarListView,
    WorkerCalendarDayEventsPipe,
    WorkerCalendarDayGroupedEventsPipe,
    LivingSituationComponent,
    AgePipe,
    AgeInYearsAndMonthsPipe,
    ArrayPipe,
    LabelTextPipe,
    GoANumberInputComponent,
    AbCityAutocompleteComponent,
    CyFormControlNameDirective,
    CssNavbarComponent,
    DragDropDirective,
    D3DragDropWorkerComponent,
    DocumentProgressComponent,
    NotificationBannerComponent,
    ResponsiveMaxDateValidator,
    ResponsiveMinDateValidator,
    NullNot0Pipe,
    RemoveFileExtensionPipe,
    DateOrTodayPipe,
    PasswordGeneratorComponent,
    AutofocusDirective,
  ],
  exports: [
    GoAButtonComponent,
    AngularComponentsModule,
    CalendarCommonModule,
    CalendarMonthModule,
    CalendarComponent,
    CalendarMonthYearComponent,
    CalendarRangeComponent,
    CalendarRangeSelectComponent,
    PhoneNumberComponent,
    ConfirmDialogComponent,
    PhoneNumberMultipleComponent,
    PhoneNumberPrimaryComponent,
    EmailAddressComponent,
    FormControlComponent,
    LivingSituationComponent,
    LuhnValidatorDirective,
    DatePickerComponent,
    FieldsetControlComponent,
    AddressComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AddressPipe,
    PhoneNumberPipe,
    AlbertaHealthNumberPipe,
    SocialInsuranceNumberPipe,
    MaterialModule,
    MatrixFormControlComponent,
    MatrixFormLabelComponent,
    EnvironmentHeaderComponent,
    YearMonthComponent,
    MessageDialogComponent,
    NumberOnlyDirective,
    NoSpacesDirective,
    EllipsisTooltipDirective,
    MatTabsHeaderLessDirective,
    MatInputCommifiedDirective,
    MatInputIntegerDirective,
    InfoBoxComponent,
    HttpClientModule,
    UndoToasterComponent,
    MaxDateValidator,
    MinDateValidator,
    InfoIconComponentComponent,
    InfoIconTooltipComponent,
    HelpIconTooltipComponent,
    AhnValidateDirective,
    SinValidateDirective,
    PostalCodeValidateDirective,
    TabGroupButtonsComponent,
    DocumentUploadComponent,
    DocumentListComponent,
    DocumentListPreviewComponent,
    DisplayfieldControlComponent,
    IndianStatusComponent,
    IndianRegistrationNumbersComponent,
    IndianStatusViewComponent,
    GoaBannerDirective,
    TittleCasePipe,
    PostalCodePipe,
    ApostrapheNamePipe,
    QuillToHtmlPipe,
    SafePipe,
    IconButton,
    RemoveButtonComponent,
    CitizenshipComponent,
    CopyComponent,
    SaveIndicatorComponent,
    SearchableDropdownComponent,
    SingleSelectComponent,
    MultiSelectComponent,
    YesNoPipe,
    YesNoDashPipe,
    YearMonthPipe,
    EnabledDisabledPipe,
    OrdinalPipe,
    NiStatusPipe,
    HighlightSearchPipe,
    DisableChildrenDirective,
    DateMediumNoTimePipe,
    DateMediumNoSecondsPipe,
    WorkerCalendarComponent,
    LivingSituationComponent,
    AgePipe,
    AgeInYearsAndMonthsPipe,
    ArrayPipe,
    LabelTextPipe,
    GoANumberInputComponent,
    AbCityAutocompleteComponent,
    CyFormControlNameDirective,
    CssNavbarComponent,
    D3DragDropWorkerComponent,
    MaxNumberDirective,
    NotificationBannerComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    ResponsiveMaxDateValidator,
    ResponsiveMinDateValidator,
    NullNot0Pipe,
    RemoveFileExtensionPipe,
    DateOrTodayPipe,
    PasswordGeneratorComponent,
    AutofocusDirective,
  ],
  providers: [
    NiStatusPipe,
    YesNoPipe,
    YesNoDashPipe,
    YearMonthPipe,
    OrdinalPipe,
    AddressPipe,
    HighlightSearchPipe,
    {
      provide: MOMENT,
      useValue: dayjs,
    },
    {
      provide: CalendarDateFormatter,
      useClass: ShortDayNameFormatter,
    },
    provideEnvironmentNgxMask(),
    DecimalPipe,
    PhoneNumberPipe,
  ],
})
export class UiSharedComponentsModule {}
