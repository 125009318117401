import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, Input, Output, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { Document, DocumentList } from '@common/entities';
import { FileService } from '../shared/services/file/file.service';
import { ControlContainer, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UploadLookup } from '@common/constants';
import { SELECT_ONE_TEXT } from '@common/constants';
import { HostListener } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { PDFDocument } from 'pdf-lib';

@Component({
  selector: 'common-document-upload-worker',
  templateUrl: './document-upload-worker.component.html',
  styleUrls: ['./document-upload-worker.component.scss'],
})
export class DocumentUploadWorkerComponent implements OnInit, OnDestroy {
  @Input() parentId: string;
  @Input() dataCy: string;
  @Input() alwaysShowOptOut = false;

  multiple = false;
  selectOneText = SELECT_ONE_TEXT;
  private _documentsUploaded: DocumentList;
  @Input() set documentsUploaded(value: DocumentList) {
    if (value) {
      this._documentsUploaded = value;
      this.setFormData();
    }
  }
  @Input() maxFileSize: number;
  _accept: string;
  @Input() set accept(value: string) {
    if (value) {
      this._accept = value;
      const accepts = value.split('.');
      const last = accepts.pop();
      this.acceptDisplay = accepts.join('') + ' or ' + last;
    }
  }

  //gives us the ability to initialize document list at the component level
  _componentInitialized: boolean;
  @Input() set componentInitialized(value: boolean) {
    if (value === true && !this._componentInitialized) {
      //only does this once
      this.initializeDocumentList();
      this._componentInitialized = true;
    }
  }

  @Input() deleteMessage: string;

  @Input() documentCategory: { key: string; value: UploadLookup };
  @Input() multipleDocument: boolean;
  @Input() categoryLabel = 'Proof of';
  @Output() started = new EventEmitter<Document[]>();
  @Output() uploadInProgress = new EventEmitter<Document>();
  @Output() fileUploaded = new EventEmitter<Document>();
  @Output() errorOccurred = new EventEmitter<Document>();
  @Output() completed = new EventEmitter<void>();
  @Output() optOutChanged = new EventEmitter<{ optOut: boolean; category: string }>();

  uploading = false;
  documentTypeFormControl: UntypedFormControl;
  errors;
  uploadMore = false;
  acceptDisplay: string;
  formGroup: UntypedFormGroup;
  public uploadButtonId: string = uuidv4();
  optOutCategories = [];
  documentInProgress: Document;
  private destroy$ = new Subject<void>();
  initOptOut: string[] = null;
  initWithError: false;

  constructor(
    public fileService: FileService,
    private fb: UntypedFormBuilder,
    private controlContainer: ControlContainer,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as UntypedFormGroup;
    this.formGroup.addControl(
      this.documentCategory.key,
      this.fb.control(null, {
        validators: this.documentCategory.value?.isOptional ? [] : [Validators.required],
      })
    );
    this.formGroup.addControl('optOut', this.fb.control(this._documentsUploaded?.optOut, null));
    this.formGroup.addControl('activated', this.fb.control(this._documentsUploaded?.activated, null));
    this.setFormData();

    this.documentTypeFormControl = this.fb.control(
      this.documentCategory.value?.documentTypes && this.documentCategory.value?.documentTypes.length === 1
        ? this.documentCategory.value.documentTypes[0]
        : ''
    );

    if (!this._componentInitialized) {
      //need to set initOptOut for the initial opt out value.
      setTimeout(() => {
        this.initializeDocumentList();
      }, 0);
    }
  }

  initializeDocumentList() {
    this.formGroup.controls.optOut.setValue(this._documentsUploaded.optOut);
    this.initOptOut = this._documentsUploaded.optOut;
    this.initWithError = this.hasErrorAndTouched;

    const fileCount = this.formGroup.controls[this.documentCategory.key]?.value?.length;
    if (fileCount > 0 || this.initOptOut) {
      //if opt out is enabled should be marked as touched to ensure invalids show error messages.
      //if they have files, this upload control should be marked as touched so if it's removed the error will display
      if (this.formGroup.controls[this.documentCategory.key]) {
        this.formGroup.controls[this.documentCategory.key].markAsTouched();
      }
    }
    this.setValidation();
    this.cdr.detectChanges();
  }

  get optOutLabel() {
    let label = this.documentCategory.value?.optOutLabel;
    if (this.documentCategory.value?.optOutLabelTransform) {
      label = this.documentCategory.value?.optOutLabelTransform;
    }
    return label;
  }

  ngOnDestroy() {
    this.formGroup.removeControl(this.documentCategory.key);
    this.destroy$.next();
    this.destroy$.complete();
  }

  async onFilesSelected(files: Array<File>) {
    await this.prepareFilesList(files);
  }

  addRemoveOptOutCategory(category: string, add = true, emitOptOutChange = false) {
    const optOutCategories = (this.formGroup.controls.optOut?.value as string[]) ?? [];
    if (!optOutCategories.includes(category) && add) {
      //add
      optOutCategories.push(category);
      this.formGroup.controls.optOut.setValue(optOutCategories);
    } else if (!add && optOutCategories.includes(category)) {
      //remove
      const removeIndex = optOutCategories.indexOf(category);
      optOutCategories.splice(removeIndex, 1);
      this.formGroup.controls.optOut.setValue(optOutCategories);
    }

    if (emitOptOutChange) {
      const optOutParam = { optOut: add, category: category };
      this.optOutChanged.emit(optOutParam);
    }
  }

  onOptOutChanged(optOut, category: string) {
    const optOutChecked = optOut.checked;
    this.addRemoveOptOutCategory(category, optOutChecked, true);
    this.setValidation();
  }

  optOutDisabled(category: string) {
    const fileCount = this.formGroup.controls[category]?.value?.length;
    return fileCount > 0;
  }

  setValidation() {
    if (this.formGroup?.controls[this.documentCategory.key]) {
      if (
        this.documentCategory.value?.isOptional ||
        this.formGroup.controls.optOut?.value?.includes(this.documentCategory.key)
      ) {
        this.formGroup.controls[this.documentCategory.key].setValidators(null);
      } else {
        this.formGroup.controls[this.documentCategory.key].setValidators([Validators.required]);
      }
      this.formGroup.updateValueAndValidity();
    }
  }

  onUploadMore() {
    if (this.documentCategory.value.documentTypes.length > 1) this.documentTypeFormControl.setValue('');
    this.uploadMore = true;
  }
  get isUploadEnabled() {
    return (
      !this.uploading && (this.documentCategory.value.documentTypes.length === 0 || this.documentTypeFormControl.value)
    );
  }
  private setFormData() {
    if (this.formGroup) {
      if (this.formGroup.controls[this.documentCategory.key]) {
        this.formGroup.controls[this.documentCategory.key].setValue(
          (this._documentsUploaded?.documents &&
            this._documentsUploaded.documents.length &&
            this._documentsUploaded.documents) ||
            null
        );
      }
      this.setValidation();
    }
  }

  private async prepareFilesList(files: Array<File>) {
    for (const file of files) {
      this.documentInProgress = {
        documentCategory: this.documentCategory.key,
        documentType: this.documentTypeFormControl.value,
        name: file.name,
        size: file.size,
        completed: false,
        progress: 1,
        file,
      } as Document;

      const isFileValid = await this.validateFile(this.documentInProgress);

      if (!this.multiple && !isFileValid) return;
      if (!this.multiple && files.length > 1) {
        break;
      }
    }
    if (files.length > 0) {
      this.startUploadingFiles();
    }
  }

  displayOptional() {
    if (!this.documentCategory.value?.isOptional) return '';
    if (this.documentCategory.value?.isOptional) {
      if (!this.documentCategory.value?.suppressOptionalLabel) return '(Optional)';
      if (this.documentCategory.value?.suppressOptionalLabel) return '';
    }
  }

  private async validateFile(document: Document): Promise<boolean> {
    this.errors = null;

    if (document.file.size > this.maxFileSize || document.file.size == 0) {
      this.setError(document, {
        maxFileSize: this.maxFileSize.toString(),
      });
      return false;
    }
    const ext = document.name.split('.').pop();
    const fileExtension = '.' + ext.toUpperCase();
    if (
      this._accept &&
      (!fileExtension ||
        (!this._accept.endsWith(fileExtension) &&
          this._accept.indexOf(fileExtension + ' ') === -1 &&
          this._accept.indexOf(fileExtension + ',') === -1))
    ) {
      this.setError(document, {
        fileExtension: 'true',
      });
      return false;
    }
    const mimeType = document.file.type;
    if (!mimeType) {
      this.setError(document, {
        mimeType: 'true',
      });
      return false;
    }

    // Check if the file is a password-protected PDF
    if (fileExtension === '.PDF') {
      const isPdfValid = await this.validatePdf(document);
      if (!isPdfValid) {
        return false;
      }
    }

    return true;
  }

  private async validatePdf(document: Document): Promise<boolean> {
    try {
      const arrayBuffer = await this.readFileAsArrayBuffer(document.file);
      const uploadedPdf = await PDFDocument.load(arrayBuffer, { ignoreEncryption: true });

      if (uploadedPdf.isEncrypted) {
        this.setError(document, {
          fileLoad: 'PDF is password protected. Please remove the password and try again.',
        });
        return false;
      }
    } catch (err) {
      this.setError(document, {
        fileLoad: 'PDF is invalid. Please select another file.',
      });
      return false;
    }

    return true;
  }

  private readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }

  private setError(document: Document, error) {
    if (this.multiple) document.errors = error;
    else this.errors = error;
    this.cdr.detectChanges();
  }

  private startUploadingFiles() {
    this.started.emit([this.documentInProgress]);
    this.uploading = true;
    this.uploadFile(this.documentInProgress);
  }

  private uploadFile(file) {
    if (file.errors) {
      file.completed = true;
      this.errorOccurred.emit(file);
      this.checkIfUploadCompleted();
      return;
    }

    this.fileService
      .upload(this.parentId, file.file)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          //file.key = result.key;
          switch (result.type) {
            case HttpEventType.Sent:
              this.uploadInProgress.emit(file);
              break;
            case HttpEventType.UploadProgress:
              file.progress = Math.round((100 * result.loaded) / result.total);
              this.uploadInProgress.emit(file);
              break;
            case HttpEventType.Response:
              file.key = result.key;
              file.progress = 100;
              file.completed = true;
              this.uploadInProgress.emit(file);
              this.fileUploaded.emit(file);
              this.checkIfUploadCompleted();
              break;
          }
        },
        () => {
          file.completed = true;
          file.errors = { upload: 'true' };
          this.errorOccurred.emit(file);
          this.checkIfUploadCompleted();
        }
      );
  }

  private checkIfUploadCompleted() {
    if (this.documentInProgress.completed) {
      this.uploading = false;
      this.uploadMore = false;
      this.completed.emit();
    }
  }
  get hasUploadedDocument(): boolean {
    const hasUploadedDocument = this._documentsUploaded?.documents?.length > 0;
    return hasUploadedDocument;
  }

  getOptOutChecked(documentCategory: string) {
    const optOutCategories = this.formGroup.controls.optOut.value as string[];
    const fileCount = this.formGroup.controls[documentCategory]?.value?.length;
    if (fileCount > 0) {
      //only need to trigger emit if opt out for this category is selected and we need to uncheck
      const triggerEmit = optOutCategories?.includes(documentCategory);
      this.addRemoveOptOutCategory(documentCategory, false, triggerEmit);
    }
    return optOutCategories?.includes(documentCategory);
  }

  get getRequiredErrorMessage() {
    let label = this.documentCategory.value?.requiredErrorOverride;
    if (this.documentCategory.value?.requiredErrorOverrideTransform) {
      label = this.documentCategory.value?.requiredErrorOverrideTransform;
    }
    if (!label) {
      //default error message
      label = `${this.categoryLabel} ${this.documentCategory.value?.displayValue?.toLowerCase()} is required.`;
    }
    return label;
  }

  getUploaderLabel(ariaLabel = false) {
    //Terence: Also looking like this may not follow this pattern and may be different per document upload.
    const categoryTitleOverride = this.documentCategory.value?.categoryTitleOverride;
    const defaultLabel = `${this.categoryLabel} ${this.documentCategory.value?.displayValue?.toLowerCase()}`.trim();
    const optionalLabel = this.displayOptional();

    let uploaderLabel = `  
    <span>${defaultLabel}</span>
    <span class="optional-label">${optionalLabel}</span>`;

    if (categoryTitleOverride) {
      uploaderLabel = `
      <span>${categoryTitleOverride}</span>
      <span class="optional-label">${optionalLabel}</span>`;
    }

    if (ariaLabel) {
      let label = categoryTitleOverride;
      if (!label) {
        label = defaultLabel;
      }
      if (this.dataCy) {
        label = `${label} - ${this.dataCy}`;
      }
      return label;
    }

    return uploaderLabel;
  }

  get hasErrorAndTouched() {
    const errorAndTouched =
      this.formGroup.controls[this.documentCategory.key]?.touched &&
      this.formGroup.controls[this.documentCategory.key].errors?.required;
    if (errorAndTouched && !this.initOptOut) {
      //optout should be set to visible (if optoutlabel is set) if there is an error and touched.
      this.initOptOut = [];
    }
    return errorAndTouched;
  }

  getDataCy(dataCy: string = null, documentCategory: string = null, identifier: string = null) {
    let objDataCy = '';
    if (dataCy) {
      objDataCy = dataCy;
    }
    if (documentCategory) {
      if (objDataCy) {
        objDataCy += '-';
      }
      objDataCy += documentCategory;
    }
    if (identifier) {
      if (objDataCy) {
        objDataCy += '-';
      }
      objDataCy += identifier;
    }
    return objDataCy;
  }
}
