import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { DateValidator } from '../validators/date.validator';
/*
 This is a clone MaxDateValidator with input value change and revalidation support
*/
@Directive({
  selector: '[iesResponsiveMaxDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: ResponsiveMaxDateValidator,
      multi: true,
    },
  ],
})
export class ResponsiveMaxDateValidator implements Validator {
  private _onChange?: () => void;
  private _maxTime;

  @Input('iesResponsiveMaxDate')
  get maxDateValue() {
    return this._maxTime;
  }

  set maxDateValue(value) {
    this._maxTime = value;
    if (this._onChange) {
      this._onChange();
    }
  }
  @Input('iesMaxDateErrorMessage') message = null;

  validate(control: AbstractControl) {
    return DateValidator.maxDate(this.maxDateValue, '', this.message)(control);
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }
}
