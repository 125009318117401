import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum SaveStatus {
  Inactive,
  InProgress,
  Error,
  Success,
}

/**
 * Provider for save-indicator information
 */
@Injectable()
export class SaveStatusProvider {
  saveStatus$ = new BehaviorSubject<SaveStatus>(SaveStatus.Inactive);

  success() {
    this.saveStatus$.next(SaveStatus.Success);
  }

  inProgress() {
    this.saveStatus$.next(SaveStatus.InProgress);
  }

  error() {
    this.saveStatus$.next(SaveStatus.Error);
  }
}
