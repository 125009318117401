import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[iesNoSpaces]',
})
export class NoSpacesDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = event as KeyboardEvent;
    if (event.key === ' ') e.preventDefault();
  }
}
