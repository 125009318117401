import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe for ahn.  Returns XXXXX-XXXX
 */
@Pipe({ name: 'albertaHealthNumber' })
export class AlbertaHealthNumberPipe implements PipeTransform {
  transform(ahn: string): string {
    if (!ahn || ahn.length !== 9) {
      return ahn;
    }

    return `${ahn.slice(0, 5)}-${ahn.slice(5, 10)}`;
  }
}
