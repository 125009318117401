/**
 * Check validity of Personal Identification numbers like SIN, PHN (AHN)
 * @param checkDigitPosition Position of check digit. Zero based index
 * @returns mod10Validation property
 */
export function mod10Validation(personalIdentifier: string, checkDigitPosition: number): { [key: string]: boolean } {
  /// Checks that a PHN or SIN number is valid.  The following are the steps used:
  /// A PHN or SIN consists of nine digits. In PHN 6th (5th zero based index) digit is check digit.
  /// In SIN 9th (8th zero based index) is check digit. Ignore - or space in the number.
  /// Check digit of PHN or SIN can determine by doing the following:
  ///
  /// Example PHN: 72714-3520 (3 is check digit)
  /// Example SIN: 680-781-507 (7 is check digit)
  ///
  /// Step 1: remove the check digit
  /// i.e. the 3 after the dash, leaving you with 72714520
  ///
  /// Step 1: Multiply every digit in an even position by 2 (ie. starting with the second digit multiply every other digit by 2).
  /// 2*2 = 4
  /// 1*2 = 2
  /// 5*2 = 10 = 1 + 0 = 1
  /// 0*2 = 0
  ///
  /// Step 2: Add together all of the digits of the numbers multiplied by 2
  /// (split numbers with 2 digits into two, i.e. 10 becomes 1 + 0, 14 becomes 1 + 4, etc.)
  /// same result can be acheived by substracting 9 from number. 10 becomes 10 - 9 = 1, 14 becomes 14 - 9 = 5
  /// 4 + 2 + 1 + 0 = 7
  ///
  /// Step 3: Add together all of the digits not multiplied by 2 (the ones in the 'odd' positions).
  /// 7 + 7 + 4 + 2 = 20
  ///
  /// Step 4: Sum together the results from Step 2 and Step 3.
  /// 7 + 20 = 27
  ///
  /// Step 5: Subtract the sum in Step 4 from the next highest multiple of 10 and compare to check digit
  /// i.e. 30 - 27 = 3 [check digit]

  if (!personalIdentifier || personalIdentifier === '') {
    //blank is Valid
    return null;
  }

  // Accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(personalIdentifier)) {
    return { mod10Validation: true };
  }
  //replaces non-digit characters to ''
  personalIdentifier = personalIdentifier.replace(/\D/g, '');

  //number cannot be all zeros
  if (personalIdentifier === '000000000') {
    return { mod10Validation: true };
  }

  let nCheck = 0,
    eSum = 0,
    oSum = 0,
    nextHighestTen = 0,
    bEven = false,
    actualCheckDigit: number;

  //check personalIdentifier should be 9 digits
  if (personalIdentifier.length === 9) {
    //check digit is last digit of personal identifier
    if (personalIdentifier.length - 1 === checkDigitPosition) {
      actualCheckDigit = +personalIdentifier.substring(checkDigitPosition);
      //pull out the check digit from personal identifier
      personalIdentifier = personalIdentifier.substring(0, checkDigitPosition);
    } else {
      //check digit is not last digit of personal identifier
      actualCheckDigit = +personalIdentifier.substring(checkDigitPosition, checkDigitPosition + 1);
      //pull out the check digit from personal identifier
      personalIdentifier =
        personalIdentifier.substring(0, checkDigitPosition) +
        personalIdentifier.substring(checkDigitPosition + 1, personalIdentifier.length);
    }
  }

  for (let n = 0; n < personalIdentifier.length; n++) {
    const cDigit = personalIdentifier.charAt(n);
    let nDigit = parseInt(cDigit, 10);

    //sum digits at odd position
    if (!bEven) oSum += nDigit;

    //multiply digit at even position by 2. subtract 9 from number greater than 9
    //add result to eSum variable to hold sum of didgits at even position
    if (bEven) {
      if ((nDigit *= 2) > 9) {
        nDigit -= 9;
        eSum += nDigit;
      } else {
        eSum += nDigit;
      }
    }

    bEven = !bEven;
  }

  //add sum of digit at odd and even position
  nCheck = eSum + oSum;
  //calculate next multiple of ten of nCheck
  nextHighestTen = Math.ceil(nCheck * 0.1) * 10;
  nCheck = nextHighestTen - nCheck;

  if (nCheck === actualCheckDigit) {
    return null;
  }

  return { mod10Validation: true };
}
