import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FiStep } from '@fscd-intake/entities';

@Component({
  selector: 'fi-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent {
  @Input() applicationId;
  @Input() steps: FiStep[];
  @Output() stepNavigated = new EventEmitter<FiStep>();
  @Input() noPreviousErrors;
  _hasIssuesInConsent: boolean;
  @Input() set hasIssuesInConsent(value) {
    this._hasIssuesInConsent = value;
  }

  get hasIssuesInConsent() {
    return this._hasIssuesInConsent;
  }

  goToStep(url) {
    this.stepNavigated.emit(url);
  }
  get activeSteps() {
    return this.steps.filter((step) => step.active);
  }
}
