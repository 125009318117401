import { Injectable } from '@angular/core';
import { DashboardListApplication } from '../interfaces/dashboard-list-application';
import { FiChild, FscdIntakeApplication } from '@fscd-intake/entities';

@Injectable({
  providedIn: 'root',
})
export class FiUtils {
  hasNameMatch = (newChild: FiChild) => (listApp: DashboardListApplication) => {
    const isStringMatch = (str1, str2) => str1.toLowerCase().trim() === str2.toLowerCase().trim();

    const isMatchFirstName = isStringMatch(listApp.firstName, newChild.firstName);
    const isMatchMiddleName = isStringMatch(listApp.middleName, newChild.middleName);
    const isMatchLastName = isStringMatch(listApp.lastName, newChild.lastName);
    return isMatchFirstName && isMatchMiddleName && isMatchLastName;
  };

  createDashboardListApplication(application: FscdIntakeApplication): DashboardListApplication {
    const firstName = application?.child?.firstName?.trim() ?? '';
    const middleName = application?.child?.middleName?.trim() ?? '';
    const lastName = application?.child?.lastName?.trim() ?? '';
    const isNameKnown = !!(firstName || lastName);
    const fullName = [firstName, lastName].join(' ');

    const isRemoved = application?.isRemoved ?? false;
    const isSubmitted = application?.isSubmitted && !isRemoved;
    const isDraft = !isSubmitted && !isRemoved;
    const status = application?.isSubmitted ? 'Submitted' : '';

    return {
      id: application?.id ?? '',
      applicationId: application?.applicationNumber ?? '',
      isDraft,
      isSubmitted,
      isRemoved,
      status,
      firstName,
      middleName,
      lastName,
      childName: isNameKnown ? fullName : '',
      dateStarted: application?.createdAt ?? null,
      dateLastSaved: application?.updatedAt ?? null,
      dateSubmitted: application?.submittedOn ?? null,
      dateRemoved: application?.removedOn ?? null,
      outputPdfUrl: application?.outputPdfUrl ?? null,
      outputPdfFileName: application?.outputPdfFileName ?? null,
    };
  }
}
