import { Directive, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[mat-tabs-header-less]',
})
export class MatTabsHeaderLessDirective implements OnInit {
  constructor(private eleRef: ElementRef) {}

  @Input() applyDirective = false;

  ngOnInit(): void {
    if (this.applyDirective) {
      this.eleRef.nativeElement.children[0].style.display = 'none';
    }
  }
}
