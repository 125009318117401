import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const AishDependentLabels: IntakeLabelCollection = {
  livesWithParent: {
    label: 'Does the child live with you?',
  },
  firstName: {
    label: "Child's first name",
  },
  middleName: {
    label: "Child's middle name",
  },
  lastName: {
    label: "Child's last name",
  },
  firstNameAlt: {
    label: 'First Name',
  },
  middleNameAlt: {
    label: 'Middle Name',
  },
  lastNameAlt: {
    label: 'Last Name',
  },
  birthdate: {
    label: "Child's birthdate",
  },
  birthdateAlt: {
    label: 'Birth Date',
  },
  attendingHighSchool: {
    label: 'Is the child attending high school?',
  },
  attendingHighSchoolAlt: {
    label: 'Is the Dependent attending high school?',
  },
};
