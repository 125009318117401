import { Lookup } from './lookup.interface';

export interface IesNotWorkingReasonLookup extends Lookup {
  isOther?: boolean;
  isMedical?: boolean;
}

export const IesNotWorkingReasons: { [name: string]: IesNotWorkingReasonLookup} = {
  unemployed: { 
    code: 'unemployed', 
    displayValue: 'Unemployed', 
    isOther: false, 
    isMedical: false 
  },
  medicalReasons: { 
    code: 'medicalReasons', 
    displayValue: 'Not able to work for medical reasons', 
    isOther: false, isMedical: true 
  },
  maternalPaternalLeave: { 
    code: 'maternalPaternalLeave', 
    displayValue: 'Maternity/parental leave', 
    isOther: false, 
    isMedical: false 
  },
  compassionateLeave: { 
    code: 'compassionateLeave', 
    displayValue: 'On compassionate leave', 
    isOther: false, 
    isMedical: false 
  },
  selfEmployed: { 
    code: 'selfEmployed', 
    displayValue: 'Self-employed', 
    isOther: false, 
    isMedical: false 
  },
  strikeLockout: { 
    code: 'strikeLockout', 
    displayValue: 'Strike or lockout', 
    isOther: false, 
    isMedical: false 
  },
  laidoff: { 
    code: 'laidoff', 
    displayValue: 'Laid off', 
    isOther: false, 
    isMedical: false 
  },
  otherReasons: { 
    code: 'otherReasons', 
    displayValue: 
    'Not able to work for other reasons', 
    isOther: true, 
    isMedical: false 
  },
};

