import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CustomDateFormats } from '@common/constants';

@Pipe({
  name: 'dateMediumNoSeconds'
})
export class DateMediumNoSecondsPipe extends DatePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any): any {
    return super.transform(value, CustomDateFormats.MediumNoSeconds);
  }
}