<div
  class="version-header"
  data-cy="version-header"
  *ngIf="!dismissed && environmentName && environmentName !== 'PRODUCTION' && environmentName !== 'prod'"
>
  Environment: {{ environmentNameVersion }}
  <span class="close-icon" (click)="dismiss()">
    <a>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
      >
        <path
          d="M 15.99 14.54C 15.99 14.54 14.54 15.99 14.54 15.99 14.54 15.99 8 9.45 8 9.45 8 9.45 1.46 15.99 1.46 15.99 1.46 15.99 0.01 14.54 0.01 14.54 0.01 14.54 6.55 8 6.55 8 6.55 8 0.01 1.46 0.01 1.46 0.01 1.46 1.46 0.01 1.46 0.01 1.46 0.01 8 6.55 8 6.55 8 6.55 14.54 0.01 14.54 0.01 14.54 0.01 15.99 1.46 15.99 1.46 15.99 1.46 9.45 8 9.45 8 9.45 8 15.99 14.54 15.99 14.54Z"
        />
      </svg>
    </a>
  </span>
</div>
