<h2 data-cy="page-title">FSCD application</h2>
<p data-cy="description">
  Based on your answers, it appears you may not meet the minimum
  <a href="https://www.alberta.ca/fscd-eligibility.aspx" target="_blank">FSCD eligibility criteria.</a>
</p>

<div class="section">
  FSCD criteria:
  <ul class="goa-unordered-list">
    <li><span>This child is under the age of 18</span></li>
    <li><span>This child is a Canadian citizen or permanent resident</span></li>
    <li><span>This child is a resident of Alberta</span></li>
    <li><span>You are the parent or legal guardian of the child</span></li>
    <li><span>This child has a confirmed diagnosis or is in the process of receiving a diagnosis</span></li>
  </ul>
</div>

<section class="goa-callout">
  <goa-callout type="information" heading="For more information contact us at:">
    Alberta Supports Contact Center: <a href="tel:1-877-644-9992">1-877-644-9992</a>
    <br />
    TTY (province-wide) <a href="tel:1-800-232-7215">1-800-232-7215</a>
    <br />
    TTY (Edmonton) <a href="tel:780-427-9999">780-427-9999</a>
  </goa-callout>
</section>
<div class="contact">
  <p>
    You may also contact a
    <a href="https://www.alberta.ca/family-resource-centres.aspx" target="_blank"> family resource centre</a>
    near you for more information. Family resource centres assist individuals with disabilities and their families with
    accessing and navigating the variety of disability supports and services available in Alberta.
  </p>
</div>

<div>
  <div class="section section-buttons">
    <button data-cy="return_to_main" class="back-button" (click)="returnToPrescreenPage()" goa-button>
      Back to pre-screening
    </button>
    <button goa-button buttonType="secondary" class="leave-button" (click)="navToFscdHome()">
      Return to FSCD home
    </button>
  </div>
</div>
