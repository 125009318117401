import { OneToFiveScaleWithNA } from './assessment-scales';
import { SkillsLookup } from './skills-lookup.interface';

export const HealthFactors: { [name: string]: SkillsLookup } = {
  physicalHealth: {
    displayValue: 'Physical health',
    scale: OneToFiveScaleWithNA,
    helperText:
      'Physical health is impacted by a variety of factors including genetic and environmental, as well as personal choices made regarding diet and nutrition, exercise, and other life activities.',
    sampleQuestions: [
      {
        question: 'How would you rate your health and energy level right now?',
      },
      {
        question: 'What could you do this week that would improve your health or increase your energy levels?',
      },
      {
        question:
          'How do you think your physical condition might affect your ability to achieve success in the training or work that you are interested in?',
        followupQuestions: [
          {
            question: 'How might the work environment be altered to accommodate your condition?',
          },
          {
            question:
              'What are some related areas of work that you might consider that would offer greater opportunities for success?',
          },
          {
            question:
              'What kind of assistance might you require to be successful in the training program you hope to pursue?',
          },
        ],
      },
    ],
  },
  mentalHealth: {
    displayValue: 'Mental health',
    scale: OneToFiveScaleWithNA,
    helperText:
      'Mental health reflects a balance around all aspects of life; social, physical, emotional, and spiritual. It is an integral part of the individual’s overall health and well-being and has an impact on how they manage their surroundings and their life choices. Mental health goes beyond the absence of mental illness and is related to self-concept, relationships with others, and the ability to manage life effectively on a day-to-day basis.',
    sampleQuestions: [
      {
        question: 'Have you ever had any concerns regarding your mental health?',
        followupQuestions: [
          {
            question: 'If yes: Tell about those concerns.',
          },
          {
            question: 'What effect did this have on your work and life?',
          },
          {
            question: 'What have you done to address the concerns you had regarding your mental health?',
          },
        ],
      },
      {
        question: 'What kinds of things do you do to help you get some work/life balance?',
      },
    ],
  },
  familyHealth: {
    displayValue: 'Family health',
    scale: OneToFiveScaleWithNA,
    helperText:
      'Family health focuses on the well-being of dependents and/or a spouse/partner. The physical, emotional, and mental health of family members can affect the ability of a person to attend and perform at work or school. Care of dependents can impose restrictions on the availability for work with the added stress affecting concentration and the attention paid to work and training.',
    sampleQuestions: [
      {
        question: 'Tell me about your family and your responsibilities to your family.',
        followupQuestions: [
          {
            question: 'How might these responsibilities impact on the work or training that you are planning to enter?',
          },
        ],
      },
      {
        question: 'How has your family impacted on your work or training in the past?',
      },
      {
        question: 'What does your family do to support you?',
      },
    ],
  },
  addictions: {
    displayValue: 'Addictions',
    scale: OneToFiveScaleWithNA,
    helperText:
      'Addiction involves a psychological and/or physiological dependence on a drug. Severe symptoms may present when the addicted person stops taking the drug. Addictive behaviour functions to produce pleasure and/or relief from internal discomfort and is characterized by powerlessness to control the behaviour as well as continuation of the behaviour in the face of significant negative consequences.',
    sampleQuestions: [
      {
        question: 'How does your (drinking) (use of drugs) (gambling) affect your work and your life?',
      },
      {
        question: 'What steps have you taken to deal with your addiction?',
      },
      {
        question:
          'How might your participation in a treatment plan impact your participation in the work or training that you plan to enter?',
      },
    ],
  },
};
