import { FiLegalStatus } from '@common/constants';
import { AlbertaAddress, BasePerson, Document } from '@common/entities';
import { IsFullAddress, RequiredDocumentIn, RequiredDocumentsIn } from '@common/validation';
import { Expose, Type } from 'class-transformer';
import { IsDefined, IsNotEmpty, Length, ValidateIf, ValidateNested } from 'class-validator';

export class FiChild extends BasePerson {
  @Expose()
  id?: string;

  @Expose()
  preferredName?: string;

  @Expose()
  @IsDefined()
  hasPrevApplication?: boolean;

  @Expose()
  @IsDefined()
  @ValidateIf((obj) => obj.hasPrevApplication)
  hasPrevClosedWithin90?: string;

  @Expose()
  @IsNotEmpty()
  citizenImmigrationStatus?: string;

  @Expose()
  @Type(() => Document)
  @ValidateNested({ each: true })
  identityDocuments?: Document[];

  @Expose()
  @Type(() => AlbertaAddress)
  @ValidateNested()
  @IsFullAddress()
  residentialAddress?: AlbertaAddress;

  @Expose()
  @IsDefined()
  hasSameMailingAddress?: boolean;

  @Expose()
  @Type(() => AlbertaAddress)
  @ValidateIf((o) => o.hasSameMailingAddress == false)
  @ValidateNested()
  @IsFullAddress()
  mailingAddress?: AlbertaAddress;

  @Expose()
  @IsNotEmpty()
  livingArrangements?: string;

  @Expose()
  @IsDefined()
  hasConfirmedDiagnosis?: boolean;

  @Expose()
  @IsDefined()
  @ValidateIf((o) => o.hasConfirmedDiagnosis)
  @Length(1, 250)
  confirmedDiagnosis?: string;

  @Expose()
  @IsDefined()
  isAwaitingDiagnosis?: boolean;

  @Expose()
  @IsDefined()
  @ValidateIf((o) => o.isAwaitingDiagnosis)
  @Length(1, 250)
  waitingDiagnosis?: string;

  @Expose()
  @Type(() => Document)
  @ValidateNested({ each: true })
  @RequiredDocumentsIn('medical')
  medicalDocuments?: Document[];

  @Expose()
  @Type(() => Document)
  @ValidateIf((o) => o.citizenImmigrationStatus == FiLegalStatus.citizen.code)
  @RequiredDocumentIn('citizen')
  get citizenDocument(): Document {
    return this.identityDocuments ? this.identityDocuments.find((d) => d.documentCategory === 'citizen') : undefined;
  }

  @Expose()
  @Type(() => Document)
  @ValidateIf((o) => o.citizenImmigrationStatus == FiLegalStatus.permanentResident.code)
  @RequiredDocumentIn('permanentResident')
  get permanentResidentDocument(): Document {
    return this.identityDocuments
      ? this.identityDocuments.find((d) => d.documentCategory === 'permanentResident')
      : undefined;
  }

  @Expose()
  @Type(() => Document)
  @ValidateNested({ each: true })
  additionalDocuments?: Document[];
}
