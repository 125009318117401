import { Operation, applyPatch, BaseOperation } from 'fast-json-patch';
import { compare } from 'json-patch-extended';
import { jsonDateParser } from 'json-date-parser';
import { PatchEntity, PatchesEntity } from '../entities/patches.entity';
import { BaseHistoryEntity } from '../entities/base-history.entity';

interface ExtendedOperation<T> extends BaseOperation {
  value: T | string;
  old: T | string;
}
export class JsonPatch {
  static patchToStringValues<T>(op: ExtendedOperation<T>) {
    //because of mongoose behavior, safer to modify using foreach over object spread
    op.value = JSON.stringify(op.value);
    op.old = JSON.stringify(op.old);
    return op;
  }
  static stringToPatch(value: string) {
    return JSON.parse(value, jsonDateParser);
  }
  static stringToPatchValues(op: PatchEntity) {
    return {
      ...op,
      value: this.stringToPatch(op.value),
      old: op.old ? this.stringToPatch(op.old) : undefined,
    } as Operation;
  }
  static patch(ops: Array<PatchEntity>, document = {}) {
    return applyPatch(
      document,
      ops.map((op) => this.stringToPatchValues(op)),
      false //we aren't going to validate, because plugins can add values (like deleted) that never get teh "add" but then barf on the "update"
    );
  }

  static compare<T>(document1: T, document2: T) {
    //json-patch-extended used as it retains old value in delta
    return compare(document1, document2);
  }
  static patchHistory(originalValue, historyCollection: Array<PatchesEntity>, date: Date, document = {}) {
    try {
      const filteredPatch = historyCollection.filter((patch) => patch.date <= date);
      if (filteredPatch.length > 0) {
        filteredPatch
          .sort((a, b) => a.date.valueOf() - b.date.valueOf())
          .forEach((patch) => this.patch(patch.ops, document));

        document['_id'] = originalValue?._id;
        return document;
      } else return undefined;
    } catch {
      console.warn(`History patch failed for entity ${originalValue._id}`);
      return originalValue;
    }
  }

  static patchCollectionHistory<T extends BaseHistoryEntity>(entities: T[], date: Date) {
    return entities
      .filter((entity) => Array.isArray(entity.history))
      .map((entity) => {
        const patch = this.patchHistory(entity, entity.history, date);
        return patch;
      })
      .filter((entity) => entity !== undefined);
  }

  static patchObjectAndKeys(object, patchDate) {
    const objectWithPatch = object.history ? this.patchHistory(object, object.history, patchDate) : object;

    return this.patchObjectKeys({ ...object, ...objectWithPatch }, patchDate);
  }

  static patchObjectKeys<T>(object: T, patchDate: Date) {
    const caseDto = Object.entries(object).reduce(
      (patchedObject, [key, value]) => ({
        ...patchedObject,
        [key]:
          value && value.history
            ? this.patchHistory(value, value.history, patchDate)
            : Array.isArray(value) && value.find((o) => o.history != undefined)
            ? this.patchCollectionHistory(value, patchDate)
            : value,
      }),
      {}
    );
    return caseDto;
  }
}
