import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe for sin.  Returns XXX XXX XXX
 */
@Pipe({ name: 'socialInsuranceNumber' })
export class SocialInsuranceNumberPipe implements PipeTransform {
  transform(sin: string, splitIncomplete = false): string {
    if (!sin) return sin;
    if (sin.length !== 9) {
      if (!splitIncomplete) return sin;
      if (splitIncomplete) {
        if (sin.length > 6) {
          return `${sin.slice(0, 3)} ${sin.slice(3, 6)} ${sin.slice(6, sin.length)}`;
        } else if (sin.length > 3) {
          return `${sin.slice(0, 3)} ${sin.slice(3, sin.length)}`;
        } else {
          return sin;
        }
      }
    }

    return `${sin.slice(0, 3)} ${sin.slice(3, 6)} ${sin.slice(6, 9)}`;
  }
}
