import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DashboardListApplication } from '../../shared/interfaces/dashboard-list-application';
import { BaseStepComponent } from './../base-step/base-step.component';
import { FiDashboardLabels } from '@common/labels';

@Component({
  selector: 'fi-application-list-mobile',
  templateUrl: './application-list-mobile.component.html',
  styleUrls: ['./application-list-mobile.component.scss'],
})
export class ApplicationListMobileComponent extends BaseStepComponent {
  submittedAppsCalloutText: string = FiDashboardLabels.submittedAppsNote.label;
  draftAppsCalloutText: string = FiDashboardLabels.draftAppsNote.label;
  removedAppsCalloutText: string = FiDashboardLabels.removedAppsNote.label;

  @Input() tabIndex: number;
  @Input() submittedList: DashboardListApplication[];
  @Input() draftList: DashboardListApplication[];
  @Input() removedList: DashboardListApplication[];

  @Output() continueApplication = new EventEmitter<DashboardListApplication>();
  @Output() viewApplication = new EventEmitter<DashboardListApplication>();
  @Output() isExpanded = new EventEmitter<boolean>();

  @ViewChild('submittedTab') submittedTabRef;
  @ViewChild('draftTab') draftTabRef;
  @ViewChild('removedTab') removedTabRef;

  openIndex = null;
  open = false;
  submittedOpen = false;
  draftOpen = false;
  removedOpen = false;

  constructor(route: ActivatedRoute, fb: FormBuilder, matDialog: MatDialog) {
    super(route, fb, matDialog);
  }

  onOpen(index) {
    this.openIndex = index;
    this.isExpanded.emit(true);
  }

  onClose(index) {
    if (this.openIndex === index) this.isExpanded.emit(false);
  }

  isLast(dashboardList: DashboardListApplication[], index: number): boolean {
    return dashboardList.length === index + 1;
  }

  onClickSubmittedView(app: DashboardListApplication) {
    this.viewApplication.emit(app);
  }

  onClickDraftContinue(app: DashboardListApplication) {
    this.continueApplication.emit(app);
  }

  onClickDraftRemove(app: DashboardListApplication) {
    this.removeAppDialog(app);
  }

  onClickRecover(app: DashboardListApplication) {
    this.recoverApplication.emit(app);
  }

  onClickDelete(app: DashboardListApplication) {
    this.deleteAppDialog(app);
  }

  dataCy(columnName: string) {
    return `application-list-mobile-${columnName}`;
  }
}
