<div
  class="copy-container"
  #tooltip="matTooltip"
  matTooltip="Copied"
  (mouseenter)="$event.stopImmediatePropagation()"
  (mouseleave)="$event.stopImmediatePropagation()"
>
  <div [ngClass]="{ 'max-length-content': extendSpace }"><ng-content></ng-content></div>
  <div
    class="material-icons copy-icon md-inactive"
    aria-hidden="true"
    aria-label="Copy"
    *ngIf="copyText && !swapIcon"
    (click)="$event.stopPropagation(); onClick()"
  >
    content_copy
  </div>
  <span class="material-icons copy-icon md-inactive highlight-icon" iesIconButton *ngIf="copyText && swapIcon"
    >check_circle</span
  >
</div>
