<section aria-labelledby="review-section-label">
  <h2 id="review-section-label" data-cy="page-title">Review application</h2>
  <div class="review-message" *ngIf="!noPreviousErrors">
    <div>There are errors or missing information in the following sections of your application.</div>
    <div>Note: you will not be able to submit your application until all of the required information is provided</div>
  </div>
  <div class="review-message" *ngIf="noPreviousErrors">
    <div>You have successfully completed all sections of the application.</div>
    <div>Note: This application is ready to be submitted if you are satisfied with all your answers.</div>
    <div>You may click on any step to make changes before you submit.</div>
  </div>
  <div class="review-results">
    <div
      class="review-step"
      *ngFor="let step of activeSteps; let $index = index"
      [ngClass]="{ 'has-issues': step.hasError }"
    >
      <div class="step-indicator">
        <div class="step-indicator-content">
          <mat-icon *ngIf="!step.hasError">done</mat-icon>
          <mat-icon *ngIf="step.hasError" style="width: 40px; height: 40px" svgIcon="stepper-error"></mat-icon>
        </div>
      </div>
      <div class="step-info">
        <div class="step-desc">
          <div class="step-name">{{ step.name }}</div>
          <div class="step-status" [innerHtml]="step.statusMessage"></div>
        </div>
        <div class="step-button-container">
          <button
            class="step-button"
            goa-button
            buttonType="primary"
            (click)="goToStep(step)"
            [attr.data-cy]="'step-' + ($index + 1)"
          >
            Go to {{ step.label }} page
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
