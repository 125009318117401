import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'common-searchable-dropdown',
  templateUrl: './searchable-dropdown.component.html',
  styleUrls: ['./searchable-dropdown.component.scss'],
})
export class SearchableDropdownComponent<T> implements OnInit, AfterViewInit {
  @ViewChild('matSelect') matSelect: MatSelect;

  /** control for the selected entity */
  @Input() searchCtrl: UntypedFormControl;

  /** This points to the filter/search field in the data entity. So, for a given entity, it would be "name" which points to Entity.name */
  @Input() dataTextFieldName: string;

  /** This points to the label to use.  If none is provided defaults to the textFieldName. */
  @Input() dataLabelFieldName: string;

  /** This points to the ID of the data entity. So, for a given entity, it would be "id" which points to Entity.id.*/
  @Input() dataIdFieldName: string;

  @Input() multiple: boolean;

  /**
   * Class string to pass into mat-panel
   */
  @Input() panelClass = '';

  @Input() matchBeginningOnly = false;

  @Input() highlightMatches = false;

  @Input() placeholderLabel: string;
  @Input() noEntriesFoundLabel: string;
  @Input() returnIdField: boolean;
  @Output() selectionChange = new EventEmitter<T[] | T>();

  compareByIdFunction;
  /** control for the MatSelect filter keyword */
  filterCtrl: UntypedFormControl = new UntypedFormControl('');

  /** list of entities filtered by search keyword */
  filteredData$: ReplaySubject<T[]> = new ReplaySubject<T[]>(1);
  filteredSelectionsCache: T[] = [];
  isIndeterminate = false;
  isChecked = false;

  searchText = '';

  private _data: T[];
  /** the data to bind to the dropdown */
  @Input() set data(value: T[]) {
    this._data = value;

    if (value) {
      this.setInitialValue();
    }
  }
  get data() {
    return this._data;
  }

  @Input() allowClearing: boolean;
  @Input() disabled: boolean;

  /** Subject that emits when the component has been destroyed. */
  constructor(private cdr: ChangeDetectorRef) {
    //this.compareByIdFunction = this.compareById.bind(this);
  }
  ngOnInit(): void {
    this.searchCtrl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.filter();
      this.setToggleAllCheckboxState();
    });

    // listen for search field value changes
    this.filterCtrl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.searchText = this.filterCtrl.value;
      this.filter();
      this.setToggleAllCheckboxState();
    });
  }

  ngAfterViewInit(): void {
    this.setInitialValue();
  }

  onSelectionChange(value) {
    this.selectionChange.emit(value);
  }

  getDisplayText(entity) {
    return this.dataLabelFieldName ? entity[this.dataLabelFieldName] : entity[this.dataTextFieldName];
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredData$.pipe(take(1), untilDestroyed(this)).subscribe((val) => {
      if (selectAllValue) {
        const valsOrIds = val?.map((v) => (this.returnIdField ? v[this.dataIdFieldName] : v));
        this.searchCtrl.patchValue(valsOrIds);
        this.selectionChange.emit(valsOrIds);
      } else {
        this.searchCtrl.patchValue([]);
        this.selectionChange.emit([]);
      }
    });
  }

  /**
   * Sets the initial value after the filteredData is loaded initially
   */
  private setInitialValue() {
    if (this.data) {
      this.filteredData$.next(this.data.slice());
    }

    if (this.matSelect) {
      this.filteredData$.pipe(take(1), untilDestroyed(this)).subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredData is loaded initially
        // and after the mat-option elements are available
        this.matSelect.compareWith = (a: T, b: T) => {
          // if both are "untruthy" they equal
          if (!a && !b) {
            return true;
          }

          return a && b && (this.returnIdField ? a === b : a[this.dataIdFieldName] === b[this.dataIdFieldName]);
        };
      });
    }
    this.cdr.detectChanges();
  }

  private filter() {
    if (!this.data) return;
    // get the search keyword
    let search = this.filterCtrl.value;
    if (!search) {
      this.filteredSelectionsCache = this.data.slice();
      this.filteredData$.next(this.filteredSelectionsCache);
      return;
    } else search = search.toLowerCase();

    // filter the data
    this.filteredSelectionsCache = this.data.filter((dataItem) => {
      const text = dataItem[this.dataTextFieldName] || '';
      const foundIndex = text.toLowerCase().indexOf(search);
      return this.matchBeginningOnly ? foundIndex === 0 : foundIndex > -1;
    });

    this.filteredData$.next(this.filteredSelectionsCache);
  }

  private setToggleAllCheckboxState() {
    let filteredLength = 0;
    if (this.multiple && this.searchCtrl && this.searchCtrl.value) {
      this.filteredSelectionsCache.forEach((el) => {
        if (this.searchCtrl.value.indexOf(el) > -1) {
          filteredLength++;
        }
      });
    }

    this.isIndeterminate = filteredLength > 0 && filteredLength < this.filteredSelectionsCache.length;
    this.isChecked = filteredLength > 0 && filteredLength === this.filteredSelectionsCache.length;
  }
}
