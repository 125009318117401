<div class="save-container">
  <span *ngIf="saveStatus !== undefined" [@enterAnimation]>
    <ng-container>
      <span
        class="save-icon status-display"
        *ngIf="saveStatus === SaveStatuses.Success || (saveStatus === SaveStatuses.Inactive && _dateSaved)"
      >
        <ng-container *ngIf="humanReadableDate$ | async as humanReadableDate">
          <span class="material-icons-outlined icon">check_circle</span>
          <span class="text">
            <span>Saved {{ humanReadableDate }}</span>
          </span>
        </ng-container>
      </span>

      <span class="saving-icon status-display" *ngIf="saveStatus === SaveStatuses.InProgress"
        ><span class="text">Saving...</span>
      </span>
      <span class="error-icon status-display" *ngIf="saveStatus === SaveStatuses.Error">
        <span class="material-icons-outlined icon">error_outline</span>
        <span class="text">Error </span>
      </span>
    </ng-container>
  </span>
</div>
