import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fi-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  copyrightYear: number;

  ngOnInit(): void {
    this.copyrightYear = new Date().getFullYear();
  }
}
