import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IndianRegistration } from '@common/entities';

@Component({
  selector: 'common-indian-status',
  templateUrl: './indian-status.component.html',
  styleUrls: ['./indian-status.component.scss'],
})
export class IndianStatusComponent implements OnInit {
  formGroup: UntypedFormGroup;
  @Input() addressee = 'you';
  @Input() dataCyPrefix = '';

  private _indianRegistration;
  @Input()
  set indianRegistration(value: IndianRegistration) {
    if (value) {
      this._indianRegistration = value;
      this.setFormData();
    }
  }
  get indianRegistration() {
    return this._indianRegistration;
  }

  constructor(private fb: UntypedFormBuilder, private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as UntypedFormGroup;
    this.buildFormGroup(this.formGroup);
    this.setFormData();
  }

  private buildFormGroup(formGroup: UntypedFormGroup) {
    formGroup.addControl(
      'band',
      this.fb.control(undefined, [Validators.required, Validators.pattern('[0-9][0-9]{2}')])
    );

    formGroup.addControl(
      'family',
      this.fb.control(undefined, [Validators.required, Validators.pattern('^(([0-9][0-9]{0,4})|(\\d{5}))$')])
    );
    formGroup.addControl(
      'position',
      this.fb.control(undefined, [Validators.required, Validators.pattern('[0-9][0-9]')])
    );
    formGroup.addControl('livesOnReserve', this.fb.control(undefined, [Validators.required]));
    formGroup.addControl('receivesFundsFromBand', this.fb.control(undefined, [Validators.required]));
  }
  private setFormData() {
    if (this.formGroup && this._indianRegistration) {
      this.formGroup.patchValue(this._indianRegistration, { emitEvent: false });
    }
  }
}
