import { PAGES } from './case-management-pages';
import { CssRoles } from '../css-roles';

const CanEditDataRoles = CssRoles.CaseValues.filter(
  (r) =>
    r.code !== CssRoles.CSSObserver.code &&
    r.code !== CssRoles.CSSInvestigator.code &&
    r.code !== CssRoles.AccountingOfficer.code &&
    // r.code !== CssRoles.ExternalServiceProvider.code &&
    r.code !== CssRoles.ContractProcurementSpecialist.code
);

const CaseManagementPagePermissions = {};
CaseManagementPagePermissions[PAGES.Audit.id] = {
  edit: [CssRoles.CECSupervisor, CssRoles.SFSCSupervisor],
  view: [],
};
CaseManagementPagePermissions[PAGES.PersonalInformation.id] = {
  edit: [
    CssRoles.CEC,
    CssRoles.DRES,
    CssRoles.SFSC,
    CssRoles.AdminAssistant,
    CssRoles.ARCAdvisor,
    CssRoles.ISCC,
    CssRoles.ChildSupportWorker,
    CssRoles.HealthFuneralBenefitsWorker,
    CssRoles.ExternalServiceProvider,
  ],
  view: [
    CssRoles.ASCC,
    CssRoles.ARCAdvisor,
    CssRoles.ISDE,
    CssRoles.CSSObserver,
    CssRoles.CSSInvestigator,
    CssRoles.AccountingOfficer,
    CssRoles.ExternalServiceProvider,
    CssRoles.ContractProcurementSpecialist,
  ],
  clientType: [CssRoles.CEC, CssRoles.SFSC],
  // Personal Details includes: Financail administrator, living condition, Is a sponsored immigrant,Indian registration number, Interpreter services required
  editPersonalDetails: [
    CssRoles.CEC,
    CssRoles.DRES,
    CssRoles.SFSC,
    CssRoles.AdminAssistant,
    CssRoles.ARCAdvisor,
    CssRoles.ISCC,
    CssRoles.ChildSupportWorker,
    CssRoles.HealthFuneralBenefitsWorker,
  ],
  editSpouseDependent: [
    CssRoles.CEC,
    CssRoles.DRES,
    CssRoles.SFSC,
    CssRoles.AdminAssistant,
    CssRoles.ARCAdvisor,
    CssRoles.ISCC,
    CssRoles.ChildSupportWorker,
    CssRoles.HealthFuneralBenefitsWorker,
  ],
};
CaseManagementPagePermissions[PAGES.Education.id] = {
  edit: [CssRoles.CEC, CssRoles.DRES, CssRoles.SFSC, CssRoles.AdminAssistant, CssRoles.ExternalServiceProvider],
  view: [
    CssRoles.ChildSupportWorker,
    CssRoles.CSSObserver,
    CssRoles.CSSInvestigator,
    CssRoles.HealthFuneralBenefitsWorker,
    CssRoles.ExternalServiceProvider,
  ],
};
CaseManagementPagePermissions[PAGES.Employment.id] = {
  edit: [
    CssRoles.CEC,
    CssRoles.DRES,
    CssRoles.SFSC,
    CssRoles.AdminAssistant,
    CssRoles.ISCC,
    CssRoles.ExternalServiceProvider,
  ],
  view: [
    CssRoles.ARCAdvisor,
    CssRoles.ChildSupportWorker,
    CssRoles.CSSObserver,
    CssRoles.CSSInvestigator,
    CssRoles.HealthFuneralBenefitsWorker,
    CssRoles.ExternalServiceProvider,
  ],
};
CaseManagementPagePermissions[PAGES.IncomesAndAssets.id] = {
  edit: [CssRoles.CEC, CssRoles.DRES, CssRoles.SFSC, CssRoles.ISCC, CssRoles.ChildSupportWorker],
  view: [
    CssRoles.ARCAdvisor,
    CssRoles.AdminAssistant,
    CssRoles.CSSObserver,
    CssRoles.CSSInvestigator,
    CssRoles.HealthFuneralBenefitsWorker,
  ],
};
CaseManagementPagePermissions[PAGES.Comments.id] = {
  edit: [
    CssRoles.CEC,
    CssRoles.DRES,
    CssRoles.SFSC,
    CssRoles.ASCC,
    CssRoles.ARCAdvisor,
    CssRoles.AdminAssistant,
    CssRoles.ISCC,
    CssRoles.ISDE,
    CssRoles.ChildSupportWorker,
    CssRoles.HealthFuneralBenefitsWorker,
    CssRoles.CSSInvestigator,
    CssRoles.AccountingOfficer,
    CssRoles.ExternalServiceProvider,
  ],
  view: [CssRoles.CSSObserver, CssRoles.ExternalServiceProvider, CssRoles.ContractProcurementSpecialist],
};
CaseManagementPagePermissions[PAGES.Reminders.id] = {
  edit: [
    CssRoles.CEC,
    CssRoles.DRES,
    CssRoles.SFSC,
    CssRoles.ISCC,
    CssRoles.ISDE,
    CssRoles.ChildSupportWorker,
    CssRoles.AdminAssistant,
    CssRoles.ExternalServiceProvider,
  ],
  view: [CssRoles.ASCC, CssRoles.ARCAdvisor, CssRoles.CSSObserver, CssRoles.HealthFuneralBenefitsWorker],
};
CaseManagementPagePermissions[PAGES.IntakeAssessment.id] = {
  edit: [CssRoles.CEC, CssRoles.DRES, CssRoles.ExternalServiceProvider],
  view: [
    CssRoles.SFSC,
    CssRoles.ASCC,
    CssRoles.ARCAdvisor,
    CssRoles.AdminAssistant,
    CssRoles.ISCC,
    CssRoles.ISDE,
    CssRoles.ChildSupportWorker,
    CssRoles.CSSObserver,
    CssRoles.CSSInvestigator,
    CssRoles.HealthFuneralBenefitsWorker,
    CssRoles.ExternalServiceProvider,
    CssRoles.ContractProcurementSpecialist,
  ],
  fullAssessment: [
    CssRoles.CEC,
    CssRoles.DRES,
    CssRoles.SFSC,
    CssRoles.ARCAdvisor,
    CssRoles.AdminAssistant,
    CssRoles.ISCC,
    CssRoles.ISDE,
    CssRoles.ChildSupportWorker,
    CssRoles.CSSObserver,
    CssRoles.CSSInvestigator,
    CssRoles.HealthFuneralBenefitsWorker,
    CssRoles.ExternalServiceProvider,
    CssRoles.ContractProcurementSpecialist,
  ],
  startIntake: [CssRoles.CEC, CssRoles.ASCC],
  move: [CssRoles.CSSAdmin],
  delete: [CssRoles.CSSAdmin],
};
CaseManagementPagePermissions[PAGES.ActionPlan.id] = {
  edit: [CssRoles.CEC, CssRoles.DRES, CssRoles.SFSC, CssRoles.ExternalServiceProvider],
  create: [CssRoles.CEC, CssRoles.DRES, CssRoles.ExternalServiceProvider],
  delete: [CssRoles.CECSupervisor, CssRoles.SFSCSupervisor],
  view: [
    CssRoles.ASCC,
    CssRoles.AdminAssistant,
    CssRoles.ISCC,
    CssRoles.ISDE,
    CssRoles.ChildSupportWorker,
    CssRoles.CSSObserver,
    CssRoles.CSSInvestigator,
    CssRoles.HealthFuneralBenefitsWorker,
    CssRoles.ExternalServiceProvider,
    CssRoles.ContractProcurementSpecialist,
  ],
  canAssign: [CssRoles.CEC, CssRoles.DRES, CssRoles.SFSC, CssRoles.AdminAssistant, CssRoles.ExternalServiceProvider],
  canRefer: [CssRoles.CEC, CssRoles.DRES],
};
CaseManagementPagePermissions[PAGES.Settings.id] = {
  edit: [...CssRoles.CaseValues],
  view: [],
};
CaseManagementPagePermissions[PAGES.AdminSettings.id] = {
  edit: [CssRoles.CSSAdmin],
};

CaseManagementPagePermissions[PAGES.WorkerDashboard.id] = {
  edit: [...CssRoles.CaseValues],
  view: [],
  //I think these actually make more sense as user settings, which default based on role.  But for now they are fine here
  hasCalendar: [CssRoles.CEC, CssRoles.DRES, CssRoles.SFSC, CssRoles.ExternalServiceProvider],
  hasActionPlanList: [
    CssRoles.CEC,
    CssRoles.DRES,
    CssRoles.SFSC,
    CssRoles.AdminAssistant,
    CssRoles.ExternalServiceProvider,
  ],
  hasReferralsList: [CssRoles.ExternalServiceProvider],
  hasIntakeList: [CssRoles.CEC],
  bulkReassignCases: [CssRoles.CECSupervisor, CssRoles.SFSCSupervisor, CssRoles.ExternalServiceProvider],
  canCreateClient: [
    CssRoles.CEC,
    CssRoles.DRES,
    CssRoles.AdminAssistant,
    CssRoles.ASCC,
    CssRoles.ISCC,
    CssRoles.HealthFuneralBenefitsWorker,
    CssRoles.ExternalServiceProvider,
  ],
  hasTeam: [CssRoles.CECSupervisor, CssRoles.SFSCSupervisor],
};

CaseManagementPagePermissions[PAGES.AuthManagement.id] = {
  edit: [CssRoles.AuthManager, CssRoles.CSSAdmin],
  view: [],
};

CaseManagementPagePermissions[PAGES.CommentTemplates.id] = {
  edit: [CssRoles.CSSManager],
};

CaseManagementPagePermissions[PAGES.FileHistory.id] = {
  view: [...CssRoles.CaseValues],
};

CaseManagementPagePermissions[PAGES.FileRestrictionManagement.id] = {
  edit: [CssRoles.CSSManager],
};

CaseManagementPagePermissions['Reports'] = {
  view: [CssRoles.CEC, CssRoles.DRES, CssRoles.SFSC],
  individualReport: [CssRoles.CEC, CssRoles.DRES, CssRoles.SFSC],
  regionReport: [CssRoles.CSSManager],
  auditReport: [CssRoles.CSSManager, CssRoles.CECSupervisor, CssRoles.SFSCSupervisor],
};

const CaseManagementDataPermissions = {
  edit: [...CanEditDataRoles],
  view: [...CssRoles.CaseValues],
  Comments: [...CssRoles.CaseValues],
  IntakeAssessment: [CssRoles.CEC, CssRoles.DRES, CssRoles.ExternalServiceProvider],
  FileRestrictions: [CssRoles.CSSManager],
  move: [CssRoles.CSSAdmin],
  delete: [CssRoles.CSSAdmin],
  // ActionPlan: {
  // do we need to go finer grained then edit/view on general data?
  // }
};

export { CaseManagementPagePermissions, CaseManagementDataPermissions };
