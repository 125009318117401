import { Lookup } from './lookup.interface';
export const WithdrawReasons: { [name: string]: Lookup } = {
  UnableToContact: { displayValue: 'Unable to contact' },
  AtApplicantsRequest: { displayValue: 'At applicant’s request' },
  DocumentsNotProvided: { displayValue: 'Documents not provided' },
  DeterminedToBeNonEmergent: { displayValue: 'Determined to be non-emergent' },
  RedirectedToUkrainianQueue: { displayValue: 'Redirected to Ukrainian queue' },
  RedirectedToOtherServices: { displayValue: 'Redirected to other services' },
  ReferredToFileReviewTeam: { displayValue: 'Referred to file review team' },
};
