import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import { MaintenanceService, ScheduledMaintenance } from '../service/maintenance.service';
import { filter, switchMap } from 'rxjs/operators';

dayjs.extend(isBetween);
@Component({
  selector: 'maintenance-mode-banner',
  templateUrl: './maintenance-mode-banner.component.html',
  styleUrls: ['./maintenance-mode-banner.component.scss'],
})
export class MaintenanceModeBannerComponent implements OnInit {
  @Input() appName = 'This application';

  scheduled: ScheduledMaintenance;
  bannerVisible = false;

  constructor(private maintenanceService: MaintenanceService, private router: Router) {
    this.scheduled = {
      active: false,
      endsAfterNow: false,
      end: undefined,
      start: undefined,
    };
  }

  async ngOnInit() {
    await this.updateVisibility();

    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(async () => await this.updateVisibility());
  }

  async updateVisibility() {
    this.scheduled = await this.maintenanceService.scheduledMaintenance();
    this.bannerVisible = this.scheduled.endsAfterNow && !this.maintenanceService.bannerDismissed;
  }

  get formattedBannerMessage(): string {
    if (!this.scheduled.start || !this.scheduled.end) {
      return '';
    }

    const dayStart = dayjs(this.scheduled.start);
    const dayEnd = dayjs(this.scheduled.end);
    const now = dayjs();

    const duration = Math.max(1, dayEnd.diff(dayStart, 'hour', false));
    const startDateFormatted = dayStart.format('dddd, MMMM D, YYYY h:mm A');
    const endDateFormatted = dayEnd.format('dddd, MMMM D, YYYY h:mm A');
    const timeUnit = duration == 1 ? 'hour' : 'hours';

    const isInWindow = now.isBetween(dayStart, dayEnd, 'minute');

    return isInWindow
      ? `Currently scheduled maintenance.  ${this.appName} will be offline until ${endDateFormatted}`
      : `Upcoming scheduled maintenance.  ${this.appName} will be offline on ${startDateFormatted} for ${duration} ${timeUnit}`;
  }

  handleDismiss() {
    this.maintenanceService.dismissBanner();
    this.bannerVisible = false;
  }
}
