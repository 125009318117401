import gql from 'graphql-tag';
import { FSCD_APPLICATION_DETAIL, FSCD_APPLICATION_LIST } from './gql-fragments';

export const SELECTED_APPLICATION_QUERY = gql`
  query getSelectedApplication {
    selectedApplicationId @client
    isSubmitted @client
  }
`;

export const MY_APPLICATIONS_QUERY = gql`
  query myApplications($limit: Float!) {
    myApplications(limit: $limit) {
      ${FSCD_APPLICATION_LIST}
    }
  }
`;

export const APPLICATION_BY_ID_QUERY = gql`
  query application($id: String!) {
    application(id: $id) {
      ${FSCD_APPLICATION_DETAIL}
    }
  }
`;

export const SAVE_APPLICATION_MUTATION = gql`
  mutation saveApplication($applicationInput: ApplicationInput!) {
    saveApplication(applicationInput: $applicationInput) {
      ${FSCD_APPLICATION_DETAIL}
    }
  }
`;

export const ASSIGN_APPLICATION_NUMBER_MUTATION = gql`
  mutation assignApplicationNumber($id: String!) {
    assignApplicationNumber(id: $id) {
      ${FSCD_APPLICATION_DETAIL}
    }
  }
`;

export const REMOVE_APPLICATION_MUTATION = gql`
  mutation removeApplication($id: String!) {
    removeApplication(id: $id) {
      id
    }
  }
`;

export const RECOVER_APPLICATION_MUTATION = gql`
  mutation recoverApplication($id: String!) {
    recoverApplication(id: $id) {
      id
    }
  }
`;

export const DELETE_APPLICATION_MUTATION = gql`
  mutation deleteApplication($id: String!) {
    deleteApplication(id: $id)
  }
`;
