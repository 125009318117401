import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { ContextProvider } from '../../services/context-provider/context.provider';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard {
  defaultNotAuthorized = '/not-authorized';

  constructor(protected readonly router: Router, private contextProvider: ContextProvider) {}

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const user = await this.contextProvider.getCurrentUser().pipe(first()).toPromise();

    if (user) {
      const allowedRoles = route.data['allowedRoles'].map((r) => r.code);
      const matchingRoles = allowedRoles?.filter((allowedRole) => user.roles.includes(allowedRole));
      if (matchingRoles?.length > 0) return true;
    }

    //whitelist so default to denied
    const notAuthorizedPage = route?.data?.notAuthorizedPage ?? this.defaultNotAuthorized;
    this.router.navigate([notAuthorizedPage]);
    return false;
  }
}
