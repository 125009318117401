import { CssRoles } from '@common/constants';
import * as dayjs from 'dayjs';
import * as isToday from 'dayjs/plugin/isToday';
import * as duration from 'dayjs/plugin/duration';
import * as relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(isToday);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export function isNullOrUndefined(val) {
  return val === null || val === undefined;
}

export function isNullUndefinedOrEmpty(val: string) {
  return isNullOrUndefined(val) || val === '';
}

export function getPrimaryRole(userRoleNames: string[]) {
  const userRoles = CssRoles.AllValues.filter((r) => userRoleNames && userRoleNames.includes(r.code));
  const userRolesByPriority = userRoles.sort((a, b) => a.priority - b.priority);
  return userRolesByPriority[0];
}

function getUnitPlural(baseUnitName: string, amount: number) {
  if (amount > 1) {
    return `${baseUnitName}s`;
  }

  return baseUnitName;
}

/* dayjs treats a month as 30 days always...
export function getNatualLanguageDateDiff(beforeDate: Date, endDate: Date) {
  const beforeDateDayjs = dayjs(endDate);
  const endDateDayjs = dayjs(beforeDate);
  const _duration = dayjs.duration(endDateDayjs.diff(beforeDateDayjs));

  const years = Math.abs(_duration.years());
  const months = Math.abs(_duration.months());
  const days = Math.abs(_duration.days());
  const hours = Math.abs(_duration.hours());
  const minutes = Math.abs(_duration.minutes());

  if (years) {
    if (months) {
      return `${years} ${getUnitPlural('year', years)} and ${months} ${getUnitPlural('month', months)}`;
    } else {
      return `${years} ${getUnitPlural('year', years)}`;
    }
  }

  if (months) {
    if (days) {
      return `${months} ${getUnitPlural('month', months)} and ${days} ${getUnitPlural('day', days)}`;
    } else {
      return `${months} ${getUnitPlural('month', months)}`;
    }
  }

  if (days) {
    if (hours) {
      return `${days} ${getUnitPlural('day', days)} and ${hours} ${getUnitPlural('hour', hours)}`;
    } else {
      return `${days} ${getUnitPlural('day', days)}`;
    }
  }

  if (hours) {
    if (minutes) {
      return `${hours} ${getUnitPlural('hour', hours)} and ${minutes} ${getUnitPlural('minute', minutes)}`;
    } else {
      return `${hours} ${getUnitPlural('hour', hours)}`;
    }
  }

  return _duration.humanize(false);
}
*/

export function getNatualLanguageDateDiff(beforeDate: Date, endDate: Date) {
  if (beforeDate > endDate) {
    const temp = endDate;
    endDate = beforeDate;
    beforeDate = temp;
  }
  //this doesn't account for daylight savings hours

  //years is the difference in years unless the before date is ahead in the month count (feb 2020 to jan 2020 = 0 years and 11 months). Then it is the difference in years - 1
  let years = endDate.getFullYear() - beforeDate.getFullYear();
  if (beforeDate.getMonth() > endDate.getMonth()) {
    years = years - 1;
  }

  //months is the difference in months unless before date is ahead in month of end date (feb 2020 to jan 2020 is 1 year 11 months). Then it is 12 months - the difference in months
  let months = endDate.getMonth() - beforeDate.getMonth();
  if (beforeDate.getMonth() > endDate.getMonth()) {
    months = 12 - (beforeDate.getMonth() - endDate.getMonth());
  }
  if (beforeDate.getDate() > endDate.getDate() && months > 0) {
    months = months - 1;
  }

  //days is the difference in days unless before day is ahead of end day ( feb 21 to march 14 ).  Then it is the number of days left in the before date month + enddate
  let days = endDate.getDate() - beforeDate.getDate();
  if (beforeDate.getDate() > endDate.getDate()) {
    // feb 26  march 4 = days in feb (28) - before (26) + end (4)
    days =
      new Date(beforeDate.getFullYear(), beforeDate.getMonth() + 1, -1).getDate() -
      beforeDate.getDate() +
      endDate.getDate();
  }
  if (days !== 0 && beforeDate.getHours() > endDate.getHours()) {
    days = days - 1;
  }

  //hours is the difference in hours unless before day is ahead of end day (13:00 to 9:00 = 20 hours).  Then it is 24 - the difference
  let hours = endDate.getHours() - beforeDate.getHours();
  if (beforeDate.getHours() > endDate.getHours()) {
    hours = 24 - (beforeDate.getHours() - endDate.getHours());
  }
  if (beforeDate.getMinutes() > endDate.getMinutes() && hours > 0) {
    hours = hours - 1;
  }

  //minutes is the difference in minutes unless before day is ahead of end day (23 minutes to 20 minutes).  Then it is 60 - the difference
  let minutes = endDate.getMinutes() - beforeDate.getMinutes();
  if (beforeDate.getMinutes() > endDate.getMinutes()) {
    minutes = 60 - (beforeDate.getMinutes() - endDate.getMinutes());
  }
  if (beforeDate.getSeconds() > endDate.getSeconds() && minutes > 0) {
    minutes = minutes - 1;
  }

  if (years) {
    if (months) {
      return `${years} ${getUnitPlural('year', years)} and ${months} ${getUnitPlural('month', months)}`;
    } else {
      return `${years} ${getUnitPlural('year', years)}`;
    }
  }

  if (months) {
    if (days) {
      return `${months} ${getUnitPlural('month', months)} and ${days} ${getUnitPlural('day', days)}`;
    } else {
      return `${months} ${getUnitPlural('month', months)}`;
    }
  }

  if (days) {
    if (hours) {
      return `${days} ${getUnitPlural('day', days)} and ${hours} ${getUnitPlural('hour', hours)}`;
    } else {
      return `${days} ${getUnitPlural('day', days)}`;
    }
  }

  if (hours) {
    if (minutes) {
      return `${hours} ${getUnitPlural('hour', hours)} and ${minutes} ${getUnitPlural('minute', minutes)}`;
    } else {
      return `${hours} ${getUnitPlural('hour', hours)}`;
    }
  }

  if (minutes) {
    return `${minutes} ${getUnitPlural('minute', minutes)}`;
  }

  return 'a few seconds';
}
