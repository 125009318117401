<meta name="version" [content]="appVersion" />
<common-environment-header
  *ngIf="!isProd"
  [applicationVersion]="applicationVersion$ | async"
></common-environment-header>

<div class="header-wrapper" #header data-cy="goa-header">
  <goa-microsite-header type="live"></goa-microsite-header>
  <goa-app-header
    url="/"
    heading="Family Support for Children with Disabilities (FSCD)"
    class="desktop"
  ></goa-app-header>
  <goa-app-header url="/" heading="FSCD" class="mobile"></goa-app-header>
  <!-- <goa-header
    serviceHome="/"
    serviceName="Family Support for Children with Disabilities (FSCD)"
    serviceLevel="Live"
    class="desktop"
  ></goa-header>
  <goa-header serviceHome="/" serviceName="FSCD" serviceLevel="Live" class="mobile"></goa-header> -->
  <div class="my-account">
    <fi-my-account #myAccount id="my-account-header" [applicationComponent]="applicationComponent"></fi-my-account>
  </div>
</div>

<div #contentSection class="content-section">
  <div class="goa-banner" iesGoaBanner role="banner">
    <div class="back-to-dashboard" *ngIf="isApplicationPage">
      <a
        class="link"
        role="link"
        (click)="goBackToDashboard()"
        (keydown.space)="goBackToDashboard()"
        (keydown.enter)="goBackToDashboard()"
      >
        <span class="arrow material-icons">arrow_back</span>&nbsp;
        <span class="text" tabindex="0">Back to homepage</span>
      </a>
      <hr />
    </div>
    <h1 id="intake-section-label">Family Support for Children with Disabilities (FSCD)</h1>
    <div class="descriptor" id="intake-section-sublabel">Apply for FSCD</div>
  </div>
  <maintenance-mode-banner appName="FSCD"></maintenance-mode-banner>
  <div [hidden]="!loading">
    <div>
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  </div>
  <main class="goa-content-section" aria-labelledby="intake-section-label intake-section-sublabel">
    <router-outlet (activate)="onActivate($event)" #appOutlet="outlet"> </router-outlet>
  </main>
</div>
<footer class="goa-footer">
  <a class="link" href="https://alberta.ca">Go to Alberta.ca</a>
  <span class="copyright">© {{ copyrightYear }} Government of Alberta</span>
</footer>
