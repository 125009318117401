import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundPageComponent } from './shared/pages/not-found-page/not-found-page.component';
import { AnonymousGuard, AuthenticationGuard } from '@common/ui/shared-components';
import { MaintenanceRedirectGuard } from '@common/ui/maintenance-mode';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { LayoutPageComponent } from './shared/pages/layout-page/layout-page.component';
import { PreScreenPageComponent } from './pages/pre-screen-page/pre-screen-page.component';
import { KnockoutPageComponent } from './pages/knockout-page/knockout-page.component';
import { ProceedWithApplicationPageComponent } from './pages/proceedwithapplication-page/proceedwithapplication-page.component';
import { ApplicationComponent } from './pages/application.component';
import { CHILD_INFO, PARENTAL_INFO, REVIEW, SERVICES } from './pages/application.steps';
import { ParentInfoPageComponent } from './pages/parent-info-page/parent-info-page.component';
import { ChildInfoPageComponent } from './pages/child-info-page/child-info-page.component';
import { IntakeApplicationAuthGuard } from './guards/intake-application.guard';
import { ReviewPageComponent } from './pages/review-page/review-page.component';
import { SessionTimeoutPageComponent } from './shared/pages/session-timeout-page/session-timeout-page.component';
import { SignedInRedirectGuard } from './guards/signed-in-redirect.guard';
import { ServiceErrorPageComponent } from './shared/pages/service-error-page/service-error-page.component';
import { ServicesPageComponent } from './pages/services-page/services-page.component';
import { ViewApplicationPageComponent } from './pages/view-application-page/view-application-page.component';

export const ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'main' },
  {
    path: '',
    component: LayoutPageComponent,
    canActivate: [AnonymousGuard],
    canActivateChild: [MaintenanceRedirectGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'main',
      },
      {
        path: 'main',
        pathMatch: 'full',
        canActivate: [SignedInRedirectGuard],
        component: MainPageComponent,
      },
      {
        path: 'prescreen',
        pathMatch: 'full',
        component: PreScreenPageComponent,
      },
      {
        path: 'knockout',
        pathMatch: 'full',
        component: KnockoutPageComponent,
      },
      {
        path: 'proceed',
        pathMatch: 'full',
        component: ProceedWithApplicationPageComponent,
      },
      {
        path: 'not-found',
        pathMatch: 'full',
        component: NotFoundPageComponent,
      },
      { path: 'session-expired', component: SessionTimeoutPageComponent },
      { path: 'serviceError', component: ServiceErrorPageComponent },
    ],
  },
  {
    path: '',
    component: LayoutPageComponent,
    canActivate: [AuthenticationGuard],
    canActivateChild: [MaintenanceRedirectGuard],
    children: [
      {
        path: 'dash',
        pathMatch: 'full',
        component: DashboardPageComponent,
      },
    ],
  },
  {
    path: 'application',
    component: LayoutPageComponent,
    canActivate: [AuthenticationGuard, IntakeApplicationAuthGuard],
    canActivateChild: [MaintenanceRedirectGuard],
    children: [
      {
        path: '',
        component: ApplicationComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: CHILD_INFO.url,
          },
          {
            path: CHILD_INFO.url,
            component: ChildInfoPageComponent,
          },
          {
            path: PARENTAL_INFO.url,
            component: ParentInfoPageComponent,
          },
          {
            path: SERVICES.url,
            component: ServicesPageComponent,
          },
          {
            path: REVIEW.url,
            component: ReviewPageComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'view-application',
    component: LayoutPageComponent,
    canActivate: [AuthenticationGuard, IntakeApplicationAuthGuard],
    canActivateChild: [MaintenanceRedirectGuard],
    children: [
      {
        path: '',
        component: ViewApplicationPageComponent,
      },
    ],
  },
  { path: '**', redirectTo: 'not-found' },
];
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class FscdIntakeRoutingModule {}
