import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentsFilter',
  pure: false,
})
export class TrueFalsePipe implements PipeTransform {
  transform(items: [], filter) {
    if (!items || !filter) return items;

    const countOfBiological = items.filter((item) => item['biologicalChild'] == true).length;
    return countOfBiological > 1 ? true : false;
  }
}
