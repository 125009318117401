import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from '@common/ui/shared-components';

@Component({
  selector: 'fi-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private analyticsService: GoogleAnalyticsService) {}

  async ngOnInit() {
    await this.analyticsService.init();
  }
}
