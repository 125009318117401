<fi-review
  [steps]="steps"
  (stepNavigated)="onStepNavigated($event)"
  [applicationId]="applicationId"
  [noPreviousErrors]="noPreviousErrors"
  [hasIssuesInConsent]="hasIssuesInConsent"
>
</fi-review>

<fi-consent
  [applicationId]="applicationId"
  [review]="review$ | async"
  [hasErrors]="hasErrors$ | async"
  (formError)="onFormError()"
  (formUpdated)="onFormUpdated($event)"
  (saveReview)="saveReview()"
  [noPreviousErrors]="noPreviousErrors"
></fi-consent>
