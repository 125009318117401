<div *ngIf="application">
  <section class="view-application-header">
    <div class="view-application-callout">
      <goa-callout type="success">
        <h1 data-cy="page-title">
          Application successfully submitted: <strong>{{ application.submittedOn | date: 'longDate' }}</strong>
        </h1>
        <h1>
          <common-copy [copyText]="application.applicationNumber">
            <strong>Application number is {{ application.applicationNumber || '-' }}</strong>
          </common-copy>
        </h1>
        <div class="mt-15" data-cy="successMessage">
          <p>
            Your application has been successfully sent to FSCD. We will contact you if further information is needed.
          </p>
        </div>
        <div class="mt-30">
          <a
            tabindex="0"
            role="link"
            (click)="onClickDownloadPdf()"
            (keydown.space)="onClickDownloadPdf()"
            (keydown.enter)="onClickDownloadPdf()"
            >Download PDF application</a
          >
          <p>You can view, download, and print from here.</p>
        </div>
      </goa-callout>
    </div>

    <goa-button (_click)="onBackToDashboard()" class="action-button" data-cy="back-to-dashboard-button" type="primary"
      >Back to homepage</goa-button
    >
  </section>

  <div class="view-application-tabs-text">
    <p>Click through the tabs to review your submitted FSCD application.</p>
  </div>

  <!-- non-mobile tabs -->
  <section class="view-application-tabs mt-15">
    <mat-tab-group mat-stretch-tabs="false" animationDuration="0" selectedIndex="0">
      <mat-tab [label]="childLabels.header.label">
        <fi-view-application-child [application]="application"></fi-view-application-child>
      </mat-tab>
      <mat-tab [label]="parentLabels.header.label">
        <fi-view-application-parent [application]="application"></fi-view-application-parent>
      </mat-tab>
      <mat-tab [label]="servicesLabels.header.label">
        <fi-view-application-services [services]="application.services"></fi-view-application-services>
      </mat-tab>
    </mat-tab-group>
  </section>

  <!-- mobile accordion -->
  <section class="view-application-accordion-mobile mt-15">
    <fi-view-application-tabs-mobile-view>
      <fi-view-application-child slot-child [application]="application"></fi-view-application-child>
      <fi-view-application-parent slot-parent [application]="application"></fi-view-application-parent>
      <fi-view-application-services slot-services [services]="application.services"></fi-view-application-services>
    </fi-view-application-tabs-mobile-view>
  </section>

  <!-- mobile buttons -->
  <goa-button
    (_click)="onBackToDashboard()"
    class="action-button show-mobile"
    data-cy="back-to-dashboard-button-mobile"
    type="primary"
  >
    Back to homepage</goa-button
  >
</div>
