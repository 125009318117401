export class CanadaPostAddressDetails {
  Id: string;
  SubBuilding: string;
  BuildingNumber: string;
  Street: string;
  City: string;
  ProvinceName: string;
  ProvinceCode: string;
  PostalCode: string;
}
