import { Component, OnInit, Input } from '@angular/core';
// import { AppConfigService } from '../ui-shared-components.services';
import { AppConfigService } from '../shared/services/config/config.service';

@Component({
  selector: 'common-environment-header',
  templateUrl: './environment-header.component.html',
  styleUrls: ['./environment-header.component.scss'],
})
export class EnvironmentHeaderComponent implements OnInit {
  dismissed = false;
  environmentName: string;
  @Input() applicationVersion: string;

  constructor(private configService: AppConfigService) {}

  ngOnInit(): void {
    this.environmentName = this.configService.getConfig().ENVIRONMENT_NAME;
  }

  get environmentNameVersion() {
    return this.applicationVersion ? `${this.environmentName} - v${this.applicationVersion}` : this.environmentName;
  }

  dismiss() {
    this.dismissed = true;
  }
}
