import { NoYesScale, NoYesScaleWithNa, OneToFiveScaleWithNA, YesNoScale } from './assessment-scales';
import { SkillsLookup } from './skills-lookup.interface';

export const LifeManagementSkills: { [name: string]: SkillsLookup } = {
  selfAwarenessAndConfidence: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Self-awareness and confidence',
    helperText:
      'Self-awareness involves self-knowledge regarding personal assets that can include personal attributes such as strengths, skills, attitudes, values, and interests as well as personal relationships and material assets.  Confidence relates to possessing a feeling of self-assurance or belief in oneself.',
    sampleQuestions: [
      {
        question: 'What are your top three skills and how have you used them in the past?',
      },
      {
        question:
          'Tell me about something that you have had to do that you have found challenging and how you followed through with it.',
      },
      {
        question: 'What have you done in the past couple of years that was new to you and how did it go for you?',
      },
    ],
  },
  stressManagement: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Stress management',
    helperText:
      'Stress is the feeling experienced when perceived demands exceed the personal and social resources that the individual is able to mobilize. People tend to feel little, if any stress, when they are able to meet the demands on their time and energy. However, as these demands exceed what they believe they can handle, they experience stress. In this context, stress is seen as a negative factor.',
    sampleQuestions: [
      {
        question: 'Tell me about a time that you experienced stress either at work or in other parts of your life.',
        followupQuestions: [
          { question: 'What did you do to manage this stress?' },
          {
            question:
              'If you were in the same situation again, what might you do differently to manage the stress that you experienced?',
          },
        ],
      },
      {
        question: 'What areas of the work/training that you are planning to enter might cause you some stress?',
        followupQuestions: [
          { question: 'What might you do to minimize the stress that you anticipate could come up?' },
        ],
      },
    ],
  },
  timeManagement: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Time management',
    helperText:
      'Time management refers to the ability to set priorities and complete daily activities both work and non-work in a timely fashion. Effective time management may include making decisions regarding scheduling and completing urgent and/or important activities. An additional aspect of time management may include the skill of delegation.',
    sampleQuestions: [
      {
        question:
          'Tell me about a time and how you managed, when it was important for you to manage your time effectively.',
      },
      {
        question: 'What challenges do you have in organizing tasks, meeting deadlines?',
      },
      {
        question:
          'What might be the positive and negative impacts of your time management skills in relation to your work and/or training goals?',
      },
      {
        question: 'What are some ideas on how to get things done when there is so much to do and not enough time?',
        followupQuestions: [{ question: 'Which of those ideas do you find most useful for your own time management?' }],
      },
    ],
  },
  financialManagement: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Financial management',
    helperText:
      'Personal financial management involves the skill of managing financial resources in order to meet financial obligations such as payments and to cover ongoing expenses such as food, housing, and personal items. Effectively managing debt as well as disposable money is another facet of financial management.',
    sampleQuestions: [
      {
        question: 'What monthly income do you require to meet your present financial needs?',
        followupQuestions: [
          {
            question:
              'How does that amount relate to the amount of money you anticipate earning in the work that you are seeking?',
          },
        ],
      },
    ],
  },
  positiveAttitude: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Positive attitude to learning, work, and health',
    helperText: `Attitudes toward learning, work, and health include individuals' beliefs, values, and related feelings and thoughts with regard to these issues. Individuals' attitudes toward these areas of their lives are related to past experiences, family, and cultural influences.`,
    sampleQuestions: [
      {
        question:
          'If things go really well for you with regard to work and the rest of your life, what does the future look like for you?',
      },

      {
        question: 'How is your health?',
        followupQuestions: [
          { question: 'How has your health affected your work in the past?' },
          { question: 'How might it affect your work and life in the future?' },
        ],
      },
      {
        question: 'Tell me about something new that you have learned in the past month.',
      },
    ],
  },
  abilityToManageChange: {
    scale: OneToFiveScaleWithNA,
    displayValue: 'Ability to manage change',
    helperText: `Change management refers to the ability to effectively respond to changes both personal and environmental that impact on work and life. It includes the skills of taking control of events related to change as much as possible. Planning and organizing life and work activities to maximize opportunities and to minimize challenges arising through change.`,
    sampleQuestions: [
      {
        question:
          'Tell me about some changes that you have experienced, either in your work or other parts of your life and tell me how you have dealt with them.',
      },

      {
        question: 'What changes do you anticipate will arise as you enter your preferred work or training program?',
        followupQuestions: [{ question: 'How will you manage these changes?' }],
      },

      {
        question:
          'If things go really well for you with regard to work and the rest of your life, what does the future look like for you?',
        followupQuestions: [
          { question: 'What are some of the things you might do to work towards the future that you envision?' },
          { question: 'What might get in the way of achieving your vision?' },
          { question: 'What might you do to get around these obstacles?' },
        ],
      },
    ],
  },
  childCare: {
    scale: NoYesScaleWithNa,
    displayValue: 'Access to child care',
    helperText: `Child care refers to the arrangements made to provide supervision and care to children. Child care options include private babysitting, daycare centres, family day homes, and before and after school care.`,
    sampleQuestions: [
      { question: 'What arrangements have you made for child care?' },
      {
        question:
          'Tell me about the arrangements you have in place for child care for times when the school schedule changes, for example, early dismissals, teacher’s convention, and so on.',
      },
      { question: 'What child care is available to you when your child is ill?' },
      {
        question:
          'How will your work schedule fit with your caregiver’s schedule especially with regard to drop-off and pick-up times for your children?',
      },
      { question: 'Where is your caregiver located in relation to your home and work?' },
    ],
  },
  transportation: {
    scale: NoYesScaleWithNa,
    displayValue: 'Access to transportation',
    helperText: `Transportation refers to all the various methods people use to travel around, with attention being focused on getting to training or to workplaces. In urban centres public transportation is usually reliable and cost-effective. However, shift work and worksites not located on public transit routes can create difficulties for individuals. Small towns and rural areas can be a greater challenge for people without a vehicle or the use of a vehicle.`,
    sampleQuestions: [
      { question: 'How will you get to college or work?' },
      { question: 'What is your usual method of transportation?' },
      { question: 'How reliable is your current way of getting from here to there?' },
      { question: 'Have you looked into all the alternative modes of transportation that are available?' },
      { question: 'What is your back-up plan in case your car breaks down or isn’t available to you?' },
    ],
  },
  internet: {
    scale: NoYesScale,
    displayValue: 'Access to internet',
    helperText: `Access to the internet and email is essential to job search and acceptance into training programs.`,
    sampleQuestions: [
      {
        question:
          'Tell me about what kind of access you have to internet (free public wifi, paid internet, mobile data).',
      },

      { question: 'Are you able to access free internet in public spaces like libraries, cafes, etc.?' },

      { question: 'How do employers contact you now?' },

      { question: 'How do you receive messages?' },
    ],
  },
  telephone: {
    scale: NoYesScale,
    displayValue: 'Access to telephone',
    helperText: `A telephone and message system is essential to job search and acceptance into training programs.`,
    sampleQuestions: [
      { question: 'Tell me about what kind of access you have to a telephone.' },

      { question: 'Do you have, and know how to use, voicemail?' },

      { question: 'How do employers contact you now?' },

      { question: 'How do you receive messages?' },
    ],
  },
  criminalRecord: {
    scale: YesNoScale,
    displayValue: 'Criminal record',
    helperText: `Various websites define a criminal record as a list of crimes for which an accused person has previously been convicted.
  Individuals with criminal records may face serious employment barriers. The unemployment rate for ex-offenders is about three times the national average. An individual on parole is four times more likely to return to prison when unemployed.
  Some types of employment require criminal record checks and bonding applications. Crimes involving property may make bonding costs prohibitive, thus preventing employment in certain fields.`,
    sampleQuestions: [
      { question: 'What challenges do you face in managing a criminal record?' },

      { question: 'Tell me what you have been doing to become a part of the community.' },

      { question: 'How has your criminal record influenced your job search so far?' },

      { question: 'What do you think an employer needs to hear from you in order to consider you for the job?' },
    ],
  },
  idAvailable: {
    scale: NoYesScale,
    displayValue: 'Has an ID',
    helperText: `Photo ID is an identity document that includes a photograph of the holder, usually only their face. The most commonly accepted forms of photo ID are those issued by government authorities such as a driver’s license or passport. ID is often required by workplaces or training programs to confirm the identity of the person.`,
    sampleQuestions: [{ question: 'What kind of ID do you have?' }],
  },
};
