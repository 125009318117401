import { Lookup } from './lookup.interface';

export class AssignmentType {
  static readonly Unassigned: Lookup = { displayValue: 'Unassigned Applications', code: 'Unassigned' };
  static readonly ProvincialEmergency: Lookup = { displayValue: 'Provincial Emergency', code: 'ProvincialEmergency' };
  static readonly Assigned: Lookup = { displayValue: 'Assigned Applications', code: 'Assigned' };
}

export const AssignmentTypes = {};
for (const [key, value] of Object.entries(AssignmentType)) {
  AssignmentTypes[key] = value;
}
