<div [formGroup]="formGroup">
  <common-form-control
    [label]="label"
    controlName="emailAddress"
    [isOptional]="!isRequired"
    [hideOptionalLabel]="hideOptionalLabel"
    [helperText]="helperText"
    [helperMessage]="helperMessage"
  >
    <input
      type="text"
      formControlName="emailAddress"
      [attr.data-cy]="dataCyPrefix + 'emailAddress'"
      class="email-input"
      [placeholder]="emailPlaceHolder"
      (blur)="onEmailBlur()"
      iesTrimPaste
      iesNoSpaces
      email
      autocomplete="email"
    />
  </common-form-control>

  <div *ngIf="formGroup.controls.confirmEmailAddress">
    <common-form-control [label]="confirmLabel" controlName="confirmEmailAddress">
      <input
        type="text"
        formControlName="confirmEmailAddress"
        [attr.data-cy]="dataCyPrefix + 'confirmEmailAddress'"
        class="email-input"
        [placeholder]="emailPlaceHolder"
        iesBlockCopyCutPaste
        (blur)="onEmailBlur()"
      />
    </common-form-control>
  </div>
</div>
