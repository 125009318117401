import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'yearMonth' })
export class YearMonthPipe implements PipeTransform {
  transform(value: string): string {
    if (!value || value.length !== 6) return '';
    const month = new Date(2020, +value.substring(4) - 1, 1).toLocaleString('en-CA', { month: 'long' });
    return `${month} ${value.substring(0, 4)}`;
  }
}
