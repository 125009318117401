import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@common/ui/material';
import { AngularComponentsModule } from '@abgov/angular-components';
import { UiSharedComponentsModule } from '@common/ui/shared-components';

import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { LayoutPageComponent } from './pages/layout-page/layout-page.component';
import { MaintenanceModeModule } from '@common/ui/maintenance-mode';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NotFoundPageComponent, FooterComponent, MyAccountComponent, LayoutPageComponent],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    AngularComponentsModule,
    UiSharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    MaintenanceModeModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    UiSharedComponentsModule,
    AngularComponentsModule,
    NotFoundPageComponent,
    FooterComponent,
    MyAccountComponent,
    LayoutPageComponent,
  ],
  providers: [],
})
export class SharedModule {}
