import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe for postal code.  Returns X1X X1X
 */
@Pipe({
  name: 'postalCode',
})
export class PostalCodePipe implements PipeTransform {
  transform(postalCode: string): string {
    if (!postalCode) {
      return postalCode;
    }

    return `${postalCode.slice(0, 3).toUpperCase()} ${postalCode.slice(3).toUpperCase()}`;
  }
}
