import { DefaultValueAccessor } from "@angular/forms";

export class TrimInputControls {
  static register(){
    const original = DefaultValueAccessor.prototype.registerOnChange;
    DefaultValueAccessor.prototype.registerOnChange = function (fn) {
      return original.call(this, (value) => {
        const trimmed = typeof value === 'string' ? value.trim() : value;
        return fn(trimmed);
      });
    };
  }
}