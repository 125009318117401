import { AlertLevel } from '../alert-levels';
import { Lookup } from '../lookup.interface';

export interface AlertLookup extends Lookup {
  additionalField?: string;
  additionalFieldOptions?: string[];
  descriptions: string[];
  level: AlertLevel;
  category?: number; // could be called id
  expiryInMonths?: number;
}

export const ClientAlerts: { [name: string]: AlertLookup } = {
  ApproachWithCaution: {
    descriptions: [
      'A client poses a threat to departmental workers. The client may have previously threatened or assaulted workers.',
    ],
    displayValue: 'Approach with caution',
    level: AlertLevel.Warning,
    category: 1,
  },
  HealthConcerns: {
    descriptions: [
      'A client is a known prescription drug abuser.  After-hours workers are alerted about issuing drugs.',
      'A client requires urgent medical attention and whereabouts of the client are unknown.',
      'A client is suicidal.',
    ],
    displayValue: 'Health concerns',
    level: AlertLevel.Caution,
    category: 2,
  },
  CommunicationRestrictionInEffect: {
    additionalField: 'Restriction type',
    additionalFieldOptions: [
      'Email only',
      'Phone only',
      'Canada Post/drop-off only',
      'Single point of contact',
      'Other',
    ],
    descriptions: [
      'CSS Security Services has been consulted. Client has been given official notice to communicate with government services in a specified manner. Indicate details regarding the type of communication permitted in the reason for alert field. All other communication methods are not permitted. Communication restrictions are reviewed by the area Manager and CSS Security Services at 120 days.',
    ],
    displayValue: 'Communication restriction in effect',
    level: AlertLevel.Warning,
  },
  TrespassNotificationInPlace: {
    descriptions: [
      'In exceptional circumstances, and in accordance with the Trespass to Premises Act, there may be occasions when a Complex Client should be banned from attending the workplace altogether in accordance with Section 2 of the Trespass to Premises Act. Trespass Notifications are provided to the client in consultation with CSS Security Services. Trespass Notifications are reviewed by the area Manager and CSS Security Services at 120 days.',
    ],
    displayValue: 'Trespass notification in place',
    level: AlertLevel.Warning,
  },
  SecurityBulletinInEffect: {
    descriptions: [
      'In exceptional circumstances, there may be occasions when a Security Bulletin has been issued pertaining to a Complex Client.  Security Bulletins are only issued by the Provincial or Ministry Security Services areas and should not be posted in a public area or shared to anyone other than its intended audience. Client has been issued a security bulletin by Corporate Security Services and will be reviewed/renewed/removed 120 days after issuance.',
    ],
    displayValue: 'Security bulletin in effect',
    level: AlertLevel.Warning,
  },
  KnownAliases: {
    descriptions: ['This individual has known aliases.'],
    displayValue: 'Known aliases',
    level: AlertLevel.Caution,
  },
  EligibilityConcerns: {
    descriptions: [
      'A client may be collecting assistance in another province.',
      'A client may be receiving undeclared income.',
      'A client has been assessed ineligible in one office and there is concern they may appear in another office claiming different circumstances.',
    ],
    displayValue: 'Eligibility concerns',
    category: 3,
    level: AlertLevel.Caution,
  },
  OutOfProvinceIncomeSupportConcerns: {
    descriptions: [
      'Another province is advising they have concerns about a client who may apply for assistance in Alberta.',
    ],
    displayValue: 'Out of province Income Support concerns',
    level: AlertLevel.Information,
    category: 4,
    expiryInMonths: 4,
  },
  OutOfProvinceChildWelfareConcerns: {
    descriptions: [
      'Another province advises workers about Child Welfare concerns for a client who has limited finances and may apply for assistance in Alberta.',
    ],
    displayValue: 'Out of province Child Welfare concerns',
    category: 5,
    expiryInMonths: 4,
    level: AlertLevel.Information,
  },
  ChildWelfareConcerns: {
    descriptions: [
      'Alberta Children’s Services has child welfare concerns about a client who may apply for assistance in Alberta.',
    ],
    displayValue: 'Child Welfare concerns',
    category: 6,
    level: AlertLevel.Information,
    expiryInMonths: 4,
  },
  AboriginalOrganizations: {
    descriptions: [
      'A client is being provided Income Support Services by an Aboriginal organization.  This alert is placed by Aboriginal organizations that have signed agreements to provide off-reserve services.',
    ],
    displayValue: ' Aboriginal organizations',
    category: 7,
    level: AlertLevel.Information,
  },
  AishAssetLimitExceeded: {
    descriptions: [
      `This alert code is placed when an applicant is denied AISH benefits due to excess assets as well as when a recipient’s file is closed due to excess assets.`,
    ],
    displayValue: 'AISH asset limit exceeded',
    level: AlertLevel.Information,
    category: 8,
  },
  ChildSupportServicesLearner: {
    descriptions: [
      'This alert is placed by Learner Income Support Office (LISO) staff when a PID is created on CCD, for a learner.',
    ],
    displayValue: 'Child Support Services learner',
    category: 9,
    level: AlertLevel.Information,
    expiryInMonths: 12,
  },
  Investigation: {
    descriptions: ['A closed file is under investigation and the fraud investigator has a specific concern.'],
    displayValue: 'Investigation',
    category: 21,
    level: AlertLevel.Caution,
  },
  NonCompliance: {
    descriptions: [
      'A file is to be closed or was closed because the client has not followed through with the Action Plan or did not cooperate with the worker.',
    ],
    displayValue: 'Non-compliance',
    category: 23,
    level: AlertLevel.Information,
    expiryInMonths: 6,
  },
  StudentFunding: {
    descriptions: [
      'This alert is placed on files of clients who are funded through the Shared Service Agreement with Alberta Advanced Education.',
    ],
    displayValue: 'Student funding',
    category: 24,
    level: AlertLevel.Information,
    expiryInMonths: 12,
  },
  Relocation: {
    descriptions: [
      'When a client requests a relocation allowance to move out of the province, they are required to sign a repayment agreement stating that if the client returns to Alberta and receives income support benefits within 12 months of receiving relocation costs (LISA need code 1833), the client must repay all of the repatriation costs.',
    ],
    displayValue: 'Relocation',
    category: 25,
    level: AlertLevel.Information,
    expiryInMonths: 12,
  },
  Recovery: {
    descriptions: ['The Minister has the authority to suspend recovery deductions.'],
    displayValue: 'Recovery',
    category: 26,
    level: AlertLevel.Information,
  },
  SponsoredImmigrant: {
    descriptions: ['Only for sponsored immigrants where sponsorship default has occurred.'],
    displayValue: 'Sponsored immigrant',
    level: AlertLevel.Information,
    category: 27,
  },
  NonComplianceChildSupportServices: {
    descriptions: [
      'To alert a worker of clients who were previously on income support and were non-compliant with Child Support Services.',
    ],
    displayValue: 'Non-compliance Child Support Services',
    category: 28,
    level: AlertLevel.Information,
    expiryInMonths: 12,
  },
  UnderpaymentOwing: {
    descriptions: [
      'This alert is placed on CCD when there is: an underpayment owing to a client, and the file is closed, and the worker is unable to locate the client.',
    ],
    displayValue: 'Underpayment owing',
    category: 29,
    level: AlertLevel.Information,
  },
};
