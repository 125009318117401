<!--div
  class="password-generator-container"
  #tooltip="matTooltip"
  matTooltip="Generate password"
  (mouseenter)="$event.stopImmediatePropagation()"
  (mouseleave)="$event.stopImmediatePropagation()"
>
  <div
    class="material-icons password-generator-icon md-inactive"
    aria-hidden="true"
    aria-label="Generate password"
    (click)="$event.stopPropagation(); onClick()"
  >
    password
  </div>
</div-->
<button goa-button buttonType="secondary" (click)="onClick()" data-cy="generate-password">Generate</button>
