import { Lookup } from './lookup.interface';

export class ReassignmentType {
  static readonly Worker: Lookup = { displayValue: 'Worker', code: 'Worker' };
  static readonly Region: Lookup = { displayValue: 'Region', code: 'Region' };
  static readonly HomeRegion: Lookup = { displayValue: 'Home region', code: 'HomeRegion' };
  static readonly ProvincialEmergency: Lookup = { displayValue: 'Provincial emergency', code: 'ProvincialEmergency' };
}

export const ReassignmentTypes = {};
for (const [key, value] of Object.entries(ReassignmentType)) {
  ReassignmentTypes[key] = value;
}
