// this was generated from the noc csv available on the government site by
// 1. converting csv to json array
// 2. combining all entries with same code
// 3. adding "Element Type Label English" === "All examples" or "Element Type Label English" === "Illustrative example(s)" to examples array
export const NationalOccupationCodes2017 = [
  {
    code: '1111',
    title: 'Financial auditors and accountants',
    examples: [
      'accountant',
      'chief accountant',
      'financial auditor',
      'income tax expert',
      'industrial accountant',
      'internal auditor',
    ],
  },
  {
    code: '1112',
    title: 'Financial and investment analysts',
    examples: [
      'chartered financial analyst',
      'financial analyst',
      'financial services research associate',
      'investment analyst',
      'money market analyst',
      'portfolio manager',
    ],
  },
  {
    code: '1113',
    title: 'Securities agents, investment dealers and brokers',
    examples: [
      'bond dealer',
      'broker',
      'commodity broker',
      'discount broker',
      'floor trader - commodity exchange',
      'foreign exchange trader',
      'investment dealer',
      'mutual fund broker',
      'mutual fund sales representative',
      'registered investment representative',
      'registered representatives supervisor',
      'securities agent',
      'securities sales agent',
      'securities trader',
      'stockbroker',
    ],
  },
  {
    code: '1114',
    title: 'Other financial officers',
    examples: [
      'account manager',
      'credit adjudicator',
      'credit unions examiner',
      'estate and trust administrator',
      'financial institutions inspector',
      'financial investigator',
      'financial planner',
      'financial underwriter',
      'mortgage broker',
      'trust officer',
    ],
  },
  {
    code: '1121',
    title: 'Human resources professionals',
    examples: [
      'classification officer - human resources',
      'classification specialist',
      'compensation research analyst',
      'conciliator',
      'employee relations officer',
      'employment equity officer',
      'human resources consultant',
      'human resources research officer',
      'job analyst',
      'labour organization business agent',
      'labour relations officer',
      'mediator',
      'union representative',
      'wage analyst',
    ],
  },
  {
    code: '1122',
    title: 'Professional occupations in business management consulting',
    examples: [
      'business management consultant',
      'business methods analyst',
      'health information management consultant',
      'ISO consultant',
      'management analyst',
      'operations management senior consultant',
      'organization and methods researcher',
      'organizational analysis consultant',
      'organizational analyst',
      'records management specialist',
    ],
  },
  {
    code: '1123',
    title: 'Professional occupations in advertising, marketing and public relations',
    examples: [
      'advertising consultant',
      'communications officer (except emergency services)',
      'communications specialist',
      'event marketing specialist',
      'fundraising consultant',
      'information officer',
      'literary agent',
      'media co-ordinator',
      'media relations officer',
      'museum educator',
      'performers agent',
      'press secretary',
      'public affairs officer',
      'public relations consultant',
      'public relations officer',
      'publicist',
      'publicity agent',
    ],
  },
  {
    code: '1211',
    title: 'Supervisors, general office and administrative support workers',
    examples: [
      'clerical supervisor',
      'data entry supervisor',
      'filing clerks supervisor',
      'hospital admitting clerks supervisor',
      'personnel clerks supervisor',
      'records office supervisor',
      'registry supervisor',
      'switchboard operators supervisor',
    ],
  },
  {
    code: '1212',
    title: 'Supervisors, finance and insurance office workers',
    examples: [
      'accounts payable supervisor',
      'accounts receivable supervisor',
      'bank clerks supervisor',
      'billing supervisor',
      'bookkeeping supervisor',
      'claims adjusters supervisor',
      'collection supervisor',
      'credit supervisor',
      'payroll supervisor',
      'supervisor of accounting',
    ],
  },
  {
    code: '1213',
    title: 'Supervisors, library, correspondence and related information workers',
    examples: [
      'advertising clerks supervisor',
      'correspondence clerks supervisor',
      'library clerks supervisor',
      'publication clerks supervisor',
      'statistical clerks supervisor',
      'survey interviewers supervisor',
    ],
  },
  {
    code: '1214',
    title: 'Supervisors, mail and message distribution occupations',
    examples: [
      'courier service supervisor',
      'letter carriers supervisor',
      'mail and postal clerks supervisor',
      'mail room supervisor',
      'messenger service supervisor',
      'postal station supervisor',
      'postmaster/mistress',
    ],
  },
  {
    code: '1215',
    title: 'Supervisors, supply chain, tracking and scheduling co-ordination occupations',
    examples: [
      'dispatch logistician',
      'flight crew scheduling supervisor',
      'freight forwarding logistician',
      'head dispatcher',
      'head shipper',
      'inventory control supervisor',
      'logistics supervisor - transportation',
      'parts service supervisor',
      'production clerks supervisor',
      'ramp services supervisor - airport',
      'receiving supervisor',
      'supply control co-ordinator',
    ],
  },
  {
    code: '1221',
    title: 'Administrative officers',
    examples: [
      'access to information and privacy officer',
      'administrative officer',
      'administrative services co-ordinator',
      'forms management officer',
      'office administrator',
      'office manager',
      'office services co-ordinator',
      'planning officer',
      'records analyst - access to information',
      'surplus assets officer',
      'university admissions officer',
    ],
  },
  {
    code: '1222',
    title: 'Executive assistants',
    examples: ['committee clerk', 'corporate secretary', 'executive assistant', 'legislative assistant'],
  },
  {
    code: '1223',
    title: 'Human resources and recruitment officers',
    examples: [
      'human resources officer',
      'personnel officer',
      'recruitment specialist',
      'staffing analyst',
      'staffing co-ordinator',
    ],
  },
  {
    code: '1224',
    title: 'Property administrators',
    examples: [
      'accommodation officer',
      'apartment rental agent',
      'housing project manager',
      'property administrator',
      'property leasing co-ordinator',
      'property rentals manager',
    ],
  },
  {
    code: '1225',
    title: 'Purchasing agents and officers',
    examples: [
      'contract management officer',
      'contracts officer',
      'energy asset surface land analyst',
      'furniture and furnishings purchaser',
      'government supply officer',
      'material management officer',
      'procurement officer',
      'purchasing agent',
    ],
  },
  {
    code: '1226',
    title: 'Conference and event planners',
    examples: [
      'conference and meeting planner',
      'conference planner',
      'conference services officer',
      'convention co-ordinator',
      'event planner',
      'festival organizer',
      'meeting planner',
      'special events organizer',
      'trade show planner',
    ],
  },
  {
    code: '1227',
    title: 'Court officers and justices of the peace',
    examples: [
      'commissioner of affidavits',
      'commissioner of marriages',
      'co-ordinator of court services',
      'court administrator',
      'court clerk supervisor',
      'court officer',
      'court registrar',
      'court services manager',
      'deputy registrar - courts',
      'judicial administrator',
      'judicial court administrator',
      'judicial officer',
      'justice of the peace',
      'registrar of bankruptcy',
      'Supreme Court registrar',
      'trial co-ordinator - courts',
    ],
  },
  {
    code: '1228',
    title: 'Employment insurance, immigration, border services and revenue officers',
    examples: [
      'border services officer (BSO)',
      'customs inspector',
      'customs officer',
      'employment insurance agent',
      'employment insurance benefits control officer',
      'immigration agent - government services',
      'immigration examining officer',
      'revenue officer',
      'tax collection officer',
      'tax enforcement officer',
    ],
  },
  {
    code: '1241',
    title: 'Administrative assistants',
    examples: [
      'administrative assistant',
      'executive secretary (except legal and medical)',
      'office administrative assistant',
      'private secretary',
      'secretary (except legal and medical)',
      'technical secretary',
    ],
  },
  {
    code: '1242',
    title: 'Legal administrative assistants',
    examples: [
      'administrative assistant - legal',
      'corporate law legal assistant',
      'legal assistant',
      'legal assistant - criminal law',
      'legal secretary',
      'litigation legal assistant',
      'litigation secretary',
      'real estate secretary',
    ],
  },
  {
    code: '1243',
    title: 'Medical administrative assistants',
    examples: ['administrative assistant - medical', 'medical secretary', 'ward secretary'],
  },
  {
    code: '1251',
    title: 'Court reporters, medical transcriptionists and related occupations',
    examples: [
      'closed captioner',
      'court reporter',
      'Hansard reporter',
      'medical transcriptionist',
      'transcriptionist',
    ],
  },
  {
    code: '1252',
    title: 'Health information management occupations',
    examples: [
      'health information management supervisor',
      'health information management technician',
      'health records technician',
      'medical records unit supervisor',
    ],
  },
  {
    code: '1253',
    title: 'Records management technicians',
    examples: [
      'information management technician',
      'microfilm records searcher',
      'records classifier',
      'records digitizing technician',
      'records technician',
    ],
  },
  {
    code: '1254',
    title: 'Statistical officers and related research support occupations',
    examples: [
      'research assistant - generalist (except university)',
      'research support officer',
      'social research assistant (except university)',
      'statistical officer',
      'technical research assistant (except post-secondary)',
    ],
  },
  {
    code: '1311',
    title: 'Accounting technicians and bookkeepers',
    examples: ['accounting bookkeeper', 'accounting technician', 'bookkeeper', 'finance technician'],
  },
  {
    code: '1312',
    title: 'Insurance adjusters and claims examiners',
    examples: ['adjuster', 'claims examiner', 'claims representative', 'insurance adjuster'],
  },
  {
    code: '1313',
    title: 'Insurance underwriters',
    examples: ['group underwriter', 'insurance underwriter', 'liability underwriter', 'property underwriter'],
  },
  {
    code: '1314',
    title: 'Assessors, valuators and appraisers',
    examples: [
      'accredited appraiser',
      'business valuator',
      'chartered business valuator (CBV)',
      'property assessor',
      'property valuator',
      'real estate appraiser',
    ],
  },
  {
    code: '1315',
    title: 'Customs, ship and other brokers',
    examples: [
      'cargo broker',
      'chartered shipbroker',
      'customs broker',
      'gas broker',
      'licensed customs broker',
      'shipbroker',
    ],
  },
  {
    code: '1411',
    title: 'General office support workers',
    examples: [
      'administrative clerk',
      'aircraft records clerk',
      'file and classification clerk',
      'filing clerk',
      'general office worker',
      'medical records clerk',
      'office assistant',
      'office clerk',
      'records filing-system clerk',
      'technical records clerk',
      'ward clerk - hospital',
      'warranty clerk',
    ],
  },
  {
    code: '1414',
    title: 'Receptionists',
    examples: [
      'answering service operator',
      'appointment clerk',
      'business receptionist',
      'dental receptionist',
      'hospital admitting clerk',
      'medical receptionist',
      'office reception clerk',
      'receptionist',
      'switchboard operator',
      'telephone operator',
      'telephone service assistant',
      'telereceptionist',
      'toll operator',
    ],
  },
  {
    code: '1415',
    title: 'Personnel clerks',
    examples: [
      'classification clerk - human resources',
      'employment clerk',
      'human resources assistant',
      'human resources clerk',
      'labour relations clerk',
      'personnel services clerk',
      'staffing clerk',
      'training clerk',
    ],
  },
  {
    code: '1416',
    title: 'Court clerks',
    examples: ['court clerk', 'court clerk-crier', 'criminal court clerk', 'judicial clerk', 'provincial court clerk'],
  },
  {
    code: '1422',
    title: 'Data entry clerks',
    examples: [
      'data control clerk',
      'data entry operator',
      'data input clerk',
      'data processor',
      'payment entry clerk',
    ],
  },
  {
    code: '1423',
    title: 'Desktop publishing operators and related occupations',
    examples: [
      'braille keyboard operator',
      'compositor - typesetting',
      'computer typesetter',
      'copy marker - typesetting',
      'desktop compositor',
      'desktop publishing (DTP) operator',
      'input operator - typesetting',
      'markup person',
      'output operator - typesetting',
      'photocomposition typesetter',
      'phototypesetter operator',
      'typographer',
    ],
  },
  {
    code: '1431',
    title: 'Accounting and related clerks',
    examples: [
      'accounting clerk',
      'accounts payable clerk',
      'accounts receivable clerk',
      'audit clerk',
      'billing clerk',
      'budget clerk',
      'costing clerk',
      'deposit clerk',
      'finance clerk',
      'freight-rate clerk',
      'income tax return preparer',
      'invoice clerk',
      'ledger clerk',
      'tax clerk',
    ],
  },
  {
    code: '1432',
    title: 'Payroll administrators',
    examples: [
      'benefits officer - payroll administration',
      'pay advisor',
      'pay and benefits administrator',
      'pay and benefits clerk',
      'pay clerk',
      'payroll clerk',
      'payroll officer',
      'salary administration officer',
    ],
  },
  {
    code: '1434',
    title: 'Banking, insurance and other financial clerks',
    examples: [
      'actuarial clerk',
      'bank clerk',
      'credit clerk',
      'dental claims clerk',
      'dividend calculation clerk',
      'insurance clerk - financial sector',
      'insurance rater',
      'ledger control clerk',
      'loan clerk - financial sector',
      'mortgage clerk',
      'premium rater - insurance',
      'real estate clerk',
      'securities clerk - financial sector',
    ],
  },
  {
    code: '1435',
    title: 'Collectors',
    examples: [
      'bill collector',
      'collection clerk',
      'collection officer (except taxation)',
      'collections investigation officer',
      'collector',
      'credit and collection clerk',
      'locator - collection',
      'skip tracer - collection',
    ],
  },
  {
    code: '1451',
    title: 'Library assistants and clerks',
    examples: [
      'circulation clerk - library',
      'interlibrary loan clerk',
      'library assistant',
      'library page',
      'periodicals clerk',
      'reference clerk',
      'shelving clerk - library',
    ],
  },
  {
    code: '1452',
    title: 'Correspondence, publication and regulatory clerks',
    examples: [
      'advertising clerk',
      'application clerk',
      'authorization clerk',
      'by-law clerk',
      'classified advertising clerk',
      'contract clerk',
      'correspondence clerk',
      'customs clerk',
      'directory compiler',
      'documentation clerk',
      'editorial assistant',
      'licence clerk',
      'passport clerk',
      'proofreader',
      'publication clerk',
      'reader',
      'registration clerk',
      'registry clerk',
      'translation clerk',
    ],
  },
  {
    code: '1454',
    title: 'Survey interviewers and statistical clerks',
    examples: [
      'census enumerator',
      'coding clerk - statistics',
      'election enumerator',
      'interview clerk',
      'poll clerk',
      'public opinion interviewer',
      'statistical clerk',
      'survey interviewer',
      'telephone survey clerk',
    ],
  },
  {
    code: '1511',
    title: 'Mail, postal and related workers',
    examples: [
      'bus parcel express clerk',
      'express mail service clerk',
      'mail clerk',
      'mail room clerk',
      'mail sorter',
      'postal clerk',
      'postal counter clerk',
      'postal wicket clerk',
      'railway mail clerk',
    ],
  },
  { code: '1512', title: 'Letter carriers', examples: ['letter carrier', 'postman/woman', 'rural mail carrier'] },
  {
    code: '1513',
    title: 'Couriers, messengers and door-to-door distributors',
    examples: [
      'bank messenger',
      'courier',
      'delivery person - courier service',
      'door-to-door distributor',
      'flyer distributor - courier service',
      'newspaper carrier',
      'runner - courier service',
    ],
  },
  {
    code: '1521',
    title: 'Shippers and receivers',
    examples: [
      'freight receiver',
      'freight shipper',
      'import freight clerk',
      'receiver',
      'shipper',
      'shipper-receiver',
      'shipping agent',
      'shipping and receiving clerk',
      'supply chain assistant',
    ],
  },
  {
    code: '1522',
    title: 'Storekeepers and partspersons',
    examples: [
      'ammunition storekeeper',
      'automotive partsperson - retail',
      'material keeper',
      'medical supply clerk',
      'motor vehicle parts clerk',
      'parts clerk',
      'parts supplier',
      'partsperson',
      'ship storeman/woman',
      'storekeeper',
      'tool room attendant',
    ],
  },
  {
    code: '1523',
    title: 'Production logistics co-ordinators',
    examples: [
      'expediter',
      'production clerk',
      'production co-ordinator',
      'production scheduler',
      'scheduling clerk',
      'supply chain co-ordinator - production',
      'traffic control clerk',
    ],
  },
  {
    code: '1524',
    title: 'Purchasing and inventory control workers',
    examples: [
      'inventory analyst',
      'inventory clerk',
      'inventory control clerk',
      'inventory planner',
      'procurement clerk',
      'purchasing clerk',
      'purchasing clerk assistant',
    ],
  },
  {
    code: '1525',
    title: 'Dispatchers',
    examples: [
      '911 dispatcher',
      'alarm system dispatcher',
      'ambulance dispatcher',
      'emergency vehicle dispatcher',
      'inbound/outbound freight co-ordinator',
      'mobile equipment dispatcher',
      'radio operator',
      'taxi dispatcher',
      'tow truck dispatcher',
      'truck dispatcher',
      'utilities maintenance crew dispatcher',
    ],
  },
  {
    code: '1526',
    title: 'Transportation route and crew schedulers',
    examples: [
      'bus scheduler',
      'crew scheduler - transportation',
      'flight crew scheduler',
      'schedule analyst',
      'train scheduler',
      'transit scheduler',
      'transportation schedules writer',
      'truck scheduler',
    ],
  },
  {
    code: '2111',
    title: 'Physicists and astronomers',
    examples: [
      'acoustics physicist',
      'aerodynamicist',
      'aerospace research scientist',
      'astronomer',
      'astrophysicist',
      'atmospheric physicist',
      'biophysicist',
      'cosmologist',
      'electronics research scientist',
      'experimental physicist',
      'health physicist',
      'medical physicist',
      'metrologist',
      'nuclear physicist',
      'optics physicist',
      'physics research scientist',
      'plasma physicist',
      'radio astronomer',
      'remote sensing research scientist',
      'solid-state physicist',
    ],
  },
  {
    code: '2112',
    title: 'Chemists',
    examples: [
      'agricultural chemist',
      'analytical chemist',
      'bioanalytical chemist',
      'biochemist',
      'chemist',
      'clinical chemist',
      'coatings chemist',
      'electrochemist',
      'environmental chemist',
      'food chemist',
      'inorganic chemist',
      'medicinal chemist',
      'nuclear magnetic resonance (NMR) spectroscopist',
      'oceanographic chemist',
      'organic chemist',
      'organic mass spectrometrist',
      'organometallic chemist',
      'pharmacological chemist',
      'physical chemist',
      'polymer chemist',
      'quality control chemist',
      'research chemist',
      'soil chemist',
      'textile chemist',
      'theoretical chemist',
    ],
  },
  {
    code: '2113',
    title: 'Geoscientists and oceanographers',
    examples: [
      'development geologist',
      'environmental geologist',
      'exploration geologist',
      'geochemist',
      'geologist',
      'geophysicist',
      'glaciologist',
      'groundwater geologist',
      'hydrogeologist',
      'hydrologist',
      'mine geologist',
      'mineralogist',
      'oceanographer',
      'paleontologist',
      'petroleum geologist',
      'petrologist',
      'sedimentologist',
      'seismologist',
      'stratigrapher',
    ],
  },
  {
    code: '2114',
    title: 'Meteorologists and climatologists',
    examples: [
      'air quality meteorologist',
      'climatologist',
      'hydrometeorologist',
      'meteorologist',
      'operational meteorologist',
    ],
  },
  {
    code: '2115',
    title: 'Other professional occupations in physical sciences',
    examples: [
      'astronaut',
      'ballistics examiner',
      'materials scientist',
      'metallurgist',
      'physical metallurgist',
      'research scientist - ceramics',
      'research scientist - composite materials',
      'soil scientist',
    ],
  },
  {
    code: '2121',
    title: 'Biologists and related scientists',
    examples: [
      'anatomist',
      'bacteriologist',
      'bioinformatician',
      'biologist',
      'botanist',
      'cell biologist',
      'ecologist',
      'embryologist',
      'geneticist',
      'histologist',
      'immunologist',
      'marine biologist',
      'microbiologist',
      'molecular biologist',
      'mycologist',
      'parasitologist',
      'pharmacologist',
      'physiologist',
      'protozoologist',
      'toxicologist',
      'virologist',
      'zoologist',
    ],
  },
  {
    code: '2122',
    title: 'Forestry professionals',
    examples: [
      'appraisal forester',
      'consulting forester',
      'district forester',
      'forester',
      'regional inventory officer - forestry',
      'registered professional forester (RPF)',
    ],
  },
  {
    code: '2123',
    title: 'Agricultural representatives, consultants and specialists',
    examples: [
      'agricultural consultant',
      'agricultural extension supervisor',
      'agricultural livestock specialist',
      'agricultural representative',
      'agricultural soil and crop specialist',
      'agriculturist',
      'agrologist',
      'agronomist',
      'consulting agrologist',
      'crop specialist',
      'farm management consultant',
      'field service adviser - agriculture',
      'field service agent - agriculture',
      "growers' advisor",
      'professional agrologist (P.Ag.)',
    ],
  },
  {
    code: '2131',
    title: 'Civil engineers',
    examples: [
      'bridge engineer',
      'civil engineer',
      'construction engineer',
      'construction project engineer',
      'environmental engineer',
      'geodetic engineer',
      'geomatics engineer',
      'highway engineer',
      'hydraulics engineer',
      'municipal engineer',
      'public works engineer',
      'sanitation engineer',
      'structural engineer',
      'surveying engineer',
      'traffic engineer',
      'transportation engineer',
      'water management engineer',
    ],
  },
  {
    code: '2132',
    title: 'Mechanical engineers',
    examples: [
      'acoustics engineer',
      'automotive engineer',
      'design engineer - mechanical',
      'energy conservation engineer',
      'fluid mechanics engineer',
      'heating, ventilation and air conditioning (HVAC) engineer',
      'mechanical engineer',
      'mechanical maintenance engineer',
      'nuclear engineer',
      'piping engineer',
      'power generation engineer',
      'refrigeration engineer',
      'robotics engineer',
      'thermal design engineer',
      'tool engineer',
    ],
  },
  {
    code: '2133',
    title: 'Electrical and electronics engineers',
    examples: [
      'avionics engineer',
      'control systems engineer',
      'electrical design engineer',
      'electrical distribution planning engineer',
      'electrical engineer',
      'electrical network engineer',
      'electrical process control engineer',
      'electrical systems planning engineer',
      'electronics engineer',
      'electronics test engineer',
      'instrumentation and control engineer',
      'roadway lighting design engineer',
      'television systems engineer',
    ],
  },
  {
    code: '2134',
    title: 'Chemical engineers',
    examples: [
      'adhesives engineer',
      'biochemical engineer',
      'biotechnical engineer',
      'chemical process control engineer',
      'chemical process engineer',
      'chemical project engineer',
      'environmental chemical engineer',
      'industrial hygiene engineer',
      'industrial waste treatment engineer',
      'liquid fuels engineer',
      'petrochemical engineer',
      'polymer engineer',
      'pulp and paper engineer',
      'refinery engineer',
      'waste treatment engineer',
    ],
  },
  {
    code: '2141',
    title: 'Industrial and manufacturing engineers',
    examples: [
      'computer integrated manufacturing (CIM) engineer',
      'fire prevention engineer',
      'industrial engineer',
      'manufacturing engineer',
      'plant engineer',
      'production engineer',
      'quality control engineer',
      'safety engineer',
      'work measurement engineer',
    ],
  },
  {
    code: '2142',
    title: 'Metallurgical and materials engineers',
    examples: [
      'ceramics engineer',
      'corrosion engineer',
      'electrometallurgical engineer',
      'foundry engineer',
      'hydrometallurgical engineer',
      'materials engineer',
      'metallurgical engineer',
      'physical metallurgical engineer',
      'pyrometallurgical engineer',
      'welding engineer',
    ],
  },
  {
    code: '2143',
    title: 'Mining engineers',
    examples: [
      'mine design engineer',
      'mine development engineer',
      'mine layout engineer',
      'mine production engineer',
      'mine safety engineer',
      'mine ventilation engineer',
      'mineral engineer',
      'mining engineer',
    ],
  },
  {
    code: '2144',
    title: 'Geological engineers',
    examples: ['geological engineer', 'geophysical engineer', 'hydrogeological engineer - engineering'],
  },
  {
    code: '2145',
    title: 'Petroleum engineers',
    examples: [
      'oil and gas drilling engineer',
      'oil and gas production engineer',
      'petroleum engineer',
      'petroleum reservoir engineer',
      'petroleum well completion engineer',
      'subsea engineer',
    ],
  },
  {
    code: '2146',
    title: 'Aerospace engineers',
    examples: [
      'aerodynamics engineer',
      'aeronautical engineer',
      'aerospace engineer',
      'aerospace structural engineer',
      'aerospace systems engineer',
      'aerospace test engineer',
      'aircraft design engineer',
      'stress engineer - aerospace',
    ],
  },
  {
    code: '2147',
    title: 'Computer engineers (except software engineers and designers)',
    examples: [
      'computer hardware engineer',
      'fibre-optic network designer',
      'hardware circuit board designer',
      'hardware development engineer',
      'hardware technical architect',
      'network test engineer',
      'systems designer - hardware',
      'telecommunications hardware engineer',
      'wireless communications network engineer',
    ],
  },
  {
    code: '2148',
    title: 'Other professional engineers, n.e.c.',
    examples: [
      'agricultural engineer',
      'biomedical engineer',
      'bioresource engineer',
      'engineering physicist',
      'engineering scientist',
      'food processing engineer',
      'marine engineer',
      'naval architect',
      'textile engineer',
    ],
  },
  {
    code: '2151',
    title: 'Architects',
    examples: [
      'architect',
      'architectural standards specialist',
      'chief architect',
      'consulting architect',
      'industrial and commercial buildings architect',
      'residential architect',
    ],
  },
  { code: '2152', title: 'Landscape architects', examples: ['landscape architect', 'senior landscape architect'] },
  {
    code: '2153',
    title: 'Urban and land use planners',
    examples: [
      'community and urban planner',
      'environmental planner',
      'land use planner',
      'municipal planner',
      'park planner',
      'planner',
      'recreation planner',
      'regional planner',
      'urban planner',
    ],
  },
  {
    code: '2154',
    title: 'Land surveyors',
    examples: ['cadastral surveyor', 'Canada lands surveyor', 'city surveyor', 'land surveyor', 'property surveyor'],
  },
  {
    code: '2161',
    title: 'Mathematicians, statisticians and actuaries',
    examples: [
      'actuary',
      'biostatistician',
      'consulting actuary',
      'demographer',
      'insurance actuary',
      'mathematician',
      'statistical analyst',
      'statistician',
    ],
  },
  {
    code: '2171',
    title: 'Information systems analysts and consultants',
    examples: [
      'computer systems analyst',
      'informatics consultant',
      'informatics security analyst',
      'information systems business analyst',
      'information technology (IT) consultant',
      'management information systems (MIS) analyst',
      'systems auditor',
      'systems consultant',
      'systems security analyst',
    ],
  },
  {
    code: '2172',
    title: 'Database analysts and data administrators',
    examples: [
      'data administrator',
      'data custodian',
      'data dictionary administrator',
      'data warehouse analyst',
      'database administrator (DBA)',
      'database analyst',
      'database architect',
      'technical architect - database',
    ],
  },
  {
    code: '2173',
    title: 'Software engineers and designers',
    examples: [
      'application architect',
      'computer software engineer',
      'embedded software engineer',
      'software architect',
      'software design engineer',
      'software design verification engineer',
      'software designer',
      'software testing engineer',
      'telecommunications software engineer',
    ],
  },
  {
    code: '2174',
    title: 'Computer programmers and interactive media developers',
    examples: [
      'application programmer',
      'business application programmer',
      'computer game developer',
      'computer programmer',
      'electronic business (e-business) software developer',
      'interactive media developer',
      'multimedia developer',
      'operating systems programmer',
      'programmer analyst',
      'scientific programmer',
      'software developer',
      'software programmer',
      'systems programmer',
      'Web programmer',
    ],
  },
  {
    code: '2175',
    title: 'Web designers and developers',
    examples: [
      'electronic business (e-business) Web site developer',
      'Internet site designer',
      'Internet site developer',
      'Intranet site designer',
      'Web designer',
      'Web developer',
      'Web manager',
      'Web site developer',
      'Webmaster',
    ],
  },
  {
    code: '2211',
    title: 'Chemical technologists and technicians',
    examples: [
      'biochemistry technologist',
      'chemical analyst',
      'chemical engineering technician',
      'chemical engineering technologist',
      'chemical laboratory analyst',
      'chemical research technician',
      'chemical technician',
      'chemical technologist',
      'food technologist',
      'formulation technician',
      'geochemical technician',
      'industrial hygiene technologist',
      'mass spectrometer technician',
      'master dyer - textiles',
      'paint technician',
      'pilot plant technician',
      'quality control technician - chemical processing',
      'quality control technician - food processing',
    ],
  },
  {
    code: '2212',
    title: 'Geological and mineral technologists and technicians',
    examples: [
      'assayer',
      'geological technician',
      'geophysical technologist',
      'groundwater technologist',
      'log technician',
      'marine geoscience technologist',
      'metallurgical technologist',
      'mineralogy technician',
      'mining engineering technologist',
      'mining technologist',
      'petroleum engineering technologist',
      'petroleum technician',
      'petrology technician',
      'reservoir engineering technician',
      'rock mechanics technician',
      'seismic technician',
      'welding technologist',
    ],
  },
  {
    code: '2221',
    title: 'Biological technologists and technicians',
    examples: [
      'agricultural technician',
      'agricultural technologist',
      'agrology technician',
      'aquaculture technician',
      'bacteriological technician',
      'biological laboratory technologist',
      'botanical technician',
      'fish hatchery technician',
      'fisheries technician',
      'food bacteriological technician',
      'microbiology quality control technologist',
      'microbiology technologist (except medical)',
      'plant breeding technician',
      'seed technologist',
      'wildlife biology technician',
    ],
  },
  {
    code: '2222',
    title: 'Agricultural and fish products inspectors',
    examples: [
      'agricultural products inspection supervisor',
      'agricultural products inspector',
      'crop certification inspector',
      'dairy products inspector',
      'fish inspector',
      'fish products inspection supervisor',
      'fish products inspector',
      'fruit and vegetables inspector',
      'grain inspector',
      'livestock inspector',
      'meat inspector',
      'plant protection inspector',
      'poultry inspector',
    ],
  },
  {
    code: '2223',
    title: 'Forestry technologists and technicians',
    examples: [
      'conservation technician - forestry',
      'cruising technician - forestry',
      'enforcement officer - forestry',
      'extension ranger - forestry',
      'fire suppression officer - forestry',
      'forest fire technician',
      'forest inventory resource officer',
      'forest survey technician',
      'forest technician',
      'forestry technician',
      'forestry technologist',
      'resource technician - forestry',
      'scaler technician - logging',
      'scaling co-ordinator - logging',
      'silviculture technician',
    ],
  },
  {
    code: '2224',
    title: 'Conservation and fishery officers',
    examples: [
      'conservation officer',
      'fish and wildlife officer',
      'fishery officer',
      'forest ranger - wildlife management',
      'game officer',
      'game warden',
      'natural resources officer',
      'park ranger',
    ],
  },
  {
    code: '2225',
    title: 'Landscape and horticulture technicians and specialists',
    examples: [
      'arborist',
      'golf course superintendent',
      'greenskeeper',
      'horticultural technician',
      'horticulture specialist',
      'horticulturist',
      'hydroponics technician',
      'landscape architectural technician',
      'landscape designer',
      'landscape gardener',
      'landscape technician',
      'landscaper',
      'lawn care specialist',
      'tree service technician',
    ],
  },
  {
    code: '2231',
    title: 'Civil engineering technologists and technicians',
    examples: [
      'bridge design technician',
      'building materials technician',
      'civil engineering technician',
      'civil engineering technologist',
      'construction specifications writer',
      'construction technologist',
      'foundation technologist',
      'highway technician',
      'municipal engineering assistant',
      'soil technologist - civil engineering',
      'structural design technologist',
      'structural investigator',
    ],
  },
  {
    code: '2232',
    title: 'Mechanical engineering technologists and technicians',
    examples: [
      'aeronautical technologist',
      'heating designer',
      'heating, ventilation and air conditioning (HVAC) technologist',
      'machine designer',
      'marine engineering technologist',
      'mechanical engineering technician',
      'mechanical engineering technologist',
      'mechanical technologist',
      'mould designer',
      'thermal station technician',
      'tool and die designer',
      'tool designer',
    ],
  },
  {
    code: '2233',
    title: 'Industrial engineering and manufacturing technologists and technicians',
    examples: [
      'computer-assisted design/computer-assisted manufacturing (CAD/CAM) programmer',
      'industrial engineering technician',
      'industrial engineering technologist',
      'loss prevention technologist - manufacturing',
      'manufacturing technician',
      'manufacturing technologist',
      'planning technician',
      'plastics manufacturing technician',
      'pulp and paper manufacturing technologist',
      'quality assurance technologist',
      'scheduling technician - manufacturing',
      'textile technologist',
      'time study analyst',
    ],
  },
  {
    code: '2234',
    title: 'Construction estimators',
    examples: [
      'chief estimator - construction',
      'construction estimator',
      'cost estimator - construction',
      'principal estimator - construction',
      'professional quantity surveyor',
      'quantity surveyor - construction',
    ],
  },
  {
    code: '2241',
    title: 'Electrical and electronics engineering technologists and technicians',
    examples: [
      'communications technologist',
      'electrical engineering technician',
      'electrical engineering technologist',
      'electricity distribution network technologist',
      'electronics design technologist',
      'electronics engineering technician',
      'electronics engineering technologist',
      'electronics manufacturing technician',
      'electronics manufacturing technologist',
      'lighting technologist',
      'metering technologist',
      'microwave maintenance technician',
      'production support technician - electronics manufacturing',
    ],
  },
  {
    code: '2242',
    title: 'Electronic service technicians (household and business equipment)',
    examples: [
      'alarm system technician',
      'audio-video service technician',
      'computer service technician',
      'electronic products field service technician',
      'electronic service technician apprentice',
      'electronic service technician supervisor',
      'office equipment service technician',
      'photocopy machine technician',
      'radio and television service technician',
      'satellite antenna servicer',
    ],
  },
  {
    code: '2243',
    title: 'Industrial instrument technicians and mechanics',
    examples: [
      'apprentice industrial instrument mechanic',
      'industrial instrument mechanic',
      'industrial instrument technician',
      'industrial instrumentation technician',
      'process control equipment mechanic',
    ],
  },
  {
    code: '2244',
    title: 'Aircraft instrument, electrical and avionics mechanics, technicians and inspectors',
    examples: [
      'aircraft electrical technician',
      'aircraft electrician',
      'aircraft instrument inspector',
      'aircraft instrument mechanic',
      'aircraft instrument technician',
      'aircraft maintenance engineer (AME) - avionics',
      'avionics inspector',
      'avionics maintenance technician',
      'avionics technician',
      'instrument overhaul and repair mechanic - avionics',
    ],
  },
  {
    code: '2251',
    title: 'Architectural technologists and technicians',
    examples: [
      'architectural design technician',
      'architectural design technologist',
      'architectural technician',
      'architectural technologist',
    ],
  },
  {
    code: '2252',
    title: 'Industrial designers',
    examples: [
      'furniture designer',
      'industrial design consultant',
      'industrial designer',
      'industrial products designer',
      'product designer',
    ],
  },
  {
    code: '2253',
    title: 'Drafting technologists and technicians',
    examples: [
      'architectural draftsperson',
      'computer-assisted design and drafting technologist',
      'computer-assisted drafting (CAD) technician',
      'design and drafting technologist',
      'drafting office supervisor',
      'drafting technician',
      'drafting technologist',
      'draftsperson',
      'electrical draftsperson',
      'electromechanical draftsperson',
      'electronic draftsperson',
      'engineering design and drafting technologist',
      'mechanical draftsperson',
      'steel detailer - drafting',
      'structural draftsperson',
      'structural steel drafter-detailer',
    ],
  },
  {
    code: '2254',
    title: 'Land survey technologists and technicians',
    examples: [
      'engineering survey technologist',
      'geodetic survey technologist',
      'geomatics technologist - land surveying',
      'land survey technician',
      'legal survey technician',
      'topographic survey technician',
      'transit operator - surveying',
    ],
  },
  {
    code: '2255',
    title: 'Technical occupations in geomatics and meteorology',
    examples: [
      'aerial survey technician',
      'aerological technician',
      'avalanche controller',
      'cartographer',
      'climate data processor',
      'climate service technician',
      'geographic information systems (GIS) technician',
      'ice observer',
      'map editor',
      'mapping technician',
      'meteorological inspector',
      'meteorological technician',
      'photogrammetric technologist',
      'photogrammetrist',
      'remote sensing (RS) technician',
      'softcopy photogrammetrist',
      'surface weather observer',
      'weather station officer-in-charge',
      'weather station operations technician',
    ],
  },
  {
    code: '2261',
    title: 'Non-destructive testers and inspection technicians',
    examples: [
      'acoustic emission technician',
      'aircraft non-destructive inspection technician',
      'eddy current technician',
      'industrial radiographer',
      'infrared thermographer',
      'liquid penetrant testing technician',
      'non-destructive inspector',
      'non-destructive testing maintenance (NDT) technician',
      'pressure vessel tester',
      'radiographic technician - non-destructive testing',
      'ultrasonic testing technician',
      'visual inspection technician - welding',
      'weld tester',
    ],
  },
  {
    code: '2262',
    title: 'Engineering inspectors and regulatory officers',
    examples: [
      'air carrier maintenance inspector',
      'air transport inspector',
      'airworthiness inspector',
      'electricity and gas meter inspector',
      'elevator inspector',
      'engineering inspector',
      'engineering regulatory officer',
      'insurance loss prevention inspector',
      'marine damage surveyor',
      'motor vehicle defects investigator',
      'railway accident investigation officer',
      'weights and measures inspector',
    ],
  },
  {
    code: '2263',
    title: 'Inspectors in public and environmental health and occupational health and safety',
    examples: [
      'environmental health officer',
      'hazardous waste inspector',
      'health and safety officer',
      'health standards inspector',
      'occupational health and safety officer',
      'pollution control inspector',
      'public health inspector',
      'public health inspectors supervisor',
      'restaurant inspector',
      'rodent control inspector',
      'water inspector',
    ],
  },
  {
    code: '2264',
    title: 'Construction inspectors',
    examples: [
      'bridge inspector',
      'building construction inspector',
      'construction inspector',
      'highway construction inspector',
      'home inspector',
      'housing construction inspector',
      'mine construction inspector',
      'plumbing inspector',
      'pre-stressed concrete inspector',
      'safety officer - construction',
    ],
  },
  {
    code: '2271',
    title: 'Air pilots, flight engineers and flying instructors',
    examples: [
      'air pilot',
      'captain - air transport',
      'check pilot',
      'chief flying instructor',
      'chief pilot',
      'co-pilot',
      'flight engineer',
      'flying instructor',
      'helicopter pilot',
      'pilot instructor',
      'relief pilot',
      'second officer - air transport',
      'test pilot',
    ],
  },
  {
    code: '2272',
    title: 'Air traffic controllers and related occupations',
    examples: [
      'air traffic controller (ATC)',
      'airport air traffic controller',
      'enroute air traffic controller',
      'flight dispatcher',
      'flight service specialist (FSS)',
      'instrument flight rules air traffic controller',
      'terminal air traffic controller',
      'visual flight rules air traffic controller',
    ],
  },
  {
    code: '2273',
    title: 'Deck officers, water transport',
    examples: [
      'Coast Guard vessel first watchkeeping officer',
      'Coast Guard vessel navigation officer',
      'Coast Guard vessel watchkeeping officer',
      'deck officer - water transport',
      'ferryboat master',
      'master mariner',
      'ship pilot',
      'ship second mate',
      'ship third mate',
      "ship's captain",
      "ship's mate",
      'tugboat captain',
    ],
  },
  {
    code: '2274',
    title: 'Engineer officers, water transport',
    examples: [
      'chief engineer - water transport',
      'engineer officer - water transport',
      'fourth engineer - water transport',
      'marine engineer officer',
      'second engineer',
      'third engineer - water transport',
      'tugboat engineer',
    ],
  },
  {
    code: '2275',
    title: 'Railway traffic controllers and marine traffic regulators',
    examples: [
      'chief rail traffic controller',
      'marine traffic regulator',
      'rail traffic controller',
      'train operator',
    ],
  },
  {
    code: '2281',
    title: 'Computer network technicians',
    examples: [
      'computer network technician',
      "computer network technicians' supervisor",
      'data centre operator',
      'Internet Web site technician',
      'local area network (LAN) administrator',
      'local area network (LAN) technician',
      'network administrator',
      'network support technician',
      'system administrator',
      'Web technician',
    ],
  },
  {
    code: '2282',
    title: 'User support technicians',
    examples: [
      'call centre agent - technical support',
      'client support representative - systems',
      'computer help desk representative - systems',
      'computer help desk supervisor',
      'hardware installation technician',
      'hardware technical support analyst',
      'help desk technician',
      'software installation technician',
      'software technical support analyst',
      'systems support representative',
      'technical support analyst - systems',
      'technical support supervisor',
      'user support technician',
    ],
  },
  {
    code: '2283',
    title: 'Information systems testing technicians',
    examples: [
      'application tester',
      'application testing technician',
      'software test co-ordinator',
      'software tester',
      'software testing technician',
      'systems tester',
      'systems testing technician',
      'user acceptance tester',
    ],
  },
  {
    code: '3011',
    title: 'Nursing co-ordinators and supervisors',
    examples: [
      'nursing care co-ordinator',
      'nursing services co-ordinator',
      'nursing supervisor',
      'patient care co-ordinator - nursing',
      'psychiatric nursing supervisor',
      'public health nursing supervisor',
    ],
  },
  {
    code: '3012',
    title: 'Registered nurses and registered psychiatric nurses',
    examples: [
      'clinical nurse',
      'community health nurse',
      'critical care nurse',
      'emergency care nurse',
      'intensive care nurse',
      'nurse researcher',
      'nursing consultant',
      'occupational health nurse',
      'private duty nurse',
      'public health nurse',
      'registered nurse (R.N.)',
      'registered psychiatric nurse (R.P.N.)',
    ],
  },
  {
    code: '3111',
    title: 'Specialist physicians',
    examples: [
      'anatomical pathologist',
      'anesthetist',
      'cardiac surgeon',
      'cardiologist',
      'clinical immunologist-allergist',
      'dermatologist',
      'diagnostic radiologist',
      'emergency physician',
      'endocrinologist',
      'gastroenterologist',
      'general pathologist',
      'general surgeon',
      'geriatrician',
      'hematologist',
      'hematopathologist',
      'medical biochemist - physician',
      'medical microbiologist',
      'nephrologist',
      'neurologist',
      'neuropathologist',
      'neurosurgeon',
      'obstetrician-gynecologist',
      'oncologist',
      'ophthalmologist',
      'orthopedic surgeon',
      'orthopedist',
      'otorhinolaryngologist',
      'pediatric surgeon',
      'pediatrician',
      'physiatrist',
      'plastic surgeon',
      'pneumologist',
      'psychiatrist',
      'radiation oncologist',
      'respirologist',
      'rheumatologist',
      'thoracic surgeon',
      'urologist',
      'vascular surgeon',
    ],
  },
  {
    code: '3112',
    title: 'General practitioners and family physicians',
    examples: ['family physician', 'general practice resident', 'general practitioner (GP)', 'medical doctor'],
  },
  {
    code: '3113',
    title: 'Dentists',
    examples: [
      'dentist',
      'endodontist',
      'oral and maxillofacial surgeon',
      'oral pathologist',
      'oral radiologist',
      'orthodontist',
      'pediatric dentist',
      'periodontist',
      'prosthodontist',
      'public health dentist',
    ],
  },
  {
    code: '3114',
    title: 'Veterinarians',
    examples: [
      'farm veterinarian',
      'small animal veterinary specialist',
      'veterinarian',
      'veterinary inspector',
      'veterinary pathologist',
      'veterinary physiologist',
      'veterinary surgeon',
      'zoo veterinarian',
    ],
  },
  { code: '3121', title: 'Optometrists', examples: ['doctor of optometry (OD)', 'optometrist'] },
  { code: '3122', title: 'Chiropractors', examples: ['chiropractor'] },
  {
    code: '3124',
    title: 'Allied primary health practitioners',
    examples: [
      'anesthesia assistant',
      'midwife',
      'nurse practitioner',
      'nurse practitioner - extended class',
      'physician assistant',
    ],
  },
  {
    code: '3125',
    title: 'Other professional occupations in health diagnosing and treating',
    examples: [
      'chiropodist',
      'doctor of osteopathic medicine',
      'doctor of podiatric medicine (D.P.M.)',
      'foot specialist',
      'naturopath',
      'naturopathic doctor (ND)',
      'orthoptist',
      'osteopathic physician',
      'podiatrist',
    ],
  },
  {
    code: '3131',
    title: 'Pharmacists',
    examples: [
      'clinical pharmacist',
      'community pharmacist',
      'hospital pharmacist',
      'industrial pharmacist',
      'pharmacist',
      'retail pharmacist',
    ],
  },
  {
    code: '3132',
    title: 'Dietitians and nutritionists',
    examples: [
      'administrative dietitian',
      'clinical dietitian',
      'community nutritionist',
      'consultant dietitian',
      'dietitian',
      'dietitian-nutritionist',
      'nutrition specialist',
      'nutritionist',
      'public health dietitian',
      'public health nutritionist',
      'registered dietitian (RD)',
      'research dietitian',
    ],
  },
  {
    code: '3141',
    title: 'Audiologists and speech-language pathologists',
    examples: [
      'audiologist',
      'certified audiologist',
      'clinical audiologist',
      'educational speech-language pathologist',
      'research audiologist',
      'speech therapist',
      'speech-language clinician',
    ],
  },
  {
    code: '3142',
    title: 'Physiotherapists',
    examples: ['physical therapist', 'physiotherapist', 'registered physiotherapist', 'research physiotherapist'],
  },
  {
    code: '3143',
    title: 'Occupational therapists',
    examples: [
      'case manager occupational therapist',
      'clinical occupational therapist',
      'community occupational therapist',
      'occupational therapist (OT)',
      'occupational therapy rehabilitation consultant',
      'research and development occupational therapist',
    ],
  },
  {
    code: '3144',
    title: 'Other professional occupations in therapy and assessment',
    examples: [
      'art therapist',
      'athletic therapist',
      'certified athletic therapist (CAT)',
      'certified kinesiologist',
      'drama therapist',
      'exercise physiologist',
      'human kineticist',
      'kinesiologist',
      'movement therapist',
      'music therapist',
      'recreational therapist',
      'registered dance therapist (DTR)',
    ],
  },
  {
    code: '3211',
    title: 'Medical laboratory technologists',
    examples: [
      'clinical immunology technologist',
      'cytogenetics technologist - medical laboratory',
      'histology technologist',
      'immunohematology technologist',
      'medical laboratory supervisor',
      'medical laboratory technologist',
      'medical technologist - medical laboratory',
    ],
  },
  {
    code: '3212',
    title: "Medical laboratory technicians and pathologists' assistants",
    examples: [
      'medical laboratory assistant',
      'medical laboratory technician',
      'pathology assistant',
      'phlebotomist',
      'phlebotomy aide',
    ],
  },
  {
    code: '3213',
    title: 'Animal health technologists and veterinary technicians',
    examples: [
      'animal health technician',
      'animal health technologist',
      'laboratory animal technician',
      'registered veterinary technician (RVT)',
      'veterinarian assistant',
      'veterinary technician',
      'veterinary technologist',
    ],
  },
  {
    code: '3214',
    title: 'Respiratory therapists, clinical perfusionists and cardiopulmonary technologists',
    examples: [
      'cardiopulmonary technologist',
      'cardiovascular perfusion supervisor',
      'cardiovascular perfusionist',
      'certified clinical perfusionist (CCP)',
      'chief respiratory technologist',
      'clinical perfusionist',
      'perfusionist',
      'registered respiratory therapist (RRT)',
      'respiratory therapist',
      'respiratory therapy chief',
      'respiratory therapy clinical instructor',
    ],
  },
  {
    code: '3215',
    title: 'Medical radiation technologists',
    examples: [
      'mammography technician',
      'nuclear medicine clinical instructor',
      'nuclear medicine technologist',
      'nuclear medicine technologists supervisor',
      'radiation oncology technologist',
      'radiation therapist',
      'radiation therapy clinical instructor',
      'radiation therapy technologist (RTT)',
      'radiography technologist',
      'radiological technologist',
      'radiotherapy technician',
      'X-ray (radiology) technician',
    ],
  },
  {
    code: '3216',
    title: 'Medical sonographers',
    examples: [
      'diagnostic medical sonography instructor',
      'medical sonographer',
      'medical sonographers supervisor',
      'registered diagnostic medical sonographer (RDMS)',
      'ultrasound technologist',
    ],
  },
  {
    code: '3217',
    title: 'Cardiology technologists and electrophysiological diagnostic technologists, n.e.c.',
    examples: [
      'cardiac stress technologist',
      'cardiology supervisor',
      'cardiology technologist',
      'electrocardiographic (ECG) technologist',
      'electrocardiography technologist',
      'electroencephalograph (EEG) technologist',
      'electromyography (EMG) technologist',
      'electroneurodiagnostic (END) technologist',
      'electroneurophysiology (ENP) technologist',
    ],
  },
  {
    code: '3219',
    title: 'Other medical technologists and technicians (except dental health)',
    examples: [
      'dietary technician',
      'food and nutrition technician - dietetics',
      'ocularist',
      'ocularist technician',
      'orthotic technician',
      'orthotist',
      'pharmacy technician',
      'prosthetic technician',
      'prosthetist',
    ],
  },
  { code: '3221', title: 'Denturists', examples: ['dental mechanic', 'denturist', 'denturologist'] },
  {
    code: '3222',
    title: 'Dental hygienists and dental therapists',
    examples: ['dental hygienist', 'dental nurse', 'dental therapist', 'registered dental hygienist'],
  },
  {
    code: '3223',
    title: 'Dental technologists, technicians and laboratory assistants',
    examples: [
      'ceramic denture moulder',
      'dental laboratory assistant',
      'dental technician',
      'dental technician supervisor',
      'dental technologist',
      'denture finisher',
      'denture wax pattern former',
      'orthodontic band maker',
      'registered dental technician',
      'registered dental technologist',
    ],
  },
  {
    code: '3231',
    title: 'Opticians',
    examples: [
      'contact lens fitter',
      'contact lens technician',
      'dispensing optician',
      'ophthalmic dispenser',
      'optician',
    ],
  },
  {
    code: '3232',
    title: 'Practitioners of natural healing',
    examples: [
      'acupuncturist',
      'aromatherapist',
      'ayurvedic practitioner',
      'herbalist',
      'homeopathist',
      'reflexologist',
      'traditional Chinese medicine practitioner',
    ],
  },
  {
    code: '3233',
    title: 'Licensed practical nurses',
    examples: [
      'graduate nursing assistant',
      'licensed practical nurse (L.P.N.)',
      'operating room technician',
      'registered nursing assistant (R.N.A.)',
    ],
  },
  {
    code: '3234',
    title: 'Paramedical occupations',
    examples: [
      'advanced care paramedic',
      'ambulance attendant',
      'ambulance services supervisor',
      'critical care paramedic',
      'emergency medical technician (EMT)',
      'emergency medical technologist - paramedic (EMT-P)',
      'paramedic',
      'paramedic emergency medical technician',
      'primary care paramedic',
    ],
  },
  {
    code: '3236',
    title: 'Massage therapists',
    examples: ['massage therapist (MT)', 'registered massage practitioner', 'registered massage therapist (RMT)'],
  },
  {
    code: '3237',
    title: 'Other technical occupations in therapy and assessment',
    examples: [
      'audiology technician',
      'audiometric assistant',
      'audiometric technician',
      'communicative disorders assistant',
      'hearing instrument practitioner',
      'occupational therapy assistant',
      'ophthalmic assistant',
      'ophthalmic technician',
      'ophthalmic technologist',
      'ophthalmologist assistant',
      'physiotherapy technician',
      'speech technician',
      'speech therapy assistant',
    ],
  },
  {
    code: '3411',
    title: 'Dental assistants',
    examples: [
      'certified dental assistant',
      'certified intra-oral dental assistant',
      'dental assistant',
      'registered dental assistant',
    ],
  },
  {
    code: '3413',
    title: 'Nurse aides, orderlies and patient service associates',
    examples: [
      'health care aide',
      'hospital attendant',
      'long term care aide',
      'nurse aide',
      'nursing attendant',
      'orderly',
      'patient care aide',
      'patient service associate',
      'personal care attendant - medical',
      'psychiatric aide',
      'resident care aide - medical',
    ],
  },
  {
    code: '3414',
    title: 'Other assisting occupations in support of health services',
    examples: [
      'autopsy assistant',
      'blood donor clinic assistant',
      'cast room technician',
      'chiropractic assistant',
      'clinical laboratory helper',
      'morgue attendant',
      'ophthalmic laboratory technician - retail',
      'ophthalmic lens grinder',
      'optical laboratory assistant',
      'optometrist assistant',
      'orthopedic technologist',
      'rehabilitation assistant',
      'sterile processing technician',
      'therapist assistant - medical',
    ],
  },
  {
    code: '4011',
    title: 'University professors and lecturers',
    examples: [
      'botany assistant professor - university',
      'computer science professor - university',
      'engineering instructor - university',
      'English professor - university',
      'food sciences department chairperson - university',
      'French language professor - university',
      'geography department head - university',
      'lecturer - university',
      'linguistics associate professor',
      'physics department chairperson - university',
      'professor of medicine - university',
      'university professor',
    ],
  },
  {
    code: '4012',
    title: 'Post-secondary teaching and research assistants',
    examples: [
      'college laboratory assistant',
      'college teaching assistant',
      'graduate assistant - university',
      'post-secondary research assistant',
      'university research assistant',
    ],
  },
  {
    code: '4021',
    title: 'College and other vocational instructors',
    examples: [
      'college teacher',
      'commercial art instructor',
      'community college teacher',
      'company trainer',
      'computer training instructor',
      'department chairperson - college',
      'department head - general and vocational college (CEGEP)',
      'firefighting instructor',
      'general and vocational college (CEGEP) teacher',
      'instructor - technology institute',
      'language school instructor',
      'lecturer - college',
      'legal assistant program teacher',
      'teacher - institute of technology',
      'training officer - company',
      'vocational institute teacher',
    ],
  },
  {
    code: '4031',
    title: 'Secondary school teachers',
    examples: [
      'adult education teacher - secondary school',
      'biology teacher - secondary school',
      'commerce teacher - secondary school',
      'English as a second language (ESL) high school teacher',
      'English teacher - secondary school',
      'French as a second language teacher - secondary school',
      'history teacher - secondary school',
      'librarian-teacher - high school',
      'remedial teacher - secondary school',
      'secondary school department head',
      'secondary school teacher',
      'special education teacher - secondary school',
      'supply high school teacher',
      'trades instructor - secondary school',
      'vocational teacher - secondary school',
    ],
  },
  {
    code: '4032',
    title: 'Elementary school and kindergarten teachers',
    examples: [
      'English as a second language elementary school teacher',
      'French as a second language elementary school teacher',
      'French immersion teacher - elementary school',
      'kindergarten teacher',
      'primary school teacher',
      'remedial teacher - elementary school',
      'special education teacher - elementary school',
      'special education teacher - primary school',
      'supply teacher - elementary school',
      'teacher-librarian - elementary school',
    ],
  },
  {
    code: '4033',
    title: 'Educational counsellors',
    examples: [
      'academic counsellor',
      'career counsellor - education',
      'school counsellor',
      'student services counsellor',
    ],
  },
  {
    code: '4111',
    title: 'Judges',
    examples: [
      'chief justice',
      'county court judge',
      "Court of Queen's Bench justice",
      'district court judge',
      'family court judge',
      'federal trial court justice',
      'provincial court of appeal justice',
      'small claims court judge',
      'superior court justice',
      'Supreme Court justice',
    ],
  },
  {
    code: '4112',
    title: 'Lawyers and Quebec notaries',
    examples: [
      'articling law student',
      'corporate counsel',
      'Crown Attorney',
      'law partner',
      'lawyer',
      'legal advisor',
      'legislative counsel',
      'notary (Quebec)',
      'prosecutor',
      'solicitor',
    ],
  },
  {
    code: '4151',
    title: 'Psychologists',
    examples: [
      'clinical psychologist',
      'experimental psychologist',
      'psychological associate',
      'psychologist',
      'research psychologist',
    ],
  },
  {
    code: '4152',
    title: 'Social workers',
    examples: [
      'co-ordinator of social work',
      'medical social worker',
      'psychiatric social worker',
      'social work supervisor',
      'social worker',
    ],
  },
  {
    code: '4153',
    title: 'Family, marriage and other related counsellors',
    examples: [
      'addictions counsellor',
      'bereavement counsellor',
      'child and youth counsellor',
      'family counsellor',
      'marriage counsellor',
      'registered clinical counsellor',
      'registered marriage and family therapist',
      'rehabilitation counsellor',
      'sex therapist',
      'vocational rehabilitation counsellor',
    ],
  },
  {
    code: '4154',
    title: 'Professional occupations in religion',
    examples: [
      'archbishop',
      'bishop',
      'cardinal',
      'chaplain',
      'evangelist',
      'granthi',
      'imam',
      'minister',
      'moderator - religion',
      'pastor',
      'priest',
      'rabbi',
    ],
  },
  {
    code: '4155',
    title: 'Probation and parole officers and related occupations',
    examples: [
      'case manager - corrections',
      'classification officer - correctional institution',
      'parole officer',
      'probation officer',
    ],
  },
  {
    code: '4156',
    title: 'Employment counsellors',
    examples: [
      'career counsellor (except education)',
      'career development counsellor',
      'employment counsellor',
      'outplacement counsellor',
      'relocation consultant',
    ],
  },
  {
    code: '4161',
    title: 'Natural and applied science policy researchers, consultants and program officers',
    examples: [
      'certified ergonomist',
      'emergency management analyst',
      'emergency preparedness planner',
      'energy policy analyst',
      'environmental impact analyst',
      'environmental issues lobbyist',
      'environmental program development supervisor',
      'ergonomics specialist',
      'fisheries analyst',
      'industrial hygienist',
      'natural and applied sciences program officer',
      'natural resources policy analyst',
      'occupational hygienist',
      'recycling program co-ordinator',
      'scientific consultant',
      'technology transfer officer',
      'transportation safety analyst',
    ],
  },
  {
    code: '4162',
    title: 'Economists and economic policy researchers and analysts',
    examples: [
      'agricultural economist',
      'economic advisor',
      'economic analyst',
      'economic policy analyst',
      'economist',
      'energy economist',
      'financial economist',
      'industrial economist',
      'international trade economist',
      'investment economist',
      'labour economist',
      'natural resources economist',
      'tax economist',
      'trade economist',
    ],
  },
  {
    code: '4163',
    title: 'Business development officers and marketing researchers and consultants',
    examples: [
      'business development officer',
      'community economic development consultant',
      'economic development officer',
      'industrial development officer',
      'market researcher',
      'marketing analyst',
      'marketing consultant',
      'regional development analyst',
      'tourism development officer',
      'tourism industry consultant',
    ],
  },
  {
    code: '4164',
    title: 'Social policy researchers, consultants and program officers',
    examples: [
      'Aboriginal issues lobbyist',
      'child welfare policy analyst',
      'community policing program consultant',
      'community social development officer',
      'consumer advisor',
      'employment equity policy consultant',
      'home economist',
      'housing policy analyst',
      'human rights officer',
      'immigration policy analyst',
      'international aid and development project officer',
      'labour policy analyst',
      'social policy researcher',
      'social services planner',
      'social survey researcher (except statistician)',
    ],
  },
  {
    code: '4165',
    title: 'Health policy researchers, consultants and program officers',
    examples: [
      'child health care programs planning officer',
      'drug and alcohol abuse consultant',
      'health care consultant',
      'health care planner',
      'health policy research analyst',
      'health promotion program officer',
      'health services researcher',
      'mental health programs consultant',
      'policy development officer - nursing homes',
    ],
  },
  {
    code: '4166',
    title: 'Education policy researchers, consultants and program officers',
    examples: [
      'curriculum developer',
      'curriculum planner',
      'education consultant',
      'education outreach program co-ordinator',
      'education policy officer',
      'education policy supervisor',
      'education program co-ordinator',
      'education program officer',
      'education researcher',
      'special education co-ordinator',
    ],
  },
  {
    code: '4167',
    title: 'Recreation, sports and fitness policy researchers, consultants and program officers',
    examples: [
      'fitness policy analyst',
      'recreation consultant',
      'recreologist',
      'sports analyst',
      'sports and recreation consultant',
      'sports consultant',
      'sports policy analyst',
      'sports program supervisor',
    ],
  },
  {
    code: '4168',
    title: 'Program officers unique to government',
    examples: [
      'attach?',
      'elections officer',
      'federal-provincial relations officer',
      'foreign service officer',
      'intergovernmental affairs officer',
      'office of the Speaker officer',
      'protocol officer',
      'returning officer',
      'royal commission officer',
      'standing or select committee officer',
      'tribunal officer',
    ],
  },
  {
    code: '4169',
    title: 'Other professional occupations in social science, n.e.c.',
    examples: [
      'anthropologist',
      'archaeologist',
      'geographer',
      'gerontologist',
      'historian',
      'linguist',
      'political scientist',
      'psychometrist',
      'sociologist',
    ],
  },
  {
    code: '4211',
    title: 'Paralegal and related occupations',
    examples: [
      'commercial law clerk',
      'corporate paralegal',
      'family law paralegal',
      'independent paralegal',
      'land titles examiner',
      'legal researcher',
      'notary public',
      'paralegal',
      'real estate law clerk',
      'title searcher',
      'trademark agent',
    ],
  },
  {
    code: '4212',
    title: 'Social and community service workers',
    examples: [
      'Aboriginal outreach worker',
      'addictions worker',
      'child and youth worker',
      'community development worker',
      'community service worker',
      'crisis intervention worker',
      'developmental service worker',
      'drop-in centre worker',
      'family service worker',
      'group home worker',
      'income maintenance officer - social services',
      'life skills instructor',
      'mental health worker',
      'rehabilitation worker - social services',
      'social services worker',
      'veteran services officer',
      'welfare and compensation officer',
      "women's shelter supervisor",
      'youth worker',
    ],
  },
  {
    code: '4214',
    title: 'Early childhood educators and assistants',
    examples: [
      'child care worker assistant',
      'daycare helper',
      'daycare supervisor',
      'daycare worker',
      'early childhood assistant',
      'early childhood education worker',
      'early childhood educator - preschool',
      'early childhood educator (ECE)',
      'early childhood educator (ECE) assistant',
      'early childhood program staff assistant',
      'early childhood supervisor',
      'preschool helper',
      'preschool supervisor',
    ],
  },
  {
    code: '4215',
    title: 'Instructors of persons with disabilities',
    examples: [
      'braille instructor',
      'instructor of persons with a mobility impairment',
      'instructor of persons who are deaf',
      'instructor of persons who are hard of hearing',
      'instructor of persons with a learning disability',
      'instructor of persons with a visual impairment',
      'instructor of persons with special needs',
      'lip-reading instructor',
      'orientation and mobility instructor',
      'sign language instructor',
      'teacher for persons with intellectual disabilities',
    ],
  },
  {
    code: '4216',
    title: 'Other instructors',
    examples: [
      "driver's licence examiner",
      'driving instructor',
      'modelling and finishing school instructor',
      'motorcycle driving instructor',
      'sewing instructor - non-vocational',
    ],
  },
  {
    code: '4217',
    title: 'Other religious occupations',
    examples: [
      'brother/sister - religion',
      'Christian science practitioner',
      'deacon',
      'missionary',
      'monk',
      'nun',
      'pastoral animator',
      'religious education worker',
      'Salvation Army field worker',
    ],
  },
  {
    code: '4311',
    title: 'Police officers (except commissioned)',
    examples: [
      'community relations officer - police',
      'constable',
      'crime prevention constable',
      'detective - police',
      'harbour police officer',
      'highway patrol officer',
      'police cadet',
      'police officer',
      'police sergeant',
      'railway police officer',
      'Royal Canadian Mounted Police (RCMP) officer',
    ],
  },
  {
    code: '4312',
    title: 'Firefighters',
    examples: [
      'airport firefighter',
      'fire captain',
      'firefighter',
      'firefighter lieutenant',
      'industrial firefighter',
      'shipboard firefighter',
    ],
  },
  {
    code: '4313',
    title: 'Non-commissioned ranks of the Canadian Armed Forces',
    examples: [
      'able seaman - armed forces',
      'chief warrant officer',
      'master corporal',
      'master seaman',
      'ordinary seaman - armed forces',
      'petty officer first class',
      'private',
      'sergeant',
    ],
  },
  {
    code: '4411',
    title: 'Home child care providers',
    examples: [
      'babysitter',
      'child care live-in caregiver',
      'child care provider - private home',
      'nanny',
      "parent's helper",
    ],
  },
  {
    code: '4412',
    title: 'Home support workers, housekeepers and related occupations',
    examples: [
      'attendant for persons with disabilities - home care',
      'family caregiver',
      'home support worker',
      'housekeeper',
      'live-in caregiver - seniors',
      'personal aide - home support',
      'personal care attendant - home care',
      'respite worker - home support',
    ],
  },
  {
    code: '4413',
    title: 'Elementary and secondary school teacher assistants',
    examples: [
      'educational assistant',
      'educational resources assistant',
      "elementary school teacher's aide",
      'homework assistant',
      'remedial education aide',
      'school lunchroom supervisor',
      "secondary school teacher's assistant",
      'special education assistant',
    ],
  },
  {
    code: '4421',
    title: 'Sheriffs and bailiffs',
    examples: ['bailiff', 'deputy sheriff', 'sheriff', "sheriff's bailiff", "sheriff's officer"],
  },
  {
    code: '4422',
    title: 'Correctional service officers',
    examples: [
      'correctional facility guard',
      'correctional officers supervisor',
      'correctional service officer',
      'prison guard',
    ],
  },
  {
    code: '4423',
    title: 'By-law enforcement and other regulatory officers, n.e.c.',
    examples: [
      'animal control officer',
      'by-law enforcement officer',
      'commercial transport inspector',
      'garbage collection inspector',
      'liquor licence inspector',
      'parking control officer',
      'property standards inspector',
      'taxi inspector',
      'zoning inspector',
    ],
  },
  {
    code: '5111',
    title: 'Librarians',
    examples: [
      'bibliographer',
      'cataloguer - library',
      'cybrarian',
      'liaison librarian',
      'librarian',
      'library consultant',
      'library supervisor',
    ],
  },
  {
    code: '5112',
    title: 'Conservators and curators',
    examples: [
      'art gallery conservator',
      'conservator - art objects',
      'conservator - museum',
      'curator',
      'historical artifact conservator',
      'natural history museum curator',
    ],
  },
  { code: '5113', title: 'Archivists', examples: ['archivist', 'historical archivist', 'multimedia archivist'] },
  {
    code: '5121',
    title: 'Authors and writers',
    examples: [
      'advertising copywriter',
      'copywriter',
      'essayist',
      'interactive media writer',
      'literary writer',
      'medical writer',
      'novelist',
      'playwright',
      'poet',
      'scientific writer',
      'script writer',
      'specifications writer',
      'speech writer',
      'technical writer',
      'writer',
    ],
  },
  {
    code: '5122',
    title: 'Editors',
    examples: [
      'advertising editor',
      'associate editor',
      'contributing editor',
      'copy editor',
      'editor',
      'editorial consultant',
      'literary editor',
      'manuscript editor',
      'medical editor',
      'news editor',
      'news service editor',
      'sports editor',
      'technical editor',
    ],
  },
  {
    code: '5123',
    title: 'Journalists',
    examples: [
      'book reviewer',
      'broadcast journalist',
      'columnist',
      'correspondent',
      'cyberjournalist',
      'investigative reporter',
      'journalist',
      'network reporter',
      'news commentator',
      'newspaper critic',
      'reporter',
    ],
  },
  {
    code: '5125',
    title: 'Translators, terminologists and interpreters',
    examples: [
      'community interpreter',
      'conference interpreter',
      'court interpreter',
      'interpreter',
      'legal terminologist',
      'literary translator',
      'localiser',
      'medical terminologist',
      'sign language interpreter',
      'terminologist',
      'translator',
      'translator adaptor',
      'translator-reviser',
    ],
  },
  {
    code: '5131',
    title: 'Producers, directors, choreographers and related occupations',
    examples: [
      'artistic director - motion picture',
      'choreographer',
      'cyber-choreographer',
      'director - motion picture',
      'director of photography',
      'filmmaker',
      'multimedia audio producer',
      'producer - performing arts',
      'producer-director',
      'radio producer',
      'record producer',
      'stage director - performing arts',
      'technical director - broadcasting',
      'television producer',
    ],
  },
  {
    code: '5132',
    title: 'Conductors, composers and arrangers',
    examples: [
      'arranger',
      'bandmaster',
      'choir director',
      'composer',
      'conductor',
      'music adapter',
      'orchestrator',
      'singer-songwriter-composer',
      'songwriter',
    ],
  },
  {
    code: '5133',
    title: 'Musicians and singers',
    examples: [
      'accompanist',
      'church organist',
      'guitar player',
      'instrumentalist',
      'music teacher - musicians',
      'musician',
      'opera singer',
      'percussionist',
      'recording artist',
      'rock singer',
      'singer',
      'vocalist',
    ],
  },
  {
    code: '5134',
    title: 'Dancers',
    examples: [
      'ballet dancer',
      'ballet teacher',
      'ballroom dancing teacher',
      'dance instructor',
      'dancer',
      'folkloric dancer',
      'interpretative dancer',
      'tap dancer',
    ],
  },
  {
    code: '5135',
    title: 'Actors and comedians',
    examples: [
      'acting teacher - private or studio',
      'actor/actress',
      'comedian',
      'drama teacher - private or studio',
      'narrator',
    ],
  },
  {
    code: '5136',
    title: 'Painters, sculptors and other visual artists',
    examples: [
      'art teacher (except primary, secondary and post-secondary education)',
      'artist',
      'artistic painter',
      'portrait painter',
      'sculptor',
      'silkscreen artist',
      'watercolourist',
    ],
  },
  {
    code: '5211',
    title: 'Library and public archive technicians',
    examples: ['archive technician', 'library technician', 'technical indexer - library'],
  },
  {
    code: '5212',
    title: 'Technical occupations related to museums and art galleries',
    examples: [
      'art gallery preparator',
      'conservation technician - museums and art galleries',
      'heritage interpreter',
      'museology technician',
      'museum extension officer',
      'museum objects cataloguer',
      'museum registrar',
      'museum technician',
      'paintings restoration technician',
      'picture framer - museum and art gallery',
      'restoration technician - museum',
      'taxidermist',
    ],
  },
  {
    code: '5221',
    title: 'Photographers',
    examples: [
      'aerial photographer',
      'commercial photographer',
      'forensic photographer',
      'industrial photographer',
      'photographer',
      'portrait photographer',
      'scientific photographer',
    ],
  },
  {
    code: '5222',
    title: 'Film and video camera operators',
    examples: [
      'assistant camera operator',
      'camera operator',
      'electronic news gathering (ENG) camera operator',
      'film camera operator',
      'motion picture camera operator',
      'studio camera operator',
      'television camera operator',
      'video camera operator',
    ],
  },
  {
    code: '5223',
    title: 'Graphic arts technicians',
    examples: [
      'animated cartoon technician',
      'animation painter',
      'computer graphics technician',
      'graphics technician',
      'multimedia graphic design technician',
    ],
  },
  {
    code: '5224',
    title: 'Broadcast technicians',
    examples: [
      'broadcast technician',
      'broadcast transmitter operator',
      'broadcasting switcher',
      'master control room (MCR) equipment operator - broadcasting',
      'master control room (MCR) technician - broadcasting',
      'mobile broadcasting equipment operator',
      'regional transmitter technician',
      'short-wave receiving station technician',
      'video transmission operator',
    ],
  },
  {
    code: '5225',
    title: 'Audio and video recording technicians',
    examples: [
      'audiovisual (AV) technician',
      'multimedia sound technician',
      'postproduction technician',
      'recording engineer',
      'recording studio technician',
      'sound effects editor',
      'sound mixer',
      'sound technician',
      'video and sound recorder',
      'video recording technician',
    ],
  },
  {
    code: '5226',
    title: 'Other technical and co-ordinating occupations in motion pictures, broadcasting and the performing arts',
    examples: [
      'costumier',
      'gaffer',
      'key grip',
      'lighting technician',
      'make-up artist - motion pictures, broadcasting and performing arts',
      'program co-ordinator - broadcasting',
      'property master - broadcasting',
      'settings shop foreman/woman',
      'special effects technician',
      'stage manager',
      'stunt co-ordinator',
      'theatre technician',
    ],
  },
  {
    code: '5227',
    title: 'Support occupations in motion pictures, broadcasting, photography and the performing arts',
    examples: [
      'boom grip',
      'camera crane operator',
      'dresser - motion pictures, broadcasting and performing arts',
      'lighting assistant',
      'production assistant',
      'prompter',
      'props person',
      'script assistant',
      'set builder',
      'special effects assistant',
      'spotlight operator',
      'stagehand',
    ],
  },
  {
    code: '5231',
    title: 'Announcers and other broadcasters',
    examples: [
      'announcer',
      'broadcaster',
      'disc jockey (DJ) - broadcast',
      'news reader',
      'radio host/hostess',
      'sports announcer',
      'talk show host/hostess',
      'television host/hostess',
    ],
  },
  {
    code: '5232',
    title: 'Other performers, n.e.c.',
    examples: [
      'acrobat',
      'busker',
      'circus performer',
      'clown',
      'fashion model',
      'magician',
      'puppeteer',
      'ventriloquist',
    ],
  },
  {
    code: '5241',
    title: 'Graphic designers and illustrators',
    examples: [
      '3D animation artist',
      'advertising designer',
      'animator - animated films',
      'bank note designer',
      'cartoonist',
      'commercial artist',
      'graphic artist',
      'graphic designer',
      'graphic designer - multimedia',
      'illustrator',
      'layout designer',
      'medical illustrator',
      'multimedia illustrator',
      'scientific illustrator',
    ],
  },
  {
    code: '5242',
    title: 'Interior designers and interior decorators',
    examples: [
      'aircraft interior designer',
      'interior decorator',
      'interior design technician',
      'interior designer',
      'kitchen designer',
      'office space planner',
      'retail space planner',
    ],
  },
  {
    code: '5243',
    title: 'Theatre, fashion, exhibit and other creative designers',
    examples: [
      'clothing designer',
      'costume designer',
      'couturier - haute couture',
      'display designer',
      'fabric designer',
      'fashion designer',
      'fur designer',
      'jewellery designer',
      'lighting designer',
      'museum exhibit designer',
      'shoe designer',
      'trophy designer',
      'window display designer',
    ],
  },
  {
    code: '5244',
    title: 'Artisans and craftspersons',
    examples: [
      'artistic floral arranger',
      'carver',
      'craft instructor (except education)',
      'craftsperson',
      'glass blower',
      'lace weaver - arts and crafts',
      'leather worker',
      'metal arts worker',
      'potter',
      'screen printing artisan',
      'silversmith',
      'stained glass artist',
      'stringed instrument maker',
      'totem pole carver',
      'weaver - arts and crafts',
    ],
  },
  {
    code: '5245',
    title: 'Patternmakers - textile, leather and fur products',
    examples: [
      'dress patternmaker',
      'embroidery patternmaker',
      'fur garment patternmaker',
      'garment patternmaker',
      'leather products patternmaker',
      'shoe patternmaker',
      'textile products patternmaker',
    ],
  },
  {
    code: '5251',
    title: 'Athletes',
    examples: [
      'athlete',
      'baseball player',
      'boxer',
      'figure skater',
      'football player',
      'golfer',
      'harness race driver',
      'hockey player',
      'jockey',
      'professional athlete',
      'skier',
      'sprinter',
      'track athlete',
    ],
  },
  {
    code: '5252',
    title: 'Coaches',
    examples: [
      'coach',
      'figure skating coach',
      'football scout',
      'gymnastics coach',
      'head coach',
      'hockey coach',
      'hockey scout',
      'national team coach',
      'soccer coach',
      'sports scout',
      'swimming coach',
      'volleyball coach',
    ],
  },
  {
    code: '5253',
    title: 'Sports officials and referees',
    examples: [
      'athletics judge',
      'clerk of the course - racetrack',
      'figure skating judge',
      'goal judge',
      'harness racing starter',
      'racetrack timer',
      'referee',
      'sports linesman/woman',
      'sports official',
      'umpire',
    ],
  },
  {
    code: '5254',
    title: 'Program leaders and instructors in recreation, sport and fitness',
    examples: [
      'aerobics instructor',
      'camp counsellor',
      'certified personal trainer',
      'day camp leader',
      'fitness appraiser',
      'fitness instructor',
      'gymnastics teacher',
      'personal trainer',
      'playground worker',
      'recreation program leader',
      'recreation technician',
      'riding instructor',
      'ski instructor',
      'ski patrol',
      'swimming instructor - sports',
    ],
  },
  {
    code: '6211',
    title: 'Retail sales supervisors',
    examples: [
      'department store supervisor',
      'head cashier',
      'liquor store supervisor',
      'produce department supervisor',
      'rental service supervisor',
      'retail sales route supervisor',
      'telemarketing supervisor',
    ],
  },
  {
    code: '6221',
    title: 'Technical sales specialists - wholesale trade',
    examples: [
      'aircraft sales representative',
      'communication equipment sales representative',
      'construction equipment sales representative',
      'electricity sales representative',
      'grain elevator district manager',
      'heavy equipment sales representative',
      'industrial supplies sales representative',
      'medical instruments sales agent',
      'sales engineer - technical support',
      'software sales representative',
      'technical sales representative',
      'technical sales supervisor',
      'technical support specialist - wholesale trade',
    ],
  },
  {
    code: '6222',
    title: 'Retail and wholesale buyers',
    examples: [
      'appliance buyer',
      'assistant buyer',
      'beverage taster and buyer',
      'buyer - retail',
      'buyer - wholesale',
      'chief buyer',
      'clothing buyer',
      'food buyer',
      'produce buyer',
    ],
  },
  {
    code: '6231',
    title: 'Insurance agents and brokers',
    examples: ['insurance agent', 'insurance broker', 'insurance sales representative', 'insurance sales supervisor'],
  },
  {
    code: '6232',
    title: 'Real estate agents and salespersons',
    examples: [
      'commercial real estate agent',
      'real estate agent',
      'real estate agent supervisor',
      'real estate sales representative',
      'residential real estate agent',
    ],
  },
  {
    code: '6235',
    title: 'Financial sales representatives',
    examples: [
      'credit officer',
      'financial services officer',
      'financial services representative',
      'loan officer',
      'mortgage officer',
      'sales associate - personal banking',
    ],
  },
  {
    code: '6311',
    title: 'Food service supervisors',
    examples: ['cafeteria supervisor', 'canteen supervisor', 'catering supervisor', 'food service supervisor'],
  },
  {
    code: '6312',
    title: 'Executive housekeepers',
    examples: [
      'assistant executive housekeeper',
      'executive housekeeper',
      'hospital executive housekeeper',
      'hotel executive housekeeper',
      'housekeeping director',
      'housekeeping manager',
    ],
  },
  {
    code: '6313',
    title: 'Accommodation, travel, tourism and related services supervisors',
    examples: [
      'casino dealer supervisor',
      'hotel clerk supervisor',
      'reservations supervisor',
      'tour guide supervisor',
      'travel clerk supervisor',
    ],
  },
  {
    code: '6314',
    title: 'Customer and information services supervisors',
    examples: [
      'call centre agent supervisor',
      'contact centre supervisor',
      'current accounts supervisor',
      'customer service representatives supervisor - financial services',
      'information clerks supervisor',
    ],
  },
  {
    code: '6315',
    title: 'Cleaning supervisors',
    examples: [
      'building cleaning supervisor',
      'carpet cleaning supervisor',
      'cleaning supervisor',
      'head custodian',
      'housekeeping supervisor',
      'window washing supervisor',
    ],
  },
  {
    code: '6316',
    title: 'Other services supervisors',
    examples: [
      'campground supervisor',
      'clubhouse attendants supervisor',
      'commissionaire supervisor',
      'dry cleaning production supervisor',
      'dry cleaning supervisor',
      'foreman/woman - laundry and dry cleaning',
      'laundry supervisor',
      'parking lot supervisor',
      'security guard supervisor',
      'ski lift attendant supervisor',
      'theatre attendant supervisor',
      'ticket taker supervisor',
    ],
  },
  {
    code: '6321',
    title: 'Chefs',
    examples: [
      'chef',
      'corporate chef',
      'executive chef',
      'executive sous-chef',
      'head chef',
      'master chef',
      'pastry chef',
      'saucier',
      'sous-chef',
      'specialist chef',
    ],
  },
  {
    code: '6322',
    title: 'Cooks',
    examples: [
      'apprentice cook',
      'cook',
      'dietary cook',
      'first cook',
      'grill cook',
      'hospital cook',
      'institutional cook',
      'journeyman/woman cook',
      'licensed cook',
      'line cook',
      'second cook',
      'short order cook',
    ],
  },
  {
    code: '6331',
    title: 'Butchers, meat cutters and fishmongers - retail and wholesale',
    examples: [
      'butcher apprentice',
      'fishmonger',
      'fishmonger - retail',
      'head butcher - wholesale',
      'meat cutter - retail or wholesale',
      'retail butcher',
      'supermarket meat cutter',
    ],
  },
  {
    code: '6332',
    title: 'Bakers',
    examples: ['baker', 'baker apprentice', 'bakery supervisor', 'bread baker', 'head baker'],
  },
  {
    code: '6341',
    title: 'Hairstylists and barbers',
    examples: [
      'barber',
      'barber apprentice',
      'hair colour technician',
      'hairdresser',
      'hairdresser apprentice',
      'hairstylist',
      'hairstylist apprentice',
      'wig stylist',
    ],
  },
  {
    code: '6342',
    title: 'Tailors, dressmakers, furriers and milliners',
    examples: [
      'alterations dressmaker',
      'custom furrier',
      'custom tailor',
      'furrier',
      'garment alterationist',
      'milliner',
      'seamstress',
      'tailor',
    ],
  },
  {
    code: '6343',
    title: 'Shoe repairers and shoemakers',
    examples: ['bootmaker', 'custom shoemaker', 'orthopedic shoemaker', 'shoe repairer', 'shoemaker'],
  },
  {
    code: '6344',
    title: 'Jewellers, jewellery and watch repairers and related occupations',
    examples: [
      'clock repairer',
      'diamond cutter',
      'gem cutter',
      'pearl cutter',
      'precious stone setter',
      'watch repairer',
    ],
  },
  {
    code: '6345',
    title: 'Upholsterers',
    examples: [
      'automobile upholsterer',
      'custom upholsterer',
      'furniture upholsterer',
      'upholsterer',
      'upholstery repairer',
    ],
  },
  {
    code: '6346',
    title: 'Funeral directors and embalmers',
    examples: ['apprentice embalmer', 'embalmer', 'funeral director', 'mortician'],
  },
  {
    code: '6411',
    title: 'Sales and account representatives - wholesale trade (non-technical)',
    examples: [
      'advertising time sales representative',
      'food products sales representative',
      'freight sales agent',
      'graphic design sales representative',
      'hotel accommodations sales executive',
      'liquor sales representative',
      'magazine sales representative',
      'oil distributor',
      'security services sales consultant',
      'transfer company agent',
      'wholesale trade representatives supervisor',
    ],
  },
  {
    code: '6421',
    title: 'Retail salespersons',
    examples: [
      'audio equipment salesperson',
      'automobile salesperson',
      'car rental agent',
      'clothing salesperson',
      'computer salesperson - retail',
      'counter clerk - retail',
      'department store clerk',
      'furniture salesperson',
      'hardware store clerk',
      'jewellery salesperson',
      'retail sales associate',
      'retail sales clerk',
      'retail salesperson',
      'sales consultant - retirement homes',
    ],
  },
  {
    code: '6511',
    title: "Maîtres d'hôtel and hosts/hostesses",
    examples: [
      'chief host/hostess - food services',
      'dining room host/hostess',
      "maître d'",
      'restaurant host/hostess',
    ],
  },
  { code: '6512', title: 'Bartenders', examples: ['bar attendant', 'bar steward', 'bartender', 'head bartender'] },
  {
    code: '6513',
    title: 'Food and beverage servers',
    examples: [
      'banquet server',
      'captain waiter/waitress',
      'chief wine steward',
      'cocktail waiter/waitress',
      'food and beverage server',
      'formal service waiter/waitress',
      'sommelier',
      'waiter/waitress',
      'wine steward',
    ],
  },
  {
    code: '6521',
    title: 'Travel counsellors',
    examples: ['reservation agent - travel agency', 'travel agent', 'travel consultant'],
  },
  {
    code: '6522',
    title: 'Pursers and flight attendants',
    examples: [
      'airline purser',
      'flight attendant',
      'flight service director',
      'passenger service director - water transportation',
      'ship purser',
    ],
  },
  {
    code: '6523',
    title: 'Airline ticket and service agents',
    examples: [
      'airline baggage agent',
      'airline cargo agent',
      'airline load planner',
      'airline passenger agent',
      'airline reservation agent',
      'airline ticket agent',
      'baggage tracer - airline',
      'client service representative - airline',
      'counter services agent - airline',
      'customer service agent - airline',
      'station agent - airline',
    ],
  },
  {
    code: '6524',
    title: 'Ground and water transport ticket agents, cargo service representatives and related clerks',
    examples: [
      'bus ticket agent',
      'cargo customer service representative - railway',
      'counter service agent - railway',
      'railway passenger agent',
      'railway reservations clerk',
      'reservations clerk - cruise line',
      'ticket agent (except airline)',
    ],
  },
  {
    code: '6525',
    title: 'Hotel front desk clerks',
    examples: [
      'guest services agent - hotel',
      'hotel front desk clerk',
      'night clerk',
      'reservations clerk - hotel',
      'room clerk',
    ],
  },
  {
    code: '6531',
    title: 'Tour and travel guides',
    examples: [
      'adventure travel site interpreter',
      'bicycle tour guide',
      'calÃ¨che driver',
      'factory tour guide',
      'industrial site interpreter',
      'tour guide',
      'travel guide',
    ],
  },
  {
    code: '6532',
    title: 'Outdoor sport and recreational guides',
    examples: [
      'canoeing guide',
      'dude wrangler',
      'fishing guide',
      'hot air balloonist',
      'hunting guide',
      'mountain climbing guide',
      'outdoor guide',
      'outfitter',
      'rafting guide',
    ],
  },
  { code: '6533', title: 'Casino occupations', examples: ['croupier', 'keno runner - casino', 'keno writer'] },
  {
    code: '6541',
    title: 'Security guards and related security service occupations',
    examples: [
      'airport security guard',
      'alarm investigator',
      'armoured car guard',
      'automatic teller machine (ATM) guard',
      'bodyguard (except police)',
      'commissionaire',
      'corporate security officer',
      'gate attendant - security',
      'house detective',
      'night guard',
      'postal inspection officer',
      'preboarding security guard',
      'private investigator',
      'private security officer',
      'retail loss prevention officer',
      'security guard',
      'store detective',
      'theft prevention officer',
    ],
  },
  {
    code: '6551',
    title: 'Customer services representatives - financial institutions',
    examples: [
      'bank teller',
      'credit union teller',
      'financial customer service representative',
      'foreign exchange teller - financial services',
    ],
  },
  {
    code: '6552',
    title: 'Other customer and information services representatives',
    examples: [
      'accounts information clerk',
      'bus information clerk',
      'complaints clerk - customer service',
      'contact centre agent - customer service',
      'courtesy desk clerk',
      'customer service representative - call centre',
      'enquiries clerk',
      'information clerk - customer service',
      'lost-and-found clerk',
      'order desk agent',
      'public relations clerk',
      'tourist information clerk',
    ],
  },
  {
    code: '6561',
    title: 'Image, social and other personal consultants',
    examples: [
      'etiquette consultant',
      'fashion and wardrobe consultant',
      'fashion colour consultant',
      'image consultant',
      'make-up consultant',
      'public speaking consultant',
      'wardrobe consultant',
      'wedding consultant',
      'weight loss consultant',
    ],
  },
  {
    code: '6562',
    title: 'Estheticians, electrologists and related occupations',
    examples: [
      'beauty treatment operator',
      'cosmetician',
      'electrologist',
      'electrolysis technician',
      'esthetician',
      'hair replacement technician',
      'manicurist',
      'pedicurist',
      'scalp treatment specialist',
      'tattoo artist',
    ],
  },
  {
    code: '6563',
    title: 'Pet groomers and animal care workers',
    examples: [
      'animal care worker (except farm)',
      'dog groomer',
      'dog trainer',
      'kennel attendant',
      'laboratory animal attendant',
      'pet groomer',
      'pound attendant',
      'veterinary attendant',
      'zoo attendant',
    ],
  },
  {
    code: '6564',
    title: 'Other personal service occupations',
    examples: [
      'astrologer',
      'chat line agent',
      'dating service consultant',
      'fortune teller',
      'psychic consultant',
      'psychic reader',
    ],
  },
  {
    code: '6611',
    title: 'Cashiers',
    examples: [
      'box office cashier',
      'cafeteria cashier',
      'grocery store cashier',
      'office cashier',
      'racetrack cashier',
      'self-serve gas bar cashier',
      'theatre cashier',
    ],
  },
  {
    code: '6621',
    title: 'Service station attendants',
    examples: ['gas station attendant (except self-serve)', 'marina attendant', 'service station attendant'],
  },
  {
    code: '6622',
    title: 'Store shelf stockers, clerks and order fillers',
    examples: [
      'bag clerk',
      'grocery clerk',
      'grocery packer',
      'order filler - retail',
      'price clerk - retail',
      'produce clerk',
      'shelf stocker - retail',
      'supermarket clerk',
    ],
  },
  {
    code: '6623',
    title: 'Other sales related occupations',
    examples: [
      'canvasser - retail',
      'demonstrator - retail',
      'direct distributor - retail',
      'door-to-door salesperson',
      'home demonstrator - retail',
      'street vendor',
      'telemarketer',
      'telephone solicitor',
    ],
  },
  {
    code: '6711',
    title: 'Food counter attendants, kitchen helpers and related support occupations',
    examples: [
      'bartender helper',
      'bus boy/girl',
      'cafeteria counter attendant',
      "cook's helper",
      'dishwasher',
      'fast-food preparer',
      'food counter attendant',
      'food preparer',
      'food service helper',
      'ice cream counter attendant',
      'kitchen helper',
      'salad bar attendant',
      'sandwich maker',
    ],
  },
  {
    code: '6721',
    title: 'Support occupations in accommodation, travel and facilities set-up services',
    examples: [
      'baggage porter',
      'banquet porter',
      'bellhop',
      'cruise line passenger attendant',
      'facilities porter',
      'hotel doorkeeper',
      'hotel guest services attendant',
      'luggage attendant',
      'room flipper',
      'showcase installer',
      'train service attendant',
    ],
  },
  {
    code: '6722',
    title: 'Operators and attendants in amusement, recreation and sport',
    examples: [
      'amusement attraction operator',
      'amusement park attendant',
      'amusement ride operator',
      'athletic equipment custodian',
      'billiard parlour attendant',
      'bingo hall attendant',
      'bowling alley attendant',
      'campground attendant',
      'game concession operator',
      'recreation attendant',
      'recreational facility attendant',
      'rink ice maker',
      'ski lift attendant',
      'sports attendant',
      'tennis court attendant',
    ],
  },
  {
    code: '6731',
    title: 'Light duty cleaners',
    examples: [
      'cleaner',
      'hospital cleaner',
      'hotel cleaner',
      'house cleaner',
      'housekeeping aide',
      'housekeeping room attendant',
      'light duty cleaner',
      'office cleaner',
      'sweeper',
    ],
  },
  {
    code: '6732',
    title: 'Specialized cleaners',
    examples: [
      'auto detailer',
      'building exterior cleaner',
      'carpet cleaner',
      'chimney cleaner',
      'freight car cleaner',
      'furnace cleaner',
      'laboratory equipment cleaner',
      'sandblaster',
      'septic tank cleaner',
      'upholstery cleaner',
      'vehicle cleaner',
      'ventilation system cleaner',
      'window cleaner',
    ],
  },
  {
    code: '6733',
    title: 'Janitors, caretakers and building superintendents',
    examples: [
      'building caretaker',
      'building superintendent',
      'custodian',
      'handyman/woman',
      'heavy-duty cleaner',
      'industrial cleaner',
      'plant cleaner',
      'school janitor',
    ],
  },
  {
    code: '6741',
    title: 'Dry cleaning, laundry and related occupations',
    examples: [
      'assembler - laundry and dry cleaning',
      'bagger - laundry and dry cleaning',
      'drapery cleaner',
      'dry cleaner',
      'dyer - laundry and dry cleaning',
      'finisher - laundry and dry cleaning',
      'fur cleaner - laundry and dry cleaning',
      'hand finisher - laundry and dry cleaning',
      'inspector - laundry and dry cleaning',
      'laundry machine operator',
      'laundry worker',
      'leather cleaner - laundry and dry cleaning',
      'presser - laundry and dry cleaning',
      'pressing machine tender - laundry and dry cleaning',
      'silk finisher - laundry and dry cleaning',
      'steam finisher - laundry and dry cleaning',
      'suede cleaner - laundry and dry cleaning',
    ],
  },
  {
    code: '6742',
    title: 'Other service support occupations, n.e.c.',
    examples: [
      'beauty salon attendant',
      'car jockey',
      'cloakroom attendant',
      'door attendant (except hotel)',
      'funeral home attendant',
      'fur storage attendant',
      'hotel valet',
      'laundromat attendant',
      'parking lot attendant',
      'shoe shiner',
      'tanning salon attendant',
      'theatre usher',
      'ticket taker',
      'toll booth attendant',
    ],
  },
  {
    code: '7201',
    title: 'Contractors and supervisors, machining, metal forming, shaping and erecting trades and related occupations',
    examples: [
      'automotive machine shop foreman/woman',
      'blacksmith foreman/woman',
      'boilermaker foreman/woman',
      'boilermaker supervisor',
      'ironwork contractor',
      'ironworkers foreman/woman',
      'machine shop supervisor',
      'machinists foreman/woman',
      'machinists supervisor',
      'maintenance machine shop foreman/woman',
      'metal mould and metal patternmakers supervisor',
      'sheet metal workers supervisor',
      'structural metal fitter supervisor',
      'tool and die inspectors supervisor',
      'tool and die makers foreman/woman',
      'welder supervisor',
    ],
  },
  {
    code: '7202',
    title: 'Contractors and supervisors, electrical trades and telecommunications occupations',
    examples: [
      'cable maintenance and repair supervisor',
      'cablevision technicians foreman/woman',
      'construction electricians foreman/woman',
      'electrical contractor',
      'industrial electrical systems foreman/woman',
      'plant electrician supervisor',
      'power line and cable workers foreman/woman',
      'power systems electricians foreman/woman',
      'residential electrician supervisor',
      'telecommunication installation and repair worker foreman/woman',
    ],
  },
  {
    code: '7203',
    title: 'Contractors and supervisors, pipefitting trades',
    examples: [
      'gas fitters foreman/woman',
      'gas fitters supervisor',
      'pipefitters foreman/woman',
      'pipefitting contractor',
      'plumber foreman/woman',
      'plumbers supervisor',
      'plumbing contractor',
      'sprinkler system installer foreman/woman',
      'steamfitter foreman/woman',
      'steamfitters supervisor',
    ],
  },
  {
    code: '7204',
    title: 'Contractors and supervisors, carpentry trades',
    examples: [
      'cabinetmaking contractor',
      'carpenter supervisor',
      'carpentry contractor',
      'finish carpenters foreman/woman',
      'form builders foreman/woman',
      'framers foreman/woman',
      'maintenance carpenters foreman/woman',
    ],
  },
  {
    code: '7205',
    title: 'Contractors and supervisors, other construction trades, installers, repairers and servicers',
    examples: [
      'bicycle repair shop supervisor',
      'bricklaying contractor',
      'cement finishing contractor',
      'glaziers foreman/woman',
      'insulators foreman/woman',
      'painters and decorators supervisor',
      'painting contractor',
      'pest control supervisor',
      'plasterer foreman/woman',
      'roofing contractor',
      'tilesetters supervisor',
    ],
  },
  {
    code: '7231',
    title: 'Machinists and machining and tooling inspectors',
    examples: [
      'automotive machinist',
      'aviation machinist',
      'general machinist',
      'machine shop inspector',
      'machined parts inspector',
      'machining inspector',
      'machinist',
      'machinist apprentice',
      'tooling inspector',
    ],
  },
  {
    code: '7232',
    title: 'Tool and die makers',
    examples: [
      'die finisher',
      'die maker',
      'jig maker',
      'metal mould maker',
      'metal patternmaker',
      'metal patternmaker apprentice',
      'mould maker - plastics processing',
      'mould maker apprentice',
      'tool and die maker',
      'tool and die maker apprentice',
      'toolmaker',
    ],
  },
  {
    code: '7233',
    title: 'Sheet metal workers',
    examples: [
      'apprentice sheet metal worker',
      'sheet metal fabricator',
      'sheet metal mechanic',
      'sheet metal worker',
      'tinsmith',
    ],
  },
  {
    code: '7234',
    title: 'Boilermakers',
    examples: [
      'boiler fitter',
      'boiler installer',
      'boilermaker',
      'boilermaker apprentice',
      'construction boilermaker',
      'industrial boilermaker',
      'marine boilermaker',
      'pressure vessel fabricator',
    ],
  },
  {
    code: '7235',
    title: 'Structural metal and platework fabricators and fitters',
    examples: [
      'metal fabricator',
      'plater',
      'platework fitter',
      'shipfitter',
      'shipfitter apprentice',
      'steel fabricator',
      'structural steel fitter',
    ],
  },
  {
    code: '7236',
    title: 'Ironworkers',
    examples: [
      'ironworker',
      'ironworker - metal building systems erector',
      'ironworker apprentice',
      'ornamental ironworker',
      'reinforcing ironworker',
      'structural steel erector',
    ],
  },
  {
    code: '7237',
    title: 'Welders and related machine operators',
    examples: [
      'aviation welding technician',
      'brazing machine operator',
      'brazing machine setter',
      'electric arc welder',
      'journeyman/woman welder',
      'laser welding operator',
      'pressure vessel welder',
      'production welder',
      'soldering machine operator',
      'spot welder',
      'submerged arc welder',
      'welder',
      'welder apprentice',
      'welder-fitter',
    ],
  },
  {
    code: '7241',
    title: 'Electricians (except industrial and power system)',
    examples: [
      'apprentice electrician',
      'construction electrician',
      'construction electrician apprentice',
      'domestic and rural electrician',
      'electrician',
    ],
  },
  {
    code: '7242',
    title: 'Industrial electricians',
    examples: [
      'industrial electrician',
      'industrial electrician apprentice',
      'marine electrician',
      'mill electrician',
      'mine electrician',
      'plant electrician',
      'plant maintenance electrician',
      'shipyard electrician',
    ],
  },
  {
    code: '7243',
    title: 'Power system electricians',
    examples: [
      'apprentice power system electrician',
      'power electrician',
      'power station electrician',
      'power system electrician',
    ],
  },
  {
    code: '7244',
    title: 'Electrical power line and cable workers',
    examples: [
      'apprentice lineman/woman - electric power systems',
      'cable installer - electrical power',
      'cable splicer - electrical power',
      'construction lineman/woman - electrical power line',
      'power line patroller',
      'power lineman/woman',
      'powerline technician',
    ],
  },
  {
    code: '7245',
    title: 'Telecommunications line and cable workers',
    examples: [
      'apprentice lineman/woman - telecommunications',
      'communication technician - construction',
      'construction technician - cable television',
      'splicer technician - telephone',
      'telecommunication cable repairer',
      'telecommunications line installer',
      'telecommunications lineman/woman',
      'telephone line technician',
    ],
  },
  {
    code: '7246',
    title: 'Telecommunications installation and repair workers',
    examples: [
      'apprentice communication electrician - switching',
      'cellular telephone technician',
      'exchange tester - telecommunications',
      'mobile radio installer',
      'private branch exchange (PBX) installer',
      'switch network installer and repairer',
      'telecommunications equipment technician',
      'telephone communication technician',
      'telephone installer',
      'telephone line and station installer',
    ],
  },
  {
    code: '7247',
    title: 'Cable television service and maintenance technicians',
    examples: [
      'cable television installation technician',
      'cable television installer',
      'cable television maintenance technician',
      'cablevision servicer',
      'community antenna television (CATV) technician',
      'direct broadcast satellite (DBS) technician - cable television',
    ],
  },
  {
    code: '7251',
    title: 'Plumbers',
    examples: ['maintenance plumber', 'plumber', 'plumber apprentice', 'plumbing mechanic'],
  },
  {
    code: '7252',
    title: 'Steamfitters, pipefitters and sprinkler system installers',
    examples: [
      'apprentice pipefitter-steamfitter',
      'fire protection mechanic',
      'fire sprinkler fitter',
      'marine pipefitter',
      'pipefitter',
      'sprinkler system fitter',
      'sprinkler system installer',
      'steamfitter',
      'steamfitter-pipefitter',
    ],
  },
  {
    code: '7253',
    title: 'Gas fitters',
    examples: ['gas fitter', 'gas fitter apprentice', 'gas servicer', 'gas technician', 'industrial gas fitter-tester'],
  },
  {
    code: '7271',
    title: 'Carpenters',
    examples: [
      'apprentice carpenter',
      'carpenter',
      'carpenter-joiner',
      'finish carpenter',
      'journeyman/woman carpenter',
      'maintenance carpenter',
      'metal framer - carpentry',
      'renovation carpenter',
      'rough carpenter',
      'stair builder-carpenter',
    ],
  },
  {
    code: '7272',
    title: 'Cabinetmakers',
    examples: ['cabinetmaker', 'cabinetmaker apprentice', 'custom wood furniture maker', 'furniture cabinetmaker'],
  },
  {
    code: '7281',
    title: 'Bricklayers',
    examples: [
      'apprentice bricklayer',
      'bricklayer',
      'brickmason',
      'refractory bricklayer',
      'stonecutter',
      'stonemason',
    ],
  },
  {
    code: '7282',
    title: 'Concrete finishers',
    examples: [
      'cement finisher apprentice',
      'cement mason',
      'concrete finisher',
      'concrete mason',
      'precast concrete finisher',
    ],
  },
  {
    code: '7283',
    title: 'Tilesetters',
    examples: [
      'apprentice tilesetter',
      'ceramic tile installer',
      'marble setter',
      'terrazzo polisher',
      'terrazzo worker',
      'tile installer',
      'tilesetter',
    ],
  },
  {
    code: '7284',
    title: 'Plasterers, drywall installers and finishers and lathers',
    examples: [
      'acoustical ceiling installer',
      'ceiling installer',
      'drywall applicator',
      'drywall finisher',
      'drywall installer and finisher apprentice',
      'drywall taper',
      'lather',
      'lather (interior systems mechanic)',
      'lather apprentice',
      'plasterer',
      'plasterer apprentice',
      'sheetrock applicator',
      'wood lather',
    ],
  },
  {
    code: '7291',
    title: 'Roofers and shinglers',
    examples: [
      'apprentice roofer',
      'asphalt roofer',
      'built-up roofer',
      'flat roofer',
      'residential steep roofer',
      'roofer',
      'shingler',
      'single-ply roofer',
    ],
  },
  {
    code: '7292',
    title: 'Glaziers',
    examples: [
      'glazier',
      'glazier and metal mechanic',
      'glazier apprentice',
      'plate glass installer',
      'stained glass glazier',
      'structural glass glazier',
    ],
  },
  {
    code: '7293',
    title: 'Insulators',
    examples: [
      'boiler and pipe insulator',
      'building insulator',
      'firestopping insulator',
      'heat and frost insulator',
      'insulation applicator',
      'insulation mechanic',
      'insulator',
      'insulator apprentice',
      'sound insulator',
    ],
  },
  {
    code: '7294',
    title: 'Painters and decorators (except interior decorators)',
    examples: [
      'construction painter',
      'maintenance painter',
      'painter',
      'painter and decorator',
      'painter and decorator apprentice',
      'paperhanger',
    ],
  },
  {
    code: '7295',
    title: 'Floor covering installers',
    examples: [
      'carpet layer',
      'floor covering installer apprentice',
      'floor covering mechanic',
      'hardwood floor layer',
      'residential floor and wall covering installer',
      'resilient floor installer',
      'vinyl floor installer',
    ],
  },
  {
    code: '7301',
    title: 'Contractors and supervisors, mechanic trades',
    examples: [
      'air conditioning and refrigeration mechanic foreman/woman',
      'aircraft maintenance engineers (AME) supervisor',
      'aircraft mechanics and inspectors foreman/woman',
      'appliance repair shop supervisor',
      'electrical mechanics foreman/woman',
      'heating systems contractor',
      'heating systems mechanics foreman/woman',
      'heavy machinery assembly foreman/woman',
      'industrial mechanics supervisor',
      'motor vehicle repair shop supervisor',
      'printing machine repairers foreman/woman',
      'railway car repairer foreman/woman',
      'railway equipment maintenance inspectors foreman/woman',
      'small-engine repair shop foreman/woman',
      'textile machine mechanics foreman/woman',
    ],
  },
  {
    code: '7302',
    title: 'Contractors and supervisors, heavy equipment operator crews',
    examples: [
      'demolition foreman/woman',
      'drilling and blasting foreman/woman - construction',
      'excavating contractor',
      'heavy equipment operators supervisor',
      'logging road construction foreman/woman',
      'oil field construction supervisor',
      'paving contractor',
      'pipeline construction supervisor',
      'railway gang foreman/woman',
      'railway section foreman/woman',
      'railway track maintenance foreman/woman',
      'road maintenance foreman/woman',
      'track foreman/woman - railway',
      'water well drilling supervisor',
    ],
  },
  {
    code: '7303',
    title: 'Supervisors, printing and related occupations',
    examples: [
      'bindery foreman/woman - printing',
      'bindery supervisor - printing',
      'composing room supervisor - printing',
      'film processing supervisor',
      'finishing supervisor - printing',
      'photographic and film processing foreman/woman',
      'platemaking supervisor - printing',
      'prepress supervisor - printing',
      'pressroom supervisor - printing',
    ],
  },
  {
    code: '7304',
    title: 'Supervisors, railway transport operations',
    examples: [
      'freight train road foreman/woman',
      'railway transport operations supervisor',
      'stationmaster',
      'trainmaster',
      'yard foreman/woman - railway',
      'yardmaster - railway',
    ],
  },
  {
    code: '7305',
    title: 'Supervisors, motor transport and other ground transit operators',
    examples: [
      'bus dispatcher',
      'bus inspector',
      'light rail transit (LRT) operators supervisor',
      'school bus drivers supervisor',
      'subway operators supervisor',
      'subway traffic controller',
      'transit mobile inspector',
      'truck drivers foreman/woman',
      'urban transit system foreman/woman',
    ],
  },
  {
    code: '7311',
    title: 'Construction millwrights and industrial mechanics',
    examples: [
      'card fixer',
      'card grinder',
      'construction millwright',
      'industrial mechanic',
      'industrial mechanic apprentice',
      'industrial sewing machine mechanic',
      'knitting machine mechanic',
      'loom fixer',
      'loom technician',
      'maintenance millwright',
      'millwright',
      'millwright apprentice',
      'open-end technician',
      'plant equipment mechanic',
      'spinning fixer',
      'textile fixer',
      'textile machine mechanic',
      'textile machinery fixer',
      'treatment plant maintenance mechanic',
    ],
  },
  {
    code: '7312',
    title: 'Heavy-duty equipment mechanics',
    examples: [
      'agricultural equipment technician',
      'construction equipment mechanic',
      'diesel mechanic - heavy equipment',
      'farm equipment mechanic',
      'heavy equipment mechanic',
      'heavy mobile logging equipment mechanic',
      'heavy mobile mining equipment mechanic',
      'heavy-duty equipment mechanic apprentice',
      'heavy-duty equipment technician',
      'locomotive mechanic',
      'tractor mechanic',
    ],
  },
  {
    code: '7313',
    title: 'Heating, refrigeration and air conditioning mechanics',
    examples: [
      'central air conditioning mechanic',
      'commercial air conditioning mechanic',
      'heating and cooling mechanic',
      'heating, ventilation and air conditioning (HVAC) mechanic',
      'refrigeration and air conditioning mechanic apprentice',
      'refrigeration mechanic',
      'transport refrigeration mechanic',
    ],
  },
  {
    code: '7314',
    title: 'Railway carmen/women',
    examples: [
      'rail vehicle mechanic',
      'railway car inspector',
      'railway carman/woman',
      'railway carman/woman apprentice',
      'streetcar and subway car mechanic',
    ],
  },
  {
    code: '7315',
    title: 'Aircraft mechanics and aircraft inspectors',
    examples: [
      'aircraft gas turbine engine technician',
      'aircraft hydraulics mechanic',
      'aircraft inspector',
      'aircraft maintenance engineer (AME) (except avionics)',
      'aircraft mechanic',
      'aircraft repair and overhaul inspector',
      'aircraft repair shop inspector',
      'aircraft structural repair technician',
      'aircraft systems inspector',
      'aviation mechanical component shop technician',
      'certified aircraft technician',
      'flight test inspector',
    ],
  },
  {
    code: '7316',
    title: 'Machine fitters',
    examples: [
      'aircraft engine fitter',
      'assembly fitter',
      'heavy equipment fitter',
      'machine builder',
      'machine fitter',
      'machine tool builder',
      'mechanical fitter',
    ],
  },
  {
    code: '7318',
    title: 'Elevator constructors and mechanics',
    examples: ['elevator constructor', 'elevator mechanic', 'elevator mechanic apprentice', 'escalator repairer'],
  },
  {
    code: '7321',
    title: 'Automotive service technicians, truck and bus mechanics and mechanical repairers',
    examples: [
      'apprentice motor vehicle mechanic',
      'automobile mechanic',
      'automotive service technician',
      'brake systems mechanic',
      'bus mechanic',
      'mechanical upgrader - motor vehicle manufacturing',
      'motor repairer - motor vehicle manufacturing',
      'transmission mechanic',
      'truck and transport mechanic',
      'truck-trailer repairer',
      'tune-up specialist - motor vehicle',
    ],
  },
  {
    code: '7322',
    title: 'Motor vehicle body repairers',
    examples: [
      'autobody repairer',
      'automotive body mechanic',
      'automotive glass technician',
      'automotive painter - motor vehicle repair',
      'automotive painter apprentice',
      'metal finisher - motor vehicle manufacturing',
      'metal repairer - motor vehicle manufacturing',
      'motor vehicle body repairer apprentice',
      'motor vehicle body technician - truck',
      'painter - motor vehicle repair',
    ],
  },
  {
    code: '7331',
    title: 'Oil and solid fuel heating mechanics',
    examples: [
      'furnace installer and repairer (except gas)',
      'heating service mechanic',
      'heating systems technician',
      'oil burner installer',
      'oil burner mechanic',
      'oil burner mechanic apprentice',
      'wood burner installer',
    ],
  },
  {
    code: '7332',
    title: 'Appliance servicers and repairers',
    examples: [
      'appliance service technician',
      'appliance service technician apprentice',
      'appliance servicer',
      'commercial foodservice appliance technician',
      'commercial laundry appliance technician',
      'dishwasher repairer',
      'refrigerator repairer',
      'service technician - electrical appliances',
      'service technician - gas appliances',
      'stove repairer',
      'vacuum cleaner repairer',
      'washing machine servicer',
      'window air conditioner repairer',
    ],
  },
  {
    code: '7333',
    title: 'Electrical mechanics',
    examples: [
      'armature winder repairer',
      'coil winder and repairer',
      'electric motor systems technician',
      'electrical mechanic',
      'electrical mechanic apprentice',
      'electrical rewind mechanic',
      'electrical transformer repairer',
      'industrial motor winder-repairer',
      'power transformer repairer',
      'transformer repairer',
    ],
  },
  {
    code: '7334',
    title: 'Motorcycle, all-terrain vehicle and other related mechanics',
    examples: [
      'all-terrain vehicle repairer',
      'forklift mechanic',
      'industrial truck repairer',
      'motor boat mechanic',
      'motor scooter repairer',
      'motorcycle mechanic',
      'motorcycle mechanic apprentice',
      'outboard motor mechanic',
      'snowmobile repairer',
    ],
  },
  {
    code: '7335',
    title: 'Other small engine and small equipment repairers',
    examples: [
      'air-cooled engine mechanic',
      'gasoline-powered lawn mower repairer',
      'lawn and garden equipment technician',
      'small engine technician',
      'small equipment mechanic apprentice',
      'small equipment repairer',
    ],
  },
  {
    code: '7361',
    title: 'Railway and yard locomotive engineers',
    examples: [
      'locomotive engineer - railway',
      'railway engineer',
      'switch engineer - railway',
      'transfer hostler - railway',
      'yard engineer - railway',
    ],
  },
  {
    code: '7362',
    title: 'Railway conductors and brakemen/women',
    examples: [
      'brakeman/woman',
      'conductor - switching yard',
      'freight train conductor',
      'front-end brakeman/woman',
      'passenger train conductor',
      'railway conductor',
      'road freight brakeman/woman',
      'tail-end brakeman/woman',
    ],
  },
  {
    code: '7371',
    title: 'Crane operators',
    examples: [
      'boom truck crane operator',
      'bridge crane operator',
      'climbing crane operator',
      'construction crane operator',
      'crane operator',
      'dragline crane operator',
      'gantry crane operator',
      'hoist operator (except underground mining)',
      'mobile crane operator',
      'tower crane operator',
      'tractor crane operator',
    ],
  },
  {
    code: '7372',
    title: 'Drillers and blasters - surface mining, quarrying and construction',
    examples: [
      'blaster - quarrying',
      'blaster - surface mining',
      'construction driller',
      'driller - surface mine',
      'open-pit blaster',
      'rotary drilling machine operator',
    ],
  },
  {
    code: '7373',
    title: 'Water well drillers',
    examples: [
      'cable tool driller - water well drilling',
      'churn drill operator - water well drilling',
      'water well driller',
      'water well driller apprentice',
    ],
  },
  {
    code: '7381',
    title: 'Printing press operators',
    examples: [
      'apprentice pressman/woman',
      'assistant pressman/woman',
      'first pressman/woman',
      'flexographic press operator',
      'offset press operator',
      'printing press operator',
      'rotogravure pressman/woman - printing',
    ],
  },
  {
    code: '7384',
    title: 'Other trades and related occupations, n.e.c.',
    examples: [
      'aircraft patternmaker',
      'blacksmith',
      'commercial diver',
      'die setter',
      'explosive ordnance detector',
      'farrier',
      'gunsmith',
      'locksmith',
      'reclamation diver',
      'recreation vehicle technician',
      'restoration steeplejack',
      'safe maker',
      'saw fitter',
      'small arms repairer',
      'toolsmith',
      'underwater contractor',
      'vault repairer',
    ],
  },
  {
    code: '7441',
    title: 'Residential and commercial installers and servicers',
    examples: [
      'aluminum window installer',
      'eavestrough installer',
      'electric appliance installer',
      'exterior cladder',
      'fence erector',
      'hot tub installer',
      'irrigation technician - installation and maintenance',
      'kitchen cupboard and vanity installer',
      'recreation structure erector',
      'siding installer',
      'sign installer',
      'swimming pool installer',
      'water conditioner servicer',
      'water heater servicer',
      'window film installer',
      'window installer',
    ],
  },
  {
    code: '7442',
    title: 'Waterworks and gas maintenance workers',
    examples: [
      'gas leak locator',
      'gas maintenance worker',
      'gas utility operator',
      'pipeline maintenance worker',
      'pipeline patrolman/woman',
      'utility plant maintenance worker',
      'waterworks maintenance worker',
    ],
  },
  {
    code: '7444',
    title: 'Pest controllers and fumigators',
    examples: [
      'animal control trapper',
      'pest control operator',
      'pest control service representative',
      'pest control technician',
      'pest controller',
      'pest exterminator',
      'pest fumigator',
    ],
  },
  {
    code: '7445',
    title: 'Other repairers and servicers',
    examples: [
      'bicycle repairer',
      'camera repairer',
      'meter repairer',
      'piano repairer',
      'scale repairer',
      'sewing machine servicer - domestic',
      'sporting goods repairer',
      'vending machine repairer',
    ],
  },
  {
    code: '7451',
    title: 'Longshore workers',
    examples: [
      'dockworker',
      'longshore worker',
      'longshoreman/woman',
      'ship loader operator',
      'stevedore',
      'tanker loader',
    ],
  },
  {
    code: '7452',
    title: 'Material handlers',
    examples: [
      'bin filler',
      'coal handler',
      'conveyor console operator',
      'forklift truck operator',
      'freight handler (except air transport)',
      'furniture mover',
      'lumber piler - building supplies',
      'material handler',
      'railway car loader',
      'stockpiler',
      'storage worker - material handling',
      'truck loader',
      'warehouseman/woman',
    ],
  },
  {
    code: '7511',
    title: 'Transport truck drivers',
    examples: [
      'bulk goods truck driver',
      'dump truck driver',
      'flatbed truck driver',
      'heavy truck driver',
      'logging truck driver',
      'long haul truck driver',
      'moving van driver',
      'shunt truck driver',
      'tow truck driver',
      'tractor-trailer truck driver',
      'transport driver',
      'truck driver',
    ],
  },
  {
    code: '7512',
    title: 'Bus drivers, subway operators and other transit operators',
    examples: [
      'bus driver',
      'bus operator',
      'light rail transit operator',
      'motor coach driver',
      'public passenger transit driver',
      'school bus driver',
      'sightseeing tour driver',
      'streetcar operator',
      'subway train operator',
      'transit operator - transportation',
    ],
  },
  {
    code: '7513',
    title: 'Taxi and limousine drivers and chauffeurs',
    examples: ['airport limousine driver', 'company chauffeur', 'limousine driver', 'private chauffeur', 'taxi driver'],
  },
  {
    code: '7514',
    title: 'Delivery and courier service drivers',
    examples: [
      'bread deliverer',
      'canteen driver',
      'courier service driver',
      'delivery driver',
      'driver salesperson',
      'dry cleaning driver',
      'mail service courier',
      'newspaper delivery driver',
      'pizza delivery driver',
      'vending machine driver-supplier',
    ],
  },
  {
    code: '7521',
    title: 'Heavy equipment operators (except crane)',
    examples: [
      'backhoe operator',
      'bulldozer operator',
      'excavator operator',
      'gradall operator',
      'grader operator',
      'heavy equipment operator',
      'heavy equipment operator apprentice',
      'loader operator - construction',
      'side boom tractor operator',
      'surface mining equipment operator',
    ],
  },
  {
    code: '7522',
    title: 'Public works maintenance equipment operators and related workers',
    examples: [
      'garbage truck driver',
      'municipal maintenance equipment operator',
      'power pole inspector',
      'public works maintenance equipment operator',
      'salt truck operator',
      'sand spreader operator',
      'sewer-flushing truck operator - public works',
      'snow removal equipment operator - public works',
      'street flusher operator',
      'street sweeper operator',
      'utility arborist',
      'utility locator',
    ],
  },
  {
    code: '7531',
    title: 'Railway yard and track maintenance workers',
    examples: [
      'ballast regulator operator - railway',
      'car controller - railway',
      'control tower operator - railway',
      'machine operator - railway',
      'rail saw operator',
      'railway equipment operator',
      'railway track patroller',
      'railway trackman/woman',
      'section worker - railway',
      'signal tower operator - railway',
      'spike machine operator - railway',
      'switch tender - railway yard',
      'tie tamper operator - railway',
      'towerman/woman - railway transport',
      'yard coupler - railway',
      'yard worker - railway',
    ],
  },
  {
    code: '7532',
    title: 'Water transport deck and engine room crew',
    examples: [
      'able seaman/woman (except armed forces)',
      'boatswain - water transport',
      'deckhand',
      'engine room crew member - ship',
      'helmsman/woman - water transport',
      'marine engine oiler',
      'marine engineering mechanic',
      'ship stoker',
      'ship tunnel operator',
    ],
  },
  {
    code: '7533',
    title: 'Boat and cable ferry operators and related occupations',
    examples: [
      'bridgemaster',
      'cable ferry operator',
      'canal lock operator',
      'charter boat operator',
      'ferry terminal worker',
      'launch master',
      'linesman/woman - canal lock system',
      'loading bridge operator',
      'lockmaster',
      'motorboat operator',
      'scow captain',
      'sightseeing boat operator',
      'small craft operator',
      'water taxi operator',
    ],
  },
  {
    code: '7534',
    title: 'Air transport ramp attendants',
    examples: [
      'aircraft groomer',
      'airport ramp attendant',
      'cargo attendant - air transport',
      'ramp agent - air transport',
      'ramp attendant - air transport',
      'station attendant - air transport',
    ],
  },
  {
    code: '7535',
    title: 'Other automotive mechanical installers and servicers',
    examples: [
      'crane greaser',
      'heavy equipment servicer',
      'muffler installer',
      'radiator installer - auto repair service',
      'shock absorber installer',
      'spring installer',
      'tire repairer',
    ],
  },
  {
    code: '7611',
    title: 'Construction trades helpers and labourers',
    examples: [
      'asphalt spreader',
      'bricklayer helper',
      'carpenter helper',
      'concrete mixer helper',
      'concrete paving labourer',
      'construction helper',
      'construction labourer',
      'demolition worker',
      'driller helper - surface mining',
      'drywall sander',
      'excavation labourer',
      'flagman/woman',
      'glazier helper',
      'pipeline mandrel operator',
      'plumber helper',
      'roofer helper',
      'stabber - pipeline construction',
    ],
  },
  {
    code: '7612',
    title: 'Other trades helpers and labourers',
    examples: [
      'aerial spraying assistant',
      'aircraft mechanic helper',
      'cable installer helper',
      'diesel mechanic helper',
      'ground worker - telecommunications',
      "mechanic's helper - automotive",
      'millwright helper',
      'refrigeration mechanic helper',
      'splicer helper - telecommunications',
      'surveyor helper',
    ],
  },
  {
    code: '7621',
    title: 'Public works and maintenance labourers',
    examples: [
      'helper - garbage collection',
      'municipal labourer',
      'parking meter collector',
      'public works labourer',
      'road maintenance worker',
      'sewer maintenance worker',
      'sidewalk cleaner',
    ],
  },
  {
    code: '7622',
    title: 'Railway and motor transport labourers',
    examples: [
      'car checker - railway',
      'delivery truck helper',
      'furniture mover helper',
      'mover helper',
      'railway labourer',
      'signal gang helper',
      'swamper - truck transport',
      'truck driver helper',
    ],
  },
  {
    code: '8211',
    title: 'Supervisors, logging and forestry',
    examples: [
      'forest operations supervisor',
      'forestry crew supervisor',
      'hook tender - logging',
      'logging contractor',
      'logging foreman/woman',
      'production supervisor - logging',
      'silviculture supervisor',
      'woods foreman/woman',
    ],
  },
  {
    code: '8221',
    title: 'Supervisors, mining and quarrying',
    examples: [
      'fill foreman/woman - underground mining',
      'mine captain',
      'mine foreman/woman',
      'mine supervisor',
      'quarry supervisor',
      'surface mine supervisor',
      'track boss - underground mining',
      'underground mine foreman/woman',
    ],
  },
  {
    code: '8222',
    title: 'Contractors and supervisors, oil and gas drilling and services',
    examples: [
      'fracturing supervisor',
      'multi-service operator - oil field services',
      'rig manager',
      'well services crew supervisor',
    ],
  },
  {
    code: '8231',
    title: 'Underground production and development miners',
    examples: [
      'blaster - underground mining',
      'chute blaster - underground mining',
      'diamond driller - underground mining',
      'drift miner',
      'driller - underground mining',
      'hardrock miner apprentice',
      'hoist operator - underground mining',
      'miner',
      'mining machine operator',
      'powderman/woman - underground mining',
      'raise miner',
      'roadheader operator',
      'scooptram operator',
      'shaft inspector',
    ],
  },
  {
    code: '8232',
    title: 'Oil and gas well drillers, servicers, testers and related workers',
    examples: [
      'assistant driller - oil and gas well drilling',
      'directional drilling operator',
      'downhole tool operator',
      'drill stem tester',
      'driller - oil and gas drilling',
      'electric line operator',
      'logging and perforating operator',
      'service rig operator',
      'slickline operator',
      'well testing operator',
      'wireline operator',
    ],
  },
  {
    code: '8241',
    title: 'Logging machinery operators',
    examples: [
      'chipping machine operator',
      'delimber operator',
      'feller buncher operator',
      'feller forwarder operator',
      'grapple operator - logging',
      'linehorse operator',
      'loader operator - logging',
      'log processor operator',
      'rigging slinger - logging',
      'shortwood harvester operator',
      'slasher operator - logging',
      'steel spar operator - logging',
      'treelength forwarder operator',
      'yarder operator - logging',
    ],
  },
  {
    code: '8252',
    title: 'Agricultural service contractors, farm supervisors and specialized livestock workers',
    examples: [
      'artificial insemination service contractor',
      'artificial inseminator',
      'cattle herdsperson',
      'crop dusting contractor',
      'crop harvesting service contractor',
      'dairy herdsperson',
      'farm foreman/woman',
      'farm supervisor',
      'feedlot foreman/woman',
      'hog operation supervisor',
      'horse trainer',
      'livestock breeding service contractor',
      'pork production technician',
      'poultry farm foreman/woman',
      'ranch foreman/woman',
      'swine herdsperson',
      'vegetable farm foreman/woman',
    ],
  },
  {
    code: '8255',
    title: 'Contractors and supervisors, landscaping, grounds maintenance and horticulture services',
    examples: [
      'greenhouse supervisor',
      'grounds maintenance contractor',
      'groundskeeping supervisor',
      'horticulture workers supervisor',
      'interior plantscaping contractor',
      'landscaping contractor',
      'landscaping foreman/woman',
      'landscaping supervisor',
      'lawn care contractor',
      'nursery foreman/woman',
      'park maintenance supervisor',
      'tree service contractor',
    ],
  },
  {
    code: '8261',
    title: 'Fishing masters and officers',
    examples: [
      'fishing vessel boatswain',
      'fishing vessel deck officer',
      'fishing vessel first mate',
      'fishing vessel master',
      'offshore fishing vessel captain',
      'trawler captain',
    ],
  },
  {
    code: '8262',
    title: 'Fishermen/women',
    examples: [
      'fisherman/woman',
      'fishing vessel skipper',
      'inshore fisherman/woman',
      'lobster fisherman/woman',
      'longliner fisherman/woman',
      'seiner fisherman/woman',
    ],
  },
  {
    code: '8411',
    title: 'Underground mine service and support workers',
    examples: [
      'backfiller - underground mining',
      'blaster helper - underground mining',
      'cage tender',
      'conveyor operator - underground mining',
      'crusher operator - underground mining',
      'driller helper - underground mining',
      'haulageman/woman - underground mining',
      'lamp keeper - underground mining',
      'materialman/woman - underground mining',
      'mine construction worker',
      'orepass tender - underground mining',
      'pipeworker - underground mining',
      'raise miner helper',
      'skip tender',
      'timberman/woman - underground mining',
      'trainman/woman - underground mining',
    ],
  },
  {
    code: '8412',
    title: 'Oil and gas well drilling and related workers and services operators',
    examples: [
      'acidizer - oil field services',
      'blender operator - oil field services',
      'control room operator - offshore drilling',
      'derrickman/woman',
      'motorhand - oil and gas drilling',
      'oil well cementer - oil field services',
      'pumper operator - oil field services',
      'pumpman/woman - oil field services',
      'rig technician - derrickhand',
    ],
  },
  {
    code: '8421',
    title: 'Chain saw and skidder operators',
    examples: [
      'bucker',
      'chain saw operator - logging',
      'faller',
      'feller',
      'forest worker - logging',
      'grapple skidder operator',
      'landingman/woman',
      'pieceworker - logging',
      'skidder operator',
    ],
  },
  {
    code: '8422',
    title: 'Silviculture and forestry workers',
    examples: [
      'clearing saw operator - forestry',
      'forest firefighter',
      'forestry crew worker',
      'pieceworker - silviculture',
      'scarification equipment operator - forestry',
      'silviculture worker',
      'thinning saw operator',
    ],
  },
  {
    code: '8431',
    title: 'General farm workers',
    examples: [
      'beef cattle farm worker',
      'cattle ranch labourer',
      'dairy farm worker',
      'farm machinery operator',
      'general farm worker',
      'grain farm worker',
      'harvester machine operator',
      'hatchery worker',
      'poultry farm worker',
      'vegetable farm worker',
    ],
  },
  {
    code: '8432',
    title: 'Nursery and greenhouse workers',
    examples: [
      'forest nursery worker',
      'greenhouse worker',
      'horticulture worker',
      'hothouse worker',
      'hydroponics worker',
      'nursery worker',
    ],
  },
  {
    code: '8441',
    title: 'Fishing vessel deckhands',
    examples: [
      'fishing vessel crewman/woman',
      'fishing vessel deckhand',
      'fishing vessel icer',
      'fishing vessel netmender',
      'seiner deckhand',
      'trawlerman/woman',
    ],
  },
  {
    code: '8442',
    title: 'Trappers and hunters',
    examples: ['fur trapper', 'game trapper', 'hunter', 'sealer', 'trapper'],
  },
  {
    code: '8611',
    title: 'Harvesting labourers',
    examples: [
      'apple picker',
      'berry picker',
      'crop farm labourer - harvesting',
      'fruit picker',
      'fruit sorter - farm',
      'harvest hand',
      'vegetable packer - farm',
    ],
  },
  {
    code: '8612',
    title: 'Landscaping and grounds maintenance labourers',
    examples: [
      'bulb planter - landscaping',
      'cemetery labourer',
      'gardening helper',
      'golf course worker',
      'grass cutter',
      'grounds maintenance worker',
      'landscape labourer',
      'park maintenance labourer',
      'park maintenance worker',
      'sod layer',
      'transplanter - landscape maintenance',
    ],
  },
  {
    code: '8613',
    title: 'Aquaculture and marine harvest labourers',
    examples: [
      'aquaculture support worker',
      'clam digger',
      'dulse gatherer',
      'fish farm helper',
      'fish tagger',
      'fry marker',
      'marine plant gatherer',
      'oyster picker',
      'sea farm attendant',
      'seaweed gatherer',
      'shellfish harvester',
    ],
  },
  {
    code: '8614',
    title: 'Mine labourers',
    examples: [
      'chute puller',
      'mine helper',
      'mine labourer',
      'miner helper - underground mining',
      'pit scaler - underground mining',
      'shoveller - underground mining',
      'underground labourer - mining',
    ],
  },
  {
    code: '8615',
    title: 'Oil and gas drilling, servicing and related labourers',
    examples: [
      'floorman/woman - oil and gas drilling',
      'leasehand',
      'oil field labourer',
      'roughneck',
      'roustabout',
      'service rig helper',
      'shakerhand',
      'swamper - oil and gas',
      'well treatment helper',
      'wireline helper',
    ],
  },
  {
    code: '8616',
    title: 'Logging and forestry labourers',
    examples: [
      'chokerman/woman',
      'forestry labourer',
      'logging labourer',
      'seasonal tree planter',
      'swamper - logging and forestry',
    ],
  },
  {
    code: '9211',
    title: 'Supervisors, mineral and metal processing',
    examples: [
      'blast furnace foreman/woman',
      'brick and tile foreman/woman',
      'cement processing supervisor',
      'coremaking foreman/woman - foundry',
      'die casting supervisor',
      'glass cutters supervisor',
      'lead refining foreman/woman',
      'lime preparation foreman/woman',
      'melting and roasting department supervisor',
      'ore milling supervisor',
      'roll shop operations coach - steel mill',
      'steel mill shift co-ordinator',
    ],
  },
  {
    code: '9212',
    title: 'Supervisors, petroleum, gas and chemical processing and utilities',
    examples: [
      'chemical processing supervisor',
      'cosmetics processing foreman/woman',
      'natural gas plant foreman/woman',
      'paint processing foreman/woman',
      'petroleum refining supervisor',
      'pharmaceuticals production supervisor',
      'pipeline operations supervisor',
      'power station supervisor',
      'sewage treatment plant supervisor',
      'specialty chemicals production supervisor',
      'water purification plant foreman/woman',
    ],
  },
  {
    code: '9213',
    title: 'Supervisors, food and beverage processing',
    examples: [
      'bottling supervisor - food and beverage processing',
      'brewer supervisor',
      'fish processing supervisor',
      'flour milling team supervisor',
      'food product testers supervisor',
      'hazard analysis critical control point (HACCP) co-ordinator',
      'meat packing supervisor',
      'packaging supervisor - food and beverage processing',
      'poultry graders foreman/woman',
      'production supervisor - food and beverage processing',
      'tobacco processing supervisor',
      'vegetable packing supervisor',
    ],
  },
  {
    code: '9214',
    title: 'Supervisors, plastic and rubber products manufacturing',
    examples: [
      'blow moulding foreman/woman - rubber and plastic manufacturing',
      'calendering foreman/woman - rubber and plastic manufacturing',
      'injection moulding foreman/woman - rubber and plastic manufacturing',
      'injection moulding supervisor - plastic products manufacturing',
      'plastic products manufacturing supervisor',
      'rubber products manufacturing supervisor',
      'tire building supervisor',
    ],
  },
  {
    code: '9215',
    title: 'Supervisors, forest products processing',
    examples: [
      'coating room foreman/woman - pulp and paper',
      'lumber grading foreman/woman - wood processing',
      'paper converting supervisor',
      'paper machine foreman/woman',
      'paper mill foreman/woman',
      'plywood making foreman/woman',
      'pulp mill foreman/woman',
      'sawmill foreman/woman',
      'shift operating supervisor - pulp and paper',
      'shingle mill foreman/woman',
      'tour foreman/woman - pulp and paper',
      'waferboard foreman/woman',
      'wood-treating plant foreman/woman',
    ],
  },
  {
    code: '9217',
    title: 'Supervisors, textile, fabric, fur and leather products processing and manufacturing',
    examples: [
      'boot and shoe foreman/woman',
      'canvas products manufacturing foreman/woman',
      'dye room supervisor',
      'embroidery supervisor - fabric products',
      'fabric-cutting department foreman/woman',
      'finishing supervisor - textiles',
      'foreman/woman - textile processing',
      'fur dressing foreman/woman',
      'hat and cap makers foreman/woman',
      'knitting supervisor',
      'sample room foreman/woman - leather products',
      'sewing machine operators supervisor',
      'stitching department supervisor',
      'tannery foreman/woman',
    ],
  },
  {
    code: '9221',
    title: 'Supervisors, motor vehicle assembling',
    examples: [
      'area co-ordinator - motor vehicle manufacturing',
      'assembly foreman/woman - motor vehicle manufacturing',
      'assembly supervisor - motor vehicle manufacturing',
      'assembly zone supervisor - motor vehicle manufacturing',
      'foreman/woman - motor vehicle manufacturing',
      'general assembly supervisor - motor vehicle manufacturing',
    ],
  },
  {
    code: '9222',
    title: 'Supervisors, electronics manufacturing',
    examples: [
      'assembly and testing supervisor - electronic equipment manufacturing',
      'communications equipment assembly supervisor - electronic equipment manufacturing',
      'electronic assembly foreman/woman',
      'final assembly and testing foreman/woman - electronic equipment manufacturing',
      'printed circuit board (PCB) fabrication foreman/woman - electronic equipment manufacturing',
      'production supervisor - electronics manufacturing',
      'systems test foreman/woman - electronic equipment manufacturing',
    ],
  },
  {
    code: '9223',
    title: 'Supervisors, electrical products manufacturing',
    examples: [
      'electrical appliance assembly foreman/woman',
      'electrical equipment manufacturing supervisor',
      'electrical motor assembly foreman/woman',
      'electrical transformer assembly foreman/woman',
      'switchgear assembly foreman/woman',
    ],
  },
  {
    code: '9224',
    title: 'Supervisors, furniture and fixtures manufacturing',
    examples: [
      'desk assembly foreman/woman',
      'furniture and fixtures manufacturing supervisor',
      'furniture assembly foreman/woman',
      'furniture finishers supervisor',
      'laminating foreman/woman',
      'woodworking machine operator foreman/woman - furniture and fixtures manufacturing',
      'woodworking machine operators supervisor - furniture and fixtures manufacturing',
    ],
  },
  {
    code: '9226',
    title: 'Supervisors, other mechanical and metal products manufacturing',
    examples: [
      'aircraft assembly foreman/woman',
      'engine assembly foreman/woman (except aircraft)',
      'helicopter assembly foreman/woman',
      'metalworking machine operators foreman/woman',
      'mobile home assembly foreman/woman',
      'shipyard painter foreman/woman',
      'snowmobile assembly supervisor',
      'truck trailer assembly foreman/woman',
    ],
  },
  {
    code: '9227',
    title: 'Supervisors, other products manufacturing and assembly',
    examples: [
      'bicycle assembly foreman/woman',
      'clock and watch assembly foreman/woman',
      'jewellery manufacturing foreman/woman',
      'millwork assembly foreman/woman',
      'millwork assembly supervisor',
      'silverware manufacturing supervisor',
      'sports equipment assembly supervisor',
      'toy manufacturing supervisor',
    ],
  },
  {
    code: '9231',
    title: 'Central control and process operators, mineral and metal processing',
    examples: [
      'blast furnace operator',
      'central control caster',
      'central control room operator - primary metal processing',
      'chief flotation operator',
      'console operator - cement manufacturing',
      'pelletizing control operator',
      'roaster operator - primary metal processing',
      'rolling mill control operator',
    ],
  },
  {
    code: '9232',
    title: 'Central control and process operators, petroleum, gas and chemical processing',
    examples: [
      'acid plant operator',
      'chemical process operator',
      'chemical processing chief technician',
      'chlor-alkali plant cell room operator',
      'gas field production operator',
      'gas plant operator',
      'gas recovery operator',
      'master operator - chemical processing',
      'oil refinery process operator',
      'panel operator - chemical processing',
      'petroleum process operator',
      'pharmaceutical processing operator',
      'pipeline compressor station operator',
      'process technician - chemical processing',
      'refinery process technician',
    ],
  },
  {
    code: '9235',
    title: 'Pulping, papermaking and coating control operators',
    examples: [
      'bleach plant operator - pulp and paper',
      'panelboard operator - pulp and paper',
      'paper machine control operator',
      'pulping control operator',
      'pulping group operator - pulp and paper',
      'pulping technician',
    ],
  },
  {
    code: '9241',
    title: 'Power engineers and power systems operators',
    examples: [
      'apprentice power dispatcher',
      'auxiliary plant operator',
      'building systems technician',
      'control room operator - electrical power systems',
      'distribution control operator - electrical power systems',
      'electrical power systems operator',
      'energy from waste plant operator',
      'nuclear generating station field operator',
      'nuclear reactor operator',
      'power dispatcher - generating station',
      'power engineer',
      'power plant operator',
      'power plant stationary engineer',
      'stationary engineer',
      'system controller - electrical power systems',
    ],
  },
  {
    code: '9243',
    title: 'Water and waste treatment plant operators',
    examples: [
      'environmental systems operator - water treatment',
      'liquid waste process operator',
      'sewage plant operator',
      'waste treatment plant operator',
      'wastewater treatment plant operator',
      'water filtration plant operator',
      'water purification plant operator',
      'water treatment plant operator',
    ],
  },
  {
    code: '9411',
    title: 'Machine operators, mineral and metal processing',
    examples: [
      'aluminum classifier',
      'asbestos dryer',
      'billet heater',
      'brick and tile crusher operator',
      'cement miller',
      'extrusion press operator',
      'foil-winding machine operator',
      'ladle pourer',
      'lead refiner',
      'lime kiln operator',
      'mica sheet laminator',
      'quenching car operator',
      'scrap metal shredder',
      'sintering machine operator',
      'slurry equipment operator',
      'steel roller',
      'uranium classifier operator',
      'zinc cell operator',
    ],
  },
  {
    code: '9412',
    title: 'Foundry workers',
    examples: [
      'bench moulder',
      'casting machine operator',
      'ceramic mouldmaker',
      'die-casting machine operator',
      'foundry worker',
      'furnace operator - foundry',
      'machine coremaker',
      'manual moulder',
      'melter - foundry',
      'metal caster',
      'pit moulder',
      'sand coremaker',
      'sand moulder',
    ],
  },
  {
    code: '9413',
    title: 'Glass forming and finishing machine operators and glass cutters',
    examples: [
      'bottle machine operator - glass products manufacturing',
      'float operator - glass forming',
      'glass beveller',
      'glass edger',
      'glass moulder',
      'glass-blowing machine operator',
      'glass-finishing machine operator',
      'glass-pressing machine operator',
      'hand glass cutter',
      'mirror maker',
    ],
  },
  {
    code: '9414',
    title: 'Concrete, clay and stone forming operators',
    examples: [
      'asbestos shingle presser',
      'brick presser operator',
      'clay press operator',
      'clay products moulder',
      'concrete block maker',
      'finisher - concrete, clay and stone products',
      'granite cutter',
      'marble cutter',
      'precast concrete moulder',
      'precast concrete slab maker',
      'stone driller',
      'stone planer',
      'stonework moulder',
    ],
  },
  {
    code: '9415',
    title: 'Inspectors and testers, mineral and metal processing',
    examples: [
      'asbestos grader',
      'brick and tile inspector',
      'castings tester - primary metal and mineral products processing',
      'clay products grader',
      'core tester - foundry',
      'fibreglass tester',
      'glass quality control inspector',
      'heat treating inspector - metal processing',
      'metal processing inspector',
      'mineral sampler - mineral products processing',
      'molten metal sampler - primary metal processing',
      'salvage glass inspector',
      'sheet steel inspector - primary metal processing',
      'steel tester - primary metal processing',
    ],
  },
  {
    code: '9416',
    title: 'Metalworking and forging machine operators',
    examples: [
      'bending press operator - metal fabrication',
      'cold press operator - metal forging',
      'disk flange operator - metal fabrication',
      'drop hammer operator - metal forging',
      'electric arc cutter - metal products manufacturing',
      'forging press operator',
      'metal-forming machine set-up operator',
      'metalworking machine operator',
      'power brake operator - metal fabrication',
      'power press operator - metal fabrication',
      'punch press setter - metalworking',
      'roll operator - metal fabrication',
      'saw operator - metal fabrication',
      'shear setter - metal fabrication',
      'sheet metal roll operator - metal fabrication',
    ],
  },
  {
    code: '9417',
    title: 'Machining tool operators',
    examples: [
      'aircraft parts etcher',
      'boring mill operator - metal machining',
      'computer numerical control (CNC) machining tool operator',
      'lathe machining operator',
      'machining tool operator',
      'milling machine set-up operator',
      'production gear cutter',
      'production grinder operator - metal machining',
      'radial drill operator - metal machining',
    ],
  },
  {
    code: '9418',
    title: 'Other metal products machine operators',
    examples: [
      'bolt machine operator',
      'bullet maker',
      'can-forming machine operator',
      'chain-making machine operator',
      'metal cable maker operator',
      'nail making machine tender',
      'spring machine operator',
      'wire screen maker',
      'wire weaver',
    ],
  },
  {
    code: '9421',
    title: 'Chemical plant machine operators',
    examples: [
      'batch mixer - chemical processing',
      'blender - chemical processing',
      'capsule machine operator',
      'evaporator operator - chemical processing',
      'formulations blender operator',
      'glue blender',
      'granulator machine operator',
      'mixer - chemical processing',
      'screener - chemical processing',
      'soap maker',
    ],
  },
  {
    code: '9422',
    title: 'Plastics processing machine operators',
    examples: [
      'bag machine operator',
      'banbury operator - plastic manufacturing',
      'blow moulding machine operator - plastic manufacturing',
      'calender operator - plastic manufacturing',
      'extruder operator - plastic manufacturing',
      'injection moulding operator - plastic manufacturing',
      'plastics press operator',
      'printing roller moulder - plastic manufacturing',
      'solution mixer operator - plastic manufacturing',
      'thermoforming operator',
    ],
  },
  {
    code: '9423',
    title: 'Rubber processing machine operators and related workers',
    examples: [
      'banbury operator - rubber products manufacturing',
      'blow-out and test operator - rubber products',
      'calender machine operator - rubber products manufacturing',
      'cure operator - rubber products manufacturing',
      'extruder operator - rubber products manufacturing',
      'moulding press tender - rubber products manufacturing',
      'refiner mill tender - rubber products manufacturing',
      'rubber belt builder',
      'rubber hose builder',
      'rubber processing machine operator',
      'rubber products assembler',
      'rubber products manufacturing inspector',
      'scrap rubber grinder - recycling',
      'tire builder',
      'tire inspector',
    ],
  },
  {
    code: '9431',
    title: 'Sawmill machine operators',
    examples: [
      'circular saw operator',
      'edgerman/woman',
      'head sawyer',
      'log cut-off operator',
      'planer operator',
      'resaw operator',
      'shake splitter',
      'trimmerman/woman',
    ],
  },
  {
    code: '9432',
    title: 'Pulp mill machine operators',
    examples: [
      'assistant bleacher operator - pulp and paper',
      'assistant digester operator',
      'cook - pulp and paper',
      "cook's first helper - pulp and paper",
      'digester operator - pulp and paper',
      'field operator - pulp and paper',
      'grinderman/woman - pulp and paper',
      'refiner operator - pulp and paper',
      'repulper operator',
      'screenman/woman - pulp and paper',
      'thermomechanical pulp assistant operator',
      'thermomechanical pulp operator',
    ],
  },
  {
    code: '9433',
    title: 'Papermaking and finishing machine operators',
    examples: [
      'assistant coating operator - pulp and paper',
      'balerman/woman - pulp and paper',
      'calender operator - pulp and paper',
      'embossing calender operator - pulp and paper',
      'fourth hand - pulp and paper',
      'graderman/woman - pulp and paper',
      'paper machine operator',
      'paper-coating machine operator',
      'sheeter operator - pulp and paper',
      'winderman/woman - pulp and paper',
    ],
  },
  {
    code: '9434',
    title: 'Other wood processing machine operators',
    examples: [
      'barker operator',
      'chipper operator',
      'lumber kiln operator',
      'particleboard line operator',
      'plywood panel assembler',
      'timber treating tank operator',
      'veneer dryer tender',
      'veneer lathe operator',
      'waferboard press operator',
      'wood treater',
    ],
  },
  {
    code: '9435',
    title: 'Paper converting machine operators',
    examples: [
      'box maker operator',
      'carton forming machine operator',
      'core maker - paper converting',
      'envelope maker operator',
      'paper bag machine operator',
      'paper box machine operator',
    ],
  },
  {
    code: '9436',
    title: 'Lumber graders and other wood processing inspectors and graders',
    examples: [
      'grader - wood processing',
      'grader tallyman/woman - wood processing',
      'lumber grader',
      'particleboard grader - wood processing',
      'plywood grader',
      'plywood inspector',
      'veneer grader',
      'wood measurer',
    ],
  },
  {
    code: '9437',
    title: 'Woodworking machine operators',
    examples: [
      'boring machine operator - woodworking',
      'computer numerically controlled (CNC) band saw operator',
      'drill operator - woodworking',
      'drum sander - woodworking',
      'edge bander operator - woodworking',
      'glue machine operator - woodworking',
      'lathe operator - woodworking',
      'planer operator - woodworking',
      'rough mill operator - woodworking',
      'shaper - woodworking',
      'woodworking machine operator',
    ],
  },
  {
    code: '9441',
    title: 'Textile fibre and yarn, hide and pelt processing machine operators and workers',
    examples: [
      'autoclave tender - textile manufacturing',
      'bleaching range operator - textile manufacturing',
      'calender operator - textile manufacturing',
      'carding machine operator - textile manufacturing',
      'coater operator - textile manufacturing',
      'dyeing range operator - textile manufacturing',
      'fabric-printing machine operator',
      'finishing range operator - textile manufacturing',
      'leather buffer - hide and pelt processing',
      'leather stretcher - hide and pelt processing',
      'spinning operator - textile manufacturing',
      'tanner - hide and pelt processing',
      'twisting operator - textile manufacturing',
      'yarn doubler - textile manufacturing',
    ],
  },
  {
    code: '9442',
    title: 'Weavers, knitters and other fabric making occupations',
    examples: [
      'carpet weaver',
      'drawer-in - textiles',
      'drawing-in machine operator',
      'embroidery machine operator',
      'hosiery knitter',
      'knitter - textiles',
      'knitting machine operator',
      'loom operator',
      'mattress sewing machine operator',
      'pinning machine operator',
      'quilting machine operator',
      'tufting operator',
      'warp knitting machine tender',
      'warp tier-in',
      'weaver - textiles',
    ],
  },
  {
    code: '9445',
    title: 'Fabric, fur and leather cutters',
    examples: [
      'clothing cutter',
      'fabric cutter',
      'fur cutter',
      'glove cutter',
      'leather cutter',
      'sample cutter',
      'shoe cutter',
    ],
  },
  {
    code: '9446',
    title: 'Industrial sewing machine operators',
    examples: [
      'fur sewing machine operator',
      'leather products sewing machine operator',
      'lining stitcher',
      'sample sewer',
      'serging machine operator',
      'sewing machine operator',
      'shoe sewer',
    ],
  },
  {
    code: '9447',
    title: 'Inspectors and graders, textile, fabric, fur and leather products manufacturing',
    examples: [
      'cloth inspector',
      'colour grader - textiles',
      'fabric examiner',
      'fibre inspector - textiles',
      'fur grader',
      'fur matcher - fur products manufacturing',
      'garment inspector',
      'hide and pelt processing inspector',
      'leather goods inspector',
      'leather grader',
      'quality control inspector - textiles',
      'shoe inspector',
      'textile grader',
      'textile product sampler',
      'yarn inspector',
    ],
  },
  {
    code: '9461',
    title: 'Process control and machine operators, food and beverage processing',
    examples: [
      'bakery machine operator - food and beverage processing',
      'brewhouse operator',
      'canning machine operator',
      'chocolate refiner',
      'cigarette machine tender',
      'citrus juice extractor',
      'control room operator - food and beverage processing',
      'corn sugar refinery operator',
      'dry foods mixer operator',
      'dryer tender - tobacco processing',
      'fermenter operator',
      'fruit preserver - food and beverage processing',
      'fryer operator - food and beverage processing',
      'grain-processing machine operator',
      'meat grinder - food and beverage processing',
      'pasteurizer operator - food and beverage processing',
      'tobacco blender',
      'winery-press operator',
    ],
  },
  {
    code: '9462',
    title: 'Industrial butchers and meat cutters, poultry preparers and related workers',
    examples: [
      'beef boner',
      'ham cutter',
      'industrial butcher',
      'industrial meat cutter',
      'meat trimmer',
      'poultry preparer',
      'slaughterer',
    ],
  },
  {
    code: '9463',
    title: 'Fish and seafood plant workers',
    examples: [
      'fish cake maker',
      'fish canning machine operator',
      'fish cleaner and cutter',
      'fish cutting machine operator - fish processing',
      'fish plant worker',
      'seafood preparer - fish and seafood processing',
      'shellfish processor',
      'shellfish shucker',
      'trimmer - fish processing',
    ],
  },
  {
    code: '9465',
    title: 'Testers and graders, food and beverage processing',
    examples: [
      'beef grader',
      'beer tester',
      'cheese grader',
      'fish grader',
      'milk grader',
      'poultry grader',
      'product tester - food and beverage processing',
      'tobacco grader',
    ],
  },
  {
    code: '9471',
    title: 'Plateless printing equipment operators',
    examples: [
      'colour copier operator',
      'electronic sign maker operator',
      'embossing-imprinting machine operator',
      'laser printer operator',
      'printing machine operator - printing',
      'quick print machine operator',
      'silk-screen printing machine operator',
      'wallpaper printer',
    ],
  },
  {
    code: '9472',
    title: 'Camera, platemaking and other prepress occupations',
    examples: [
      'camera operator - graphic arts',
      'cylinder preparer - printing',
      'dot etcher - printing',
      'file preparation operator',
      'film stripper-assembler',
      'platemaker - printing',
      'pre-flight operator - printing',
      'prepress technician',
      'printing plate engraver',
      'proofmaker',
      'screenmaker',
      'studio-image-processing system operator',
    ],
  },
  {
    code: '9473',
    title: 'Binding and finishing machine operators',
    examples: [
      'binder - printing',
      'binder and finisher',
      'bindery operator',
      'bookbinding machine operator',
      'Cerlox binder operator',
      'cutter operator - printing',
      'finishing machine operator - printing',
      'foil stamper - printing',
      'gathering machine set-up operator - printing',
      'gold leaf stamper',
      'laminating machine operator',
      'machine bookbinder',
      'perforator operator - printing',
      'stitcher operator - printing',
    ],
  },
  {
    code: '9474',
    title: 'Photographic and film processors',
    examples: [
      'darkroom technician',
      'film developer',
      'film printing machine operator',
      'film processor',
      'photofinisher',
      'photograph developer',
      'photograph inspector - photographic processing',
      'photographic processor',
    ],
  },
  {
    code: '9521',
    title: 'Aircraft assemblers and aircraft assembly inspectors',
    examples: [
      'aircraft assembler',
      'aircraft assembly inspector',
      'airframe assembler',
      'bench and structural assembler',
      'bench fitter mechanic - aircraft assembly',
      'rigger - aircraft assembly',
      'wing tank mechanic - aircraft assembly',
    ],
  },
  {
    code: '9522',
    title: 'Motor vehicle assemblers, inspectors and testers',
    examples: [
      'assembly inspector - motor vehicle manufacturing',
      'auto assembly worker',
      'body assembler - motor vehicle manufacturing',
      'car assembler',
      'chassis inspector - motor vehicle manufacturing',
      'door fitter',
      'motor vehicle assembler',
      'sport utility vehicle (SUV) assembler',
      'test driver - automobile assembly',
      'transmission installer',
      'van assembler',
    ],
  },
  {
    code: '9523',
    title: 'Electronics assemblers, fabricators, inspectors and testers',
    examples: [
      'capacitor assembler',
      'circuit board assembler',
      'component inserting machine operator',
      'crystal final tester',
      'electronic components tester',
      'electronics assembler',
      'electronics inspector - electronic equipment manufacturing',
      'finished product inspector - electronic equipment manufacturing',
      'precision instrument assembler - electronic equipment manufacturing',
      'printed circuit board (PCB) assembly inspector',
      'surface mount assembler',
      'through-hole assembler',
      'wafer fabrication operator',
      'wave soldering machine operator',
      'wiring and assembly operator',
    ],
  },
  {
    code: '9524',
    title: 'Assemblers and inspectors, electrical appliance, apparatus and equipment manufacturing',
    examples: [
      'circuit breaker assembler',
      'coffee maker assembler',
      'dishwasher assembler',
      'electric clothes dryer assembler',
      'electric lawn mower assembler',
      'electric refrigerator assembler',
      'electrical appliance assembler',
      'electrical appliance assembly inspector',
      'electrical control assembly inspector',
      'electrical equipment production assembler',
      'hair dryer assembler',
    ],
  },
  {
    code: '9525',
    title: 'Assemblers, fabricators and inspectors, industrial electrical motors and transformers',
    examples: [
      'alternating current (AC) and direct current (DC) motors fitter-assembler',
      'alternating current (AC) and direct current (DC) motors inspector and tester',
      'control panel assembler',
      'electrical control panel inspector',
      'electrical fitter',
      'electrical switchgear panel wirer',
      'motor and generator assembler and wirer',
      'panelboard assembler - industrial electrical equipment',
      'power transformer assembler',
      'switchgear and control panel assembler - industrial electrical equipment',
      'switchgear fitter-wirer',
      'transformer coil winder',
      'transformer inspector',
      'transformer winder',
    ],
  },
  {
    code: '9526',
    title: 'Mechanical assemblers and inspectors',
    examples: [
      'automotive engine assembler',
      'garden machinery assembler',
      'gearbox assembler',
      'gearcase assembler',
      'hydraulic hoist assembler',
      'mechanical assembler',
      'sewing machine assembler',
      'snowmobile assembler',
      'tractor assembler',
      'transmission assembler',
      'truck assembler',
      'truck assembly inspector',
      'truck trailer assembler',
      'vending machine assembler',
    ],
  },
  {
    code: '9527',
    title: 'Machine operators and inspectors, electrical apparatus manufacturing',
    examples: [
      'assembly machine setter - electrical equipment manufacturing',
      'dry cell charge machine operator',
      'electrical dry battery inspector',
      'electrical light bulb inspector',
      'epoxy coating machine operator',
      'machine operator - electrical equipment manufacturing',
      'machine set-up operator - electrical appliance manufacturing',
      'production line battery repairer - electrical equipment manufacturing',
    ],
  },
  {
    code: '9531',
    title: 'Boat assemblers and inspectors',
    examples: [
      'aluminum boat assembler',
      'boat assembler',
      'boat inspector',
      'canoe assembler',
      'fibreglass boat assembler',
      'motorboat assembler',
      'sailboat assembler',
    ],
  },
  {
    code: '9532',
    title: 'Furniture and fixture assemblers and inspectors',
    examples: [
      'assembly inspector - furniture manufacturing',
      'chair assembler',
      'desk assembler',
      'fixture assembler',
      'furniture assembler',
      'furniture inspector',
      'metal furniture assembler',
      'table assembler',
      'wood furniture assembler',
    ],
  },
  {
    code: '9533',
    title: 'Other wood products assemblers and inspectors',
    examples: [
      'manufactured housing production worker',
      'millwork assembler',
      'prefabricated housing assembler',
      'sash and door inspector',
      'wood products assembler',
      'wood products assembling inspector',
      'wood products bench assembler',
      'wooden box assembler',
      'wooden crate builder',
      'wooden door maker',
      'wooden pallet maker',
    ],
  },
  {
    code: '9534',
    title: 'Furniture finishers and refinishers',
    examples: [
      'finishing machine operator - furniture manufacturing',
      'furniture finisher',
      'furniture polisher - furniture finishing',
      'furniture refinisher',
      'furniture stainer',
      'furniture stripper',
      'touch-up person - furniture finishing',
      'trimmer - furniture finishing',
      'wash-off operator - furniture finishing',
      'wood finisher - furniture finishing and refinishing',
    ],
  },
  {
    code: '9535',
    title: 'Plastic products assemblers, finishers and inspectors',
    examples: [
      'fibreglass laminator',
      'plastic bottle trimmer',
      'plastic parts assembler',
      'plastic products fabricator',
      'plastic products inspector and tester',
      'plastic tank assembler',
      'plastics assembler',
      'plastics inspector',
      'plastics trimmer',
      'skylight assembler - plastic products manufacturing',
    ],
  },
  {
    code: '9536',
    title: 'Industrial painters, coaters and metal finishing process operators',
    examples: [
      'assembly line painter',
      'automatic paint-sprayer operator',
      'automobile painter - motor vehicle manufacturing',
      'aviation painter',
      'ceramic and enamel refinisher',
      'coating machine operator - industrial painting and coating',
      'electrogalvanizing machine operator',
      'enameller - ship and boat building',
      'hot-dip galvanizer - metal plating',
      'industrial products painter',
      'metal coater operator',
      'metal electroplater',
      'production painter-finisher',
      'spray paint operator',
    ],
  },
  {
    code: '9537',
    title: 'Other products assemblers, finishers and inspectors',
    examples: [
      'antenna assembler',
      'bicycle assembler',
      'brush making machine operator',
      'button machine operator',
      'camera assembly inspector',
      'crayon making machine tender',
      'eyeglass frame assembler',
      'golf club assembler',
      'jewellery annealer',
      'jewellery inspector',
      'lampshade assembler',
      'piano assembly inspector',
      'prism maker operator',
      'skate press operator',
      'sports ball moulder',
      'stuffing machine tender',
      'tennis ball maker operator',
      'toy inspector',
      'Venetian blind assembler',
      'watch assembler',
    ],
  },
  {
    code: '9611',
    title: 'Labourers in mineral and metal processing',
    examples: [
      'asbestos worker - mineral processing',
      'brick and tile kiln cleaner',
      'core shop labourer - foundry',
      'extrusion helper - primary metal processing',
      'fibreglass oven charger helper',
      'furnace loader - primary metal and mineral products processing',
      'glass packer',
      'lime kiln operator helper - mineral products processing',
      'metal pourer helper',
      'rolling mill worker - primary metal processing',
      'salt screening labourer',
      'tailings disposal labourer - primary metal processing',
    ],
  },
  {
    code: '9612',
    title: 'Labourers in metal fabrication',
    examples: [
      'blacksmith helper',
      'buffing machine tender - metal products manufacturing',
      'grinder-deburrer',
      'labourer - metal fabrication',
      'metal cleaner - metal products manufacturing',
      'metalworking machine helper',
      'plater helper - metal fabrication',
      'shipfitter helper',
      'shotblaster - metal fabrication',
      'wheelabrator operator - metal products manufacturing',
    ],
  },
  {
    code: '9613',
    title: 'Labourers in chemical products processing and utilities',
    examples: [
      'coating machine feeder - chemical processing',
      'filter cleaner - chemical processing',
      'helper - chemical processing',
      'labourer - gas utility',
      'loader - chemical processing',
      'retort unloader',
      'still cleaner - chemical processing',
      'water intake tender',
      'waterworks labourer',
    ],
  },
  {
    code: '9614',
    title: 'Labourers in wood, pulp and paper processing',
    examples: [
      'beater operator helper - pulp and paper',
      'chip bin attendant - wood processing',
      'conveyor cleaner - pulp and paper',
      'green wood chainman/woman - wood processing',
      'grinder feeder - pulp and paper',
      'labourer - pulp and paper',
      'labourer - wood processing',
      'lumber straightener - wood processing',
      'plywood dryer feeder',
      'sheeter helper - pulp and paper',
      'utility man/woman - pulp and paper',
    ],
  },
  {
    code: '9615',
    title: 'Labourers in rubber and plastic products manufacturing',
    examples: [
      'belt builder helper',
      'labourer - plastic products manufacturing',
      'labourer - rubber products manufacturing',
      'machine offbearer - rubber products manufacturing',
      'mould cleaner - rubber products manufacturing',
      'moulded products stripper - rubber and plastic products manufacturing',
      'roll changer - rubber manufacturing',
      'spreader operator helper - plastic products manufacturing',
    ],
  },
  {
    code: '9616',
    title: 'Labourers in textile processing',
    examples: [
      'doffer',
      'dyeing and finishing machine loader',
      'helper - textiles',
      'textile machine cleaner',
      'waste machine feeder',
      'yarn handler',
    ],
  },
  {
    code: '9617',
    title: 'Labourers in food and beverage processing',
    examples: [
      'bottle-washing machine tender - food and beverage processing',
      'brewery labourer',
      'candy packer',
      'dairy helper - food and beverage processing',
      'food processing labourer',
      'frozen food packer',
      'meat packager',
      'nut sorter - food and beverage processing',
      'production helper - food and beverage processing',
      'tobacco processing labourer',
    ],
  },
  {
    code: '9618',
    title: 'Labourers in fish and seafood processing',
    examples: [
      'cannery labourer',
      'fish briner - fish processing',
      'fish plant labourer',
      'fish salter - fish processing',
      'fish weigher',
      'shellfish labourer',
      'shellfish packer - fish processing',
    ],
  },
  {
    code: '9619',
    title: 'Other labourers in processing, manufacturing and utilities',
    examples: [
      'bindery helper',
      'box packer',
      'carton marker',
      'chair sander',
      'clothing plant labourer',
      'electrical products labourer',
      'film cutter - film processing',
      'furniture packer',
      'garment folder',
      'lens blocker',
      'machine packager',
      'seed packager',
      'shoe manufacturing labourer',
      'sorter - recyclable materials',
      'upholsterer helper',
    ],
  },
  {
    code: '0011',
    title: 'Legislators',
    examples: [
      'cabinet minister',
      'city councillor',
      'First Nations band chief',
      'governor general',
      'lieutenant-governor',
      'mayor',
      'Member of Legislative Assembly (MLA)',
      'Member of Parliament (MP)',
      'Member of the National Assembly (MNA)',
      'Member of the Provincial Parliament (MPP)',
      'premier',
      'prime minister',
      'school board trustee',
      'senator',
    ],
  },
  {
    code: '0012',
    title: 'Senior government managers and officials',
    examples: [
      'assistant deputy minister',
      'chief administrative officer - regional municipality',
      'chief statistician - government services',
      'city administrator',
      'deputy minister',
      'director general - government services',
      'executive director - government services',
      'high commissioner',
      'Human Rights Commission chairperson',
    ],
  },
  {
    code: '0013',
    title: 'Senior managers - financial, communications and other business services',
    examples: [
      'advertising agency president',
      'bank president',
      'chief executive officer (CEO) - telephone company',
      'chief financial officer (CFO) - advertising agency',
      'chief operating officer - engineering firm',
      'computing services company president',
      'credit union executive director',
      'executive vice-president - real estate agency',
      'general manager - real estate management company',
      'human resources vice-president - bank',
      'marketing vice-president - clerical staff services',
      'president and chief executive officer - financial, communications and other business services',
      'trust company regional vice-president',
    ],
  },
  {
    code: '0014',
    title: 'Senior managers - health, education, social and community services and membership organizations',
    examples: [
      'arts and culture association chairperson',
      'automobile association executive director',
      'business association president',
      'educational institution chief financial officer (CFO)',
      'health services institution executive director',
      'labour organization president',
      'membership organization general manager',
      'music guild president',
      'professional association executive director',
      'scientific association chairperson',
      'social services institution corporate controller',
      'voluntary organization executive director',
    ],
  },
  {
    code: '0015',
    title: 'Senior managers - trade, broadcasting and other services, n.e.c.',
    examples: [
      'cleaning service general manager',
      'department store president',
      'engineering vice-president - television broadcasting services',
      'finance vice-president - food wholesaling',
      'hotel chain regional vice-president',
      'marketing vice-president - grocery store chain',
      'operations vice-president - radio broadcasting services',
      'professional sports club chief financial officer (CFO)',
      'restaurant chain corporate controller',
      'travel agency chief executive officer (CEO)',
    ],
  },
  {
    code: '0016',
    title: 'Senior managers - construction, transportation, production and utilities',
    examples: [
      'chief executive officer (CEO) - manufacturing company',
      'chief financial officer (CFO) - urban transit system',
      'executive vice-president - railway',
      'finance vice-president - mining company',
      'logging company corporate controller',
      'marketing vice-president - airline',
      'operations vice-president - electric power company',
      'petroleum production company regional vice-president',
      'publishing house general manager',
      'residential construction company president',
      'trucking company general manager',
    ],
  },
  {
    code: '0111',
    title: 'Financial managers',
    examples: [
      'controller - financial services',
      'director - financial services',
      'director of accounting',
      'finance director',
      'financial administrator',
      'financial control manager',
      'financial planning and analysis manager',
      'internal audit services manager',
      'treasurer',
    ],
  },
  {
    code: '0112',
    title: 'Human resources managers',
    examples: [
      'employer-employee relations manager',
      'human resources administrator',
      'human resources manager',
      'industrial relations manager',
      'occupational health and safety manager',
      'pay and benefits manager',
      'personnel services manager',
      'personnel training and development manager',
      'recruiting manager',
      'staff relations manager',
    ],
  },
  {
    code: '0113',
    title: 'Purchasing managers',
    examples: [
      'contract manager',
      'food purchasing manager',
      'material manager',
      'procurement director',
      'purchasing contracts manager',
      'purchasing director',
      'supply chain logistics manager',
    ],
  },
  {
    code: '0114',
    title: 'Other administrative services managers',
    examples: [
      'administrative services chief',
      'administrative services manager',
      'business manager - non-profit organization',
      'inventory control manager',
      'records administrator',
      'regional administrative manager',
      'security manager',
      'support services manager',
    ],
  },
  {
    code: '0121',
    title: 'Insurance, real estate and financial brokerage managers',
    examples: [
      'bond sales manager',
      'brokerage manager - investments',
      'commodities trading manager',
      'financial brokerage manager',
      'insurance claims service manager',
      'insurance manager',
      'investment manager - financial brokerage',
      'mortgage broker manager',
      'real estate broker',
      'real estate service manager',
      'securities sales director',
      'trading floor manager',
    ],
  },
  {
    code: '0122',
    title: 'Banking, credit and other investment managers',
    examples: [
      'assistant operations manager - banking, credit and investment',
      'bank manager',
      'banking operations manager',
      'collection centre manager',
      'commercial banking manager',
      'corporate banking centre manager',
      'corporate services manager - banking, credit and investment',
      'credit card centre manager',
      'credit card company operations manager',
      'credit manager',
      'credit union manager',
      'mortgage and consumer credit manager',
      'personal services manager - banking, credit and investment',
      'regional collection manager',
      'trust company manager',
    ],
  },
  {
    code: '0124',
    title: 'Advertising, marketing and public relations managers',
    examples: [
      'advertising director',
      'advertising manager',
      'communications director',
      'electronic business (e-business) manager',
      'fundraising campaign manager',
      'Internet communications manager',
      'marketing manager',
      'media relations director',
      'promotions manager',
      'public relations director',
      'sales and marketing manager',
      'Web communications manager',
      'Web marketing manager',
    ],
  },
  {
    code: '0125',
    title: 'Other business services managers',
    examples: [
      'accounting firm manager',
      'contact centre manager',
      'employment agency manager',
      'energy asset joint venture manager',
      'legal firm manager',
      'management consulting service manager',
      'market research service manager',
      'nursing registry manager',
      'payroll service manager',
      'personnel agency manager',
      'professional services manager - business services',
      'security service manager',
    ],
  },
  {
    code: '0131',
    title: 'Telecommunication carriers managers',
    examples: [
      'microwave facilities manager - telecommunications',
      'network installation manager - telecommunications',
      'network operations manager - telecommunications',
      'network services manager - telecommunications',
      'operations and transmission services manager - telecommunications',
      'regional manager - telecommunications system',
      'switching systems director - telecommunications',
      'telecommunications facilities manager',
      'telecommunications installation manager',
      'telecommunications manager',
      'telecommunications services manager',
      'telephone company district manager',
    ],
  },
  {
    code: '0132',
    title: 'Postal and courier services managers',
    examples: [
      'collection and delivery operations manager - postal service',
      'mail operations manager',
      'mail processing plant manager',
      'messenger service manager',
      'parcel processing plant manager',
      'post office manager',
      'postal station superintendent',
      'production control manager - postal service',
    ],
  },
  {
    code: '0211',
    title: 'Engineering managers',
    examples: [
      'electrical engineering service manager',
      'engineering department manager',
      'engineering manager',
      'engineering research and development director',
      'production engineering manager',
      'telecommunications engineering service manager',
    ],
  },
  {
    code: '0212',
    title: 'Architecture and science managers',
    examples: [
      'agricultural chemistry branch director',
      'architectural manager',
      'chief actuary',
      'director of research - forestry',
      'director of research - manufacturing',
      'director of research - mining',
      'landscape architecture manager',
      'life sciences program manager',
      'petroleum geology department manager',
      'scientific research department manager',
      'statistical service manager',
    ],
  },
  {
    code: '0213',
    title: 'Computer and information systems managers',
    examples: [
      'computer systems manager',
      'data centre manager',
      'data processing and systems analysis manager',
      'data processing director',
      'electronic data processing (EDP) manager',
      'information systems manager',
      'management information system (MIS) manager',
      'software development manager',
      'software engineering manager',
      'systems development manager',
    ],
  },
  {
    code: '0311',
    title: 'Managers in health care',
    examples: [
      'chief of anesthesia',
      'chief of emergency medicine',
      'chief of medical staff',
      'director of clinical medicine',
      'director of dietetics',
      'director of laboratory medicine',
      'director of nursing - medical services',
      'director of occupational therapy',
      'director of physiotherapy',
      'director of surgery',
      'home care services director - medical services',
      'medical clinic director',
      'mental health residential care program manager',
      'rehabilitation services director',
    ],
  },
  {
    code: '0411',
    title: 'Government managers - health and social policy development and program administration',
    examples: [
      'city medical officer of health - government services',
      'health information and promotion director - government services',
      'health program operations manager - government services',
      'housing policy and development director - government services',
      'immigration and settlement director - government services',
      'social services director - government services',
      'social services planning manager - government services',
    ],
  },
  {
    code: '0412',
    title: 'Government managers - economic analysis, policy development and program administration',
    examples: [
      'border services manager',
      'business development director - government services',
      'customs manager - government services',
      'economic analysis chief - government services',
      'economic development director - government services',
      'economic policy analysis director - government services',
      'energy market analysis director - government services',
      'excise tax programs director - government services',
      'farm products marketing director - government services',
      'forest resource analysis chief - government services',
      'grain market analysis manager - government services',
      'international trade policy manager - government services',
      'revenue programs manager - government services',
      'tax policy research manager - government services',
      'taxation manager',
      'tourism development manager - government services',
      'trade development director',
    ],
  },
  {
    code: '0413',
    title: 'Government managers - education policy development and program administration',
    examples: [
      'education curriculum development director',
      'education director',
      'education policy analysis and research director',
      'education program administration manager',
      'school inspection director - public administration',
    ],
  },
  {
    code: '0414',
    title: 'Other managers in public administration',
    examples: [
      'clerk of the committee - Legislative Assembly',
      'elections planning director',
      'federal-provincial relations director',
      'intergovernmental affairs director',
    ],
  },
  {
    code: '0421',
    title: 'Administrators - post-secondary education and vocational training',
    examples: [
      'aviation school manager',
      'business school manager',
      'community college dean',
      'community college registrar',
      'dean - university or college',
      'dean of adult education',
      'faculty of science dean',
      'school of nursing dean',
      'school of technology dean',
      'student activities dean',
      'trade school manager',
      'university registrar',
    ],
  },
  {
    code: '0422',
    title: 'School principals and administrators of elementary and secondary education',
    examples: [
      'board of education administrator',
      'chief superintendent - schools',
      'director of education - correctional institution',
      'director of school for the hearing impaired',
      'district school superintendent',
      'private school headmaster/mistress',
      'school principal',
      'school vice-principal',
      'secondary school principal',
      'superintendent of elementary education',
      'superintendent of secondary schools',
      'superintendent of special education',
    ],
  },
  {
    code: '0423',
    title: 'Managers in social, community and correctional services',
    examples: [
      'association director',
      'child welfare services administrator',
      "children's aid society director",
      'community centre director',
      'correctional institution director',
      'correctional treatment and training director',
      'detention centre director',
      'environmental group director',
      'family services area manager',
      'income maintenance director',
      'labour organization manager',
      'membership services manager',
      'political organization manager',
      'prison warden',
      'social assistance director',
      'social services regional administrator',
      'social work director',
      'trade association manager',
      'volunteer services director',
    ],
  },
  {
    code: '0431',
    title: 'Commissioned police officers',
    examples: [
      'chief superintendent',
      'deputy police chief',
      'harbour police chief',
      'police chief',
      'police lieutenant',
      'police superintendent',
      'railway police chief',
      'Royal Canadian Mounted Police (RCMP) commissioner',
      'staff inspector',
    ],
  },
  {
    code: '0432',
    title: 'Fire chiefs and senior firefighting officers',
    examples: ['deputy fire chief', 'district fire chief', 'fire chief'],
  },
  {
    code: '0433',
    title: 'Commissioned officers of the Canadian Armed Forces',
    examples: [
      'admiral',
      'artillery officer',
      'brigadier general',
      'colonel',
      'commodore',
      'lieutenant commander',
      'major',
      'naval cadet',
      'officer cadet',
      'search and rescue captain',
    ],
  },
  {
    code: '0511',
    title: 'Library, archive, museum and art gallery managers',
    examples: [
      'archives director',
      'art gallery manager',
      'assistant director of archives',
      'chief librarian',
      'library director',
      'museum administrator',
      'museum executive director',
    ],
  },
  {
    code: '0512',
    title: 'Managers - publishing, motion pictures, broadcasting and performing arts',
    examples: [
      'ballet company director',
      'book publishing manager',
      'broadcasting manager',
      'broadcasting station manager',
      'copyright manager - publishing',
      'dance company director',
      'editor-in-chief',
      'managing editor',
      'opera company director',
      'publishing manager',
      'radio programming manager',
      'radio station manager',
      'television station manager',
      'theatre company manager',
      'video production company manager',
    ],
  },
  {
    code: '0513',
    title: 'Recreation, sports and fitness program and service directors',
    examples: [
      'director of recreation',
      'professional football team manager',
      'professional hockey team manager',
      'professional track and field team manager',
      'sports administration director - government',
      'sports association director',
      'sports federation director',
      'sports program director',
      "YMCA (Young Men's Christian Association) programs director",
    ],
  },
  {
    code: '0601',
    title: 'Corporate sales managers',
    examples: [
      'corporate sales manager',
      'national accounts manager - sales',
      'regional sales manager',
      'trade expansion manager',
    ],
  },
  {
    code: '0621',
    title: 'Retail and wholesale trade managers',
    examples: [
      'antique dealer',
      'assistant manager - retail',
      'automobile repair shop manager',
      'bookstore manager',
      'car dealership manager',
      'cattle dealer',
      'clothing store manager',
      'department store manager',
      'pleasure boat dealer',
      'sporting goods store manager',
      'store manager - retail',
      'supermarket manager',
      'variety store manager',
    ],
  },
  {
    code: '0631',
    title: 'Restaurant and food service managers',
    examples: [
      'banquet manager',
      'bar manager',
      'cafeteria manager',
      'catering service manager',
      'dining room manager',
      'food services manager',
      'hotel food and beverage service manager',
      'restaurant assistant manager',
      'restaurant manager',
      'restaurateur - food services',
    ],
  },
  {
    code: '0632',
    title: 'Accommodation service managers',
    examples: [
      'bed and breakfast operator',
      'front desk manager - accommodation services',
      'guest-house operator',
      'hotel assistant manager',
      'hotel director',
      'hotel manager',
      'motel manager',
      'reservations manager',
      'seasonal resort manager',
      'ski resort manager',
      'tourist home operator',
    ],
  },
  {
    code: '0651',
    title: 'Managers in customer and personal services, n.e.c.',
    examples: [
      'barber shop manager',
      'car wash manager',
      'cooking school manager',
      'driving school manager',
      'dry cleaning service manager',
      'hairdressing salon manager',
      'pest control service manager',
      'residential cleaning service manager',
      'septic tank service manager',
      'spa manager',
      'window washing service manager',
    ],
  },
  {
    code: '0711',
    title: 'Construction managers',
    examples: [
      'commercial construction manager',
      'construction manager',
      'construction project manager',
      'construction superintendent',
      'general contractor',
      'housing construction manager',
      'industrial construction manager',
      'pipeline construction manager',
      'residential construction manager',
    ],
  },
  {
    code: '0712',
    title: 'Home building and renovation managers',
    examples: [
      'home builder',
      'home renovation contractor',
      'home renovator',
      'renovation contractor',
      'residential homes contractor',
    ],
  },
  {
    code: '0714',
    title: 'Facility operation and maintenance managers',
    examples: [
      'airport manager',
      'arena manager',
      'campus maintenance manager',
      'facilities maintenance head',
      'facility operations manager',
      'harbour master',
      'maintenance and service superintendent',
      'maintenance manager',
      'maintenance support services chief',
      'mechanical services superintendent',
      'plant maintenance superintendent',
      'recreation facility manager',
      'shopping centre manager',
      'warehouse manager',
    ],
  },
  {
    code: '0731',
    title: 'Managers in transportation',
    examples: [
      'bus company manager',
      'distribution manager - logistics',
      'flight operations manager',
      'freight forwarding manager',
      'marine superintendent',
      'rail operations superintendent',
      'railway freight manager',
      'traffic manager - transportation',
      'transport department manager',
      'transportation manager',
      'urban transit system manager',
    ],
  },
  {
    code: '0811',
    title: 'Managers in natural resources production and fishing',
    examples: [
      'director of mining',
      'drilling operations manager',
      'fishing operations manager',
      'forestry operations manager',
      'gas field operations manager',
      'mine manager',
      'oil well servicing manager',
      'quarry manager',
      'shore captain - fishing',
    ],
  },
  {
    code: '0821',
    title: 'Managers in agriculture',
    examples: [
      'apiarist',
      'apple grower',
      'chicken farmer',
      'dairy farmer',
      'domestic animal breeder',
      'fruit farmer',
      'hog breeder',
      'horse breeder',
      'maple syrup producer',
      'market gardener',
      'potato farmer',
      'rancher',
      'seed grower',
      'sod farmer',
      'vegetable grower',
      'vineyard manager',
      'viticulturist',
      'wheat farmer',
    ],
  },
  {
    code: '0822',
    title: 'Managers in horticulture',
    examples: [
      'Christmas tree farm operator',
      'flower grower',
      'greenhouse manager',
      'greenhouse operator',
      'nursery manager',
      'nursery operator',
      'plant grower - nursery',
    ],
  },
  {
    code: '0823',
    title: 'Managers in aquaculture',
    examples: [
      'aquaculture manager',
      'aquaculture operator',
      'fish farm operator',
      'fish farmer',
      'fish hatchery manager',
      'fish hatchery operator',
      'mussel grower',
      'oyster grower',
      'salmon grower',
      'trout farmer',
    ],
  },
  {
    code: '0911',
    title: 'Manufacturing managers',
    examples: [
      'automobile production manager',
      'clothing factory manager',
      'dairy plant manager',
      'distillery manager',
      'factory superintendent',
      'foundry manager',
      'manufacturing manager',
      'manufacturing operations manager',
      'manufacturing plant manager',
      'printing plant manager',
      'production manager - manufacturing',
      'textile mill manager',
      'tire plant manager',
    ],
  },
  {
    code: '0912',
    title: 'Utilities managers',
    examples: [
      'director of waste management',
      'director of water pollution control',
      'distribution systems director - utilities',
      'electric generating plant manager',
      'electric power plant manager',
      'electrical power transmission operations director',
      'gas supply operations manager',
      'liquid waste facility manager',
      'refined petroleum products distribution manager',
      'sewage treatment plant manager',
      'water filtration plant manager',
      'water supply director',
    ],
  },
];
