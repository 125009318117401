import { Lookup } from '../lookup.interface';

export interface OutcomeLookup extends Lookup {
  displayValue2?: string;
  value: string;
  outcomeReason?: string;
}
export const ActionPlanItemOutcomes: { [name: string]: OutcomeLookup } = {
  inProgress: { code: 'inProgress', value: 'inProgress', displayValue: 'In Progress' },
  startedServices: {
    code: 'startedServices',
    value: 'inProgress',
    displayValue: 'Started services',
    outcomeReason: 'startedServices',
  },
  complete: { code: 'complete', value: 'complete', displayValue: 'Complete', displayValue2: 'completed' },
  approved: {
    code: 'approved',
    value: 'complete',
    displayValue: 'Approved',
    displayValue2: 'approved',
    outcomeReason: 'approved',
  },
  completedServices: {
    code: 'completedServices',
    value: 'complete',
    displayValue: 'Completed service',
    outcomeReason: 'completedServices',
  },
  notApproved: {
    code: 'notApproved',
    value: 'complete',
    displayValue: 'Not approved',
    displayValue2: 'not approved',
    outcomeReason: 'notApproved',
  },
  incomplete: { code: 'incomplete', value: 'incomplete', displayValue: 'Incomplete', displayValue2: 'incomplete' },
  cancelled: { code: 'cancelled', value: 'cancelled', displayValue: 'Cancelled', displayValue2: 'cancelled' },
  clientNotWilling: {
    code: 'clientNotWilling',
    value: 'incomplete',
    displayValue: 'Incomplete - Client not willing, withdrew',
    outcomeReason: 'clientNotWilling',
  },
  clientCircumstancesChanged: {
    code: 'clientCircumstancesChanged',
    value: 'incomplete',
    displayValue: 'Incomplete - Client circumstances changed',
    outcomeReason: 'clientCircumstancesChanged',
  },
  clientFoundWork: {
    code: 'clientFoundWork',
    value: 'incomplete',
    displayValue: 'Incomplete - Client found work',
    outcomeReason: 'clientFoundWork',
  },
  noLongerRequired: {
    code: 'noLongerRequired',
    value: 'incomplete',
    displayValue: 'Incomplete - No longer required',
    outcomeReason: 'noLongerRequired',
  },
  unableToContactClient: {
    code: 'unableToContactClient',
    value: 'incomplete',
    displayValue: 'Incomplete - Unable to contact client',
    outcomeReason: 'unableToContactClient',
  },
  clientWithdrawn: {
    code: 'clientWithdrawn',
    value: 'incomplete',
    displayValue: 'Incomplete - Client withdrawn from services',
    outcomeReason: 'clientWithdrawn',
  },
  clientNotProgressing: {
    code: 'clientNotProgressing',
    value: 'incomplete',
    displayValue: 'Incomplete - Client not progressing',
    outcomeReason: 'clientNotProgressing',
  },
  clientReferredToDifferentProgram: {
    code: 'clientReferredToDifferentProgram',
    value: 'incomplete',
    displayValue: 'Incomplete - Client referred to different program',
    outcomeReason: 'clientReferredToDifferentProgram',
  },
};

export const ActionPlanItemOutcomeDefaultOptions = Object.values(ActionPlanItemOutcomes).filter(
  (value) => !value.outcomeReason
);
