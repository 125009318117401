import { Expose } from 'class-transformer';
import { IsDefined, IsNotEmpty, IsNumberString, Length, MaxLength } from 'class-validator';

export class IndianRegistration {
  /**
   * Band number
   */
  @Expose()
  @IsNotEmpty()
  @Length(3, 3)
  @IsNumberString()
  band?: string;

  /**
   * Family number
   */
  @Expose()
  @IsNotEmpty()
  @MaxLength(5)
  @IsNumberString()
  family?: string;

  /**
   * Family number
   */
  @Expose()
  @IsNotEmpty()
  @Length(2, 2)
  @IsNumberString()
  position?: string;

  /**
   * Lives on Reserve?
   */
  @Expose()
  @IsDefined()
  livesOnReserve?: boolean;

  /**
   * Receives funds from band?
   */
  @Expose()
  @IsDefined()
  receivesFundsFromBand?: boolean;


}
