import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private config;
  private lastFetch: number;
  constructor(private http: HttpClient) {}
  public loadConfig() {
    return this.http
      .get('./assets/config/configMap/config.json')
      .toPromise()
      .then((config) => {
        this.config = config;
        this.lastFetch = Date.now();
      })
      .catch((err) => {
        console.error(err);
      });
  }
  
  getConfig() {
    return this.config;
  }

  /**
   * Get the cached config or fetch a fresh one if expired.
   * @param expirationMS lifetime of the cached config in milliseconds.
   */
  public async cachedGetConfig(expirationMS: number): Promise<any> {
    const timeSinceLast = Date.now() - this.lastFetch;
    if (!this.config || timeSinceLast > expirationMS) {
      await this.loadConfig();
    }
    
    return this.config; 
  }
}
