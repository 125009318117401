import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { APP_VERSION, HALF_HOUR_SEC, ONE_MIN_SEC } from '@fscd-intake/entities';
import { MaintenanceService } from '@common/ui/maintenance-mode';
import { AppConfigService, SessionMonitor } from '@common/ui/shared-components';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, combineLatest, lastValueFrom, map, take } from 'rxjs';
import { HealthCheckService } from '../../../services/health-check.service';
import { PageNavigation } from '../../page-navigation';

@Component({
  selector: 'fi-layout-page',
  templateUrl: './layout-page.component.html',
  styleUrls: ['./layout-page.component.scss'],
})
export class LayoutPageComponent implements OnInit, OnDestroy {
  _destroy$ = new Subject<void>();
  appVersion = APP_VERSION;
  loading = false;
  applicationComponent;
  copyrightYear = new Date().getFullYear();
  isApplicationPage = false;

  @ViewChild('contentSection', { read: ElementRef, static: false })
  contentSection;

  applicationVersionUI$: Observable<string>;
  applicationVersionAPI$: Observable<string>;
  applicationVersionFile$: Observable<string>;
  applicationVersion$: Observable<string>;
  isProd = false;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private sessionMonitor: SessionMonitor,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private maintenanceService: MaintenanceService,
    private healthCheckService: HealthCheckService,
    private toasterService: ToastrService,
    private cdr: ChangeDetectorRef,
    private configService: AppConfigService
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.sessionMonitor.stopMonitor();
  }

  async ngOnInit() {
    try {
      const scheduledMaintenance = await this.maintenanceService.scheduledMaintenance();
      const currentRoute = this.router.url.split('?')[0];
      if (!scheduledMaintenance.active && currentRoute === '/serviceError') {
        this.router.navigate(['']);
      }

      const healthy = await lastValueFrom(this.healthCheckService.isServicesAlive().pipe(take(1)));
      if (!healthy) {
        this.router.navigate(['serviceError']);
        return;
      }
    } catch (error) {
      this.router.navigate(['serviceError']);
      return;
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
        //reset css classes on banner
        this.renderer.removeClass(this.contentSection.nativeElement, 'offset');
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.loading = false;
      }
    });

    let notified = false;
    if (this.authenticationService.isAuthenticated()) {
      this.setVersionObservables();
      await this.authenticationService.refreshToken(HALF_HOUR_SEC);
      this.sessionMonitor.monitorSession(30);
      this.sessionMonitor.sessionTimeRemainingSeconds$.subscribe((timeRemainingSeconds) => {
        if (timeRemainingSeconds <= ONE_MIN_SEC && timeRemainingSeconds > 0 && !notified) {
          notified = true;
          this.toasterService.warning(
            `Your session will expire shortly. Please save your application to avoid losing information.`,
            undefined,
            {
              disableTimeOut: false,
              timeOut: 15000,
            }
          );
        }

        if (timeRemainingSeconds <= 0) {
          this.timeoutRedirect();
        }
      });
    }

    this.isApplicationPage = this.isInApplication(this.route.snapshot?.url[0]?.path);
  }

  onActivate(componentRef: unknown) {
    this.applicationComponent = componentRef;
    this.cdr.detectChanges();
  }

  async goBackToDashboard() {
    await this.saveCurrentApplication();
    const isSubmitted = this.applicationComponent.application.isSubmitted;
    if (isSubmitted) {
      this.router.navigateByUrl('/dash?tab=submitted');
    } else {
      this.router.navigateByUrl('/dash?tab=draft');
    }
  }

  private timeoutRedirect() {
    const redirectUrl = `${window.location.origin}/session-expired`;
    this.authenticationService.signOut(redirectUrl);
  }

  private isInApplication(url: string) {
    return url ? url.includes('application') : false;
  }

  private async saveCurrentApplication() {
    let valid = true;

    const component = this.applicationComponent?.routerOutlet?.component as PageNavigation;
    if (component && component.next) {
      valid = component.next();
      if (!this.applicationComponent?.doSave || !valid) {
        return;
      }
      await this.applicationComponent.doSave();
    }
  }

  private getVersionInfoUI$(): Observable<string> | null {
    return this.healthCheckService.queryGetUIVersion();
  }

  private getVersionInfoAPI$(): Observable<string> | null {
    return this.healthCheckService.queryGetAPIVersion();
  }

  private getVersionInfoFile$(): Observable<string> | null {
    return this.healthCheckService.queryGetFileVersion();
  }

  private setVersionObservables() {
    const envName = this.configService.getConfig().ENVIRONMENT_NAME?.toLowerCase();
    this.isProd = envName && (envName === 'production' || envName === 'prod');
    //do not do the work of querying this on prod
    if (!this.isProd) {
      this.applicationVersionUI$ = this.getVersionInfoUI$();
      this.applicationVersionAPI$ = this.getVersionInfoAPI$();
      this.applicationVersionFile$ = this.getVersionInfoFile$();
      this.applicationVersion$ = combineLatest([
        this.applicationVersionUI$,
        this.applicationVersionAPI$,
        this.applicationVersionFile$,
      ]).pipe(
        map(([ui, api, file]) => {
          if (!ui && !api && !file) return null;
          return `${this.appVersion} | UI: ${ui} | API: ${api} | File: ${file}`;
        })
      );
    }
  }
}
