import { Lookup } from './lookup.interface';
export interface CitizenshipTypesLookup extends Lookup {
  additionalInformation?: string;
  edbId?: string;
  isImmgrant?: boolean;
}
export const CitizenshipTypes: { [name: string]: CitizenshipTypesLookup } = {
  canadianCitizen: { displayValue: 'A Canadian citizen', code: 'canadianCitizen', edbId: '1' },
  permanentResident: { displayValue: 'A permanent resident of Canada', edbId: '4', isImmgrant: true },
  protectedPerson: { displayValue: 'Protected person', code: 'protectedPerson', isImmgrant: true },
  refugee: {
    displayValue: 'Refugee claimant',
    code: 'refugee',
    additionalInformation: 'countryOfOrigin',
    edbId: '3',
    isImmgrant: true,
  },
  temporaryResident: { displayValue: 'A temporary resident', code: 'temporaryResident', edbId: '6', isImmgrant: true },
  noStatusInCanada: { displayValue: 'No status in Canada', code: 'noStatusInCanada' },
  landedImmigrant: { displayValue: 'Landed immigrant', code: 'landedImmigrant', edbId: '2', isImmgrant: true },
};
