import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isEqual } from 'lodash';
export class CompareValidator {
  private static getOtherControl(
    formControl: AbstractControl,
    otherInput: string,
    otherInputError: string
  ): AbstractControl {
    if (!formControl.parent) return null;
    const otherControl = formControl.parent.controls[otherInput];
    if (!otherControl) return null;
    if (otherControl.invalid && otherControl.errors[otherInputError]) otherControl.setErrors(null);
    return formControl.invalid || otherControl.invalid || formControl.value == null || otherControl.value == null
      ? null
      : otherControl;
  }
  static shouldMatchControl(otherInput: () => AbstractControl, label: string): ValidatorFn {
    return function (formControl: AbstractControl) {
      const otherControl = otherInput();

      const inputIsDate = formControl.value instanceof Date;
      const otherInputIsDate = otherControl.value instanceof Date;
      if (inputIsDate && !otherInputIsDate) return { shouldMatch: { label } };

      return otherControl &&
        formControl.value !== null &&
        formControl.value !== undefined &&
        formControl.value !== '' &&
        (inputIsDate
          ? formControl.value.getTime() !== otherControl.value.getTime()
          : formControl.value !== otherControl.value)
        ? { shouldMatch: { label } }
        : null;
    };
  }
  static shouldNotMatchControl(otherInput: () => AbstractControl, label: string): ValidatorFn {
    return function (formControl: AbstractControl) {
      const otherControl = otherInput();
      return otherControl &&
        formControl.value !== null &&
        formControl.value !== undefined &&
        formControl.value !== '' &&
        formControl.value === otherControl.value
        ? { shouldNotMatch: { label } }
        : null;
    };
  }
  static shouldMatch(otherInput: string, label: string): ValidatorFn {
    return function (formControl: AbstractControl) {
      const otherControl = CompareValidator.getOtherControl(formControl, otherInput, 'shouldMatch');
      return otherControl && formControl.value !== otherControl.value ? { shouldMatch: { label } } : null;
    };
  }
  static shouldNotMatch(otherInput: string, label: string): ValidatorFn {
    return function (formControl: AbstractControl) {
      const otherControl = CompareValidator.getOtherControl(formControl, otherInput, 'shouldNotMatch');
      return otherControl && formControl.value === otherControl.value ? { shouldNotMatch: { label } } : null;
    };
  }
  static shouldMatchValue(otherValue: any, label: string): ValidatorFn {
    const _isEqual = isEqual;
    return function (formControl: AbstractControl) {
      return _isEqual(formControl.value, otherValue) ? null : { shouldMatch: { label } };
    };
  }
  static max(otherInput: string, label: string, dataType = ''): ValidatorFn {
    return function (formControl: AbstractControl) {
      const otherControl = CompareValidator.getOtherControl(formControl, otherInput, 'min');
      return otherControl && formControl.value > otherControl.value ? { max: { label, dataType } } : null;
    };
  }
  static min(otherInput: string, label: string, dataType = ''): ValidatorFn {
    return function (formControl: AbstractControl) {
      const otherControl = CompareValidator.getOtherControl(formControl, otherInput, 'max');
      return otherControl && formControl.value < otherControl.value ? { min: { label, dataType } } : null;
    };
  }

  /**
   * Returns error only if otherInput has a certain
   */
  static requiredIf(predicate: () => boolean): ValidatorFn {
    return function (formControl: AbstractControl): null | ValidationErrors {
      if (predicate()) {
        return Validators.required(formControl);
      }

      return null;
    };
  }
}
