export const NationalOccupationCodes2021 = [
  {
    code: '10010',
    title: 'Financial managers',
    examples: [
      'controller - financial services',
      'director - financial services',
      'director of accounting',
      'finance director',
      'financial administrator',
      'financial control manager',
      'financial planning and analysis manager',
      'internal audit services manager',
      'treasurer',
    ],
  },
  {
    code: '10011',
    title: 'Human resources managers',
    examples: [
      'employer-employee relations manager',
      'human resources manager',
      'industrial relations manager',
      'occupational health and safety manager',
      'pay and benefits manager',
      'personnel director',
      'personnel services manager',
      'personnel training and development manager',
      'recruiting manager',
      'staff relations manager',
    ],
  },
  {
    code: '10012',
    title: 'Purchasing managers',
    examples: [
      'contract manager',
      'food purchasing manager',
      'material manager',
      'procurement director',
      'purchasing contracts manager',
      'purchasing director',
      'supply chain logistics manager',
    ],
  },
  {
    code: '10019',
    title: 'Other administrative services managers',
    examples: [
      'administrative services chief',
      'administrative services manager',
      'business manager - non-profit organization',
      'court registrar',
      'court services manager',
      'inventory control manager',
      'records administrator',
      'regional administrative manager',
      'security manager',
      'support services manager',
      'Supreme Court registrar',
    ],
  },
  {
    code: '10020',
    title: 'Insurance, real estate and financial brokerage managers',
    examples: [
      'bond sales manager',
      'brokerage manager - investments',
      'commodities trading manager',
      'financial brokerage manager',
      'insurance claims service manager',
      'insurance manager',
      'investment manager - financial brokerage',
      'mortgage broker manager',
      'real estate service manager',
      'securities sales director',
      'trading floor manager',
    ],
  },
  {
    code: '10021',
    title: 'Banking, credit and other investment managers',
    examples: [
      'assistant operations manager - banking, credit and investment',
      'bank manager',
      'banking operations manager',
      'collection centre manager',
      'commercial banking manager',
      'corporate banking centre manager',
      'corporate services manager - banking, credit and investment',
      'credit card centre manager',
      'credit card company operations manager',
      'credit manager',
      'credit union manager',
      'mortgage and consumer credit manager',
      'personal services manager - banking, credit and investment',
      'regional collection manager',
      'trust company manager',
    ],
  },
  {
    code: '10022',
    title: 'Advertising, marketing and public relations managers',
    examples: [
      'advertising director',
      'advertising manager',
      'electronic business (e-business) manager',
      'fundraising campaign manager',
      'Internet communications manager',
      'marketing manager',
      'media relations director',
      'promotions manager',
      'public relations director',
      'sales and marketing manager',
      'Web communications manager',
      'Web marketing manager',
    ],
  },
  {
    code: '10029',
    title: 'Other business services managers',
    examples: [
      'accounting firm manager',
      'contact centre manager',
      'employment agency manager',
      'energy asset joint venture manager',
      'legal firm manager',
      'management consulting service manager',
      'market research service manager',
      'nursing registry manager',
      'payroll service manager',
      'personnel agency manager',
      'professional services manager - business services',
      'security service manager',
    ],
  },
  {
    code: '10030',
    title: 'Telecommunication carriers managers',
    examples: [
      'microwave facilities manager - telecommunications',
      'network installation manager - telecommunications',
      'network operations manager - telecommunications',
      'network services manager - telecommunications',
      'operations and transmission services manager - telecommunications',
      'regional manager - telecommunications system',
      'switching systems director - telecommunications',
      'telecommunications facilities manager',
      'telecommunications installation manager',
      'telecommunications manager',
      'telecommunications services manager',
      'telephone company district manager',
    ],
  },
  {
    code: '11100',
    title: 'Financial auditors and accountants',
    examples: [
      'accountant',
      'chief accountant',
      'financial auditor',
      'income tax expert',
      'industrial accountant',
      'internal auditor',
    ],
  },
  {
    code: '11101',
    title: 'Financial and investment analysts',
    examples: [
      'chartered financial analyst',
      'financial analyst',
      'financial services research associate',
      'investment analyst',
      'money market analyst',
      'portfolio manager',
    ],
  },
  {
    code: '11102',
    title: 'Financial advisors',
    examples: [
      'account manager',
      'financial advisor',
      'financial consultant',
      'financial planner',
      'financial security advisor',
      'personal finance advisor',
      'personal investment advisor',
    ],
  },
  {
    code: '11103',
    title: 'Securities agents, investment dealers and brokers',
    examples: [
      'bond dealer',
      'broker',
      'commodity broker',
      'discount broker',
      'floor trader - commodity exchange',
      'foreign exchange trader',
      'investment dealer',
      'mutual fund broker',
      'mutual fund sales representative',
      'registered investment representative',
      'registered representatives supervisor',
      'securities agent',
      'securities sales agent',
      'securities trader',
      'stockbroker',
    ],
  },
  {
    code: '11109',
    title: 'Other financial officers',
    examples: [
      'credit adjudicator',
      'credit unions examiner',
      'estate and trust administrator',
      'financial institutions inspector',
      'financial investigator',
      'financial underwriter',
      'mortgage broker',
      'trust officer',
    ],
  },
  {
    code: '11200',
    title: 'Human resources professionals',
    examples: [
      'classification officer - human resources',
      'classification specialist',
      'compensation research analyst',
      'conciliator',
      'employee relations officer',
      'employment equity officer',
      'human resources consultant',
      'human resources research officer',
      'job analyst',
      'labour organization business agent',
      'labour relations officer',
      'mediator',
      'union representative',
      'wage analyst',
    ],
  },
  {
    code: '11201',
    title: 'Professional occupations in business management consulting',
    examples: [
      'business management consultant',
      'business methods analyst',
      'health information management consultant',
      'ISO consultant',
      'management analyst',
      'operations management senior consultant',
      'organization and methods researcher',
      'organizational analysis consultant',
      'organizational analyst',
      'records management specialist',
    ],
  },
  {
    code: '11202',
    title: 'Professional occupations in advertising, marketing and public relations',
    examples: [
      'advertising consultant',
      'communications officer (except emergency services)',
      'communications specialist',
      'digital marketing consultant',
      'event marketing specialist',
      'fundraising consultant',
      'information officer',
      'literary agent',
      'marketing consultant',
      'marketing coordinator',
      'media coordinator',
      'media relations officer',
      'museum educator',
      'performers agent',
      'press secretary',
      'public affairs officer',
      'public relations consultant',
      'public relations officer',
      'publicist',
      'publicity agent',
    ],
  },
  {
    code: '12010',
    title: 'Supervisors, general office and administrative support workers',
    examples: [
      'clerical supervisor',
      'data entry supervisor',
      'filing clerks supervisor',
      'hospital admitting clerks supervisor',
      'personnel clerks supervisor',
      'records office supervisor',
      'registry supervisor',
      'switchboard operators supervisor',
    ],
  },
  {
    code: '12011',
    title: 'Supervisors, finance and insurance office workers',
    examples: [
      'accounts payable supervisor',
      'accounts receivable supervisor',
      'bank clerks supervisor',
      'billing supervisor',
      'bookkeeping supervisor',
      'claims adjusters supervisor',
      'collection supervisor',
      'credit supervisor',
      'payroll supervisor',
      'supervisor of accounting',
    ],
  },
  {
    code: '12012',
    title: 'Supervisors, library, correspondence and related information workers',
    examples: [
      'advertising clerks supervisor',
      'correspondence clerks supervisor',
      'library clerks supervisor',
      'publication clerks supervisor',
      'statistical clerks supervisor',
      'survey interviewers supervisor',
    ],
  },
  {
    code: '12013',
    title: 'Supervisors, supply chain, tracking and scheduling coordination occupations',
    examples: [
      'flight crew scheduling supervisor',
      'head dispatcher',
      'head shipper',
      'inventory control supervisor',
      'parts service supervisor',
      'production clerks supervisor',
      'receiving supervisor',
      'transportation logistics supervisor',
    ],
  },
  {
    code: '12100',
    title: 'Executive assistants',
    examples: ['committee clerk', 'corporate secretary', 'executive assistant', 'legislative assistant'],
  },
  {
    code: '12101',
    title: 'Human resources and recruitment officers',
    examples: [
      'human resources officer',
      'personnel officer',
      'recruitment specialist',
      'staffing analyst',
      'staffing coordinator',
    ],
  },
  {
    code: '12102',
    title: 'Procurement and purchasing agents and officers',
    examples: [
      'contract management officer',
      'contracts officer',
      'energy asset surface land analyst',
      'furniture and furnishings purchaser',
      'government supply officer',
      'material management officer',
      'procurement officer',
      'purchasing agent',
      'sourcing specialist',
      'strategic sourcing analyst',
    ],
  },
  {
    code: '12103',
    title: 'Conference and event planners',
    examples: [
      'conference and meeting planner',
      'conference planner',
      'conference services officer',
      'convention coordinator',
      'event planner',
      'festival organizer',
      'meeting planner',
      'special events organizer',
      'trade show planner',
    ],
  },
  {
    code: '12104',
    title: 'Employment insurance and revenue officers',
    examples: [
      'employment insurance agent',
      'employment insurance benefits control officer',
      'revenue officer',
      'tax collection officer',
      'tax enforcement officer',
    ],
  },
  {
    code: '12110',
    title: 'Court reporters, medical transcriptionists and related occupations',
    examples: [
      'braille technician',
      'closed captioner',
      'court reporter',
      'Hansard reporter',
      'medical transcriptionist',
      'transcriptionist',
    ],
  },
  {
    code: '12111',
    title: 'Health information management occupations',
    examples: [
      'health information management supervisor',
      'health information management technician',
      'health records technician',
      'medical records unit supervisor',
    ],
  },
  {
    code: '12112',
    title: 'Records management technicians',
    examples: [
      'information management technician',
      'microfilm records searcher',
      'records classifier',
      'records digitizing technician',
      'records technician',
    ],
  },
  {
    code: '12113',
    title: 'Statistical officers and related research support occupations',
    examples: [
      'research assistant - generalist (except university)',
      'research support officer',
      'social research assistant (except university)',
      'statistical officer',
      'technical research assistant (except post-secondary)',
    ],
  },
  {
    code: '12200',
    title: 'Accounting technicians and bookkeepers',
    examples: ['accounting bookkeeper', 'accounting technician', 'bookkeeper', 'finance technician'],
  },
  {
    code: '12201',
    title: 'Insurance adjusters and claims examiners',
    examples: ['adjuster', 'claims examiner', 'claims representative', 'insurance adjuster'],
  },
  {
    code: '12202',
    title: 'Insurance underwriters',
    examples: ['group underwriter', 'insurance underwriter', 'liability underwriter', 'property underwriter'],
  },
  {
    code: '12203',
    title: 'Assessors, business valuators and appraisers',
    examples: [
      'accredited appraiser',
      'business valuator',
      'chartered business valuator (CBV)',
      'property assessor',
      'property valuator',
      'real estate appraiser',
    ],
  },
  {
    code: '13100',
    title: 'Administrative officers',
    examples: [
      'access to information and privacy officer',
      'administrative officer',
      'administrative services coordinator',
      'forms management officer',
      'office administrator',
      'office manager',
      'office services coordinator',
      'planning officer',
      'records analyst - access to information',
      'surplus assets officer',
      'university admissions officer',
    ],
  },
  {
    code: '13101',
    title: 'Property administrators',
    examples: [
      'accommodation officer',
      'apartment rental agent',
      'housing project manager',
      'property administrator',
      'property leasing coordinator',
      'property rentals manager',
    ],
  },
  {
    code: '13102',
    title: 'Payroll administrators',
    examples: [
      'benefits officer - payroll administration',
      'pay advisor',
      'pay and benefits administrator',
      'pay and benefits clerk',
      'pay clerk',
      'payroll clerk',
      'payroll officer',
      'salary administration officer',
    ],
  },
  {
    code: '13110',
    title: 'Administrative assistants',
    examples: [
      'administrative assistant',
      'executive secretary (except legal and medical)',
      'office administrative assistant',
      'private secretary',
      'secretary (except legal and medical)',
      'technical secretary',
    ],
  },
  {
    code: '13111',
    title: 'Legal administrative assistants',
    examples: [
      'administrative assistant - legal',
      'corporate law legal assistant',
      'legal assistant',
      'legal assistant - criminal law',
      'legal secretary',
      'litigation legal assistant',
      'litigation secretary',
      'real estate secretary',
    ],
  },
  {
    code: '13112',
    title: 'Medical administrative assistants',
    examples: ['administrative assistant - medical', 'medical secretary', 'ward secretary'],
  },
  {
    code: '13200',
    title: 'Customs, ship and other brokers',
    examples: [
      'cargo broker',
      'chartered shipbroker',
      'customs broker',
      'gas broker',
      'licensed customs broker',
      'shipbroker',
    ],
  },
  {
    code: '13201',
    title: 'Production and transportation logistics coordinators',
    examples: [
      'dispatch logistician',
      'freight forwarding logistician',
      'production coordinator',
      'production scheduler',
      'supply chain coordinator - production',
      'supply control coordinator',
    ],
  },
  {
    code: '14100',
    title: 'General office support workers',
    examples: [
      'administrative clerk',
      'aircraft records clerk',
      'file and classification clerk',
      'filing clerk',
      'general office worker',
      'medical records clerk',
      'office assistant',
      'office clerk',
      'records filing-system clerk',
      'technical records clerk',
      'ward clerk - hospital',
      'warranty clerk',
    ],
  },
  {
    code: '14101',
    title: 'Receptionists',
    examples: [
      'answering service operator',
      'appointment clerk',
      'business receptionist',
      'dental receptionist',
      'hospital admitting clerk',
      'medical receptionist',
      'office reception clerk',
      'receptionist',
      'switchboard operator',
      'telephone operator',
      'telephone service assistant',
      'telereceptionist',
      'toll operator',
    ],
  },
  {
    code: '14102',
    title: 'Personnel clerks',
    examples: [
      'classification clerk - human resources',
      'employment clerk',
      'human resources assistant',
      'human resources clerk',
      'labour relations clerk',
      'personnel services clerk',
      'staffing clerk',
      'training clerk',
    ],
  },
  {
    code: '14103',
    title: 'Court clerks and related court services occupations',
    examples: [
      'court administrator',
      'court clerk',
      'court clerk supervisor',
      'court clerk-crier',
      'court officer',
      'criminal court clerk',
      'judicial clerk',
      'provincial court clerk',
    ],
  },
  {
    code: '14110',
    title: 'Survey interviewers and statistical clerks',
    examples: [
      'census enumerator',
      'coding clerk - statistics',
      'election enumerator',
      'interview clerk',
      'poll clerk',
      'public opinion interviewer',
      'statistical clerk',
      'survey interviewer',
      'telephone survey clerk',
    ],
  },
  {
    code: '14111',
    title: 'Data entry clerks',
    examples: [
      'data control clerk',
      'data entry operator',
      'data input clerk',
      'data processor',
      'payment entry clerk',
    ],
  },
  {
    code: '14112',
    title: 'Desktop publishing operators and related occupations',
    examples: [
      'braille keyboard operator - typesetting',
      'compositor - typesetting',
      'computer typesetter',
      'copy marker - typesetting',
      'desktop compositor',
      'desktop publishing (DTP) operator',
      'input operator - typesetting',
      'markup person - typesetting',
      'output operator - typesetting',
      'photocomposition typesetter',
      'photocompositor',
      'phototypesetting machine operator',
      'typographer',
    ],
  },
  {
    code: '14200',
    title: 'Accounting and related clerks',
    examples: [
      'accounting clerk',
      'accounts payable clerk',
      'accounts receivable clerk',
      'audit clerk',
      'billing clerk',
      'budget clerk',
      'costing clerk',
      'deposit clerk',
      'finance clerk',
      'freight-rate clerk',
      'income tax return preparer',
      'invoice clerk',
      'ledger clerk',
      'tax clerk',
    ],
  },
  {
    code: '14201',
    title: 'Banking, insurance and other financial clerks',
    examples: [
      'actuarial clerk',
      'bank clerk',
      'credit clerk',
      'dental claims clerk',
      'dividend calculation clerk',
      'insurance clerk - financial sector',
      'insurance rater',
      'ledger control clerk',
      'loan clerk - financial sector',
      'mortgage clerk',
      'premium rater - insurance',
      'real estate clerk',
      'securities clerk - financial sector',
    ],
  },
  {
    code: '14202',
    title: 'Collection clerks',
    examples: [
      'bill collector',
      'collection clerk',
      'collection officer (except taxation)',
      'collections investigation officer',
      'collector',
      'credit and collection clerk',
      'locator - collection',
      'skip tracer - collection',
    ],
  },
  {
    code: '14300',
    title: 'Library assistants and clerks',
    examples: [
      'circulation clerk - library',
      'interlibrary loan clerk',
      'library assistant',
      'library page',
      'periodicals clerk',
      'reference clerk',
      'shelving clerk - library',
    ],
  },
  {
    code: '14301',
    title: 'Correspondence, publication and regulatory clerks',
    examples: [
      'advertising clerk',
      'application clerk',
      'authorization clerk',
      'by-law clerk',
      'classified advertising clerk',
      'contract clerk',
      'correspondence clerk',
      'customs clerk',
      'directory compiler',
      'documentation clerk',
      'editorial assistant',
      'licence clerk',
      'passport clerk',
      'proofreader',
      'publication clerk',
      'registration clerk',
      'registry clerk',
      'translation clerk',
    ],
  },
  {
    code: '14400',
    title: 'Shippers and receivers',
    examples: [
      'freight receiver',
      'freight shipper',
      'import freight clerk',
      'receiver',
      'shipper',
      'shipper-receiver',
      'shipping agent',
      'shipping and receiving clerk',
      'supply chain assistant',
    ],
  },
  {
    code: '14401',
    title: 'Storekeepers and partspersons',
    examples: [
      'ammunition storekeeper',
      'automotive partsperson - retail',
      'material keeper',
      'medical supply clerk',
      'motor vehicle parts clerk',
      'parts clerk',
      'parts supplier',
      'partsperson',
      'ship storeman/woman',
      'storekeeper',
      'tool room attendant',
    ],
  },
  {
    code: '14402',
    title: 'Production logistics workers',
    examples: ['expediter', 'production clerk', 'scheduling clerk', 'traffic control clerk'],
  },
  {
    code: '14403',
    title: 'Purchasing and inventory control workers',
    examples: [
      'inventory analyst',
      'inventory clerk',
      'inventory control clerk',
      'inventory planner',
      'procurement clerk',
      'purchasing clerk',
      'purchasing clerk assistant',
    ],
  },
  {
    code: '14404',
    title: 'Dispatchers',
    examples: [
      '911 dispatcher',
      'alarm system dispatcher',
      'ambulance dispatcher',
      'emergency vehicle dispatcher',
      'mobile equipment dispatcher',
      'radio operator',
      'taxi dispatcher',
      'tow truck dispatcher',
      'truck dispatcher',
      'utilities maintenance crew dispatcher',
    ],
  },
  {
    code: '14405',
    title: 'Transportation route and crew schedulers',
    examples: [
      'bus scheduler',
      'crew scheduler - transportation',
      'flight crew scheduler',
      'schedule analyst',
      'train scheduler',
      'transit scheduler',
      'transportation schedules writer',
      'truck scheduler',
    ],
  },
  {
    code: '20010',
    title: 'Engineering managers',
    examples: [
      'director of engineering research and development',
      'electrical engineering manager',
      'engineering department manager',
      'engineering manager',
      'production engineering manager',
      'telecommunications engineering service manager',
    ],
  },
  {
    code: '20011',
    title: 'Architecture and science managers',
    examples: [
      'agricultural chemistry branch director',
      'architectural manager',
      'chief actuary',
      'landscape architecture manager',
      'life sciences program manager',
      'petroleum geology department manager',
      'research director - forestry',
      'research director - manufacturing',
      'research director - mining',
      'scientific research department manager',
      'statistical service manager',
    ],
  },
  {
    code: '20012',
    title: 'Computer and information systems managers',
    examples: [
      'business systems manager',
      'cloud solutions architect manager',
      'computer systems manager',
      'data centre manager',
      'data processing and systems analysis manager',
      'data processing director',
      'director of software engineering',
      'electronic data processing (EDP) manager',
      'enterprise resource planning (ERP) manager',
      'information systems manager',
      'management information system (MIS) manager',
      'software development manager',
      'software engineering manager',
      'systems development manager',
      'technical delivery manager - information technology (IT)',
      'technical program manager - information technology (IT)',
    ],
  },
  {
    code: '21100',
    title: 'Physicists and astronomers',
    examples: [
      'acoustics physicist',
      'aerodynamicist',
      'aerospace research scientist',
      'astronomer',
      'astrophysicist',
      'atmospheric physicist',
      'biophysicist',
      'cosmologist',
      'electronics research scientist',
      'experimental physicist',
      'health physicist',
      'medical physicist',
      'metrologist',
      'nuclear physicist',
      'optics physicist',
      'physics research scientist',
      'plasma physicist',
      'radio astronomer',
      'remote sensing research scientist',
      'solid-state physicist',
    ],
  },
  {
    code: '21101',
    title: 'Chemists',
    examples: [
      'agricultural chemist',
      'analytical chemist',
      'bioanalytical chemist',
      'biochemist',
      'chemist',
      'clinical chemist',
      'coatings chemist',
      'electrochemist',
      'environmental chemist',
      'food chemist',
      'inorganic chemist',
      'medicinal chemist',
      'nuclear magnetic resonance (NMR) spectroscopist',
      'oceanographic chemist',
      'organic chemist',
      'organic mass spectrometrist',
      'organometallic chemist',
      'pharmacological chemist',
      'physical chemist',
      'polymer chemist',
      'quality control chemist',
      'research chemist',
      'soil chemist',
      'textile chemist',
      'theoretical chemist',
    ],
  },
  {
    code: '21102',
    title: 'Geoscientists and oceanographers',
    examples: [
      'development geologist',
      'environmental geologist',
      'exploration geologist',
      'geochemist',
      'geologist',
      'geophysicist',
      'glaciologist',
      'groundwater geologist',
      'hydrogeologist',
      'hydrologist',
      'mine geologist',
      'mineralogist',
      'oceanographer',
      'paleontologist',
      'petroleum geologist',
      'petrologist',
      'sedimentologist',
      'seismologist',
      'stratigrapher',
    ],
  },
  {
    code: '21103',
    title: 'Meteorologists and climatologists',
    examples: [
      'air quality meteorologist',
      'climatologist',
      'hydrometeorologist',
      'meteorologist',
      'operational meteorologist',
    ],
  },
  {
    code: '21109',
    title: 'Other professional occupations in physical sciences',
    examples: [
      'astronaut',
      'ballistics examiner',
      'materials scientist',
      'metallurgist',
      'physical metallurgist',
      'research scientist - ceramics',
      'research scientist - composite materials',
      'soil scientist',
    ],
  },
  {
    code: '21110',
    title: 'Biologists and related scientists',
    examples: [
      'anatomist',
      'bacteriologist',
      'bioinformatician',
      'biologist',
      'botanist',
      'cell biologist',
      'ecologist',
      'embryologist',
      'geneticist',
      'histologist',
      'immunologist',
      'marine biologist',
      'microbiologist',
      'molecular biologist',
      'mycologist',
      'parasitologist',
      'pharmacologist',
      'physiologist',
      'protozoologist',
      'toxicologist',
      'virologist',
      'zoologist',
    ],
  },
  {
    code: '21111',
    title: 'Forestry professionals',
    examples: [
      'appraisal forester',
      'consulting forester',
      'district forester',
      'forester',
      'regional inventory officer - forestry',
      'registered professional forester (RPF)',
    ],
  },
  {
    code: '21112',
    title: 'Agricultural representatives, consultants and specialists',
    examples: [
      'agricultural consultant',
      'agricultural extension supervisor',
      'agricultural livestock specialist',
      'agricultural representative',
      'agricultural soil and crop specialist',
      'agriculturist',
      'agrologist',
      'agronomist',
      'consulting agrologist',
      'crop specialist',
      'farm management consultant',
      'field service adviser - agriculture',
      'field service agent - agriculture',
      "growers' advisor",
      'professional agrologist (P.Ag.)',
    ],
  },
  {
    code: '21120',
    title: 'Public and environmental health and safety professionals',
    examples: [
      'environmental health officer',
      'environmental officer',
      'environmental safety advisor',
      'hazardous waste inspector',
      'pollution control inspector',
      'public health inspector',
      'public health inspectors supervisor',
      'public safety consultant',
      'public safety coordinator',
      'public safety officer',
      'restaurant inspector',
      'rodent control inspector',
      'water inspector',
    ],
  },
  {
    code: '21200',
    title: 'Architects',
    examples: [
      'architect',
      'architectural standards specialist',
      'chief architect',
      'consulting architect',
      'industrial and commercial buildings architect',
      'residential architect',
    ],
  },
  { code: '21201', title: 'Landscape architects', examples: ['landscape architect', 'senior landscape architect'] },
  {
    code: '21202',
    title: 'Urban and land use planners',
    examples: [
      'community and urban planner',
      'environmental planner',
      'land use planner',
      'municipal planner',
      'park planner',
      'planner',
      'recreation planner',
      'regional planner',
      'urban planner',
    ],
  },
  {
    code: '21203',
    title: 'Land surveyors',
    examples: ['cadastral surveyor', 'Canada lands surveyor', 'city surveyor', 'land surveyor', 'property surveyor'],
  },
  {
    code: '21210',
    title: 'Mathematicians, statisticians and actuaries',
    examples: [
      'actuary',
      'biostatistician',
      'consulting actuary',
      'demographer',
      'insurance actuary',
      'mathematician',
      'statistical analyst',
      'statistician',
    ],
  },
  {
    code: '21211',
    title: 'Data scientists',
    examples: ['data scientist', 'machine learning engineer', 'machine learning specialist', 'quantitative analyst'],
  },
  {
    code: '21220',
    title: 'Cybersecurity specialists',
    examples: [
      'cybersecurity analyst',
      'informatics security analyst',
      'informatics security consultant',
      'information technology (IT) security specialist',
      'systems security analyst',
    ],
  },
  {
    code: '21221',
    title: 'Business systems specialists',
    examples: [
      'business systems analyst',
      'business systems consultant',
      'business systems specialist - computer systems',
      'information systems business analyst',
      'information technology (IT) business analyst',
    ],
  },
  {
    code: '21222',
    title: 'Information systems specialists',
    examples: [
      'computer systems analyst',
      'informatics consultant',
      'information systems quality assurance (QA) analyst',
      'information technology (IT) consultant',
      'management information systems (MIS) analyst',
      'systems auditor',
      'systems consultant',
    ],
  },
  {
    code: '21223',
    title: 'Database analysts and data administrators',
    examples: [
      'data administrator',
      'data custodian',
      'data dictionary administrator',
      'data warehouse analyst',
      'database administrator (DBA)',
      'database analyst',
      'database architect',
      'technical architect - database',
    ],
  },
  {
    code: '21230',
    title: 'Computer systems developers and programmers',
    examples: [
      'computer programmer',
      'operating systems programmer',
      'programmer analyst',
      'scientific programmer',
      'systems programmer',
    ],
  },
  {
    code: '21231',
    title: 'Software engineers and designers',
    examples: [
      'application architect',
      'computer software engineer',
      'embedded software designer',
      'embedded software engineer',
      'software architect',
      'software design engineer',
      'software design verification engineer',
      'software designer',
      'software testing engineer',
      'telecommunications software engineer',
    ],
  },
  {
    code: '21232',
    title: 'Software developers and programmers',
    examples: [
      'application programmer',
      'computer game developer',
      'electronic business (e-business) software developer',
      'interactive media developer',
      'multimedia developer',
      'software developer',
      'software programmer',
    ],
  },
  {
    code: '21233',
    title: 'Web designers',
    examples: ['Internet site designer', 'Intranet site designer', 'Web designer'],
  },
  {
    code: '21234',
    title: 'Web developers and programmers',
    examples: [
      'electronic business (e-business) Web site developer',
      'Internet site developer',
      'Web developer',
      'Web manager',
      'Web programmer',
      'Web site developer',
    ],
  },
  {
    code: '21300',
    title: 'Civil engineers',
    examples: [
      'bridge engineer',
      'civil engineer',
      'construction engineer',
      'construction project engineer',
      'environmental engineer',
      'geodetic engineer',
      'geomatics engineer',
      'highway engineer',
      'hydraulics engineer',
      'municipal engineer',
      'public works engineer',
      'sanitation engineer',
      'structural engineer',
      'surveying engineer',
      'traffic engineer',
      'transportation engineer',
      'water management engineer',
    ],
  },
  {
    code: '21301',
    title: 'Mechanical engineers',
    examples: [
      'acoustics engineer',
      'automotive engineer',
      'design engineer - mechanical',
      'energy conservation engineer',
      'fluid mechanics engineer',
      'heating, ventilation and air conditioning (HVAC) engineer',
      'mechanical engineer',
      'mechanical maintenance engineer',
      'nuclear engineer',
      'piping engineer',
      'power generation engineer',
      'refrigeration engineer',
      'robotics engineer',
      'thermal design engineer',
      'tool engineer',
    ],
  },
  {
    code: '21310',
    title: 'Electrical and electronics engineers',
    examples: [
      'avionics engineer',
      'control systems engineer',
      'electrical design engineer',
      'electrical distribution planning engineer',
      'electrical engineer',
      'electrical network engineer',
      'electrical process control engineer',
      'electrical systems planning engineer',
      'electronics engineer',
      'electronics test engineer',
      'instrumentation and control engineer',
      'roadway lighting design engineer',
      'television systems engineer',
    ],
  },
  {
    code: '21311',
    title: 'Computer engineers (except software engineers and designers)',
    examples: [
      'computer hardware engineer',
      'fibre-optic network designer',
      'hardware circuit board designer',
      'hardware development engineer',
      'hardware technical architect',
      'network test engineer',
      'systems designer - hardware',
      'telecommunications hardware engineer',
      'wireless communications network engineer',
    ],
  },
  {
    code: '21320',
    title: 'Chemical engineers',
    examples: [
      'adhesives engineer',
      'biochemical engineer',
      'biotechnical engineer',
      'chemical process control engineer',
      'chemical process engineer',
      'chemical project engineer',
      'environmental chemical engineer',
      'industrial hygiene engineer',
      'industrial waste treatment engineer',
      'liquid fuels engineer',
      'petrochemical engineer',
      'polymer engineer',
      'pulp and paper engineer',
      'refinery engineer',
      'waste treatment engineer',
    ],
  },
  {
    code: '21321',
    title: 'Industrial and manufacturing engineers',
    examples: [
      'computer integrated manufacturing (CIM) engineer',
      'fire prevention engineer',
      'industrial engineer',
      'manufacturing engineer',
      'plant engineer',
      'production engineer',
      'quality control engineer',
      'safety engineer',
      'work measurement engineer',
    ],
  },
  {
    code: '21322',
    title: 'Metallurgical and materials engineers',
    examples: [
      'ceramics engineer',
      'corrosion engineer',
      'electrometallurgical engineer',
      'foundry engineer',
      'hydrometallurgical engineer',
      'materials engineer',
      'metallurgical engineer',
      'physical metallurgical engineer',
      'pyrometallurgical engineer',
      'welding engineer',
    ],
  },
  {
    code: '21330',
    title: 'Mining engineers',
    examples: [
      'mine design engineer',
      'mine development engineer',
      'mine layout engineer',
      'mine production engineer',
      'mine safety engineer',
      'mine ventilation engineer',
      'mineral engineer',
      'mining engineer',
    ],
  },
  {
    code: '21331',
    title: 'Geological engineers',
    examples: ['geological engineer', 'geophysical engineer', 'hydrogeological engineer - engineering'],
  },
  {
    code: '21332',
    title: 'Petroleum engineers',
    examples: [
      'oil and gas drilling engineer',
      'oil and gas production engineer',
      'petroleum engineer',
      'petroleum reservoir engineer',
      'petroleum well completion engineer',
      'subsea engineer',
    ],
  },
  {
    code: '21390',
    title: 'Aerospace engineers',
    examples: [
      'aerodynamics engineer',
      'aeronautical engineer',
      'aerospace engineer',
      'aerospace structural engineer',
      'aerospace systems engineer',
      'aerospace test engineer',
      'aircraft design engineer',
      'stress engineer - aerospace',
    ],
  },
  {
    code: '21399',
    title: 'Other professional engineers',
    examples: [
      'agricultural engineer',
      'biomedical engineer',
      'bioresource engineer',
      'engineering physicist',
      'engineering scientist',
      'food processing engineer',
      'marine engineer',
      'naval architect',
      'textile engineer',
    ],
  },
  {
    code: '22100',
    title: 'Chemical technologists and technicians',
    examples: [
      'biochemistry technologist',
      'chemical analyst',
      'chemical engineering technician',
      'chemical engineering technologist',
      'chemical laboratory analyst',
      'chemical research technician',
      'chemical technician',
      'chemical technologist',
      'food technologist',
      'formulation technician',
      'geochemical technician',
      'industrial hygiene technologist',
      'mass spectrometer technician',
      'master dyer - textiles',
      'paint technician',
      'pilot plant technician',
      'quality control technician - chemical processing',
      'quality control technician - food processing',
    ],
  },
  {
    code: '22101',
    title: 'Geological and mineral technologists and technicians',
    examples: [
      'assayer',
      'geological technician',
      'geophysical technologist',
      'groundwater technologist',
      'log technician',
      'marine geoscience technologist',
      'metallurgical technologist',
      'mineralogy technician',
      'mining engineering technologist',
      'mining technologist',
      'petroleum engineering technologist',
      'petroleum technician',
      'petrology technician',
      'reservoir engineering technician',
      'rock mechanics technician',
      'seismic technician',
      'welding technologist',
    ],
  },
  {
    code: '22110',
    title: 'Biological technologists and technicians',
    examples: [
      'agricultural technician',
      'agricultural technologist',
      'agrology technician',
      'aquaculture technician',
      'bacteriological technician',
      'biological laboratory technologist',
      'botanical technician',
      'fish hatchery technician',
      'fisheries technician',
      'food bacteriological technician',
      'microbiology quality control technologist',
      'microbiology technologist (except medical)',
      'plant breeding technician',
      'seed technologist',
      'wildlife biology technician',
    ],
  },
  {
    code: '22111',
    title: 'Agricultural and fish products inspectors',
    examples: [
      'agricultural products inspection supervisor',
      'agricultural products inspector',
      'crop certification inspector',
      'dairy products inspector',
      'fish inspector',
      'fish products inspection supervisor',
      'fish products inspector',
      'fruit and vegetables inspector',
      'grain inspector',
      'livestock inspector',
      'meat inspector',
      'plant protection inspector',
      'poultry inspector',
    ],
  },
  {
    code: '22112',
    title: 'Forestry technologists and technicians',
    examples: [
      'conservation technician - forestry',
      'cruising technician - forestry',
      'enforcement officer - forestry',
      'extension ranger - forestry',
      'fire suppression officer - forestry',
      'forest fire technician',
      'forest inventory resource officer',
      'forest survey technician',
      'forest technician',
      'forestry technician',
      'forestry technologist',
      'resource technician - forestry',
      'scaler technician - logging',
      'scaling coordinator - logging',
      'silviculture technician',
    ],
  },
  {
    code: '22113',
    title: 'Conservation and fishery officers',
    examples: [
      'conservation officer',
      'fish and wildlife officer',
      'fishery officer',
      'forest ranger - wildlife management',
      'game officer',
      'game warden',
      'natural resources officer',
      'park ranger',
    ],
  },
  {
    code: '22114',
    title: 'Landscape and horticulture technicians and specialists',
    examples: [
      'arborist',
      'golf course superintendent',
      'greenskeeper',
      'horticultural technician',
      'horticulture specialist',
      'horticulturist',
      'hydroponics technician',
      'landscape architectural technician',
      'landscape designer',
      'landscape gardener',
      'landscape technician',
      'landscaper',
      'lawn care specialist',
      'tree service technician',
    ],
  },
  {
    code: '22210',
    title: 'Architectural technologists and technicians',
    examples: [
      'architectural design technician',
      'architectural design technologist',
      'architectural technician',
      'architectural technologist',
    ],
  },
  {
    code: '22211',
    title: 'Industrial designers',
    examples: [
      'furniture designer',
      'industrial design consultant',
      'industrial designer',
      'industrial products designer',
      'product designer',
    ],
  },
  {
    code: '22212',
    title: 'Drafting technologists and technicians',
    examples: [
      'architectural draftsperson',
      'building information modelling (BIM) technologist',
      'computer-aided design and drafting (CADD) technologist',
      'computer-assisted drafting (CAD) technician',
      'design and drafting technologist',
      'drafting office supervisor',
      'drafting technician',
      'drafting technologist',
      'draftsperson',
      'electrical draftsperson',
      'electromechanical draftsperson',
      'electronic draftsperson',
      'engineering design and drafting technologist',
      'mechanical draftsperson',
      'steel detailer - drafting',
      'structural draftsperson',
      'structural steel drafter-detailer',
    ],
  },
  {
    code: '22213',
    title: 'Land survey technologists and technicians',
    examples: [
      'engineering survey technologist',
      'geodetic survey technologist',
      'geomatics technologist - land surveying',
      'land survey technician',
      'legal survey technician',
      'topographic survey technician',
      'transit operator - surveying',
    ],
  },
  {
    code: '22214',
    title: 'Technical occupations in geomatics and meteorology',
    examples: [
      'aerial survey technician',
      'aerological technician',
      'avalanche controller',
      'avalanche technician',
      'cartographer',
      'climate data processor',
      'climate service technician',
      'geographic information systems (GIS) technician',
      'ice observer',
      'map editor',
      'mapping technician',
      'meteorological inspector',
      'meteorological technician',
      'photogrammetric technologist',
      'photogrammetrist',
      'remote sensing (RS) technician',
      'softcopy photogrammetrist',
      'surface weather observer',
      'weather station operations technician',
    ],
  },
  {
    code: '22220',
    title: 'Computer network and web technicians',
    examples: [
      'computer network technician',
      'data centre operator',
      'Internet Web site technician',
      'local area network (LAN) administrator',
      'local area network (LAN) technician',
      'network administrator',
      'network support technician',
      'network system administrator',
      'Web technician',
    ],
  },
  {
    code: '22221',
    title: 'User support technicians',
    examples: [
      'call centre agent - technical support',
      'client support representative - systems',
      'computer help desk representative - systems',
      'computer help desk supervisor',
      'hardware installation technician',
      'hardware technical support analyst',
      'help desk technician',
      'software installation technician',
      'software technical support analyst',
      'systems support representative',
      'technical support analyst',
      'technical support supervisor',
      'user support technician',
    ],
  },
  {
    code: '22222',
    title: 'Information systems testing technicians',
    examples: [
      'application tester',
      'application testing technician',
      'software test coordinator',
      'software tester',
      'software testing technician',
      'systems tester',
      'systems testing technician',
      'user acceptance tester',
    ],
  },
  {
    code: '22230',
    title: 'Non-destructive testers and inspectors',
    examples: [
      'acoustic emission technician',
      'aircraft non-destructive inspection technician',
      'eddy current technician',
      'industrial radiographer',
      'infrared thermographer',
      'liquid penetrant testing technician',
      'non-destructive inspector',
      'non-destructive testing maintenance (NDT) technician',
      'pressure vessel tester',
      'radiographic technician - non-destructive testing',
      'ultrasonic testing technician',
      'visual inspection technician - welding',
      'weld tester',
    ],
  },
  {
    code: '22231',
    title: 'Engineering inspectors and regulatory officers',
    examples: [
      'air carrier maintenance inspector',
      'air transport inspector',
      'airworthiness inspector',
      'electricity and gas meter inspector',
      'elevator inspector',
      'engineering inspector',
      'engineering regulatory officer',
      'insurance loss prevention inspector',
      'marine damage surveyor',
      'motor vehicle defects investigator',
      'railway accident investigation officer',
      'weights and measures inspector',
    ],
  },
  {
    code: '22232',
    title: 'Occupational health and safety specialists',
    examples: [
      'health and safety officer',
      'occupational health and safety officer',
      'occupational health supervisor',
      'occupational safety consultant',
      'occupational safety coordinator',
    ],
  },
  {
    code: '22233',
    title: 'Construction inspectors',
    examples: [
      'bridge inspector',
      'building construction inspector',
      'construction inspector',
      'highway construction inspector',
      'home inspector',
      'housing construction inspector',
      'mine construction inspector',
      'plumbing inspector',
      'pre-stressed concrete inspector',
      'safety officer - construction',
    ],
  },
  {
    code: '22300',
    title: 'Civil engineering technologists and technicians',
    examples: [
      'bridge design technician',
      'building materials technician',
      'civil engineering technician',
      'civil engineering technologist',
      'construction specifications writer',
      'construction technologist',
      'foundation technologist',
      'highway technician',
      'municipal engineering assistant',
      'soil technologist - civil engineering',
      'structural design technologist',
      'structural investigator',
    ],
  },
  {
    code: '22301',
    title: 'Mechanical engineering technologists and technicians',
    examples: [
      'aeronautical technologist',
      'heating designer',
      'heating, ventilation and air conditioning (HVAC) technologist',
      'machine designer',
      'marine engineering technologist',
      'mechanical engineering technician',
      'mechanical engineering technologist',
      'mechanical technologist',
      'mould designer',
      'thermal station technician',
      'tool and die designer',
      'tool designer',
    ],
  },
  {
    code: '22302',
    title: 'Industrial engineering and manufacturing technologists and technicians',
    examples: [
      'computer-assisted design/computer-assisted manufacturing (CAD/CAM) programmer',
      'industrial engineering technician',
      'industrial engineering technologist',
      'loss prevention technologist - manufacturing',
      'manufacturing technician',
      'manufacturing technologist',
      'planning technician',
      'plastics manufacturing technician',
      'pulp and paper manufacturing technologist',
      'quality assurance technologist',
      'scheduling technician - manufacturing',
      'textile technologist',
      'time study analyst',
    ],
  },
  {
    code: '22303',
    title: 'Construction estimators',
    examples: [
      'chief estimator - construction',
      'construction estimator',
      'cost estimator - construction',
      'principal estimator - construction',
      'professional quantity surveyor',
      'quantity surveyor - construction',
    ],
  },
  {
    code: '22310',
    title: 'Electrical and electronics engineering technologists and technicians',
    examples: [
      'communications technologist',
      'electrical engineering technician',
      'electrical engineering technologist',
      'electricity distribution network technologist',
      'electronics design technologist',
      'electronics engineering technician',
      'electronics engineering technologist',
      'electronics manufacturing technician',
      'electronics manufacturing technologist',
      'lighting technologist',
      'metering technologist',
      'microwave maintenance technician',
      'production support technician - electronics manufacturing',
    ],
  },
  {
    code: '22311',
    title: 'Electronic service technicians (household and business equipment)',
    examples: [
      'alarm system technician',
      'audio-video service technician',
      'computer service technician',
      'electronic products field service technician',
      'electronic service technician apprentice',
      'electronic service technician supervisor',
      'office equipment service technician',
      'photocopy machine technician',
      'radio and television service technician',
      'satellite antenna servicer',
    ],
  },
  {
    code: '22312',
    title: 'Industrial instrument technicians and mechanics',
    examples: [
      'apprentice industrial instrument mechanic',
      'industrial instrument mechanic',
      'industrial instrument technician',
      'industrial instrumentation technician',
      'process control equipment mechanic',
    ],
  },
  {
    code: '22313',
    title: 'Aircraft instrument, electrical and avionics mechanics, technicians and inspectors',
    examples: [
      'aircraft electrical technician',
      'aircraft electrician',
      'aircraft instrument inspector',
      'aircraft instrument mechanic',
      'aircraft instrument technician',
      'aircraft maintenance engineer (AME) - avionics',
      'avionics inspector',
      'avionics maintenance technician',
      'avionics technician',
      'instrument overhaul and repair mechanic - avionics',
    ],
  },
  {
    code: '30010',
    title: 'Managers in health care',
    examples: [
      'chief of anesthesia',
      'chief of emergency medicine',
      'chief of medical staff',
      'director of clinical medicine',
      'director of dietetics',
      'director of laboratory medicine',
      'director of nursing - medical services',
      'director of occupational therapy',
      'director of physiotherapy',
      'director of surgery',
      'home care services director - medical services',
      'medical clinic director',
      'mental health residential care program manager',
      'rehabilitation services director',
    ],
  },
  {
    code: '31100',
    title: 'Specialists in clinical and laboratory medicine',
    examples: [
      'anatomical pathologist',
      'anesthetist',
      'cardiologist',
      'dermatologist',
      'diagnostic radiologist',
      'emergency physician',
      'endocrinologist',
      'gastroenterologist',
      'general pathologist',
      'geriatrician',
      'hematologist',
      'hematopathologist',
      'medical biochemist - physician',
      'medical microbiologist',
      'nephrologist',
      'neurologist',
      'neuropathologist',
      'oncologist',
      'pediatrician',
      'physiatrist',
      'pneumologist',
      'psychiatrist',
      'radiation oncologist',
      'respirologist',
      'rheumatologist',
    ],
  },
  {
    code: '31101',
    title: 'Specialists in surgery',
    examples: [
      'cardiac surgeon',
      'general surgeon',
      'neurosurgeon',
      'obstetrician-gynecologist',
      'ophthalmologist',
      'orthopedic surgeon',
      'orthopedist',
      'otorhinolaryngologist',
      'pediatric surgeon',
      'plastic surgeon',
      'thoracic surgeon',
      'urologist',
      'vascular surgeon',
    ],
  },
  {
    code: '31102',
    title: 'General practitioners and family physicians',
    examples: ['family physician', 'general practice resident', 'general practitioner (GP)', 'medical doctor'],
  },
  {
    code: '31103',
    title: 'Veterinarians',
    examples: [
      'farm veterinarian',
      'small animal veterinary specialist',
      'veterinarian',
      'veterinary inspector',
      'veterinary pathologist',
      'veterinary physiologist',
      'veterinary surgeon',
      'zoo veterinarian',
    ],
  },
  {
    code: '31110',
    title: 'Dentists',
    examples: [
      'dentist',
      'endodontist',
      'oral and maxillofacial surgeon',
      'oral pathologist',
      'oral radiologist',
      'orthodontist',
      'pediatric dentist',
      'periodontist',
      'prosthodontist',
      'public health dentist',
    ],
  },
  { code: '31111', title: 'Optometrists', examples: ['doctor of optometry (OD)', 'optometrist'] },
  {
    code: '31112',
    title: 'Audiologists and speech-language pathologists',
    examples: [
      'audiologist',
      'certified audiologist',
      'clinical audiologist',
      'educational speech-language pathologist',
      'research audiologist',
      'speech therapist',
      'speech-language clinician',
    ],
  },
  {
    code: '31120',
    title: 'Pharmacists',
    examples: [
      'clinical pharmacist',
      'community pharmacist',
      'hospital pharmacist',
      'industrial pharmacist',
      'pharmacist',
      'retail pharmacist',
    ],
  },
  {
    code: '31121',
    title: 'Dietitians and nutritionists',
    examples: [
      'administrative dietitian',
      'clinical dietitian',
      'community nutritionist',
      'consultant dietitian',
      'dietitian',
      'dietitian-nutritionist',
      'nutrition specialist',
      'nutritionist',
      'public health dietitian',
      'public health nutritionist',
      'registered dietitian (RD)',
      'research dietitian',
    ],
  },
  {
    code: '31200',
    title: 'Psychologists',
    examples: ['clinical psychologist', 'psychological associate', 'psychologist', 'research psychologist'],
  },
  { code: '31201', title: 'Chiropractors', examples: ['chiropractor'] },
  {
    code: '31202',
    title: 'Physiotherapists',
    examples: ['physical therapist', 'physiotherapist', 'registered physiotherapist', 'research physiotherapist'],
  },
  {
    code: '31203',
    title: 'Occupational therapists',
    examples: [
      'case manager occupational therapist',
      'clinical occupational therapist',
      'community occupational therapist',
      'occupational therapist (OT)',
      'occupational therapy rehabilitation consultant',
      'research and development occupational therapist',
    ],
  },
  {
    code: '31204',
    title: 'Kinesiologists and other professional occupations in therapy and assessment',
    examples: [
      'athletic therapist',
      'certified athletic therapist (CAT)',
      'certified kinesiologist',
      'exercise physiologist',
      'human kineticist',
      'kinesiologist',
      'recreational therapist',
    ],
  },
  {
    code: '31209',
    title: 'Other professional occupations in health diagnosing and treating',
    examples: [
      'chiropodist',
      'doctor of naturopathic medicine',
      'doctor of osteopathic medicine',
      'doctor of podiatric medicine (D.P.M.)',
      'foot specialist',
      'naturopathic doctor (ND)',
      'osteopathic physician',
      'podiatrist',
    ],
  },
  {
    code: '31300',
    title: 'Nursing coordinators and supervisors',
    examples: [
      'nursing care coordinator',
      'nursing services coordinator',
      'nursing supervisor',
      'patient care coordinator - nursing',
      'psychiatric nursing supervisor',
      'public health nursing supervisor',
    ],
  },
  {
    code: '31301',
    title: 'Registered nurses and registered psychiatric nurses',
    examples: [
      'clinical nurse',
      'community health nurse',
      'critical care nurse',
      'emergency care nurse',
      'intensive care nurse',
      'nurse researcher',
      'nursing consultant',
      'occupational health nurse',
      'private duty nurse',
      'public health nurse',
      'registered nurse (R.N.)',
      'registered psychiatric nurse (R.P.N.)',
    ],
  },
  {
    code: '31302',
    title: 'Nurse practitioners',
    examples: ['extended class registered nurse', 'nurse practitioner', 'pediatric nurse practitioner'],
  },
  {
    code: '31303',
    title: 'Physician assistants, midwives and allied health professionals',
    examples: ['genetic counsellor', 'midwife', 'orthoptist', 'pathology assistant', 'physician assistant'],
  },
  {
    code: '32100',
    title: 'Opticians',
    examples: [
      'contact lens fitter',
      'contact lens practitioner',
      'dispensing optician',
      'licensed optician',
      'optician',
    ],
  },
  {
    code: '32101',
    title: 'Licensed practical nurses',
    examples: [
      'graduate nursing assistant',
      'licensed practical nurse (L.P.N.)',
      'operating room technician',
      'registered nursing assistant (R.N.A.)',
    ],
  },
  {
    code: '32102',
    title: 'Paramedical occupations',
    examples: [
      'advanced care paramedic',
      'ambulance attendant',
      'ambulance services supervisor',
      'critical care paramedic',
      'emergency medical technician (EMT)',
      'emergency medical technologist - paramedic (EMT-P)',
      'paramedic',
      'paramedic emergency medical technician',
      'primary care paramedic',
    ],
  },
  {
    code: '32103',
    title: 'Respiratory therapists, clinical perfusionists and cardiopulmonary technologists',
    examples: [
      'anesthesia assistant',
      'cardiopulmonary technologist',
      'cardiovascular perfusion supervisor',
      'cardiovascular perfusionist',
      'certified clinical perfusionist (CCP)',
      'chief respiratory technologist',
      'clinical perfusionist',
      'perfusionist',
      'registered respiratory therapist (RRT)',
      'respiratory therapist',
      'respiratory therapy chief',
      'respiratory therapy clinical instructor',
    ],
  },
  {
    code: '32104',
    title: 'Animal health technologists and veterinary technicians',
    examples: [
      'animal health technician',
      'animal health technologist',
      'laboratory animal technician',
      'registered veterinary technician (RVT)',
      'veterinarian assistant',
      'veterinary technician',
      'veterinary technologist',
    ],
  },
  {
    code: '32109',
    title: 'Other technical occupations in therapy and assessment',
    examples: [
      'audiology technician',
      'communicative disorders assistant',
      'hearing instrument practitioner',
      'hearing instrument specialist',
      'occupational therapy assistant',
      'ophthalmic technician',
      'ophthalmic technologist',
      'physical rehabilitation therapist',
      'physical therapist assistant (PTA)',
      'physiotherapy assistant',
      'physiotherapy technician',
      'rehabilitation assistant',
      'speech aide',
      'speech technician',
      'speech therapy assistant',
    ],
  },
  { code: '32110', title: 'Denturists', examples: ['dental mechanic', 'denturist', 'denturologist'] },
  {
    code: '32111',
    title: 'Dental hygienists and dental therapists',
    examples: ['dental hygienist', 'dental nurse', 'dental therapist', 'registered dental hygienist'],
  },
  {
    code: '32112',
    title: 'Dental technologists and technicians',
    examples: [
      'dental technician',
      'dental technician supervisor',
      'dental technologist',
      'registered dental technician (RDT)',
      'registered dental technologist (RDT)',
    ],
  },
  {
    code: '32120',
    title: 'Medical laboratory technologists',
    examples: [
      'clinical genetics technologist',
      'cytogenetics technologist - medical laboratory',
      'histology technologist',
      'medical laboratory cytotechnologist',
      'medical laboratory technologist',
      'medical laboratory technologists supervisor',
    ],
  },
  {
    code: '32121',
    title: 'Medical radiation technologists',
    examples: [
      'mammography technician',
      'nuclear medicine clinical instructor',
      'nuclear medicine technologist',
      'nuclear medicine technologists supervisor',
      'radiation oncology technologist',
      'radiation therapist',
      'radiation therapy clinical instructor',
      'radiation therapy technologist (RTT)',
      'radiography technologist',
      'radiological technologist',
      'radiotherapy technician',
      'X-ray (radiology) technician',
    ],
  },
  {
    code: '32122',
    title: 'Medical sonographers',
    examples: [
      'diagnostic medical sonography instructor',
      'medical sonographer',
      'medical sonographers supervisor',
      'registered diagnostic medical sonographer (RDMS)',
      'ultrasound technologist',
    ],
  },
  {
    code: '32123',
    title: 'Cardiology technologists and electrophysiological diagnostic technologists',
    examples: [
      'cardiac stress technologist',
      'cardiology supervisor',
      'cardiology technologist',
      'electrocardiographic (ECG) technologist',
      'electrocardiography technologist',
      'electroencephalograph (EEG) technologist',
      'electromyography (EMG) technologist',
      'electroneurodiagnostic (END) technologist',
      'electroneurophysiology (ENP) technologist',
    ],
  },
  { code: '32124', title: 'Pharmacy technicians', examples: ['pharmacy technician', 'pharmacy technician supervisor'] },
  {
    code: '32129',
    title: 'Other medical technologists and technicians',
    examples: [
      'dietary technician',
      'food and nutrition technician - dietetics',
      'ocularist',
      'ocularist technician',
      'orthotic technician',
      'orthotist',
      'prosthetic technician',
      'prosthetist',
    ],
  },
  {
    code: '32200',
    title: 'Traditional Chinese medicine practitioners and acupuncturists',
    examples: ['acupuncturist', 'traditional Chinese medicine practitioner'],
  },
  {
    code: '32201',
    title: 'Massage therapists',
    examples: ['massage therapist (MT)', 'registered massage practitioner', 'registered massage therapist (RMT)'],
  },
  {
    code: '32209',
    title: 'Other practitioners of natural healing',
    examples: [
      'aromatherapist',
      'ayurvedic practitioner',
      'herbalist',
      'holistic nutritionist',
      'holistic practitioner',
      'homeopath',
      'hypnotherapist',
      'naturotherapist',
      'osteopathic manual practitioner',
      'reflexologist',
      'rolfer',
    ],
  },
  {
    code: '33100',
    title: 'Dental assistants and dental laboratory assistants',
    examples: [
      'ceramic denture moulder',
      'certified dental assistant',
      'certified intra-oral dental assistant',
      'dental assistant',
      'dental laboratory assistant',
      'denture finisher',
      'denture wax pattern former',
      'orthodontic band maker',
      'registered dental assistant',
    ],
  },
  {
    code: '33101',
    title: 'Medical laboratory assistants and related technical occupations',
    examples: [
      'medical laboratory aide',
      'medical laboratory assistant',
      'medical laboratory technician',
      'phlebotomist',
      'phlebotomy aide',
    ],
  },
  {
    code: '33102',
    title: 'Nurse aides, orderlies and patient service associates',
    examples: [
      'health care aide',
      'hospital attendant',
      'long term care aide',
      'nurse aide',
      'nursing attendant',
      'orderly',
      'patient care aide',
      'patient service associate',
      'personal care attendant - medical',
      'psychiatric aide',
      'resident care aide - medical',
    ],
  },
  {
    code: '33103',
    title: 'Pharmacy technical assistants and pharmacy assistants',
    examples: ['pharmacy aide', 'pharmacy assistant', 'pharmacy technical assistant'],
  },
  {
    code: '33109',
    title: 'Other assisting occupations in support of health services',
    examples: [
      'audiometric assistant',
      'audiometric technician',
      'autopsy assistant',
      'blood donor clinic assistant',
      'cast room technician',
      'chiropractic assistant',
      'clinical laboratory helper',
      'medical device reprocessing technician',
      'morgue attendant',
      'ophthalmic assistant',
      'ophthalmic laboratory technician - retail',
      'ophthalmic lens grinder',
      'ophthalmologist assistant',
      'optical laboratory assistant',
      'optometrist assistant',
      'orthopedic technologist',
      'rehabilitation aide',
      'therapy aide',
    ],
  },
  {
    code: '40010',
    title: 'Government managers - health and social policy development and program administration',
    examples: [
      'city medical officer of health - government services',
      'health information and promotion director - government services',
      'health program operations manager - government services',
      'housing policy and development director - government services',
      'immigration and settlement director - government services',
      'social services director - government services',
      'social services planning manager - government services',
    ],
  },
  {
    code: '40011',
    title: 'Government managers - economic analysis, policy development and program administration',
    examples: [
      'border services manager',
      'business development director - government services',
      'customs manager - government services',
      'economic analysis chief - government services',
      'economic development director - government services',
      'economic policy analysis director - government services',
      'energy market analysis director - government services',
      'excise tax programs director - government services',
      'farm products marketing director - government services',
      'forest resource analysis chief - government services',
      'grain market analysis manager - government services',
      'international trade policy manager - government services',
      'revenue programs manager - government services',
      'tax policy research manager - government services',
      'taxation manager',
      'tourism development manager - government services',
      'trade development director',
    ],
  },
  {
    code: '40012',
    title: 'Government managers - education policy development and program administration',
    examples: [
      'education curriculum development director',
      'education director',
      'education policy analysis and research director',
      'education program administration manager',
      'school inspection director - public administration',
    ],
  },
  {
    code: '40019',
    title: 'Other managers in public administration',
    examples: [
      'clerk of the committee - Legislative Assembly',
      'elections planning director',
      'federal-provincial relations director',
      'intergovernmental affairs director',
    ],
  },
  {
    code: '40020',
    title: 'Administrators - post-secondary education and vocational training',
    examples: [
      'aviation school manager',
      'business school manager',
      'community college dean',
      'community college registrar',
      'dean - university or college',
      'dean of adult education',
      'faculty of science dean',
      'school of nursing dean',
      'school of technology dean',
      'student activities dean',
      'trade school manager',
      'university registrar',
    ],
  },
  {
    code: '40021',
    title: 'School principals and administrators of elementary and secondary education',
    examples: [
      'board of education administrator',
      'chief superintendent - schools',
      'director of education - correctional institution',
      'director of school for the hearing impaired',
      'district school superintendent',
      'private school headmaster/mistress',
      'school principal',
      'school vice-principal',
      'secondary school principal',
      'superintendent of elementary education',
      'superintendent of secondary schools',
      'superintendent of special education',
    ],
  },
  {
    code: '40030',
    title: 'Managers in social, community and correctional services',
    examples: [
      'assistant vice-president - health, education, social and community services and membership organizations',
      'association director',
      'child welfare services administrator',
      "children's aid society director",
      'community centre director',
      'correctional institution director',
      'correctional treatment and training director',
      'detention centre director',
      'environmental group director',
      'family services area manager',
      'income maintenance director',
      'labour organization manager',
      'membership services manager',
      'political organization manager',
      'prison warden',
      'social assistance director',
      'social services regional administrator',
      'social work director',
      'trade association manager',
      'volunteer services director',
    ],
  },
  {
    code: '40040',
    title: 'Commissioned police officers and related occupations in public protection services',
    examples: [
      'chief superintendent',
      'deputy police chief',
      'harbour police chief',
      'police chief',
      'police lieutenant',
      'police superintendent',
      'railway police chief',
      'Royal Canadian Mounted Police (RCMP) commissioner',
    ],
  },
  {
    code: '40041',
    title: 'Fire chiefs and senior firefighting officers',
    examples: ['deputy fire chief', 'district fire chief', 'fire chief'],
  },
  {
    code: '40042',
    title: 'Commissioned officers of the Canadian Armed Forces',
    examples: [
      'admiral',
      'artillery officer',
      'brigadier general',
      'colonel',
      'commodore',
      'lieutenant commander',
      'major',
      'naval cadet',
      'officer cadet',
      'search and rescue captain',
    ],
  },
  {
    code: '41100',
    title: 'Judges',
    examples: [
      'chief justice',
      'county court judge',
      "Court of Queen's Bench justice",
      'district court judge',
      'family court judge',
      'federal trial court justice',
      'provincial court of appeal justice',
      'small claims court judge',
      'superior court justice',
      'Supreme Court justice',
    ],
  },
  {
    code: '41101',
    title: 'Lawyers and Quebec notaries',
    examples: [
      'barrister',
      'corporate counsel',
      'Crown Attorney',
      'law partner',
      'lawyer',
      'legal advisor',
      'legal counsel',
      'legislative counsel',
      'notary (Quebec)',
      'prosecutor',
      'solicitor',
    ],
  },
  {
    code: '41200',
    title: 'University professors and lecturers',
    examples: [
      'botany assistant professor - university',
      'computer science professor - university',
      'engineering instructor - university',
      'English professor - university',
      'food sciences department chairperson - university',
      'French language professor - university',
      'geography department head - university',
      'lecturer - university',
      'linguistics associate professor',
      'physics department chairperson - university',
      'professor of medicine - university',
      'university professor',
    ],
  },
  {
    code: '41201',
    title: 'Post-secondary teaching and research assistants',
    examples: [
      'college laboratory assistant',
      'college teaching assistant',
      'graduate assistant - university',
      'post-secondary research assistant',
      'university research assistant',
    ],
  },
  {
    code: '41210',
    title: 'College and other vocational instructors',
    examples: [
      'college teacher',
      'commercial art instructor',
      'community college teacher',
      'company trainer',
      'computer training instructor',
      'department chairperson - college',
      'department head - general and vocational college (CEGEP)',
      'firefighting instructor',
      'general and vocational college (CEGEP) teacher',
      'instructor - technology institute',
      'language school instructor',
      'lecturer - college',
      'legal assistant program teacher',
      'teacher - institute of technology',
      'training officer - company',
      'vocational institute teacher',
    ],
  },
  {
    code: '41220',
    title: 'Secondary school teachers',
    examples: [
      'adult education teacher - secondary school',
      'biology teacher - secondary school',
      'commerce teacher - secondary school',
      'English as a second language (ESL) high school teacher',
      'English teacher - secondary school',
      'French as a second language teacher - secondary school',
      'history teacher - secondary school',
      'librarian-teacher - high school',
      'remedial teacher - secondary school',
      'secondary school department head',
      'secondary school teacher',
      'special education teacher - secondary school',
      'supply high school teacher',
      'trades instructor - secondary school',
      'vocational teacher - secondary school',
    ],
  },
  {
    code: '41221',
    title: 'Elementary school and kindergarten teachers',
    examples: [
      'English as a second language elementary school teacher',
      'French as a second language elementary school teacher',
      'French immersion teacher - elementary school',
      'kindergarten teacher',
      'primary school teacher',
      'remedial teacher - elementary school',
      'special education teacher - elementary school',
      'special education teacher - primary school',
      'supply teacher - elementary school',
      'teacher-librarian - elementary school',
    ],
  },
  {
    code: '41300',
    title: 'Social workers',
    examples: [
      'coordinator of social work',
      'medical social worker',
      'psychiatric social worker',
      'social work supervisor',
      'social worker',
    ],
  },
  {
    code: '41301',
    title: 'Therapists in counselling and related specialized therapies',
    examples: [
      'addictions counsellor',
      'art therapist',
      'bereavement counsellor',
      'child and youth counsellor',
      'drama therapist',
      'family counsellor',
      'marriage counsellor',
      'movement therapist',
      'music therapist',
      'psychotherapist',
      'registered clinical counsellor',
      'registered dance therapist',
      'registered marriage and family therapist',
      'sex therapist',
    ],
  },
  {
    code: '41302',
    title: 'Religious leaders',
    examples: [
      'archbishop',
      'bishop',
      'cardinal',
      'chaplain',
      'evangelist',
      'granthi',
      'imam',
      'minister',
      'moderator - religion',
      'pastor',
      'priest',
      'rabbi',
    ],
  },
  {
    code: '41310',
    title: 'Police investigators and other investigative occupations',
    examples: [
      'detective - police',
      'detective sergeant - police',
      'drug investigator - police',
      'police investigator',
    ],
  },
  { code: '41311', title: 'Probation and parole officers', examples: ['parole officer', 'probation officer'] },
  {
    code: '41320',
    title: 'Educational counsellors',
    examples: [
      'academic counsellor',
      'career counsellor - education',
      'Indigenous advisor - college',
      'school counsellor',
      'student services counsellor',
    ],
  },
  {
    code: '41321',
    title: 'Career development practitioners and career counsellors (except education)',
    examples: [
      'career counsellor (except education)',
      'career development counsellor',
      'career development practitioner',
      'outplacement counsellor',
      'rehabilitation counsellor',
      'relocation consultant',
      'vocational rehabilitation counsellor',
    ],
  },
  {
    code: '41400',
    title: 'Natural and applied science policy researchers, consultants and program officers',
    examples: [
      'certified ergonomist',
      'emergency management analyst',
      'emergency preparedness planner',
      'energy policy analyst',
      'environmental impact analyst',
      'environmental issues lobbyist',
      'environmental program development supervisor',
      'ergonomics specialist',
      'fisheries analyst',
      'industrial hygienist',
      'natural and applied sciences program officer',
      'natural resources policy analyst',
      'occupational hygienist',
      'recycling program coordinator',
      'scientific consultant',
      'technology transfer officer',
      'transportation safety analyst',
    ],
  },
  {
    code: '41401',
    title: 'Economists and economic policy researchers and analysts',
    examples: [
      'agricultural economist',
      'economic advisor',
      'economic analyst',
      'economic policy analyst',
      'economist',
      'energy economist',
      'financial economist',
      'industrial economist',
      'international trade economist',
      'investment economist',
      'labour economist',
      'natural resources economist',
      'tax economist',
      'trade economist',
    ],
  },
  {
    code: '41402',
    title: 'Business development officers and market researchers and analysts',
    examples: [
      'business development officer',
      'community economic development consultant',
      'economic development officer',
      'industrial development officer',
      'market researcher',
      'marketing analyst',
      'marketing researcher',
      'regional development analyst',
      'tourism development officer',
      'tourism industry consultant',
    ],
  },
  {
    code: '41403',
    title: 'Social policy researchers, consultants and program officers',
    examples: [
      'Aboriginal issues lobbyist',
      'child welfare policy analyst',
      'community policing program consultant',
      'community social development officer',
      'consumer advisor',
      'employment equity policy consultant',
      'home economist',
      'housing policy analyst',
      'human rights officer',
      'immigration and citizenship consultant',
      'immigration policy analyst',
      'Indigenous affairs officer',
      'international aid and development project officer',
      'labour policy analyst',
      'social policy researcher',
      'social services planner',
      'social survey researcher (except statistician)',
    ],
  },
  {
    code: '41404',
    title: 'Health policy researchers, consultants and program officers',
    examples: [
      'child health care programs planning officer',
      'drug and alcohol abuse consultant',
      'health care consultant',
      'health care planner',
      'health policy research analyst',
      'health promotion program officer',
      'health services researcher',
      'mental health programs consultant',
      'policy development officer - nursing homes',
    ],
  },
  {
    code: '41405',
    title: 'Education policy researchers, consultants and program officers',
    examples: [
      'curriculum developer',
      'curriculum planner',
      'education consultant',
      'education outreach program coordinator',
      'education policy officer',
      'education policy supervisor',
      'education program coordinator',
      'education program officer',
      'education researcher',
      'special education coordinator',
    ],
  },
  {
    code: '41406',
    title: 'Recreation, sports and fitness policy researchers, consultants and program officers',
    examples: [
      'fitness policy analyst',
      'recreation consultant',
      'recreologist',
      'sports analyst',
      'sports and recreation consultant',
      'sports consultant',
      'sports policy analyst',
      'sports program supervisor',
    ],
  },
  {
    code: '41407',
    title: 'Program officers unique to government',
    examples: [
      'attaché',
      'elections officer',
      'federal-provincial relations officer',
      'foreign service officer',
      'intergovernmental affairs officer',
      'office of the Speaker officer',
      'protocol officer',
      'returning officer',
      'royal commission officer',
      'standing or select committee officer',
      'tribunal officer',
    ],
  },
  {
    code: '41409',
    title: 'Other professional occupations in social science',
    examples: [
      'anthropologist',
      'archaeologist',
      'geographer',
      'gerontologist',
      'historian',
      'linguist',
      'political scientist',
      'psychometrist',
      'sociologist',
    ],
  },
  {
    code: '42100',
    title: 'Police officers (except commissioned)',
    examples: [
      'community relations officer - police',
      'constable',
      'crime prevention constable',
      'harbour police officer',
      'highway patrol officer',
      'police cadet',
      'police officer',
      'police sergeant',
      'railway police officer',
      'Royal Canadian Mounted Police (RCMP) officer',
    ],
  },
  {
    code: '42101',
    title: 'Firefighters',
    examples: [
      'airport firefighter',
      'fire captain',
      'firefighter',
      'firefighter lieutenant',
      'industrial firefighter',
      'shipboard firefighter',
    ],
  },
  {
    code: '42102',
    title: 'Specialized members of the Canadian Armed Forces',
    examples: [
      'air weapons systems technician',
      'ammunition technician',
      'weapons engineering technician',
      'weapons technician',
    ],
  },
  {
    code: '42200',
    title: 'Paralegals and related occupations',
    examples: [
      'commercial law clerk',
      'commissioner of affidavits',
      'commissioner of marriages',
      'corporate paralegal',
      'family law paralegal',
      'independent paralegal',
      'judicial officer',
      'justice of the peace',
      'land titles examiner',
      'legal researcher',
      'notary public',
      'paralegal',
      'real estate law clerk',
      'registrar of bankruptcy',
      'title searcher',
      'trademark agent',
    ],
  },
  {
    code: '42201',
    title: 'Social and community service workers',
    examples: [
      'Aboriginal outreach worker',
      'addictions worker',
      'child and youth worker',
      'community development worker',
      'community service worker',
      'crisis intervention worker',
      'developmental service worker',
      'drop-in centre worker',
      'family service worker',
      'group home worker',
      'income maintenance officer - social services',
      'life skills instructor',
      'mental health worker',
      'rehabilitation worker - social services',
      'social services worker',
      'veteran services officer',
      'welfare and compensation officer',
      "women's shelter supervisor",
      'youth worker',
    ],
  },
  {
    code: '42202',
    title: 'Early childhood educators and assistants',
    examples: [
      'child care worker assistant',
      'daycare helper',
      'daycare supervisor',
      'daycare worker',
      'early childhood assistant',
      'early childhood education worker',
      'early childhood educator - preschool',
      'early childhood educator (ECE)',
      'early childhood educator (ECE) assistant',
      'early childhood program staff assistant',
      'early childhood supervisor',
      'preschool helper',
      'preschool supervisor',
    ],
  },
  {
    code: '42203',
    title: 'Instructors of persons with disabilities',
    examples: [
      'braille instructor',
      'instructor of persons who are deaf',
      'instructor of persons who are hard of hearing',
      'instructor of persons with a learning disability',
      'instructor of persons with a mobility impairment',
      'instructor of persons with a visual impairment',
      'instructor of persons with special needs',
      'lip-reading instructor',
      'orientation and mobility instructor',
      'sign language instructor',
      'teacher for persons with intellectual disabilities',
    ],
  },
  {
    code: '42204',
    title: 'Religion workers',
    examples: [
      'brother/sister - religion',
      'Christian science practitioner',
      'deacon',
      'missionary',
      'monk',
      'nun',
      'pastoral animator',
      'religious education worker',
      'Salvation Army field worker',
    ],
  },
  {
    code: '43100',
    title: 'Elementary and secondary school teacher assistants',
    examples: [
      'educational assistant',
      'educational resources assistant',
      'homework assistant',
      'remedial education aide',
      'special education assistant',
    ],
  },
  {
    code: '43109',
    title: 'Other instructors',
    examples: [
      "driver's licence examiner",
      'driving instructor',
      'modelling instructor',
      'motorcycle driving instructor',
      'sewing instructor - non-vocational',
    ],
  },
  {
    code: '43200',
    title: 'Sheriffs and bailiffs',
    examples: ['bailiff', 'deputy sheriff', 'sheriff', "sheriff's bailiff", "sheriff's officer"],
  },
  {
    code: '43201',
    title: 'Correctional service officers',
    examples: [
      'correctional facility guard',
      'correctional officers supervisor',
      'correctional service officer',
      'primary worker/kimisinaw - correctional service',
      'prison guard',
    ],
  },
  {
    code: '43202',
    title: 'By-law enforcement and other regulatory officers',
    examples: [
      'animal control officer',
      'by-law enforcement officer',
      'commercial transport inspector',
      'garbage collection inspector',
      'liquor licence inspector',
      'parking control officer',
      'property standards inspector',
      'taxi inspector',
      'zoning inspector',
    ],
  },
  {
    code: '43203',
    title: 'Border services, customs, and immigration officers',
    examples: [
      'border services officer (BSO)',
      'customs inspector',
      'customs officer',
      'immigration agent - government services',
      'immigration examining officer',
    ],
  },
  {
    code: '43204',
    title: 'Operations members of the Canadian Armed Forces',
    examples: [
      'airborne electronic sensor operator',
      'assault pioneer',
      'combat engineer',
      'naval combat information operator',
      'sonar operator',
    ],
  },
  {
    code: '44100',
    title: 'Home child care providers',
    examples: [
      'babysitter',
      'child care live-in caregiver',
      'child care provider - private home',
      'nanny',
      "parent's helper",
    ],
  },
  {
    code: '44101',
    title: 'Home support workers, caregivers and related occupations',
    examples: [
      'attendant for persons with disabilities - home care',
      'family caregiver',
      'home support worker',
      'live-in caregiver',
      'live-in caregiver - seniors',
      'personal aide - home support',
      'personal care attendant - home care',
      'respite worker - home support',
    ],
  },
  {
    code: '44200',
    title: 'Primary combat members of the Canadian Armed Forces',
    examples: ['armoured soldier - Canadian Army', 'infanteer', 'infantry soldier', 'soldier - Canadian Army'],
  },
  {
    code: '45100',
    title: 'Student monitors, crossing guards and related occupations',
    examples: ['bus monitor', 'crossing guard', 'lunch monitor', 'lunch supervisor', 'school lunchroom supervisor'],
  },
  {
    code: '50010',
    title: 'Library, archive, museum and art gallery managers',
    examples: [
      'archives director',
      'art gallery manager',
      'assistant director of archives',
      'chief librarian',
      'library director',
      'museum administrator',
      'museum executive director',
    ],
  },
  {
    code: '50011',
    title: 'Managers - publishing, motion pictures, broadcasting and performing arts',
    examples: [
      'ballet company director',
      'book publishing manager',
      'broadcasting manager',
      'broadcasting station manager',
      'copyright manager - publishing',
      'dance company director',
      'editor-in-chief',
      'managing editor',
      'opera company director',
      'publishing manager',
      'radio programming manager',
      'radio station manager',
      'television station manager',
      'theatre company manager',
      'video production company manager',
    ],
  },
  {
    code: '50012',
    title: 'Recreation, sports and fitness program and service directors',
    examples: [
      'director of recreation',
      'professional football team manager',
      'professional hockey team manager',
      'professional track and field team manager',
      'sports administration director - government',
      'sports association director',
      'sports federation director',
      'sports program director',
      "YMCA (Young Men's Christian Association) programs director",
    ],
  },
  {
    code: '51100',
    title: 'Librarians',
    examples: [
      'bibliographer',
      'cataloguer - library',
      'cybrarian',
      'liaison librarian',
      'librarian',
      'library consultant',
      'library supervisor',
    ],
  },
  {
    code: '51101',
    title: 'Conservators and curators',
    examples: [
      'art gallery conservator',
      'art objects conservator',
      'conservator - museum',
      'curator',
      'historical artifact conservator',
      'natural history museum curator',
    ],
  },
  { code: '51102', title: 'Archivists', examples: ['archivist', 'historical archivist', 'multimedia archivist'] },
  {
    code: '51110',
    title: 'Editors',
    examples: [
      'advertising editor',
      'associate editor',
      'contributing editor',
      'copy editor',
      'editor',
      'editorial consultant',
      'literary editor',
      'manuscript editor',
      'medical editor',
      'news editor',
      'news service editor',
      'sports editor',
      'technical editor',
    ],
  },
  {
    code: '51111',
    title: 'Authors and writers (except technical)',
    examples: [
      'advertising copywriter',
      'copywriter',
      'essayist',
      'interactive media writer',
      'literary writer',
      'novelist',
      'playwright',
      'poet',
      'script writer',
      'speech writer',
      'writer',
    ],
  },
  { code: '51112', title: 'Technical writers', examples: ['medical writer', 'scientific writer', 'technical writer'] },
  {
    code: '51113',
    title: 'Journalists',
    examples: [
      'book reviewer',
      'broadcast journalist',
      'columnist',
      'correspondent',
      'cyberjournalist',
      'investigative reporter',
      'journalist',
      'network reporter',
      'news commentator',
      'newspaper critic',
      'reporter',
    ],
  },
  {
    code: '51114',
    title: 'Translators, terminologists and interpreters',
    examples: [
      'community interpreter',
      'conference interpreter',
      'court interpreter',
      'interpreter',
      'legal terminologist',
      'literary translator',
      'localiser - linguistics',
      'medical terminologist',
      'sign language interpreter',
      'terminologist',
      'translator',
      'translator adaptor',
      'translator-reviser',
    ],
  },
  {
    code: '51120',
    title: 'Producers, directors, choreographers and related occupations',
    examples: [
      'artistic director - motion picture',
      'choreographer',
      'cyber-choreographer',
      'director - motion picture',
      'director of photography',
      'filmmaker',
      'multimedia audio producer',
      'producer - performing arts',
      'producer-director',
      'radio producer',
      'record producer',
      'stage director - performing arts',
      'technical director - broadcasting',
      'television producer',
    ],
  },
  {
    code: '51121',
    title: 'Conductors, composers and arrangers',
    examples: [
      'arranger',
      'bandmaster',
      'choir director',
      'composer',
      'conductor',
      'music adapter',
      'orchestrator',
      'singer-songwriter-composer',
      'songwriter',
    ],
  },
  {
    code: '51122',
    title: 'Musicians and singers',
    examples: [
      'accompanist',
      'church organist',
      'guitar player',
      'instrumentalist',
      'music teacher - musicians',
      'musician',
      'opera singer',
      'percussionist',
      'recording artist',
      'rock singer',
      'singer',
      'vocalist',
    ],
  },
  {
    code: '52100',
    title: 'Library and public archive technicians',
    examples: ['archive technician', 'library technician', 'technical indexer - library'],
  },
  {
    code: '52110',
    title: 'Film and video camera operators',
    examples: [
      'assistant camera operator',
      'camera operator',
      'electronic news gathering (ENG) camera operator',
      'film camera operator',
      'motion picture camera operator',
      'studio camera operator',
      'television camera operator',
      'video camera operator',
    ],
  },
  {
    code: '52111',
    title: 'Graphic arts technicians',
    examples: [
      'animated cartoon technician',
      'animation painter',
      'computer graphics technician',
      'graphics technician',
      'multimedia graphic design technician',
    ],
  },
  {
    code: '52112',
    title: 'Broadcast technicians',
    examples: [
      'broadcast technician',
      'broadcast transmitter operator',
      'broadcasting switcher',
      'master control room (MCR) equipment operator - broadcasting',
      'master control room (MCR) technician - broadcasting',
      'mobile broadcasting equipment operator',
      'regional transmitter technician',
      'short-wave receiving station technician',
      'video transmission operator',
    ],
  },
  {
    code: '52113',
    title: 'Audio and video recording technicians',
    examples: [
      'audiovisual (AV) technician',
      'multimedia sound technician',
      'postproduction technician',
      'recording engineer',
      'recording studio technician',
      'sound effects editor',
      'sound mixer',
      'sound technician',
      'video and sound recorder',
      'video recording technician',
    ],
  },
  {
    code: '52114',
    title: 'Announcers and other broadcasters',
    examples: [
      'announcer',
      'broadcaster',
      'disc jockey (DJ) - broadcast',
      'news reader',
      'radio host/hostess',
      'sports announcer',
      'talk show host/hostess',
      'television host/hostess',
    ],
  },
  {
    code: '52119',
    title: 'Other technical and coordinating occupations in motion pictures, broadcasting and the performing arts',
    examples: [
      'costumier',
      'gaffer',
      'key grip',
      'lighting technician',
      'make-up artist - motion pictures, broadcasting and performing arts',
      'program coordinator - broadcasting',
      'property master - broadcasting',
      'settings shop foreman/woman',
      'special effects technician',
      'stage manager',
      'stunt coordinator',
      'theatre technician',
    ],
  },
  {
    code: '52120',
    title: 'Graphic designers and illustrators',
    examples: [
      '3D animation artist',
      'advertising designer',
      'animator - animated films',
      'bank note designer',
      'cartoonist',
      'commercial artist',
      'graphic artist',
      'graphic designer',
      'graphic designer - multimedia',
      'illustrator',
      'layout designer',
      'medical illustrator',
      'multimedia illustrator',
      'scientific illustrator',
    ],
  },
  {
    code: '52121',
    title: 'Interior designers and interior decorators',
    examples: [
      'aircraft interior designer',
      'interior decorator',
      'interior design technician',
      'interior designer',
      'kitchen designer',
      'office space planner',
      'retail space planner',
    ],
  },
  {
    code: '53100',
    title: 'Registrars, restorers, interpreters and other occupations related to museum and art galleries',
    examples: [
      'art gallery preparator',
      'conservation technician - museums and art galleries',
      'heritage interpreter',
      'museology technician',
      'museum extension officer',
      'museum objects cataloguer',
      'museum registrar',
      'museum technician',
      'paintings restoration technician',
      'picture framer - museum and art gallery',
      'restoration technician - museum',
      'taxidermist',
    ],
  },
  {
    code: '53110',
    title: 'Photographers',
    examples: [
      'aerial photographer',
      'commercial photographer',
      'forensic photographer',
      'industrial photographer',
      'photographer',
      'portrait photographer',
      'scientific photographer',
    ],
  },
  {
    code: '53111',
    title: 'Motion pictures, broadcasting, photography and performing arts assistants and operators',
    examples: [
      'boom grip',
      'camera crane operator',
      'dresser - motion pictures, broadcasting and performing arts',
      'lighting assistant',
      'production assistant',
      'prompter',
      'props person',
      'script assistant',
      'set builder',
      'special effects assistant',
      'spotlight operator',
      'stagehand',
    ],
  },
  {
    code: '53120',
    title: 'Dancers',
    examples: [
      'ballet dancer',
      'ballet teacher',
      'ballroom dancing teacher',
      'dance instructor',
      'dancer',
      'folkloric dancer',
      'interpretative dancer',
      'tap dancer',
    ],
  },
  {
    code: '53121',
    title: 'Actors, comedians and circus performers',
    examples: [
      'acrobat',
      'acting teacher - private or studio',
      'actor/actress',
      'circus performer',
      'clown',
      'comedian',
      'drama teacher - private or studio',
      'narrator',
    ],
  },
  {
    code: '53122',
    title: 'Painters, sculptors and other visual artists',
    examples: [
      'art teacher (except primary, secondary and post-secondary education)',
      'artist',
      'artistic painter',
      'portrait painter',
      'sculptor',
      'silkscreen artist',
      'watercolourist',
    ],
  },
  {
    code: '53123',
    title: 'Theatre, fashion, exhibit and other creative designers',
    examples: [
      'clothing designer',
      'costume designer',
      'couturier - haute couture',
      'fabric designer',
      'fashion designer',
      'fur designer',
      'jewellery designer',
      'lighting designer',
      'museum exhibit designer',
      'shoe designer',
      'trophy designer',
    ],
  },
  {
    code: '53124',
    title: 'Artisans and craftspersons',
    examples: [
      'artistic floral arranger',
      'carver',
      'craft instructor (except education)',
      'craftsperson',
      'glass blower',
      'lace weaver - arts and crafts',
      'leather worker',
      'metal arts worker',
      'potter',
      'screen printing artisan',
      'silversmith',
      'stained glass artist',
      'stringed instrument maker',
      'totem pole carver',
      'weaver - arts and crafts',
    ],
  },
  {
    code: '53125',
    title: 'Patternmakers - textile, leather and fur products',
    examples: [
      'dress patternmaker',
      'embroidery patternmaker',
      'fur garment patternmaker',
      'garment patternmaker',
      'leather products patternmaker',
      'shoe patternmaker',
      'textile products patternmaker',
    ],
  },
  {
    code: '53200',
    title: 'Athletes',
    examples: [
      'athlete',
      'baseball player',
      'boxer',
      'figure skater',
      'football player',
      'golfer',
      'harness race driver',
      'hockey player',
      'jockey',
      'professional athlete',
      'skier',
      'sprinter',
      'track athlete',
    ],
  },
  {
    code: '53201',
    title: 'Coaches',
    examples: [
      'coach',
      'figure skating coach',
      'football scout',
      'gymnastics coach',
      'head coach',
      'hockey coach',
      'hockey scout',
      'national team coach',
      'soccer coach',
      'sports scout',
      'swimming coach',
      'volleyball coach',
    ],
  },
  {
    code: '53202',
    title: 'Sports officials and referees',
    examples: [
      'athletics judge',
      'clerk of the course - racetrack',
      'figure skating judge',
      'goal judge',
      'harness racing starter',
      'racetrack timer',
      'referee',
      'sports linesman/woman',
      'sports official',
      'umpire',
    ],
  },
  {
    code: '54100',
    title: 'Program leaders and instructors in recreation, sport and fitness',
    examples: [
      'aerobics instructor',
      'camp counsellor',
      'certified personal trainer',
      'day camp leader',
      'fitness appraiser',
      'fitness instructor',
      'gymnastics teacher',
      'personal trainer',
      'playground worker',
      'recreation program leader',
      'recreation technician',
      'riding instructor',
      'ski instructor',
      'ski patrol',
      'swimming instructor - sports',
    ],
  },
  {
    code: '55109',
    title: 'Other performers',
    examples: ['busker', 'fashion model', 'influencer', 'magician', 'puppeteer'],
  },
  {
    code: '60010',
    title: 'Corporate sales managers',
    examples: [
      'corporate sales manager',
      'national accounts manager - sales',
      'regional sales manager',
      'trade expansion manager',
    ],
  },
  {
    code: '60020',
    title: 'Retail and wholesale trade managers',
    examples: [
      'antique dealer',
      'assistant manager - retail',
      'automobile repair shop manager',
      'bookstore manager',
      'car dealership manager',
      'cattle dealer',
      'clothing store manager',
      'department store manager',
      'pleasure boat dealer',
      'sporting goods store manager',
      'store manager - retail',
      'supermarket manager',
      'variety store manager',
    ],
  },
  {
    code: '60030',
    title: 'Restaurant and food service managers',
    examples: [
      'banquet manager',
      'bar manager',
      'cafeteria manager',
      'catering service manager',
      'dining room manager',
      'food services manager',
      'hotel food and beverage service manager',
      'restaurant assistant manager',
      'restaurant manager',
      'restaurateur - food services',
    ],
  },
  {
    code: '60031',
    title: 'Accommodation service managers',
    examples: [
      'bed and breakfast operator',
      'front desk manager - accommodation services',
      'guest-house operator',
      'hotel assistant manager',
      'hotel director',
      'hotel manager',
      'motel manager',
      'reservations manager',
      'seasonal resort manager',
      'ski resort manager',
      'tourist home operator',
    ],
  },
  {
    code: '60040',
    title: 'Managers in customer and personal services',
    examples: [
      'barber shop manager',
      'car wash manager',
      'cooking school manager',
      'driving school manager',
      'dry cleaning service manager',
      'hairdressing salon manager',
      'pest control service manager',
      'residential cleaning service manager',
      'septic tank service manager',
      'spa manager',
      'window washing service manager',
    ],
  },
  {
    code: '62010',
    title: 'Retail sales supervisors',
    examples: [
      'department store supervisor',
      'head cashier',
      'liquor store supervisor',
      'produce department supervisor',
      'rental service supervisor',
      'retail sales route supervisor',
      'telemarketing supervisor',
    ],
  },
  {
    code: '62020',
    title: 'Food service supervisors',
    examples: ['cafeteria supervisor', 'canteen supervisor', 'catering supervisor', 'food service supervisor'],
  },
  {
    code: '62021',
    title: 'Executive housekeepers',
    examples: [
      'assistant executive housekeeper',
      'executive housekeeper',
      'hospital executive housekeeper',
      'hotel executive housekeeper',
      'housekeeping director',
      'housekeeping manager',
    ],
  },
  {
    code: '62022',
    title: 'Accommodation, travel, tourism and related services supervisors',
    examples: [
      'casino dealer supervisor',
      'hotel clerk supervisor',
      'reservations supervisor',
      'tour guide supervisor',
      'travel clerk supervisor',
    ],
  },
  {
    code: '62023',
    title: 'Customer and information services supervisors',
    examples: [
      'call centre agent supervisor',
      'contact centre supervisor',
      'current accounts supervisor',
      'customer service representatives supervisor - financial services',
      'information clerks supervisor',
    ],
  },
  {
    code: '62024',
    title: 'Cleaning supervisors',
    examples: [
      'building cleaning supervisor',
      'carpet cleaning supervisor',
      'cleaning supervisor',
      'head custodian',
      'housekeeping supervisor',
      'window washing supervisor',
    ],
  },
  {
    code: '62029',
    title: 'Other services supervisors',
    examples: [
      'clubhouse attendants supervisor',
      'commissionaire supervisor',
      'dry cleaning production supervisor',
      'dry cleaning supervisor',
      'laundry and dry cleaning forewoman',
      'laundry supervisor',
      'parking lot supervisor',
      'security guard supervisor',
      'ski lift attendants supervisor',
      'spa supervisor',
      'theatre attendants supervisor',
      'ticket taker supervisor',
    ],
  },
  {
    code: '62100',
    title: 'Technical sales specialists - wholesale trade',
    examples: [
      'aircraft sales representative',
      'communication equipment sales representative',
      'construction equipment sales representative',
      'electricity sales representative',
      'heavy equipment sales representative',
      'industrial supplies sales representative',
      'medical instruments sales agent',
      'software sales representative',
      'technical sales representative',
      'technical sales supervisor',
      'technical support specialist - wholesale trade',
    ],
  },
  {
    code: '62101',
    title: 'Retail and wholesale buyers',
    examples: [
      'appliance buyer',
      'assistant buyer',
      'beverage taster and buyer',
      'buyer - retail',
      'buyer - wholesale',
      'chief buyer',
      'clothing buyer',
      'food buyer',
      'produce buyer',
    ],
  },
  {
    code: '62200',
    title: 'Chefs',
    examples: [
      'chef',
      'corporate chef',
      'executive chef',
      'executive sous-chef',
      'head chef',
      'master chef',
      'pastry chef',
      'saucier',
      'sous-chef',
      'specialist chef',
    ],
  },
  {
    code: '62201',
    title: 'Funeral directors and embalmers',
    examples: ['apprentice embalmer', 'embalmer', 'funeral director', 'mortician'],
  },
  {
    code: '62202',
    title: 'Jewellers, jewellery and watch repairers and related occupations',
    examples: [
      'clock repairer',
      'diamond cutter',
      'gem cutter',
      'pearl cutter',
      'precious stone setter',
      'watch repairer',
    ],
  },
  {
    code: '63100',
    title: 'Insurance agents and brokers',
    examples: ['insurance agent', 'insurance broker', 'insurance sales representative', 'insurance sales supervisor'],
  },
  {
    code: '63101',
    title: 'Real estate agents and salespersons',
    examples: [
      'commercial real estate agent',
      'real estate agent',
      'real estate agent supervisor',
      'real estate sales representative',
      'residential real estate agent',
    ],
  },
  {
    code: '63102',
    title: 'Financial sales representatives',
    examples: [
      'credit officer',
      'financial services officer',
      'financial services representative',
      'loan officer',
      'mortgage officer',
      'sales associate - personal banking',
    ],
  },
  {
    code: '63200',
    title: 'Cooks',
    examples: [
      'apprentice cook',
      'cook',
      'dietary cook',
      'first cook',
      'grill cook',
      'hospital cook',
      'institutional cook',
      'journeyman/woman cook',
      'licensed cook',
      'line cook',
      'second cook',
      'short order cook',
    ],
  },
  {
    code: '63201',
    title: 'Butchers - retail and wholesale',
    examples: ['butcher - butcher shop', 'butcher apprentice', 'head butcher - wholesale', 'retail butcher'],
  },
  {
    code: '63202',
    title: 'Bakers',
    examples: ['baker', 'baker apprentice', 'bakery supervisor', 'bread baker', 'head baker'],
  },
  {
    code: '63210',
    title: 'Hairstylists and barbers',
    examples: [
      'barber',
      'barber apprentice',
      'hair colour technician',
      'hairdresser',
      'hairdresser apprentice',
      'hairstylist',
      'hairstylist apprentice',
      'wig stylist',
    ],
  },
  {
    code: '63211',
    title: 'Estheticians, electrologists and related occupations',
    examples: [
      'beauty treatment operator',
      'cosmetician',
      'electrologist',
      'electrolysis technician',
      'esthetician',
      'hair replacement technician',
      'manicurist',
      'pedicurist',
      'scalp treatment specialist',
      'tattoo artist',
    ],
  },
  {
    code: '63220',
    title: 'Shoe repairers and shoemakers',
    examples: ['bootmaker', 'custom shoemaker', 'orthopedic shoemaker', 'shoe repairer', 'shoemaker'],
  },
  {
    code: '63221',
    title: 'Upholsterers',
    examples: [
      'automobile upholsterer',
      'custom upholsterer',
      'furniture upholsterer',
      'upholsterer',
      'upholstery repairer',
    ],
  },
  {
    code: '64100',
    title: 'Retail salespersons and visual merchandisers',
    examples: [
      'audio equipment salesperson',
      'automobile salesperson',
      'car rental agent',
      'clothing salesperson',
      'computer salesperson - retail',
      'counter clerk - retail',
      'department store clerk',
      'display designer',
      'furniture salesperson',
      'hardware store clerk',
      'jewellery salesperson',
      'retail sales associate',
      'retail sales clerk',
      'retail salesperson',
      'sales consultant - retirement homes',
      'window display designer',
    ],
  },
  {
    code: '64101',
    title: 'Sales and account representatives - wholesale trade (non-technical)',
    examples: [
      'advertising time sales representative',
      'food products sales representative',
      'freight sales agent',
      'graphic design sales representative',
      'hotel accommodations sales executive',
      'liquor sales representative',
      'magazine sales representative',
      'oil distributor',
      'security services sales consultant',
      'transfer company agent',
      'wholesale trade representatives supervisor',
    ],
  },
  {
    code: '64200',
    title: 'Tailors, dressmakers, furriers and milliners',
    examples: [
      'alterations dressmaker',
      'custom furrier',
      'custom tailor',
      'furrier',
      'garment alterationist',
      'milliner',
      'seamstress',
      'tailor',
    ],
  },
  {
    code: '64201',
    title: 'Image, social and other personal consultants',
    examples: [
      'etiquette consultant',
      'fashion and wardrobe consultant',
      'fashion colour consultant',
      'image consultant',
      'make-up consultant',
      'public speaking consultant',
      'wardrobe consultant',
      'wedding consultant',
      'weight loss consultant',
    ],
  },
  {
    code: '64300',
    title: "Maîtres d'hôtel and hosts/hostesses",
    examples: [
      'chief host/hostess - food services',
      'dining room host/hostess',
      "maître d'",
      'restaurant host/hostess',
    ],
  },
  { code: '64301', title: 'Bartenders', examples: ['bar attendant', 'bar steward', 'bartender', 'head bartender'] },
  {
    code: '64310',
    title: 'Travel counsellors',
    examples: ['reservation agent - travel agency', 'travel agent', 'travel consultant'],
  },
  {
    code: '64311',
    title: 'Pursers and flight attendants',
    examples: [
      'airline purser',
      'flight attendant',
      'flight service director',
      'passenger service director - water transportation',
      'ship purser',
    ],
  },
  {
    code: '64312',
    title: 'Airline ticket and service agents',
    examples: [
      'airline baggage agent',
      'airline cargo agent',
      'airline load planner',
      'airline passenger agent',
      'airline reservation agent',
      'airline ticket agent',
      'baggage tracer - airline',
      'client service representative - airline',
      'counter services agent - airline',
      'customer service agent - airline',
      'station agent - airline',
    ],
  },
  {
    code: '64313',
    title: 'Ground and water transport ticket agents, cargo service representatives and related clerks',
    examples: [
      'bus ticket agent',
      'cargo customer service representative - railway',
      'counter service agent - railway',
      'railway passenger agent',
      'railway reservations clerk',
      'reservations clerk - cruise line',
      'ticket agent (except airline)',
    ],
  },
  {
    code: '64314',
    title: 'Hotel front desk clerks',
    examples: [
      'guest services agent - hotel',
      'hotel front desk clerk',
      'night clerk',
      'reservations clerk - hotel',
      'room clerk',
    ],
  },
  {
    code: '64320',
    title: 'Tour and travel guides',
    examples: [
      'adventure travel site interpreter',
      'bicycle tour guide',
      'calèche driver',
      'factory tour guide',
      'industrial site interpreter',
      'tour guide',
      'travel guide',
    ],
  },
  { code: '64321', title: 'Casino workers', examples: ['croupier', 'keno runner - casino', 'keno writer'] },
  {
    code: '64322',
    title: 'Outdoor sport and recreational guides',
    examples: [
      'canoeing guide',
      'dude wrangler',
      'fishing guide',
      'hot air balloonist',
      'hunting guide',
      'mountain climbing guide',
      'outdoor guide',
      'outfitter',
      'rafting guide',
    ],
  },
  {
    code: '64400',
    title: 'Customer services representatives - financial institutions',
    examples: [
      'bank teller',
      'credit union teller',
      'financial customer service representative',
      'foreign exchange teller - financial services',
    ],
  },
  {
    code: '64401',
    title: 'Postal services representatives',
    examples: [
      'bus parcel express clerk',
      'express mail service clerk',
      'mail clerk',
      'mail room clerk',
      'postal counter clerk',
      'postal wicket clerk',
    ],
  },
  {
    code: '64409',
    title: 'Other customer and information services representatives',
    examples: [
      'accounts information clerk',
      'bus information clerk',
      'complaints clerk - customer service',
      'contact centre agent - customer service',
      'courtesy desk clerk',
      'customer service representative - call centre',
      'enquiries clerk',
      'information clerk - customer service',
      'lost-and-found clerk',
      'order desk agent',
      'public relations clerk',
      'tourist information clerk',
    ],
  },
  {
    code: '64410',
    title: 'Security guards and related security service occupations',
    examples: [
      'alarm investigator',
      'armoured car guard',
      'bodyguard (except police)',
      'commissionaire',
      'corporate security officer',
      'house detective',
      'night guard',
      'postal inspection officer',
      'private investigator',
      'private security officer',
      'screening officer',
      'security guard',
      'store detective',
      'theft prevention officer',
    ],
  },
  {
    code: '65100',
    title: 'Cashiers',
    examples: [
      'box office cashier',
      'cafeteria cashier',
      'grocery store cashier',
      'movie theatre cashier',
      'office cashier',
      'racetrack cashier',
      'self-serve gas bar cashier',
    ],
  },
  {
    code: '65101',
    title: 'Service station attendants',
    examples: ['gas station attendant (except self-serve)', 'marina attendant', 'service station attendant'],
  },
  {
    code: '65102',
    title: 'Store shelf stockers, clerks and order fillers',
    examples: [
      'bag clerk',
      'fulfillment associate',
      'grocery clerk',
      'grocery packer',
      'price clerk - retail',
      'produce clerk',
      'shelf stocker - retail',
      'supermarket clerk',
    ],
  },
  {
    code: '65109',
    title: 'Other sales related occupations',
    examples: [
      'canvasser - retail',
      'demonstrator - retail',
      'direct distributor - retail',
      'door-to-door salesperson',
      'home demonstrator - retail',
      'street vendor',
      'telemarketer',
      'telephone solicitor',
    ],
  },
  {
    code: '65200',
    title: 'Food and beverage servers',
    examples: [
      'banquet server',
      'captain waiter/waitress',
      'chief wine steward',
      'cocktail waiter/waitress',
      'food and beverage server',
      'formal service waiter/waitress',
      'sommelier',
      'waiter/waitress',
      'wine steward',
    ],
  },
  {
    code: '65201',
    title: 'Food counter attendants, kitchen helpers and related support occupations',
    examples: [
      'bartender helper',
      'bus boy/girl',
      'cafeteria counter attendant',
      "cook's helper",
      'dishwasher',
      'fast-food preparer',
      'food counter attendant',
      'food preparer',
      'food service helper',
      'ice cream counter attendant',
      'kitchen helper',
      'salad bar attendant',
      'sandwich maker',
    ],
  },
  {
    code: '65202',
    title: 'Meat cutters and fishmongers - retail and wholesale',
    examples: ['fishmonger', 'fishmonger - retail', 'meat cutter - retail or wholesale', 'supermarket meat cutter'],
  },
  {
    code: '65210',
    title: 'Support occupations in accommodation, travel and facilities set-up services',
    examples: [
      'baggage porter',
      'banquet porter',
      'bellhop',
      'cruise line passenger attendant',
      'facilities porter',
      'hotel doorkeeper',
      'hotel guest services attendant',
      'luggage attendant',
      'room flipper',
      'showcase installer',
      'train service attendant',
    ],
  },
  {
    code: '65211',
    title: 'Operators and attendants in amusement, recreation and sport',
    examples: [
      'amusement attraction operator',
      'amusement park attendant',
      'amusement ride operator',
      'athletic equipment custodian',
      'billiard parlour attendant',
      'bingo hall attendant',
      'bowling alley attendant',
      'campground attendant',
      'campground supervisor',
      'game concession operator',
      'recreation attendant',
      'recreational facility attendant',
      'rink ice maker',
      'ski lift attendant',
      'sports attendant',
      'tennis court attendant',
    ],
  },
  {
    code: '65220',
    title: 'Pet groomers and animal care workers',
    examples: [
      'animal care worker (except farm)',
      'dog groomer',
      'dog trainer',
      'kennel attendant',
      'laboratory animal attendant',
      'pet groomer',
      'pound attendant',
      'veterinary attendant',
      'zoo attendant',
    ],
  },
  {
    code: '65229',
    title: 'Other support occupations in personal services',
    examples: [
      'astrologer',
      'chat line agent',
      'dating service consultant',
      'fortune teller',
      'psychic consultant',
      'psychic reader',
    ],
  },
  {
    code: '65310',
    title: 'Light duty cleaners',
    examples: [
      'cleaner',
      'hospital cleaner',
      'hotel cleaner',
      'house cleaner',
      'housekeeper',
      'housekeeping aide',
      'housekeeping room attendant',
      'light duty cleaner',
      'office cleaner',
      'sweeper',
    ],
  },
  {
    code: '65311',
    title: 'Specialized cleaners',
    examples: [
      'auto detailer',
      'building exterior cleaner',
      'carpet cleaner',
      'chimney cleaner',
      'freight car cleaner',
      'furnace cleaner',
      'laboratory equipment cleaner',
      'sandblaster',
      'septic tank cleaner',
      'upholstery cleaner',
      'vehicle cleaner',
      'ventilation system cleaner',
      'window cleaner',
    ],
  },
  {
    code: '65312',
    title: 'Janitors, caretakers and heavy-duty cleaners',
    examples: ['custodian', 'heavy-duty cleaner', 'industrial cleaner', 'plant cleaner', 'school janitor'],
  },
  {
    code: '65320',
    title: 'Dry cleaning, laundry and related occupations',
    examples: [
      'assembler - laundry and dry cleaning',
      'bagger - laundry and dry cleaning',
      'drapery cleaner',
      'dry cleaner',
      'dyer - laundry and dry cleaning',
      'finisher - laundry and dry cleaning',
      'fur cleaner - laundry and dry cleaning',
      'hand finisher - laundry and dry cleaning',
      'inspector - laundry and dry cleaning',
      'laundry machine operator',
      'laundry worker',
      'leather cleaner - laundry and dry cleaning',
      'presser - laundry and dry cleaning',
      'pressing machine tender - laundry and dry cleaning',
      'silk finisher - laundry and dry cleaning',
      'steam finisher - laundry and dry cleaning',
      'suede cleaner - laundry and dry cleaning',
    ],
  },
  {
    code: '65329',
    title: 'Other service support occupations',
    examples: [
      'beauty salon attendant',
      'car jockey',
      'cloakroom attendant',
      'door attendant (except hotel)',
      'funeral home attendant',
      'fur storage attendant',
      'hotel valet',
      'laundromat attendant',
      'parking lot attendant',
      'shoe shiner',
      'tanning salon attendant',
      'theatre usher',
      'ticket taker',
      'toll booth attendant',
    ],
  },
  {
    code: '70010',
    title: 'Construction managers',
    examples: [
      'commercial construction manager',
      'construction manager',
      'construction project manager',
      'construction superintendent',
      'general contractor',
      'housing construction manager',
      'industrial construction manager',
      'pipeline construction manager',
      'residential construction manager',
    ],
  },
  {
    code: '70011',
    title: 'Home building and renovation managers',
    examples: [
      'home builder',
      'home renovation contractor',
      'home renovator',
      'renovation contractor',
      'residential homes contractor',
    ],
  },
  {
    code: '70012',
    title: 'Facility operation and maintenance managers',
    examples: [
      'airport manager',
      'arena manager',
      'campus maintenance manager',
      'facilities maintenance head',
      'facility operations manager',
      'grain elevator district manager',
      'harbour master',
      'maintenance and service superintendent',
      'maintenance manager',
      'maintenance support services chief',
      'mechanical services superintendent',
      'plant maintenance superintendent',
      'recreation facility manager',
      'shopping centre manager',
      'warehouse manager',
    ],
  },
  {
    code: '70020',
    title: 'Managers in transportation',
    examples: [
      'bus company manager',
      'distribution manager - logistics',
      'flight operations manager',
      'freight forwarding manager',
      'marine superintendent',
      'rail operations superintendent',
      'railway freight manager',
      'traffic manager - transportation',
      'transport department manager',
      'transportation manager',
      'urban transit system manager',
    ],
  },
  {
    code: '70021',
    title: 'Postal and courier services managers',
    examples: [
      'collection and delivery operations manager - postal service',
      'mail operations manager',
      'mail processing plant manager',
      'messenger service manager',
      'parcel processing plant manager',
      'post office manager',
      'postal station superintendent',
      'production control manager - postal service',
    ],
  },
  {
    code: '72010',
    title: 'Contractors and supervisors, machining, metal forming, shaping and erecting trades and related occupations',
    examples: [
      'automotive machine shop foreman/woman',
      'blacksmith foreman/woman',
      'boilermaker foreman/woman',
      'boilermaker supervisor',
      'ironwork contractor',
      'ironworkers foreman/woman',
      'machine shop supervisor',
      'machinists foreman/woman',
      'machinists supervisor',
      'maintenance machine shop foreman/woman',
      'metal mould and metal patternmakers supervisor',
      'sheet metal workers supervisor',
      'structural metal fitter supervisor',
      'tool and die inspectors supervisor',
      'tool and die makers foreman/woman',
      'welder supervisor',
    ],
  },
  {
    code: '72011',
    title: 'Contractors and supervisors, electrical trades and telecommunications occupations',
    examples: [
      'cable maintenance and repair supervisor',
      'cablevision technicians foreman/woman',
      'construction electricians foreman/woman',
      'electrical contractor',
      'industrial electrical systems foreman/woman',
      'plant electrician supervisor',
      'power line and cable workers foreman/woman',
      'power systems electricians foreman/woman',
      'residential electrician supervisor',
      'telecommunication installation and repair worker foreman/woman',
    ],
  },
  {
    code: '72012',
    title: 'Contractors and supervisors, pipefitting trades',
    examples: [
      'gas fitters foreman/woman',
      'gas fitters supervisor',
      'pipefitters foreman/woman',
      'pipefitting contractor',
      'plumber foreman/woman',
      'plumbers supervisor',
      'plumbing contractor',
      'sprinkler system installer foreman/woman',
      'steamfitter foreman/woman',
      'steamfitters supervisor',
    ],
  },
  {
    code: '72013',
    title: 'Contractors and supervisors, carpentry trades',
    examples: [
      'cabinetmaking contractor',
      'carpenter supervisor',
      'carpentry contractor',
      'finish carpenters foreman/woman',
      'form builders foreman/woman',
      'framers foreman/woman',
      'maintenance carpenters foreman/woman',
    ],
  },
  {
    code: '72014',
    title: 'Contractors and supervisors, other construction trades, installers, repairers and servicers',
    examples: [
      'bicycle repair shop supervisor',
      'bricklaying contractor',
      'cement finishing contractor',
      'glaziers foreman/woman',
      'insulators foreman/woman',
      'painters and decorators supervisor',
      'painting contractor',
      'pest control supervisor',
      'plasterer foreman/woman',
      'roofing contractor',
      'tilesetters supervisor',
    ],
  },
  {
    code: '72020',
    title: 'Contractors and supervisors, mechanic trades',
    examples: [
      'air conditioning and refrigeration mechanic foreman/woman',
      'aircraft maintenance engineers (AME) supervisor',
      'aircraft mechanics and inspectors foreman/woman',
      'appliance repair shop supervisor',
      'electrical mechanics foreman/woman',
      'heating systems contractor',
      'heating systems mechanics foreman/woman',
      'heavy machinery assembly foreman/woman',
      'industrial mechanics supervisor',
      'motor vehicle repair shop supervisor',
      'printing machine repairers foreman/woman',
      'railway car repairer foreman/woman',
      'railway equipment maintenance inspectors foreman/woman',
      'small-engine repair shop foreman/woman',
      'textile machine mechanics foreman/woman',
    ],
  },
  {
    code: '72021',
    title: 'Contractors and supervisors, heavy equipment operator crews',
    examples: [
      'demolition foreman/woman',
      'drilling and blasting foreman/woman - construction',
      'excavating contractor',
      'heavy equipment operators supervisor',
      'logging road construction foreman/woman',
      'oil field construction supervisor',
      'paving contractor',
      'pipeline construction supervisor',
      'railway gang foreman/woman',
      'railway section foreman/woman',
      'railway track maintenance foreman/woman',
      'road maintenance foreman/woman',
      'track foreman/woman - railway',
      'water well drilling supervisor',
    ],
  },
  {
    code: '72022',
    title: 'Supervisors, printing and related occupations',
    examples: [
      'bindery foreman/woman - printing',
      'bindery supervisor - printing',
      'composing room supervisor - printing',
      'film processing supervisor',
      'finishing supervisor - printing',
      'photographic and film processing foreman/woman',
      'platemaking supervisor - printing',
      'prepress supervisor - printing',
      'pressroom supervisor - printing',
    ],
  },
  {
    code: '72023',
    title: 'Supervisors, railway transport operations',
    examples: [
      'freight train road foreman/woman',
      'railway transport operations supervisor',
      'stationmaster',
      'trainmaster',
      'yard foreman/woman - railway',
      'yardmaster - railway',
    ],
  },
  {
    code: '72024',
    title: 'Supervisors, motor transport and other ground transit operators',
    examples: [
      'bus dispatcher',
      'bus inspector',
      'light rail transit (LRT) operators supervisor',
      'school bus drivers supervisor',
      'subway operators supervisor',
      'subway traffic controller',
      'transit mobile inspector',
      'truck drivers foreman/woman',
      'urban transit system foreman/woman',
    ],
  },
  {
    code: '72025',
    title: 'Supervisors, mail and message distribution occupations',
    examples: [
      'courier service supervisor',
      'letter carriers supervisor',
      'mail and postal clerks supervisor',
      'mail room supervisor',
      'messenger service supervisor',
      'postal station supervisor',
      'postmaster/mistress',
    ],
  },
  {
    code: '72100',
    title: 'Machinists and machining and tooling inspectors',
    examples: [
      'automotive machinist',
      'aviation machinist',
      'general machinist',
      'machine shop inspector',
      'machined parts inspector',
      'machining inspector',
      'machinist',
      'machinist apprentice',
      'tooling inspector',
    ],
  },
  {
    code: '72101',
    title: 'Tool and die makers',
    examples: [
      'die finisher',
      'die maker',
      'jig maker',
      'metal mould maker',
      'metal patternmaker',
      'metal patternmaker apprentice',
      'mould maker - plastics processing',
      'mould maker apprentice',
      'tool and die maker',
      'tool and die maker apprentice',
      'toolmaker',
    ],
  },
  {
    code: '72102',
    title: 'Sheet metal workers',
    examples: [
      'apprentice sheet metal worker',
      'sheet metal fabricator',
      'sheet metal mechanic',
      'sheet metal worker',
      'tinsmith',
    ],
  },
  {
    code: '72103',
    title: 'Boilermakers',
    examples: [
      'boiler fitter',
      'boiler installer',
      'boilermaker',
      'boilermaker apprentice',
      'construction boilermaker',
      'industrial boilermaker',
      'marine boilermaker',
      'pressure vessel fabricator',
    ],
  },
  {
    code: '72104',
    title: 'Structural metal and platework fabricators and fitters',
    examples: [
      'metal fabricator',
      'plater',
      'platework fitter',
      'shipfitter',
      'shipfitter apprentice',
      'steel fabricator',
      'structural steel fitter',
    ],
  },
  {
    code: '72105',
    title: 'Ironworkers',
    examples: [
      'ironworker',
      'ironworker - metal building systems erector',
      'ironworker apprentice',
      'ornamental ironworker',
      'reinforcing ironworker',
      'structural steel erector',
    ],
  },
  {
    code: '72106',
    title: 'Welders and related machine operators',
    examples: [
      'aviation welding technician',
      'brazing machine operator',
      'brazing machine setter',
      'electric arc welder',
      'journeyman/woman welder',
      'laser welding operator',
      'pressure vessel welder',
      'production welder',
      'soldering machine operator',
      'spot welder',
      'submerged arc welder',
      'welder',
      'welder apprentice',
      'welder-fitter',
    ],
  },
  {
    code: '72200',
    title: 'Electricians (except industrial and power system)',
    examples: [
      'apprentice electrician',
      'construction electrician',
      'construction electrician apprentice',
      'domestic and rural electrician',
      'electrician',
    ],
  },
  {
    code: '72201',
    title: 'Industrial electricians',
    examples: [
      'industrial electrician',
      'industrial electrician apprentice',
      'marine electrician',
      'mill electrician',
      'mine electrician',
      'plant electrician',
      'plant maintenance electrician',
      'shipyard electrician',
    ],
  },
  {
    code: '72202',
    title: 'Power system electricians',
    examples: [
      'apprentice power system electrician',
      'power electrician',
      'power station electrician',
      'power system electrician',
    ],
  },
  {
    code: '72203',
    title: 'Electrical power line and cable workers',
    examples: [
      'apprentice lineman/woman - electric power systems',
      'cable installer - electrical power',
      'cable splicer - electrical power',
      'construction lineman/woman - electrical power line',
      'power line patroller',
      'power lineman/woman',
      'powerline technician',
    ],
  },
  {
    code: '72204',
    title: 'Telecommunications line and cable installers and repairers',
    examples: [
      'apprentice lineman/woman - telecommunications',
      'cable television installation technician',
      'cable television maintenance technician',
      'communication technician - construction',
      'construction technician - cable television',
      'telecommunication cable repairer',
      'telecommunications line installer',
      'telecommunications lineman/woman',
      'telephone line technician',
    ],
  },
  {
    code: '72205',
    title: 'Telecommunications equipment installation and cable television service technicians',
    examples: [
      'apprentice communication electrician - switching',
      'cable television service technician',
      'cablevision servicer',
      'cellular telephone technician',
      'direct broadcast satellite (DBS) technician - cable television',
      'exchange tester - telecommunications',
      'mobile radio installer - telecommunications',
      'private branch exchange (PBX) installer - telecommunications',
      'switch network installer and repairer - telecommunications',
      'switch network installer and repairwoman - telecommunications',
      'telecommunications equipment technician',
      'telephone communication technician',
      'telephone installer',
      'telephone line and station installer',
    ],
  },
  {
    code: '72300',
    title: 'Plumbers',
    examples: ['maintenance plumber', 'plumber', 'plumber apprentice', 'plumbing mechanic'],
  },
  {
    code: '72301',
    title: 'Steamfitters, pipefitters and sprinkler system installers',
    examples: [
      'apprentice pipefitter-steamfitter',
      'fire protection mechanic',
      'fire sprinkler fitter',
      'marine pipefitter',
      'pipefitter',
      'sprinkler system fitter',
      'sprinkler system installer',
      'steamfitter',
      'steamfitter-pipefitter',
    ],
  },
  {
    code: '72302',
    title: 'Gas fitters',
    examples: ['gas fitter', 'gas fitter apprentice', 'gas servicer', 'gas technician', 'industrial gas fitter-tester'],
  },
  {
    code: '72310',
    title: 'Carpenters',
    examples: [
      'apprentice carpenter',
      'carpenter',
      'carpenter-joiner',
      'finish carpenter',
      'journeyman/woman carpenter',
      'maintenance carpenter',
      'metal framer - carpentry',
      'renovation carpenter',
      'rough carpenter',
      'stair builder-carpenter',
    ],
  },
  {
    code: '72311',
    title: 'Cabinetmakers',
    examples: ['cabinetmaker', 'cabinetmaker apprentice', 'custom wood furniture maker', 'furniture cabinetmaker'],
  },
  {
    code: '72320',
    title: 'Bricklayers',
    examples: [
      'apprentice bricklayer',
      'bricklayer',
      'brickmason',
      'refractory bricklayer',
      'stonecutter',
      'stonemason',
    ],
  },
  {
    code: '72321',
    title: 'Insulators',
    examples: [
      'boiler and pipe insulator',
      'building insulator',
      'firestopping insulator',
      'heat and frost insulator',
      'insulation applicator',
      'insulation mechanic',
      'insulator',
      'insulator apprentice',
      'sound insulator',
    ],
  },
  {
    code: '72400',
    title: 'Construction millwrights and industrial mechanics',
    examples: [
      'card fixer',
      'card grinder',
      'construction millwright',
      'industrial mechanic',
      'industrial mechanic apprentice',
      'industrial sewing machine mechanic',
      'knitting machine mechanic',
      'loom fixer',
      'loom technician',
      'maintenance millwright',
      'millwright',
      'millwright apprentice',
      'open-end technician',
      'plant equipment mechanic',
      'spinning fixer',
      'textile fixer',
      'textile machine mechanic',
      'textile machinery fixer',
      'treatment plant maintenance mechanic',
    ],
  },
  {
    code: '72401',
    title: 'Heavy-duty equipment mechanics',
    examples: [
      'agricultural equipment technician',
      'construction equipment mechanic',
      'diesel mechanic - heavy equipment',
      'farm equipment mechanic',
      'heavy equipment mechanic',
      'heavy mobile logging equipment mechanic',
      'heavy mobile mining equipment mechanic',
      'heavy-duty equipment mechanic apprentice',
      'heavy-duty equipment technician',
      'locomotive mechanic',
      'tractor mechanic',
    ],
  },
  {
    code: '72402',
    title: 'Heating, refrigeration and air conditioning mechanics',
    examples: [
      'central air conditioning mechanic',
      'commercial air conditioning mechanic',
      'heating and cooling mechanic',
      'heating, ventilation and air conditioning (HVAC) mechanic',
      'refrigeration and air conditioning mechanic apprentice',
      'refrigeration mechanic',
      'transport refrigeration mechanic',
    ],
  },
  {
    code: '72403',
    title: 'Railway carmen/women',
    examples: [
      'rail vehicle mechanic',
      'railway car inspector',
      'railway carman/woman',
      'railway carman/woman apprentice',
      'streetcar and subway car mechanic',
    ],
  },
  {
    code: '72404',
    title: 'Aircraft mechanics and aircraft inspectors',
    examples: [
      'aircraft gas turbine engine technician',
      'aircraft hydraulics mechanic',
      'aircraft inspector',
      'aircraft maintenance engineer (AME) (except avionics)',
      'aircraft mechanic',
      'aircraft repair and overhaul inspector',
      'aircraft repair shop inspector',
      'aircraft structural repair technician',
      'aircraft systems inspector',
      'aviation mechanical component shop technician',
      'certified aircraft technician',
      'flight test inspector',
    ],
  },
  {
    code: '72405',
    title: 'Machine fitters',
    examples: [
      'aircraft engine fitter',
      'assembly fitter',
      'heavy equipment fitter',
      'machine builder',
      'machine fitter',
      'machine tool builder',
      'mechanical fitter',
    ],
  },
  {
    code: '72406',
    title: 'Elevator constructors and mechanics',
    examples: ['elevator constructor', 'elevator mechanic', 'elevator mechanic apprentice', 'escalator repairer'],
  },
  {
    code: '72410',
    title: 'Automotive service technicians, truck and bus mechanics and mechanical repairers',
    examples: [
      'apprentice motor vehicle mechanic',
      'automobile mechanic',
      'automotive service technician',
      'brake systems mechanic',
      'bus mechanic',
      'mechanical upgrader - motor vehicle manufacturing',
      'motor repairer - motor vehicle manufacturing',
      'transmission mechanic',
      'truck and transport mechanic',
      'truck-trailer repairer',
      'tune-up specialist - motor vehicle',
    ],
  },
  {
    code: '72411',
    title: 'Auto body collision, refinishing and glass technicians and damage repair estimators',
    examples: [
      'auto body and collision technician',
      'auto body repairer',
      'automobile damage repair estimator',
      'automotive body mechanic',
      'automotive glass technician',
      'automotive painter - motor vehicle repair',
      'automotive painter apprentice',
      'metal finisher - motor vehicle manufacturing',
      'metal repairer - motor vehicle manufacturing',
      'motor vehicle body technician - truck',
      'painter - motor vehicle repair',
    ],
  },
  {
    code: '72420',
    title: 'Oil and solid fuel heating mechanics',
    examples: [
      'furnace installer and repairer (except gas)',
      'heating service mechanic',
      'heating systems technician',
      'oil burner installer',
      'oil burner mechanic',
      'oil burner mechanic apprentice',
      'wood burner installer',
    ],
  },
  {
    code: '72421',
    title: 'Appliance servicers and repairers',
    examples: [
      'appliance service technician',
      'appliance service technician apprentice',
      'appliance servicer',
      'commercial foodservice appliance technician',
      'commercial laundry appliance technician',
      'dishwasher repairer',
      'refrigerator repairer',
      'service technician - electrical appliances',
      'service technician - gas appliances',
      'stove repairer',
      'vacuum cleaner repairer',
      'washing machine servicer',
      'window air conditioner repairer',
    ],
  },
  {
    code: '72422',
    title: 'Electrical mechanics',
    examples: [
      'armature winder repairer',
      'coil winder and repairer',
      'electric motor systems technician',
      'electrical mechanic',
      'electrical mechanic apprentice',
      'electrical rewind mechanic',
      'electrical transformer repairer',
      'industrial motor winder-repairer',
      'power transformer repairer',
      'transformer repairer',
    ],
  },
  {
    code: '72423',
    title: 'Motorcycle, all-terrain vehicle and other related mechanics',
    examples: [
      'all-terrain vehicle repairer',
      'forklift mechanic',
      'industrial truck repairer',
      'lift truck mechanic',
      'motor boat mechanic',
      'motor scooter repairer',
      'motorcycle mechanic',
      'motorcycle mechanic apprentice',
      'outboard motor mechanic',
      'recreation vehicle technician',
      'snowmobile repairer',
    ],
  },
  {
    code: '72429',
    title: 'Other small engine and small equipment repairers',
    examples: [
      'air-cooled engine mechanic',
      'gasoline-powered lawn mower repairer',
      'lawn and garden equipment technician',
      'small engine technician',
      'small equipment mechanic apprentice',
      'small equipment repairer',
    ],
  },
  {
    code: '72500',
    title: 'Crane operators',
    examples: [
      'boom truck crane operator',
      'bridge crane operator',
      'climbing crane operator',
      'construction crane operator',
      'crane operator',
      'dragline crane operator',
      'gantry crane operator',
      'hoist operator (except underground mining)',
      'mobile crane operator',
      'tower crane operator',
      'tractor crane operator',
    ],
  },
  {
    code: '72501',
    title: 'Water well drillers',
    examples: [
      'cable tool driller - water well drilling',
      'churn drill operator - water well drilling',
      'water well driller',
      'water well driller apprentice',
    ],
  },
  {
    code: '72600',
    title: 'Air pilots, flight engineers and flying instructors',
    examples: [
      'air pilot',
      'captain - air transport',
      'check pilot',
      'chief flying instructor',
      'chief pilot',
      'co-pilot',
      'flight engineer',
      'flying instructor',
      'helicopter pilot',
      'pilot instructor',
      'relief pilot',
      'second officer - air transport',
      'test pilot',
    ],
  },
  {
    code: '72601',
    title: 'Air traffic controllers and related occupations',
    examples: [
      'air traffic controller (ATC)',
      'airport air traffic controller',
      'enroute air traffic controller',
      'flight dispatcher',
      'flight service specialist (FSS)',
      'instrument flight rules air traffic controller',
      'terminal air traffic controller',
      'visual flight rules air traffic controller',
    ],
  },
  {
    code: '72602',
    title: 'Deck officers, water transport',
    examples: [
      'Coast Guard vessel first watchkeeping officer',
      'Coast Guard vessel navigation officer',
      'Coast Guard vessel watchkeeping officer',
      'deck officer - water transport',
      'ferryboat master',
      'master mariner',
      'ship pilot',
      'ship second mate',
      'ship third mate',
      "ship's captain",
      "ship's mate",
      'tugboat captain',
    ],
  },
  {
    code: '72603',
    title: 'Engineer officers, water transport',
    examples: [
      'chief engineer - water transport',
      'engineer officer - water transport',
      'fourth engineer - water transport',
      'marine engineer officer',
      'second engineer',
      'third engineer - water transport',
      'tugboat engineer',
    ],
  },
  {
    code: '72604',
    title: 'Railway traffic controllers and marine traffic regulators',
    examples: [
      'chief rail traffic controller',
      'marine traffic regulator',
      'rail traffic controller',
      'train operator',
    ],
  },
  {
    code: '72999',
    title: 'Other technical trades and related occupations',
    examples: [
      'aircraft patternmaker',
      'blacksmith',
      'commercial diver',
      'die setter',
      'explosive ordnance detector',
      'farrier',
      'gunsmith',
      'locksmith',
      'reclamation diver',
      'restoration steeplejack',
      'safe maker',
      'saw fitter',
      'small arms repairer',
      'toolsmith',
      'underwater contractor',
      'vault repairer',
    ],
  },
  {
    code: '73100',
    title: 'Concrete finishers',
    examples: [
      'cement finisher apprentice',
      'cement mason',
      'concrete finisher',
      'concrete mason',
      'precast concrete finisher',
    ],
  },
  {
    code: '73101',
    title: 'Tilesetters',
    examples: [
      'apprentice tilesetter',
      'ceramic tile installer',
      'marble setter',
      'terrazzo polisher',
      'terrazzo worker',
      'tile installer',
      'tilesetter',
    ],
  },
  {
    code: '73102',
    title: 'Plasterers, drywall installers and finishers and lathers',
    examples: [
      'acoustical ceiling installer',
      'ceiling installer',
      'drywall applicator',
      'drywall finisher',
      'drywall installer and finisher apprentice',
      'drywall taper',
      'lather',
      'lather (interior systems mechanic)',
      'lather apprentice',
      'plasterer',
      'plasterer apprentice',
      'sheetrock applicator',
      'wood lather',
    ],
  },
  {
    code: '73110',
    title: 'Roofers and shinglers',
    examples: [
      'apprentice roofer',
      'asphalt roofer',
      'built-up roofer',
      'flat roofer',
      'residential steep roofer',
      'roofer',
      'shingler',
      'single-ply roofer',
    ],
  },
  {
    code: '73111',
    title: 'Glaziers',
    examples: [
      'glazier',
      'glazier and metal mechanic',
      'glazier apprentice',
      'plate glass installer',
      'stained glass glazier',
      'structural glass glazier',
    ],
  },
  {
    code: '73112',
    title: 'Painters and decorators (except interior decorators)',
    examples: [
      'construction painter',
      'maintenance painter',
      'painter',
      'painter and decorator',
      'painter and decorator apprentice',
      'paperhanger',
    ],
  },
  {
    code: '73113',
    title: 'Floor covering installers',
    examples: [
      'carpet layer',
      'floor covering installer apprentice',
      'floor covering mechanic',
      'hardwood floor layer',
      'residential floor and wall covering installer',
      'resilient floor installer',
      'vinyl floor installer',
    ],
  },
  {
    code: '73200',
    title: 'Residential and commercial installers and servicers',
    examples: [
      'aluminum window installer',
      'eavestrough installer',
      'electric appliance installer',
      'exterior cladder',
      'fence erector',
      'hot tub installer',
      'irrigation technician - installation and maintenance',
      'kitchen cupboard and vanity installer',
      'recreation structure erector',
      'siding installer',
      'sign installer',
      'swimming pool installer',
      'water conditioner servicer',
      'water heater servicer',
      'window installer',
    ],
  },
  {
    code: '73201',
    title: 'General building maintenance workers and building superintendents',
    examples: ['apartment building maintenance worker', 'building operator - maintenance', 'building superintendent'],
  },
  {
    code: '73202',
    title: 'Pest controllers and fumigators',
    examples: [
      'animal control trapper',
      'pest control operator',
      'pest control service representative',
      'pest control technician',
      'pest controller',
      'pest exterminator',
      'pest fumigator',
    ],
  },
  {
    code: '73209',
    title: 'Other repairers and servicers',
    examples: [
      'bicycle repairer',
      'camera repairer',
      'meter repairer',
      'piano repairer',
      'scale repairer',
      'sewing machine servicer - domestic',
      'sporting goods repairer',
      'vending machine repairer',
    ],
  },
  {
    code: '73300',
    title: 'Transport truck drivers',
    examples: [
      'bulk goods truck driver',
      'dump truck driver',
      'flatbed truck driver',
      'heavy truck driver',
      'logging truck driver',
      'long haul truck driver',
      'moving van driver',
      'shunt truck driver',
      'tow truck driver',
      'tractor-trailer truck driver',
      'transport driver',
      'truck driver',
    ],
  },
  {
    code: '73301',
    title: 'Bus drivers, subway operators and other transit operators',
    examples: [
      'bus driver',
      'bus operator',
      'light rail transit operator',
      'motor coach driver',
      'public passenger transit driver',
      'school bus driver',
      'sightseeing tour driver',
      'streetcar operator',
      'subway train operator',
      'transit operator - transportation',
    ],
  },
  {
    code: '73310',
    title: 'Railway and yard locomotive engineers',
    examples: [
      'locomotive engineer - railway',
      'railway engineer',
      'switch engineer - railway',
      'transfer hostler - railway',
      'yard engineer - railway',
    ],
  },
  {
    code: '73311',
    title: 'Railway conductors and brakemen/women',
    examples: [
      'brakeman/woman',
      'conductor - switching yard',
      'freight train conductor',
      'front-end brakeman/woman',
      'passenger train conductor',
      'railway conductor',
      'road freight brakeman/woman',
      'tail-end brakeman/woman',
    ],
  },
  {
    code: '73400',
    title: 'Heavy equipment operators',
    examples: [
      'backhoe operator',
      'bulldozer operator',
      'excavator operator',
      'gradall operator',
      'grader operator',
      'heavy equipment operator',
      'heavy equipment operator apprentice',
      'loader operator - construction',
      'side boom tractor operator',
      'surface mining equipment operator',
    ],
  },
  {
    code: '73401',
    title: 'Printing press operators',
    examples: [
      'apprentice pressman/woman',
      'assistant pressman/woman',
      'first pressman/woman',
      'flexographic press operator',
      'offset press operator',
      'printing press operator',
      'rotogravure pressman/woman - printing',
    ],
  },
  {
    code: '73402',
    title: 'Drillers and blasters - surface mining, quarrying and construction',
    examples: [
      'blaster - quarrying',
      'blaster - surface mining',
      'construction driller',
      'driller - surface mine',
      'open-pit blaster',
      'rotary drilling machine operator',
    ],
  },
  {
    code: '74100',
    title: 'Mail and parcel sorters and related occupations',
    examples: ['mail sorter', 'postal clerk - mail and parcel sorting'],
  },
  { code: '74101', title: 'Letter carriers', examples: ['letter carrier', 'postal carrier', 'rural mail carrier'] },
  {
    code: '74102',
    title: 'Couriers and messengers',
    examples: [
      'bank messenger',
      'courier',
      'courier service driver',
      'delivery person - courier service',
      'mail service courier',
      'runner - courier service',
    ],
  },
  {
    code: '74200',
    title: 'Railway yard and track maintenance workers',
    examples: [
      'ballast regulator operator - railway',
      'car controller - railway',
      'control tower operator - railway',
      'machine operator - railway',
      'rail saw operator',
      'railway equipment operator',
      'railway track patroller',
      'railway trackman/woman',
      'section worker - railway',
      'signal tower operator - railway',
      'spike machine operator - railway',
      'switch tender - railway yard',
      'tie tamper operator - railway',
      'towerman/woman - railway transport',
      'yard coupler - railway',
      'yard worker - railway',
    ],
  },
  {
    code: '74201',
    title: 'Water transport deck and engine room crew',
    examples: [
      'able seaman/woman (except armed forces)',
      'boatswain - water transport',
      'deckhand',
      'engine room crew member - ship',
      'helmsman/woman - water transport',
      'marine engine oiler',
      'marine engineering mechanic',
      'ship stoker',
      'ship tunnel operator',
    ],
  },
  {
    code: '74202',
    title: 'Air transport ramp attendants',
    examples: [
      'aircraft groomer',
      'airport ramp attendant',
      'cargo attendant - air transport',
      'ramp agent - air transport',
      'ramp attendant - air transport',
      'ramp services supervisor - airport',
      'station attendant - air transport',
    ],
  },
  {
    code: '74203',
    title: 'Automotive and heavy truck and equipment parts installers and servicers',
    examples: [
      'crane greaser',
      'heavy equipment servicer (except mechanic)',
      'muffler installer',
      'radiator installer - auto repair service',
      'shock absorber installer',
      'spring installer',
      'tire repairer',
    ],
  },
  {
    code: '74204',
    title: 'Utility maintenance workers',
    examples: [
      'gas leak locator',
      'gas maintenance worker',
      'gas utility operator',
      'pipeline maintenance worker',
      'pipeline patrolman/woman',
      'power pole inspector',
      'utility locator',
      'utility plant maintenance worker',
      'waterworks maintenance worker',
    ],
  },
  {
    code: '74205',
    title: 'Public works maintenance equipment operators and related workers',
    examples: [
      'garbage truck driver',
      'municipal maintenance equipment operator',
      'public works maintenance equipment operator',
      'salt truck operator',
      'sand spreader operator',
      'sewer-flushing truck operator - public works',
      'snow removal equipment operator - public works',
      'street flusher operator',
      'street sweeper operator',
      'utility arborist',
    ],
  },
  {
    code: '75100',
    title: 'Longshore workers',
    examples: [
      'dockworker',
      'longshore worker',
      'longshoreman/woman',
      'ship loader operator',
      'stevedore',
      'tanker loader',
    ],
  },
  {
    code: '75101',
    title: 'Material handlers',
    examples: [
      'bin filler',
      'coal handler',
      'conveyor console operator',
      'forklift truck operator',
      'freight handler (except air transport)',
      'furniture mover',
      'lumber piler - building supplies',
      'material handler',
      'railway car loader',
      'stockpiler',
      'storage worker - material handling',
      'truck loader',
      'warehouseman/woman',
    ],
  },
  {
    code: '75110',
    title: 'Construction trades helpers and labourers',
    examples: [
      'asphalt spreader',
      'bricklayer helper',
      'carpenter helper',
      'concrete mixer helper',
      'concrete paving labourer',
      'construction helper',
      'construction labourer',
      'demolition worker',
      'driller helper - surface mining',
      'drywall sander',
      'excavation labourer',
      'flagperson',
      'glazier helper',
      'pipeline mandrel operator',
      'plumber helper',
      'roofer helper',
      'stabber - pipeline construction',
    ],
  },
  {
    code: '75119',
    title: 'Other trades helpers and labourers',
    examples: [
      'aerial spraying assistant',
      'aircraft mechanic helper',
      'cable installer helper',
      'diesel mechanic helper',
      'ground worker - telecommunications',
      "mechanic's helper - automotive",
      'millwright helper',
      'refrigeration mechanic helper',
      'splicer helper - telecommunications',
      'surveyor helper',
    ],
  },
  {
    code: '75200',
    title: 'Taxi and limousine drivers and chauffeurs',
    examples: ['airport limousine driver', 'company chauffeur', 'limousine driver', 'private chauffeur', 'taxi driver'],
  },
  {
    code: '75201',
    title: 'Delivery service drivers and door-to-door distributors',
    examples: [
      'bread deliverer',
      'canteen driver',
      'delivery driver',
      'door-to-door distributor',
      'driver salesperson',
      'dry cleaning driver',
      'flyer distributor',
      'newspaper carrier',
      'newspaper delivery driver',
      'pizza delivery driver',
      'vending machine driver-supplier',
    ],
  },
  {
    code: '75210',
    title: 'Boat and cable ferry operators and related occupations',
    examples: [
      'bridgemaster',
      'cable ferry operator',
      'canal lock operator',
      'charter boat operator',
      'ferry terminal worker',
      'launch master',
      'linesman/woman - canal lock system',
      'loading bridge operator',
      'lockmaster',
      'motorboat operator',
      'scow captain',
      'sightseeing boat operator',
      'small craft operator',
      'water taxi operator',
    ],
  },
  {
    code: '75211',
    title: 'Railway and motor transport labourers',
    examples: [
      'car checker - railway',
      'delivery truck helper',
      'furniture mover helper',
      'mover helper',
      'railway labourer',
      'signal gang helper',
      'swamper - truck transport',
      'truck driver helper',
    ],
  },
  {
    code: '75212',
    title: 'Public works and maintenance labourers',
    examples: [
      'helper - garbage collection',
      'municipal labourer',
      'parking meter collector',
      'public works labourer',
      'road maintenance worker',
      'sewer maintenance worker',
      'sidewalk cleaner',
    ],
  },
  {
    code: '80010',
    title: 'Managers in natural resources production and fishing',
    examples: [
      'director of mining',
      'drilling operations manager',
      'fishing operations manager',
      'forestry operations manager',
      'gas field operations manager',
      'mine manager',
      'oil well servicing manager',
      'quarry manager',
      'shore captain - fishing',
    ],
  },
  {
    code: '80020',
    title: 'Managers in agriculture',
    examples: [
      'apiarist',
      'apple grower',
      'chicken farmer',
      'dairy farmer',
      'domestic animal breeder',
      'fruit farmer',
      'hog breeder',
      'horse breeder',
      'maple syrup producer',
      'market gardener',
      'potato farmer',
      'rancher',
      'seed grower',
      'sod farmer',
      'vegetable grower',
      'vineyard manager',
      'viticulturist',
      'wheat farmer',
    ],
  },
  {
    code: '80021',
    title: 'Managers in horticulture',
    examples: [
      'Christmas tree farm operator',
      'flower grower',
      'greenhouse manager',
      'greenhouse operator',
      'nursery manager',
      'nursery operator',
      'plant grower - nursery',
    ],
  },
  {
    code: '80022',
    title: 'Managers in aquaculture',
    examples: [
      'aquaculture manager',
      'aquaculture operator',
      'fish farm operator',
      'fish farmer',
      'fish hatchery manager',
      'fish hatchery operator',
      'fish hatchery supervisor',
      'mussel grower',
      'oyster grower',
      'salmon grower',
      'shellfish farmer',
      'trout farmer',
    ],
  },
  {
    code: '82010',
    title: 'Supervisors, logging and forestry',
    examples: [
      'forest operations supervisor',
      'forestry crew supervisor',
      'hook tender - logging',
      'logging contractor',
      'logging foreman/woman',
      'production supervisor - logging',
      'silviculture supervisor',
      'woods foreman/woman',
    ],
  },
  {
    code: '82020',
    title: 'Supervisors, mining and quarrying',
    examples: [
      'fill foreman/woman - underground mining',
      'mine captain',
      'mine foreman/woman',
      'mine supervisor',
      'quarry supervisor',
      'surface mine supervisor',
      'track boss - underground mining',
      'underground mine foreman/woman',
    ],
  },
  {
    code: '82021',
    title: 'Contractors and supervisors, oil and gas drilling and services',
    examples: ['fracturing supervisor', 'multi-service operator - oil field services', 'rig manager'],
  },
  {
    code: '82030',
    title: 'Agricultural service contractors and farm supervisors',
    examples: [
      'artificial insemination service contractor',
      'crop harvesting service contractor',
      'farm foreman/woman',
      'farm supervisor',
      'feedlot foreman/woman',
      'hog operation supervisor',
      'livestock breeding service contractor',
      'poultry farm foreman/woman',
      'ranch foreman/woman',
      'vegetable farm foreman/woman',
    ],
  },
  {
    code: '82031',
    title: 'Contractors and supervisors, landscaping, grounds maintenance and horticulture services',
    examples: [
      'greenhouse supervisor',
      'grounds maintenance contractor',
      'groundskeeping supervisor',
      'horticulture workers supervisor',
      'interior plantscaping contractor',
      'landscaping contractor',
      'landscaping foreman/woman',
      'landscaping supervisor',
      'lawn care contractor',
      'nursery foreman/woman',
      'park maintenance supervisor',
      'tree service contractor',
    ],
  },
  {
    code: '83100',
    title: 'Underground production and development miners',
    examples: [
      'blaster - underground mining',
      'chute blaster - underground mining',
      'diamond driller - underground mining',
      'drift miner',
      'driller - underground mining',
      'hardrock miner apprentice',
      'hoist operator - underground mining',
      'miner',
      'mining machine operator',
      'powderman/woman - underground mining',
      'raise miner',
      'roadheader operator',
      'scooptram operator',
      'shaft inspector',
    ],
  },
  {
    code: '83101',
    title: 'Oil and gas well drillers, servicers, testers and related workers',
    examples: [
      'assistant driller - oil and gas well drilling',
      'directional drilling operator',
      'downhole tool operator',
      'drill stem tester',
      'driller - oil and gas drilling',
      'electric line operator',
      'logging and perforating operator',
      'service rig operator',
      'slickline operator',
      'well testing operator',
      'wireline operator',
    ],
  },
  {
    code: '83110',
    title: 'Logging machinery operators',
    examples: [
      'chipping machine operator',
      'delimber operator',
      'feller buncher operator',
      'feller forwarder operator',
      'grapple operator - logging',
      'linehorse operator',
      'loader operator - logging',
      'log processor operator',
      'rigging slinger - logging',
      'shortwood harvester operator',
      'slasher operator - logging',
      'steel spar operator - logging',
      'treelength forwarder operator',
      'yarder operator - logging',
    ],
  },
  {
    code: '83120',
    title: 'Fishing masters and officers',
    examples: [
      'fishing vessel boatswain',
      'fishing vessel deck officer',
      'fishing vessel first mate',
      'fishing vessel master',
      'offshore fishing vessel captain',
      'trawler captain',
    ],
  },
  {
    code: '83121',
    title: 'Fishermen/women',
    examples: [
      'fisherman/woman',
      'fishing vessel skipper',
      'inshore fisherman/woman',
      'lobster fisherman/woman',
      'longliner fisherman/woman',
      'seiner fisherman/woman',
    ],
  },
  {
    code: '84100',
    title: 'Underground mine service and support workers',
    examples: [
      'backfiller - underground mining',
      'blaster helper - underground mining',
      'cage tender',
      'conveyor operator - underground mining',
      'crusher operator - underground mining',
      'driller helper - underground mining',
      'haulageman/woman - underground mining',
      'lamp keeper - underground mining',
      'materialman/woman - underground mining',
      'mine construction worker',
      'orepass tender - underground mining',
      'pipeworker - underground mining',
      'raise miner helper',
      'skip tender',
      'timberman/woman - underground mining',
      'trainman/woman - underground mining',
    ],
  },
  {
    code: '84101',
    title: 'Oil and gas well drilling and related workers and services operators',
    examples: [
      'acidizer - oil field services',
      'blender operator - oil field services',
      'control room operator - offshore drilling',
      'derrickhand',
      'motorhand - oil and gas drilling',
      'offshore drilling rig technician',
      'oil well cementer - oil field services',
      'pumper operator - oil field services',
    ],
  },
  {
    code: '84110',
    title: 'Chain saw and skidder operators',
    examples: [
      'bucker',
      'chain saw operator - logging',
      'faller',
      'feller',
      'forest worker - logging',
      'grapple skidder operator',
      'landingman/woman',
      'pieceworker - logging',
      'skidder operator',
    ],
  },
  {
    code: '84111',
    title: 'Silviculture and forestry workers',
    examples: [
      'clearing saw operator - forestry',
      'forest firefighter',
      'forestry crew worker',
      'pieceworker - silviculture',
      'scarification equipment operator - forestry',
      'silviculture worker',
      'thinning saw operator',
    ],
  },
  {
    code: '84120',
    title: 'Specialized livestock workers and farm machinery operators',
    examples: [
      'artificial inseminator',
      'cattle herdsperson',
      'dairy herdsperson',
      'farm machinery operator',
      'harvester machine operator',
      'horse trainer',
      'pork production technician',
      'swine herdsperson',
    ],
  },
  {
    code: '84121',
    title: 'Fishing vessel deckhands',
    examples: [
      'fishing vessel crewman/woman',
      'fishing vessel deckhand',
      'fishing vessel icer',
      'fishing vessel netmender',
      'seiner deckhand',
      'trawlerman/woman',
    ],
  },
  {
    code: '85100',
    title: 'Livestock labourers',
    examples: [
      'beef cattle farm worker',
      'cattle ranch labourer',
      'dairy farm worker',
      'livestock labourer',
      'poultry farm worker',
    ],
  },
  {
    code: '85101',
    title: 'Harvesting labourers',
    examples: [
      'apple picker',
      'berry picker',
      'crop farm labourer - harvesting',
      'fruit picker',
      'fruit sorter - farm',
      'harvest hand',
      'vegetable packer - farm',
    ],
  },
  {
    code: '85102',
    title: 'Aquaculture and marine harvest labourers',
    examples: [
      'aquaculture support worker',
      'clam digger',
      'dulse gatherer',
      'fish farm helper',
      'fish tagger',
      'fry marker',
      'marine plant gatherer',
      'oyster picker',
      'sea farm attendant',
      'seaweed gatherer',
      'shellfish harvester',
    ],
  },
  {
    code: '85103',
    title: 'Nursery and greenhouse labourers',
    examples: [
      'forest nursery labourer',
      'greenhouse labourer',
      'horticulture labourer',
      'hothouse labourer',
      'hydroponics worker',
      'nursery labourer',
    ],
  },
  {
    code: '85104',
    title: 'Trappers and hunters',
    examples: ['fur trapper', 'game trapper', 'hunter', 'sealer', 'trapper'],
  },
  {
    code: '85110',
    title: 'Mine labourers',
    examples: [
      'chute puller',
      'mine helper',
      'mine labourer',
      'miner helper - underground mining',
      'pit scaler - underground mining',
      'shoveller - underground mining',
      'underground labourer - mining',
    ],
  },
  {
    code: '85111',
    title: 'Oil and gas drilling, servicing and related labourers',
    examples: [
      'floorman/woman - oil and gas drilling',
      'leasehand',
      'oil field labourer',
      'roughneck',
      'roustabout',
      'service rig helper',
      'shakerhand',
      'swamper - oil and gas',
      'well treatment helper',
      'wireline helper',
    ],
  },
  {
    code: '85120',
    title: 'Logging and forestry labourers',
    examples: [
      'chokerman/woman',
      'forestry labourer',
      'logging labourer',
      'seasonal tree planter',
      'swamper - logging and forestry',
    ],
  },
  {
    code: '85121',
    title: 'Landscaping and grounds maintenance labourers',
    examples: [
      'bulb planter - landscaping',
      'cemetery labourer',
      'gardening helper',
      'golf course worker',
      'grass cutter',
      'grounds maintenance worker',
      'landscape labourer',
      'park maintenance labourer',
      'park maintenance worker',
      'sod layer',
      'transplanter - landscape maintenance',
    ],
  },
  {
    code: '90010',
    title: 'Manufacturing managers',
    examples: [
      'automobile production manager',
      'clothing factory manager',
      'dairy plant manager',
      'distillery manager',
      'factory superintendent',
      'foundry manager',
      'manufacturing manager',
      'manufacturing operations manager',
      'manufacturing plant manager',
      'printing plant manager',
      'production manager - manufacturing',
      'textile mill manager',
      'tire plant manager',
    ],
  },
  {
    code: '90011',
    title: 'Utilities managers',
    examples: [
      'director of waste management',
      'director of water pollution control',
      'distribution systems director - utilities',
      'electric generating plant manager',
      'electric power plant manager',
      'electrical power transmission operations director',
      'gas supply operations manager',
      'liquid waste facility manager',
      'refined petroleum products distribution manager',
      'sewage treatment plant manager',
      'water filtration plant manager',
      'water supply director',
    ],
  },
  {
    code: '92010',
    title: 'Supervisors, mineral and metal processing',
    examples: [
      'blast furnace foreman/woman',
      'brick and tile foreman/woman',
      'cement processing supervisor',
      'coremaking foreman/woman - foundry',
      'die casting supervisor',
      'glass cutters supervisor',
      'lead refining foreman/woman',
      'lime preparation foreman/woman',
      'melting and roasting department supervisor',
      'ore milling supervisor',
      'roll shop operations coach - steel mill',
      'steel mill shift coordinator',
    ],
  },
  {
    code: '92011',
    title: 'Supervisors, petroleum, gas and chemical processing and utilities',
    examples: [
      'chemical processing supervisor',
      'cosmetics processing foreman/woman',
      'natural gas plant foreman/woman',
      'paint processing foreman/woman',
      'petroleum refining supervisor',
      'pharmaceuticals production supervisor',
      'pipeline operations supervisor',
      'power station supervisor',
      'sewage treatment plant supervisor',
      'specialty chemicals production supervisor',
      'water purification plant foreman/woman',
    ],
  },
  {
    code: '92012',
    title: 'Supervisors, food and beverage processing',
    examples: [
      'bottling supervisor - food and beverage processing',
      'brewer supervisor',
      'fish processing supervisor',
      'flour milling team supervisor',
      'food product testers supervisor',
      'hazard analysis critical control point (HACCP) coordinator',
      'meat packing supervisor',
      'packaging supervisor - food and beverage processing',
      'poultry graders foreman/woman',
      'production supervisor - food and beverage processing',
      'tobacco processing supervisor',
      'vegetable packing supervisor',
    ],
  },
  {
    code: '92013',
    title: 'Supervisors, plastic and rubber products manufacturing',
    examples: [
      'blow moulding foreman/woman - rubber and plastic manufacturing',
      'calendering foreman/woman - rubber and plastic manufacturing',
      'injection moulding foreman/woman - rubber and plastic manufacturing',
      'injection moulding supervisor - plastic products manufacturing',
      'plastic products manufacturing supervisor',
      'rubber products manufacturing supervisor',
      'tire building supervisor',
    ],
  },
  {
    code: '92014',
    title: 'Supervisors, forest products processing',
    examples: [
      'coating room foreman/woman - pulp and paper',
      'lumber grading foreman/woman - wood processing',
      'paper converting supervisor',
      'paper machine foreman/woman',
      'paper mill foreman/woman',
      'plywood making foreman/woman',
      'pulp mill foreman/woman',
      'sawmill foreman/woman',
      'shift operating supervisor - pulp and paper',
      'shingle mill foreman/woman',
      'tour foreman/woman - pulp and paper',
      'waferboard foreman/woman',
      'wood-treating plant foreman/woman',
    ],
  },
  {
    code: '92015',
    title: 'Supervisors, textile, fabric, fur and leather products processing and manufacturing',
    examples: [
      'boot and shoe foreman/woman',
      'canvas products manufacturing foreman/woman',
      'dye room supervisor',
      'embroidery supervisor - fabric products',
      'fabric-cutting department foreman/woman',
      'finishing supervisor - textiles',
      'foreman/woman - textile processing',
      'fur dressing foreman/woman',
      'hat and cap makers foreman/woman',
      'knitting supervisor',
      'sample room foreman/woman - leather products',
      'sewing machine operators supervisor',
      'stitching department supervisor',
      'tannery foreman/woman',
    ],
  },
  {
    code: '92020',
    title: 'Supervisors, motor vehicle assembling',
    examples: [
      'area coordinator - motor vehicle manufacturing',
      'assembly foreman/woman - motor vehicle manufacturing',
      'assembly supervisor - motor vehicle manufacturing',
      'assembly zone supervisor - motor vehicle manufacturing',
      'foreman/woman - motor vehicle manufacturing',
      'general assembly supervisor - motor vehicle manufacturing',
    ],
  },
  {
    code: '92021',
    title: 'Supervisors, electronics and electrical products manufacturing',
    examples: [
      'assembly and testing supervisor - electronic equipment manufacturing',
      'communications equipment assembly supervisor - electronic equipment manufacturing',
      'electrical appliance assembly foreman/woman',
      'electrical appliance assembly supervisor',
      'electrical equipment manufacturing supervisor',
      'electrical motor assembly foreman/woman',
      'electrical transformer assembly foreman/woman',
      'electronic assembly foreman/woman',
      'final assembly and testing foreman/woman - electronic equipment manufacturing',
      'printed circuit board (PCB) fabrication foreman/woman - electronic equipment manufacturing',
      'production supervisor - electronics manufacturing',
      'switchgear assembly foreman/woman',
      'systems test foreman/woman - electronic equipment manufacturing',
    ],
  },
  {
    code: '92022',
    title: 'Supervisors, furniture and fixtures manufacturing',
    examples: [
      'desk assembly foreman/woman',
      'furniture and fixtures manufacturing supervisor',
      'furniture assembly foreman/woman',
      'furniture finishers supervisor',
      'laminating foreman/woman',
      'woodworking machine operator foreman/woman - furniture and fixtures manufacturing',
      'woodworking machine operators supervisor - furniture and fixtures manufacturing',
    ],
  },
  {
    code: '92023',
    title: 'Supervisors, other mechanical and metal products manufacturing',
    examples: [
      'aircraft assembly foreman/woman',
      'engine assembly foreman/woman (except aircraft)',
      'helicopter assembly foreman/woman',
      'metalworking machine operators foreman/woman',
      'mobile home assembly foreman/woman',
      'shipyard painter foreman/woman',
      'snowmobile assembly supervisor',
      'truck trailer assembly foreman/woman',
    ],
  },
  {
    code: '92024',
    title: 'Supervisors, other products manufacturing and assembly',
    examples: [
      'bicycle assembly foreman/woman',
      'clock and watch assembly foreman/woman',
      'jewellery manufacturing foreman/woman',
      'millwork assembly supervisor',
      'silverware manufacturing supervisor',
      'sports equipment assembly supervisor',
      'toy manufacturing supervisor',
    ],
  },
  {
    code: '92100',
    title: 'Power engineers and power systems operators',
    examples: [
      'apprentice power dispatcher',
      'auxiliary plant operator',
      'building systems technician',
      'control room operator - electrical power systems',
      'distribution control operator - electrical power systems',
      'electrical power systems operator',
      'energy from waste plant operator',
      'nuclear generating station field operator',
      'nuclear reactor operator',
      'power dispatcher - generating station',
      'power engineer',
      'power plant operator',
      'power plant stationary engineer',
      'stationary engineer',
    ],
  },
  {
    code: '92101',
    title: 'Water and waste treatment plant operators',
    examples: [
      'environmental systems operator - water treatment',
      'liquid waste process operator',
      'sewage plant operator',
      'waste treatment plant operator',
      'wastewater treatment plant operator',
      'water filtration plant operator',
      'water purification plant operator',
      'water treatment plant operator',
    ],
  },
  {
    code: '93100',
    title: 'Central control and process operators, mineral and metal processing',
    examples: [
      'blast furnace operator',
      'central control caster',
      'central control room operator - primary metal processing',
      'chief flotation operator',
      'console operator - cement manufacturing',
      'pelletizing control operator',
      'roaster operator - primary metal processing',
      'rolling mill control operator',
    ],
  },
  {
    code: '93101',
    title: 'Central control and process operators, petroleum, gas and chemical processing',
    examples: [
      'acid plant operator',
      'chemical process operator',
      'chemical processing chief technician',
      'chlor-alkali plant cell room operator',
      'gas field production operator',
      'gas plant operator',
      'gas recovery operator',
      'master operator - chemical processing',
      'oil refinery process operator',
      'panel operator - chemical processing',
      'petroleum process operator',
      'pharmaceutical processing operator',
      'pipeline compressor station operator',
      'process technician - chemical processing',
      'refinery process technician',
    ],
  },
  {
    code: '93102',
    title: 'Pulping, papermaking and coating control operators',
    examples: [
      'bleach plant operator - pulp and paper',
      'panelboard operator - pulp and paper',
      'paper machine control operator',
      'pulping control operator',
      'pulping group operator - pulp and paper',
      'pulping technician',
    ],
  },
  {
    code: '93200',
    title: 'Aircraft assemblers and aircraft assembly inspectors',
    examples: [
      'aircraft assembler',
      'aircraft assembly inspector',
      'airframe assembler',
      'bench and structural assembler',
      'bench fitter mechanic - aircraft assembly',
      'rigger - aircraft assembly',
      'wing tank mechanic - aircraft assembly',
    ],
  },
  {
    code: '94100',
    title: 'Machine operators, mineral and metal processing',
    examples: [
      'aluminum classifier',
      'asbestos dryer',
      'billet heater',
      'brick and tile crusher operator',
      'cement miller',
      'extrusion press operator',
      'foil-winding machine operator',
      'ladle pourer',
      'lead refiner',
      'lime kiln operator',
      'mica sheet laminator',
      'quenching car operator',
      'scrap metal shredder',
      'sintering machine operator',
      'slurry equipment operator',
      'steel roller',
      'uranium classifier operator',
      'zinc cell operator',
    ],
  },
  {
    code: '94101',
    title: 'Foundry workers',
    examples: [
      'bench moulder - foundry',
      'casting machine operator - foundry',
      'ceramic mouldmaker - foundry',
      'die-casting machine operator',
      'foundry worker',
      'furnace operator - foundry',
      'manual moulder',
      'melter - foundry',
      'pit moulder',
      'sand coremaker',
      'sand moulder',
    ],
  },
  {
    code: '94102',
    title: 'Glass forming and finishing machine operators and glass cutters',
    examples: [
      'bottle machine operator - glass products manufacturing',
      'float operator - glass forming',
      'glass beveller',
      'glass edger',
      'glass moulder',
      'glass-blowing machine operator',
      'glass-finishing machine operator',
      'glass-pressing machine operator',
      'hand glass cutter',
      'mirror maker',
    ],
  },
  {
    code: '94103',
    title: 'Concrete, clay and stone forming operators',
    examples: [
      'asbestos shingle presser',
      'brick presser operator',
      'clay press operator',
      'clay products moulder',
      'concrete block maker',
      'finisher - concrete, clay and stone products',
      'granite cutter',
      'marble cutter',
      'precast concrete moulder',
      'precast concrete slab maker',
      'stone driller',
      'stone planer',
      'stonework moulder',
    ],
  },
  {
    code: '94104',
    title: 'Inspectors and testers, mineral and metal processing',
    examples: [
      'asbestos grader',
      'brick and tile inspector',
      'castings tester - primary metal and mineral products processing',
      'clay products grader',
      'core tester - foundry',
      'fibreglass tester',
      'glass quality control inspector',
      'heat treating inspector - metal processing',
      'metal processing inspector',
      'mineral sampler - mineral products processing',
      'molten metal sampler - primary metal processing',
      'salvage glass inspector',
      'sheet steel inspector - primary metal processing',
      'steel tester - primary metal processing',
    ],
  },
  {
    code: '94105',
    title: 'Metalworking and forging machine operators',
    examples: [
      'bending press operator - metal fabrication',
      'cold press operator - metal forging',
      'disk flange operator - metal fabrication',
      'drop hammer operator - metal forging',
      'electric arc cutter - metal products manufacturing',
      'forging press operator',
      'metal-forming machine set-up operator',
      'metalworking machine operator',
      'power brake operator - metal fabrication',
      'power press operator - metal fabrication',
      'punch press setter - metalworking',
      'roll operator - metal fabrication',
      'saw operator - metal fabrication',
      'shear setter - metal fabrication',
      'sheet metal roll operator - metal fabrication',
    ],
  },
  {
    code: '94106',
    title: 'Machining tool operators',
    examples: [
      'aircraft parts etcher',
      'boring mill operator - metal machining',
      'computer numerical control (CNC) machining tool operator',
      'lathe machining operator',
      'machining tool operator',
      'milling machine set-up operator',
      'radial drill operator - metal machining',
    ],
  },
  {
    code: '94107',
    title: 'Machine operators of other metal products',
    examples: [
      'bolt machine operator',
      'bullet maker',
      'can-forming machine operator',
      'chain-making machine operator',
      'metal cable maker operator',
      'nail making machine tender',
      'spring machine operator',
      'wire screen maker',
      'wire weaver',
    ],
  },
  {
    code: '94110',
    title: 'Chemical plant machine operators',
    examples: [
      'batch mixer - chemical processing',
      'blender operator - chemical processing',
      'capsule machine operator - chemical processing',
      'evaporator operator - chemical processing',
      'formulations blender operator - chemical processing',
      'glue blender - chemical processing',
      'granulator machine operator - chemical processing',
      'mixer - chemical processing',
      'screener - chemical processing',
      'soap maker',
    ],
  },
  {
    code: '94111',
    title: 'Plastics processing machine operators',
    examples: [
      'bag machine operator',
      'banbury operator - plastic manufacturing',
      'blow moulding machine operator - plastic manufacturing',
      'calender operator - plastic manufacturing',
      'extruder operator - plastic manufacturing',
      'injection moulding operator - plastic manufacturing',
      'plastics press operator',
      'printing roller moulder - plastic manufacturing',
      'solution mixer operator - plastic manufacturing',
      'thermoforming operator',
    ],
  },
  {
    code: '94112',
    title: 'Rubber processing machine operators and related workers',
    examples: [
      'banbury operator - rubber products manufacturing',
      'blow-out and test operator - rubber products',
      'calender machine operator - rubber products manufacturing',
      'cure operator - rubber products manufacturing',
      'extruder operator - rubber products manufacturing',
      'moulding press tender - rubber products manufacturing',
      'refiner mill tender - rubber products manufacturing',
      'rubber belt builder',
      'rubber hose builder',
      'rubber processing machine operator',
      'rubber products assembler',
      'rubber products manufacturing inspector',
      'scrap rubber grinder - recycling',
      'tire builder',
      'tire inspector',
    ],
  },
  {
    code: '94120',
    title: 'Sawmill machine operators',
    examples: [
      'circular saw operator',
      'edgerman/woman',
      'head sawyer',
      'log cut-off operator',
      'planer operator',
      'resaw operator',
      'shake splitter',
      'trimmerman/woman',
    ],
  },
  {
    code: '94121',
    title: 'Pulp mill, papermaking and finishing machine operators',
    examples: [
      'assistant bleacher operator - pulp and paper',
      'assistant coating operator - pulp and paper',
      'calender operator - pulp and paper',
      'cook - pulp and paper',
      'digester operator - pulp and paper',
      'embossing calender operator - pulp and paper',
      'fourth hand - pulp and paper',
      'graderman/woman - pulp and paper',
      'grinderman/woman - pulp and paper',
      'paper machine operator',
      'paper-coating machine operator',
      'refiner operator - pulp and paper',
      'sheeter operator - pulp and paper',
      'thermomechanical pulp assistant operator',
      'thermomechanical pulp operator',
      'winder operator - pulp and paper',
      'winderman/woman - pulp and paper',
    ],
  },
  {
    code: '94122',
    title: 'Paper converting machine operators',
    examples: [
      'box maker operator',
      'carton forming machine operator',
      'core maker - paper converting',
      'envelope maker operator',
      'paper bag machine operator',
      'paper box machine operator',
    ],
  },
  {
    code: '94123',
    title: 'Lumber graders and other wood processing inspectors and graders',
    examples: [
      'grader - wood processing',
      'grader tallyman/woman - wood processing',
      'lumber grader',
      'particleboard grader - wood processing',
      'plywood grader',
      'plywood inspector',
      'veneer grader',
      'wood measurer',
    ],
  },
  {
    code: '94124',
    title: 'Woodworking machine operators',
    examples: [
      'boring machine operator - woodworking',
      'computer numerically controlled (CNC) band saw operator',
      'drill operator - woodworking',
      'drum sander - woodworking',
      'edge bander operator - woodworking',
      'glue machine operator - woodworking',
      'lathe operator - woodworking',
      'planer operator - woodworking',
      'rough mill operator - woodworking',
      'shaper - woodworking',
      'woodworking machine operator',
    ],
  },
  {
    code: '94129',
    title: 'Other wood processing machine operators',
    examples: [
      'barker operator',
      'chipper operator',
      'lumber kiln operator',
      'particleboard line operator',
      'plywood panel assembler',
      'timber treating tank operator',
      'veneer dryer tender',
      'veneer lathe operator',
      'waferboard press operator',
      'wood treater operator',
    ],
  },
  {
    code: '94130',
    title: 'Textile fibre and yarn, hide and pelt processing machine operators and workers',
    examples: [
      'autoclave tender - textile manufacturing',
      'bleaching range operator - textile manufacturing',
      'calender operator - textile manufacturing',
      'carding machine operator - textile manufacturing',
      'dyeing range operator - textile manufacturing',
      'fabric-printing machine operator',
      'finishing range operator - textile manufacturing',
      'leather buffer - hide and pelt processing',
      'leather stretcher - hide and pelt processing',
      'tanner - hide and pelt processing',
      'twisting operator - textile manufacturing',
      'yarn doubler - textile manufacturing',
    ],
  },
  {
    code: '94131',
    title: 'Weavers, knitters and other fabric making occupations',
    examples: [
      'carpet weaver',
      'drawer-in - textiles',
      'drawing-in machine operator',
      'embroidery machine operator',
      'hosiery knitter',
      'knitter - textiles',
      'knitting machine operator',
      'loom operator',
      'mattress sewing machine operator',
      'pinning machine operator',
      'quilting machine operator',
      'tufting operator',
      'warp knitting machine tender',
      'warp tier-in',
      'weaver - textiles',
    ],
  },
  {
    code: '94132',
    title: 'Industrial sewing machine operators',
    examples: [
      'fur sewing machine operator',
      'leather products sewing machine operator',
      'lining stitcher',
      'sample sewer',
      'serging machine operator',
      'sewing machine operator',
      'shoe sewer',
    ],
  },
  {
    code: '94133',
    title: 'Inspectors and graders, textile, fabric, fur and leather products manufacturing',
    examples: [
      'cloth inspector',
      'colour grader - textiles',
      'fabric examiner',
      'fibre inspector - textiles',
      'fur grader',
      'fur matcher - fur products manufacturing',
      'garment inspector',
      'hide and pelt processing inspector',
      'leather goods inspector',
      'leather grader',
      'quality control inspector - textiles',
      'shoe inspector',
      'textile grader',
      'textile product sampler',
      'yarn inspector',
    ],
  },
  {
    code: '94140',
    title: 'Process control and machine operators, food and beverage processing',
    examples: [
      'bakery machine operator - food and beverage processing',
      'brewhouse operator',
      'canning machine operator',
      'chocolate refiner',
      'cigarette machine tender',
      'citrus juice extractor',
      'control room operator - food and beverage processing',
      'corn sugar refinery operator',
      'dry foods mixer operator',
      'dryer tender - tobacco processing',
      'fermenter operator',
      'fruit preserver - food and beverage processing',
      'fryer operator - food and beverage processing',
      'grain-processing machine operator',
      'meat grinder - food and beverage processing',
      'pasteurizer operator - food and beverage processing',
      'tobacco blender',
      'winery-press operator',
    ],
  },
  {
    code: '94141',
    title: 'Industrial butchers and meat cutters, poultry preparers and related workers',
    examples: [
      'beef boner',
      'ham cutter',
      'industrial butcher',
      'industrial meat cutter',
      'meat trimmer',
      'poultry preparer',
      'slaughterer',
    ],
  },
  {
    code: '94142',
    title: 'Fish and seafood plant workers',
    examples: [
      'fish cake maker',
      'fish canning machine operator - fish processing',
      'fish cleaner and cutter - fish processing',
      'fish cutting machine operator - fish processing',
      'fish plant worker',
      'seafood preparer - fish and seafood processing',
      'shellfish processor',
      'shellfish shucker',
      'trimmer - fish processing',
    ],
  },
  {
    code: '94143',
    title: 'Testers and graders, food and beverage processing',
    examples: [
      'beef grader',
      'beer tester',
      'cheese grader',
      'fish grader',
      'milk grader',
      'poultry grader',
      'product tester - food and beverage processing',
      'tobacco grader',
    ],
  },
  {
    code: '94150',
    title: 'Plateless printing equipment operators',
    examples: [
      'colour copier operator',
      'electronic sign maker operator',
      'embossing-imprinting machine operator',
      'laser printer operator',
      'printing machine operator - printing',
      'quick print machine operator',
      'silk-screen printing machine operator',
      'wallpaper printer',
    ],
  },
  {
    code: '94151',
    title: 'Camera, platemaking and other prepress occupations',
    examples: [
      'camera operator - graphic arts',
      'cylinder preparer - printing',
      'dot etcher - printing',
      'file preparation operator',
      'film stripper-assembler',
      'platemaker - printing',
      'pre-flight operator - printing',
      'prepress technician',
      'printing plate engraver',
      'proofmaker',
      'screenmaker',
      'studio-image-processing system operator',
    ],
  },
  {
    code: '94152',
    title: 'Binding and finishing machine operators',
    examples: [
      'binder - printing',
      'binder and finisher',
      'bindery operator',
      'bookbinding machine operator',
      'Cerlox binder operator',
      'cutter operator - printing',
      'finishing machine operator - printing',
      'foil stamper - printing',
      'gathering machine set-up operator - printing',
      'gold leaf stamper',
      'laminating machine operator',
      'machine bookbinder',
      'perforator operator - printing',
      'stitcher operator - printing',
    ],
  },
  {
    code: '94153',
    title: 'Photographic and film processors',
    examples: [
      'darkroom technician',
      'film developer',
      'film printing machine operator',
      'film processor',
      'photofinisher',
      'photograph developer',
      'photograph inspector - photographic processing',
      'photographic processor',
    ],
  },
  {
    code: '94200',
    title: 'Motor vehicle assemblers, inspectors and testers',
    examples: [
      'assembly inspector - motor vehicle manufacturing',
      'auto assembly worker',
      'body assembler - motor vehicle manufacturing',
      'car assembler',
      'chassis inspector - motor vehicle manufacturing',
      'door fitter',
      'motor vehicle assembler',
      'sport utility vehicle (SUV) assembler',
      'test driver - automobile assembly',
      'transmission installer',
      'van assembler',
    ],
  },
  {
    code: '94201',
    title: 'Electronics assemblers, fabricators, inspectors and testers',
    examples: [
      'capacitor assembler',
      'circuit board assembler',
      'component inserting machine operator',
      'crystal final tester',
      'electronic components tester',
      'electronics assembler',
      'electronics inspector - electronic equipment manufacturing',
      'finished product inspector - electronic equipment manufacturing',
      'precision instrument assembler - electronic equipment manufacturing',
      'printed circuit board (PCB) assembly inspector',
      'surface mount assembler',
      'through-hole assembler',
      'wafer fabrication operator',
      'wave soldering machine operator',
      'wiring and assembly operator',
    ],
  },
  {
    code: '94202',
    title: 'Assemblers and inspectors, electrical appliance, apparatus and equipment manufacturing',
    examples: [
      'circuit breaker assembler',
      'coffee maker assembler',
      'dishwasher assembler',
      'electric clothes dryer assembler',
      'electric lawn mower assembler',
      'electric refrigerator assembler',
      'electrical appliance assembler',
      'electrical appliance assembly inspector',
      'electrical control assembly inspector',
      'electrical equipment production assembler',
      'hair dryer assembler',
    ],
  },
  {
    code: '94203',
    title: 'Assemblers, fabricators and inspectors, industrial electrical motors and transformers',
    examples: [
      'alternating current (AC) and direct current (DC) motor inspector and tester',
      'alternating current (AC) and direct current (DC) motors fitter-assembler',
      'control panel assembler',
      'electrical control panel inspector',
      'electrical fitter',
      'electrical switchgear panel wirer',
      'motor and generator assembler and wirer',
      'panelboard assembler - industrial electrical equipment',
      'power transformer assembler',
      'switchgear and control panel assembler - industrial electrical equipment',
      'switchgear fitter-wirer',
      'transformer coil winder',
      'transformer inspector',
      'transformer winder',
    ],
  },
  {
    code: '94204',
    title: 'Mechanical assemblers and inspectors',
    examples: [
      'automotive engine assembler',
      'garden machinery assembler',
      'gearbox assembler',
      'gearcase assembler',
      'hydraulic hoist assembler',
      'mechanical assembler',
      'sewing machine assembler',
      'snowmobile assembler',
      'tractor assembler',
      'transmission assembler',
      'truck assembler',
      'truck assembly inspector',
      'truck trailer assembler',
      'vending machine assembler',
    ],
  },
  {
    code: '94205',
    title: 'Machine operators and inspectors, electrical apparatus manufacturing',
    examples: [
      'assembly machine setter - electrical equipment manufacturing',
      'dry cell charge machine operator',
      'electrical dry battery inspector',
      'electrical light bulb inspector',
      'epoxy coating machine operator',
      'machine operator - electrical equipment manufacturing',
      'machine set-up operator - electrical appliance manufacturing',
      'production line battery repairer - electrical equipment manufacturing',
    ],
  },
  {
    code: '94210',
    title: 'Furniture and fixture assemblers, finishers, refinishers and inspectors',
    examples: [
      'assembly inspector - furniture manufacturing',
      'chair assembler',
      'desk assembler',
      'fixture assembler',
      'furniture assembler',
      'furniture finisher',
      'furniture inspector',
      'furniture polisher - furniture finishing',
      'furniture refinisher',
      'furniture stainer',
      'furniture stripper',
      'metal furniture assembler',
      'table assembler',
      'touch-up person - furniture finishing',
      'trimmer - furniture finishing',
      'wash-off operator - furniture finishing',
      'wood finisher - furniture finishing and refinishing',
      'wood furniture assembler',
    ],
  },
  {
    code: '94211',
    title: 'Assemblers and inspectors of other wood products',
    examples: [
      'manufactured housing production worker',
      'millwork assembler',
      'prefabricated housing assembler',
      'sash and door inspector',
      'wood products assembler',
      'wood products assembling inspector',
      'wood products bench assembler',
      'wooden box assembler',
      'wooden crate builder',
      'wooden door maker',
      'wooden pallet maker',
    ],
  },
  {
    code: '94212',
    title: 'Plastic products assemblers, finishers and inspectors',
    examples: [
      'fibreglass laminator',
      'plastic bottle trimmer',
      'plastic parts assembler',
      'plastic products fabricator',
      'plastic products inspector and tester',
      'plastic tank assembler',
      'plastics assembler',
      'plastics inspector',
      'plastics trimmer',
      'skylight assembler - plastic products manufacturing',
    ],
  },
  {
    code: '94213',
    title: 'Industrial painters, coaters and metal finishing process operators',
    examples: [
      'assembly line painter',
      'automatic paint-sprayer operator',
      'automobile painter - motor vehicle manufacturing',
      'aviation painter',
      'ceramic and enamel refinisher',
      'coating machine operator - industrial painting and coating',
      'electrogalvanizing machine operator',
      'enameller - ship and boat building',
      'hot-dip galvanizer - metal plating',
      'industrial products painter',
      'metal coater operator',
      'metal electroplater',
      'production painter-finisher',
      'spray paint operator',
    ],
  },
  {
    code: '94219',
    title: 'Other products assemblers, finishers and inspectors',
    examples: [
      'aluminum boat assembler',
      'antenna assembler',
      'bicycle assembler',
      'boat assembler',
      'boat inspector',
      'brush making machine operator',
      'button machine operator',
      'camera assembly inspector',
      'canoe assembler',
      'crayon making machine tender',
      'eyeglass frame assembler',
      'fibreglass boat assembler',
      'golf club assembler',
      'jewellery annealer',
      'jewellery inspector',
      'lampshade assembler',
      'motorboat assembler',
      'piano assembly inspector',
      'prism maker operator',
      'sailboat assembler',
      'skate press operator',
      'sports ball moulder',
      'stuffing machine tender',
      'tennis ball maker operator',
      'toy inspector',
      'Venetian blind assembler',
      'watch assembler',
    ],
  },
  {
    code: '95100',
    title: 'Labourers in mineral and metal processing',
    examples: [
      'asbestos worker - mineral processing',
      'brick and tile kiln cleaner',
      'core shop labourer - foundry',
      'extrusion helper - primary metal processing',
      'fibreglass oven charger helper',
      'furnace loader - primary metal and mineral products processing',
      'glass packer',
      'lime kiln operator helper - mineral products processing',
      'metal pourer helper',
      'rolling mill worker - primary metal processing',
      'salt screening labourer',
      'tailings disposal labourer - primary metal processing',
    ],
  },
  {
    code: '95101',
    title: 'Labourers in metal fabrication',
    examples: [
      'blacksmith helper',
      'buffing machine tender - metal products manufacturing',
      'grinder-deburrer',
      'labourer - metal fabrication',
      'metal cleaner - metal products manufacturing',
      'metalworking machine helper',
      'plater helper - metal fabrication',
      'shipfitter helper',
      'shotblaster - metal fabrication',
      'wheelabrator operator - metal products manufacturing',
    ],
  },
  {
    code: '95102',
    title: 'Labourers in chemical products processing and utilities',
    examples: [
      'coating machine feeder - chemical processing',
      'filter cleaner - chemical processing',
      'helper - chemical processing',
      'labourer - gas utility',
      'loader - chemical processing',
      'retort unloader',
      'still cleaner - chemical processing',
      'water intake tender',
      'waterworks labourer',
    ],
  },
  {
    code: '95103',
    title: 'Labourers in wood, pulp and paper processing',
    examples: [
      'beater operator helper - pulp and paper',
      'chip bin attendant - wood processing',
      'conveyor cleaner - pulp and paper',
      'green wood chainman/woman - wood processing',
      'grinder feeder - pulp and paper',
      'labourer - pulp and paper',
      'labourer - wood processing',
      'lumber straightener - wood processing',
      'plywood dryer feeder',
      'sheeter helper - pulp and paper',
      'utility man/woman - pulp and paper',
    ],
  },
  {
    code: '95104',
    title: 'Labourers in rubber and plastic products manufacturing',
    examples: [
      'belt builder helper',
      'labourer - plastic products manufacturing',
      'labourer - rubber products manufacturing',
      'machine offbearer - rubber products manufacturing',
      'mould cleaner - rubber products manufacturing',
      'moulded products stripper - rubber and plastic products manufacturing',
      'roll changer - rubber manufacturing',
      'spreader operator helper - plastic products manufacturing',
    ],
  },
  {
    code: '95105',
    title: 'Labourers in textile processing and cutting',
    examples: [
      'clothing cutter - clothing manufacturing',
      'doffer',
      'dyeing and finishing machine loader',
      'fabric cutter - fabric products manufacturing',
      'fur cutter',
      'glove cutter',
      'helper - textiles',
      'leather cutter',
      'sample cutter',
      'shoe cutter',
      'textile machine cleaner',
      'waste machine feeder',
      'yarn handler',
    ],
  },
  {
    code: '95106',
    title: 'Labourers in food and beverage processing',
    examples: [
      'bottle-washing machine tender - food and beverage processing',
      'brewery labourer',
      'candy packer',
      'dairy helper - food and beverage processing',
      'food processing labourer',
      'frozen food packer',
      'meat packager',
      'nut sorter - food and beverage processing',
      'production helper - food and beverage processing',
      'tobacco processing labourer',
    ],
  },
  {
    code: '95107',
    title: 'Labourers in fish and seafood processing',
    examples: [
      'cannery labourer',
      'fish briner - fish processing',
      'fish plant labourer',
      'fish salter - fish processing',
      'fish weigher',
      'shellfish labourer',
      'shellfish packer - fish processing',
    ],
  },
  {
    code: '95109',
    title: 'Other labourers in processing, manufacturing and utilities',
    examples: [
      'bindery helper',
      'box packer',
      'carton marker',
      'chair sander',
      'clothing plant labourer',
      'electrical products labourer',
      'film cutter - film processing',
      'furniture packer',
      'garment folder',
      'lens blocker',
      'machine packager',
      'seed packager',
      'shoe manufacturing labourer',
      'sorter - recyclable materials',
      'upholsterer helper',
    ],
  },
  {
    code: '00010',
    title: 'Legislators',
    examples: [
      'cabinet minister',
      'city councillor',
      'First Nations band chief',
      'governor general',
      'lieutenant-governor',
      'mayor',
      'Member of Legislative Assembly (MLA)',
      'Member of Parliament (MP)',
      'Member of the National Assembly (MNA)',
      'Member of the Provincial Parliament (MPP)',
      'premier',
      'prime minister',
      'school board trustee',
      'senator',
    ],
  },
  {
    code: '00011',
    title: 'Senior government managers and officials',
    examples: [
      'assistant deputy minister',
      'chief administrative officer - regional municipality',
      'chief statistician - government services',
      'city administrator',
      'deputy minister',
      'director general - government services',
      'executive director - government services',
      'high commissioner',
      'Human Rights Commission chairperson',
    ],
  },
  {
    code: '00012',
    title: 'Senior managers - financial, communications and other business services',
    examples: [
      'advertising agency president',
      'bank president',
      'chief executive officer (CEO) - telephone company',
      'chief financial officer (CFO) - advertising agency',
      'chief operating officer - engineering firm',
      'computing services company president',
      'credit union executive director',
      'executive vice-president - real estate agency',
      'general manager - real estate management company',
      'human resources vice-president - bank',
      'marketing vice-president - clerical staff services',
      'operations vice-president - satellite communication services',
      'president and chief executive officer - financial, communications and other business services',
      'trust company regional vice-president',
    ],
  },
  {
    code: '00013',
    title: 'Senior managers - health, education, social and community services and membership organizations',
    examples: [
      'arts and culture association chairperson',
      'automobile association executive director',
      'business association president',
      'educational institution chief financial officer (CFO)',
      'health services institution executive director',
      'labour organization president',
      'membership organization general manager',
      'music guild president',
      'professional association executive director',
      'scientific association chairperson',
      'social services institution corporate controller',
      'voluntary organization executive director',
    ],
  },
  {
    code: '00014',
    title: 'Senior managers - trade, broadcasting and other services',
    examples: [
      'cleaning service general manager',
      'department store president',
      'engineering vice-president - television broadcasting services',
      'finance vice-president - food wholesaling',
      'hotel chain regional vice-president',
      'marketing vice-president - grocery store chain',
      'operations vice-president - radio broadcasting services',
      'professional sports club chief financial officer (CFO)',
      'restaurant chain corporate controller',
      'travel agency chief executive officer (CEO)',
    ],
  },
  {
    code: '00015',
    title: 'Senior managers - construction, transportation, production and utilities',
    examples: [
      'chief executive officer (CEO) - manufacturing company',
      'chief financial officer (CFO) - urban transit system',
      'executive vice-president - railway',
      'finance vice-president - mining company',
      'logging company corporate controller',
      'marketing vice-president - airline',
      'operations vice-president - electric power company',
      'petroleum production company regional vice-president',
      'publishing house general manager',
      'residential construction company president',
      'trucking company general manager',
    ],
  },
];
