import { Platform } from '@angular/cdk/platform';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TimeZoneString } from '@common/constants';
import * as dayjs from 'dayjs';
import * as tz from 'dayjs/plugin/timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppDateFormats } from './date-format';
import { DayJsDateAdapter } from './dayjs-date-adapter';

dayjs.extend(tz);

/**
 * Component for date/time entry using a calendar
 */
@Component({
  selector: 'common-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: CalendarComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: CalendarComponent,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: DayJsDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
    { provide: MAT_DATE_FORMATS, useValue: AppDateFormats },
  ],
})
export class CalendarComponent implements OnInit, OnDestroy, ControlValueAccessor {
  _destroy$ = new Subject<void>();
  @Input() dateFilterFn?: (date: Date | null) => boolean;
  /**
   * placeholder display text
   */
  @Input() placeholderText = 'MMM D YYYY';
  /**
   * Use the end of day
   */
  @Input() useEndOfDay = false;

  /**
   * forces the selected date to be in a particular timezone (e.g. 2000-01-01 00:00 +11:00)
   */
  @Input() forceTimezone: TimeZoneString = null;

  /**
   * Date formcontrol
   */
  _date: UntypedFormControl;

  /**
   * Is touched.  This is a bit of a hack, because CVA doesn't propogate markAllAsTouched
   */
  @Input() set isTouched(value) {
    if (this._date) {
      if (value) {
        this._date.markAllAsTouched();
      } else {
        this._date.markAsUntouched();
      }
    }
  }

  /**
   * The max allowed date
   */
  @Input() set maxDate(value) {
    if (value) {
      this._maxDateDayJs = dayjs(value);
    }
  }
  _maxDateDayJs = dayjs('9999-12-31T00:00:00Z');

  /**
   * The min allowed date
   */
  @Input() set minDate(value) {
    if (value) {
      this._minDateDayJs = dayjs(value);
    }
  }
  _minDateDayJs = dayjs('1800-01-01T00:00:00Z');

  /**
   * Text entry for date
   */
  @ViewChild('dateInput') _input;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this._date = this.formBuilder.control(null, { updateOn: 'blur' });
    this._date.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((value) => {
      if (!this.onChange) return;

      if (value === null) {
        this.onChange(null);
      } else {
        if (this.useEndOfDay) {
          this.onChange(value?.endOf('day').toDate());
        } else {
          this.onChange(
            this.forceTimezone
              ? value
                ? dayjs.tz(value.startOf('day').format('YYYY-MM-DD'), this.forceTimezone).toDate()
                : value
              : value?.startOf('day').toDate()
          );
        }
      }
      this.touched();
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  validate() {
    if (this._date?.invalid) {
      return this._date.errors;
    }
  }

  writeValue(obj): void {
    if (!obj) {
      this._date.setValue(obj, { emitEvent: false });
    } else {
      if (this.useEndOfDay) {
        this._date.setValue(dayjs(obj).endOf('day'), { emitEvent: false });
      } else {
        this._date.setValue(dayjs(obj).startOf('day'), { emitEvent: false });
      }
    }
  }
  onBlur() {
    this.touched();
  }
  onChange;
  registerOnChange(fn) {
    this.onChange = fn;
  }
  touched;
  registerOnTouched(fn) {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this._date.disable({ emitEvent: false });
    } else {
      this._date.enable({ emitEvent: false });
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this._date.setValue(dayjs(this._input.nativeElement.value));
    }
  }

  dateFilter = (date: Date | null): boolean => {
    if (this.dateFilterFn) {
      return this.dateFilterFn(date);
    }
    return true;
  };
}
