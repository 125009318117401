import { IncomeLookup } from './case-management/case-income-types';

export interface FbIncomeLookup extends IncomeLookup {
  requiresDescription?: boolean;
}

export const FbIncomeTypes: { [name: string]: FbIncomeLookup } = {
  employmentIncome: {
    order: 1,
    displayValue: 'Employment income',
    tips: 'Money received from: salary, wages, bonuses',
    documentTypes: [
      'Bank statement',
      'Pay stubs',
      'Written document from employer (must include name of employer, name of employee, pay period, date of pay, gross income/deductions)',
    ],
    helpText: 'Including self-employment income',
    needSupportDocument: false,
  },
  employmentInsurance: {
    order: 2,
    displayValue: 'Employment Insurance (EI)',
    tips: `Federal government benefits for:
    <ul>
      <li>loss of a job through no fault of their own – (for example, due to work shortage or seasonal work)</li>
      <li>people able and available to work, but can’t find a job</li>
      <li>parents on maternity or paternity leave</li>
    </ul>`,
    documentTypes: [],
    needSupportDocument: false,
  },
  // selfEmploymentIncome: {
  //   order: 3,
  //   displayValue: 'Self-employment',
  //   tips: `Earned from a business operated as a sole proprietorship or with someone else as a partner
  //   including earnings from:
  //   <ul>
  //     <li>a business</li>
  //     <li>sales commission</li>
  //     <li>farming</li>
  //     <li>fishing activities</li>
  //   </ul>`,
  //   documentTypes: [],
  //   needSupportDocument: false,
  // },
  workersCompensation: {
    order: 4,
    displayValue: `Worker's Compensation payment`,
    tips: `Payment for workplace injuries or illnesses`,
    documentTypes: [],
    needSupportDocument: false,
  },
  cpp: {
    order: 5,
    displayValue: `Canada Pension Plan (CPP)`,
    tips: `Federal government benefits including:
    <ul>
    <li>retirement pension (CPP)</li>
    <li>disability benefit (CPP-D)</li>
    <li>survivor’s pension</li>
  </ul>`,
    documentTypes: [],
    needSupportDocument: false,
    helpText: 'Includes both Retirement (CPP-R) and Disability (CPP-D)',
  },
  // oas: {
  //   order: 6,
  //   displayValue: `Old Age Security (OAS)`,
  //   tips: `Federal government benefits for seniors aged 65 and older and/or their spouses/partners, aged 60 to 64:
  //   <ul>
  //   <li>Old Age Security pension (OAS)</li>
  //   <li>allowance for the survivor</li>
  //   <li>Guaranteed Income Supplement (GIS)</li>
  //   <li>allowance for a GIS recipient's spouse/partner</li>
  // </ul>`,
  //   documentTypes: [],
  //   needSupportDocument: false,
  // },
  // gis: {
  //   order: 7,
  //   displayValue: `Guaranteed Income Supplement (GIS)`,
  //   documentTypes: [],
  //   needSupportDocument: false,
  // },
  asb: {
    order: 8,
    displayValue: `Seniors' benefits`,
    documentTypes: [],
    needSupportDocument: false,
    helpText: `Federal and provincial programs: Old Age Security (OAS), Guaranteed Income Supplement (GIS), Alberta Seniors Benefit (ASB)`,
  },
  otherPensionIncome: {
    order: 9,
    displayValue: `Other pension income`,
    tips: `Any other type of pension income, or funds received`,
    documentTypes: [],
    needSupportDocument: false,
    helpText: `E.g, Canadian Armed Forces pension, Police services pension, Firefighters’ pension`,
    requiresDescription: true,
  },
  bandPayments: {
    order: 10,
    displayValue: `First Nations Band payments`,
    documentTypes: [],
    needSupportDocument: false,
    helpText: 'Including per capita payments',
  },
  adultSupport: {
    order: 11,
    displayValue: `Child support/adult support`,
    tips: `Money that one spouse/partner may have to pay the other spouse/partner due to a separation or divorce`,
    documentTypes: [],
    needSupportDocument: false,
  },
  trainingStudent: {
    order: 12,
    displayValue: `Training allowance/student funding`,
    tips: `Training allowance or student funding can include provincial or federal student loans, funding for training from a Tribal Band, or any other type of loan/funding for educational purposes`,
    documentTypes: [],
    needSupportDocument: false,
  },
  rentalIncome: {
    order: 13,
    displayValue: `Rental income`,
    tips: `Income from a property or room being rented to someone else`,
    documentTypes: [],
    needSupportDocument: false,
    helpText: 'Including all income from boarders/renters',
  },
  // boardersRenters: {
  //   order: 14,
  //   displayValue: `Income from boarders/renters`,
  //   documentTypes: [],
  //   needSupportDocument: false,
  // },
  // otherGov: {
  //   order: 15,
  //   displayValue: `Other government funds`,
  //   documentTypes: [],
  //   needSupportDocument: false,
  // },
  // tipsCommissions: {
  //   order: 16,
  //   displayValue: `Honorarium, tips, commissions`,
  //   tips: `An honorarium or tip is a voluntary payment that is given to a person for services for which fees are not legally or traditionally required. Commissions are amounts of money paid to an employee based on their performance.`,
  //   documentTypes: [],
  //   needSupportDocument: false,
  // },
  otherIncome: {
    order: 17,
    displayValue: `Other sources income`,
    tips: `Any other type of income, or funds received`,
    documentTypes: [],
    needSupportDocument: false,
    helpText: 'Including other government funds, tips/commissions/honorarium, stipends, passive income, etc.',
    requiresDescription: true,
  },
};
