import * as Quill from 'quill';
const QuillType: any = Quill;
import 'quill-paste-smart';
import * as Clipboard from 'quill/modules/clipboard';
import { MSWordWebHeaderMatcher } from './matcher/msword-web-header.matcher';
import { MSWordWebListMatcher } from './matcher/msword-web-list.matcher';
import { ClipboardTransformerBase } from './transformer/clipboard-transformer.base';
import { MSWordAppTransformer } from './transformer/ms-word-app.transformer';
import { MSWordWebTransformer } from './transformer/ms-word-web.transformer';
const Clipboard = QuillType.import('modules/clipboard');

const DefaultTransformers: typeof ClipboardTransformerBase[] = [MSWordAppTransformer, MSWordWebTransformer];

export class QuillMSOClipboard extends Clipboard {
  transformers: ClipboardTransformerBase[];

  constructor(quill: Quill.Quill, config: any) {
    super(quill, config);

    this.addMatcher(Node.ELEMENT_NODE, MSWordWebHeaderMatcher);
    this.addMatcher('li', MSWordWebListMatcher);

    if (config.msoClipboardTransformers) {
      this.transformers = config.msoClipboardTransformers;
    } else {
      this.transformers = [...DefaultTransformers].map((t) => new (t as { new () })());
    }
  }

  onPaste(e: ClipboardEvent) {
    const document = this.parseHtmlFromClipboard(e);
    if (!document) {
      return super.onPaste(e);
    }

    e.preventDefault();

    // Creating a clone of the incoming event since there's mixed support of the ability to set the cliboard data.
    // https://github.com/marmelab/react-admin/issues/2091
    const clonedEvent = {
      preventDefault: () => e.preventDefault(),
      clipboardData: {
        getData: (type: string): string => {
          switch (type) {
            case 'text/html':
              return document.body.innerHTML;
            default:
              return e.clipboardData.getData(type);
          }
        },
      },
    };

    for (const t of this.transformers) {
      t.transform(document);
    }

    return super.onPaste(clonedEvent);
  }

  private parseHtmlFromClipboard(e: ClipboardEvent): Document | null {
    const rawHtml = e.clipboardData.getData('text/html');
    if (!rawHtml) {
      return null;
    }
    const parser = new DOMParser();

    return parser.parseFromString(rawHtml, 'text/html');
  }
}
