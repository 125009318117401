import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'apostrapheName',
})
export class ApostrapheNamePipe implements PipeTransform {
  transform(value: string): string {
    const trimmedValue = value?.trim();
    if (!trimmedValue) {
      return;
    }

    return trimmedValue.endsWith('s') ? `${trimmedValue}'` : `${trimmedValue}'s`;
  }
}
