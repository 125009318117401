<mat-select
  [panelClass]="panelClass"
  [formControl]="searchCtrl"
  [placeholder]="placeholderLabel"
  (selectionChange)="onSelectionChange(matSelect.value)"
  [multiple]="multiple"
  #matSelect
>
  <mat-option>
    <ngx-mat-select-search
      [formControl]="filterCtrl"
      [placeholderLabel]="placeholderLabel"
      [noEntriesFoundLabel]="noEntriesFoundLabel"
      [showToggleAllCheckbox]="multiple"
      [toggleAllCheckboxIndeterminate]="isIndeterminate"
      [toggleAllCheckboxChecked]="isChecked"
      (toggleAll)="toggleSelectAll($event)"
    ></ngx-mat-select-search>
  </mat-option>
  <mat-option *ngIf="allowClearing" class="select-none" [value]="null">None</mat-option>
  <mat-option *ngFor="let item of filteredData$ | async" [value]="returnIdField ? item[dataIdFieldName] : item">
    <span
      [innerHTML]="highlightMatches ? (getDisplayText(item) | highlightSearch: searchText) : getDisplayText(item)"
    ></span>
  </mat-option>
</mat-select>
