import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Municipalities } from '@common/constants';

export const INVALID_MUNICIPALITY_MESSAGE = 'Selected municipality is invalid.';

export function ValidateABMuni(municipalities?: { _id?: string; name: string }[]): ValidatorFn {
  return (control: AbstractControl) => {
    if (control.value && !(municipalities ?? Municipalities).some((m) => m.name === control.value)) {
      return { customMessage: INVALID_MUNICIPALITY_MESSAGE };
    }

    return null;
  };
}
