import { registerDecorator, ValidationArguments } from 'class-validator';
import { Document } from '../entities/document.entity';

export function RequiredDocumentIn(property: string) {
  return function (object, propertyName: string) {
    registerDecorator({
      name: `RequiredDocument - ${property}`,
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      validator: {
        validate(document, args: ValidationArguments) {
          const [requiredDocCategory] = args.constraints;
          const doc = document as Document;
          if (doc?.documentCategory === requiredDocCategory) {
            return true;
          }

          return false;
        },
        defaultMessage(): string {
          return `Required document ${property} missing`;
        },
      },
    });
  };
}
