import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from '@common/utils';

@Pipe({
  name: 'highlightSearch',
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: string, search: string): string {
    let valueStr = value + ''; // Ensure numeric values are converted to strings

    //highlight all the search terms that match
    const searchFields = search?.trim()?.split(' ');
    if (search && search.length > 0) {
      searchFields
        .map((s) => s.trim())
        .filter((s) => !!s)
        .forEach((search) => {
          const escapedSearch = Utilities.regExpEscape(search);
          const regex = new RegExp(`(?![^&;]+;)(?!<[^<>]*)(${escapedSearch})(?![^<>]*>)(?![^&;]+;)`, 'gi');
          valueStr = valueStr.replace(regex, '<strong>$1</strong>');
        });
    }
    return valueStr;
  }
}
