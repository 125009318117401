import { Component, Input } from '@angular/core';

@Component({
  selector: 'fi-information-box',
  templateUrl: './information-box.component.html',
  styleUrls: ['./information-box.component.scss'],
})
export class InformationBoxComponent {
  @Input() icon = '';
  @Input() title = '';
  @Input() description = '';
  @Input() description1 = '';
  @Input() description2 = '';
  @Input() html1 = '';
  @Input() html2 = '';
  @Input() html3 = '';
}
