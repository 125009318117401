<div>
  <div class="dash-header" data-cy="dash-header">
    <h1 *ngIf="profileFirstName">Welcome to your homepage, {{ profileFirstName }}</h1>
    <div class="button">
      <goa-button
        (_click)="onAddNewApplication()"
        class="start-application-button"
        data-cy="start-application-button"
        [type]="empty ? 'primary' : 'secondary'"
      >
        <span>+ Start a new application</span></goa-button
      >

      <!-- Mobile Button -->
      <goa-button
        (_click)="onAddNewApplication()"
        class="start-application-button-mobile"
        data-cy="start-application-button-mobile"
        [type]="isAccordionExpanded ? 'secondary' : 'primary'"
        ><span>+ Start a new application</span></goa-button
      >
    </div>
  </div>

  <div *ngIf="empty" class="dash-header-text">
    <p>
      To apply for FSCD, simply click on the "Start a new application" button. Once you have completed the application,
      the homepage will show your submitted, draft, and recently removed FSCD applications.
    </p>
    <p>
      For easy access to your homepage, change your password, or signing out, just click on your username located at the
      top right-hand corner of the page.
    </p>
  </div>

  <div *ngIf="loading" class="loading" data-cy="loading-bar">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div *ngIf="!loading && !empty" class="dash-body" data-cy="dash-body">
    <mat-tab-group mat-stretch-tabs="false" animationDuration="0" [(selectedIndex)]="tabIndex">
      <mat-tab label="Submitted application(s)">
        <div class="mt-24 info-box" *ngIf="applicationsSubmitted.length > 0">
          <goa-callout type="important"> {{ submittedAppsCalloutText }} </goa-callout>
        </div>
        <fi-application-list
          [listType]="getListTypeSubmitted()"
          [dataSource]="applicationsSubmitted"
          actionButtonLeftText="View"
          [actionButtonLeftStylePrimary]="true"
          [displayedColumns]="displayedColumnsSubmitted"
          (viewApplication)="onViewApplication($event)"
        ></fi-application-list>
      </mat-tab>
      <mat-tab label="Draft application(s)">
        <div class="mt-24 info-box" *ngIf="applicationsDraft.length > 0">
          <goa-callout type="important"> {{ draftAppsCalloutText }} </goa-callout>
        </div>
        <fi-application-list
          [listType]="getListTypeDraft()"
          [dataSource]="applicationsDraft"
          actionButtonLeftText="Continue"
          actionButtonRightText="Remove"
          [actionButtonLeftStylePrimary]="true"
          [actionButtonRightStylePrimary]="false"
          [displayedColumns]="displayColumnsDraft"
          (continueApplication)="onContinueApplication($event)"
          (removeApplication)="onRemoveApplication($event)"
        ></fi-application-list>
      </mat-tab>
      <mat-tab label="Recently removed application(s)">
        <div class="mt-24 info-box" *ngIf="applicationsRemoved.length > 0">
          <goa-callout type="important"> {{ removedAppsCalloutText }} </goa-callout>
        </div>
        <fi-application-list
          [listType]="getListTypeRemoved()"
          [dataSource]="applicationsRemoved"
          actionButtonLeftText="Restore"
          actionButtonRightText="Permanently delete"
          [actionButtonLeftStylePrimary]="true"
          [actionButtonRightStylePrimary]="false"
          [displayedColumns]="displayedColumnsRemoved"
          (recoverApplication)="onRecoverApplication($event)"
          (deleteApplication)="onDeleteApplication($event)"
        ></fi-application-list>
      </mat-tab>
    </mat-tab-group>

    <!-- Mobile View -->
    <fi-application-list-mobile
      [tabIndex]="tabIndexMobile"
      [submittedList]="applicationsSubmitted"
      [draftList]="applicationsDraft"
      [removedList]="applicationsRemoved"
      (continueApplication)="onContinueApplication($event)"
      (viewApplication)="onViewApplication($event)"
      (removeApplication)="onRemoveApplication($event)"
      (recoverApplication)="onRecoverApplication($event)"
      (deleteApplication)="onDeleteApplication($event)"
      (isExpanded)="onExpansion($event)"
    ></fi-application-list-mobile>

    <!-- New application modal -->
    <fi-start-app-modal
      [showModal]="showStartAppModal"
      (startNewApp)="onStartAppModalContinue($event)"
    ></fi-start-app-modal>
  </div>
</div>
