import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FiLegalStatus } from '@common/constants';
import { FileService } from '@common/ui/shared-components';
import { ValidationUtils } from '@common/utils';
import {
  FiChild,
  FscdIntakeApplication,
  FscdIntakeApplicationErrors,
  ServiceDurationOptions,
} from '@fscd-intake/entities';
import { Apollo } from 'apollo-angular';
import { Observable, Subject, lastValueFrom, map, takeUntil } from 'rxjs';
import { FiGraphqlService } from '../../services/fi-graphql.service';
import { BaseSaveComponent } from '../base-save/base-save.component';
import { PageNavigation } from '../page-navigation';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';

@Component({
  selector: 'fi-child-info-page',
  templateUrl: './child-info-page.component.html',
  styleUrls: ['./child-info-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildInfoPageComponent extends BaseSaveComponent implements OnInit, OnDestroy, PageNavigation {
  private destroy$ = new Subject<void>();
  application: FscdIntakeApplication;
  isSubmitted: boolean;
  loading = true;
  childInfo$: Observable<FiChild>;
  hasErrors$: Observable<boolean>;
  childFormValue;

  constructor(
    private graphqlService: FiGraphqlService,
    apollo: Apollo,
    fileService: FileService,
    authenticationService: AuthenticationService
  ) {
    super(apollo, fileService, authenticationService, null, graphqlService);
  }
  next(): boolean {
    return true;
  }

  async ngOnInit() {
    await super.ngOnInit();
    await this.loadChildInfo();
  }

  async loadChildInfo() {
    const { selectedApplicationId, isSubmitted } = await this.graphqlService.getSelectedApplication();
    this.isSubmitted = isSubmitted;
    this.applicationId = selectedApplicationId;

    if (this.applicationId) {
      const results$ = this.graphqlService.getFullApplication(this.applicationId);
      this.childInfo$ = results$.pipe(takeUntil(this.destroy$)).pipe(
        map((application) => {
          this.application = application;
          return application.child;
        })
      );

      this.hasErrors$ = results$.pipe(
        map((application) => {
          return application?.applicationErrors?.childErrors?.length > 0 || false;
        })
      );
    }
  }

  async onFormError() {
    this.displayStaticToastError('One or more fields are not filled in correctly.');
  }

  async onFormUpdated(formValue) {
    this.childFormValue = formValue;
  }

  deleteIfNotEmpty(documents) {
    if (documents?.length > 0) {
      this.deleteDocuments(documents);
    }
  }

  save() {
    super.save();
    const updatedFormValue = this.childFormValue;
    this.deleteIfNotEmpty(updatedFormValue?.identityDocuments);
    this.deleteIfNotEmpty(updatedFormValue?.medicalDocuments);
    this.deleteIfNotEmpty(updatedFormValue?.additionalDocuments);

    if (updatedFormValue) {
      delete updatedFormValue['identityDocsUploadCntl'];
      delete updatedFormValue['medicalDocsUploadCntl'];
      delete updatedFormValue['additionalDocsUploadCntl'];
    }

    const childStatus = updatedFormValue?.citizenImmigrationStatus;
    if (childStatus === FiLegalStatus.citizen?.code) {
      this.deleteDocuments(updatedFormValue?.identityDocuments, false, 'permanentResident');
    } else if (childStatus === FiLegalStatus.permanentResident?.code) {
      this.deleteDocuments(updatedFormValue?.identityDocuments, false, 'citizen');
    }

    const child = {
      ...updatedFormValue,
    } as FiChild;

    /* child with NO confirmed diagnosis can only avail services upto 2 years */
    if (
      !child.hasConfirmedDiagnosis &&
      this.application.services &&
      ServiceDurationOptions[this.application.services.agreementDuration]?.requiredForConfirmedDiagnosis
    ) {
      this.application.services.agreementDuration = undefined;
    }

    const applicationErrors = this.application?.applicationErrors
      ? { ...this.application.applicationErrors }
      : new FscdIntakeApplicationErrors();
    applicationErrors.childErrors = ValidationUtils.validateEntity(FiChild, child);

    const applicationToSave = {
      id: this.applicationId,
      child: child,
      applicationErrors: applicationErrors,
      services: this.application?.services,
    } as FscdIntakeApplication;

    return lastValueFrom(this.graphqlService.updateApplication(applicationToSave));
  }

  async ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
