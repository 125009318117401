import { Directive, HostBinding, HostListener, Input } from '@angular/core';

/**
 * Directive which adds button role to a clickable span/div.
 * Note that this should be a last resort.  Where possible use button and change the styling (remove borders, backgrounds etc.)
 */
@Directive({
  selector: '[iesIconButton]',
})
export class IconButton {
  @Input() disabled: boolean;

  @HostBinding('attr.role') role = 'button';

  @HostBinding('attr.aria-disabled') get ariaDisabled() {
    if (this.disabled) {
      return 'true';
    } else {
      return 'false';
    }
  }

  @HostBinding('attr.disabled') get disabledBinding() {
    if (this.disabled) {
      return 'true';
    } else {
      return null;
    }
  }

  @HostBinding('attr.tabindex') get tabindex() {
    if (this.disabled) {
      return null;
    } else {
      return 0;
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === ' ' || event.key === 'Enter' || event.key === 'Spacebar') {
      const clickEvent = document.createEvent('MouseEvents');
      // hopefully we don't care about the position args... if we do we may need an alternative solution like an output event
      clickEvent.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
      event.target.dispatchEvent(clickEvent);
    }
  }
}
