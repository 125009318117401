import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from '../../../popups/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponentCanDeactivateDirective } from '../../../ui-shared-components.directives';

@Injectable()
export class PendingChangesGuard {
  constructor(private _dialog: MatDialog) {}

  canDeactivate(component: AbstractComponentCanDeactivateDirective): boolean | Observable<boolean> {
    const { canDeactivate, title, message, noLabel, yesLabel } = component.canDeactivate();

    if (canDeactivate) return true;
    if (this._dialog.openDialogs.length > 0) return this._dialog.openDialogs[0].afterClosed();

    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        title: title || 'Leave without saving',
        message: message || 'Are you sure you want to leave this page with unsaved changes?',
        cancelLabel: noLabel || 'Cancel',
        confirmLabel: yesLabel || 'Confirm',
      },
    });
    return dialogRef.afterClosed();
  }
}
