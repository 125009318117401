import { Expose } from 'class-transformer';
import { IsNotEmpty, ValidateIf } from 'class-validator';
import { LivingSituations } from '../constants/living-situations';
import { BaseLivingSituation } from './base-living-situation';

/**
 * Information about a living situation
 */
export class LivingSituation extends BaseLivingSituation {
  @Expose()
  @ValidateIf((o) => o.type === LivingSituations.institution.code)
  @IsNotEmpty()
  institutionName?: string;

  @Expose()
  @ValidateIf((o) => o.type === LivingSituations.longTermCare.code)
  @IsNotEmpty()
  longTermCareFacilityName?: string;
}
