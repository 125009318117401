<div>
  <common-fieldset-control [label]="label" [showLabel]="showLabel">
    <div class="phone-number-container" *ngFor="let phoneNumberGroup of formArray.controls; let $index = index">
      <span class="phone-number-input">
        <common-phone-number
          label="Phone number"
          [dataCy]="'phoneNumber' + $index.toString()"
          [formControl]="phoneNumberGroup"
          [isTouched]="phoneNumberGroup.touched"
          [isRequired]="isRequired"
        ></common-phone-number>
      </span>
      <span
        class="phone-number-delete"
        [class]="componentDisabled ? 'disabled' : 'enabled'"
        *ngIf="formArray.length > 1 || allowNoNumbers"
        iesIconButton
        aria-label="Remove phone number"
        (click)="deleteNumber($index)"
      >
        <span class="material-icons" [attr.data-cy]="'phoneNumber' + $index.toString() + '-removeButton'">
          highlight_off
        </span>
      </span>
    </div>
    <button
      goa-button
      buttonType="tertiary"
      class="phone-number-add"
      [disabled]="hasInvalidOrEmptyPhoneNumber() || componentDisabled"
      (click)="addNumber()"
      data-cy="phoneNumber-addButton"
    >
      + Add another number
    </button>
  </common-fieldset-control>
</div>
