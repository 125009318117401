import { Lookup } from './lookup.interface';

export const ContractRegions: { [name: string]: Lookup } = {
  calgary: { displayValue: 'Calgary', code: 'calgary' },
  central: { displayValue: 'Central', code: 'central' },
  edmonton: { displayValue: 'Edmonton', code: 'edmonton' },
  northeast: { displayValue: 'Northeast', code: 'northeast' },
  northwest: { displayValue: 'Northwest', code: 'northwest' },
  south: { displayValue: 'South', code: 'south' },
  province_wide: { displayValue: 'Province-wide', code: 'province_wide' },
};
