export const IesDimgRegion = [
  {
    iesRegion: 'Northcentral',
    dimgRegion: 'north-central',
  },
  {
    iesRegion: 'Northeast',
    dimgRegion: 'north-east',
  },
  {
    iesRegion: 'Northwest',
    dimgRegion: 'north-west',
  },
  {
    iesRegion: 'Edmonton',
    dimgRegion: 'edmonton',
  },
  {
    iesRegion: 'Central',
    dimgRegion: 'central',
  },
  {
    iesRegion: 'Calgary',
    dimgRegion: 'calgary',
  },
  {
    iesRegion: 'South',
    dimgRegion: 'south',
  },
];
