import { IntakeLabelCollection } from '../../entities/intake-label.interface';
export const AishMySituationLabels: IntakeLabelCollection = {
  hasTrustee: {
    label: 'Do you have a Trustee or someone currently acting under a Power of Attorney?',
  },
  isCompletedOnBehalf: {
    label: 'Is someone helping you complete the application?',
  },
  onBehalfName: {
    label: 'Name/organization',
  },
  onBehalfPhone: {
    label: 'Phone number',
  },
  onBehalfEmail: {
    label: 'Email address',
  },
  isInPalliativeCare: {
    label: 'Are you receiving end-of-life (palliative) care or have you been diagnosed with a terminal illness?',
  },
  isInLongTermSupportiveLiving: {
    label: 'Are you living in, or waiting to move into, a long-term care or nursing home?',
  },
  hasIncomeSupportBenefits: {
    label: 'Are you receiving Income Support benefits from the Government of Alberta?',
  },
  isEmployed: {
    label: 'Have you ever been employed?',
  },
  isInPddProgram: {
    label: 'Have you applied or plan to apply for the Persons with Development Disabilities (PDD) program?',
  },
  maritalStatus: {
    label: 'What is your marital status?',
  },
  hasDependentChild: {
    label: 'Do you have dependent children?',
  },
};
