import { registerDecorator } from 'class-validator';

export function validateLegalName(legalName: string) {
  //Note closely the rules:
  // chars cannot be D, F, I, O, Q, U
  // additionally, 1st char cannot be W, Z
  //   const legalNameRegExp = /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][\s-]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
  // const validationReturn = Validators.pattern("^[ ]*[a-zA-Z]+[a-zA-Z|.|'|\\-| ]*$")(control);
  const legalNameRegExp = "^[ ]*[a-zA-Z]+[a-zA-Z|.|'|\\-| ]*$";
  const re = new RegExp(legalNameRegExp, 'g');
  return re.test(legalName);
  //return legalNameRegExp.test(legalName);
}

export function LegalNameFormat() {
  return function (object, propertyName: string) {
    registerDecorator({
      name: 'LegalNameFormat',
      target: object.constructor,
      propertyName: propertyName,
      validator: {
        validate(name: string) {
          if (name) {
            return validateLegalName(name);
          }
          return true;
        },
        defaultMessage(): string {
          return 'Name must be a valid.';
        },
      },
    });
  };
}
