<div [formGroup]="formGroup" class="client-intake-living-situation">
  <common-form-control [control]="formGroup.controls.type" [label]="label">
    <mat-select
      (selectionChange)="onLivingSituationSelect()"
      formControlName="type"
      data-cy="livingSituation-dropdown"
      aria-label="livingSituation"
      role="combobox"
      [class.type-dropdown]="
        formGroup.controls.other || formGroup.controls.institutionName || formGroup.controls.longTermCareFacilityName
      "
      placeholder="{{ selectOneText }}"
    >
      <mat-option disabled value="">{{ selectOneText }}</mat-option>
      <mat-option *ngFor="let option of livingSituations | keyvalue: unsort" [value]="option.key">
        {{ option.value.displayValue }}
      </mat-option>
    </mat-select>
  </common-form-control>
  <!--  
  <common-form-control [control]="formGroup.controls.other" label="Other" *ngIf="formGroup.controls.other">
    <input type="text" formControlName="other" data-cy="otherText" />
  </common-form-control>
-->
  <common-form-control
    [control]="formGroup.controls.institutionName"
    label="Institution name"
    *ngIf="formGroup.controls.institutionName"
  >
    <input type="text" formControlName="institutionName" data-cy="institutionName" />
  </common-form-control>
  <common-form-control
    [control]="formGroup.controls.longTermCareFacilityName"
    label="Long-term care facility name"
    *ngIf="formGroup.controls.longTermCareFacilityName"
  >
    <input type="text" formControlName="longTermCareFacilityName" data-cy="FacilityName" />
  </common-form-control>
</div>
