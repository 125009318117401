import { Expose } from 'class-transformer';

export class ProducerResponse {
  @Expose()
  jobId?: number;

  @Expose()
  jobSubmittedOn?: Date;

  @Expose()
  applicationId?: string;

  @Expose()
  queueName?: string;

  @Expose()
  source?: string;
}
