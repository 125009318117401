import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Validators, UntypedFormBuilder, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SELECT_ONE_TEXT } from '@common/constants';
import * as dayjs from 'dayjs';
import { conditionalValidator } from '../ui-shared-components.validators';

@Component({
  selector: 'common-calendar-range-select',
  templateUrl: './calendar-range-select.component.html',
  styleUrls: ['./calendar-range-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: CalendarRangeSelectComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: CalendarRangeSelectComponent,
      multi: true,
    },
  ],
})
export class CalendarRangeSelectComponent implements ControlValueAccessor, OnDestroy {
  _destroy$ = new Subject<void>();
  selectOneText = SELECT_ONE_TEXT;

  @Input() label: string;

  /**
   * Is touched.  This is a bit of a hack, because CVA doesn't propogate markAllAsTouched
   */
  @Input() set isTouched(value) {
    if (this.formGroup) {
      if (value) {
        this.formGroup.markAllAsTouched();
      } else {
        this.formGroup.markAsUntouched();
      }
    }
  }

  @Output() selectionValueChange = new EventEmitter<string>();

  calendarRangeTypes = [
    { key: { fromDate: dayjs().startOf('day'), toDate: dayjs().endOf('day') }, code: 'today', displayValue: 'Today' },
    {
      //starting from this Monday
      key: { fromDate: dayjs().subtract(1, 'day').startOf('week').add(1, 'day'), toDate: dayjs().endOf('day') },
      code: 'week',
      displayValue: 'Week',
    },
    {
      key: { fromDate: dayjs().startOf('month'), toDate: dayjs().endOf('day') },
      code: 'month',
      displayValue: 'Month',
    },
  ];

  formGroup = this.formBuilder.group({
    type: this.formBuilder.control('', Validators.required),
    date: this.formBuilder.control(
      '',
      conditionalValidator(() => this.showDate, Validators.required)
    ),
    dateRange: this.formBuilder.control(
      '',
      conditionalValidator(() => this.showDateRange, Validators.required)
    ),
    /*
    date: this.formBuilder.control(dayjs().startOf('day')),
    dateRange: this.formBuilder.control({
      fromDate: dayjs().subtract(1, 'week').startOf('day'),
      toDate: dayjs().endOf('day'),
    }),
    */
  });

  showDate() {
    return this.formGroup.controls.type.value == 'customDate';
  }
  showDateRange() {
    return this.formGroup.controls.type.value == 'customRange';
  }

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  validate() {
    return this.formGroup.valid ? null : { invalid: true };
  }

  writeValue(value): void {
    if (value && value.fromDate && value.toDate) {
      const groupValue = {
        type: '',
        date: null,
        dateRange: null,
      };
      for (const type of this.calendarRangeTypes) {
        if (type.key.fromDate.isSame(dayjs(value.fromDate)) && type.key.toDate.isSame(dayjs(value.toDate)))
          groupValue.type = type.code;
      }
      if (!groupValue.type) {
        if (dayjs(value.fromDate).startOf('day').isSame(dayjs(value.toDate).startOf('day'))) {
          groupValue.type = 'customDate';
          groupValue.date = dayjs(value.fromDate).startOf('day');
        } else {
          groupValue.type = 'customRange';
          groupValue.dateRange = value;
        }
      }

      this.formGroup.patchValue(groupValue, { emitEvent: false });
    }
  }

  registerOnChange(fn) {
    this._destroy$.next();
    this.formGroup.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      const formValue = this.formGroup.getRawValue();
      let dateRange;
      for (const type of this.calendarRangeTypes) {
        if (type.code == formValue.type)
          dateRange = { fromDate: type.key.fromDate.toDate(), toDate: type.key.toDate.toDate() };
      }
      if (!dateRange) {
        if (formValue.type == 'customDate' && formValue.date) {
          dateRange = {
            fromDate: dayjs(formValue.date).startOf('day').toDate(),
            toDate: dayjs(formValue.date).endOf('day').toDate(),
          };
        } else if (
          formValue.type == 'customRange' &&
          formValue.dateRange &&
          formValue.dateRange.fromDate &&
          formValue.dateRange.toDate
        ) {
          dateRange = formValue.dateRange;
        }
      }
      //console.log(dateRange);
      fn(dateRange);
    });
  }

  touched;
  registerOnTouched(fn) {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) this.formGroup.disable({ emitEvent: false });
    else this.formGroup.enable({ emitEvent: false });
  }

  unsort() {
    return 0;
  }
}
