import { IsNotEmpty } from 'class-validator';
import { Expose } from 'class-transformer';
import { User } from './user.entity';

export class Municipality {
  @Expose()
  _id?: string;

  @Expose()
  @IsNotEmpty()
  name: string;

  @Expose()
  region: string;

  @Expose()
  createdBy?: User;

  @Expose()
  createdAt?: Date;

  @Expose()
  updatedBy?: User;

  @Expose()
  updatedAt?: Date;
}
