<div [formGroup]="formGroup">
  <section aria-labelledby="parent-info-section-label" class="sub-form">
    <h2 id="parent-info-section-label" data-cy="page-title">{{ fiParentInfoLabels.header | labelText }}</h2>
    <div>
      <goa-callout type="information">
        {{ fiParentInfoLabels.primaryContactInfoText.label }} <br /><br />
        {{ fiParentInfoLabels.autoFillingInfoText.label }}
      </goa-callout>
      <div class="form-control first-field">
        <common-form-control
          [label]="fiParentInfoLabels.firstName | labelText"
          [control]="formGroup.controls.firstName"
        >
          <input type="text" data-cy="firstName" formControlName="firstName" />
        </common-form-control>
      </div>
      <div class="form-control">
        <common-form-control
          [label]="fiParentInfoLabels.middleName | labelText"
          [isOptional]="true"
          [control]="formGroup.controls.middleName"
        >
          <input type="text" formControlName="middleName" data-cy="middleName" />
        </common-form-control>
      </div>
      <div class="form-control">
        <common-form-control [label]="fiParentInfoLabels.lastName | labelText" [control]="formGroup.controls.lastName">
          <input type="text" formControlName="lastName" data-cy="lastName" />
        </common-form-control>
      </div>

      <div class="form-control">
        <common-form-control
          [label]="fiParentInfoLabels.preferredName | labelText"
          [isOptional]="true"
          [control]="formGroup.controls.preferredName"
        >
          <input type="text" formControlName="preferredName" data-cy="preferredName" />
        </common-form-control>
      </div>

      <div class="form-control" role="group" aria-label="Date of birth">
        <common-form-control
          [label]="fiParentInfoLabels.birthdate | labelText"
          [control]="formGroup.controls.birthdate"
        >
          <common-date-picker
            formControlName="birthdate"
            data-cy="birthdate"
            [iesMaxDate]="_dateMax"
            [iesMinDate]="_dateMin"
            required
          ></common-date-picker>
        </common-form-control>
      </div>

      <div class="form-control gender">
        <common-form-control [label]="fiParentInfoLabels.gender | labelText" [control]="formGroup.controls.gender">
          <mat-select
            id="gender"
            color="primary"
            [formControl]="formGroup.controls.gender"
            data-cy="gender"
            aria-label="gender"
            disableOptionCentering="true"
            placeholder="{{ selectOneText }}"
          >
            <mat-option disabled value="">{{ selectOneText }}</mat-option>
            <mat-option *ngFor="let item of genderList | keyvalue: unsort" [value]="item.key">
              {{ item.value['displayValue'] }}
            </mat-option>
          </mat-select>
        </common-form-control>
      </div>

      <div class="form-control-radio-button">
        <common-fieldset-control [label]="fiParentInfoLabels.isEnglishFirstLanguage | labelText">
          <mat-radio-group
            [formControl]="formGroup.controls.isEnglishFirstLanguage"
            (change)="onIsEnglishFirstLanguageChange($event.value)"
            color="primary"
          >
            <mat-radio-button data-cy="isEnglishFirstLanguage-yes" [value]="true">Yes</mat-radio-button>
            <mat-radio-button data-cy="isEnglishFirstLanguage-no" [value]="false">No</mat-radio-button>
          </mat-radio-group>
          <div
            class="error-text"
            *ngIf="
              formGroup?.controls?.isEnglishFirstLanguage?.errors &&
              formGroup?.controls?.isEnglishFirstLanguage?.touched
            "
          >
            This field is required.
          </div>
        </common-fieldset-control>
      </div>
      <ng-container *ngIf="formGroup.controls.isEnglishFirstLanguage.value === false">
        <div class="form-control sub-question">
          <common-form-control
            [label]="fiParentInfoLabels.primaryLanguage | labelText"
            [control]="formGroup.controls.primaryLanguage"
            [abbreviatedLabel]="'This field'"
          >
            <input type="text" formControlName="primaryLanguage" data-cy="primaryLanguage" maxlength="50" />
          </common-form-control>
        </div>
        <div class="form-control-radio-button sub-question">
          <common-fieldset-control [label]="fiParentInfoLabels.requiresInterpreter | labelText">
            <mat-radio-group [formControl]="formGroup.controls.requiresInterpreter" color="primary">
              <mat-radio-button data-cy="requiresInterpreter" [value]="true">Yes</mat-radio-button>
              <mat-radio-button data-cy="requiresInterpreter" [value]="false">No</mat-radio-button>
            </mat-radio-group>
            <div
              class="error-text"
              *ngIf="
                formGroup?.controls?.requiresInterpreter?.errors && formGroup?.controls?.requiresInterpreter?.touched
              "
            >
              This field is required.
            </div>
          </common-fieldset-control>
        </div>
      </ng-container>

      <div class="form-control-radio-button">
        <common-fieldset-control [label]="fiParentInfoLabels.haveOtherCommunicationNeed | labelText">
          <mat-radio-group
            [formControl]="formGroup.controls.haveOtherCommunicationNeed"
            (change)="onHaveOtherCommunicationNeedChange($event.value)"
            color="primary"
          >
            <mat-radio-button data-cy="haveOtherCommunicationNeed-yes" [value]="true">Yes</mat-radio-button>
            <mat-radio-button data-cy="haveOtherCommunicationNeed-no" [value]="false">No</mat-radio-button>
          </mat-radio-group>
          <div
            class="error-text"
            *ngIf="
              formGroup?.controls?.haveOtherCommunicationNeed?.errors &&
              formGroup?.controls?.haveOtherCommunicationNeed?.touched
            "
          >
            This field is required.
          </div>
          <goa-details
            [heading]="
              isScreenSmall()
                ? 'Click here for more info'
                : 'Click here to check what other communication needs could be'
            "
            ><p>{{ fiParentInfoLabels.haveOtherCommunicationNeed.helperText }}</p></goa-details
          >
        </common-fieldset-control>
      </div>

      <ng-container *ngIf="formGroup.controls.haveOtherCommunicationNeed.value">
        <div class="form-control sub-question">
          <common-form-control
            [label]="fiParentInfoLabels.otherCommunicationNeed | labelText"
            [control]="formGroup.controls.otherCommunicationNeed"
            [abbreviatedLabel]="'This field'"
          >
            <input
              type="text"
              formControlName="otherCommunicationNeed"
              data-cy="otherCommunicationNeed"
              maxlength="100"
            />
          </common-form-control>
        </div>
      </ng-container>

      <common-phone-number-multiple
        formControlName="phoneNumbers"
        [isTouched]="formGroup.get('phoneNumbers').touched"
        [isRequired]="true"
      ></common-phone-number-multiple>

      <div class="form-control">
        <common-email-address
          formControlName="email"
          [label]="'Email address'"
          [enableConfirm]="false"
          [isRequired]="true"
        >
        </common-email-address>
      </div>
      <div class="form-control-radio-button">
        <common-fieldset-control [label]="fiParentInfoLabels.hasSameAddressAsChild | labelText">
          <mat-radio-group
            [formControl]="formGroup.controls.hasSameAddressAsChild"
            (change)="onHasSameAddressAsChildChange($event.value)"
            color="primary"
          >
            <mat-radio-button data-cy="hasSameAddressAsChild-yes" [value]="true">Yes</mat-radio-button>
            <mat-radio-button data-cy="hasSameAddressAsChild-no" [value]="false">No</mat-radio-button>
          </mat-radio-group>
          <div
            class="error-text"
            *ngIf="
              formGroup?.controls?.hasSameAddressAsChild?.errors && formGroup?.controls?.hasSameAddressAsChild?.touched
            "
          >
            This field is required.
          </div>
        </common-fieldset-control>
      </div>
      <div
        class="form-control sub-question"
        *ngIf="formGroup.controls.hasSameAddressAsChild.value === false"
        data-cy="residentialAddress"
      >
        <common-address
          dataCyPrefix="home"
          formControlName="residentialAddress"
          [isTouched]="formGroup?.get('residentialAddress')?.touched"
          [isRequired]="true"
          [useAddressValidationService]="true"
          postalCodeFormat="AB"
        ></common-address>
      </div>
      <div class="form-control">
        <common-form-control
          [label]="fiParentInfoLabels.relationToChild | labelText"
          [control]="formGroup.controls.relationToChild"
        >
          <mat-select
            id="relationToChild"
            color="primary"
            data-cy="relationToChild"
            [formControl]="formGroup.controls.relationToChild"
            aria-label="relationToChild"
            placeholder="{{ selectOneText }}"
            (selectionChange)="onRelationChange($event.value)"
            disableOptionCentering="true"
            data-cy="relationToChild"
          >
            <mat-option disabled value="">{{ selectOneText }}</mat-option>
            <mat-option *ngFor="let item of relationList | keyvalue: unsort" [value]="item.value['code']">
              {{ item.value['displayValue'] }}
            </mat-option>
          </mat-select>
        </common-form-control>
      </div>
    </div>
  </section>

  <!-- Document Uploader -->
  <div
    class="width-80 form-control"
    *ngIf="formGroup.controls.relationToChild.value === 'other' || hasIdentityDocument"
  >
    <common-document-upload
      label="Upload supporting documents"
      [showUploadRemaining]="false"
      [parentId]="applicationId"
      [documentsUploaded]="documents"
      [documentCategories]="documentsNeeded"
      (fileUploaded)="onFileUploaded($event)"
      (removeFile)="onRemoveFile($event)"
      tooltipMessage="Display file in new window"
      tooltipPosition="right"
      ariaLabelForPreviewIcon="Display document"
      (uploadStarted)="uploadStarted()"
      (uploadCompleted)="uploadCompleted()"
    >
    </common-document-upload>
  </div>

  <!-- Learn about FSCD -->
  <div class="sub-form">
    <ng-container class="form-control">
      <label class="checkbox-label">
        {{ fiParentInfoLabels.learnAboutFscd | labelText }}
      </label>
      <div>
        <mat-checkbox
          aria-label="Physician"
          data-cy="learnAboutFscdPhysician"
          formControlName="learnAboutFscdPhysician"
          [disableRipple]="true"
        ></mat-checkbox>
        &nbsp;Physician
      </div>
      <div>
        <mat-checkbox
          aria-label="Hospital"
          data-cy="learnAboutFscdHospital"
          formControlName="learnAboutFscdHospital"
          [disableRipple]="true"
        ></mat-checkbox>
        &nbsp;Hospital
      </div>
      <div>
        <mat-checkbox
          aria-label="Agency"
          data-cy="learnAboutFscdAgency"
          formControlName="learnAboutFscdAgency"
          [disableRipple]="true"
        ></mat-checkbox>
        &nbsp;Agency
      </div>
      <div>
        <mat-checkbox
          aria-label="Early Intervention Program"
          data-cy="learnAboutFscdEarlyInterventionProgram"
          formControlName="learnAboutFscdEarlyInterventionProgram"
          [disableRipple]="true"
        ></mat-checkbox>
        &nbsp;Early Intervention Program
      </div>
      <div>
        <mat-checkbox
          aria-label="Children's Link"
          data-cy="learnAboutFscdChildrensLink"
          formControlName="learnAboutFscdChildrensLink"
          [disableRipple]="true"
        ></mat-checkbox>
        &nbsp;Children's Link
      </div>
      <div>
        <mat-checkbox
          aria-label="Health Unit"
          data-cy="learnAboutFscdHealthUnit"
          formControlName="learnAboutFscdHealthUnit"
          [disableRipple]="true"
        ></mat-checkbox>
        &nbsp;Health Unit
      </div>

      <div>
        <mat-checkbox
          aria-label="School"
          data-cy="learnAboutFscdSchool"
          formControlName="learnAboutFscdSchool"
          [disableRipple]="true"
        ></mat-checkbox>
        &nbsp;School
      </div>
      <div>
        <mat-checkbox
          aria-label="Children's Services"
          data-cy="learnAboutFscdChildrensServices"
          formControlName="learnAboutFscdChildrensServices"
          [disableRipple]="true"
        ></mat-checkbox>
        &nbsp;Children's Services
      </div>
      <div>
        <mat-checkbox
          aria-label="Other"
          data-cy="learnAboutFscdOther"
          formControlName="learnAboutFscdOther"
          (change)="onLearnAboutFscdOtherChange($event.checked)"
          [disableRipple]="true"
        ></mat-checkbox>
        &nbsp;Other
      </div>
      <ng-container *ngIf="formGroup.controls.learnAboutFscdOther.value">
        <div class="form-control sub-question">
          <common-form-control
            [shouldUseLabel]="false"
            [control]="formGroup.controls.learnAboutFscdOtherDetail"
            [abbreviatedLabel]="'This field'"
          >
            <input
              type="text"
              aria-label="Other source of information"
              data-cy="learnAboutFscdOtherDetail"
              formControlName="learnAboutFscdOtherDetail"
              maxlength="100"
            />
          </common-form-control>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="add-second-parent-button" *ngIf="!formGroup.controls.hasSecondParent.value">
    <goa-button
      type="tertiary"
      (_click)="toggleSecondParent(true)"
      data-cy="addSecondParentButton"
      aria-label="Add another Parent/Guardian"
    >
      + Add another Parent/Guardian</goa-button
    >
  </div>

  <!-- Second Parent -->
  <section
    aria-labelledby="second-parent-info-section-label"
    class="sub-form"
    *ngIf="formGroup.controls.hasSecondParent.value === true"
  >
    <h2 id="second-parent-info-section-label">
      {{ fiParentInfoLabels.secondParentheader | labelText }}
      <a
        class="remove-second-parent-button"
        role="link"
        (click)="toggleSecondParent(false)"
        (keydown.space)="toggleSecondParent(false)"
        (keydown.enter)="toggleSecondParent(false)"
      >
        <span class="arrow material-icons">cancel</span>&nbsp;
        <span class="text" tabindex="0">Remove this Parent/Guardian</span>
      </a>
    </h2>
    <div formGroupName="secondParent">
      <div class="form-control first-field">
        <common-form-control
          [label]="fiParentInfoLabels.firstName | labelText"
          [control]="secondParentFG.controls.firstName"
        >
          <input type="text" data-cy="secondParentFirstName" formControlName="firstName" />
        </common-form-control>
      </div>

      <div class="form-control">
        <common-form-control
          [label]="fiParentInfoLabels.middleName | labelText"
          [isOptional]="true"
          [control]="secondParentFG.controls.middleName"
        >
          <input type="text" formControlName="middleName" data-cy="secondParentMiddleName" />
        </common-form-control>
      </div>

      <div class="form-control">
        <common-form-control
          [label]="fiParentInfoLabels.lastName | labelText"
          [control]="secondParentFG.controls.lastName"
        >
          <input type="text" formControlName="lastName" data-cy="secondParentLastName" />
        </common-form-control>
      </div>

      <div class="form-control">
        <common-form-control
          [label]="fiParentInfoLabels.preferredName | labelText"
          [isOptional]="true"
          [control]="secondParentFG.controls.preferredName"
        >
          <input type="text" formControlName="preferredName" data-cy="secondParentPreferredName" />
        </common-form-control>
      </div>

      <div class="form-control" role="group" aria-label="Date of birth">
        <common-form-control
          [label]="fiParentInfoLabels.birthdate | labelText"
          [control]="secondParentFG.controls.birthdate"
        >
          <common-date-picker
            formControlName="birthdate"
            data-cy="secondParentBirthdate"
            [iesMaxDate]="_dateMax"
            [iesMinDate]="_dateMin"
            required
          ></common-date-picker>
        </common-form-control>
      </div>

      <div class="form-control gender">
        <common-form-control [label]="fiParentInfoLabels.gender | labelText" [control]="secondParentFG.controls.gender">
          <mat-select
            id="gender"
            color="primary"
            [formControl]="secondParentFG.controls.gender"
            data-cy="secondParentGender"
            aria-label="secondParentGender"
            disableOptionCentering="true"
            placeholder="{{ selectOneText }}"
          >
            <mat-option disabled value="">{{ selectOneText }}</mat-option>
            <mat-option *ngFor="let item of genderList | keyvalue: unsort" [value]="item.key">
              {{ item.value['displayValue'] }}
            </mat-option>
          </mat-select>
        </common-form-control>
      </div>

      <div class="form-control-radio-button">
        <common-fieldset-control [label]="fiParentInfoLabels.isEnglishFirstLanguage | labelText">
          <mat-radio-group
            [formControl]="secondParentFG.controls.isEnglishFirstLanguage"
            (change)="secondParentIsEnglishFirstLanguageChange($event.value)"
            color="primary"
          >
            <mat-radio-button data-cy="secondParentIsEnglishFirstLanguage-yes" [value]="true">Yes</mat-radio-button>
            <mat-radio-button data-cy="secondParentIsEnglishFirstLanguage-no" [value]="false">No</mat-radio-button>
          </mat-radio-group>
          <div
            class="error-text"
            *ngIf="
              secondParentFG.controls.isEnglishFirstLanguage?.errors &&
              secondParentFG.controls.isEnglishFirstLanguage?.touched
            "
          >
            This field is required.
          </div>
        </common-fieldset-control>
      </div>
      <ng-container *ngIf="secondParentFG.controls.isEnglishFirstLanguage.value === false">
        <div class="form-control sub-question">
          <common-form-control
            [label]="fiParentInfoLabels.primaryLanguage | labelText"
            [control]="secondParentFG.controls.primaryLanguage"
            [abbreviatedLabel]="'This field'"
          >
            <input type="text" formControlName="primaryLanguage" data-cy="primaryLanguage" maxlength="50" />
          </common-form-control>
        </div>
        <div class="form-control-radio-button sub-question">
          <common-fieldset-control [label]="fiParentInfoLabels.requiresInterpreter | labelText">
            <mat-radio-group [formControl]="secondParentFG.controls.requiresInterpreter" color="primary">
              <mat-radio-button data-cy="secondParentRequiresInterpreter-yes" [value]="true">Yes</mat-radio-button>
              <mat-radio-button data-cy="secondParentRequiresInterpreter-no" [value]="false">No</mat-radio-button>
            </mat-radio-group>
            <div
              class="error-text"
              *ngIf="
                secondParentFG.controls.requiresInterpreter?.errors &&
                secondParentFG.controls.requiresInterpreter?.touched
              "
            >
              This field is required.
            </div>
          </common-fieldset-control>
        </div>
      </ng-container>

      <div class="form-control-radio-button">
        <common-fieldset-control [label]="fiParentInfoLabels.haveOtherCommunicationNeed | labelText">
          <mat-radio-group
            [formControl]="secondParentFG.controls.haveOtherCommunicationNeed"
            (change)="secondParentOtherCommunicationNeedChange($event.value)"
            color="primary"
          >
            <mat-radio-button data-cy="secondParentHaveOtherCommunicationNeed-yes" [value]="true">Yes</mat-radio-button>
            <mat-radio-button data-cy="secondParentHaveOtherCommunicationNeed-no" [value]="false">No</mat-radio-button>
          </mat-radio-group>
          <div
            class="error-text"
            *ngIf="
              secondParentFG?.controls?.haveOtherCommunicationNeed?.errors &&
              secondParentFG?.controls?.haveOtherCommunicationNeed?.touched
            "
          >
            This field is required.
          </div>
          <goa-details heading="Click here for details"
            ><p>{{ fiParentInfoLabels.haveOtherCommunicationNeed.helperText }}</p></goa-details
          >
        </common-fieldset-control>
      </div>

      <ng-container *ngIf="secondParentFG.controls.haveOtherCommunicationNeed.value">
        <div class="form-control sub-question">
          <common-form-control
            [label]="fiParentInfoLabels.otherCommunicationNeed | labelText"
            [control]="secondParentFG.controls.otherCommunicationNeed"
            [abbreviatedLabel]="'This field'"
          >
            <input
              type="text"
              formControlName="otherCommunicationNeed"
              data-cy="secondParentOtherCommunicationNeed"
              maxlength="100"
            />
          </common-form-control>
        </div>
      </ng-container>

      <common-phone-number-multiple
        [formControl]="secondParentFG.controls.phoneNumbers"
        [isTouched]="secondParentFG.get('phoneNumbers').touched"
        [isRequired]="true"
      ></common-phone-number-multiple>

      <div class="form-control">
        <common-email-address
          [formControl]="secondParentFG.controls.email"
          [label]="'Email address'"
          [enableConfirm]="false"
          [isRequired]="true"
          [isTouched]="secondParentFG?.get('email')?.touched"
        >
        </common-email-address>
      </div>

      <div class="form-control-radio-button">
        <common-fieldset-control [label]="fiParentInfoLabels.hasSameAddressAsChild | labelText">
          <mat-radio-group
            [formControl]="secondParentFG.controls.hasSameAddressAsChild"
            (change)="onHasSameAddressAsChildChange($event.value)"
            color="primary"
          >
            <mat-radio-button data-cy="hasSameAddressAsChild-yes" [value]="true">Yes</mat-radio-button>
            <mat-radio-button data-cy="hasSameAddressAsChild-no" [value]="false">No</mat-radio-button>
          </mat-radio-group>
          <div
            class="error-text"
            *ngIf="
              secondParentFG.controls?.hasSameAddressAsChild?.errors &&
              secondParentFG.controls?.hasSameAddressAsChild?.touched
            "
          >
            This field is required.
          </div>
        </common-fieldset-control>
      </div>

      <div
        class="form-control sub-question"
        *ngIf="secondParentFG.controls.hasSameAddressAsChild.value === false"
        data-cy="secondParentResidentialAddress"
      >
        <common-address
          dataCyPrefix="secondParentHome"
          [formControl]="secondParentFG.controls.residentialAddress"
          [isTouched]="secondParentFG?.get('residentialAddress')?.touched"
          [isRequired]="true"
          [useAddressValidationService]="true"
          postalCodeFormat="AB"
        ></common-address>
      </div>

      <div class="form-control">
        <common-form-control
          [label]="fiParentInfoLabels.relationToChild | labelText"
          [control]="secondParentFG.controls.relationToChild"
        >
          <mat-select
            id="relationToChild"
            color="primary"
            data-cy="secondParentRelationToChild"
            [formControl]="secondParentFG.controls.relationToChild"
            aria-label="secondParentRelationToChild"
            placeholder="{{ selectOneText }}"
            (selectionChange)="onRelationChange($event.value)"
            disableOptionCentering="true"
          >
            <mat-option disabled value="">{{ selectOneText }}</mat-option>
            <mat-option *ngFor="let item of relationList | keyvalue: unsort" [value]="item.value['code']">
              {{ item.value['displayValue'] }}
            </mat-option>
          </mat-select>
        </common-form-control>
      </div>
    </div>
  </section>
</div>
