import { Lookup } from './lookup.interface';

export interface TimeSearchRangeLookup extends Lookup {
  days: number;
}

export class TimeSearchRange {
  public static readonly today = { displayValue: 'Today', code: 'today', days: 1};
  public static readonly last60Days = { displayValue: 'Last 60 days', code: 'last60Days', days: 60 };
  public static readonly last30Days = { displayValue: 'Last 30 days', code: 'last30Days', days: 30 };
  public static readonly last7Days = { displayValue: 'Last 7 days', code: 'last7Days', days: 7 };
}

export const TimeSearchRanges: { [name: string]: TimeSearchRangeLookup } = {
  today: TimeSearchRange.today,
  last60Days: TimeSearchRange.last60Days,
  last30Days: TimeSearchRange.last30Days,
  last7Days: TimeSearchRange.last7Days,
};
