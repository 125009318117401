import { Component, Input, OnInit } from '@angular/core';
import { FileService } from '../shared/services/file/file.service';
import { Document, DocumentList } from '@common/entities';
import { startCase, lowerCase } from 'lodash';
import { UploadLookup } from '@common/constants';

export interface CategorizedDocuments {
  category: string;
  documents: Document[];
  optOutLabel: string;
  categoryTitleOverride?: string;
  order?: number;
}

@Component({
  selector: 'common-document-list-preview',
  templateUrl: './document-list-preview.component.html',
  styleUrls: ['./document-list-preview.component.scss'],
})
export class DocumentListPreviewComponent implements OnInit {
  @Input() parentId: string;
  @Input() documents: Document[] = []; //keep this in for backward compatibility as it's being used by old pattern
  // @Input() documentList: DocumentList = null;
  @Input() showInGroups = false;
  @Input() showColumnHeadings = false;
  @Input() categoryLabel = 'Proof of';
  @Input() categoryDisplay = null; //gives us the ability to specify the category display
  @Input() categoryLookup: { [name: string]: UploadLookup };
  groupedDocuments: CategorizedDocuments[];

  _documentList: DocumentList;
  @Input() set documentList(value: DocumentList) {
    if (value) {
      this._documentList = value;
      if (!this.documents || this.documents?.length === 0) {
        this.documents = value.documents;
      }
    }
  }
  get documentList() {
    return this._documentList;
  }

  constructor(private fileService: FileService) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
    this.groupDocuments();
  }

  groupDocuments() {
    this.groupedDocuments = [];
    if (this.documents?.length > 0) {
      const categories = this.documents.map((a) => a.documentCategory);
      const uniqueCategories = [...new Set(categories)];
      uniqueCategories.forEach((category) => {
        const categoryDetails = this.categoryLookup ? this.categoryLookup[category] : null;
        this.groupedDocuments.push({
          category: this.categoryDisplay ?? lowerCase(startCase(category)), //.toLowerCase(),
          documents: this.documents.filter((document) => document.documentCategory == category),
          optOutLabel: null,
          categoryTitleOverride: categoryDetails?.categoryTitleOverride,
          order: categoryDetails?.order ?? 0,
        });
      });
    }

    if (this.documentList?.optOut?.length > 0) {
      const categories = this.documentList?.optOut;
      const uniqueCategories = [...new Set(categories)];
      uniqueCategories.forEach((category) => {
        const categoryDetails = this.categoryLookup ? this.categoryLookup[category] : null;
        this.groupedDocuments.push({
          category: this.categoryDisplay ?? lowerCase(startCase(category)), //.toLowerCase(),
          documents: [],
          optOutLabel: categoryDetails?.optOutLabel,
          categoryTitleOverride: categoryDetails?.categoryTitleOverride,
          order: categoryDetails?.order ?? 0,
        });
      });
    }
    this.groupedDocuments.sort((a, b) => {
      return a.order - b.order;
    });
  }

  onView(documentKey: string) {
    this.fileService.getDocument(documentKey, this.parentId).subscribe((result) => window.open(result['URL']));
  }

  formatDocumentCategory(category: string) {
    //convert from camel case to sentense case
    return startCase(category)
      .toLowerCase()
      .replace(/(^\s*\w|[.!?]\s*\w)/g, function (c) {
        return c.toUpperCase();
      });
  }

  getDoclistLabel(group: CategorizedDocuments, categoryLabel: string) {
    const categoryTitleOverride = group.categoryTitleOverride;
    let uploaderLabel = `${categoryLabel} ${group.category.toLowerCase()}`;
    if (categoryTitleOverride) {
      uploaderLabel = `${categoryTitleOverride}`;
    }
    return uploaderLabel;
  }
}
