import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FbAdditionalResourcesLabels: IntakeLabelCollection = {
  haveLifeInsurance: {
    label: 'Did the deceased have a life insurance policy?',
  },
  whoIsBeneficiary: {
    label: 'Who is the beneficiary?',
    helperText: 'A beneficiary is the person who will receive the life insurance policy payment',
  },
  otherBeneficiary: {
    label: 'Who is the other beneficiary?',
  },
  havePrepaidFuneral: {
    label: 'Did the deceased have a prepaid funeral?',
  },
  isFirstNationBand: {
    label: 'Is the First Nation Band contributing to the funeral costs?',
  },
  belongToGroupProgram: {
    label: 'Did the deceased belong to any of the following groups/programs:',
  },
  knowCauseOfDeath: {
    label: 'Was the cause of death any of the following:',
  },
  hasAlternateFundingApplied: {
    label: 'Have you applied for funding from an alternative source designed to cover these expenses?',
    helperText:
      'There are programs that have funeral benefits that are intended to cover these expenses. You must apply for these before submitting this application.',
  },
  wasAlternateFundingDenied: {
    label: 'Were you denied funding from these alternative sources?',
    helperText: 'Please upload a copy of this denial below.',
    alternateHelperText: ' You must use these sources of funding for funeral benefits, or upload proof of denial.',
  },

  hasDeathBenefitApplied: {
    label: 'Have you applied for the Canada Pension Plan (CPP) death benefit on behalf of the deceased?',
  },
  cppDeathBenefitInfo: {
    label: 'The Canada Pension Plan (CPP) death benefit is expected to be put towards the cost of the funeral service.',
  },
  cppDeathBenefitDeduction: {
    label:
      '$2500 will be deducted from the total amount received from the Alberta funeral benefit, if you have or will receive the CPP death benefit.',
    replacementLabel:
      '$<<replacementLabel>> will be deducted from the total amount received from the Alberta funeral benefit, if you have or will receive the CPP death benefit.',
  },
};
