import { User } from './user.entity';

export class PatchEntity {
  op: string;
  path: string;
  value: string; //serialize object to string for graphql compatibility
  old: string;
}

export class PatchesEntity {
  _id: string;
  ops: Array<PatchEntity>;
  date: Date;
  name: string;
  action: string;
  updatedBy: User;
}
