import { Lookup } from '../lookup.interface';

export interface ApplicationListFieldLookup extends Lookup {
  selected: boolean;
}

export class ApplicationListField {
  static readonly isEmergency: ApplicationListFieldLookup = {
    displayValue: 'Emergency',
    code: 'isEmergency',
    selected: false,
  };
  static readonly interpreter: ApplicationListFieldLookup = {
    displayValue: 'Interpreter',
    code: 'interpreter',
    selected: false,
  };
  static readonly partner: ApplicationListFieldLookup = {
    displayValue: 'Marital status',
    code: 'partner',
    selected: false,
  };
  static readonly firstName: ApplicationListFieldLookup = {
    displayValue: 'First Name',
    code: 'firstName',
    selected: false,
  };
  static readonly lastName: ApplicationListFieldLookup = {
    displayValue: 'Last Name',
    code: 'lastName',
    selected: false,
  };
  static readonly appliedAt: ApplicationListFieldLookup = {
    displayValue: 'Applied',
    code: 'appliedAt',
    selected: false,
  };
  static readonly caseId: ApplicationListFieldLookup = { displayValue: 'HS ID', code: 'caseId', selected: false };
  static readonly municipalityName: ApplicationListFieldLookup = {
    displayValue: 'Municipality',
    code: 'municipalityName',
    selected: false,
  };
  static readonly socialInsuranceNumber: ApplicationListFieldLookup = {
    displayValue: 'SIN',
    code: 'socialInsuranceNumber',
    selected: false,
  };
  static readonly assignedAt: ApplicationListFieldLookup = {
    displayValue: 'Assigned',
    code: 'assignedAt',
    selected: false,
  };
  static readonly dueDate: ApplicationListFieldLookup = {
    displayValue: 'Due',
    code: 'contactAttemptDueDate',
    selected: false,
  };

  static readonly caseStatus: ApplicationListFieldLookup = {
    displayValue: 'Case status',
    code: 'caseStatus',
    selected: false,
  };
}

export const ApplicationListFields = {};
for (const [key, value] of Object.entries(ApplicationListField)) {
  ApplicationListFields[key] = value;
}
