import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'common-remove-button',
  templateUrl: './remove-button.component.html',
  styleUrls: ['./remove-button.component.scss'],
})
export class RemoveButtonComponent {
  @Input() label: string;
  @Input() dataCy: string;
  @Output() click = new EventEmitter();
  onClick() {
    this.click.emit();
  }
}
