import { Lookup } from './lookup.interface';
export interface GenderLookup extends Lookup {
  edbId: string;
  lmtaDisplayValue?: string;
}

export const Genders: { [name: string]: GenderLookup } = {
  male: { displayValue: 'Male', code: 'male', edbId: '2' },
  female: { displayValue: 'Female', code: 'female', edbId: '1' },
  genderDiverse: { displayValue: 'Gender diverse', lmtaDisplayValue: 'Unspecified', code: 'genderDiverse', edbId: '3' },
  other: { displayValue: 'Prefer not to say', lmtaDisplayValue: 'Prefer not to report', code: 'other', edbId: '3' },
};
