import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FiChildInfoLabels: IntakeLabelCollection = {
  header: { label: 'Child information' },
  firstName: { label: 'Child’s First name' },
  middleName: { label: 'Child’s Middle name' },
  lastName: { label: 'Child’s Last name' },
  preferredName: { label: 'Preferred name, if different' },
  birthdate: { label: 'Date of birth' },
  gender: { label: 'Gender' },
  hasPrevApplication: { label: 'Has this child had previous involvement with FSCD?' },
  hasPrevClosedWithin90: { label: 'Has this child’s FSCD file been closed within the last 90 days?' },
  isChildCitizenOrPermanentResident: { label: 'Citizenship/immigration status' },
  residentialAddress: { label: 'Residential address' },
  street: { label: 'Street address' },
  suite: { label: 'Suite or unit #' },
  city: { label: 'City/town' },
  province: { label: 'Province/territory' },
  postalCode: { label: 'Postal code' },
  hasSameMailingAddress: { label: 'Is the child’s mailing address the same as the residential address?' },
  livingArrangements: { label: 'Child’s living arrangements' },
  hasConfirmedDiagnosis: { label: 'Does the child have a confirmed diagnosis?' },
  confirmedDiagnosis: { label: 'Please provide the child’s confirmed diagnosis' },
  isAwaitingDiagnosis: { label: 'Is the child waiting to receive a diagnosis?' },
  diagnosisExplored: { label: 'Please provide the diagnosis being explored' },
};
