import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Municipalities } from '@common/constants';
import { map, merge, Observable, ReplaySubject, startWith } from 'rxjs';
import { ValidateABMuni } from './ab-muni.validator';

@Component({
  selector: 'common-ab-city-autocomplete',
  templateUrl: './ab-city-autocomplete.component.html',
  styleUrls: ['./ab-city-autocomplete.component.scss'],
})
export class AbCityAutocompleteComponent implements OnInit {
  majorCities: IMunicipalityOption[];
  allMunicipalities: IMunicipalityOption[];
  cityOptions$: Observable<IMunicipalityGroup[]>;

  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() cyTag: string;
  @Input() searchMethod: 'contains' | 'startsWith' = 'startsWith';
  private _municipalities: IMunicipalityOption[];
  @Input()
  public get municipalities(): IMunicipalityOption[] {
    return this._municipalities;
  }
  public set municipalities(value: IMunicipalityOption[]) {
    this._municipalities = value;
    this.makeMunicipalityOptions();
  }

  @ViewChild('autoInput')
  inputElement: ElementRef<HTMLInputElement>;
  private get input() {
    return this.inputElement?.nativeElement;
  }

  private inputChange$ = new ReplaySubject<string>();

  get value(): any {
    return this.input.value;
  }
  set value(newVal: any) {
    this.input.value = newVal;
    this.onChange(newVal);
  }

  ngOnInit(): void {
    if (!this.controlName) {
      throw new Error("ABCityAutocomplete: must have 'controlName' set.");
    }
    if (!this.formGroup) {
      throw new Error("ABCityAutocomplete: must have a 'formGroup' set.");
    }

    const cityControl = this.formGroup.get(this.controlName);
    if (!cityControl) {
      throw new Error(`ABCityAutocomplete: form control not found matching '${this.controlName}'`);
    }

    this.makeMunicipalityOptions();

    this.onInput(cityControl.value);
    this.cityOptions$ = merge(
      // Value changes from the form control.
      cityControl.valueChanges.pipe(map((v) => v as string)),
      // Value changes from local events.
      this.inputChange$.asObservable()
    ).pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  onChange(newVal: string) {
    this.inputChange$.next(newVal);
  }

  onInput(newVal: string) {
    this.inputChange$.next(newVal);
  }

  private _filter(value: string): IMunicipalityGroup[] {
    const filterVal = value.toLowerCase();
    if (filterVal == '') {
      return [
        { name: 'Major cities', options: this.majorCities },
        { name: 'Municipalities', options: this.allMunicipalities },
      ];
    }

    return [
      {
        name: undefined,
        options: this.allMunicipalities.filter((m) =>
          this.searchMethod === 'startsWith' ? m.lcName.startsWith(filterVal) : m.lcName.includes(filterVal)
        ),
      },
    ];
  }

  private makeMunicipalityOptions() {
    this.allMunicipalities = (this.municipalities ?? Municipalities).map((m) => ({
      name: m.name,
      id: m._id,
      lcName: m.name.toLowerCase(),
    }));

    this.majorCities = ['Calgary', 'Edmonton', 'Red Deer', 'Lethbridge'].map((id) =>
      this.allMunicipalities.find((m) => m.id === id)
    );

    const cityControl = this.formGroup.get(this.controlName);
    if (cityControl) cityControl.addValidators([ValidateABMuni(this.municipalities)]);
  }
}

interface IMunicipalityOption {
  name: string;
  id: string;
  lcName: string;
}

interface IMunicipalityGroup {
  name: string;
  options: IMunicipalityOption[];
}
