import { UntypedFormControl } from '@angular/forms';

export class IntegerFormControl extends UntypedFormControl {
  constructor(...args) {
    super(...args);
  }

  setValue(value, options: { onlySelf?: boolean; emitEvent?: boolean } = {}) {
    super.setValue(parseInt(value) || undefined, options);
  }
}
