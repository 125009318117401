import { UploadLookup } from './upload-lookup.interface';
export const IesAssetTypes: { [name: string]: UploadLookup } = {
  bankAccounts: {
    displayValue: 'Bank accounts',
    documentTypes: ['Statement from bank account'],
    needSupportDocument: true,
    order: 1,
    helpText: 'Include the total amount of money you have in all of your bank accounts.',
    isOptional: true,
    suppressOptionalLabel: true,
  },
  tfsa: {
    displayValue: 'Tax-free savings account',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 2,
  },
  insurance: {
    displayValue: 'Life insurance (cash surrender value)',
    documentTypes: [],
    needSupportDocument: true,
    order: 3,
  },
  rrsp: {
    displayValue: 'Registered Retirement Savings Plan (RRSP)',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 4,
  },
  stocksAndBonds: {
    displayValue: 'Stocks / bonds / mutual funds',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 5,
  },
  uncashedChequesOrCashOnHand: {
    displayValue: 'Uncashed cheques or cash on hand',
    documentTypes: [],
    needSupportDocument: false,
    order: 6,
    helpText: 'Money or cheques you have in your wallet, house or at another location.',
  },
};
