import { Lookup } from '../lookup.interface';

export interface ArchiveReasonsLookup extends Lookup {
  disabled?: boolean;
}
export const ActionPlanArchiveReasons: { [name: string]: ArchiveReasonsLookup } = {
  activeOnAISH: {
    displayValue: 'Active on AISH',
    code: 'activeOnAISH',
  },
  incompleteArcReporting: {
    displayValue: 'ARC Reporting not Completed',
    code: 'incompleteArcReporting',
  },
  clientRequest: {
    displayValue: 'Client request',
    code: 'clientRequest',
  },
  deceased: {
    displayValue: 'Deceased',
    code: 'deceased',
  },
  excessIncomeResourcesWithEmployment: {
    displayValue: 'Excess income/resources including employment',
    code: 'excessIncomeResourcesWithEmployment',
  },
  excessIncomeResourcesWithoutEmployment: {
    displayValue: 'Excess income/resources non-employment',
    code: 'excessIncomeResourcesWithoutEmployment',
  },
  incarceration: {
    displayValue: 'Incarceration',
    code: 'incarceration',
  },
  leavingEmployment: {
    displayValue: 'Leaving employment that could have been reasonably maintained',
    code: 'leavingEmployment',
  },
  movingOutOfProvince: {
    displayValue: 'Moving out of province',
    code: 'movingOutOfProvince',
  },
  nonCompliance: {
    displayValue: 'Non-compliance',
    code: 'nonCompliance',
  },
  notAvailableToPursueEmployment: {
    displayValue: 'No longer available to pursue reasonable employment',
    code: 'notAvailableToPursueEmployment',
    disabled: true,
  },
  startedTraining: {
    displayValue: 'Started training',
    code: 'startedTraining',
  },

  other: {
    displayValue: 'Other',
    code: 'other',
  },
};
