<div>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Child information</mat-panel-title>
      </mat-expansion-panel-header>
      <ng-content select="[slot-child]"> </ng-content>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Parent/guardian information</mat-panel-title>
      </mat-expansion-panel-header>
      <ng-content select="[slot-parent]"> </ng-content>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Services</mat-panel-title>
      </mat-expansion-panel-header>
      <ng-content select="[slot-services]"> </ng-content>
    </mat-expansion-panel>
  </mat-accordion>
</div>
