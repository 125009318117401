import { ValidationIssue } from '@common/entities';
import { Expose } from 'class-transformer';

export class FscdIntakeApplicationErrors {
  @Expose()
  childErrors: ValidationIssue[];

  @Expose()
  parentGuardianErrors: ValidationIssue[];

  @Expose()
  servicesErrors: ValidationIssue[];

  @Expose()
  reviewErrors: ValidationIssue[];
}
