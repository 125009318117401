<div class="multi-select-container">
  <mat-chip-listbox
    selectable
    multiple
    #chipList
    [ngClass]="{ focused: isFocused, invalid: showError, disabled: disabled }"
  >
    <mat-chip-option
      [selected]="option.selected"
      [disableRipple]="true"
      *ngFor="let option of options; let $index = index"
      [value]="option.value"
    >
      {{ option.displayValue }}
    </mat-chip-option>
  </mat-chip-listbox>
</div>
