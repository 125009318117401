import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FiRelations } from '@common/constants';
import { User } from '@common/entities';
import { ContextProvider, FileService } from '@common/ui/shared-components';
import { ValidationUtils } from '@common/utils';
import { FiParent, FscdIntakeApplication, FscdIntakeApplicationErrors } from '@fscd-intake/entities';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';
import { Apollo } from 'apollo-angular';
import { Observable, Subject, lastValueFrom, map, of, takeUntil } from 'rxjs';
import { FiGraphqlService } from '../../services/fi-graphql.service';
import { BaseSaveComponent } from '../base-save/base-save.component';
import { PageNavigation } from '../page-navigation';

@Component({
  selector: 'fi-parent-info-page',
  templateUrl: './parent-info-page.component.html',
  styleUrls: ['./parent-info-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParentInfoPageComponent extends BaseSaveComponent implements OnInit, OnDestroy, PageNavigation {
  private destroy$ = new Subject<void>();
  application: FscdIntakeApplication;
  isSubmitted: boolean;
  loading = true;
  parentInfo$: Observable<FiParent>;
  hasErrors$: Observable<boolean>;
  profile$: Observable<User>;
  parentFormValue;

  constructor(
    apollo: Apollo,
    fileService: FileService,
    authenticationService: AuthenticationService,
    contextProvider: ContextProvider,
    private graphqlService: FiGraphqlService,
    public cdr: ChangeDetectorRef
  ) {
    super(apollo, fileService, authenticationService, contextProvider, graphqlService);
  }
  next(): boolean {
    return true;
  }

  async ngOnInit() {
    await super.ngOnInit();
    await this.loadParentInfo();
  }

  async loadParentInfo() {
    const { selectedApplicationId, isSubmitted } = await this.graphqlService.getSelectedApplication();
    this.isSubmitted = isSubmitted;
    this.applicationId = selectedApplicationId;

    this.profile$ = this.contextProvider.getCurrentUser();
    if (this.applicationId) {
      const results$ = this.graphqlService.getFullApplication(this.applicationId);
      this.parentInfo$ = results$.pipe(takeUntil(this.destroy$)).pipe(
        map((application) => {
          this.application = application;
          if (application.parent) {
            return application.parent;
          } else {
            this.getParentInfoFromSubmittedApp();
          }
        })
      );

      this.hasErrors$ = results$.pipe(
        map((application) => {
          return application?.applicationErrors?.parentGuardianErrors?.length > 0 || false;
        })
      );
    }
  }

  async getParentInfoFromSubmittedApp() {
    const allApplications = await lastValueFrom(this.fiGraphqlService.getAllApplications());

    if (allApplications.length === 0) return;

    const latestSubmittedApplication = this.findLatestSubmittedApplication(allApplications);

    if (!latestSubmittedApplication) return;

    const submittedApp$ = this.graphqlService.getFullApplication(latestSubmittedApplication.id);
    submittedApp$.pipe(takeUntil(this.destroy$)).subscribe((submittedApp) => {
      if (submittedApp.parent) {
        //Don't copy documents from submitted application
        if (submittedApp.parent?.documents?.length > 0) submittedApp.parent.documents = [];

        this.parentInfo$ = of(submittedApp.parent);
        this.cdr.detectChanges();
      }
    });
  }

  private findLatestSubmittedApplication(applications: FscdIntakeApplication[]): FscdIntakeApplication | null {
    return applications
      .filter((application) => application.isSubmitted === true)
      .reduce((latestApp, currentApp) => {
        if (!latestApp || currentApp.updatedAt > latestApp.updatedAt) {
          return currentApp;
        }
        return latestApp;
      }, null);
  }

  async onFormError() {
    this.displayStaticToastError('One or more fields are not filled in correctly.');
  }

  async onFormUpdated(formValue) {
    this.parentFormValue = formValue;
  }

  save() {
    super.save();
    const updatedFormValue = this.parentFormValue;
    this.deleteDocuments(updatedFormValue?.documents);
    if (updatedFormValue) {
      delete updatedFormValue['uploadControl'];
    }

    const relationToChild = updatedFormValue?.relationToChild;
    if (relationToChild === FiRelations.parent?.code) {
      //delete all documents
      this.deleteDocuments(updatedFormValue?.documents, true);
    }

    const parent = {
      ...updatedFormValue,
    } as FiParent;

    const applicationErrors = this.application?.applicationErrors
      ? { ...this.application.applicationErrors }
      : new FscdIntakeApplicationErrors();
    applicationErrors.parentGuardianErrors = ValidationUtils.validateEntity(FiParent, parent);

    const applicationToSave = {
      id: this.applicationId,
      parent: parent,
      applicationErrors: applicationErrors,
    } as FscdIntakeApplication;
    return lastValueFrom(this.graphqlService.updateApplication(applicationToSave));
  }

  async ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
