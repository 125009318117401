import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'common-info-icon',
  templateUrl: './info-icon-component.component.html',
  styleUrls: ['./info-icon-component.component.scss'],
})
export class InfoIconComponentComponent implements OnInit {
  @Input() containerElement: ElementRef;
  @Input() messageElement: ElementRef;
  @Input() expanded = false;
  @Input() infoTooltip: string;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    if (this.containerElement) {
      this.renderer.addClass(this.containerElement.nativeElement || this.containerElement, 'info-container');
      this.renderer[this.expanded ? 'addClass' : 'removeClass'](
        this.containerElement.nativeElement || this.containerElement,
        'info-container-expanded'
      );
    }
    if (this.messageElement) {
      this.renderer.addClass(this.messageElement.nativeElement || this.messageElement, 'info-text');
    }
    let id = '';
    if (
      this.containerElement &&
      this.messageElement &&
      (id =
        (this.messageElement.nativeElement || this.messageElement).id ||
        ((this.messageElement.nativeElement || this.messageElement).children?.length === 1 &&
          (this.messageElement.nativeElement || this.messageElement).children[0].id))
    ) {
      this.renderer.setAttribute(this.containerElement.nativeElement || this.containerElement, 'aria-describedby', id);
    }
  }

  onClick($event): void {
    $event.preventDefault();

    if (!this.infoTooltip && this.containerElement) {
      this.expanded = !this.expanded;
      this.renderer[this.expanded ? 'addClass' : 'removeClass'](
        this.containerElement.nativeElement || this.containerElement,
        'info-container-expanded'
      );
    }
  }
}
