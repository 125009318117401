import { DocumentDetails } from '@common/entities';
import { FscdIntakeApplication } from '@fscd-intake/entities';
export class DocumentIterator {
  private application: FscdIntakeApplication;
  private documentDetails: DocumentDetails[] = [];

  constructor(application: FscdIntakeApplication) {
    this.application = application;
  }

  public iterateNames(): DocumentDetails[] {
    this.getApplicationDocuments();
    this.getChildIdentityDocuments();
    this.getChildMedicalDocuments();
    this.getChildAdditionalDocuments();
    this.getParentDocuments();
    return this.documentDetails;
  }

  private getApplicationDocuments() {
    if (this.application?.outputPdfUrl) {
      this.collectDocumentDetails(this.application.outputPdfFileName, 'Full Application', 'Application');
    }
  }

  private getChildIdentityDocuments() {
    if (this.application?.child?.identityDocuments?.length > 0) {
      this.application.child.identityDocuments.forEach((x) => {
        this.collectDocumentDetails(x.key, x.documentCategory, x.documentType);
      });
    }
  }

  private getChildMedicalDocuments() {
    if (this.application?.child?.medicalDocuments?.length > 0) {
      this.application.child.medicalDocuments.forEach((x) => {
        this.collectDocumentDetails(x.key, x.documentCategory, x.documentType);
      });
    }
  }
  private getChildAdditionalDocuments() {
    if (this.application?.child?.additionalDocuments?.length > 0) {
      this.application.child.additionalDocuments?.forEach((x) => {
        this.collectDocumentDetails(x.key, x.documentCategory, x.documentType);
      });
    }
  }

  private getParentDocuments() {
    if (this.application?.parent?.documents?.length > 0) {
      this.application.parent?.documents?.forEach((x) => {
        this.collectDocumentDetails(x.key, x.documentCategory, x.documentType);
      });
    }
  }

  collectDocumentDetails(
    documentKey: string,
    documentCategory: string,
    documentType: string,
    isSpouseDocument?: boolean,
    isDependentDocument?: boolean
  ) {
    this.documentDetails.push({
      name: documentKey,
      category: documentCategory,
      type: documentType,
      clientName: this.getApplicantFullName(),
      isSpouseDocument: isSpouseDocument ?? false,
      isDependentDocument: isDependentDocument ?? false,
    });
  }

  getApplicantFullName() {
    const applicant = this.application.child;
    const middleName = applicant.middleName;
    if (middleName) return `${applicant.firstName} ${middleName} ${applicant.lastName}`;
    else return `${applicant.firstName} ${applicant.lastName}`;
  }
}
