import { Component, Input } from '@angular/core';
import { FiServicesLabels } from '@common/labels';
import { Utilities } from '@common/utils';
import { FiServices, ModifiedYesNoOptionsUnsureYes, ServiceDurationOptions } from '@fscd-intake/entities';

@Component({
  selector: 'fi-view-application-services',
  templateUrl: './view-application-services.component.html',
  styleUrls: ['./view-application-services.component.scss'],
})
export class ViewApplicationServicesComponent {
  labels = FiServicesLabels;
  customRadioOptions = ModifiedYesNoOptionsUnsureYes;
  serviceDurationOptions = ServiceDurationOptions;
  @Input() services: FiServices;
  utility = Utilities;
}
