import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { gql } from '@apollo/client/core';
import { Page, User } from '@common/entities';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';

/**
 * Provider for context information
 */
@Injectable({
  providedIn: 'root',
})
export class ContextProvider {
  CURRENT_USER_QUERY = gql`
    query getCurrentUser {
      user @client {
        _id
        name
        roles
        primaryRole
        title
        location
        phone
        agency
        email
        district
        office @client {
          phone
          fax
          streetAddress
          city
          postalCode
          province
        }
      }
    }
  `;

  constructor(private router: Router, public apollo: Apollo) {
    // subscribe to navigation end and get the page info from the route
    // see ../../case-management.routes.ts
    this.router.events.pipe(startWith(this.router)).subscribe((event) => {
      if (!(event instanceof Router) && !(event instanceof NavigationEnd)) return;

      let route = this.router.routerState.root;
      while (route.firstChild) {
        route = route.firstChild;
      }

      this._currentPageContext = {
        id: route.snapshot.data['id'],
        name: route.snapshot.data['label'],
        //relies on param inheritance
        caseId: route.snapshot.params['caseId'],
        allowedRoles: route.snapshot.data['allowedRoles'],
      };
      this.pageContext.next(this._currentPageContext);
    });
  }

  /**
   * Context information about the current activated page
   */
  pageContext = new BehaviorSubject<Page>(null);

  private _currentPageContext;
  /**
   * Returns the current page context
   */
  getCurrentPageContext(): Page {
    return this._currentPageContext;
  }

  /**
   * Returns the current logged in user
   */
  getCurrentUser() {
    return this.apollo
      .watchQuery<{ user: User }>({
        query: this.CURRENT_USER_QUERY,
        returnPartialData: true, //incase user has missing attributes
      })
      .valueChanges.pipe(map((result) => result.data?.user))
      .pipe(shareReplay(1));
  }

  /**
   * Sets the current user
   */
  setCurrentUser(userProfile?: User) {
    this.apollo.client.writeQuery({
      query: this.CURRENT_USER_QUERY,
      data: { user: userProfile },
    });
  }
}
