<div class="nav-flexbox-container">
  <ng-container *ngIf="!_isBelowBreakpoint">
    <div class="nav-flexbox-item" *ngFor="let link of navlinks">
      <div [ngClass]="{ 'nav-flexbox-item-current': link.current === 1 }">
        <a [attr.data-cy]="link.dataCy" href="{{ link.url }}">{{ link.title }}</a>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="_isBelowBreakpoint">
    <button
      class="nav-menu"
      mat-icon-button
      [matMenuTriggerFor]="navMenu"
      overlayPanelClass="compass-menu"
      yPosition="below"
      aria-label="Navigation options"
    >
      <span class="material-icons-outlined menu-icon"> menu </span>
    </button>
    <mat-menu #navMenu="matMenu">
      <a
        class="compass-menu-item"
        *ngFor="let link of navlinks"
        mat-menu-item
        [attr.data-cy]="link.dataCy"
        href="{{ link.url }}"
        >{{ link.title }}</a
      >
    </mat-menu>
  </ng-container>
</div>
