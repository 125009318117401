import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { PhoneNumber } from '@common/entities';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Component for user input of a phone number.
 */
@Component({
  selector: 'common-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: PhoneNumberComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: PhoneNumberComponent,
      multi: true,
    },
  ],
})
export class PhoneNumberComponent implements ControlValueAccessor, OnDestroy, OnInit {
  _destroy$ = new Subject<void>();

  @Input() dataCy = 'datacy';

  /**
   * Hides the labels
   */
  @Input() hideLabel = false;

  /**
   * Is touched.  This is a bit of a hack, because CVA doesn't propogate markAllAsTouched
   */
  @Input() set isTouched(value) {
    this._isTouched = value;
    if (value) {
      this.formGroup?.markAllAsTouched();
    } else {
      this.formGroup?.markAsUntouched();
    }
  }
  private _isTouched = false;

  @Input() label = 'Phone number';

  @Input() isRequired = true;
  formGroup: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      phoneNumber: ['', { validators: this.isRequired ? Validators.required : '', updateOn: 'blur' }],
      type: ['Mobile', { updateOn: 'blur' }],
      isPrimary: [false],
    });
    if (this._isTouched) {
      this.formGroup.markAllAsTouched();
    }
  }
  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  validate() {
    return this.formGroup.valid ? null : { invalid: true };
  }

  writeValue(obj: PhoneNumber): void {
    this.formGroup.patchValue(obj, { emitEvent: false });
  }

  onChange;
  registerOnChange(fn) {
    this.formGroup.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(fn);
  }
  touched;
  registerOnTouched(fn) {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({ emitEvent: false });
    } else {
      this.formGroup.enable({ emitEvent: false });
    }
  }
}
