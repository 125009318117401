import { Lookup } from '@common/constants';

export interface ServiceDurationLookup extends Lookup {
  requiredForConfirmedDiagnosis?: boolean;
}

export const ServiceDurationOptions: {
  [name: string]: ServiceDurationLookup;
} = {
  oneYear: {
    displayValue: '1 year',
    code: 'oneYear',
  },
  twoYears: {
    displayValue: '2 years',
    code: 'twoYears',
  },
  threeYears: {
    displayValue: '3 years',
    code: 'threeYears',
    requiredForConfirmedDiagnosis: true,
  },
};
