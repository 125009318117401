import { FinancialsLabels } from './financials-labels.interface';

export const FbDeceasedFinancialLabels: FinancialsLabels = {
  hasNoIncome: {
    label: 'The deceased did not have any income.',
  },
  incomeNotProvided: {
    label: `The deceased's income was not provided.`,
  },
  hasNoAssets: {
    label: 'The deceased did not have any assets.',
  },
  assetsNotProvided: {
    label: `The deceased's assets were not provided.`,
  },
  // hasIncomeAssets: {
  //   label:
  //     'You have not shared any financial information for the deceased. Does the deceased have any of the above sources of income or assets?',
  // },
  // canProvideBankStatement: {
  //   label: "Are you able to provide the deceased's bank accounts/statements?",
  // },
  knowBankLocation: {
    label: 'Can you provide this banking information?',
  },
  noIncomeAssets: {
    label: 'No information regarding the income or assets of the deceased has been shared.',
  },
};
