import { registerDecorator } from 'class-validator';

export function NoLeadingWhiteSpace() {
  return function (object, propertyName: string) {
    registerDecorator({
      name: 'NoLeadingWhiteSpace',
      target: object.constructor,
      propertyName: propertyName,
      validator: {
        validate(value: string) {
          let isValid = true;

          isValid = !value?.startsWith(' ')

          return isValid;
        },
        defaultMessage(): string {
          return 'value has leading whitespace';
        },
      },
    });
  };
}
