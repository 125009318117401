import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot } from '@angular/router';

//defaults can be seen under
//https://angular.io/api/router/BaseRouteReuseStrategy
export class CustomReuseStrategy implements RouteReuseStrategy {
  handlers: { [key: string]: DetachedRouteHandle } = {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
      this.handlers[route.routeConfig.path] = handle;
  }
  
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
      return !!route.routeConfig && !!this.handlers[route.routeConfig.path];
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
      console.debug('CustomReuseStrategy:retrieve', route);
      if (!route.routeConfig) return null;
      return this.handlers[route.routeConfig.path];
  }
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future.routeConfig === curr.routeConfig) {
      return !future.data.alwaysRefresh;
    } else {
      return false;
    }
  }
}
