import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FiPrescreenLabels: IntakeLabelCollection = {
  birthdate: {
    label: "Child's full date of birth",
  },
  isChildCitizenOrPermanentResident: {
    label: 'Is this child a Canadian citizen or permanent resident?',
  },
  isResidentOfAlberta: {
    label: 'Is this child a resident of Alberta?',
  },
  isParentOrGuardian: {
    label: 'Are you the parent/legal guardian of the child requiring FSCD services?',
    helperText:
      'A guardian is someone who is responsible for the child’s development and well-being. In most cases, the parents of a child will also be the child’s guardians. Parent means a person determined under Part 1 of the Family Law Act to be a parent of a child. Parents are generally defined as the birth mother and biological father, or a person specified as a parent of the child in an adoption order. A guardian may also be a person who is appointed under Part 2 of the Family Law Act, or a person who is a guardian of the child under an agreement or order made pursuant to the Child, Youth and Family Enhancement Act.',
  },
  hasConfirmedDiagnosis: {
    label: 'Does this child have a confirmed diagnosis for a disability?',
    helperText: `According to the FSCD ACT, a disability is a "chronic developmental, physical, sensory, mental or neurological condition or impairment that does not include a condition for which the primary need is for medical care or health services to treat or manage the condition, unless it is a chronic condition that significantly limits a child's ability to function in normal daily living".`,
  },
  isAwaitingDiagnosis: {
    label: 'Is this child waiting to receive a diagnosis for a disability?',
    helperText: `According to the FSCD ACT, a disability is a "chronic developmental, physical, sensory, mental or neurological condition or impairment that does not include a condition for which the primary need is for medical care or health services to treat or manage the condition, unless it is a chronic condition that significantly limits a child's ability to function in normal daily living".`,
  },
};
