import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateOrToday',
})
export class DateOrTodayPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(
    date: string | number | Date,
    leadingPhrase?: string,
    format?: string,
    timezone?: string,
    locale?: string
  ): string {
    const today = new Date().setHours(0, 0, 0, 0);
    const compareDate = new Date(date).setHours(0, 0, 0, 0);
    if (!compareDate) throw new Error(`Invalid date: ${JSON.stringify(date)}`);
    return compareDate === today
      ? 'today'
      : `${leadingPhrase ? leadingPhrase + ' ' : ''}${this.datePipe.transform(date, format, timezone, locale)}`;
  }
}
