import { Component } from '@angular/core';
import { ContextProvider } from '@common/ui/shared-components';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';

@Component({
  selector: 'fi-session-timeout-page',
  templateUrl: './session-timeout-page.component.html',
  styleUrls: ['./session-timeout-page.component.scss'],
})
export class SessionTimeoutPageComponent {
  constructor(private authenticationService: AuthenticationService, private contextProvider: ContextProvider) {}

  login(url?: string) {
    const returnUrl = url ? url : window.location.origin;
    this.contextProvider.setCurrentUser(null);
    this.authenticationService.signIn(returnUrl);
  }
}
