<div [formGroup]="formGroup">
  <div class="indian-registration-numbers">
    <div class="registration-number control-margin">
      <common-form-control helperMessage="3 digits" [control]="formGroup.controls.band" label="Band #">
        <input
          type="text"
          inputmode="tel"
          maxlength="3"
          formControlName="band"
          [attr.data-cy]="dataCyPrefix + 'Band'"
          iesNumberOnly
        />
      </common-form-control>
    </div>
    <div class="registration-number control-margin">
      <common-form-control helperMessage="5 digits max" [control]="formGroup.controls.family" label="Family #">
        <input
          type="text"
          inputmode="tel"
          maxlength="5"
          formControlName="family"
          [attr.data-cy]="dataCyPrefix + 'Family'"
          iesNumberOnly
        />
      </common-form-control>
    </div>
    <div class="registration-number control-margin">
      <common-form-control helperMessage="2 digits" [control]="formGroup.controls.position" label="Position #">
        <input
          type="text"
          inputmode="tel"
          maxlength="2"
          [attr.data-cy]="dataCyPrefix + 'Position'"
          formControlName="position"
          iesNumberOnly
        />
      </common-form-control>
    </div>
  </div>
  <div class="form-control">
    <common-fieldset-control
      label="{{ addressee === 'you' ? 'Are' : 'Is your' }} {{ addressee?.toLowerCase() }} living on a reserve?"
    >
      <div [class.error]="formGroup.controls.livesOnReserve.errors && formGroup.controls.livesOnReserve.touched">
        <mat-radio-group color="primary" [formControl]="formGroup.controls.livesOnReserve">
          <mat-radio-button [attr.data-cy]="dataCyPrefix + 'LivesOnReserve-yes'" [value]="true">Yes</mat-radio-button>
          <mat-radio-button [attr.data-cy]="dataCyPrefix + 'LivesOnReserve-no'" [value]="false">No</mat-radio-button>
        </mat-radio-group>
      </div>
      <div
        class="error-text"
        *ngIf="formGroup?.controls?.livesOnReserve?.errors && formGroup?.controls?.livesOnReserve?.touched"
      >
        This field is required.
      </div>
    </common-fieldset-control>
  </div>
  <div class="form-control">
    <common-fieldset-control
      label="{{ addressee === 'you' ? 'Do' : 'Does your' }} {{ addressee?.toLowerCase() }} receive funds from {{
        addressee === 'you' ? 'your' : 'their'
      }} Band?"
    >
      <div
        [class.error]="
          formGroup.controls.receivesFundsFromBand.errors && formGroup.controls.receivesFundsFromBand.touched
        "
      >
        <mat-radio-group color="primary" [formControl]="formGroup.controls.receivesFundsFromBand">
          <mat-radio-button [attr.data-cy]="dataCyPrefix + 'ReceivesFundsFromBand-yes'" [value]="true"
            >Yes</mat-radio-button
          >
          <mat-radio-button [attr.data-cy]="dataCyPrefix + 'ReceivesFundsFromBand-no'" [value]="false"
            >No</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div
        class="error-text"
        *ngIf="
          formGroup?.controls?.receivesFundsFromBand?.errors && formGroup?.controls?.receivesFundsFromBand?.touched
        "
      >
        This field is required.
      </div>
    </common-fieldset-control>
  </div>
</div>
