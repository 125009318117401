import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FbPrescreenLabels: IntakeLabelCollection = {
  hasStatusInCanada: {
    label: 'Was the deceased one of the following?',
  },
  wasResident: {
    label: 'Was the deceased an Alberta resident?',
  },
  seekingReimbursement: {
    label: 'Are you looking to be reimbursed for a funeral service that has already happened and been paid for?',
  },
  serviceLast6Months: {
    label: 'Did this funeral service happen WITHIN the last 6 months?',
  },
  receivingAish: {
    label: 'Was the deceased receiving Assured Income for the Severely Handicapped (AISH)?',
  },
  emergencySituation: {
    label: 'Was there an emergency reason why the service had to be paid before submitting this application?',
  },
  hasTreatyAndLivingOnReserve: {
    label: 'Was the deceased a Treaty Status First Nations person AND living on Reserve?',
  },
  payResources: {
    label: 'Did the deceased belong to any of the following groups/programs?',
  },
  causeOfDeath: {
    label: 'Was the cause of death any of the following?',
  },
  incarcerated: {
    label: 'Was the deceased incarcerated when they passed away?',
  },
};
