<span
  class="help-icon-tooltip help-icon material-icons-outlined"
  aria-label="Show or hide help"
  (click)="isOpen = !isOpen"
  type="button"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
></span>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen">
  <div class="help-icon-tooltip-box">
    <div class="flex-row">
      <h3 class="flex-grow">{{ helpTitle }}</h3>
      <button (click)="isOpen = false" class="no-border-button">
        <span class="material-icons-outlined"> close </span>
      </button>
    </div>
    <ng-container *ngTemplateOutlet="helpTooltip"></ng-container>
  </div>
</ng-template>
