import { LocationList } from './lookup.interface';

export const StateList: LocationList = {
  Alabama: {
    displayName: 'Alabama',
    code: 'AL',
  },
  Alaska: {
    displayName: 'Alaska',
    code: 'AK',
  },
  AmericanSamoa: {
    displayName: 'American Samoa',
    code: 'AS',
  },
  Arizona: {
    displayName: 'Arizona',
    code: 'AZ',
  },
  Arkansas: {
    displayName: 'Arkansas',
    code: 'AR',
  },
  California: {
    displayName: 'California',
    code: 'CA',
  },
  Colorado: {
    displayName: 'Colorado',
    code: 'CO',
  },
  Connecticut: {
    displayName: 'Connecticut',
    code: 'CT',
  },
  Delaware: {
    displayName: 'Delaware',
    code: 'DE',
  },
  DistrictOfColumbia: {
    displayName: 'District Of Columbia',
    code: 'DC',
  },
  FederatedStatesOfMicronesia: {
    displayName: 'Federated States Of Micronesia',
    code: 'FM',
  },
  Florida: {
    displayName: 'Florida',
    code: 'FL',
  },
  Georgia: {
    displayName: 'Georgia',
    code: 'GA',
  },
  Guam: {
    displayName: 'Guam',
    code: 'GU',
  },
  Hawaii: {
    displayName: 'Hawaii',
    code: 'HI',
  },
  TIdahoEST: {
    displayName: 'Idaho',
    code: 'ID',
  },
  Illinois: {
    displayName: 'Illinois',
    code: 'IL',
  },
  Indiana: {
    displayName: 'Indiana',
    code: 'IN',
  },
  Iowa: {
    displayName: 'Iowa',
    code: 'IA',
  },
  Kansas: {
    displayName: 'Kansas',
    code: 'KS',
  },
  Kentucky: {
    displayName: 'Kentucky',
    code: 'KY',
  },
  Louisiana: {
    displayName: 'Louisiana',
    code: 'LA',
  },
  Maine: {
    displayName: 'Maine',
    code: 'ME',
  },
  MarshallIslands: {
    displayName: 'Marshall Islands',
    code: 'MH',
  },
  Maryland: {
    displayName: 'Maryland',
    code: 'MD',
  },
  Massachusetts: {
    displayName: 'Massachusetts',
    code: 'MA',
  },
  TEST: {
    displayName: 'Michigan',
    code: 'MI',
  },
  Minnesota: {
    displayName: 'Minnesota',
    code: 'MN',
  },
  Mississippi: {
    displayName: 'Mississippi',
    code: 'MS',
  },
  Missouri: {
    displayName: 'Missouri',
    code: 'MO',
  },
  Montana: {
    displayName: 'Montana',
    code: 'MT',
  },
  Nebraska: {
    displayName: 'Nebraska',
    code: 'NE',
  },
  Nevada: {
    displayName: 'Nevada',
    code: 'NV',
  },
  NewHampshire: {
    displayName: 'New Hampshire',
    code: 'NH',
  },
  NewJersey: {
    displayName: 'New Jersey',
    code: 'NJ',
  },
  NewMexico: {
    displayName: 'New Mexico',
    code: 'NM',
  },
  NewYork: {
    displayName: 'New York',
    code: 'NY',
  },
  NorthCarolina: {
    displayName: 'North Carolina',
    code: 'NC',
  },
  NorthDakota: {
    displayName: 'North Dakota',
    code: 'ND',
  },
  NorthernMarianaIslands: {
    displayName: 'Northern Mariana Islands',
    code: 'MP',
  },
  Ohio: {
    displayName: 'Ohio',
    code: 'OH',
  },
  Oklahoma: {
    displayName: 'Oklahoma',
    code: 'OK',
  },
  Oregon: {
    displayName: 'Oregon',
    code: 'OR',
  },
  Palau: {
    displayName: 'Palau',
    code: 'PW',
  },
  Pennsylvania: {
    displayName: 'Pennsylvania',
    code: 'PA',
  },
  PuertoRico: {
    displayName: 'Puerto Rico',
    code: 'PR',
  },
  RhodeIsland: {
    displayName: 'Rhode Island',
    code: 'RI',
  },
  SouthCarolina: {
    displayName: 'South Carolina',
    code: 'SC',
  },
  SouthDakota: {
    displayName: 'South Dakota',
    code: 'SD',
  },
  Tennessee: {
    displayName: 'Tennessee',
    code: 'TN',
  },
  Texas: {
    displayName: 'Texas',
    code: 'TX',
  },
  Utah: {
    displayName: 'Utah',
    code: 'UT',
  },
  Vermont: {
    displayName: 'Vermont',
    code: 'VT',
  },
  VirginIslands: {
    displayName: 'Virgin Islands',
    code: 'VI',
  },
  Virginia: {
    displayName: 'Virginia',
    code: 'VA',
  },
  Washington: {
    displayName: 'Washington',
    code: 'WA',
  },
  WestVirginia: {
    displayName: 'West Virginia',
    code: 'WV',
  },
  Wisconsin: {
    displayName: 'Wisconsin',
    code: 'WI',
  },
  Wyoming: {
    displayName: 'Wyoming',
    code: 'WY',
  },
};
