import { CssRoles } from '../css-roles';

//These ids are used in the comment categories, do not change the ids
export const PAGES = {
  Applications: {
    id: 'Applications',
    label: 'Applications',
    shortLabel: 'Applications',
  },
  Audit: {
    id: 'Audit',
    label: 'Audit',
    shortLabel: 'Audit',
  },
  Client: {
    id: 'Client',
    label: 'Client',
    shortLabel: 'Client',
  },
  PersonalInformation: {
    id: 'Personal Information',
    label: 'Personal information',
    shortLabel: 'Personal',
    tabs: {
      personal: 'Personal',
      dependents: 'Dependents',
      spouse: 'Spouse',
    },
  },
  IncomesAndAssets: {
    id: 'Incomes and Assets',
    label: 'Income & assets',
    shortLabel: 'Income & assets',
    tabs: {
      income: 'Income',
      assets: 'Assets',
    },
  },
  Education: {
    id: 'Education',
    label: 'Education',
    shortLabel: 'Education',
  },
  Employment: {
    id: 'Employment',
    label: 'Employment',
    shortLabel: 'Employment',
  },
  Comments: {
    id: 'Comments',
    label: 'Comments',
    shortLabel: 'Comments',
  },
  ReleaseAnnouncementEdit: {
    id: 'ReleaseAnnouncement',
    label: 'Release announcement',
    shortLabel: 'ReleaseAnnouncement',
  },
  Reminders: {
    id: 'Reminders',
    label: 'Reminders',
    shortLabel: 'Reminders',
  },
  IntakeAssessment: {
    id: 'Intake Assessment',
    label: 'Intake & assessment',
    shortLabel: 'Intake',
  },
  EmployabilityAssessment: {
    id: 'Employability Assessment',
    label: 'Employability assessment',
    shortLabel: 'Employability',
  },
  ActionPlan: {
    id: 'ActionPlan',
    label: 'Action plan',
    shortLabel: 'Action plan',
  },
  NewClient: {
    id: 'NewClient',
    label: 'New client',
    shortLabel: 'New client',
  },
  WorkerDashboard: {
    id: 'WorkerDashboard',
    label: 'Home',
    shortLabel: 'Home',
  },
  AdminSettings: {
    id: 'AdminSettings',
    label: 'Admin settings',
    shortLabel: 'Admin',
    allowedRoles: [CssRoles.CSSAdmin],
  },
  AuthManagement: {
    id: 'AuthManagement',
    label: 'User & role management',
    shortLabel: 'Users & roles',
    allowedRoles: [CssRoles.CSSAdmin],
  },
  Settings: {
    id: 'Settings',
    label: 'Settings',
    shortLabel: 'Settings',
  },
  CommentTemplates: {
    id: 'CommentTemplates',
    label: 'Comment tempates',
    shortLabel: 'Templates',
  },
  FileHistory: {
    id: 'FileHistory',
    label: 'History',
    shortLabel: 'History',
  },
  FileRestrictionManagement: {
    id: 'FileRestrictions',
    label: 'File restrictions',
    shortLabel: 'Restrictions',
  },
};

export const SECTIONS = {
  ApplicationInfo: {
    id: 'ApplicationInfo',
  },
  LabourMarket: {
    id: 'LabourMarket',
  },
  EligibilityDecision: {
    id: 'Eligibility Decision',
  },
  IdentifiedNeeds: {
    id: 'IdentifiedNeeds',
  },
};
