export const DM_Collections = [
  { collectionName: 'case.actionPlan', tableName: 'actionPlan' },
  { collectionName: 'case.actionPlanItem', tableName: 'actionPlanItem' },
  { collectionName: 'case.assets', tableName: 'assets' },
  { collectionName: 'case.case', tableName: 'case' },
  { collectionName: 'case.caseAudit', tableName: 'caseAudit' },
  { collectionName: 'case.client', tableName: 'client' },
  { collectionName: 'case.clientDependent', tableName: 'clientDependent' },
  { collectionName: 'case.dependent', tableName: 'dependent' },
  { collectionName: 'case.education', tableName: 'education' },
  { collectionName: 'case.employment', tableName: 'employment' },
  { collectionName: 'case.incomes', tableName: 'incomes' },
  { collectionName: 'case.intakeAssessment', tableName: 'intakeAssessment' },
  { collectionName: 'case.comments', tableName: 'comments' },
  { collectionName: 'case.commentThreads', tableName: 'commentThreads' },
  { collectionName: 'case.fileRestrictions', tableName: 'fileRestrictions' },
  { collectionName: 'case.referral', tableName: 'referral' },
  { collectionName: 'case.consent', tableName: 'consent' },
  { collectionName: 'clm.caseStub', tableName: 'caseStub' },
  { collectionName: 'clm.caseloadCase', tableName: 'caseloadCase' },
  { collectionName: 'am.serviceProvider', tableName: 'serviceProvider' },
];
