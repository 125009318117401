import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const FbKeyQuestionsLabels: IntakeLabelCollection = {
  relationshipToDeceased: {
    label: "What is your (the applicant's) relationship to the deceased?",
  },
  otherRelationship: {
    label: 'Please specify',
  },
  programType: {
    label:
      'Was the deceased part of a household receiving Assured Income for the Severely Handicapped (AISH) or Income Support?',
  },
  statusInCanada: {
    label: "What was the deceased's Citizenship or immigration status?",
  },
  whereDeceasedLived: {
    label: 'Where did the deceased live?',
  },
  hasTreatyStatus: {
    label: 'Was the deceased a First Nations person?',
    helperText:
      'First Nations are persons who are registered under the Indian Act of Canada OR persons who belong to a First Nation or Indian band that signed a treaty with the Crown.',
  },
  livedOnReserved: {
    label: 'Was the deceased living on reserve at the time of death?',
  },
  isDeceasedMinor: {
    label: 'Was the deceased a minor?',
    helperText: 'Minors are people that are under 18 years of age.',
  },
  maritalStatus: {
    label: `What was the deceased's marital status at time of death?`,
  },
  hasDependentChild: {
    label: 'Did the deceased have dependents at time of death? ',
    helperText:
      'A dependent is someone who is living with and needs support from an adult member of the household AND who is either under the age of 18, or (if attending an education program under the Education Act) under the age of 20.',
    spousePartnerLabel: 'Did the deceased or their <<spouseLabel>> have dependents at time of death?',
  },
  incarcerated: {
    label: 'Was the deceased incarcerated when they passed away?',
  },
};
