import { Component, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NgControl,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { extractTouchedChanges } from '../shared/helpers/touched-changes';
import { SELECT_ONE_TEXT } from '@common/constants';

@Component({
  selector: 'common-year-month',
  templateUrl: './year-month.component.html',
  styleUrls: ['./year-month.component.scss'],
})
export class YearMonthComponent implements OnInit, OnDestroy, ControlValueAccessor {
  selectOneText = SELECT_ONE_TEXT;
  @Input() isRequired = true;
  @Input() formControlName: string;
  //@Input() emitIfInvalid = false;
  _months = [];
  yearMonthFormControl: AbstractControl;
  formGroup: UntypedFormGroup;
  private value: string;
  private touched;
  private changed;
  private touchSubscription: Subscription;
  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl,
    private fb: UntypedFormBuilder,
    private controlContainer: ControlContainer
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;

    for (let i = 0; i < 12; i++) {
      this._months.push(this.createMonth(i));
    }
  }
  ngOnInit(): void {
    const containerFormGroup = this.controlContainer.control as UntypedFormGroup;
    this.yearMonthFormControl = containerFormGroup.get(this.formControlName);

    this.formGroup = this.fb.group({
      month: ['', (this.isRequired && Validators.required) || []],
      year: ['', (this.isRequired && Validators.required) || []],
    });

    this.setFormValue(this.value);
    this.touchSubscription = extractTouchedChanges(this.yearMonthFormControl).subscribe((touched: boolean) => {
      if (touched) this.formGroup.markAllAsTouched();
    });
  }
  ngOnDestroy(): void {
    if (this.touchSubscription) this.touchSubscription.unsubscribe();
  }
  writeValue(obj: string): void {
    this.value = obj;
    this.setFormValue(obj);
  }
  registerOnChange(fn): void {
    this.changed = fn;
  }
  registerOnTouched(fn): void {
    this.touched = fn;
  }

  setDisabledState(isDisabled): void {
    if (this.formGroup) isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  onYearMonthChanged() {
    this.updateControlValue();
  }

  onYearBlur() {
    this.touched();
  }

  private setFormValue(value) {
    if (this.formGroup) {
      if (value)
        this.formGroup.setValue({
          year: value.substr(0, 4),
          month: +value.substr(4, 2),
        });
      else this.formGroup.setValue({ month: '', year: '' });

      this.formGroup.updateValueAndValidity();
      this.setControlErrors();
    }
  }

  private updateControlValue() {
    if (this.formGroup.invalid) {
      this.setValue('');
      this.setControlErrors();
    } else {
      this.yearMonthFormControl.setErrors({});
      const yearMonthValue = this.formGroup.value;
      if (yearMonthValue.year && yearMonthValue.month)
        this.setValue(yearMonthValue.year + yearMonthValue.month.toString().padStart(2, '0'));
      else this.setValue('');
      this.yearMonthFormControl.updateValueAndValidity();
    }
  }

  private setControlErrors() {
    if (this.formGroup.invalid) {
      // if year, month are all blank, will invoke required error by calling component
      if (this.yearControl.errors?.required && this.monthControl.errors?.required)
        this.yearMonthFormControl.setErrors({ required: true });
      else this.yearMonthFormControl.setErrors({ yearMonth: true });
    }
  }
  private setValue(newValue: string) {
    if (this.value !== newValue) {
      this.value = newValue;
      if (this.changed) this.changed(this.value);
    }
  }

  private createMonth(month: number) {
    return {
      label: new Date(2020, month, 1).toLocaleString('en-CA', {
        month: 'long',
      }),
      value: month + 1,
    };
  }

  get yearControl() {
    return this.formGroup.controls.year;
  }
  get monthControl() {
    return this.formGroup.controls.month;
  }
}
