import { RowInputType } from './row-input-type';
import { UploadLookup } from './upload-lookup.interface';
export const AishAssetTypes: { [name: string]: UploadLookup } = {
  bankAccountCount: {
    displayValue: 'Number of bank accounts',
    documentTypes: [],
    needSupportDocument: false,
    order: 0,
    inputType: RowInputType.Counter
  },
  bankAccounts: {
    displayValue: 'Bank account balance',
    documentTypes: ['Statement from bank account'],
    needSupportDocument: true,
    order: 1,
    helpText: 'Include all bank account types e.g. chequing, savings, business.',
    inputType: RowInputType.DollarValue
  },
  insurance: {
    displayValue: 'Life insurance',
    documentTypes: [],
    needSupportDocument: true,
    order: 2,
    helpText: 'Cash value of your life insurance.',
    inputType: RowInputType.DollarValue
  },
  termDeposits: {
    displayValue: 'Term deposits',
    documentTypes: ['Bank statement'],
    needSupportDocument: true,
    order: 3,
    inputType: RowInputType.DollarValue
  },
  rrsp: {
    displayValue: 'Registered Retirement Savings Plan (RRSP)',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 4,
    inputType: RowInputType.DollarValue
  },
  gic: {
    displayValue: 'Guaranteed Investment Certificate (GIC)',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 5,
    inputType: RowInputType.DollarValue
  },
  rrif: {
    displayValue: 'Registered Retirement Income Fund (RRIF)',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 6,
    inputType: RowInputType.DollarValue
  },
  annuities: {
    displayValue: 'Annuities',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 7,
    inputType: RowInputType.DollarValue
  },
  lira: {
    displayValue: 'Locked-In Retirement Account (LIRA)',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 8,
    inputType: RowInputType.DollarValue
  },
  rdsp: {
    displayValue: 'Registered Disability Savings Plan (RDSP)',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 9,
    inputType: RowInputType.DollarValue
  },
  resp: {
    displayValue: 'Registered Education Savings Plan (RESP)',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 10,
    inputType: RowInputType.DollarValue
  },
  stocksAndBonds: {
    displayValue: 'Stocks and/or bonds',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 11,
    inputType: RowInputType.DollarValue
  },
  trustFunds: {
    displayValue: 'Trust funds',
    documentTypes: ['Bank statement', 'Investment statements (showing the current market value)'],
    needSupportDocument: true,
    order: 12,
    inputType: RowInputType.DollarValue
  },
  other: { 
    displayValue: 'Other investment(s)', 
    documentTypes: [], 
    needSupportDocument: true, 
    order: 13,
    inputType: RowInputType.DollarValue
  },
  uncashedChequesOrCashOnHand: {
    displayValue: 'Uncashed cheques or cash on hand',
    documentTypes: [],
    needSupportDocument: false,
    order: 14,
    helpText: 'Money or cheques you have in your wallet, house or at another location.',
    inputType: RowInputType.DollarValue
  },
};
