export const PARENTAL_INFO = {
  code: 'parentGuardian',
  name: 'Parent/Guardian information',
  url: 'parent-info',
  state: 'notVisited',
  hasError: false,
  active: true,
  label: 'Parent/Guardian',
};

export const CHILD_INFO = {
  code: 'child',
  name: 'Child information',
  url: 'child-info',
  state: 'notVisited',
  hasError: false,
  active: true,
  label: 'Child information',
};

export const SERVICES = {
  code: 'services',
  name: 'Services',
  url: 'services',
  state: 'notVisited',
  hasError: false,
  active: true,
  label: 'Services',
};

export const REVIEW = {
  code: 'review',
  name: 'Review',
  url: 'review',
  state: 'notVisited',
  hasError: false,
  active: true,
  label: 'Review',
};
