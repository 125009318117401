<!-- <input type="text" formControlName="suiteNumber" [attr.data-cy]="dataCyPrefix + '-suite'" /> -->

<div [formGroup]="formGroup">
  <input
    #autoInput
    type="text"
    placeholder="Municipality"
    aria-label="Municipality"
    matInput
    [formControlName]="controlName"
    [matAutocomplete]="municipalAuto"
    [attr.data-cy]="cyTag"
    (input)="onInput($event.target.value)"
    (change)="onChange($event.target.value)"
    autocomplete="new-Municipality"
  />
  <mat-autocomplete autoActiveFirstOption #municipalAuto="matAutocomplete">
    <mat-optgroup *ngFor="let group of cityOptions$ | async" [label]="group.name">
      <mat-option *ngFor="let option of group.options" [value]="option.name" (mousedown)="$event.preventDefault()">
        {{ option.name }}
      </mat-option>
    </mat-optgroup>
  </mat-autocomplete>
</div>
