import { AssetLookup } from './asset-lookup.interface.';

export const AishAdditionalAssetTypes: { [name: string]: AssetLookup } = {
  vehicle: {
    order: 1,
    displayValue: 'Vehicles',
    documentTypes: ['Vehicle registration', 'Bill of sale'],
    subTypes: {
      vehicle: {
        displayValue: 'Vehicle',
      },
      vehicleDisability: {
        displayValue: 'Vehicle adapted for a disability',
      },
    },
    isOptional: false,
  },
  recreationalVehicle: {
    order: 2,
    displayValue: 'Recreational vehicles',
    documentTypes: ['Vehicle registration', 'Bill of sale'],
    subTypes: {
      recreationalVehicle: {
        displayValue: 'Recreational vehicle',
      },
    },
    isOptional: false,
  },
  farm: {
    order: 4,
    displayValue: 'Farms',
    documentTypes: ['Most recent property tax assessment', 'Mortgage documents'],
    subTypes: {
      farmValue: {
        displayValue: 'Current value of farm assets',
      },
      landValue: {
        displayValue: 'Current value of land other than the home quarter section',
      },
    },
    isOptional: false,
  },
  business: {
    displayValue: 'Businesses',
    order: 5,
    tips: 'Business assets or property, such as vehicles and equipment.',
    documentTypes: [
      'Most recent property tax assessment',
      'Mortgage documents',
      'Tax notice of assessment',
      'Business income tax return accounting statement/balance sheet',
      'Business asset insurance',
    ],
    subTypes: {
      businessValue: {
        displayValue: 'Current value of business',
      },
    },
    isOptional: false,
  },
  property: {
    order: 3,
    displayValue: 'Properties',
    documentTypes: ['Most recent property tax assessment', 'Mortgage documents'],
    subTypes: {
      homePrincipleResidence: {
        displayValue: 'Home/principle residence',
      },
      recreationProperty: {
        displayValue: 'Recreation property',
      },
      rentalProperty: {
        displayValue: 'Rental property',
      },
    },
    isOptional: false,
  },
};
