import { gql } from '@apollo/client/core';

export const GQL_GET_ERROR_MESSAGES = gql`
  query getErrorMessages {
    errorMessages @client {
      errorKey
      errorMessage
    }
  }
`;

export const updateErrorMessageCache = (cache, newMessage: { errorKey; errorMessage }) => {
  // Fetch the errorMessages from the cache
  const existingMessages = cache.readQuery({
    query: GQL_GET_ERROR_MESSAGES,
  });

  // Use writeQuery to update the cache and update ui
  cache.writeQuery({
    query: GQL_GET_ERROR_MESSAGES,
    data: {
      errorMessages: [newMessage, ...(existingMessages?.errorMessages || [])],
    },
  });
};

export const getErrorMessage = (cache, errorKey) => {
  // Fetch the errorMessages from the cache
  const errorMessages = cache.readQuery({
    query: GQL_GET_ERROR_MESSAGES,
  });

  const errorMessageEntry = errorMessages.find((e) => e.errorKey === errorKey);

  return errorMessageEntry.errorMessage;
};
