import { IntakeLabel } from '../../entities/intake-label.interface';
import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export interface FinancialsLabels {
  hasNoIncome: IntakeLabel;
  hasNoAssets: IntakeLabel;
  incomeNotProvided?: IntakeLabel;
  assetsNotProvided?: IntakeLabel;
  // canProvideBankStatement: IntakeLabel;
  knowBankLocation: IntakeLabel;
  uploadBankStatementLabel?: IntakeLabel;
  noIncomeAssets?: IntakeLabel;
  pageDescription?: IntakeLabel;
}

export const FbIncomeAssetLabels: IntakeLabelCollection = {
  //common
  incomeTypeHeader: { label: 'Income type', alternateLabel: 'Incomes' },
  incomeAmountHeader: { label: 'Net monthly income', alternateLabel: 'Amount' },
  incomePaymentDateHeader: { label: 'Date of next payment', alternateLabel: 'Payment date' },
  assetTypeHeader: { label: 'Assets type', alternateLabel: 'Assets' },
  assetAmountHeader: { label: 'Amount' },
  noFinancialsAlertMessage: {
    label:
      'You may be contacted by the Health and Funeral Benefits unit to discuss this financial situation to assist with determining eligibility for funeral benefits.<br /><br />Contact centre assessors may call you during office hours: 8:15 a.m. - 4:30 p.m., Monday to Friday, closed statutory holidays.',
  },
};
